// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <td><input type=\"text\" class=\"form-control input-sm reminder-due-date\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.due_date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"/></td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showUserColumn : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <td><input type=\"text\" class=\"form-control input-sm reminder-message\" value=\""
    + alias3(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "\"/></td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCasefile : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <td>\n    <button type=\"button\" class=\"btn btn-sm btn-primary save\" data-loading-text=\"Saving...\">Save</button>\n    <button type=\"button\" class=\"btn btn-sm btn-default cancel-save\">Cancel</button>\n  </td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "    <td><div class=\"fao-select-container\"></div></td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <td><a href=\""
    + alias3((helpers.caseUrl || (depth0 && depth0.caseUrl) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"caseUrl","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.caseField || (depth0 && depth0.caseField) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),"ref",{"name":"caseField","hash":{},"data":data}))
    + "</a></td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.due_date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showUserColumn : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <td>"
    + alias3(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCasefile : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPending : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "  </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <td>"
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fao : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <td><a class=\"popout\" href=\""
    + alias3((helpers.caseUrl || (depth0 && depth0.caseUrl) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"caseUrl","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.caseField || (depth0 && depth0.caseField) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),"ref",{"name":"caseField","hash":{},"data":data}))
    + "</a></td>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "      <button type=\"button\" class=\"btn btn-sm btn-default update\"><i class=\"fa fa-pencil\"></i> Edit</button>\n      <button type=\"button\" class=\"btn btn-sm btn-danger dismiss\">Dismiss</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      "
    + container.escapeExpression(((helper = (helper = helpers.actionLabel || (depth0 != null ? depth0.actionLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"actionLabel","hash":{},"data":data}) : helper)))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.editing : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
