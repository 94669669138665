// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <a href=\"/cases/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default btn-sm pull-right\">View case</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <div class=\"form-group\">\n        <div class=\"col-sm-4\">\n          <label for=\"ref\" class=\"control-label\">Case Reference</label>\n        </div>\n        <div class=\"col-sm-4\">\n          <input type=\"text\" class=\"form-control\" id=\"ref\" name=\"ref\"\n                 value=\""
    + container.escapeExpression(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ref","hash":{},"data":data}) : helper)))
    + "\"/>\n        </div>\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return alias2(alias1(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.owner : depth0)) != null ? stack1.last_name : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return alias2(alias1(((stack1 = (depth0 != null ? depth0.currentUser : depth0)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.currentUser : depth0)) != null ? stack1.last_name : stack1), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"message","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_nature : stack1), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_number : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.missing_date : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_birth : stack1), depth0));
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_death : stack1), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.last_checked : stack1), depth0));
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.custodian_name : stack1), depth0));
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_holder_name : stack1), depth0));
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n               value=\""
    + container.escapeExpression((helpers.mostRecentCapitalValue || (depth0 && depth0.mostRecentCapitalValue) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_values : stack1),{"name":"mostRecentCapitalValue","hash":{},"data":data}))
    + "\"";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n               value=\""
    + container.escapeExpression((helpers.mostRecentIncomeValue || (depth0 && depth0.mostRecentIncomeValue) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_values : stack1),{"name":"mostRecentIncomeValue","hash":{},"data":data}))
    + "\"";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.address1 : stack1), depth0))
    + "\"";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.address2 : stack1), depth0))
    + "\"";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.city : stack1), depth0))
    + "\"";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.post_code : stack1), depth0))
    + "\"";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.legacy_address : stack1), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<form class='form-horizontal create-casefile-form col-sm-8'>\n  <fieldset>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.edit : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"category\" class=\"control-label\">Category</label>\n      </div>\n      <div class=\"col-sm-4\">\n        <input type=\"text\" class=\"form-control\" id=\"category\" name=\"category\"/>\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"owner\" class=\"control-label\">Created By</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"owner\" name=\"owner\" disabled\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.owner : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"allocate\" class=\"control-label\">Allocate To</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"allocate\" name=\"allocate\"\n        />\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"message\" class=\"control-label\">Message</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"message\" placeholder=\"No message given\" name=\"message\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.message : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"asset_nature\" class=\"control-label\">Asset Nature</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"asset_nature\" placeholder=\"Nature of the asset\" name=\"asset_nature\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_nature : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"number_assets\" class=\"control-label\">Number of Assets</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"number_assets\" placeholder=\"Number of assets\" name=\"asset_number\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_number : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"date_missing\" class=\"control-label\">Date Missing</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"date\" class=\"form-control\" id=\"date_missing\" placeholder=\"Date Missing\" name=\"missing_date\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.missing_date : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"date_of_birth\" class=\"control-label\">Date of Birth</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"date\" class=\"form-control\" id=\"date_of_birth\" placeholder=\"Date of Birth\" name=\"date_of_birth\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_birth : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"date_of_death\" class=\"control-label\">Date of Death</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"date\" class=\"form-control\" id=\"date_of_death\" placeholder=\"Date of Death\" name=\"date_of_death\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_death : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"last_checked\" class=\"control-label\">Last Checked</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"date\" class=\"form-control\" id=\"last_checked\" placeholder=\"Last Checked\" name=\"last_checked\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.last_checked : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"custodian_name\" class=\"control-label\">Custodian Name</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"custodian_name\" placeholder=\"custodian_name\" name=\"custodian_name\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.custodian_name : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"holder_names\" class=\"control-label\">Asset Holder Names</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"holder_names\" placeholder=\"holder_names\" name=\"asset_holder_name\"\n               value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_holder_name : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"capital_value\" class=\"control-label\">Capital Value</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"capital_value\" placeholder=\"Capital value\"\n               name=\"capital_value\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_values : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"income_value\" class=\"control-label\">Past recoverable income value</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"income_value\" placeholder=\"Past recoverable income value\"\n               name=\"income_value\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_values : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"address_line_1\" class=\"control-label\">Address Line 1</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"address1\" placeholder=\"address_line_1\" name=\"address1\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.address1 : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"address_line_2\" class=\"control-label\">Address Line 2</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"address2\" placeholder=\"address_line_2\" name=\"address2\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.address2 : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"city\" class=\"control-label\">City</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"city\" placeholder=\"city\" name=\"city\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.city : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"post_code\" class=\"control-label\">Post Code</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <input type=\"text\" class=\"form-control\" id=\"post_code\" placeholder=\"post_code\" name=\"post_code\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.post_code : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"country\" class=\"control-label\">Country</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <div class=\"countries\"></div>\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <div class=\"col-sm-4\">\n        <label for=\"legacy_address\" class=\"control-label\">Additional Address Details</label>\n      </div>\n      <div class=\"col-sm-8\">\n        <textarea id=\"legacy_address\" class=\"form-control\" name=\"legacy_address\" rows=\"4\" placeholder=\"Additional Address Details...\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.legacy_address : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</textarea>\n      </div>\n    </div>\n  </fieldset>\n\n  <div class=\"form-group\">\n    <div class=\"col-sm-offset-4 col-sm-8\">\n      <button type=\"submit\" id=\"submit-casefile\" class=\"btn btn-primary\">Save</button>\n    </div>\n  </div>\n\n</form>";
},"useData":true});
