'use strict';
var template = require('../../templates/reporting/report-timetrack-edits.hbs');
var Spinner = require('spin');
var ReportTimetrackList = require('./timetrack-edits-list');
var CaseFileReportView = require('./report-casefile');
var alertTemplate = require('../../templates/alert.hbs');
var Events = require('../../models/cases/events');
var ReportList = require('../../models/reporting/report-list');
var ReportItem = require('../../models/reporting/report-item');

var ReportTimetrackEditsView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    start: '#start',
    end: '#end',
    submit: '#submit',
    csvExport: '#export-csv',
    alertArea: '.alert-container',
  },
  events: {
    'click @ui.submit': 'generateReport',
    'click @ui.csvExport': 'exportReportCSV',
  },
  onRender: function() {
    this.ui.start.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    });

    this.ui.end.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    });

    this.eventsList = new Backbone.Marionette.Region({
      el: this.$('#reporting-area')[0],
    });
  },
  generateReport: function(e) {
    e.preventDefault();
    var that = this;
    var start = this.ui.start.val();
    var end = this.ui.end.val();

    if (start === '' || end === '') {
      that.ui.alertArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'Please ensure both dates are selected.',
          type: 'danger',
        })
      );
      return false;
    }

    var events = new Events([], {
      urlRoot:
        '/api/events/' +
        '?created_after=' +
        start +
        '&created_before=' +
        end +
        '&type=1' +
        '&page_size=100000',
    });

    events.fetch().then(function() {
      var data = that.processEventsByUser(events);
      that.report = data;
      var reportList = new ReportTimetrackList({
        collection: data,
      });
      that.eventsList.show(reportList);
    });
    return false;
  },
  processEventsByUser: function(events) {
    var that = this;
    var coll = new ReportList();
    var i = 0;

    var userBuckets = events.groupBy(function(event) {
      return event.get('user');
    });

    _.forEach(userBuckets, function(userBucket, userId) {
      var monthBuckets = _.groupBy(userBucket, function(event) {
        var created = event.get('created');
        var date = new Date(created);
        var mon =
          (date.getMonth() + 1).toString().length > 1
            ? date.getMonth() + 1
            : '0' + (date.getMonth() + 1).toString();
        var bucketID = mon + '/' + date.getFullYear();
        return bucketID;
      });

      _.forEach(monthBuckets, function(monthBucket, monthId) {
        monthId = that.fixMonth(monthId);
        var reportItem = new ReportItem();
        i = i + 1;
        var evCollection = new Events(monthBucket);
        reportItem.set('events', evCollection);
        reportItem.set('id', i);
        reportItem.set('key', monthId);
        reportItem.set('user', userId);
        coll.add(reportItem);
      });
    });

    coll.setSortOrder('key');
    coll.orderBy = ['key'];
    return coll;
  },
  fixMonth: function(monthId) {
    var arr = monthId.split('/');
    var mon = CaseFileReportView.MONTHS[arr[0]];

    return mon + ' ' + arr[1];
  },
  exportReportCSV: function(e) {
    e.preventDefault();
    // check for valid sensitive data token,
    // only allow CSV export if in sensitive data session
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doExportReportCSV(sdToken);
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }

    return false;
  },
  doExportReportCSV: function() {
    var spinner = new Spinner().spin(this.$el[0]);
    var data = null;
    var that = this;
    var csvString = '';

    if (that.report !== null) {
      // general report
      data = that.report;

      csvString = 'User, Team, Month/Year, Edits Performed' + '\n';

      data.forEach(function(item) {
        var user = TCAS.users.get(item.get('user'));
        var team = item.get('team');

        if (typeof user === 'undefined') {
          user = item.get('user');
        } else {
          user = user.getFullName();
        }

        team = TCAS.teams.get(team);
        if (team) {
          team = team.get('name');
        } else {
          team = '-';
        }

        csvString += '"' + user + '",';
        csvString += '"' + team + '",';
        csvString += '"' + item.get('key') + '",';
        csvString += '"' + item.get('events').length + '",';
        csvString += '\n';
      });
    }

    var csvData = {
      content: csvString,
      filename: 'timetrack_report',
    };

    $.ajax({
      url: '/api/tocsv',
      data: csvData,
      type: 'post',
      success: function(data) {
        spinner.stop();
        window.location = data;
      },
      error: function(e) {
        console.log(e);
      },
    });
  },
});

ReportTimetrackEditsView.MONTHS = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

module.exports = ReportTimetrackEditsView;
