'use strict';

var UserDetail = require('./userdetail');

var User = Backbone.Model.extend({
  urlRoot: '/api/users/',
  getFullName: function(){
    return this.get('userdetail').get('contact').getFullName();
  },
  parse: function(response){
    response.userdetail = new UserDetail(response.userdetail, { parse: true });
    return response;
  },
  hasGroup: function(groupId){
    if ((typeof groupId === 'number')) {
      groupId = [groupId];
    }
    return this.hasAnyGroups(groupId);
  },
  setGroup: function(group) {
    var that = this;
    var isAdmin = _.contains(that.get('groups'), User.GROUPS.ADMIN);

    this.set('groups', [group]);
    if(isAdmin) {
      var groups = this.get('groups');
      groups.push(User.GROUPS.ADMIN);
      that.set('groups', groups);
    }
  },
  getGroup: function() {
    var that = this;

    if(!this.has('groups')) {
      return null;
    }

    switch(this.get('groups').length) {
      case 0:
        return null;
      case 1:
        if(_.contains(that.get('groups'), User.GROUPS.ADMIN)) {
          return null;
        } else {
          return that.get('groups')[0];
        }
        break;
      default:
        var groups = that.get('groups').slice();
        groups.splice(groups.indexOf(User.GROUPS.ADMIN), 1);
        return _.min(groups);
    }
  },
  setAdmin: function() {
    var that = this;
    if(!_.contains(that.get('groups'), User.GROUPS.ADMIN)) {
      var groups = that.get('groups');
      groups.push(User.GROUPS.ADMIN);
      that.set('groups', groups);
    }
  },
  isAdmin: function() {
    var that = this;
    return (_.contains(that.get('groups', User.GROUPS.ADMIN)));
  },
  removeAdmin: function() {
    var that = this;
    if(_.contains(that.get('groups'), User.GROUPS.ADMIN)) {
      var groups = that.get('groups');
      groups.splice(groups.indexOf(User.GROUPS.ADMIN),1);
      that.set('groups', groups);
    }
  },
  hasAnyGroups: function(groups){
    var userGroupIds = _.map(this.get('groups'), function(group){
      return group;
    });
    return _.intersection(userGroupIds, groups).length > 0;
  },
  toJSON: function() {
    var data = Backbone.Model.prototype.toJSON.call(this);
    if (this.has('userdetail')) {
      data.userdetail = this.get('userdetail').toJSON();
    }
    return data;
  }
});

User.GROUPS = {
  ROOT: 1,
  ADMIN: 2,
  GENERAL_MANAGER: 3,
  TEAM_LEADER: 4,
  TEAM_SECOND_LEADER: 5,
  CASE_MANAGER: 6,
  RESEARCHER: 7,
  CLIENT: 8,
  PROSPECT: 9,
  OPERATIONS_MANAGER: 10,
  COMMERCIAL_MANAGER: 11,
  PROBATE_RESEARCHER: 12,
  AFFILIATE: 13,
  ACCOUNTS: 14,
  BUSINESS_ASSISTANT: 15
};

User.GROUP_NAMES = [];
User.GROUP_NAMES[User.GROUPS.ROOT] = 'Root';
User.GROUP_NAMES[User.GROUPS.ADMIN] = 'Admin';
User.GROUP_NAMES[User.GROUPS.GENERAL_MANAGER] = 'General Manager';
User.GROUP_NAMES[User.GROUPS.OPERATIONS_MANAGER] = 'Operations Manager';
User.GROUP_NAMES[User.GROUPS.COMMERCIAL_MANAGER] = 'Commercial Manager';
User.GROUP_NAMES[User.GROUPS.TEAM_LEADER] = 'Team Leader';
User.GROUP_NAMES[User.GROUPS.TEAM_SECOND_LEADER] = 'Second Leader';
User.GROUP_NAMES[User.GROUPS.PROBATE_RESEARCHER] = 'Probate Researcher';
User.GROUP_NAMES[User.GROUPS.BUSINESS_ASSISTANT] = 'Business Assistant';
User.GROUP_NAMES[User.GROUPS.AFFILIATE] = 'Affiliate';
User.GROUP_NAMES[User.GROUPS.ACCOUNTS] = 'Accounts';
User.GROUP_NAMES[User.GROUPS.CASE_MANAGER] = 'Case Manager';
User.GROUP_NAMES[User.GROUPS.RESEARCHER] = 'Researcher';
User.GROUP_NAMES[User.GROUPS.CLIENT] = 'Client';
User.GROUP_NAMES[User.GROUPS.PROSPECT] = 'Prospect';

User.CASE_WORKER_GROUPS = [User.GROUPS.ADMIN, User.GROUPS.GENERAL_MANAGER,
  User.GROUPS.OPERATIONS_MANAGER, User.GROUPS.COMMERCIAL_MANAGER,
  User.GROUPS.TEAM_LEADER, User.GROUPS.TEAM_SECOND_LEADER,
  User.GROUPS.CASE_MANAGER, User.GROUPS.RESEARCHER,
  User.GROUPS.PROBATE_RESEARCHER, User.GROUPS.BUSINESS_ASSISTANT];

User.SUPERVISOR_GROUPS = [User.GROUPS.ADMIN, User.GROUPS.GENERAL_MANAGER,
  User.GROUPS.OPERATIONS_MANAGER, User.GROUPS.COMMERCIAL_MANAGER,
  User.GROUPS.TEAM_LEADER, User.GROUPS.TEAM_SECOND_LEADER,
  User.GROUPS.PROBATE_RESEARCHER];

module.exports = User;