// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-warning alert-dismissible\" role=\"alert\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\"><i\n        class=\"fa fa-close\"></i></span></button>\n  <strong>Warning!</strong> You do not have Archived Cases session enabled, so reports will not contain any data\n  from archived cases.\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "\n                  value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fromDate : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return "\n                  value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.toDate : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"9":function(container,depth0,helpers,partials,data) {
    return " selected=\"selected\" ";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                  <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depths[1] != null ? depths[1].category : depths[1]),"==",(depth0 != null ? depth0.id : depth0),{"name":"compare","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n                    ("
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + ")</option>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"15":function(container,depth0,helpers,partials,data) {
    return " - "
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.user : depth0),{"name":"userFullName","hash":{},"data":data}));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.teamNames : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return " -\n      "
    + container.escapeExpression(((helper = (helper = helpers.teamNames || (depth0 != null ? depth0.teamNames : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"teamNames","hash":{},"data":data}) : helper)));
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <tr "
    + ((stack1 = (helpers.ifOdd || (depth0 && depth0.ifOdd) || alias2).call(alias1,(data && data.index),{"name":"ifOdd","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n              <td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.id : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n              <td>Asset</td>\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedAssetTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.confirmedAssetTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pendingAssetTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pledgedAssetTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.atRiskAssetTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.assetSubTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),"total",{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td>"
    + alias3((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.percentPerformanceTarget : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n              <td>"
    + alias3((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.percentStretchTarget : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n            </tr>\n            <tr "
    + ((stack1 = (helpers.ifOdd || (depth0 && depth0.ifOdd) || alias2).call(alias1,(data && data.index),{"name":"ifOdd","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n              <td></td>\n              <td>Council</td>\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedLATotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.confirmedLATotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pendingLATotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pledgedLATotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.atRiskLATotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.laSubTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td></td>\n              <td></td>\n              <td></td>\n            </tr>\n"
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return " class=\"row-alternate\" ";
},"23":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n              <td colspan=\"11\" class=\"table-separator\"></td>\n            </tr>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <tr class=\"row-bold\">\n              <td>Total:</td>\n              <td></td>\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.confirmedTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pendingTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pledgedTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.atRiskTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td></td>\n              <td></td>\n            </tr>\n            <tr class=\"row-bold sums-stats\">\n              <td colspan=\"2\">Booked & Confirmed:</td>\n              <td>"
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.bookedConfirmedTotal : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "</td>\n            </tr>\n            <tr class=\"row-bold sums-stats\">\n              <td colspan=\"2\">Booked & Confirmed & Pending:</td>\n              <td>"
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.bookedConfirmedPendingTotal : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "</td>\n            </tr>\n            <tr class=\"row-bold sums-stats\">\n              <td colspan=\"2\">Maximum Potential:</td>\n              <td>"
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.maximumPotential : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "</td>\n            </tr>\n            <tr class=\"row-bold sums-stats\">\n              <td colspan=\"2\">Maximum Potential (minus At Risk):</td>\n              <td>"
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.maximumPotentialMinusAtRisk : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "</td>\n            </tr>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <div class=\"pipeline-stats-floating\">\n        <table class=\"table table-condensed table-total pipeline-report-stats-table hidden-xs\">\n          <tbody>\n            <tr>\n              <td>Total:</td>\n              <td></td>\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.confirmedTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pendingTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pledgedTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.atRiskTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td></td>\n              <td></td>\n            </tr>\n          </tbody>\n        </table>\n        <div class=\"sums-stats\">\n          <div class=\"row\">\n            <div class=\"col-md-3 col-sm-6 col-xs-12\">\n              Booked & Confirmed: "
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.bookedConfirmedTotal : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "\n            </div>\n            <div class=\"col-md-3 col-sm-6 col-xs-12\">\n              Booked & Confirmed & Pending: "
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.bookedConfirmedPendingTotal : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "\n            </div>\n            <div class=\"col-md-3 col-sm-6 col-xs-12\">\n              Maximum Potential: "
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.maximumPotential : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "\n            </div>\n            <div class=\"col-md-3 col-sm-6 col-xs-12\">\n              Maximum Potential (minus At Risk): "
    + alias3((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,(depth0 != null ? depth0.maximumPotentialMinusAtRisk : depth0),{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "\n            </div>\n          </div>\n        </div>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isArchiveDataSession : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div>\n  <div class=\"panel panel-default\" role=\"tablist\">\n    <div class=\"panel-heading\" id=\"pipeline-filter-heading\" role=\"tab\">\n      <a href=\"#\" id=\"pipline-filter-toggle\" data-toggle=\"collapse\" data-target=\"#pipeline-filter-panel\"\n        aria-expanded=\"true\" aria-controls=\"filter-accordion\">\n        <h4 class=\"panel-title\">Filter</h4>\n      </a>\n    </div>\n    <div id=\"pipeline-filter-panel\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\n      aria-labelledby=\"pipeline-filter-heading\">\n      <div class=\"panel-body\">\n        <form class=\"pipeline-filters\">\n          <fieldset>\n            <div class=\"row\">\n              <div class=\"form-group col-sm-2\">\n                <label for=\"pipeline-booking-date-from\">From</label>\n                <input type=\"text\" name=\"booking_date_from\" class=\"form-control booking-date-from\"\n                  id=\"pipeline-booking-date-from\" placeholder=\"DD/MM/YYYY\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fromDate : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n              </div>\n              <div class=\"form-group col-sm-2\">\n                <label for=\"pipeline-booking-date-to\">To</label>\n                <input type=\"text\" name=\"booking_date_to\" class=\"form-control booking-date-to\"\n                  id=\"pipeline-booking-date-to\" placeholder=\"DD/MM/YYYY\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.toDate : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n              </div>\n              <div class=\"form-group col-sm-4\">\n                <label for=\"pipeline-team\">Team</label>\n                <input type=\"text\" class=\"form-control team\" name=\"team\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTeamDisabled : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n              </div>\n            </div>\n            <div class=\"row\">\n              <div class=\"form-group col-sm-4\">\n                <label class=\"case-manager-label\">Case manager</label>\n                <div class=\"casemanager-select-container\"></div>\n              </div>\n              <div class=\"form-group col-sm-4\">\n                <label class=\"partner-label\">Partner</label>\n                <div class=\"partner-select-container\"></div>\n              </div>\n            </div>\n            <div class=\"row\">\n              <div class=\"form-group col-sm-3\">\n                <label for=\"pipeline-case-type\" class=\"control-label\">Case type</label>\n                <select id=\"pipeline-case-type\" class=\"form-control\" name=\"case_type\">\n                  <option value=\"0\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.caseType : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Any</option>\n                  <option value=\"asset\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.caseType : depth0),"==","asset",{"name":"compare","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Asset\n                  </option>\n                  <option value=\"council\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.caseType : depth0),"==","council",{"name":"compare","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Council\n                  </option>\n                </select>\n              </div>\n              <div class=\"form-group col-sm-3\">\n                <label for=\"pipeline-category\" class=\"control-label\">Category</label>\n                <select id=\"pipeline-category\" class=\"form-control category\" name=\"category\">\n                  <option value=\"0\">Any</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\n              </div>\n              <div class=\"form-group col-sm-3 inactive-users-checkbox-container\">\n                <label class=\"control-label\">Users</label>\n                <div class=\"checkbox\">\n                  <label for=\"include-inactive-users\">\n                    <input type=\"checkbox\" name=\"include_inactive\" id=\"include-inactive-users\" value=\"1\"\n                      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.includeInactiveUsers : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Include <span\n                      class=\"badge\">Inactive</span>\n                  </label>\n                </div>\n              </div>\n            </div>\n\n            <button type=\"submit\" class=\"btn btn-success btn-sm submit-btn\" id=\"company-report-submit\"\n              data-loading-text=\"Please wait...\">\n              Generate Report\n            </button>\n          </fieldset>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div id=\"reporting-area\" class=\"reporting-area hidden\">\n  <h2>Current CM Pipeline<span class=\"report-for-name\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</span></h2>\n\n  <ul class=\"nav nav-tabs\" role=\"tablist\">\n    <li role=\"presentation\" class=\"active\"><a href=\"#pipeline-summary\" class=\"tab-pipeline-summary\"\n        aria-controls=\"pipeline-summary\" role=\"tab\" data-toggle=\"tab\">Overview</a></li>\n    <li role=\"presentation\"><a href=\"#pipeline-granular\" class=\"tab-pipeline-granular\" aria-controls=\"pipeline-granular\"\n        role=\"tab\" data-toggle=\"tab\">Granular</a></li>\n  </ul>\n  <div class=\"tab-content\">\n    <div role=\"tabpanel\" class=\"tab-pane active pipeline-summary\" id=\"pipeline-summary\">\n      <div class=\"table-responsive\">\n        <table class=\"table table-condensed pipeline-report-table\">\n          <thead>\n            <tr>\n              <th></th>\n              <th></th>\n              <th>Booked YTD "
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.fromDate : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + " - "
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.friday : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</th>\n              <th>Confirmed</th>\n              <th>Pending Conf</th>\n              <th>Pledges</th>\n              <th>At Risk</th>\n              <th>Sub Total</th>\n              <th>Total</th>\n              <th>% of Performance Target</th>\n              <th>% of Stretch Target</th>\n            </tr>\n          </thead>\n          <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.userData : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tbody>\n        </table>\n      </div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div role=\"tabpanel\" class=\"tab-pane pipeline-granular\" id=\"pipeline-granular\">\n\n    </div>\n  </div>\n</div>";
},"useData":true,"useDepths":true});
