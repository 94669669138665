'use strict';

var CaseProspect = require('../cases/caseprospect');
var Survey = require('./survey');

var SurveyAccessToken = Backbone.Model.extend({
  urlRoot: '/api/surveyaccesstokens',
  parse: function(response) {
    response.client = new CaseProspect(response.client, { parse: true });
    response.survey = new Survey(response.survey, { parse: true });

    return response;
  }
});

module.exports = SurveyAccessToken;
