'use strict';
var Allocation = require('./allocation');
var BaseCollection = require('../base-collection');

var Allocations = BaseCollection.extend({
  urlRoot: '/api/allocations/',
  model: Allocation,
  comparator: function(allocation){
    return -allocation.get('created');
  }
});

module.exports = Allocations;