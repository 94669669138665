'use strict';

var template = require('../../templates/websites/websites-page.hbs');
var WebsitePreviewView = require('./website-preview');

var WebsitesPageView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childViewContainer: '.row',
  childView: WebsitePreviewView
});

module.exports = WebsitesPageView;
