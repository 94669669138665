'use strict';

var BaseCollection = require('../base-collection');
var Question = require('./question');

var Questions = BaseCollection.extend({
  urlRoot: '/api/questions/',
  model: Question
});

module.exports = Questions;