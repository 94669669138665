// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda;

  return "\n    <a href=\""
    + alias1(((helper = (helper = helpers.caseProspectUrl || (depth0 != null ? depth0.caseProspectUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"caseProspectUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.last_name : stack1), depth0))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.last_name : stack1), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-success\">Client</span>";
},"7":function(container,depth0,helpers,partials,data) {
    return "<span\n        class=\"label label-accounts\">Accounts</span>";
},"9":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\"\n        class=\"btn btn-xs btn-danger reset-2fa\">Reset 2FA</button>";
},"11":function(container,depth0,helpers,partials,data) {
    return "<button\n        type=\"button\" class=\"btn btn-xs btn-default unlock\">Unlock</button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.caseProspectUrl : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</td></a>\n<td>"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.email : stack1), depth0))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_2fa_enabled : stack1),((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_secret_set : stack1),{"name":"or","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_locked : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
