'use strict';
var template = require('../../templates/reporting/report-authenticationlog-row.hbs');

var AuthenticationLogRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tbody',
  serializeData: function() {
    var data = this.model.toJSON();
    data.failed = data.status === 0;
    data.success = data.status === 1;
    data.unlocked = data.status === 2;
    if (data.failed) {
      data.reason = data.failure == 'password' ? 'Password' : '2FA';
    }
    return data;
  }
});

module.exports = AuthenticationLogRowView;