'use strict';

var template = require('../../templates/cases/requests.hbs');
var Request = require('../../models/cases/request');
var Requests = require('../../models/cases/requests');
var RequestsTableView = require('./request-list');
var User = require('../../models/users/user');
var Spinner = require('spin');

var RequestsView = Backbone.Marionette.LayoutView.extend({
  className: 'requests-page',
  template: template,
  regions: {
    activeRequests: '.requests-active',
    closedRequests: '.requests-closed'
  },
  onScrollActiveRequests: function(){
    var that = this;
    var scrollTop = this.activeRequests.$el.scrollTop();
    var isMaxScroll = (this.activeRequests.$el[0].scrollHeight - this.activeRequests.$el.outerHeight()) ===
      scrollTop;
    if (isMaxScroll && this.requestsActiveCollection.next) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.spinner.spin(this.activeRequests.$el.parent()[0]);
      this.requestsActiveCollection.fetchNext().then(function() {
        that.spinner.stop();
      });
    }
  },
  onScrollClosedRequests: function(){
    var that = this;
    var scrollTop = this.closedRequests.$el.scrollTop();
    var isMaxScroll = (this.closedRequests.$el[0].scrollHeight - this.closedRequests.$el.outerHeight() - scrollTop) < 5;
    if (isMaxScroll && this.requestsClosedCollection.next) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.spinner.spin(this.closedRequests.$el.parent()[0]);
      this.requestsClosedCollection.fetchNext().then(function() {
        that.spinner.stop();
      });
    }
  },
  onRender: function(){
    var requestsUrl = '/api/requests/?status=' + Request.STATUSES.PENDING;
    var requestsClosedUrl = '/api/requests/?status=' +
      Request.STATUSES.APPROVED + '&status=' + Request.STATUSES.DENIED;
    // unless user has admin rights, restrict requests to the team
    if (!_.contains(TCAS.session.get('groups'), User.GROUPS.ADMIN)) {
      var teamId = TCAS.reqres.request('teamId', TCAS.session.get('id'));
      if (teamId) {
        requestsUrl += '&team='+teamId;
        requestsClosedUrl += '&team='+teamId;
      } else {
        // user is not part of a team, so use a fake team, it should get no results
        requestsUrl += '&team=10000';
        requestsClosedUrl += '&team=10000';
      }
    }

    var requests = new Requests([], {
      urlRoot: requestsUrl
    });
    var requestsClosed = new Requests([], {
      urlRoot: requestsClosedUrl
    });
    requests.fetch();
    requestsClosed.fetch();

    this.requestsActiveCollection = requests;
    this.requestsClosedCollection = requestsClosed;

    this.listenTo(requests, 'change:status', function(model, status){
      if (status === Request.STATUSES.APPROVED ||
        status === Request.STATUSES.DENIED) {
        requestsClosed.add(model);
      }
    });

    var requestsView = new RequestsTableView({
      collection: requests
    });
    var closedRequestsView = new RequestsTableView({
      collection: requestsClosed
    });

    this.activeRequests.show(requestsView);
    this.closedRequests.show(closedRequestsView);
  },
  onShow: function() {
    var lazyScrollActiveRequests = _.bind(_.debounce(this.onScrollActiveRequests, 100), this);
    var lazyScrollClosedRequests = _.bind(_.debounce(this.onScrollClosedRequests, 100), this);
    this.activeRequests.$el.scroll(lazyScrollActiveRequests);
    this.closedRequests.$el.scroll(lazyScrollClosedRequests);
  },
  onDestroy: function() {
    // remove scroll listeners
    if (this.activeRequests) {
      this.activeRequests.$el.off('scroll');
    }
    if (this.closedRequests) {
      this.closedRequests.$el.off('scroll');
    }
  }
});

module.exports = RequestsView;