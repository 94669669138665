'use strict';

var _ = require('lodash');
var template =
  require('../../../templates/reporting/individual-yearly-in-claims-table.hbs');

var IndividualYearlyInClaimsTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var inClaimsMilestones = this.getOption('inClaimsMilestones');
    var researchMilestonesForInClaims =
      this.getOption('researchMilestonesForInClaims');
    var researchMilestonesThisYear =
      this.getOption('researchMilestonesThisYear');
    var categories = this.getOption('categories');
    var casefiles = this.getOption('casefiles');
    var userTarget = this.getOption('userTarget');
    var thisYear = this.getOption('year');

    var data = {};

    var inClaimsByMonth = [];
    var researchByMonth = [];
    for (var i = 0; i < 12; i += 1) {
      inClaimsByMonth[i] = [];
      researchByMonth[i] = [];
    }

    // group milestones by month
    inClaimsMilestones.each(function(milestone) {
      var created = new Date(milestone.get('created'));
      inClaimsByMonth[created.getMonth()].push(milestone);
    });
    researchMilestonesThisYear.each(function(milestone) {
      var created = new Date(milestone.get('created'));
      researchByMonth[created.getMonth()].push(milestone);
    });

    var totalInClaimsPerMonth = [];
    var totalInClaimsYTD = [];
    var totalAssetResearchYTD = [];
    var totalLAResearchYTD = [];
    var researchThisYear = [];
    var researchThisYearYTD = [];
    var conversionRateAsset = [];
    var conversionRateLA = [];
    var totalInClaims = 0;
    for (i = 0; i < inClaimsByMonth.length; i += 1) {
      totalInClaimsPerMonth[i] = inClaimsByMonth[i].length;
      totalInClaims += totalInClaimsPerMonth[i];
      totalInClaimsYTD[i] = totalInClaims;

      totalAssetResearchYTD[i] = 0;
      totalLAResearchYTD[i] = 0;

      // find out total asset and LA Research in the month
      researchByMonth[i].forEach(function(research) {
        var caseId = research.get('case');
        //find the category for case
        var categoryId = casefiles.get(caseId).get('category');
        var category = categories.get(categoryId);
        var categoryGroupId = category.get('category_group');
        if ([2, 3].indexOf(categoryGroupId) === -1) {
          totalAssetResearchYTD[i] += 1;
        } else {
          totalLAResearchYTD[i] += 1;
        }
      });

      if (i > 0) {
        totalAssetResearchYTD[i] += totalAssetResearchYTD[i - 1];
        totalLAResearchYTD[i] += totalLAResearchYTD[i - 1];
      }

      var convertedAssetCasesInMonth = 0;
      var convertedLACasesInMonth = 0;

      inClaimsByMonth[i].forEach(function(inClaims) {
        var caseId = inClaims.get('case');

        // find associated research milestone
        var research = researchMilestonesForInClaims.find(function(r) {
          return r.get('case') === caseId;
        });

        //find the category for case
        var categoryId = casefiles.get(caseId).get('category');
        var category = categories.get(categoryId);
        var categoryGroupId = category.get('category_group');

        if (research) {
          var researchYear = new Date(research.get('created')).getFullYear();
          if (researchYear === thisYear) {
            if ([2, 3].indexOf(categoryGroupId) === -1) {
              // it is an asset case
              convertedAssetCasesInMonth += 1;
            } else {
              convertedLACasesInMonth += 1;
            }
          }
        }
      });

      researchThisYear[i] = convertedAssetCasesInMonth +
        convertedLACasesInMonth;
      researchThisYearYTD[i] = researchThisYear[i];
      if (i > 0) {
        researchThisYearYTD[i] += researchThisYearYTD[i - 1];
      }

      conversionRateAsset[i] = totalAssetResearchYTD[i] > 0 ?
        convertedAssetCasesInMonth / totalAssetResearchYTD[i] : 0;
      conversionRateLA[i] = totalLAResearchYTD[i] > 0 ?
        convertedLACasesInMonth / totalLAResearchYTD[i] : 0;
    }

    data.totalPerMonth= totalInClaimsPerMonth;
    data.totalYTD = totalInClaimsYTD;
    data.thisYear = thisYear;
    data.researchThisYear = researchThisYear;
    data.researchThisYearYTD = researchThisYearYTD;
    data.conversionRateAsset = conversionRateAsset;
    data.conversionRateLA = conversionRateLA;
    data.conversionRateAssetTarget =
      userTarget.get('asset_case_conversion_rate');
    data.conversionRateLATarget = userTarget.get('la_case_conversion_rate');

    return data;
  }
});

module.exports = IndividualYearlyInClaimsTableView;
