'use strict';
var CaseFile = require('./casefile');
var BaseCollection = require('../base-collection');

var CaseFiles = BaseCollection.extend({
  urlRoot: '/api/cases/',
  model: CaseFile,
  comparators: {
    'date-asc': function(caseFile) {
      return caseFile.get('created');
    },
    'date-desc': function(caseFile) {
      return -caseFile.get('created');
    },
    'legacy-id-asc': function(caseFile) {
      return caseFile.get('legacy_id');
    },
    'legacy-id-desc': function(caseFile) {
      return -caseFile.get('legacy_id');
    }
  },
  setSortOrder: function(sortOrder) {
    if(this.comparators.hasOwnProperty(sortOrder)) {
      this.comparator = this.comparators[sortOrder];
    }
    this.sort();
  }
});

module.exports = CaseFiles;