'use strict';

var template = require('../../templates/clients/relationship-row.hbs');
var templateEdit = require('../../templates/clients/relationship-row-edit.hbs');

var RelationshipRowView = Backbone.Marionette.ItemView.extend({
  getTemplate: function() {
    if (this.editMode) {
      return templateEdit;
    } else {
      return template;
    }
  },
  tagName: 'tr',
  className: 'relationship-row',
  triggers: {
    'click button.delete': 'relationship:delete'
  },
  events: {
    'click @ui.editBtn': 'onClickEdit',
    'click @ui.cancelBtn': 'onClickCancel',
    'click @ui.saveBtn': 'onClickSave'
  },
  ui: {
    editBtn: 'button.edit',
    cancelBtn: 'button.cancel',
    saveBtn: 'button.edit-save',
    type: 'select.relation-type',
    personA: 'input.person-a',
    description: 'input.relationship-description'
  },
  onClickEdit: function() {
    this.editMode = true;
    this.render();
  },
  onClickCancel: function() {
    this.editMode = false;
    this.render();
  },
  onClickSave: function() {
    // jshint ignore:start
    var that = this;
    var relationshipType = this.ui.type.val();
    var personA = this.ui.personA.val();
    var personB = this.getOption('clientId');
    var description = this.ui.description.val();
    this.ui.saveBtn.button('loading');

    this.model.save({
      relationship_type: relationshipType,
      person_a: personA,
      person_b: personB,
      description: description
    }, {
      wait: true,
      success: function() {
        that.editMode = false;
        that.render();
      }
    });
    // jshint ignore:end
  },
  onRender: function() {
    var id = this.model.get('id');
    var clients = this.getOption('clients');
    var clientId = this.getOption('clientId');
    var existingRelations = this.getOption('relations');
    function compareFunction(a, b) {
      var aName = a.getFullName(true);
      var bName = b.getFullName(true);
      return aName.localeCompare(bName);
    }

    function queryProspects(query) {
      var clientsSorted = clients.toArray().sort(compareFunction);
      var clientsSelectData = clientsSorted.filter(function(prospect) {
        // can't be related to yourself
        if (prospect.get('id') === clientId) {
          return false;
        }

        // ignore prospects that are already in relations
        if (existingRelations.some(
            function(relation) {
              return relation.get('is_active') &&
                relation.get('person_a') === prospect.get('id') &&
                relation.get('id') !== id;
            }
          )) {
          return false;
        }
        if (query.term.length > 0) {
          return prospect.getFullName(true).toLowerCase()
            .indexOf(query.term.toLowerCase()) !== -1;
        }
        return true;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: prospect.getFullName(true)
        };
      });

      query.callback({results: clientsSelectData, more: false});
    }

    if (this.editMode) {
      // set up select2 for person selection
      this.ui.personA.select2({
        query: queryProspects,
        initSelection : function (element, callback) {
          var id = element.val();
          var data = { id: id, text: clients.get(id).getFullName(true) };
          callback(data);
        }
      });
    }
  }
});

module.exports = RelationshipRowView;
