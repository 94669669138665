// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"form-group\">\n          <label for=\"client-"
    + alias4(((helper = (helper = helpers.clientId || (depth0 != null ? depth0.clientId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"clientId","hash":{},"data":data}) : helper)))
    + "-subject\" class=\"control-label\">Subject</label>\n          <input type=\"text\" class=\"form-control subject\" id=\"client-"
    + alias4(((helper = (helper = helpers.clientId || (depth0 != null ? depth0.clientId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"clientId","hash":{},"data":data}) : helper)))
    + "-subject\" name=\"subject\" placeholder=\"Type your subject here...\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subject : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subject","hash":{},"data":data}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    return "          <button type=\"button\" class=\"btn btn-default draft\" data-loading-text=\"Saving...\" title=\"Save as draft to preview emails before they are sent. After saving a draft remember to publish or clients will not see it.\">Save Draft</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "Send Email";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.enableFAOClients : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "Add Note";
},"11":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"col-md-6\">\n            <div class=\"form-group fao-clients-container\">\n              <label for=\"fao-client\" class=\"control-label\">FAO clients</label>\n              <input type=\"text\" class=\"fao-clients-select form-control\" name=\"fao_clients\" />\n            </div>\n          </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"form-group\">\n          <button type=\"button\" class=\"btn btn-default btn-xs attach\"><i class=\"fa fa-plus\"></i> Attach new file</button>\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"file-selection-container\"></div>\n        <div class=\"dropzone-previews\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "            <button type=\"button\" class=\"btn btn-default btn-xs attach-existing\"><i class=\"fa fa-plus\"></i> Attach existing file</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<form class=\"message-create-form\" role=\"form\">\n  <div class=\"row\">\n    <div class=\"col-md-6\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.clientId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <div class=\"form-group\">\n        <textarea class=\"form-control\" rows=\"3\" name=\"content\" placeholder=\"Type your message here...\"></textarea>\n      </div>\n      <div class=\"form-group\">\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || alias2).call(alias1,(depth0 != null ? depth0.clientId : depth0),(depth0 != null ? depth0.enableFAOClients : depth0),{"name":"or","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <button type=\"submit\" class=\"btn btn-success submit\" data-loading-text=\"Saving...\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.clientId : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</button>\n      </div>\n    </div>\n    <div class=\"col-md-6\">\n      <div class=\"row\">\n        <div class=\"col-md-6\">\n          <div class=\"form-group\">\n            <label for=\"fao\" class=\"control-label\">FAO users</label>\n            <input type=\"text\" class=\"fao-select form-control\" id=\"fao\" name=\"fao\" />\n          </div>\n        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.enableFAOClients : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_file",{"name":"hasPerm","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</form>";
},"useData":true});
