'use strict';
var template = require('../templates/nav-sidebar-slider.hbs');

var NavigationSidebarView = Backbone.Marionette.ItemView.extend({
  tagName: 'nav',
  className: 'navbar navbar-right',
  template: template,
  events: {
    'click a[data-toggle="collapse"]': 'toggleCollapse',
    'click .sb-toggle-left': 'closeMenu',
    'click .popout-link': 'onClickPopoutLink',
    'click .tcas-sub-menu a': 'closeMenu'
  },
  initialize: function() {
    this.listenTo(TCAS.vent, 'route:change', this.onRouteChange);
  },
  toggleCollapse: function(e){
    this.$('.collapse.in').collapse('hide');
  },
  closeMenu: function(){
    $('html').removeClass('sb-active sb-open');
  },
  onClickPopoutLink: function(e) {
    e.preventDefault();
    e.stopPropagation();
    var href = this.$(e.currentTarget).attr('href');
    TCAS.commands.execute('popoutURL', href, '_blank', 1024, 800);
    return false;
  },
  onRouteChange: function(route) {
    var path = location.pathname;
    // clear existing active links
    this.$('a').removeClass('active');
    this.$('a[href="' + path + '"]').addClass('active')
      .parents('.tcas-sub-menu').siblings('a[data-toggle="collapse"]')
      .addClass('active');
  },
  onShow: function() {
    // set up #site click listener
    $('#sb-site').on('click', function() {
      $('html').removeClass('sb-active sb-open');
    });
  },
  serializeData: function() {
    var path = location.pathname;
    var isCaseActive = path.indexOf('/cases') === 0 ||
      path.indexOf('/reminders') === 0;
    var isRequestsActive = path.indexOf('/requests') === 0;
    var isTemplatesActive = path.indexOf('/templates') === 0;
    var isTimeTrackActive = path.indexOf('/timetrack') === 0;
    var isReportingActive = path.indexOf('/reporting') === 0;
    var isAdminActive = false;
    var isTeamActive = path.indexOf('/team') === 0;
    var adminPaths = ['/categories', '/users', '/permissions', '/templates'];
    for (var i = 0; i < adminPaths.length; i += 1) {
      if (path.indexOf(adminPaths[i]) === 0) {
        isAdminActive = true;
        break;
      }
    }

    var data = {
      isCaseActive: isCaseActive,
      isRequestsActive: isRequestsActive,
      isTemplatesActive: isTemplatesActive,
      isTimeTrackActive: isTimeTrackActive,
      isReportingActive: isReportingActive,
      isAdminActive: isAdminActive,
      isTeamActive: isTeamActive,
      route: location.pathname
    };

    return data;
  }
});

module.exports = NavigationSidebarView;
