'use strict';

var template = require('../../templates/research/research.hbs');

var ResearchView = Backbone.Marionette.ItemView.extend({
  className: 'research form-group',
  template: template,
  events: {
    'dblclick': 'resizeContent',
    'keyup @ui.content': 'onTextKeyUp'
  },
  ui: {
    content: 'textarea'
  },
  onTextKeyUp: function(){
    var that = this;
    var content = that.ui.content.val();
    if (!this.$el.hasClass('edited') &&
      content !== this.model.get('content')) {
      this.$el.removeClass('saved').addClass('edited');
    }
    var $el = that.$el;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(function(){
      that.model.save({content: content},
        {
          wait: true,
          success: function(){
            $el.addClass('saved').removeClass('edited');
          }
        }
      );
    }, 500);
  },
  resizeContent: function() {
    this.ui.content.removeAttr('rows');
    this.ui.content[0].style.height = 'auto';
    this.ui.content[0].style.height = (this.ui.content[0].scrollHeight + 5) + 'px';
  }
});

module.exports = ResearchView;