// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<input type=\"checkbox\" value=\"\" id=\"allocate-checkbox\">";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return container.escapeExpression((helpers.assetValueBand || (depth0 && depth0.assetValueBand) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.capital_value : depth0),(depth0 != null ? depth0.income_value : depth0),(depths[2] != null ? depths[2].researchSignedOff : depths[2]),{"name":"assetValueBand","hash":{},"data":data}));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.allocation : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n  <td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.allocation : depth0)) != null ? stack1.case_manager : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n  <td class=\"supervisor-name\">"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.allocation : depth0)) != null ? stack1.supervisor : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "  <td></td>\n  <td></td>\n  <td></td>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "";
},"12":function(container,depth0,helpers,partials,data) {
    return "              <div>\n                Category: "
    + container.escapeExpression((helpers.categoryField || (depth0 && depth0.categoryField) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.category : depth0),"code",{"name":"categoryField","hash":{},"data":data}))
    + "\n              </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <div>\n                Custodian: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.custodian_name : stack1), depth0))
    + "\n              </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.contactAddress || (depth0 && depth0.contactAddress) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"contactAddress","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda;

  return "<tr class=\"case-row "
    + alias3((helpers.caseStatusColour || (depth0 && depth0.caseStatusColour) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),(depth0 != null ? depth0.fa_sent : depth0),(depth0 != null ? depth0.disclosure_sent : depth0),(depth0 != null ? depth0.is_archived : depth0),{"name":"caseStatusColour","hash":{},"data":data}))
    + "\" data-toggle=\"collapse\"\n  data-target=\"#case-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"case-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <td rowspan=\"2\"></td>\n  <td id=\"allocate-checkbox-area\">\n    <div>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCheckbox : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n  </td>\n  <td>"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</td>\n  <td>"
    + alias3((helpers.caseStatusName || (depth0 && depth0.caseStatusName) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"caseStatusName","hash":{"isArchived":(depth0 != null ? depth0.is_archived : depth0),"faSent":(depth0 != null ? depth0.fa_sent : depth0)},"data":data}))
    + "</td>\n  <td>"
    + alias3((helpers.categoryField || (depth0 && depth0.categoryField) || alias2).call(alias1,(depth0 != null ? depth0.category : depth0),"code",{"name":"categoryField","hash":{},"data":data}))
    + "</td>\n  <td>\n    "
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_values : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </td>\n  <td>"
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.custodian_name : stack1), depth0))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allocation : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "  <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.last_worked_on : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n</tr>\n<tr class=\"case-expanded\">\n  <td colspan=\"11\">\n    <div class=\"collapse\" id=\"case-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n      <div class=\"btn-group visible-xs\">\n        <a href=\"/cases/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default popout\">Open</a>\n        <a href=\"/api/casefilecsv?casefile_id="
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default\" id=\"export-csv\">Export Data</a>\n        <a href=\"/cases/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/edit\" class=\"btn btn-default popout-edit\">Edit</a>\n        <a class=\"btn btn-default\" id=\"delete-casefile\">Delete</a>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-md-10 col-sm-10 col-xs-12\">\n          <ul class=\"nav nav-tabs\">\n            <li class=\"active\"><a href=\"#\" data-toggle=\"tab\" data-target=\"#detail"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">Detail</a></li>\n            <li><a href=\"#\" data-toggle=\"tab\" data-target=\"#allocation"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">Allocation</a></li>\n            <li><a href=\"#\" data-toggle=\"tab\" data-target=\"#messages"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">Messages</a></li>\n          </ul>\n\n          <div class=\"tab-content\">\n            <div class=\"tab-pane active\" id=\"detail"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n              <div>\n                Reference: "
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "\n              </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n              <div>\n                Missing since: "
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.missing_date : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\n              </div>\n              <div>\n                Date of Birth: "
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_birth : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\n              </div>\n              <div>\n                Date of Death: "
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_death : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\n              </div>\n              <div>\n                Reg. asset holder name(s): "
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_holder_name : stack1), depth0))
    + "\n              </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "              <div>\n                Reg. asset holder address(es):\n                <div class=\"asset-holder-addresses\">"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n              </div>\n            </div>\n            <div class=\"tab-pane allocation-list-container\" id=\"allocation"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n            <div class=\"tab-pane message-list-container\" id=\"messages"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n          </div>\n        </div>\n        <div class=\"col-md-2 col-sm-2 hidden-xs\">\n          <div class=\"btn-group-vertical\">\n            <a href=\"/cases/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default popout\">Open</a>\n            <a href=\"/api/casefilecsv?casefile_id="
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default\" id=\"export-csv\">Export Data</a>\n            <a href=\"/cases/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/edit\" class=\"btn btn-default popout-edit\">Edit</a>\n            <a class=\"btn btn-default\" id=\"delete-casefile\">Delete</a>\n          </div>\n        </div>\n      </div>\n    </div>\n  </td>\n</tr>";
},"useData":true,"useDepths":true});
