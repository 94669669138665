// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "<input type=\"checkbox\" value=\"\" id=\"allocate-checkbox\">";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <td>"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_values : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.capital_value : depth0),{"name":"formatCurrency","hash":{},"data":data}));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.allocation : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n    <td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.allocation : depth0)) != null ? stack1.case_manager : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "    <td></td>\n    <td></td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<tr class=\"case-row "
    + alias3((helpers.caseStatusColour || (depth0 && depth0.caseStatusColour) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),(depth0 != null ? depth0.fa_sent : depth0),(depth0 != null ? depth0.disclosure_sent : depth0),(depth0 != null ? depth0.is_archived : depth0),{"name":"caseStatusColour","hash":{},"data":data}))
    + "\">\n  <td rowspan=\"2\"></td>\n  <td id=\"allocate-checkbox-area\">\n      <div>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allocation : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n  </td>\n  <td>"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</td>\n  <td>"
    + alias3((helpers.caseStatusName || (depth0 && depth0.caseStatusName) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"caseStatusName","hash":{"isArchived":(depth0 != null ? depth0.is_archived : depth0)},"data":data}))
    + "</td>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_assetvalue",{"name":"hasPerm","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allocation : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "</tr>";
},"useData":true});
