'use strict';

var template = require('../../../templates/reporting/company-activity-report.hbs');
var Spinner = require('spin');
var moment = require('moment');
var CaseFiles = require('../../../models/cases/casefiles');
var Milestones = require('../../../models/cases/milestones');
var BookingRecords = require('../../../models/reporting/bookingrecords');
var UserTargets = require('../../../models/reporting/usertargets');
var CompanyTargets = require('../../../models/reporting/companytargets');
var CompanyTarget = require('../../../models/reporting/companytarget');
var TeamTargets = require('../../../models/reporting/teamtargets');
var TeamTarget = require('../../../models/reporting/teamtarget');
var Teams = require('../../../models/users/teams');
var CompanyActivityResearchTableView = require('./company-activity-research-table');
var CompanyActivityInClaimsTableView = require('./company-activity-in-claims-table');
var CompanyActivityBookedTableView = require('./company-activity-booked-table');
var CompanyActivityMilestonesByMonthTableView = require('./company-activity-milestones-by-month-table');
var CompanyActivityCaseSizeTableView = require('./company-activity-case-size-table');
var CompanyActivitySummaryTableView = require('./company-activity-summary-table');

var CompanyActivityReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'company-activity-report',
  ui: {
    year: '.year',
    submit: '.submit-btn',
    alertArea: '#alert-area',
    reportingArea: '.reporting-area',
    team: '.team',
    teamName: '.team-name',
  },
  regions: {
    summaryRegion: '.summary-container',
    researchRegion: '.research-container',
    inClaimsRegion: '.in-claims-container',
    disclosureRegion: '.disclosure-container',
    bookedAssetRegion: '.booked-asset-container',
    bookedLARegion: '.booked-la-container',
    concludedRegion: '.concluded-container',
    avgAssetCaseSizeRegion: '.avg-asset-case-size-container',
    avgLACaseSizeRegion: '.avg-la-case-size-container',
  },
  behaviors: {
    SubmitForm: {
      submitHandler: 'generateReport',
    },
    Validate: {
      validateOptions: {
        rules: {
          year: {
            required: true,
            number: true,
          },
        },
      },
    },
  },
  initialize: function() {
    this.teams = new Teams();
    this.listenTo(this.teams, 'sync', this.render);
    this.listenTo(this.archiveDataTokens, 'sync', this.render);
    if (!TCAS.session.hasPerm('filter_company_report_team')) {
      var userId = TCAS.session.get('id');
      var teamAllocation = this.getOption('teamAllocations').find(function(teamAllocation) {
        return teamAllocation.get('user') === userId;
      });
      if (teamAllocation) {
        this.selectedTeams = [teamAllocation.get('team')];
      } else {
        this.selectedTeams = [];
      }
    }
    this.teams.fetch();
  },
  onDataReady: function(dataRows, section, dataFields) {
    // partial data for summary is ready, pass it along to the summaryTable view
    // data is array of individual user data fields,
    // process as needed depending on section
    if (dataFields) {
      var data = {};
      for (var field in dataFields) {
        if (dataFields.hasOwnProperty(field)) {
          var mappedField = dataFields[field];
          data[mappedField] = [];
          for (var i = 0; i < 12; i += 1) {
            data[mappedField][i] = 0;
          }

          dataRows.forEach(function(userData) {
            var monthValues = userData[field];
            for (var i = 0; i < monthValues.length; i += 1) {
              data[mappedField][i] += monthValues[i];
            }
          });
        }
      }

      this.summaryTable.setData(data, section);
    }
  },
  generateReport: function(data) {
    var that = this;
    var year = parseInt(this.ui.year.val(), 10);
    var users = this.getOption('users');
    var filteredUsers = users.toArray();
    var includeInactiveUsers = data.include_inactive === '1'; // jshint ignore:line
    if (!includeInactiveUsers) {
      filteredUsers = filteredUsers.filter(function(user) {
        return user.get('is_active');
      });
    }
    var team = 0;
    var teamIds = [];
    if (data.team) {
      teamIds = data.team.split(',').map(function(id) {
        return parseInt(id, 10);
      });
    }

    if (teamIds.length) {
      var teamAllocations = this.getOption('teamAllocations');
      // filter users from any team
      filteredUsers = filteredUsers.filter(function(user) {
        var matchedTeamAllocations = teamAllocations.filter(function(teamAllocation) {
          var startYear = parseInt(teamAllocation.get('start_date').substr(0, 4));
          var endYear = parseInt(teamAllocation.get('end_date').substr(0, 4));
          return (
            teamAllocation.get('user') === user.get('id') &&
            year >= startYear &&
            year <= endYear &&
            teamIds.indexOf(teamAllocation.get('team')) !== -1
          );
        });
        return matchedTeamAllocations.length;
      });

      // set the team name in heading
      var teamNames = this.teams
        .filter(function(team) {
          return teamIds.indexOf(team.get('id')) !== -1;
        })
        .map(function(team) {
          return team.get('name');
        })
        .join(', ');
      this.ui.teamName.text(' - ' + teamNames).removeClass('hidden');
    } else {
      this.ui.teamName.text('').addClass('hidden');
    }

    // 1st january this year to next year
    var startDate = new Date(year, 0, 1);
    var endDate = moment(new Date(year, 0, 1))
      .endOf('year')
      .toDate();

    this.ui.submit.button('loading');

    // fetch all user targets and teams, data is used in multiple tables
    this.userTargets = new UserTargets();
    this.userTargets.setFilters({ page_size: 1000, year: year });
    this.companyTargets = new CompanyTargets();
    this.teamTargets = new TeamTargets();
    $.when(this.userTargets.fetch(), this.teamTargets.fetch(), this.companyTargets.fetch()).done(
      function() {
        // fetch data for this time period
        that.refreshResearch(startDate, endDate, filteredUsers, that.userTargets, teamIds);
        that.refreshInClaims(startDate, endDate, filteredUsers, that.userTargets, teamIds);
        that.refreshDisclosure(startDate, endDate, filteredUsers, that.userTargets, teamIds);
        that.refreshBooked(startDate, endDate, filteredUsers, that.userTargets, teamIds);
        that.refreshConcluded(startDate, endDate, filteredUsers, that.userTargets, teamIds);

        var summaryOptions = {};
        if (that.companyTargets.length) {
          summaryOptions.target = that.companyTargets.find(function(target) {
            return target.get('year') === year;
          });
        }
        if (teamIds.length === 1) {
          summaryOptions.target = that.teamTargets.find(function(teamTarget) {
            return teamTarget.get('team') === teamIds[0] && teamTarget.get('year') === year;
          });

          // if no team target has been set yet, use a blank one
          if (!summaryOptions.target) {
            summaryOptions.target = new TeamTarget();
          }
        }
        if (!summaryOptions.target) {
          // no team or company target, set an empty company target
          summaryOptions.target = new CompanyTargets();
        }
        that.summaryTable = new CompanyActivitySummaryTableView(summaryOptions);
        that.summaryRegion.show(that.summaryTable);

        that.ui.reportingArea.removeClass('hidden');
        that.ui.submit.button('reset');
      }
    );
  },
  refreshResearch: function(startDate, endDate, users, userTargets, teamIds) {
    var that = this;

    // fetch all research signed off (pk 6) milestones for this user
    var researchMilestones = new Milestones();
    var filters = {
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      is_active: true,
      milestone_type: 6,
      page_size: 10000,
    };
    if (teamIds.length) {
      filters.user__team__in = teamIds.join(','); // jshint ignore:line
    }

    researchMilestones.setFilters(filters);
    researchMilestones.fetch({
      success: function() {
        that.researchTable = new CompanyActivityResearchTableView({
          researchMilestones: researchMilestones,
          users: users,
          userTargets: userTargets,
        });
        that.listenTo(that.researchTable, 'dataReady', that.onDataReady);
        that.researchRegion.show(that.researchTable);
      },
    });
  },
  refreshInClaims: function(startDate, endDate, users, userTargets, teamIds) {
    var that = this;

    // fetch all in claims (pk 13) and research signed off (pk 6) milestones
    var inClaimsMilestones = new Milestones();
    var inClaimsFilters = {
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      is_active: true,
      milestone_type: 13,
      page_size: 10000,
    };
    var researchMilestones = new Milestones();
    var researchFilters = {
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      is_active: true,
      milestone_type: 6,
      page_size: 10000,
    };

    if (teamIds.length) {
      inClaimsFilters.user__team__in = teamIds.join(',');
      researchFilters.user__team__in = teamIds.join(','); // jshint ignore:line
    }

    inClaimsMilestones.setFilters(inClaimsFilters);
    researchMilestones.setFilters(researchFilters);

    $.when(inClaimsMilestones.fetch(), researchMilestones.fetch()).done(function() {
      that.inClaimsTable = new CompanyActivityInClaimsTableView({
        inClaimsMilestones: inClaimsMilestones,
        researchMilestones: researchMilestones,
        users: users,
        userTargets: userTargets,
        year: startDate.getFullYear(),
      });
      that.listenTo(that.inClaimsTable, 'dataReady', that.onDataReady);
      that.inClaimsRegion.show(that.inClaimsTable);
    });
  },
  refreshDisclosure: function(startDate, endDate, users, userTargets, teamIds) {
    var that = this;

    // fetch all disclosure sent (pk 26) milestones
    var disclosureMilestones = new Milestones();
    var filters = {
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      is_active: true,
      milestone_type: 26,
      page_size: 10000,
    };
    if (teamIds.length) {
      filters.user__team__in = teamIds.join(','); // jshint ignore:line
    }

    disclosureMilestones.setFilters(filters);
    disclosureMilestones.fetch({
      success: function() {
        that.disclosureTable = new CompanyActivityMilestonesByMonthTableView({
          milestones: disclosureMilestones,
          users: users,
        });
        that.listenTo(that.disclosureTable, 'dataReady', function(dataRows) {
          // dataRows refers to generic milestone
          that.onDataReady(dataRows, 'disclosure', {
            milestonesByMonth: 'disclosureByMonth',
            milestonesYTD: 'disclosureYTD',
          });
        });
        that.disclosureRegion.show(that.disclosureTable);
      },
    });
  },
  refreshBooked: function(startDate, endDate, users, userTargets, teamIds) {
    var that = this;
    var categories = this.getOption('categories');

    // fetch all booking records during this period
    var bookingRecords = new BookingRecords();
    var bookingLARecords = new BookingRecords();
    var bookingAssetRecords = new BookingRecords();
    var bookingCases = new CaseFiles();
    var filters = {
      date_after: moment(startDate).format('DD/MM/YYYY'),
      date_before: moment(endDate).format('DD/MM/YYYY'),
      is_active: true,
      page_size: 10000,
    };

    if (teamIds.length) {
      filters.team__in = teamIds.join(','); // jshint ignore:line
    }
    bookingRecords.setFilters(filters);

    bookingRecords.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these records
        var caseIds = bookingRecords.map(function(bookingRecord) {
          return bookingRecord.get('case');
        });
        bookingCases.setFilters({
          id__in: caseIds.length ? caseIds.join(',') : 0,
          is_active: true,
          page_size: 10000,
        });
        bookingCases.fetch({
          success: function() {
            // separate cases and booking records into LA and asset collections
            bookingCases.each(function(bookedCase) {
              var category = categories.get(bookedCase.get('category'));
              var categoryGroupId = category.get('category_group');
              var filteredBookingRecords = bookingRecords.where({
                case: bookedCase.get('id'),
              });
              if ([2, 3].indexOf(categoryGroupId) === -1) {
                bookingAssetRecords.add(filteredBookingRecords);
              } else {
                bookingLARecords.add(filteredBookingRecords);
              }
            });

            that.bookedAssetTable = new CompanyActivityBookedTableView({
              bookingRecords: bookingAssetRecords,
              users: users,
            });
            that.bookedLATable = new CompanyActivityBookedTableView({
              bookingRecords: bookingLARecords,
              users: users,
            });
            that.avgAssetCaseSizeTable = new CompanyActivityCaseSizeTableView({
              bookingRecords: bookingAssetRecords,
              users: users,
            });
            that.avgLACaseSizeTable = new CompanyActivityCaseSizeTableView({
              bookingRecords: bookingLARecords,
              users: users,
            });
            that.listenTo(that.bookedAssetTable, 'dataReady', function(data) {
              this.onDataReady(data, 'booked asset', {
                totalByMonth: 'bookedAssetByMonth',
                totalYTD: 'bookedAssetYTD',
              });
            });
            that.listenTo(that.bookedLATable, 'dataReady', function(data) {
              this.onDataReady(data, 'booked la', {
                totalByMonth: 'bookedLAByMonth',
                totalYTD: 'bookedLAYTD',
              });
            });
            that.listenTo(that.avgAssetCaseSizeTable, 'dataReady', function(data) {
              this.onDataReady(data, 'average asset size', {
                totalByMonth: 'assetRevenueByMonth',
                numCasesByMonth: 'numAssetCasesByMonth',
              });
            });
            that.listenTo(that.avgLACaseSizeTable, 'dataReady', function(data) {
              this.onDataReady(data, 'average la size', {
                totalByMonth: 'laRevenueByMonth',
                numCasesByMonth: 'numLACasesByMonth',
              });
            });
            that.bookedAssetRegion.show(that.bookedAssetTable);
            that.bookedLARegion.show(that.bookedLATable);
            that.avgAssetCaseSizeRegion.show(that.avgAssetCaseSizeTable);
            that.avgLACaseSizeRegion.show(that.avgLACaseSizeTable);
          },
        });
      },
    });
  },
  refreshConcluded: function(startDate, endDate, users, userTargets, teamIds) {
    var that = this;

    // fetch all case concluded (pk 35) milestones for this user
    var concludedMilestones = new Milestones();
    var filters = {
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      is_active: true,
      milestone_type: 35,
      page_size: 10000,
    };
    if (teamIds.length) {
      filters.user__team__in = teamIds.join(','); // jshint ignore:line
    }

    concludedMilestones.setFilters(filters);
    concludedMilestones.fetch({
      success: function() {
        that.concludedTable = new CompanyActivityMilestonesByMonthTableView({
          milestones: concludedMilestones,
          users: users,
        });
        that.listenTo(that.concludedTable, 'dataReady', function(dataRows) {
          this.onDataReady(dataRows, 'concluded', {
            milestonesByMonth: 'concludedByMonth',
            milestonesYTD: 'concludedYTD',
          });
        });
        that.concludedRegion.show(that.concludedTable);
      },
    });
  },
  onRender: function() {
    // set the date filters to this year
    var today = new Date();
    this.ui.year.val(today.getFullYear());

    var teamOptions = {
      data: this.teams.map(function(team) {
        return {
          id: team.get('id'),
          text: team.get('name'),
        };
      }),
      multiple: true,
    };
    this.ui.team.val(this.selectedTeams).select2(teamOptions);
  },
  serializeData: function() {
    var data = {
      teams: this.teams.toJSON(),
    };
    if (!TCAS.session.hasPerm('filter_company_report_team')) {
      data.isTeamDisabled = true;
    }

    var archiveDataToken = TCAS.request('archiveDataToken');
    data.isArchiveDataSession = !!archiveDataToken;

    return data;
  },
});

module.exports = CompanyActivityReportView;
