'use strict';

var template = require('../../templates/milestones/milestone.hbs');
var MilestoneView = require('./milestone');
var User = require('../../models/users/user');

var ClientMilestoneView = MilestoneView.extend({
  onRender: function() {
    this.$('[title]').tooltip();
    this.ui.createdDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.editable = this.editable;
    data.isClient = this.getOption('isClient');

    // include active case manager in serialize data, new milestones should not
    // be applied if case is unassigned
    var allocation = this.getOption('casefile').getCurrentAllocation();
    if (allocation && allocation.get('case_manager')) {
      data.case_manager = allocation.get('case_manager'); // jshint ignore:line
    }

    data.canApply = false;

    if (TCAS.session.hasPerm('add_clientmilestone')) {
      if (TCAS.session.hasGroup([User.GROUPS.ROOT, User.GROUPS.ADMIN, User.GROUPS.OPERATIONS_MANAGER,
          User.GROUPS.COMMERCIAL_MANAGER, User.GROUPS.GENERAL_MANAGER, User.GROUPS.BUSINESS_ASSISTANT])) {
        data.canApply = true;
      } else if (data.case_manager) {
        if (data.case_manager === TCAS.session.get('id')) {
          data.canApply = true;
        } else if (allocation && allocation.get('partner') === TCAS.session.get('id')) {
          data.canApply = true;
        } else {
          var caseManager = TCAS.users.find(function(user) {
            return user.get('id') === data.case_manager;
          });
          if (caseManager) {
            if (TCAS.session.hasGroup([User.GROUPS.TEAM_LEADER, User.GROUPS.TEAM_SECOND_LEADER]) &&
              TCAS.session.get('userdetail').team === caseManager.get('userdetail').get('team')) {
              data.canApply = true;
            }
          }
        }
      }
    }

    if (data.is_disabled && !data.checked) {
      data.canApply = false;
    }

    return data;
  }
});

module.exports = ClientMilestoneView;