'use strict';
var template = require('../../templates/categories/category-list.hbs');
var CategoryRowView = require('./category-row');

var CategoryListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: CategoryRowView,
  childViewContainer: 'tbody',
  childViewOptions: function() {
    return {
      researchTypes: this.options.researchTypes
    };
  }
});

module.exports = CategoryListView;