// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<br/><small>(Relates to "
    + container.escapeExpression(((helper = (helper = helpers.response_to_correspondence || (depth0 != null ? depth0.response_to_correspondence : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"response_to_correspondence","hash":{},"data":data}) : helper)))
    + ")</small>";
},"3":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <td>\n    <div class=\"form-group\">\n      <input class=\"form-control input-sm\" name=\"case_prospect\" value=\""
    + container.escapeExpression(((helper = (helper = helpers.case_prospect || (depth0 != null ? depth0.case_prospect : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"case_prospect","hash":{},"data":data}) : helper)))
    + "\">\n    </div>\n  </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "  <td>"
    + container.escapeExpression((helpers.prospectFullName || (depth0 && depth0.prospectFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_prospect : depth0),{"name":"prospectFullName","hash":{},"data":data}))
    + "</td>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.evidence : depth0)) != null ? stack1.file_download : stack1), depth0))
    + "\" class=\"file-link\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.evidence : depth0)) != null ? stack1.original_file_name : stack1), depth0))
    + "</a>";
},"11":function(container,depth0,helpers,partials,data) {
    return "None";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td>"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.response_to_correspondence : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td><select name=\"is_incoming\" class=\"form-control input-sm\">\n  <option value=\"False\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_incoming : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Outgoing</option>\n  <option value=\"True\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_incoming : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Incoming</option>\n</select></td>\n<td><input type=\"text\" class=\"form-control input-sm\" name=\"date\" placeholder=\"DD/MM/YYYY\" value=\""
    + alias4((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"/></td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.editMode : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "<td>"
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.evidence : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td><button type=\"button\" class=\"btn btn-xs btn-default cancel\">Cancel</button> <button type=\"button\" class=\"btn btn-xs btn-primary save\" data-loading-text=\"Saving...\">Save</button></td>";
},"useData":true});
