'use strict';


var Request = Backbone.Model.extend({
  urlRoot: '/api/requests/',
  parse: function(response){
    response.created = new Date(response.created);
    response.modified = new Date(response.modified);
    return response;
  }
});

Request.STATUSES = {
  PENDING: 0,
  APPROVED: 1,
  DENIED: 2
};

Request.ACTIONS = {
  SIGN_OFF: 0,
  CONCLUDE_CASE: 1,
  ABANDON_CASE: 2,
  UNPOST_RESEARCH: 3,
  HAND_BACK: 4,
  ALLOW_ADD_ALLOCATION: 5,
  IN_CLAIMS: 6,
  DISCLOSURE_SENT: 7,
  CASE_BOOKED: 8,
  CONDITIONS_OF_APPROVAL_REPORT: 9,
  MESSAGE_REVIEW: 10
};

Request.DESCRIPTIONS = {
  REQUEST_SIGN_OFF: 0,
  REQUEST_CONCLUDE_CASE: 1,
  REQUEST_ABANDON_CASE: 2,
  REQUEST_UNPOST_RESEARCH: 3,
  REQUEST_HAND_BACK: 4,
  REQUEST_ALLOW_ADD_ALLOCATION: 5,
  REQUEST_IN_CLAIMS: 6,
  REQUEST_DISCLOSURE_SENT: 7,
  REQUEST_CASE_BOOKED: 8,
  REQUEST_CONDITIONS_OF_APPROVAL_REPORT: 9,
  REQUEST_MESSAGE_REVIEW: 10
};

Request.TYPES = {
  CASE: 0,
  USER: 1
};

module.exports = Request;