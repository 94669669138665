// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <th>"
    + alias4(((helper = (helper = helpers.dateColumn || (depth0 != null ? depth0.dateColumn : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dateColumn","hash":{},"data":data}) : helper)))
    + "</th>\n            <th>Net amount</th>\n            <th>"
    + alias4(((helper = (helper = helpers.payeeColumn || (depth0 != null ? depth0.payeeColumn : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payeeColumn","hash":{},"data":data}) : helper)))
    + "</th>\n            <th>Client</th>\n            <th>Description</th>\n            <th>VAT</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <th>"
    + alias4(((helper = (helper = helpers.dateColumn || (depth0 != null ? depth0.dateColumn : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dateColumn","hash":{},"data":data}) : helper)))
    + "</th>\n            <th>Amount</th>\n            <th>"
    + alias4(((helper = (helper = helpers.payeeColumn || (depth0 != null ? depth0.payeeColumn : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payeeColumn","hash":{},"data":data}) : helper)))
    + "</th>\n            <th>Client</th>\n            <th>Description</th>\n            <th>VAT</th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "        <tbody class=\"cost-add\">\n          <tr>\n            <td class=\"cost-date\">\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"date\" placeholder=\"DD/MM/YYYY\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"/>\n              </div>\n            </td>\n            <td class=\"cost-amount\">\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"amount\" placeholder=\"Amount...\" value=\"0.00\"/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"payee\" placeholder=\""
    + alias3(((helper = (helper = helpers.payeePlaceholder || (depth0 != null ? depth0.payeePlaceholder : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"payeePlaceholder","hash":{},"data":data}) : helper)))
    + "\"/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"client\" placeholder=\"Client...\"/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <textarea type=\"text\" class=\"form-control input-sm\" name=\"reason\" placeholder=\"Reason...\" rows=\"1\" maxlength=\"64\"></textarea>\n              </div>\n            </td>\n            <td class=\"cost-vat\">\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"vat\" placeholder=\"VAT\" value=\"0.00\"/>\n              </div>\n            </td>\n            <input type=\"hidden\" name=\"type\" value=\""
    + alias3(((helper = (helper = helpers.filterType || (depth0 != null ? depth0.filterType : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"filterType","hash":{},"data":data}) : helper)))
    + "\"/>\n            <td class=\"col-xs-2\">\n              <button type=\"submit\" class=\"btn btn-success btn-sm\" data-loading-text=\"Saving...\">Add</button>\n            </td>\n          </tr>\n        </tbody>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"text-right\">\n  <button type=\"button\" class=\"btn btn-default btn-xs copy\" data-clipboard-target=\"#"
    + alias4(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tableId","hash":{},"data":data}) : helper)))
    + " tbody.cost-items\"><i class=\"fa fa-copy\"></i> Copy</button>\n</div>\n<form action=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"table-responsive\">\n    <table class=\"table table-condensed table-hover\" id=\""
    + alias4(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tableId","hash":{},"data":data}) : helper)))
    + "\">\n      <thead>\n        <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isIncoming : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "          <th></th>\n        </tr>\n      </thead>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_cost",{"name":"hasPerm","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <tbody class=\"cost-items\"></tbody>\n      <tbody class=\"cost-total\"></tbody>\n    </table>\n  </div>\n</form>";
},"useData":true});
