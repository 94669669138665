'use strict';

var template = require('../../templates/clients/correspondence-add.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var templateFilePreview = require(
  '../../templates/clients/correspondence-file-preview.hbs');
var Dropzone = require('dropzone');

//jshint camelcase:false
var CorrespondenceAddView = Backbone.Marionette.ItemView.extend({
  tagName: 'tr',
  template: template,
  events: {
    'click .correspondence-file-preview button.remove': 'removeFile'
  },
  ui: {
    incomingSelect: 'select[name="is_incoming"]',
    prospect: 'input[name="case_prospect"]',
    date: 'input[name="date"]',
    evidence: 'input[name="evidence"]',
    attachFile: 'button.add-file',
    submitBtn: 'button[type="submit"]',
    uploadContainer: '.upload-file-container',
    dropzonePreviews: '.dropzone-previews',
    filePreviews: '.file-previews',
    relatesTo: '.relates-to-correspondence',
    formRow: ''
  },
  removeFile: function(){
    this.ui.filePreviews.empty();
    this.ui.evidence.val('');
    this.ui.uploadContainer.removeClass('hide');
  },
  saveCorrespondence: function(data){
    var that = this;
    var casefile = this.getOption('casefile');
    data.casefile = casefile.get('id');
    var date = data.date.split('/');
    data.date = date[2] + '-' + date[1] + '-' + date[0];
    data.owner = TCAS.session.get('id');

    if (this.selectedCorrespondence) {
      data.response_to_correspondence = this.selectedCorrespondence.get('id');
    }

    that.ui.submitBtn.button('loading');

    function performSave(){
      that.collection.create(data, {
        wait: true,
        success: function(){
          that.ui.submitBtn.button('reset');
          that.removeFile();
          if (that.selectedCorrespondence) {
            that.selectedCorrespondence.trigger('manualDeselect');
            that.correspondenceSelected(that.selectedCorrespondence, false);
          }
          if (data.evidence && data.case_prospect) {
            // file was uploaded and matched to a client, make sure the file is client viewable
            // file should exist in casefile files since it should have just been fetched
            var file = casefile.get('files').find(function(file) {
              return file.get('id') === data.evidence;
            });
            if (file) {
              file.save({ clients_allowed: [parseInt(data.case_prospect, 10)] }, {
                patch: true,
                wait: true
              }).then(function() {
                // fetch case files again so switching to files tab will show new client file
                casefile.get('files').fetch();
              });
            }
          }
        }
      });
    }

    if (this.isFileQueued) {
      this.listenToOnce(this, 'fileUploaded', function(fileId){
        casefile.get('files').fetch().then(function(){
          data.evidence = fileId;
          performSave();
        });
      });

      this.dropzone.processQueue();
    } else {
      performSave();
    }
  },
  correspondenceSelected: function(model, selected){
    if (selected) {
      this.selectedCorrespondence = model;
      this.ui.prospect.select2('val', model.get('prospect'));
      if (model.get('is_incoming') === 'True') {
        this.ui.incomingSelect.val('False');
      } else {
        this.ui.incomingSelect.val('True');
      }
      this.ui.relatesTo.html('Related to '+model.get('id'));
    } else {
      this.selectedCorrespondence = null;
      this.ui.relatesTo.html(this.ui.relatesTo.attr('data-initial-text'));
    }
  },
  onRender: function(){
    var that = this;
    var clients = this.getOption('clients');

    function compareFunction(a, b) {
      var aName = a.getFullName();
      var bName = b.getFullName();
      return aName.localeCompare(bName);
    }

    function getProspectName(prospect) {
      var name = prospect.getFullName();
      if (prospect.get('contact').get('post_code')) {
        var post = prospect.get('contact').get('post_code');
        name += ' (' + post.trim()+ ')';
      }

      return name;
    }

    function queryProspects(query){
      var clientsSorted = clients.toArray().sort(compareFunction);
      var clientsSelectData = clientsSorted.filter(function(prospect) {
        if (query.term.length > 0) {
          return prospect.getFullName().toLowerCase().indexOf(query.term.toLowerCase()) !== -1;
        }
        return true;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: getProspectName(prospect)
        };
      });

      query.callback({results: clientsSelectData, more: false});
    }

    this.ui.date.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
      todayHighlight: true
    });

    this.ui.prospect.select2({
      query: queryProspects,
      initSelection : function (element, callback) {
        var id = element.val();
        var data = {id: id, text: clients.get(id).getFullName()};
        callback(data);
      }
    });
  },
  onShow: function(){
    var that = this;
    var casefile = this.getOption('casefile');

    //configure dropzone
    this.dropzone = new Dropzone(this.el, {
      url: '/api/cases/' + casefile.get('id') + '/files/',
      headers: {'X-CSRFToken': TCAS.csrfToken},
      previewsContainer: that.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      clickable: that.ui.attachFile[0],
      maxFilesize: 500,
      acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.eml,.msg,.ged',
      autoProcessQueue: false,
      accept: function (file, done) {
        var alphaNumericPattern = new RegExp(
          '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
        );
        // check for only alphanumeric characters
        if (alphaNumericPattern.test(file.name)) {
          done();
        } else {
          done('This file has unexpected characters, ' +
            'file names must use the correct file naming structure and ' +
            'only contain characters consisting of: ' +
            'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
            'apostrophes, commas or spaces.');
        }
      }
    });
    this.dropzone.on('success', function (file, response) {
      that.trigger('fileUploaded', response.id);
      that.isFileQueued = false;
      casefile.get('files').fetch().then(function () {
        that.dropzone.removeAllFiles();
        that.ui.uploadContainer.removeClass('hide');
      });
    });
    this.dropzone.on('sending', function (file, xhr, formData) {
      // Show the total progress bar when upload starts
      that.$(file.previewElement).find('.progress').removeClass('hide');
      that.$(file.previewElement).find('button').addClass('hide');
      if (that.getOption('clientId')) {
        formData.append('clients_allowed', [that.getOption('clientId')]);
      }
    });
    this.dropzone.on('addedfile', function (file) {
      _(this.files).each(function(f){
        if (f != file) {
          this.removeFile(f);
        }
      }, this);

      that.isFileQueued = true;
      that.ui.evidence.val(-1);
      that.$(file.previewElement).find('button').removeClass('hide');
      that.ui.uploadContainer.addClass('hide');
    });
    this.dropzone.on('error', function (file) {
      that.ui.evidence.val('');
    });
    this.dropzone.on('removedfile', function () {
      that.isFileQueued = false;
      that.ui.evidence.val('');
      that.ui.uploadContainer.removeClass('hide');
    });
    this.dropzone.on('dragover', function () {
      that.$el.addClass('success');
    });
    this.dropzone.on('dragleave', function () {
      that.$el.removeClass('success');
    });
    this.dropzone.on('drop', function (e) {
      that.$el.removeClass('success');
      if (_(e.dataTransfer.types).contains('text/plain')) {
        var data = JSON.parse(e.dataTransfer.getData('text/plain'));
        that.ui.filePreviews.empty().append(templateFilePreview(data));
        that.ui.evidence.val(data.id);
        that.ui.uploadContainer.addClass('hide');
        e.preventDefault();
      }
    });
  },
  serializeData: function() {
    return { clientId: this.getOption('clientId') };
  }
});

module.exports = CorrespondenceAddView;