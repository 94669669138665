// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<fieldset>\n    <div class=\"form-group col-md-3 col-sm-3 col-xs-12\">\n        <label for=\"after-date\">After Date</label>\n        <input type=\"text\" class=\"form-control\" id=\"after-date\"/>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-3 col-xs-12\">\n        <label for=\"before-date\">Before Date</label>\n        <input type=\"text\" class=\"form-control\" id=\"before-date\"/>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-3 col-xs-12\">\n      <label for=\"case-manager\">Case Manager</label>\n      <input type=\"text\" class=\"form-control\" id=\"case-manager\" value=\""
    + container.escapeExpression(((helper = (helper = helpers.user || (depth0 != null ? depth0.user : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"user","hash":{},"data":data}) : helper)))
    + "\"/>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-3 col-xs-12\">\n      <label for=\"supervisor\">Supervisor</label>\n      <input type=\"text\" class=\"form-control\" id=\"supervisor\"/>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-3 col-xs-12\">\n        <label for=\"activity\">Activity</label>\n        <select id=\"activity\" class=\"form-control\">\n            <option value=\"\">Any</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.activities : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-3 col-xs-12\">\n        <label for=\"task\">Task</label>\n        <select id=\"task\" class=\"form-control\">\n          <option value=\"\">Any</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tasks : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n\n    <div class=\"form-group col-md-3 col-sm-4 col-xs-12\">\n        <label for=\"case-file\">Case</label>\n        <input type=\"text\" class=\"form-control\" id=\"case-file\"/>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-4 col-xs-12\">\n      <label for=\"category\">Category</label>\n      <input type=\"text\" class=\"form-control\" id=\"category\"/>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-3 col-xs-12\">\n        <label for=\"status\">Status</label>\n        <select id=\"status\" class=\"form-control\">\n          <option value=\"\">Any</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.statuses : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n\n    <div class=\"form-group col-md-3 col-sm-4 col-xs-12\">\n        <label for=\"value-above\">Value Above</label>\n        <input type=\"text\" class=\"form-control\" id=\"value-above\"/>\n    </div>\n\n\n    <div class=\"form-group col-md-3 col-sm-4 col-xs-12\">\n        <label for=\"value-below\">Value Below</label>\n        <input type=\"text\" class=\"form-control\" id=\"value-below\"/>\n    </div>\n\n    <div class=\"form-group col-lg-2 col-md-3 col-sm-4 col-xs-6\">\n      <label for=\"fa-sent\">FA Sent</label>\n      <select id=\"fa-sent\" class=\"form-control\">\n        <option value=\"\">Any</option>\n        <option value=\"True\">Yes</option>\n        <option value=\"False\">No</option>\n      </select>\n    </div>\n\n    <div class=\"form-group col-lg-2 col-md-3 col-sm-4 col-xs-6\">\n      <label for=\"disclosure-sent\">Disclosure Sent</label>\n      <select id=\"disclosure-sent\" class=\"form-control\">\n        <option value=\"\">Any</option>\n        <option value=\"True\">Yes</option>\n        <option value=\"False\">No</option>\n      </select>\n    </div>\n\n    <div class=\"form-group col-lg-2 col-md-3 col-sm-4 col-xs-6\">\n        <label for=\"case-booked\">Case Booked</label>\n        <select id=\"case-booked\" class=\"form-control\">\n            <option value=\"\">Any</option>\n            <option value=\"True\">Yes</option>\n            <option value=\"False\">No</option>\n        </select>\n    </div>\n\n\n    <div class=\"clearfix\"></div>\n    <div class=\"col-xs-12\">\n        <button type=\"submit\" class=\"btn btn-primary\" id=\"submit-filter\" data-loading-text=\"Filtering...\">Filter</button>\n        <button type=\"button\" class=\"btn btn-default\" id=\"reset-filter\">Reset filter</button>\n        <button type=\"button\" class=\"btn btn-default csv\" id=\"csv-export\" data-loading-text=\"Exporting....\">Export</button>\n    </div>\n</fieldset>";
},"useData":true});
