'use strict';
var template = require('../../templates/timetrack/timetrack-create.hbs');
var TimeTrack = require('../../models/timetrack/timetrack');
var CaseFile = require('../../models/cases/casefile');
var Message = require('../../models/cases/message');
var moment = require('moment');

var TimetrackCreateView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    'date': '#date',
    'caseRef': '#case',
    'task': '#task',
    'activity': '#activity',
    'start': '#start',
    'end': '#finish',
    'duration': '#hours',
    'caseMessageCheckboxContainer': '.case-message-checkbox-container',
    'caseMessageCheckbox': '#case-message-checkbox'
  },
  events: {
    'change @ui.start': 'changeTime',
    'change @ui.end': 'changeTime',
    'change @ui.activity': 'changeActivity'
  },
  changeActivity: function() {
    //this.ui.caseRef.valid();
    var activity = this.ui.activity.val();
    var tasks = TimeTrack.ACTIVITY_TASKS[activity];
    var that = this;
    this.ui.task.html('');
    _.forEach(tasks, function(value) {
      that.ui.task.append(
        $('<option></option>').attr('value', value).text(TimeTrack.TASKS[value])
      );
    });
  },
  changeTime: function() {
    var that = this;
    var dateStart = moment(this.ui.date.datepicker('getUTCDate'));
    var dateEnd = moment(dateStart);

    var startHours = this.ui.start.val();
    var endHours = this.ui.end.val();

    // check if the field is valid
    this.ui.end.valid();

    if (startHours.length && startHours.indexOf(':') === -1) {
      startHours = startHours.substr(0, 2)+':'+startHours.substr(2, 4);
      this.ui.start.val(startHours);
    }
    if (endHours.length && endHours.indexOf(':') === -1) {
      endHours = endHours.substr(0, 2)+':'+endHours.substr(2, 4);
      this.ui.end.val(endHours);
    }

    var startHoursSplit = startHours.split(':');
    var endHoursSplit = endHours.split(':');

    dateStart.hours(parseInt(startHoursSplit[0], 10));
    dateStart.minutes(parseInt(startHoursSplit[1], 10));
    dateEnd.hours(parseInt(endHoursSplit[0], 10));
    dateEnd.minutes(parseInt(endHoursSplit[1], 10));

    var diff = moment.utc(dateEnd.diff(dateStart));

    if (diff.isValid()) {
      that.ui.duration.val(diff.format('HH:mm'));
    } else {
      that.ui.duration.val('Invalid Range');
    }
  },
  getTasks: function(TASKS) {
    var tasks = [];
    for (var key in TASKS) {
      var obj = TASKS[key];
      tasks.push({
        id: key,
        name: obj
      });
    }
    return tasks;
  },
  submitData: function(data) {
    var that = this;
    var start = this.ui.date.datepicker('getUTCDate');
    var finish = this.ui.date.datepicker('getUTCDate');

    start.setHours(
      parseInt(data.start.split(':')[0]),
      parseInt(data.start.split(':')[1]));
    finish.setHours(
      parseInt(data.finish.split(':')[0]),
      parseInt(data.finish.split(':')[1]));

    var entry = null;
    if(this.options.entry) {
      entry = that.options.entry;
    } else {
      entry = new TimeTrack();
    }

    if (data.case === '0') {
      data.case = null;
    }

    function saveTimeTrackEntry(timeTrackData) {
      entry.save(timeTrackData, {
        wait: true,
        success: function() {
          TCAS.vent.trigger('save:timetrack', entry);
        },
        error: function() {
          TCAS.vent.trigger('fail:timetrack');
        }
      });
    }

    function formatCaseMessage(message) {
      var s = 'Timetrack Entry: ' + moment(start.getTime()).format('DD/MM/YYYY') +
        ' ' + data.start + ' - ' + data.finish + '\n' + message;

      return s;
    }

    var isCaseMessage = this.ui.caseMessageCheckbox.is(':visible') &&
      this.ui.caseMessageCheckbox.is(':checked');

    if (isCaseMessage) {
      var messageData = {
        casefile: data.case,
        content: formatCaseMessage(data.description),
        type: Message.TYPES.TIMETRACK,
        owner: TCAS.session.get('id'),
        fao: [],
        fao_clients: [],
        attachments: [],
        is_published: true
      };

      // save the case message first,
      // id will be required for saving the timetrack message
      var msg = new Message();
      if (this.options.entry && this.options.entry.has('case_message')) {
        messageData.id = this.options.entry.get('case_message');
      }

      msg.save(messageData, {
        wait: true,
        success: function(caseMessage) {
          saveTimeTrackEntry({
            activity: data.activity,
            task: data.task,
            owner: TCAS.session.get('id'),
            posted: false,
            note: data.description,
            case: data.case,
            start: start,
            end: finish,
            is_case_message: true, // jshint ignore:line
            case_message: caseMessage.get('id') // jshint ignore:line
          });
        }
      });
    } else {
      // if there was a case message before, delete it
      if (this.options.entry && this.options.entry.has('case_message')) {
        var msg = new Message({ id: this.options.entry.get('case_message')});
        msg.destroy();
      }

      saveTimeTrackEntry({
        activity: data.activity,
        task: data.task,
        owner: TCAS.session.get('id'),
        posted: false,
        note: data.description,
        case: data.case,
        start: start,
        end: finish,
        case_message: null,
        is_case_message: false // jshint ignore:line
      });
    }
  },
  behaviors: {
    SubmitForm: {
      message: 'create timetrack form',
      selector: '.create-timetrack-form',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          date: {
            required: true
          },
          start: {
            required: true,
            time: 'required time'
          },
          finish: {
            required: true,
            time: 'required time',
            greaterThan: ['#start', 'start time']
          },
          activity: {
            required: true
          },
          case: {
            required: function(elem) {
              var $activity = $(elem).parents('form').find('#activity');
              return $activity.val() == 0; // 0 = Case Work
            }
          },
          task: {
            required: true
          },
          description: {
            required: true
          }
        },
        messages: {
          case: {
            required: function(rule, elem) {
              var activity = document.getElementById('activity');
              var caseMessageCheckbox = document.getElementById(
                'case-message-checkbox');
              var isCaseWork = activity.options[activity.selectedIndex]
                  .text === 'Case Work';
              if (isCaseWork) {
                return 'Case Work activity requires a case be specified';
              } else if (caseMessageCheckbox.checked) {
                return 'Adding a note to a case requires a case to be ' +
                  'specified.';
              }
            }
          }
        },
        ignore: []
      },
      selector: '.create-timetrack-form'
    }
  },
  onShow: function() {
    var that = this;

    $.validator.addMethod('time', function(value, element) {
      return this.optional(element) ||
        /^(([0-1]?[0-9])|([2][0-3])):?([0-5]?[0-9])(:([0-5]?[0-9]))?$/i.
        test(value);
    }, 'Please enter a valid time (hh:mm or hhmm).');

    $.validator.addMethod('greaterThan', function(value, element, params) {
      if($(params[0]).val() !== '') {
        if (value.indexOf(':') !== -1) {
          var valueSplit = value.split(':');
          value = parseInt(valueSplit.join(''));
        }
        var smallerValue = $(params[0]).val();
        if (smallerValue.indexOf(':') !== -1) {
          var smallerSplit = smallerValue.split(':');
          smallerValue = parseInt(smallerSplit.join(''));
        }
        return isNaN(value) && isNaN(smallerValue) ||
          (Number(value) > Number(smallerValue));
      }
    }, 'Must be after {1}');

    this.ui.date.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    }).on('changeDate', function(e) {
      TCAS.vent.trigger('form:change:date', e.date);
    });

    this.listenTo(TCAS.vent, 'widget:change:date', function(date) {
      if(!(date instanceof Date)) {
        return;
      }
      date.setHours(0,0,0,0);
      if(date.getTime() === that.ui.date.datepicker('getDate').getTime()) {
        return;
      } else {
        that.ui.date.datepicker('setDate', date);
      }
    });

    function formatCaseFile(caseFile, container, query){
      var s = caseFile.ref;
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      //s = caseFile.id
      return s;
    }

    this.ui.caseRef.select2({
      placeholder: 'Case',
      minimumInputLength: 3,
      ajax: {
        url: '/api/cases/',
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatCaseFile,
      formatSelection: formatCaseFile,
      allowClear: true,
      initSelection: function(element, callback) {
        if(that.options.entry !== undefined) {
          if (that.options.entry.has('case')) {
            var casefile = new CaseFile({
              id: that.options.entry.get('case')
            });

            casefile.fetch().then(function() {
              callback(casefile.toJSON());
            });
          }
        }
      }
    }).on('change', function() {
      $(this).valid();
      that.ui.caseMessageCheckboxContainer.toggleClass('hide', $(this).val() === '');
    });

    this.ui.caseRef.select2('val', '');

    if(this.options.entry) {
      that.ui.task.val(that.options.entry.get('task'));
      that.ui.activity.val(that.options.entry.get('activity'));
      that.ui.date.datepicker('setDate',
        new Date(that.options.entry.get('start')));
      that.changeTime();
      this.ui.caseRef.select2('val', 0);
    } else {
      that.ui.date.datepicker('setDate', new Date());
    }

    that.changeActivity();
    if(that.options.entry) {
      that.ui.task.val(that.options.entry.get('task'));
    }
  },
  serializeData: function() {
    var that = this;
    var data = {};
    if(this.options.entry) {
      data.entry = that.options.entry.toJSON();
    }
    //data.tasks = this.getTasks(TimeTrack.TASKS);
    data.activities = this.getTasks(TimeTrack.ACTIVITIES);
    return data;
  },
});

module.exports = TimetrackCreateView;