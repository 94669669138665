'use strict';

var template = require('../../../templates/reporting/survey-text-answers-table.hbs');

var SurveyTextAnswersTableView = Backbone.Marionette.ItemView.extend({
  className: 'survey-text-answers-table',
  template: template,
  serializeData: function() {
    var answersData = this.getOption('answers').map(function(answer) {
      return answer.toJSON();
    });

    return {
      answers: answersData,
      question: this.getOption('question').toJSON()
    };
  }
});

module.exports = SurveyTextAnswersTableView;
