'use strict';

var template = require('../../templates/clients/correspondence-list.hbs');

var CorrespondenceAddView = require('./correspondence-add');
var CorrespondenceListView = require('./correspondence-list');


//jshint camelcase:false
var CorrespondenceLayoutView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    correspondenceAdd: '.correspondence-add'
  },
  behaviors: {
    Validate: {
      validateOptions: {
        rules: {
          date: {
            required: true
          },
          prospect: {
            required: true
          },
          description: {
            required: true,
            maxlength: 64
          },
          evidence: {
            required: true
          }
        },
        messages: {
          date_received: {
            required: 'Date received is required for incoming correspondence.'
          },
          evidence: {
            required: 'An attached file is required to record correspondence.'
          }
        },
        ignore: []
      }
    },
    SubmitForm: {
      submitHandler: 'saveCorrespondence'
    }
  },
  saveCorrespondence: function(data){
    //delegate to the add view
    this.correspondenceAddView.saveCorrespondence(data);
  },
  onShow: function(){
    var correspondenceListView = new CorrespondenceListView({
      collection: this.collection,
      casefile: this.getOption('casefile'),
      clients: this.getOption('clients'),
      clientId: this.getOption('clientId'),
      el: this.$('.correspondence-items')
    });
    correspondenceListView.render();
    this.listenTo(correspondenceListView, 'correspondenceSelected',
      function(model, selected){
        this.correspondenceAddView.correspondenceSelected(model, selected);
      }
    );

    this.correspondenceAddView = new CorrespondenceAddView({
      casefile: this.getOption('casefile'),
      clients: this.getOption('clients'),
      clientId: this.getOption('clientId'),
      collection: this.collection
    });
    this.correspondenceAdd.show(this.correspondenceAddView);
  },
  serializeData: function(){
    var data = {
      date: new Date(),
      url: this.collection.url()
    };
    return data;
  }
});

module.exports = CorrespondenceLayoutView;