'use strict';

var template = require('../../templates/cases/casefile-detail-list.hbs');
var AssetValue = require('../../models/cases/assetvalue');

var CaseFileDetailsView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    'capitalValue': '#capital-value',
    'valuationDate': '#valuation-date',
    'commission': '#commission',
    'incomeValue': '#income-value',
    'expectedCompletion': '#expected-completion-date',
    'saveValuationChanges': '#save-valuation-changes'
  },
  initialize: function() {
    var that = this;

    this.listenTo(TCAS.vent, 'status:change', function() {
      that.options.casefile.fetch().then(function() {
        that.render();
      });
    });

    this.valuation =
      this.options.casefile
        .get('asset')
        .get('asset_values').findWhere({
          is_most_recent: true // jshint ignore:line
        });
    this.asset = this.options.casefile.get('asset');
  },
  saveValuation: function(data) {
    var that = this;

    var capitalValue = data.capitalValue;
    var commission = data.commission;
    var incomeValue = data.incomeValue;
    var date = this.ui.valuationDate.val().split('/');
    var valuationDate = date[2] + '-' + date[1] + '-' + date[0];
    date = this.ui.expectedCompletion.val().split('/');
    var expectedCompletion = date[2] + '-' + date[1] + '-' + date[0];

    var existingValuation = this.getOption('casefile').get('asset').getCurrentValuation();
    if (existingValuation) {
      existingValuation.set('is_most_recent', false);
    }

    this.getOption('casefile').get('asset').get('asset_values').create({
      capital_value: capitalValue, // jshint ignore:line
      commission: commission,
      income_value: incomeValue, // jshint ignore:line
      valuation_date: valuationDate, // jshint ignore:line
      expected_completion_date: expectedCompletion, // jshint ignore:line
      asset: that.asset.get('id'),
    }, {
      wait: true,
      success: function(model) {
        that.valuation = model;
        that.render();
      }
    });

    return false;
  },
  behaviors: {
    SubmitForm: {
      message: 'add valuation form',
      selector: '.add-valuation-form',
      submitHandler: 'saveValuation'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          capitalValue: {
            required: true,
            number: true
          },
          commission: {
            required: true,
            number: true
          },
          incomeValue: {
            required: true,
            number: true
          },
          valuationDate: {
            required: true
          },
          expectedCompletion: {
            required: true
          }
        },
        ignore: []
      },
      selector: '.add-valuation-form'
    }
  },
  onRender: function() {
    this.ui.valuationDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });

    this.ui.expectedCompletion.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });
  },
  serializeData: function() {
    var data = this.options.casefile.toJSON();
    // find out the category group to work out the sector (asset / LA case)
    // these require different form labelling and fields
    var categoryGroup = TCAS.reqres.request('categoryGroup', data.category);
    if (categoryGroup) {
      if ([2, 3].indexOf(categoryGroup.get('id')) === -1) {
        data.isAssetCase = true;
      } else {
        data.isLACase = true;
      }
    }
    var currentAllocation = this.options.casefile.getCurrentAllocation();
    if (currentAllocation) {
      data.currentAllocation = currentAllocation.toJSON();
    }

    if (this.valuation !== undefined) {
      data.most_recent_valuation = this.valuation.toJSON(); // jshint ignore:line
    }

    if (!this.options.casefile.get('milestones').isEmpty()) {
      data.researchSignedOff = this.options.casefile.checkResearchSignedOff();
    } else if (!this.fetchedMilestones) {
      this.listenToOnce(this.options.casefile.get('milestones'), 'sync',
        function(){
          this.render();
        }
      );
      this.options.casefile.fetchMilestones();
      this.fetchedMilestones = true;
    }

    return data;
  }
});

module.exports = CaseFileDetailsView;