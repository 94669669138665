'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-disclosure-sent-row.hbs');

var IndividualMonthlyDisclosureSentRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click .popout': 'onClickPopoutCase'
  },
  onClickPopoutCase: function() {
    TCAS.commands.execute('popoutCase', this.model);

    return false;
  },
  serializeData: function() {
    var data = this.model.toJSON();
    // find the research report (6) milestones and fa sent (7) milestones
    var milestones = this.getOption('milestones');
    if (milestones.length) {
      var disclosureSent = milestones.where({ milestone_type: 26 })[0];
      data.disclosureSent = disclosureSent.toJSON();
    }
    return data;
  }
});

module.exports = IndividualMonthlyDisclosureSentRowView;
