'use strict';
var ClientMilestone = require('./clientmilestone');
var BaseCollection = require('../base-collection');

var ClientMilestones = BaseCollection.extend({
  urlRoot: '/api/clientmilestones/',
  model: ClientMilestone,
  comparator: function(milestone){
    return -milestone.get('created');
  }
});

module.exports = ClientMilestones;
