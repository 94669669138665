'use strict';
var BaseCollection = require('../base-collection');
var ForecastingRecord = require('./forecastingrecord');

var ForecastingRecords = BaseCollection.extend({
  model: ForecastingRecord,
  urlRoot: '/api/forecastingrecords/',
  comparator: function(forecastingRecord){
    return new Date(forecastingRecord.get('date')).getTime();
  }
});

module.exports = ForecastingRecords;
