'use strict';

var BaseCollection = require('../base-collection');
var CalendarEventType = require('./calendareventtype');

var CalendarEventTypes = BaseCollection.extend({
  urlRoot: '/api/calendareventtypes/',
  model: CalendarEventType
});

module.exports = CalendarEventTypes;
