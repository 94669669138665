'use strict';
var template = require('../../templates/teams/team-list.hbs');
var TeamRowView = require('./team-row');

var TeamListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: TeamRowView,
  childViewContainer: 'tbody',
});

module.exports = TeamListView;
