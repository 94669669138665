'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-in-claims-list.hbs');
var IndividualMonthlyInClaimsRowView = require('./individual-monthly-in-claims-row');
var Milestones = require('../../../models/cases/milestones');

var IndividualMonthlyInClaimsListView =
  Backbone.Marionette.CompositeView.extend({
    template: template,
    ui: {
      tablePad: '.table-pad',
      tableTotal: '.table-total'
    },
    initialize: function() {
      this.minRows = 0;
      this.listenTo(this.getOption('milestones'), 'sync', this.render);
      this.listenTo(this.getOption('milestones'), 'sync', this.calculateTotals);
    },
    collectionEvents: {
      'add': 'calculateTotals',
      'remove': 'calculateTotals'
    },
    childView: IndividualMonthlyInClaimsRowView,
    childViewContainer: 'tbody.table-data',
    childViewOptions: function(model) {
      var caseId = model.get('id');
      return {
        milestones: new Milestones(this.getOption('milestones').filter(
          function(m) {
            return m.get('case') === caseId;
          }
        ))
      };
    },
    setMinRows: function(minRows) {
      if (minRows >= this.minRows) {
        this.minRows = minRows;
      }

      this.ui.tablePad.empty();
      for (var i = 0; i < (this.minRows - this.collection.length); i += 1) {
        this.ui.tablePad.append('<tr><td colspan="5">&nbsp;</td></tr>');
      }
    },
    calculateTotals: function() {
      var that = this;
      var totalThisYear = 0;
      var year = this.getOption('year');
      this.collection.each(function(casefile) {
        var researchReport = that.getOption('milestones').where({
          'case': casefile.get('id'),
          'milestone_type': 6
        })[0];
        if (researchReport.get('created').getFullYear() === year) {
          totalThisYear += 1;
        }
      });
      this.ui.tableTotal.html('<tr><td>Totals</td><td></td><td></td><td>' +
        this.collection.length + '</td><td>' + totalThisYear + '</td></tr>');

      // set min rows again in case a render happened
      this.setMinRows(this.minRows);
    },
    serializeData: function() {
      return {
        year: this.getOption('year')
      };
    }
  }
);

module.exports = IndividualMonthlyInClaimsListView;
