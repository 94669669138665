'use strict';
var template = require('../../templates/cases/reminder-row.hbs');
var Reminder = require('../../models/cases/reminder');
var CaseManagerSelectView = require('../allocations/casemanager-select');

var ReminderRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click @ui.dismissBtn': 'dismissReminder',
    'click @ui.updateBtn': 'updateReminder',
    'click @ui.saveBtn': 'saveReminder',
    'click @ui.cancelSaveBtn': 'cancelSave',
    'click .popout': 'popoutCase'
  },
  ui: {
    dismissBtn: 'button.dismiss',
    updateBtn: 'button.update',
    saveBtn: 'button.save',
    cancelSaveBtn: 'button.cancel-save',
    message: 'input.reminder-message',
    dueDate: 'input.reminder-due-date'
  },
  popoutCase: function(e){
    e.preventDefault();
    TCAS.commands.execute('popoutCase', this.model.get('case'));

    return false;
  },
  dismissReminder: function(){
    var that = this;
    this.ui.dismissBtn.button('loading');
    this.model.save({status: Reminder.STATUSES.DISMISSED},
      {
        wait: true,
        success: function(){
          that.model.collection.fetch();
          that.render();
        }
      }
    );
  },
  updateReminder: function(){
    this.editing = true;
    this.render();
  },
  saveReminder: function(){
    //jshint camelcase:false
    var that = this;
    this.ui.saveBtn.button('loading');
    var date = this.ui.dueDate.val().split('/');
    var data = {
      due_date: date[2] + '-' + date[1] + '-' + date[0],
      message: that.ui.message.val()
    };
    if (this.getOption('showUserColumn')) {
      data.fao = this.faoSelect.getSelectedCaseManager();
    }

    this.model.save(data, {
      wait: true,
      success: function(){
        that.editing = false;
        that.render();
      }
    });
  },
  cancelSave: function(){
    this.editing = false;
    this.render();
  },
  serializeData: function(){
    var data = this.model.toJSON();
    data.editing = this.editing;
    data.showCasefile = this.getOption('showCasefile');
    data.isPending = this.model.get('status') === Reminder.STATUSES.PENDING;
    var actionLabels = ['Pending', 'Completed', 'Dismissed'];
    data.actionLabel = actionLabels[this.model.get('status')];
    data.showUserColumn = this.getOption('showUserColumn');

    return data;
  },
  onRender: function(){
    this.ui.dueDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });

    if (this.model.get('status') === Reminder.STATUSES.PENDING) {
      var dueDate = new Date(this.model.get('due_date')).getTime();
      var today = new Date().getTime();
      var day = 60000 * 60 * 24;
      this.$el.removeClass('danger warning info');
      this.$el.removeAttr('title');
      if (dueDate < today) {
        this.$el.addClass('danger');
        this.$el.attr('title', 'This is overdue or due today.');
      } else if (dueDate < (today + day)) {
        this.$el.addClass('warning');
        this.$el.attr('title', 'This is due within 24 hours.');
      } else if (dueDate < (today + (day * 7))) {
        this.$el.addClass('active');
        this.$el.attr('title', 'This is due this week.');
      }
    }

    if (this.getOption('showUserColumn') && this.editing) {
      this.faoRegion = new Backbone.Marionette.Region({
        el: this.$('.fao-select-container')
      });
      this.faoSelect = new CaseManagerSelectView({
        user: this.model.get('fao')
      });
      this.faoRegion.show(this.faoSelect);
    }
  }
});

module.exports = ReminderRowView;