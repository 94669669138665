'use strict';

var _ = require('lodash');
var template =
  require('../../../templates/reporting/company-activity-in-claims-table.hbs');

var CompanyActivityInClaimsTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var data = [];
    var inClaimsMilestones = this.getOption('inClaimsMilestones');
    var researchMilestones = this.getOption('researchMilestones');
    var userTargets = this.getOption('userTargets');
    var users = this.getOption('users');
    var year = this.getOption('year');

    // for each user compute the number of research cases and ytd
    users.forEach(function(user) {
      var userId = user.get('id');
      var userTarget = userTargets.find(function(target) {
        return target.get('user') === userId;
      });
      var userInClaimsMilestones = inClaimsMilestones.filter(
        function(milestone) {
          return milestone.get('user') === userId;
        }
      );
      var userResearchMilestones = researchMilestones.filter(
        function(milestone) {
          return milestone.get('user') === userId;
        }
      );
      var inClaimsMilestonesByMonth = [];
      var researchMilestonesByMonth = [];
      for (var i = 0; i < 12; i += 1) {
        inClaimsMilestonesByMonth[i] = [];
        researchMilestonesByMonth[i] = [];
      }

      // group case ids by month
      userInClaimsMilestones.forEach(function(milestone) {
        var created = new Date(milestone.get('created'));
        inClaimsMilestonesByMonth[created.getMonth()].push(milestone);
      });
      userResearchMilestones.forEach(function(milestone) {
        var created = new Date(milestone.get('created'));
        researchMilestonesByMonth[created.getMonth()].push(milestone);
      });

      var inClaimsByMonth = [];
      var inClaimsYTD = [];
      var researchYTD = [];
      var researchThisYear = [];
      var inClaimsFromResearchThisYear = [];
      var conversionRateYTD = [];

      var totalInClaims = 0;
      var totalResearch = 0;
      for (i = 0; i < 12; i += 1) {
        inClaimsByMonth[i] = inClaimsMilestonesByMonth[i].length;
        totalInClaims += inClaimsMilestonesByMonth[i].length;
        inClaimsYTD[i] = totalInClaims;

        totalResearch += researchMilestonesByMonth[i].length;
        researchYTD[i] = totalResearch;
        researchMilestonesByMonth[i].forEach(function(milestone) {
          researchThisYear.push(milestone);
        });

        // count how many in claims have research reports from this year
        inClaimsMilestonesByMonth[i].forEach(function(milestone) {
          var caseId = milestone.get('case');
          var matchedResearchThisYear = researchThisYear.find(function(milestone) {
            return milestone.get('case') === caseId;
          });
          if (matchedResearchThisYear) {
            inClaimsFromResearchThisYear.push(milestone);
          }
        });

        // work out conversion rate using YTD research so far and
        // in claims from research this year so far
        conversionRateYTD[i] = 0;
        if (inClaimsFromResearchThisYear.length > 0) {
          conversionRateYTD[i] =
            inClaimsFromResearchThisYear.length / researchYTD[i];
        }
      }

      var rowData = {
        user: user,
        inClaimsByMonth: inClaimsByMonth,
        inClaimsYTD: inClaimsYTD,
        conversionRateYTD: conversionRateYTD
      };
      data.push(rowData);
    });

    // inform parent view that data is ready for use in the summary table,
    // trigger(event, <array of data rows>, <section>, <field map>)
    this.trigger(
      'dataReady',
      data,
      'inClaims',
      { inClaimsByMonth: 'inClaimsByMonth', inClaimsYTD: 'inClaimsYTD' }
    );

    return data;
  }
});

module.exports = CompanyActivityInClaimsTableView;
