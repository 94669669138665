'use strict';
var Dropzone = require('dropzone');

var template = require('../../templates/survey/survey-responses.hbs');
var templateEdit = require('../../templates/survey/survey-response-form.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var Answer = require('../../models/survey/answer');
var SurveyScannedFiles = require('../../models/survey/surveyscannedfiles');
var Files = require('../../models/cases/files');
var User = require('../../models/users/user');
var CaseProspects = require('../../models/cases/caseprospects');
var QuestionResponseFormView = require('./question-response-form');
var FileListView = require('../cases/file-list');
var ConfirmModal = require('../confirm-modal');

var SurveyResponseFormView = Backbone.Marionette.CompositeView.extend({
  getTemplate: function() {
    return this.getOption('editable') ? templateEdit : template;
  },
  className: 'survey-form',
  ui: {
    saveBtn: 'button.save',
    emailSurvey: 'button.email-survey',
    editBtn: 'button.edit',
    alertContainer: '.alert-container',
    alertTop: '.alert-area',
    file: '.questionnaire-file',
    dropzonePreviews: '.dropzone-previews',
    filePreview: '.file-preview',
    fileHiddenInput: '.paper-questionnaire-hidden'
  },
  events: {
    'click @ui.emailSurvey': 'onClickEmailSurvey',
    'click @ui.editBtn': 'onClickEdit'
  },
  childView: QuestionResponseFormView,
  childViewContainer: '.questions-container',
  childViewOptions: function(question) {
    console.log('in child view options');
    return {
      question: question,
      answer: this.getOption('answers').find(function(answer) {
        return answer.get('question') === question.get('id');
      }),
      editable: this.getOption('editable')
    };
  },
  behaviors: {
    SubmitForm: {
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: function (self) {
        var rules = {};
        var showFile = self.view.getOption('showFile');
        self.view.collection.forEach(function (question) {
          rules['question-' + question.get('id')] = {
            required: showFile ? false : question.get('is_required')
          };
        });

        if (self.view.getOption('showDateForm')) {
          rules['created'] = {
            required: true
          };
        }

        if (showFile) {
          rules['paper-questionnaire'] = {
            required: true
          }
        }

        return {
          rules: rules,
          ignore: '.ignore'
        };
      }
    }
  },
  onClickEmailSurvey: function() {
    var that = this;
    var client = this.getOption('client');
    if (client.get('contact').get('email')) {
      var confirmDialog = new ConfirmModal({
        body: 'Are you sure you want to email a request to complete the Evaluation Questionnaire ' +
              'to this client?'});
      confirmDialog.render();
      this.listenTo(confirmDialog, 'confirmYes', function() {
        console.log(arguments);
        this.ui.emailSurvey.button('loading');
        $.get('/api/surveys/1/email/?client=' + client.get('id'), function() {
          that.ui.emailSurvey.button('reset');
          that.ui.alertTop.append(alertTemplate({
            type: 'success',
            message: 'The client will be sent an email to request completion of the evaluation questionnaire.'
          }));
        });
      });
    }
  },
  onClickEdit: function() {
    this.options.editable = !this.options.editable;
    this.render();
  },
  submitData: function (data) {
    var that = this;
    var showThanksPageOnSubmit = this.getOption('showThanksPageOnSubmit');
    this.ui.saveBtn.button('loading');
    var answers = this.getOption('answers');
    var questions = this.collection;
    var client = this.getOption('client');
    var surveyAccessToken = this.getOption('surveyAccessToken');
    var isCaseManager = !TCAS.session.hasGroup(User.GROUPS.CLIENT);
    if (!TCAS.session.isAuth()) {
      isCaseManager = false;
    }
    var date = data.date;
    var promises = questions.map(function(question) {
      var dataAnswer = data['question-' + question.get('id')];
      var answer = answers.find(function(a) {
        return a.get('question') === question.get('id');
      });
      if (!answer) {
        answer = new Answer({
          question: question.get('id'),
          client: client.get('id'),
          is_case_manager_submission: isCaseManager
        });
      }
      if (question.get('type') === 'text') {
        answer.set('answer_text', dataAnswer);
      } else {
        answer.set('answer_multiple_choice', parseInt(dataAnswer, 10));
      }
      if (surveyAccessToken) {
        answer.set('survey_access_token', surveyAccessToken);
      }
      if (date) {
        answer.set('date', date);
      }
      return answer.save({}, { wait: true });
    });

    $.when.apply($, promises).done(function() {
      if (showThanksPageOnSubmit) {
        TCAS.router.navigate('/survey/thanks', {trigger: true});
      } else {
        that.ui.alertContainer.append('<div class="alert alert-success">Thank you, your responses have been received.</div>');
        that.options.editable = false;
        that.render();
        $(document).scrollTop(0);
      }
    });
  },
  showScannedFile: function() {
    var that = this;
    var client = this.getOption('client');

    this.surveyScannedFiles.fetch().then(function () {
      if (that.surveyScannedFiles.length) {
        var fileId = that.surveyScannedFiles.at(0).get('file');
        var files = new Files();
        files.setFilters({
          id: fileId
        });
        files.fetch().then(function () {
          var fileRegion = new Backbone.Marionette.Region({
            el: that.ui.filePreview
          });
          var fileList = new FileListView({
            collection: files,
            clients: new CaseProspects([client]),
            isSimple: !TCAS.session.isAdmin()
          });
          fileRegion.show(fileList);
          if (files.length) {
            that.ui.fileHiddenInput.val(files.at(0).get('id'));
          }
        });
      }
    });
  },
  onRender: function() {
    var that = this;
    var clientId = this.getOption('client').get('id');

    if (this.ui.file.length) {
      if (this.getOption('editable')) {
        this.dropzone = new Dropzone(this.ui.file[0], {
          url: '/api/cases/' + this.getOption('casefileId') + '/files/',
          headers: {'X-CSRFToken': TCAS.csrfToken},
          previewsContainer: this.ui.dropzonePreviews[0],
          previewTemplate: templateDropzoneFile(),
          maxFilesize: 500,
          acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.eml,.msg,.ged',
          clickable: that.ui.file[0],
          accept: function (file, done) {
            var alphaNumericPattern = new RegExp(
              '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
            );
            // check for only alphanumeric characters
            if (alphaNumericPattern.test(file.name)) {
              done();
            } else {
              done('This file has unexpected characters, ' +
                'file names must use the correct file naming structure and ' +
                'only contain characters consisting of: ' +
                'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
                'apostrophes, commas or spaces.');
            }
          }
        });
        this.dropzone.on('success', function (file, response) {
          that.$(file.previewElement).remove();
          if (that.surveyScannedFiles.length) {
            that.surveyScannedFiles.at(0).destroy();
          }
          that.surveyScannedFiles.create({
            client: clientId,
            survey: that.getOption('surveyId'),
            file: response.id
          }, {
            wait: true,
            success: function () {
              that.showScannedFile();
            }
          });
        });
        this.dropzone.on('error', function (file) {
          that.$(file.previewElement).find('button').removeClass('hide');
        });
        this.dropzone.on('sending', function (file, xhr, formData) {
          // Show the total progress bar when upload starts
          that.$(file.previewElement).find('.progress').removeClass('hide');
          formData.append('is_casefile_direct_upload', 1);
          formData.append('clients_allowed', clientId);
        });
      }

      this.surveyScannedFiles = new SurveyScannedFiles();
      this.surveyScannedFiles.setFilters({
        survey: this.getOption('surveyId'),
        client: clientId
      });
      this.showScannedFile();

      this.$('[title]').tooltip();
    }
  },
  serializeData: function() {
    var date = new Date();
    this.getOption('answers').forEach(function(answer) {
      date = answer.get('date');
    });
    var isComplete = false;
    if (this.getOption('answers').length === this.collection.length) {
      isComplete = true;
    }
    return {
      showDateField: this.getOption('showDateField'),
      showFile: this.getOption('showFile'),
      showEmailSurveyButton: this.getOption('showEmailSurveyButton'),
      showEdit: this.getOption('showEdit'),
      client: this.getOption('client').toJSON(),
      answers: this.getOption('answers').toJSON(),
      date: date,
      editable: this.getOption('editable'),
      isComplete: isComplete
    };
  }
});

module.exports = SurveyResponseFormView;
