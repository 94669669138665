// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.location || (depth0 != null ? depth0.location : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"location","hash":{},"data":data}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner : depth0),{"name":"userFullName","hash":{},"data":data}));
},"7":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"panel panel-default\">\n    <div class=\"panel-heading\" data-toggle=\"collapse\"\n         data-target=\"#targets-collapse\">\n      <h4 class=\"panel-title\">Team Targets</h4>\n    </div>\n    <div id=\"targets-collapse\" class=\"panel-collapse collapse in\">\n      <div id=\"team-targets-panel\" class=\"panel-body\">\n\n      </div>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"panel panel-default\">\n  <div class=\"panel-heading\" data-toggle=\"collapse\"\n       data-target=\"#details-collapse\">\n    <h4 class=\"panel-title\">Team Details</h4>\n  </div>\n  <div id=\"details-collapse\" class=\"panel-collapse collapse in\">\n    <div id=\"details-panel\" class=\"panel-body\">\n      <form class=\"form-horizontal\" id=\"save-team-details\">\n        <div class=\"form-group\">\n          <label class=\"col-sm-2 control-label\">Team Name:</label>\n          <div class=\"col-sm-6\">\n            <input class=\"form-control input-sm\" name='name'\n                   "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/>\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-2\">Location:</label>\n          <div class=\"col-sm-6\">\n            <input class=\"form-control input-sm\" name='location'\n                   "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.location : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/>\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-2\">Created by:</label>\n          <div class=\"col-sm-6\">\n            <label class=\"control-label\"> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.owner : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</label>\n          </div>\n        </div>\n\n        <div class=\"details-alert-area\"></div>\n\n        <button class=\"btn btn-success save-details\">Save Details</button>\n      </form>\n    </div>\n  </div>\n</div>\n\n<div class=\"panel panel-default\">\n  <div class=\"panel-heading\" data-toggle=\"collapse\"\n       data-target=\"#members-collapse\">\n    <h4 class=\"panel-title\">Team Members</h4>\n  </div>\n  <div id=\"members-collapse\" class=\"panel-collapse collapse in\">\n    <div id=\"members-panel\" class=\"panel-body\">\n\n      <div class=\"form-group\">\n        <div class=\"pull-right\">\n          <div class=\"user-select-container\">\n            <input type=\"text\" class=\"form-control\" id=\"select-user\" name=\"select-user\"/>\n          </div>\n          <button class=\"btn btn-success class-md-2\" id=\"add-user\">Add to Team</button>\n        </div>\n        <div class=\"clearfix\"></div>\n      </div>\n\n      <div class=\"members-alert-area\"></div>\n      <!--<div class=\"clearfix\"></div>-->\n      <h5>Current Team</h5>\n      <div id=\"members\"></div>\n      <a href=\"#show-past-members\" data-toggle=\"collapse\" data-target=\"#past-members\"><h5>View previous team members</h5></a>\n      <div id=\"past-members\" class=\"collapse\"></div>\n    </div>\n  </div>\n</div>\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(alias1,"add_teamtarget",{"name":"hasPerm","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
