'use strict';
var CaseLoadRowView = require('./caseload-row');
var template = require('../../templates/caseload/caseload-list.hbs');

var CaseLoadTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'table',
  id: 'caseload',
  className: 'table table-hover table-condensed',
  childViewContainer: 'tbody',
  template: template,
  childView: CaseLoadRowView
});

module.exports = CaseLoadTableView;