'use strict';

var Contact = require('./contact');

var UserDetail = Backbone.Model.extend({
  urlRoot: '/api/userdetails/',
  parse: function(response){
    response.contact = new Contact(response.contact, { parse: true });
    return response;
  },
  toJSON: function() {
    var data = Backbone.Model.prototype.toJSON.call(this);
    if (this.has('contact')) {
      data.contact = this.get('contact').toJSON();
    }
    return data;
  }
});

module.exports = UserDetail;