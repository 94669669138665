// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <tbody class=\"table-add\">\n          <tr>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"date_made\" placeholder=\"DD/MM/YYYY\" value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.today : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"/>\n              </div>\n            </td>\n            <td></td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"payee\" placeholder=\"Payee name...\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.filterClient : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"number\" class=\"form-control input-sm\" name=\"amount\" placeholder=\"Amount...\" value=\"0.00\"/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"description\" placeholder=\"Description...\"/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"case_prospect\" placeholder=\"Client...\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.filterClient : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"text\" class=\"form-control input-sm\" name=\"invoice_number\" placeholder=\"Invoice number...\"/>\n              </div>\n            </td>\n            <td>\n              <div class=\"form-group\">\n                <input type=\"checkbox\" class=\"form-control\" name=\"is_international\" value=\"1\" />\n              </div>\n            </td>\n            <td>\n              <button type=\"submit\" class=\"btn btn-success btn-sm\" data-loading-text=\"Saving...\">Add</button>\n            </td>\n          </tr>\n        </tbody>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.filterClient : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.filterClient : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.last_name : stack1), depth0))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.filterClient : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" disabled=\"disabled\"";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <tbody class=\"table-total\">\n          <tr>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),0,{"name":"lt","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n          </tr>\n        </tbody>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"text-danger\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<form action=\""
    + container.escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"table-responsive\">\n    <table class=\"table table-condensed table-hover\">\n      <thead>\n        <tr>\n          <th class=\"col-xs-1\">Date made</th>\n          <th class=\"col-xs-1\">Modified</th>\n          <th class=\"col-xs-2\">Payee name</th>\n          <th class=\"col-xs-1\">Amount</th>\n          <th class=\"col-xs-3\">Description</th>\n          <th class=\"col-xs-2\">Client</th>\n          <th class=\"col-xs-1\">Invoice number</th>\n          <th class=\"col-xs-1\">International</th>\n          <th class=\"col-xs-1\"></th>\n        </tr>\n      </thead>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_clientpayment",{"name":"hasPerm","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <tbody class=\"table-items\"></tbody>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </table>\n  </div>\n</form>";
},"useData":true});
