// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/cases/my\" title=\"My Cases\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCaseActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-briefcase\"></i></a>\n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "class=\"active\"";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hasPerms","hash":{"perms":"[\"view_client_casefile\"]"},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <li>\n            <a href=\"/client/cases\" title=\"My Cases\" "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/client/cases",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-briefcase\"></i></a>\n          </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/requests\" title=\"Requests\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isRequestsActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-pencil-square-o\"></i></a>\n        </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/templates\" title=\"Templates\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isTemplatesActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-file-text\"></i></a>\n        </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/calendar\" title=\"Calendar\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCalendarActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-calendar\"></i></a>\n        </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/websites\" title=\"Useful Links\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isWebsitesActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-link\"></i></a>\n        </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/timetrack\" title=\"TimeTrack Entry\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isTimeTrackActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-clock-o\"></i></a>\n        </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/reporting/cases/individual/monthly\" title=\"Individual Cases Monthly Report\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isReportingActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-bar-chart-o\"></i></a>\n        </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/permissions\" title=\"Permissions\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAdminActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-wrench\"></i></a>\n        </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li>\n            <a href=\"/team\" title=\"Teams\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isTeamActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-group\"></i></a>\n        </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<ul id=\"app-menu-dropdown\" class=\"nav navbar-nav\">\n    <li>\n        <a class=\"sb-toggle-left\">\n            <i class=\"fa fa-fw fa-bars\"></i>\n        </a>\n    </li>\n\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"add_casefile\", \"view_all_casefile\", \"view_team_casefile\",\n    \"add_allocation\", \"view_reminder\"]"},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_request\"]"},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_templates\"]"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_calendar",{"name":"hasPerm","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_website",{"name":"hasPerm","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_timetrack\", \"add_timetrack\"]"},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_individual_report\", \"view_company_report\", \"view_pipeline_report\", \"view_case_report\", \"view_timetrack_report\", \"view_authenticationlog\"]"},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"change_category\", \"add_user\", \"view_user\", \"add_template\"]"},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"change_team\", \"view_permission\"]"},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n</ul>";
},"useData":true});
