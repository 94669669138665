'use strict';
var template = require('../../templates/teams/team-row.hbs');
var Team = require('../../models/users/team');

var TeamRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  model: Team,
  events: {
    'click': 'popoutTeam'
  },
  popoutTeam: function() {
    window.open('/team/' + this.model.get('id'),
      'Team detail '+this.model.get('name'),
      'width=1024,height=800,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,copyhistory=no,resizable=yes');
    return false;
  }
});

module.exports = TeamRowView;
