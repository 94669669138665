'use strict';

var template = require('../../templates/permissions/permissions.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var Permissions = require('../../models/users/permissions');
var PermissionsSelection = require('./permissions-selection');
var Groups = require('../../models/users/groups');

var PermissionsView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    'groups': '#groups',
    'select': '#select-group',
    alertsArea: '#alerts-area',
    filesPassword: '.files-password',
    filesPasswordForm: '.files-password-form',
    archivePassword: '.archive-password',
    archivePasswordForm: '.archive-password-form'
  },
  events: {
    'click @ui.select': 'selectGroup',
    'submit @ui.filesPasswordForm': 'onFilesPasswordSubmit',
    'submit @ui.archivePasswordForm': 'onArchivePasswordSubmit'
  },
  serializeData: function() {
    var data = {};
    data.groups = this.groups.toJSON();
    return data;
  },
  selectGroup: function() {
    var that = this;
    var group = this.ui.groups.val();

    this.groupPerms = new Permissions({
      group: group
    });
    var p1 = this.groupPerms.fetch();

    this.allPerms = new Permissions([], {});
    var p2 = this.allPerms.fetch();
    var $alertsArea = this.ui.alertsArea;

    $.when(p1, p2).done(function() {
      var permissionsSelector = new PermissionsSelection({
        groupPerms: that.groupPerms,
        allPerms: that.allPerms,
        groupId: group
      });
      that.permissionsArea.show(permissionsSelector);
      $alertsArea.html('');
    });
  },
  onFilesPasswordSubmit: function(){
    var that = this;
    if (this.ui.filesPasswordForm.valid()) {
      var password = this.ui.filesPassword.val();
      $.post('/api/files/set_files_password/', {files_password: password}, function(data, status){
        if (status == "success") {
          that.ui.filesPasswordForm.prepend('<div class="alert alert-success" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
            '<strong>Saved!</strong> The sensitive data password has been set. Sensitive data sessions will now require this password.</div>');
        }
      }).fail(function(xhr, textStatus){
        var error = JSON.parse(xhr.responseText);
        if (error.hasOwnProperty('detail')) {
          that.ui.filesPasswordForm.prepend('<div class="alert alert-danger" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
            '<strong>Error!</strong> '+error.detail+'</div>');
        }
      });
    }

    return false;
  },
  onArchivePasswordSubmit: function() {
    var that = this;
    if (this.ui.archivePasswordForm.valid()) {
      var password = this.ui.archivePassword.val();
      $.post('/api/cases/set_archive_password/', { archive_password: password }, function(data, status){
        if (status == 'success') {
          that.ui.archivePasswordForm.prepend('<div class="alert alert-success" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
            '<strong>Saved!</strong> The archived cases password has been set. Accessing archived cases will now require this password.</div>');
        }
      }).fail(function(xhr, textStatus){
        var error = JSON.parse(xhr.responseText);
        if (error.hasOwnProperty('detail')) {
          that.ui.archivePasswordForm.prepend('<div class="alert alert-danger" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
            '<strong>Error!</strong> '+error.detail+'</div>');
        }
      });
    }

    return false;
  },
  initialize: function() {
    var that = this;
    this.groups = new Groups();
    this.groups.fetch().then(function() {
      that.groups.remove(that.groups.findWhere({name: 'root'}));
      that.render();
    });
  },
  onRender: function() {
    this.permissionsArea = new Backbone.Marionette.Region({
      el: this.$('#permissions-area')[0]
    });

    var permissionsSelector = new PermissionsSelection({
      groupPerms: new Permissions([],{}),
      allPerms: new Permissions([], {})
    });
    this.permissionsArea.show(permissionsSelector);

    this.listenTo(TCAS.vent, 'permsSaved', function() {
      this.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'Permissions saved successfully.',
          type: 'success'
        }));
    });

    this.listenTo(TCAS.vent, 'permsError', function() {
      this.ui.alertsArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'An error occurred and permissions were not saved.',
          type: 'danger'
        }));
    });

    this.ui.filesPasswordForm.validate({
      /*jshint camelcase: false */
      rules: {
        files_password: {
          required: true,
          passwordComplexity: true,
          minlength: 8
        },
        files_password2: {
          required: true,
          equalTo: '#files-password'
        }
      },
      messages: {
        files_password2: {
          equalTo: 'Please enter the same password again.'
        }
      }
    });

    this.ui.archivePasswordForm.validate({
      /*jshint camelcase: false */
      rules: {
        archive_password: {
          required: true,
          passwordComplexity: true,
          minlength: 8
        },
        archive_password2: {
          required: true,
          equalTo: '#archive-password'
        }
      },
      messages: {
        archive_password2: {
          equalTo: 'Please enter the same password again.'
        }
      }
    });

    this.selectGroup();
  }
});

module.exports = PermissionsView;