// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-financials\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#financials\">\n        <h3 class=\"panel-title\">Revenue & Forecasting</h3>\n      </a>\n      <div class=\"accodion-body collapse\" id=\"financials\">\n        <div class=\"panel panel-default\">\n          <div class=\"panel-body\">\n            <div class=\"filter-casemanager-container pull-right\"></div>\n            <h4>Booking History</h4>\n            <div class=\"booking-history-container\"></div>\n          </div>\n          <div class=\"panel-body\">\n            <h4>Forecasting</h4>\n            <div class=\"forecasting-container\"></div>\n          </div>\n          <div class=\"panel-body\">\n            <h4>Pledging</h4>\n            <div class=\"pledging-container\"></div>\n          </div>\n        </div>\n\n        <div class=\"panel panel-default\">\n          <div class=\"panel-body\">\n            <h4>Client payments</h4>\n            <div class=\"client-payments-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-disbursements\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#costs\">\n        <h3 class=\"panel-title\">Income & Disbursements</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"costs\">\n        <div class=\"panel-body\">\n          <h4>Costs</h4>\n          <div class=\"costs-out-container\"></div>\n          <h4>Monies Received</h4>\n          <div class=\"costs-income-container\"></div>\n          <h4>Disbursements</h4>\n          <div class=\"costs-disbursement-container\"></div>\n        </div>\n      </div>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-milestones\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#milestones\">\n        <div class=\"row\">\n          <div class=\"col-sm-4\">\n            <h3 class=\"panel-title\">Milestones</h3>\n          </div>\n          <div class=\"col-sm-8\">\n            <div class=\"progress\">\n              <div class=\"progress-bar\" role=\"progressbar\" aria-valuenow=\"0\" aria-valuemin=\"0\" aria-valuemax=\"100\"\n                style=\"min-width: 2em;\">\n                0%\n              </div>\n            </div>\n          </div>\n        </div>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"milestones\">\n        <div class=\"panel-body\">\n          <div class=\"milestones-list-container\"></div>\n        </div>\n      </div>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-research\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#research\">\n        <h3 class=\"panel-title\">Research</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"research\">\n        <div class=\"panel-body research-list\"></div>\n      </div>\n    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-reminders\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#reminders\">\n        <h3 class=\"panel-title\">Reminders</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"reminders\">\n        <div class=\"panel-body reminders-container\"></div>\n      </div>\n    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-correspondence\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#correspondence\">\n        <h3 class=\"panel-title\">Correspondence</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"correspondence\">\n        <div class=\"panel-body\">\n          <h4>Email</h4>\n          <div class=\"correspondence-messages-container\"></div>\n          <h4>Letters</h4>\n          <div class=\"correspondence-container\"></div>\n        </div>\n      </div>\n    </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-files\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#files\">\n        <h3 class=\"panel-title files-title\">Files</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"files\">\n        <div class=\"panel-body\">\n          <div class=\"row\">\n            <div class=\"col-xs-12 col-sm-10 col-md-10\">\n              <div class=\"list-actions-bar\">\n                <div class=\"checkbox\"><label><input type=\"checkbox\" class=\"select-all-files\" /></label></div>\n                <button type=\"button\" class=\"btn btn-default btn-xs download-files\" disabled>Download</button>\n              </div>\n              <div class=\"list-group\">\n                <div class=\"file-list-container\"></div>\n                <div class=\"dropzone-previews\"></div>\n                <button type=\"button\" class=\"btn btn-default btn-block add-file list-group-item text-center\"><i\n                    class=\"fa fa-plus fa-lg\"></i> Drag and drop a file or click</button>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-history\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#history\">\n        <h3 class=\"panel-title\">Case History</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"history\">\n        <div class=\"panel-body history-list-container\"></div>\n      </div>\n    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"panel panel-casefile casefile-allocation\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#allocation\">\n        <h3 class=\"panel-title\">Allocation Details</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"allocation\">\n        <div class=\"panel-body allocation-list\"></div>\n      </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"milestone-btn-container "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></div>\n<div class=\"clearfix\"></div>\n<div class=\"casefile-detail\">\n  <div class=\"panel-group\">\n    <div class=\"panel panel-casefile casefile-details\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#details\">\n        <h3 class=\"panel-title\">Case Details</h3>\n      </a>\n      <div class=\"accordion-body panel-body collapse in\" id=\"details\">\n        <div class=\"details-list\"></div>\n      </div>\n    </div>\n\n    <div class=\"panel panel-casefile panel-clients\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#clients\">\n        <h3 class=\"panel-title clients-title\">People</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"clients\">\n        <div class=\"panel-body\">\n          <h4>Family & Beneficiaries</h4>\n          <div class=\"client-type-filter\">\n            <div class=\"checkbox-inline\"><label><input type=\"checkbox\" name=\"type\" value=\"client\" checked=\"checked\" />\n                "
    + alias3((helpers.prospectTypeLabel || (depth0 && depth0.prospectTypeLabel) || alias2).call(alias1,"client",{"name":"prospectTypeLabel","hash":{},"data":data}))
    + " <span class=\"prospect-count-client\"></span></label></div>\n            <div class=\"checkbox-inline\"><label><input type=\"checkbox\" name=\"type\" value=\"beneficiary\"\n                  checked=\"checked\" /> "
    + alias3((helpers.prospectTypeLabel || (depth0 && depth0.prospectTypeLabel) || alias2).call(alias1,"beneficiary",{"name":"prospectTypeLabel","hash":{},"data":data}))
    + " <span\n                  class=\"prospect-count-beneficiary\"></span></label></div>\n            <div class=\"checkbox-inline\"><label><input type=\"checkbox\" name=\"type\" value=\"ancestor\" />\n                "
    + alias3((helpers.prospectTypeLabel || (depth0 && depth0.prospectTypeLabel) || alias2).call(alias1,"ancestor",{"name":"prospectTypeLabel","hash":{},"data":data}))
    + " <span class=\"prospect-count-ancestor\"></span></label></div>\n          </div>\n          <div class=\"client-list-container\"></div>\n          <h4>Contacts & Associates</h4>\n          <div class=\"contact-list-container\"></div>\n        </div>\n      </div>\n    </div>\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_clientpayment",{"name":"hasPerm","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_cost",{"name":"hasPerm","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_milestone",{"name":"hasPerm","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"panel panel-casefile casefile-notes\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#notes\">\n        <h3 class=\"panel-title\">Notes</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"notes\">\n        <div class=\"panel-body message-list-container\"></div>\n      </div>\n    </div>\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_research",{"name":"hasPerm","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_reminder",{"name":"hasPerm","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"panel panel-casefile casefile-enquiries\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#enquiries\">\n        <h3 class=\"panel-title\">Estate Financials</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"enquiries\">\n        <div class=\"panel-body enquiries-container\"></div>\n      </div>\n    </div>\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_correspondence",{"name":"hasPerm","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_files",{"name":"hasPerm","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_events",{"name":"hasPerm","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_allocation",{"name":"hasPerm","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>";
},"useData":true});
