'use strict';

var moment = require('moment');
var TimetrackPieChartView = require('./timetrack-pie-chart');

var TimetrackTaskPieChartsView = Backbone.Marionette.CollectionView.extend({
  childView: TimetrackPieChartView,
  childViewOptions: function(model) {
    var userIds = this.getOption('userIds');
    var teamIds = this.getOption('teamIds');
    var start = moment(this.getOption('start'));
    var end = moment(this.getOption('end'));
    var clickUrl = '/timetrack/report/activity/' + model.get('activity') + '/' +
      start.format('YYYY-MM-DD') + '_' + end.format('YYYY-MM-DD');
    if (teamIds.length) {
      clickUrl += '/teams/' + teamIds.join(',');
    }
    if (userIds.length) {
      clickUrl += '/users/' + userIds.join(',');
    }

    return {
      title: model.get('name'),
      data: model.get('tasks').map(function(task) {
        return task.minutes;
      }),
      labels: model.get('tasks').map(function(task) {
        return task.name;
      }),
      clickUrl: clickUrl
    }
  }
});

module.exports = TimetrackTaskPieChartsView;
