'use strict';
var MilestoneType = require('./milestonetype');
var BaseCollection = require('../base-collection');

var MilestoneTypes = BaseCollection.extend({
  urlRoot: '/api/milestonetypes/',
  model: MilestoneType,
  comparator: 'ordering_id'
});

module.exports = MilestoneTypes;