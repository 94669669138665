'use strict';
var template = require('../templates/main-body-layout.hbs');
var Handlebars = require('hbsfy/runtime');

var MainBodyLayout = Backbone.Marionette.LayoutView.extend({
  initialize: function(options) {
    this.title = options.title;
  },
  className: 'row',
  template: template,
  regions: {
    content: '#content'
  },
  ui: {
    title: 'h1'
  },
  setTitle: function(title) {
    this.ui.title.html(Handlebars.Utils.escapeExpression(title));
  },
  serializeData: function() {
    var data = {
      'title': this.title,
    };
    if(this.getOption('subtitle')) {
      data.subtitle = this.getOption('subtitle');
    }
    return data;
  },
  onShow: function(){
    if (this.getOption('contentView')) {
      this.content.show(this.getOption('contentView'));
    }
  }
});

module.exports = MainBodyLayout;
