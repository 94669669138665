// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answer_text : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <tr>\n      <td>"
    + alias3((helpers.caseField || (depth0 && depth0.caseField) || alias2).call(alias1,(depth0 != null ? depth0.casefile : depth0),"ref",{"name":"caseField","hash":{},"data":data}))
    + " - "
    + alias3(((helper = (helper = helpers.answer_text || (depth0 != null ? depth0.answer_text : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"answer_text","hash":{},"data":data}) : helper)))
    + "</td>\n    </tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<h3>Question "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.number : stack1), depth0))
    + ": "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.question : stack1), depth0))
    + "</h3>\n\n<table class=\"table table-hover table-condensed\">\n  <thead>\n    <th>Answers</th>\n  </thead>\n  <tbody>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tbody>\n</table>";
},"useData":true});
