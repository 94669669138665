'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-concluded-list.hbs');
var IndividualMonthlyConcludedRowView = require('./individual-monthly-concluded-row');
var BookingRecords = require('../../../models/reporting/bookingrecords');
var Util = require('../../../util');

var IndividualMonthlyConcludedListView =
  Backbone.Marionette.CompositeView.extend({
    template: template,
    ui: {
      tablePad: '.table-pad',
      tableTotal: '.table-total'
    },
    initialize: function() {
      this.minRows = 0;
      this.listenTo(this.getOption('bookingRecords'), 'sync', this.render);
      this.listenTo(this.getOption('concludedMilestones', 'sync', this.render));
      this.listenTo(this.getOption('bookingRecords'), 'sync',
        this.calculateTotals);
      this.listenTo(this.getOption('concludedMilestones'), 'sync',
        this.calculateTotals);
    },
    collectionEvents: {
      'add': 'calculateTotals',
      'remove': 'calculateTotals'
    },
    childView: IndividualMonthlyConcludedRowView,
    childViewContainer: 'tbody.table-data',
    childViewOptions: function(model) {
      var caseId = model.get('id');
      return {
        concludedMilestone: this.getOption('concludedMilestones').find(
          function(m) {
            return m.get('case') === caseId;
          }
        ),
        bookingRecords: new BookingRecords(
          this.getOption('bookingRecords').filter(function(bookingRecord) {
            return bookingRecord.get('case') === caseId;
          })
        )
      };
    },
    setMinRows: function(minRows) {
      if (minRows >= this.minRows) {
        this.minRows = minRows;
      }

      this.ui.tablePad.empty();
      for (var i = 0; i < (this.minRows - this.collection.length); i += 1) {
        this.ui.tablePad.append('<tr><td colspan="4">&nbsp;</td></tr>');
      }
    },
    calculateTotals: function() {
      var that = this;
      var totalRevenue = 0;
      this.collection.each(function(casefile) {
        that.getOption('bookingRecords').each(function(bookingRecord) {
          if (bookingRecord.get('case') === casefile.get('id')) {
            totalRevenue += bookingRecord.get('revenue');
          }
        });
      });
      // display the totals
      this.ui.tableTotal.html('<tr>' +
        '<td>Totals</td><td></td><td ' +
        (totalRevenue < 0 ? 'class="text-danger"' : '') + '>' +
        Util.formatCurrency(totalRevenue, true) + '</td><td>' +
        this.collection.length + '</td></tr>');

      // also set min rows again in case a render happened
      this.setMinRows(this.minRows);
    }
  }
);

module.exports = IndividualMonthlyConcludedListView;
