'use strict';
var HistoryList = require('./casefile-history-list');
var template = require('../../templates/history/casefile-history.hbs');
var Event = require('../../models/cases/event');

var CaseFileHistoryView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    'addEntry': '#add-entry'
  },
  events: {
    'click @ui.addEntry': 'addHistory'
  },
  onRender: function() {
    this.listHistory = new Backbone.Marionette.Region({
      el: this.$('#history-list')[0]
    });

    var historyList = new HistoryList({
      collection: this.options.history
    });
    this.listHistory.show(historyList);
  },
  addHistory: function() {
    var that = this;

    var event = new Event({
      event: 1,
      user: TCAS.session.get('id'),
      case: that.options.casefile.get('id'),
      details: ''
    });

    that.listHistory.currentView.collection.add(event);
    that.listHistory.currentView.render();

    event.save({}, {
      success: function() {
        that.listHistory.currentView.collection.add(event);
        that.listHistory.currentView.render();
        console.log('success');
      },
      error: function() {
        console.log('error');
      }
    });
  }
});

module.exports = CaseFileHistoryView;