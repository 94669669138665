// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.user || (depth0 != null ? depth0.user : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user","hash":{},"data":data}) : helper)))
    + "\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"list-casefiles\" class=\"table-responsive\"></div>\n\n<div class=\"alerts-area\"></div>\n<div class=\"clearfix\"></div>\n\n<div class=\"form-group col-md-6 col-sm-8\">\n  <div class=\"row\">\n    <div class=\"col-md-3 col-sm-3 col-xs-3\">\n      <label for=\"allocate\" class=\"control-label pull-right\">Allocate to: </label>\n    </div>\n    <div class=\"col-md-6 col-sm-6 col-xs-9\">\n      <input type=\"text\" class=\"form-control\" id=\"allocate\" name=\"allocate\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.user : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n    </div>\n    <div class=\"col-md-3 col-sm-3 col-xs-6\">\n      <button class=\"btn btn-primary\" id=\"allocate-cases\">Allocate</button>\n    </div>\n  </div>\n</div>\n<button class=\"btn btn-default show-all-cases pull-right\">Show All Unallocated Cases</button>\n<div class=\"clearfix\"></div>\n\n<hr />\n\n<div class=\"panel panel-default\">\n  <div class=\"panel-heading collapsed\" data-toggle=\"collapse\" data-target=\"#rel-caseload\" id=\"rel-caseload-title\">\n    Caseload for selected cases</div>\n  <div class=\"panel-collapse collapse\" id=\"rel-caseload\">\n    <div id=\"user-caseload\" class=\"table-responsive\"></div>\n  </div>\n</div>\n\n<div class=\"panel panel-default\">\n  <div class=\"panel-heading collapsed\" data-toggle=\"collapse\" data-target=\"#full-caseload\" id=\"full-caseload-title\">Full\n    Caseload</div>\n  <div class=\"panel-collapse collapse\" id=\"full-caseload\">\n    <div id=\"user-full-caseload\" class=\"table-responsive\"></div>\n  </div>\n</div>\n\n<div class=\"modal-entry\"></div>\n<div class=\"spinner-anchor\"></div>";
},"useData":true});
