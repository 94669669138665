'use strict';
var template = require('../templates/no-permission.hbs');

var NoPermissionView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function(){
    return { route: this.getOption('route') };
  }
});

module.exports = NoPermissionView;