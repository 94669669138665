'use strict';

var template = require('../../templates/financials/financialserviceenquiries-row.hbs');

var FinancialServiceEnquiriesRowView = Backbone.Marionette.ItemView.extend({
  className: 'financialserviceenquiries-row',
  tagName: 'tr',
  template: template,
  ui: {
    name: '.name',
    notApplicable: '.not-applicable',
    dateSent: '.date-sent',
    isResponded: '.is-responded',
    isComplete: '.is-complete',
    notes: '.notes',
    saveBtn: '.save',
  },
  events: {
    'click @ui.saveBtn': 'onClickSave',
  },
  onClickSave: function() {
    var that = this;
    this.ui.saveBtn.button('loading');
    var data = {
      financial_service_name: this.ui.name.val(),
      is_not_applicable: this.ui.notApplicable.is(':checked'),
      is_enquiry_responded: this.ui.isResponded.is(':checked'),
      is_enquiry_complete: this.ui.isComplete.is(':checked'),
      notes: this.ui.notes.val(),
      casefile: this.getOption('casefile').get('id'),
    };
    var date = this.ui.dateSent.val().split('/');
    data.date_sent = date[2] + '-' + date[1] + '-' + date[0];

    this.model.save(data, {
      wait: true,
      success: function(model) {
        that.ui.saveBtn.button('reset');
        that.trigger('save', that.model);
        if (model.get('is_not_applicable')) {
          that.$el.addClass('hidden');
        }
      },
    });
  },
  onRender: function() {
    this.ui.dateSent.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.isCustom = this.getOption('isCustom');
    return data;
  },
});

module.exports = FinancialServiceEnquiriesRowView;
