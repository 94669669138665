'use strict';

var template = require('../../templates/emails/email-list.hbs');
var EmailRowView = require('./email-row');

var EmailListView = Backbone.Marionette.CompositeView.extend({
  className: 'email-row',
  template: template,
  childViewContainer: 'tbody',
  childView: EmailRowView,
  childEvents: {
    'show:email': 'onShowEmail'
  },
  onShowEmail: function(view) {
    this.$('tr').removeClass('active');
    view.$el.addClass('active');
    this.trigger('show:email', view.model);
  }
});

module.exports = EmailListView;
