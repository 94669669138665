'use strict';

var Handlebars = require('hbsfy/runtime');
var moment = require('moment');
var helpers = require('handlebars-helpers');
var Casefile = require('./models/cases/casefile');
var CaseProspect = require('./models/cases/caseprospect');
var TimeTrack = require('./models/timetrack/timetrack');
var User = require('./models/users/user');
var Event = require('./models/cases/event');
var Util = require('./util');

// register handlebars-helpers 'comparison' and 'math' categories
helpers.comparison({
  handlebars: Handlebars,
});
helpers.math({
  handlebars: Handlebars,
});
helpers.object({
  handlebars: Handlebars,
});

Handlebars.registerHelper('categoryField', function(categoryId, field) {
  if (categoryId) {
    categoryId = parseInt(categoryId, 10);
    var category = TCAS.categories.find(function(category) {
      return category.get('id') === categoryId;
    });

    field = Handlebars.Utils.escapeExpression(field);
    var s = '<span class="category-field" data-category-field="' + field + '"';
    s += ' data-category-id="' + categoryId + '">';
    if (category && category.has(field)) {
      s += category.get(field);
    }
    s += '</span>';
    return new Handlebars.SafeString(s);
  } else {
    return '';
  }
});

Handlebars.registerHelper('categoryGroup', function(categoryGroupId) {
  categoryGroupId = parseInt(categoryGroupId, 10);
  var categoryGroup = TCAS.categoryGroups.find(function(group) {
    return group.get('id') === categoryGroupId;
  });

  if (categoryGroup) {
    return categoryGroup.get('name');
  }

  return '';
});

Handlebars.registerHelper('caseField', function(caseId, field) {
  if (caseId) {
    caseId = parseInt(caseId);
    field = Handlebars.Utils.escapeExpression(field);

    var casefile = TCAS.casefiles.get(parseInt(caseId));
    var s = '<span class="casefile-field" data-casefile-field="' + field + '"';
    s += ' data-casefile-id="' + caseId + '">';
    if (casefile && casefile.has(field)) {
      s += casefile.get(field);
    } else {
      if (!casefile) {
        casefile = new Casefile({ id: caseId });
        casefile.fetch().then(function() {
          $('.casefile-field[data-casefile-id="' + casefile.get('id') + '"]').html(
            casefile.get(field)
          );
        });
        TCAS.casefiles.add(casefile);
      }
    }
    s += '</span>';
    return new Handlebars.SafeString(s);
  } else {
    return '';
  }
});

Handlebars.registerHelper('caseUrl', function(caseId) {
  return '/cases/' + caseId;
});

Handlebars.registerHelper('caseStatusName', function(statusId, options) {
  if ('faSent' in options.hash) {
    if (options.hash.faSent && statusId === Casefile.STATUS_IDS.RESEARCH) {
      return 'FA Sent';
    }
  }

  if ('isArchived' in options.hash && options.hash.isArchived) {
    return 'Archived';
  } else {
    return Casefile.STATUS_NAMES[statusId];
  }
});

Handlebars.registerHelper('timeTrackActivity', function(activityId) {
  return TimeTrack.ACTIVITIES[activityId];
});

Handlebars.registerHelper('timeTrackTask', function(taskId) {
  return TimeTrack.TASKS[taskId];
});

Handlebars.registerHelper('caseStatusColour', function(
  statusId,
  faSent,
  disclosureSent,
  isArchived
) {
  switch (statusId) {
    case 0:
      return 'unallocated';
    case 1:
      if (faSent) {
        return 'fa-sent';
      }
      return 'research';
    case 2:
      if (disclosureSent) {
        return 'disclosure-sent';
      }
      return 'in-claims';
    case 3:
      return 'abandoned';
    case 4:
      return isArchived ? 'archived' : 'concluded';
  }
});

Handlebars.registerHelper('userFullName', function(userId) {
  if (userId) {
    var user = TCAS.users.find(function(user) {
      return user.get('id') === userId;
    });
    var s = '<span class="user-fullname" data-user-id="' + userId + '">';
    if (user) {
      s += Handlebars.Utils.escapeExpression(user.getFullName());
      if (!user.get('is_active')) {
        s += ' <span class="badge">Inactive</span>';
      }
    } else {
      _.defer(function() {
        TCAS.reqres.request('user', userId).then(function(user) {
          var $userName = $('.user-fullname[data-user-id="' + user.get('id') + '"]');
          var s = Handlebars.Utils.escapeExpression(user.getFullName());
          if (!user.get('is_active')) {
            s += ' <span class="badge">Inactive</span>';
          }
          $userName.html(s);
        });
      });
    }
    s += '</span>';

    return new Handlebars.SafeString(s);
  }
  return '';
});
Handlebars.registerHelper('userFullNameOnly', function(userId) {
  if (userId) {
    var user = TCAS.users.find(function(user) {
      return user.get('id') === userId;
    });
    var s = '';
    if (user) {
      s = Handlebars.Utils.escapeExpression(user.getFullName());
    }
    return new Handlebars.SafeString(s);
  }
  return '';
});

Handlebars.registerHelper('userContactId', function(userId) {
  if (userId) {
    var user = TCAS.users.find(function(user) {
      return user.get('id') === userId;
    });
    if (user) {
      return user.get('userdetail').get('contact').id;
    }
    return null;
  }
  return null;
});

Handlebars.registerHelper('prospectFullName', function(caseProspectId, showMiddleName) {
  if (typeof showMiddleName !== 'boolean') {
    showMiddleName = false;
  }
  if (caseProspectId) {
    var prospect = TCAS.prospects.find(function(prospect) {
      return prospect.get('id') === caseProspectId;
    });
    var s = '<span class="prospect-fullname" data-prospect-id="' + caseProspectId + '">';
    if (prospect) {
      var contact = prospect.get('contact');
      var name = contact.get('first_name');
      if (showMiddleName && contact.get('middle_name')) {
        name += ' ' + contact.get('middle_name');
      }
      name += ' ' + contact.get('last_name').toUpperCase();

      if (contact.get('surname_at_birth')) {
        name += ' (' + contact.get('surname_at_birth').toUpperCase() + ')';
      }

      s += Handlebars.Utils.escapeExpression(name);
    } else {
      _.defer(function() {
        TCAS.reqres.request('prospect', caseProspectId).then(function(prospect) {
          var $prospectName = $(
            '.prospect-fullname[data-prospect-id="' + prospect.get('id') + '"]'
          );

          contact = prospect.get('contact');
          name = contact.getFullNameWithSurnameAtBirth(showMiddleName);
          $prospectName.html(name);

          s += Handlebars.Utils.escapeExpression(name);
        });
      });
    }
    s += '</span>';

    return new Handlebars.SafeString(s);
  }
  return '';
});

Handlebars.registerHelper('prospectFullNameOnly', function(prospectId, showMiddleName) {
  if (typeof showMiddleName !== 'boolean') {
    showMiddleName = false;
  }
  var prospect = TCAS.prospects.find(function(prospect) {
    return prospect.get('id') === prospectId;
  });
  var s = '';
  if (prospect) {
    s = Handlebars.Utils.escapeExpression(prospect.getFullName(showMiddleName));
  }
  return new Handlebars.SafeString(s);
});

Handlebars.registerHelper('prospectContactId', function(prospectId) {
  var prospect = TCAS.prospects.find(function(prospect) {
    return prospect.get('id') === prospectId;
  });
  if (prospect) {
    return prospect.get('contact').get('id');
  }
  return null;
});

Handlebars.registerHelper('prospectRoleLabel', function(prospectId) {
  var prospect = TCAS.prospects.find(function(prospect) {
    return prospect.get('id') === prospectId;
  });
  var s = '';
  if (prospect) {
    if (prospect.isBeneficiary()) {
      s = '<span class="label label-primary">Beneficiary</span>';
    } else if (prospect.isClient()) {
      s = '<span class="label label-success">Client</span>';
    } else if (prospect.isAncestor()) {
      s = '<span class="label label-info">Ancestor</span>';
    } else {
      s = '<span class="label label-default">Contact</span>';
    }
    if (prospect.get('is_missing')) {
      s = s
        .replace('label-primary', 'label-danger')
        .replace('label-success', 'label-danger')
        .replace('label-info', 'label-danger');
    }
  }

  return new Handlebars.SafeString(s);
});

Handlebars.registerHelper('prospectObjectRoleLabel', function(prospect) {
  var s = '';
  if (prospect) {
    if (prospect.type === CaseProspect.TYPES.accounts) {
      s = '<span class="label label-accounts">Accounts</span>';
    } else if (prospect.type === CaseProspect.TYPES.beneficiary) {
      s = '<span class="label label-primary">Beneficiary</span>';
    } else if (prospect.type === CaseProspect.TYPES.client) {
      s =
        '<span class="label label-success" ' +
        (prospect.has_access ? 'title="This client has access to view the case"' : '') +
        '>Client' +
        (prospect.has_access ? ' <i class="fa fa-user"></i>' : '') +
        '</span>';
    } else if (prospect.type === CaseProspect.TYPES.ancestor) {
      s = '<span class="label label-info">Ancestor</span>';
    } else {
      s = '<span class="label label-default">Contact</span>';
    }
    if (prospect.is_missing) {
      s = s
        .replace('label-primary', 'label-danger')
        .replace('label-success', 'label-danger')
        .replace('label-info', 'label-danger');
    } else if (prospect.is_deceased_asset_holder) {
      s = s
        .replace('label-primary', 'label-warning')
        .replace('label-success', 'label-warning')
        .replace('label-info', 'label-warning');
    }
    if (prospect.user && prospect.user.userdetail.is_locked) {
      s += ' <span class="label label-default locked">Locked</span>';
    }
  }

  return new Handlebars.SafeString(s);
});

Handlebars.registerHelper('prospectTypeLabel', function(prospectType) {
  var s = '';

  if (prospectType === CaseProspect.TYPES.accounts) {
    s = '<span class="label label-accounts">Accounts</span>';
  } else if (prospectType === CaseProspect.TYPES.beneficiary) {
    s = '<span class="label label-primary">Beneficiary</span>';
  } else if (prospectType === CaseProspect.TYPES.client) {
    s = '<span class="label label-success">Client</span>';
  } else if (prospectType === CaseProspect.TYPES.ancestor) {
    s = '<span class="label label-info">Ancestor</span>';
  } else {
    s = '<span class="label label-default">Contact</span>';
  }

  return new Handlebars.SafeString(s);
});

Handlebars.registerHelper('contactFullName', function(contactId) {
  if (contactId) {
    contactId = parseInt(contactId, 10);
    var contact = TCAS.contacts.find(function(contact) {
      return contact.get('id') === contactId;
    });
    var s = '<span class="contact-fullname" data-contact-id="' + contactId + '">';
    if (contact) {
      s += Handlebars.Utils.escapeExpression(contact.getFullName());
    } else {
      _.defer(function() {
        TCAS.reqres.request('contact', contactId).then(function(contact) {
          var $contactFullName = $(
            '.contact-fullname[data-contact-id="' + contact.get('id') + '"]'
          );
          $contactFullName.html(contact.getFullName());
        });
      });
    }
    s += '</span>';

    return new Handlebars.SafeString(s);
  }
  return '';
});

Handlebars.registerHelper('hasPerm', function(permission, options) {
  var $el = $('<div></div>');

  if (TCAS.session.hasPerm(permission)) {
    $el.html(options.fn(this));
  } else {
    $el.html(options.inverse(this));
  }

  return $el.html();
});

Handlebars.registerHelper('hasPerms', function(options) {
  var $el = $('<div></div>');
  var and = true;
  var or = false;

  var permissions = JSON.parse(options.hash.perms);

  _.forEach(permissions, function(permission) {
    if (TCAS.session.hasPerm(permission)) {
      or = true;
    } else {
      and = false;
    }
  });

  if (or) {
    $el.html(options.fn(this));
  } else {
    $el.html(options.inverse(this));
  }

  return $el.html();
});

Handlebars.registerHelper('ifContains', function(collection, item, options) {
  if (collection && _(collection).contains(item)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('ifStartsWith', function(needle, haystack, options) {
  var match = false;
  if (needle instanceof Array) {
    for (var i = 0; i < needle.length; i += 1) {
      if (haystack.indexOf(needle[i])) {
        match = true;
        break;
      }
    }
  } else {
    match = haystack.indexOf(needle) === 0;
  }
  if (match) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('ifEqual', function(a, b, options) {
  if (a == b) {
    return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('contactAddress', function(contact) {
  // jshint camelcase:false
  var s = '';
  if (contact) {
    s += '<address>';
    var contactFields = ['address1', 'address2', 'city', 'county', 'post_code', 'country'];
    _(contactFields).each(function(field, index) {
      if (contact[field]) {
        s += Handlebars.Utils.escapeExpression(contact[field]);
        if (index !== contactFields.length - 1) {
          s += '<br/>';
        }
      }
    });
    if (contact.legacy_address) {
      s += '<br/><br/>' + Handlebars.Utils.escapeExpression(contact.legacy_address);
    }
    s += '</address>';
  }
  return new Handlebars.SafeString(s);
});

Handlebars.registerHelper('contactClipboard', function(contact) {
  var nameFields = ['title', 'first_name', 'last_name'];
  var addressFields = ['address1', 'address2', 'city', 'county', 'post_code', 'country'];
  var s = '';
  _(nameFields).each(function(field) {
    if (contact[field]) {
      s += ' ' + contact[field];
    }
  });

  s = s.trim();
  _(addressFields).each(function(field) {
    if (contact[field]) {
      s += '\n' + contact[field];
    }
  });

  return s;
});

Handlebars.registerHelper('selectOption', function(selectedOption, options) {
  var $el = $('<select />').html(options.fn(this));
  $el.find('[value=' + selectedOption + ']').attr('selected', 'selected');
  return $el.html();
});

Handlebars.registerHelper('selectCheckbox', function(values, options) {
  var $el = $('<div></div>').html(options.fn(this));
  $el.find('input[type="checkbox"]').each(function() {
    if (_(values).contains($(this).val())) {
      $(this).attr('checked', 'checked');
    } else {
      $(this).removeAttr('checked');
    }
  });

  return $el.html();
});

Handlebars.registerHelper('selectRadio', function(values, options) {
  var $el = $('<div></div>').html(options.fn(this));
  $el.find('input[type="radio"]').each(function() {
    if (_(values).contains($(this).val())) {
      $(this).attr('checked', 'checked');
    } else {
      $(this).removeAttr('checked');
    }
  });

  return $el.html();
});

Handlebars.registerHelper('formatDate', function(date, format) {
  return moment(date).format(format);
});

Handlebars.registerHelper('formatCurrency', function(value) {
  return Util.formatCurrency(value);
});

Handlebars.registerHelper('formatSimpleCurrency', function(value) {
  return new Handlebars.SafeString(Util.formatCurrency(value, true));
});

Handlebars.registerHelper('formatPercent', function(value) {
  return Math.round(value * 100) + '%';
});

Handlebars.registerHelper('percentage', function(numerator, denominator) {
  return Math.round((numerator / denominator) * 100) + '%';
});

Handlebars.registerHelper('fixedDecimalPlaces', function(number, dp) {
  return number.toFixed(dp);
});

Handlebars.registerHelper('currencyTableCell', function(value, className, showDoubleDigits) {
  var classes = [];
  if (className && typeof className === 'string') {
    classes.push(className);
  }

  if (!(showDoubleDigits && typeof showDoubleDigits === 'boolean')) {
    showDoubleDigits = false;
  }

  if (value < 0) {
    classes.push('text-danger');
  }

  var s = '<td ';
  s += 'data-value="' + value + '"';
  if (classes.length > 0) {
    s += ' class="' + classes.join(' ') + '"';
  }
  s += '>' + Util.formatCurrency(value, !showDoubleDigits) + '</td>';

  return new Handlebars.SafeString(s);
});

Handlebars.registerHelper('leadingZeros', function(numDigits, number) {
  var numberString = '' + number;
  var s = '';
  for (var i = 0; i < numDigits - numberString.length; i++) {
    s += '0';
  }
  s += number;

  return s;
});

Handlebars.registerHelper('dateToWeek', function(dateString) {
  var date = new Date(dateString);
  var dayOfWeek = date.getDay();
  var dayOfMonth = date.getDate();
  var fridayOfMonth = dayOfMonth + (5 - dayOfWeek);
  var weekNum = Math.ceil(fridayOfMonth / 7);
  if (weekNum >= 5) {
    weekNum = 'End';
  }
  return weekNum;
});

Handlebars.registerHelper('flagNotInWeek', function(dateString, tooltip) {
  var s = '';
  var now = moment();
  var fridayMoment = moment()
    .day(-2)
    .startOf('day');
  if (now.day() >= 5) {
    fridayMoment = moment()
      .day(5)
      .startOf('day');
  }
  var saturdayMoment = moment(fridayMoment).add(1, 'days');
  var date = moment(dateString);
  if (date.isBefore(saturdayMoment)) {
    s =
      '<i class="fa fa-exclamation-triangle text-danger" ' +
      'data-placement="right" title="' +
      tooltip +
      '"></i>';
  }

  return new Handlebars.SafeString(s);
});

Handlebars.registerHelper('last', function(array) {
  if (!array) {
    return null;
  } else {
    return array[array.length - 1];
  }
});

Handlebars.registerHelper('mostRecentCapitalValue', function(array) {
  var recent = _(array).find(function(item) {
    if (item.is_most_recent) {
      return item;
    }
  });

  return recent.capital_value;
});

Handlebars.registerHelper('mostRecentIncomeValue', function(array) {
  var recent = _(array).find(function(item) {
    if (item.is_most_recent) {
      return item;
    }
  });

  return recent.income_value;
});

Handlebars.registerHelper('getEventName', function(choice) {
  return Event.EVENTS[choice];
});

Handlebars.registerHelper('eventParser', function(details) {
  return Event.eventParser(details);
});

Handlebars.registerHelper('capitalize', function(text) {
  return text.charAt(0).toUpperCase() + text.substr(1);
});

Handlebars.registerHelper('equalTo', function(v1, v2) {
  return v1 === v2;
});

Handlebars.registerHelper('getRoleName', function(groupId) {
  return User.GROUP_NAMES[groupId];
});

Handlebars.registerHelper('getTeamName', function(teamId) {
  var team = TCAS.teams.get(teamId);
  if (team) {
    return team.get('name');
  } else {
    return '-';
  }
});

Handlebars.registerHelper('limitChars', function(message, length) {
  if (message.length > length) {
    return message.substring(0, length) + '...';
  } else {
    return message;
  }
});

Handlebars.registerHelper('formatMessage', function(content) {
  content = content.replace(/\*/g, '<br>*');
  content = content.replace(/\n/g, '<br>');
  return new Handlebars.SafeString(content);
});

Handlebars.registerHelper('reformatMessage', function(content) {
  content = content.replace('<br>*', '*');
  content = content.replace('<br>', '\n');
  return new Handlebars.SafeString(content);
});

Handlebars.registerHelper('safeString', function(content) {
  return new Handlebars.SafeString(content);
});

Handlebars.registerHelper('assetValueBand', function(value, incomeValue, researchSignedOff) {
  function getBand(value) {
    if (value <= 749.0) {
      return 'B';
    } else if (value >= 750.0 && value <= 1249.0) {
      return 'AB';
    } else if (value >= 1250.0 && value <= 9999.0) {
      return 'A';
    } else if (value >= 10000.0 && value <= 19999.0) {
      return 'AA';
    } else if (value >= 20000.0) {
      return 'AAA';
    }
  }

  function getGroup(user) {
    if (!user.has('groups')) {
      return null;
    }
    switch (user.get('groups').length) {
      case 0:
        return null;
      case 1:
        if (_.contains(user.get('groups'), User.GROUPS.ADMIN)) {
          return null;
        } else {
          return user.get('groups')[0];
        }
        break;
      default:
        var groups = _.clone(user.get('groups'));
        groups.splice(groups.indexOf(User.GROUPS.ADMIN), 1);
        return _.min(groups);
    }
  }

  var total = value;
  if (incomeValue) {
    total += incomeValue;
  }

  // CM and SL can see value after FA Sent
  var group = getGroup(TCAS.session);
  if (group === User.GROUPS.CASE_MANAGER || group === User.GROUPS.TEAM_SECOND_LEADER) {
    if (researchSignedOff) {
      return total.toFixed(2);
    } else {
      return getBand(total);
    }
  }

  if (TCAS.session.hasPerm('view_assetvalue')) {
    return total.toFixed(2);
  } else {
    return getBand(total);
  }
});

Handlebars.registerHelper('ifOwner', function(owner, options) {
  if (TCAS.session.hasGroup(User.GROUPS.ADMIN)) {
    return options.fn(this);
  }
  if (TCAS.session.get('id') === owner) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('ifAdmin', function(options) {
  if (TCAS.session.hasGroup(User.GROUPS.ADMIN)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('postCodePartial', function(postCode) {
  if (postCode === '') {
    return '';
  } else if (postCode.length <= 3) {
    return postCode;
  } else {
    return postCode.substr(postCode.length - 3);
  }
});

Handlebars.registerHelper('isClient', function(options) {
  if (TCAS.session.hasGroup(User.GROUPS.CLIENT) && TCAS.session.hasPerm('view_client_casefile')) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
