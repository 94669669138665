'use strict';
var template = require('../../templates/users/user-create-details.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var UserDetail = require('../../models/users/userdetail');
var Contact = require('../../models/users/contact');
var User = require('../../models/users/user');
var Team = require('../../models/users/team');
var ConfirmModal = require('../confirm-modal');
var CountriesSelectView = require('../countries-select');

var UserDetailsView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    alertsArea: '.alerts-area',
    countries: 'input[name="country"]',
    roles: '#group',
    username: '#username',
    email: '#email',
    signature: '.signature',
    unlockAccount: '.unlock-account',
    inactive: '.inactive-checkbox',
    reset2FA: '.reset-2fa'
  },
  events: {
    'click @ui.unlockAccount': 'unlockAccount',
    'click @ui.reset2FA': 'onClickReset2FA'
  },
  unlockAccount: function() {
    var that = this;
    this.options.user.get('userdetail').save({'is_locked': false},{
      success: function() {
        that.options.user.get('userdetail').fetch().then(function() {
          that.render();
        });
      }
    });
  },
  onClickReset2FA: function() {
    var that = this;
    var confirmModal = new ConfirmModal({
      body: 'Are you sure you want to reset the 2 factor authentication for this user?'
    });
    this.listenTo(confirmModal, 'confirmYes', function() {
      var url = this.options.user.get('userdetail').url();
      url += '/reset_2fa/';
      $.getJSON(url, function() {
        that.ui.alertsArea.html(
          alertTemplate({
            strong: 'Success!',
            message: 'The 2 Factor Authentication has been reset for this user.',
            type: 'success'
          })
        );
      });
    });
    confirmModal.render();
  },
  submitData: function(data) {
    //jshint camelcase:false
    var that = this;
    var group = parseInt($('#group').val());
    this.ui.alertsArea.html('');

    var user = this.options.user;
    var newUser = this.options.isNewUser;

    //if new user or user has no team
    if(newUser ||
      user.get('userdetail').get('team') === null ||
      group !== User.GROUPS.TEAM_LEADER) {
      that.saveDetails(that, user, newUser, group, data);
      return false;
    }

    var team = new Team({
      id: user.get('userdetail').get('team')
    });

    team.fetch({
      success: function() {
        if(team.get('team_leader') === null ||
          team.get('team_leader') === user.get('id')) {
          that.saveDetails(that, user, newUser, group, data);
          return;
        } else {
          var confirmModal = new ConfirmModal({
            title: 'The users team already has a Team Leader',
            body: 'You are setting this user to Team Leader but the users ' +
            'team already has a Team Leader specified. <br><br>' +
            'If you continue, the existing Team Leader will be demoted to ' +
            'Second Leader and this user will become the new Team Leader.' +
            '<br><br>Are you sure you want to continue?'
          });
          that.listenTo(confirmModal, 'confirmYes', function() {
            that.saveDetails(that, user, newUser, group, data);
          });
          confirmModal.render();
        }
      }
    });
    return false;
  },
  saveDetails: function(that, user, newUser, group, data) {
    user.set({
      username: data.username,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name
    });
    user.setGroup(group);

    if('admin' in data) {
      user.setAdmin();
    } else {
      user.removeAdmin();
    }

    if ('inactive' in data) {
      user.set('is_active', false);
    } else {
      user.set('is_active', true);
    }

    if (data.password) {
      user.set('password', data.password);
    }

    // user details
    var userdetail;
    if(user.get('userdetail') === undefined) {
      userdetail = new UserDetail();
    } else {
      userdetail = user.get('userdetail');
      user.unset('userdetail');
    }

    // user contact
    var contact;
    if(userdetail.get('contact') === undefined) {
      contact = new Contact();
    } else {
      contact = userdetail.get('contact');
      userdetail.unset('contact');
    }

    contact.set({
      title: data.title,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      organisation: data.organisation,
      phone: data.phone,
      extension: data.extension,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      post_code: data.post_code,
      county: data.county,
      country: data.country
    });

    if(newUser) {
      user.set('owner', TCAS.session.get('id'));
      userdetail.set('owner', TCAS.session.get('id'));
      contact.set('owner', TCAS.session.get('id'));
    }

    user.save({}, {
      error: function() {
        that.ui.alertsArea.html(
          alertTemplate({
            strong: 'Warning!',
            message: 'User was not saved successfully.',
            type: 'danger'
          }));
      }
    }).then(function() {
      // need to set the user of userdetail in order to save it
      if(newUser) {
        userdetail.set('user', user.get('id'));
      }

      contact.save({}, {
        error: function() {
          if(newUser) {
            user.destroy();
          }

          that.ui.alertsArea.html(
            alertTemplate({
              strong: 'Warning!',
              message: 'User was not saved successfully.',
              type: 'danger'
            }));
        }
      }).then(function() {
        // need to set the contact of userdetail
        //if(newUser) {
        userdetail.set('contact', contact);
        //}
        userdetail.save({ signature: data.signature }, {
          error: function() {
            if(newUser) {
              user.destroy();
              contact.destroy();
            }

            that.ui.alertsArea.html(
              alertTemplate({
                strong: 'Warning!',
                message: 'User was not saved successfully.',
                type: 'danger'
              }));
          }
        }).then(function() {
          that.ui.alertsArea.html(
            alertTemplate({
              strong: 'Success!',
              message: 'User saved successfully.',
              type: 'success'
            })
          );
          if(newUser) {
            TCAS.users.add(user);
          } else {
            TCAS.users.add(user, {merge: true});
          }

          // open user in edit mode
          TCAS.router.navigate('/users/'+user.get('id'), {trigger: true});

          // scroll to top to see any success/error messages
          $.scrollTo(0);
        });
      });
    });
  },
  behaviors: {
    SubmitForm: {
      message: 'user create details form',
      selector: '.user-create-details-form',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          group: {
            required: true
          },
          username: {
            required: true,
            remote: {
              param: {
                url: '/api/checkusername/'
              },
              depends: function(element) {
                return $(element).val()
                  !== $(element).attr('data-cur-username');
              }
            }
          },
          password: {
            minlength: 8,
            passwordComplexity: true
          },
          password2: {
            equalTo: '#password1',
            required: function(elem){
              var password = $(elem).parents('form').
                find('input[name="password"]').val();
              return password !== '';
            }
          },
          email: {
            required: true,
            email: true,
            maxlength: 255,
            remote: {
              param: {
                url: '/api/checkemail/'
              },
              depends: function(element) {
                return $(element).val() !== $(element).attr('data-cur-email');
              }
            }
          },
          title: {
            maxlength: 35
          },
          first_name: {
            required: true,
            maxlength: 50
          },
          last_name: {
            required: true,
            maxlength: 50
          },
          organisation: {
            maxlength: 50
          },
          phone: {
            maxlength: 20
          },
          extension: {
            maxlength: 20
          },
          address1: {
            maxlength: 50
          },
          address2: {
            maxlength: 50
          },
          city: {
            maxlength: 50
          },
          post_code: {
            maxlength: 15
          },
          county: {
            maxlength: 50
          },
          country: {
            maxlength: 50
          }
        },
        ignore: [],
        messages: {
          password2: {
            required: 'Please type the same password.'
          }
        }
      },
      selector: '.user-create-details-form'
    }
  },
  onRender: function(){
    var that = this;
    this.countryRegion = new Backbone.Marionette.Region({
      el: this.$('#country-input-container')
    });
    var country = this.options.user.has('userdetail') ?
      this.options.user.get('userdetail').get('contact').get('country') :
      'United Kingdom';
    this.countryRegion.show(new CountriesSelectView({
      countries: TCAS.countries,
      selectedCountry: country
    }));

    var role = this.options.user.getGroup();
    if(role) {
      that.ui.roles.val(role);
    } else {
      that.ui.roles.val(7);
    }

    if(_.contains(this.options.user.get('groups'), 2)) {
      that.$('input[name="admin"]').prop('checked', true);
    }
  },
  onShow: function() {
    this.ui.signature.summernote({
      height: 200,
      toolbar: [
        // [groupName, [list of button]]
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['paragraph', 'ul', 'ol']],
        ['height', ['height']],
        ['insert', ['link', 'picture', 'hr']],
        ['codeview', ['codeview']],
        ['undo', ['undo', 'redo']]
      ]
    });
    var signature = '';
    if (this.getOption('user').has('userdetail')) {
      signature = this.getOption('user').get('userdetail').get('signature');
    }
    this.ui.signature.summernote('code', signature);
  },
  serializeData: function() {
    var data = this.options.user.toJSON();
    data.roles = [];
    User.CASE_WORKER_GROUPS.forEach(function(groupid) {
      if (groupid !== User.GROUPS.ADMIN) {
        data.roles.push({ id: groupid, name: User.GROUP_NAMES[groupid] });
      }
    });

    data.roles.push({ id: User.GROUPS.AFFILIATE, name: User.GROUP_NAMES[User.GROUPS.AFFILIATE] });
    data.roles.push({ id: User.GROUPS.ACCOUNTS, name: User.GROUP_NAMES[User.GROUPS.ACCOUNTS] });

    console.log('data roles: ', data.roles);

    if(this.options.isNewUser) {
      data.isNewUser = true;
    }

    if(_.contains(this.options.user.get('groups'), User.GROUPS.ADMIN)) {
      data.admin = true;
    }

    return data;
  }
});

module.exports = UserDetailsView;
