// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <button type=\"button\" class=\"btn btn-danger deny\" data-loading-text=\"Denying...\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.canApproveThisRequest : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Deny</button>\n        <button type=\"button\" class=\"btn btn-primary approve\" data-loading-text=\"Approving...\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.canApproveThisRequest : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Approve</button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        "
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.actioned_by : depth0),{"name":"userFullName","hash":{},"data":data}))
    + " "
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.modified : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = ((helper = (helper = helpers.request || (depth0 != null ? depth0.request : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"request","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "  <a class=\"popout-case\" href=\""
    + alias3((helpers.caseUrl || (depth0 && depth0.caseUrl) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"caseUrl","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.caseField || (depth0 && depth0.caseField) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),"ref",{"name":"caseField","hash":{},"data":data}))
    + "</a></td>\n<td>"
    + alias3(((helper = (helper = helpers.statusName || (depth0 != null ? depth0.statusName : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"statusName","hash":{},"data":data}) : helper)))
    + "</td>\n<td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPending : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
