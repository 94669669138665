'use strict';
var moment = require('moment');
var CaseFile = require('../../models/cases/casefile');
var TimeTrack = require('../../models/timetrack/timetrack');
var User = require('../../models/users/user');
var template = require('../../templates/timetrack/timetrack-filter.hbs');

var FilterView = Backbone.Marionette.ItemView.extend({
  tagName: 'form',
  className: 'case-filter-form',
  template: template,
  ui: {
    filter: '#submit-filter',
    csvExport: '.csv',
    reset: '#reset-filter',
    calendar: '#calendar-widget',
    afterDate: '#after-date',
    beforeDate: '#before-date',
    caseManager: '#case-manager',
    supervisor: '#supervisor',
    activity: '#activity',
    task: '#task',
    caseFile: '#case-file',
    category: '#category',
    status: '#status',
    valueAbove: '#value-above',
    valueBelow: '#value-below',
    faSent: '#fa-sent',
    disclosureSent: '#disclosure-sent',
    caseBooked: '#case-booked'
  },
  events: {
    'click @ui.filter': 'filterSubmit',
    'click @ui.reset': 'resetFilter',
    'click @ui.csvExport': 'exportResults'
  },
  filterSubmit: function() {
    /* jshint camelcase:false */
    var afterDate = this.ui.afterDate.datepicker('getDate');
    var beforeDate = this.ui.beforeDate.datepicker('getDate');
    var caseManager = this.ui.caseManager.select2('val');
    var supervisor = this.ui.supervisor.select2('val');
    var activity = this.ui.activity.val();
    var task = this.ui.task.val();
    var caseFile = this.ui.caseFile.select2('val');
    var category = this.ui.category.select2('val');
    var status = this.ui.status.val();
    var valueAbove = this.ui.valueAbove.val();
    var valueBelow = this.ui.valueBelow.val();
    var faSent = this.ui.faSent.val();
    var disclosureSent = this.ui.disclosureSent.val();
    var caseBooked = this.ui.caseBooked.val();

    var filters = {};
    if(afterDate) {
      if(this.ui.afterDate.val() !== '') {
        filters.start_a = afterDate.toISOString();
      }
    }
    if(beforeDate) {
       if(this.ui.beforeDate.val() !== '') {
         var beforeMoment = moment(beforeDate);
         beforeMoment.add(1, 'days');
         filters.start_b = beforeMoment.toDate().toISOString();
       }
    }
    if(activity) {
      filters.activity = activity;
    }
    if(task) {
      filters.task = task;
    }
    if(caseFile) {
      filters.case = caseFile;
    }
    if (category) {
      filters.category = category;
    }
    if (caseManager) {
      filters.owner = caseManager;
    }
    if (supervisor) {
      filters.supervisor = supervisor;
    }
    if (status) {
      filters.status = status;
    }
    if (faSent) {
      filters.fa_sent = faSent;
    }
    if (disclosureSent) {
      filters.disclosure_sent = disclosureSent;
    }
    if (caseBooked) {
      filters.case_booked = caseBooked;
    }
    if(valueAbove) {
      filters.value_a = valueAbove;
    }
    if(valueBelow) {
      filters.value_b = valueBelow;
    }

    filters.ordering = '-start';
    this.collection.setFilters(filters);

    var $button = this.ui.filter;
    $button.button('loading');
    this.collection.fetch().then(function(){
      $button.button('reset');
    });

    return false;
  },
  resetFilter: function(){
    //clear the form values
    this.ui.caseManager.select2('val', '');
    this.ui.supervisor.select2('val', '');
    this.ui.activity.val('');
    this.ui.task.val('');
    this.ui.caseFile.select2('val', '');
    this.ui.category.select2('val', '');
    this.ui.status.val('');
    this.ui.valueAbove.val('');
    this.ui.valueBelow.val('');
    this.ui.faSent.val('');
    this.ui.disclosureSent.val('');
    this.ui.caseBooked.val('');
  },
  exportResults: function() {
    // check for valid sensitive data token,
    // only allow CSV export if in sensitive data session
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doExportResults(sdToken);
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }
  },
  doExportResults: function(sdToken) {
    var afterDate = this.ui.afterDate.datepicker('getDate');
    var beforeDate = this.ui.beforeDate.datepicker('getDate');
    var caseManager = this.ui.caseManager.select2('val');
    var supervisor = this.ui.supervisor.select2('val');
    var activity = this.ui.activity.val();
    var task = this.ui.task.val();
    var caseFile = this.ui.caseFile.select2('val');
    var category = this.ui.category.select2('val');
    var status = this.ui.status.val();
    var valueAbove = this.ui.valueAbove.val();
    var valueBelow = this.ui.valueBelow.val();
    var faSent = this.ui.faSent.val();
    var disclosureSent = this.ui.disclosureSent.val();
    var caseBooked = this.ui.caseBooked.val();

    var filters = {};
    if(afterDate) {
      if(this.ui.afterDate.val() !== '') {
        filters.start_a = afterDate.toISOString();
      }
    }
    if(beforeDate) {
      if(this.ui.beforeDate.val() !== '') {
        filters.start_b = beforeDate.toISOString();
      }
    }
    if(activity) {
      filters.activity = activity;
    }
    if(task) {
      filters.task = task;
    }
    if(caseFile) {
      filters.case = caseFile;
    }
    if (category) {
      filters.category = category;
    }
    if (caseManager) {
      filters.owner = caseManager;
    }
    if (supervisor) {
      filters.supervisor = supervisor;
    }
    if (status) {
      filters.status = status;
    }
    if (faSent) {
      filters.fa_sent = faSent;
    }
    if (disclosureSent) {
      filters.disclosure_sent = disclosureSent;
    }
    if (caseBooked) {
      filters.case_booked = caseBooked;
    }
    if(valueAbove) {
      filters.value_a = valueAbove;
    }
    if(valueBelow) {
      filters.value_b = valueBelow;
    }

    filters.ordering = '-start';

    var url = '/api/timetrack/csv/';
    this.ui.csvExport.button('loading');
    var params = [];
    _.forOwn(filters, function(value, filter){
      params.push(filter + '=' + value);
    });
    url += '?sdtoken=' + encodeURIComponent(sdToken);
    if (params.length) {
      url += '&' + params.join('&');
    }

    window.location.href = url;
    this.ui.csvExport.button('reset');
  },
  onShow: function(){
    function formatCategory(category, container, query){
      var s = category.code + ' (' + category.name + ')';
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });

      return s;
    }

    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });

      return s;
    }

    function formatCasefile(casefile, container, query){
      var s = casefile.ref; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });

      return s;
    }

    this.ui.category.select2({
      placeholder: 'Category name/code',
      minimumInputLength: 1,
      ajax: {
        url: '/api/categories/',
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatCategory,
      formatSelection: formatCategory,
      allowClear: true
    });

    var caseWorkersUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    if(TCAS.session.hasPerm('view_all_timetrack')) {
      // do nothing
    } else if (TCAS.session.hasPerm('view_team_timetrack')) {
      if(TCAS.session.get('userdetail').team !== null) {
        caseWorkersUrl += '&userdetail__team=' +
          TCAS.session.get('userdetail').team;
      } else {
        caseWorkersUrl += '&id=' + TCAS.session.get('id');
      }
    } else if (TCAS.session.hasPerm('view_timetrack')) {
      caseWorkersUrl += '&id=' + TCAS.session.get('id');
    }

    this.ui.caseManager.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseWorkersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
      initSelection: function(element, callback) {
        callback(TCAS.session.toJSON());
      }
    });

    var supervisorsUrl = '/api/users/?groups=' +
      User.SUPERVISOR_GROUPS.join('&groups=');

    if(TCAS.session.hasPerm('view_all_timetrack')) {
      // do nothing
    } else if (TCAS.session.hasPerm('view_team_timetrack')) {
      if(TCAS.session.get('userdetail').team !== null) {
        supervisorsUrl += '&userdetail__team=' +
        TCAS.session.get('userdetail').team;
      } else {
        supervisorsUrl += '&id=' + TCAS.session.get('id');
      }
    } else if (TCAS.session.hasPerm('view_timetrack')) {
      supervisorsUrl += '&id=' + TCAS.session.get('id');
    }

    this.ui.supervisor.select2({
      placeholder: 'Supervisor name',
      ajax: {
        url: supervisorsUrl,
        minimumInputLength: 1,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true
    });

    this.ui.caseFile.select2({
      placeholder: 'Case',
      ajax: {
        url: '/api/cases/',
        minimumInputLength: 1,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatCasefile,
      formatSelection: formatCasefile,
      allowClear: true
    });

    this.ui.afterDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });

    this.ui.beforeDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });

  },
  getTasks: function(TASKS) {
    var tasks = [];
    for(var key in TASKS) {
      var obj = TASKS[key];
      tasks.push({
        id: key,
        name: obj
      });
    }
    return tasks;
  },
  serializeData: function(){
    var data = CaseFile.STATUS_IDS;

    if(_.indexOf(TCAS.session.get('groups'), User.GROUPS.CASE_MANAGER) > -1) {
      data.user = TCAS.session.get('id');
    }

    data.tasks = this.getTasks(TimeTrack.TASKS);
    data.activities = this.getTasks(TimeTrack.ACTIVITIES);

    return data;
  }
});

module.exports = FilterView;
