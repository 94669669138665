'use strict';
var template = require('../../templates/timetrack/timetrack-overview.hbs');
var TimeTrackList = require('./timetrack-list');
var TimeTracks = require('../../models/timetrack/timetracks');
var TimeTrackFilter = require('./timetrack-filter');
var Spinner = require('spin');

var TimetrackOverviewView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    'timeTrackList': '#timetrack-list',
    'timetrackFilter': '#timetrack-filter'
  },
  ui: {
    loadingBar: 'div.loading-bar',
    loadMoreBtn: 'button.pagination-next',
    pageInfo: '.page-info-bar',
    itemCount: '.item-count',
    itemTotal: '.item-total'
  },
  events: {
    'click @ui.loadMoreBtn': 'onClickLoadMore'
  },
  initialize: function(){
    this.collection = new TimeTracks([]);
    this.listenTo(this.collection, 'sync', this.onSync);

    var lazyScroll = _.bind(_.debounce(this.onScroll, 100), this);
    $(window).scroll(lazyScroll);
  },
  onSync: function(){
    var that = this;
    this.ui.itemCount.html(this.collection.length);
    this.ui.itemTotal.html(this.collection.total);
    var progressWidth = that.ui.pageInfo.find('.progress').width();
    this.ui.pageInfo.removeClass('hide');
    this.ui.pageInfo.find('.progress-bar').attr('aria-valuenow',
      this.collection.length).attr('aria-valuemax', this.collection.length);
    this.ui.pageInfo.find('.progress-bar').width(
      Math.floor((this.collection.length/this.collection.total) *
        progressWidth)).is(':visible');

    if (this.spinner) {
      this.spinner.stop();
    }
    this.ui.loadMoreBtn.button('reset');
    _.defer(function() {
      that.ui.loadMoreBtn.prop('disabled', !that.collection.next);
    });
  },
  onScroll: function(){
    var scrollTop = $(window).scrollTop();
    var isMaxScroll = (document.body.scrollHeight - $(window).height()) ===
      scrollTop;
    if (isMaxScroll && this.collection.next) {
      this.collection.fetchNext();
    }
  },
  onClickLoadMore: function() {
    if (this.collection.next) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.spinner.spin(this.ui.loadingBar[0]);
      this.ui.loadMoreBtn.button('loading');
      this.collection.fetchNext();
    }
  },
  onRender: function() {
    this.timeTrackList.show(new TimeTrackList({
      collection: this.collection
    }));
    this.timetrackFilter.show(new TimeTrackFilter({
      collection: this.collection
    }));
  }
});

module.exports = TimetrackOverviewView;