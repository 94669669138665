// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div class=\"form-group\">\n      <label for=\"secret-character-"
    + alias2(alias1(depth0, depth0))
    + "\" class=\"col-md-4\">Character "
    + alias2((helpers.add || (depth0 && depth0.add) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,1,{"name":"add","hash":{},"data":data}))
    + ":</label>\n      <div class=\"col-md-3\">\n        <input type=\"text\" name=\"character_"
    + alias2(alias1(depth0, depth0))
    + "\" class=\"form-control\" id=\"secret-character-"
    + alias2(alias1(depth0, depth0))
    + "\" maxlength=\"1\" required />\n      </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <div class=\"alert alert-info\" role=\"alert\"><strong>Hint:</strong> "
    + container.escapeExpression(((helper = (helper = helpers.hint || (depth0 != null ? depth0.hint : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hint","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form class=\"secret-challenge form-horizontal\">\n  <div class=\"alert alert-danger hide\" role=\"alert\"></div>\n  <p class=\"help-block\">Please enter the specified characters from your secret word or phrase.</p>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.character_sequence : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"has-error\">\n    <p class=\"help-block\">Secret word or phrase case is sensitive, can contain spaces and must be between 8 and 16 characters long.</p>\n  </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hint : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <a href=\"#\" class=\"forgot-2fa-link pull-right\">Forgot your secret word?</a>\n\n  <button type=\"submit\" class=\"btn btn-primary submit-challenge\" data-loading-text=\"Please wait...\">Login</button>\n</form>\n\n<form class=\"forgot-2fa-form\" style=\"display:none;\">\n  <div class=\"form-group\">\n    <p class=\"help-block\">\n      If you have forgotten your secret word or phrase, click the button below.\n      You will be emailed a link, which will reset your 2 factor authentication method and allow you to create it again.\n    </p>\n  </div>\n\n  <button type=\"submit\" class=\"btn btn-default\">Reset 2 Factor Authentication</button>\n</form>";
},"useData":true});
