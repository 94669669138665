// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.disclosure : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.confirmedRevenue : depth0),"",(depth0 != null ? depth0.displayPence : depth0),{"name":"currencyTableCell","hash":{},"data":data}));
},"5":function(container,depth0,helpers,partials,data) {
    return "<td></td>";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.pendingRevenue : depth0),"",(depth0 != null ? depth0.displayPence : depth0),{"name":"currencyTableCell","hash":{},"data":data}));
},"9":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.pledgedRevenue : depth0),"",(depth0 != null ? depth0.displayPence : depth0),{"name":"currencyTableCell","hash":{},"data":data}));
},"11":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.atRiskRevenue : depth0),"",(depth0 != null ? depth0.displayPence : depth0),{"name":"currencyTableCell","hash":{},"data":data}));
},"13":function(container,depth0,helpers,partials,data) {
    return "&#x2714;";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.record : depth0)) != null ? stack1.is_csoa_raised : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.record : depth0)) != null ? stack1.is_csoa_raised_changed : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.team : depth0)) != null ? stack1.name : stack1), depth0))
    + "</td>\n<td>"
    + alias2(((helper = (helper = helpers.caseType || (depth0 != null ? depth0.caseType : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"caseType","hash":{},"data":data}) : helper)))
    + "</td>\n<td><a href=\"/cases/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.casefile : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"popout\" target=\"_blank\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.casefile : depth0)) != null ? stack1.ref : stack1), depth0))
    + "</a></td>\n<td>"
    + alias2((helpers.userFullName || (depth0 && depth0.userFullName) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.id : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.code : stack1), depth0))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.disclosure : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + alias2((helpers.formatDate || (depth0 && depth0.formatDate) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.record : depth0)) != null ? stack1.date : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias2((helpers.dateToWeek || (depth0 && depth0.dateToWeek) || alias4).call(alias3,(depth0 != null ? depth0.dateString : depth0),{"name":"dateToWeek","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.confirmedRevenue : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.pendingRevenue : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.pledgedRevenue : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.atRiskRevenue : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n<td>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isBooked : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.record : depth0)) != null ? stack1.description : stack1), depth0))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isBooked : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
