// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-warning alert-dismissible\" role=\"alert\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\"><i class=\"fa fa-close\"></i></span></button>\n  <strong>Warning!</strong> You do not have Archived Cases session enabled, so reports will not contain any data\n  from archived cases.\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"9":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,depth0,">=",1,{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,depth0,{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " class=\"bg-success\" ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <div class=\"user-revenue-section report-table-container\">\n    <h3 class=\"booked-background\">"
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.id : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "</h3>\n    <div class=\"user-revenue-container\">\n      <table class=\"table table-condensed table-responsive\">\n        <thead>\n          <tr>\n            <th></th>\n            <th></th>\n            <th>Jan</th>\n            <th>Feb</th>\n            <th>Mar</th>\n            <th>Apr</th>\n            <th>May</th>\n            <th>Jun</th>\n            <th>Jul</th>\n            <th>Aug</th>\n            <th>Sep</th>\n            <th>Oct</th>\n            <th>Nov</th>\n            <th>Dec</th>\n          </tr>\n        </thead>\n        <tbody>\n          <tr>\n            <th>Revenue</th>\n            <th>Actual - Asset</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.assetRevenueByMonth : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <th></th>\n            <th>Actual - Council & B/V</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.laRevenueByMonth : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <th></th>\n            <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.revenueYTD : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <td colspan=\"14\" class=\"table-separator\"></td>\n          </tr>\n          <tr class=\"row-alternate\">\n            <th>% Performance to Plan YTD</th>\n            <th>Phased Target</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.phasedTarget : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr class=\"row-alternate\">\n            <th></th>\n            <th>Phased Stretch</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.phasedStretch : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isArchiveDataSession : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div>\n  <form class=\"form-inline\">\n    <div class=\"form-group\">\n      <label for=\"company-activity-year\">Year</label>\n      <input id=\"company-activity-year\" class=\"form-control year\" type=\"number\" name=\"year\" placeholder=\"YYYY\" />\n    </div>\n\n    <div class=\"form-group\">\n      <label for=\"company-revenue-team\">Team</label>\n      <input type=\"text\" id=\"company-revenue-team\" class=\"form-control team\" name=\"team\" placeholder=\"Select a team...\"\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTeamDisabled : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n    </div>\n\n    <div class=\"form-group inactive-users-checkbox-container\">\n      <div class=\"checkbox\">\n        <label for=\"include-inactive-users\">\n          <input type=\"checkbox\" name=\"include_inactive\" id=\"include-inactive-users\" value=\"1\"\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.includeInactiveUsers : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Include <span class=\"badge\">Inactive</span>\n        </label>\n      </div>\n    </div>\n\n    <button type=\"submit\" class=\"btn btn-success btn-sm submit-btn\" id=\"company-report-submit\" data-loading-text=\"Please wait...\">\n      Generate Report\n    </button>\n  </form>\n</div>\n\n<div id=\"reporting-area\" class=\"reporting-area hidden\">\n  <h2>Revenue Performance<span class=\"team-name "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.teamName : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"> - "
    + container.escapeExpression(((helper = (helper = helpers.teamName || (depth0 != null ? depth0.teamName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"teamName","hash":{},"data":data}) : helper)))
    + "</span></h2>\n\n  <div class=\"summary-section report-table-container report-table-container-summary\">\n    <h3 class=\"summary-background\">Summary</h3>\n    <div class=\"summary-container\">\n      <table class=\"table table-condensed table-responsive\">\n        <thead>\n          <tr>\n            <th></th>\n            <th></th>\n            <th>Jan</th>\n            <th>Feb</th>\n            <th>Mar</th>\n            <th>Apr</th>\n            <th>May</th>\n            <th>Jun</th>\n            <th>Jul</th>\n            <th>Aug</th>\n            <th>Sep</th>\n            <th>Oct</th>\n            <th>Nov</th>\n            <th>Dec</th>\n          </tr>\n        </thead>\n        <tbody>\n          <tr>\n            <th>Revenue</th>\n            <th>Actual - Asset</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalAssetRevenueByMonth : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <th></th>\n            <th>Actual - Council & B/V</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalLARevenueByMonth : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <th></th>\n            <th>Actual Mth</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalRevenueByMonth : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <th></th>\n            <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalRevenueYTD : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <td colspan=\"14\" class=\"table-separator\"></td>\n          </tr>\n          <tr class=\"row-alternate\">\n            <th>Performance Plan YTD</th>\n            <th>Phased Target</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalPhasedTarget : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr class=\"row-alternate\">\n            <th></th>\n            <th>Phased Stretch</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalPhasedStretch : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <td colspan=\"14\" class=\"table-separator\"></td>\n          </tr>\n          <tr>\n            <th>% Performance to Plan YTD</th>\n            <th>Phased Target</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalPhasedTargetPercentage : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n          <tr>\n            <th></th>\n            <th>Phased Stretch</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalPhasedStretchPercentage : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.usersData : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
