'use strict';
var template =
  require('../../templates/templates_repository/templates-create.hbs');
var Dropzone = require('dropzone');
var GeneralFile = require('../../models/reporting/generalfile');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var templateFilePreview = require(
  '../../templates/clients/correspondence-file-preview.hbs');
var alertTemplate = require('../../templates/alert.hbs');


var TemplatesCreateView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    attachFile: 'button.add-file',
    submitBtn: 'button[type="submit"]',
    uploadContainer: '.upload-file-container',
    form: 'form',
    formRow: 'tbody.template-add tr',
    evidence: 'input[name="evidence"]',
    dropzonePreviews: '.dropzone-previews',
    filePreviews: '.file-previews',
    alertsArea: '.alerts-area'
  },
  onShow: function() {
    var that = this;

    if(this.options.templateAdded) {
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'Template was added successfully.',
          type: 'success'
        }));
    }

    //configure dropzone
    this.dropzone = new Dropzone(this.el, {
      url: '/api/generalfiles/',
      headers: {'X-CSRFToken': TCAS.csrfToken},
      previewsContainer: that.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      clickable: that.ui.attachFile[0],
      autoProcessQueue: false,
      maxFiles: 1,
      maxFilesize: 500,
      acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.ged',
      accept: function(file, done){
        // check for only alphanumeric characters
        var alphaNumericPattern = new RegExp(
          '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
        );
        if (alphaNumericPattern.test(file.name)) {
          done();
        } else {
          done('This file has unexpected characters, ' +
            'file names must use the correct file naming structure and ' +
            'only contain characters consisting of: ' +
            'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
            'apostrophes, commas or spaces.');
        }
      }
    });
    this.dropzone.on('success', function(file, response){
      that.trigger('fileUploaded', response.id);
      that.isFileQueued = false;
      TCAS.vent.trigger('dropzone:saved', response);
    });
    this.dropzone.on('sending', function(file, xhr, formData) {
      // Show the total progress bar when upload starts
      that.$(file.previewElement).find('.progress').removeClass('hide');
      formData.append('description', that.data.description);
      formData.append('type', GeneralFile.TYPE_TEMPLATE);
      formData.append('name', that.data.name);
      formData.append('owner', TCAS.session.get('id'));
      formData.append('category', that.data.category);
      formData.append('grouping', that.data.grouping);
    });
    this.dropzone.on('addedfile', function(file){
      that.isFileQueued = true;
      that.ui.evidence.val(-1);
      that.$(file.previewElement).find('button').removeClass('hide');
      that.ui.uploadContainer.addClass('hide');
    });
    this.dropzone.on('error', function(file){
      that.ui.evidence.val('');
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'An error occurred uploading your item!',
          type: 'danger'
        }));
    });
    this.dropzone.on('removedfile', function(){
      that.isFileQueued = false;
      that.ui.evidence.val('');
      that.ui.uploadContainer.removeClass('hide');
    });
    this.dropzone.on('dragover', function(){
      that.ui.formRow.addClass('success');
    });
    this.dropzone.on('dragleave', function(){
      that.ui.formRow.removeClass('success');
    });
    this.dropzone.on('drop', function(e){
      that.ui.formRow.removeClass('success');
      if (_(e.dataTransfer.types).contains('text/plain')) {
        var data = JSON.parse(e.dataTransfer.getData('text/plain'));
        that.ui.filePreviews.empty().append(templateFilePreview(data));
        that.ui.evidence.val(data.id);
        that.ui.uploadContainer.addClass('hide');
        e.preventDefault();
      }
    });
  },
  behaviors: {
    Validate: {
      validateOptions: {
        rules: {
          name: {
            required: true
          },
          description: {
            required: true
          },
          category: {
            required: true,
            maxlength: 120
          },
          grouping: {
            required: true,
            maxlength: 120
          },
          evidence: {
            required: true
          }
        },
        messages: {
          evidence: {
            required: 'A file is required.'
          }
        },
        ignore: []
      }
    },
    SubmitForm: {
      submitHandler: 'saveTemplate'
    }
  },
  saveTemplate: function(data) {
    var that = this;
    this.data = data;
    that.ui.submitBtn.button('loading');
    if (this.isFileQueued) {
      this.listenToOnce(this, 'fileUploaded', function (fileId) {
        data.evidence = fileId;
      });
      this.dropzone.processQueue();
    }
  }
});

module.exports = TemplatesCreateView;