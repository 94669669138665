'use strict';

var CountriesSelectView = Backbone.Marionette.ItemView.extend({
  tagName: 'input',
  className: 'form-control',
  template: false,
  initialize: function(options){
    //jshint camelcase:false
    this.$el.attr('name', 'country');
    this.$el.attr('type', 'text');
    var selected = options.selectedCountry;
    if (selected) {
      var country = _.find(this.options.countries, function(c){
        return c.printable_name.toLowerCase() === selected.toLowerCase() ||
          c.iso3.toLowerCase() === selected.toLowerCase() || c.iso.toLowerCase() === selected.toLowerCase();
      });
      if (country) {
        this.$el.val(country.iso3);
      }
    }
  },
  serializeData: function(){
    return this.options;
  },
  onShow: function(){
    //jshint camelcase:false
    function formatSelection(object, container, query){
      return object.printable_name + ' (' + object.iso3 + ')';
    }

    var countriesData = _.clone(this.options.countries);
    _.each(countriesData, function(c){
      c.id = c.iso3;
      c.text = c.printable_name + ' (' + c.iso3 + ')';
    });
    this.$el.select2({
      formatSelection: formatSelection,
      data: countriesData
    });
  }
});

module.exports = CountriesSelectView;