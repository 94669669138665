'use strict';

var Allocations = require('./allocations');
var MilestoneTypes = require('./milestonetypes');
var Asset = require('./asset');
var Messages = require('./messages');
var Research = require('./researchs');
var Files = require('./files');
var Reminders = require('./reminders');
var Correspondences = require('./correspondences');
var Costs = require('./costs');
var BookingRecords = require('../reporting/bookingrecords');
var ForecastingRecords = require('../reporting/forecastingrecords');
var PledgingRecords = require('../reporting/pledgingrecords');
var ClientPayments = require('./clientpayments');
var Milestones = require('./milestones');

var CaseFile = Backbone.Model.extend({
  urlRoot: '/api/cases/',
  parse: function(response){
    response.created = new Date(response.created);
    response.modified = new Date(response.modified);
    response.asset = new Asset(response.asset, {parse: true});
    response.allocations = new Allocations(response.allocations, {parse: true});
    response.messages = new Messages();
    response.research = new Research();
    response.milestones = new Milestones([]);
    response.files = new Files([], {
      urlRoot: this.url()+'/files/?page_size=5000',
      comparator: function(a, b) {
        var aFileName = a.get('original_file_name');
        var bFileName = b.get('original_file_name');
        return aFileName.toLowerCase().localeCompare(bFileName.toLowerCase());
      }
    });
    response.reminders = new Reminders([], {urlRoot: this.url()+'/reminders/?page_size=5000'});
    response.correspondence = new Correspondences([],
      {urlRoot: this.url()+'/correspondence/?page_size=5000'});
    response.costs = new Costs([], {urlRoot: this.url()+'/costs/?page_size=5000'});
    response.bookingRecords = new BookingRecords([], {
      urlRoot: this.url() + '/bookingrecords/?page_size=5000' });
    response.forecastingRecords = new ForecastingRecords([], {
      urlRoot: this.url() + '/forecastingrecords/?page_size=5000' });
    response.pledgingRecords = new PledgingRecords([], {
      urlRoot: this.url() + '/pledgingrecords/?page_size=5000' });
    response.clientPayments = new ClientPayments([],
      { urlRoot: this.url() + '/clientpayments/?page_size=5000' });

    return response;
  },
  getCurrentAllocation: function(){
    var status = this.get('status');
    // special handling for abandoned or concluded cases
    var allocation = this.get('allocations').find(function(allocation){
      return allocation.get('is_active');
    });
    if (allocation) {
      return allocation;
    } else if (status === CaseFile.STATUS_IDS.ABANDONED ||
        this.get('status') === CaseFile.STATUS_IDS.CONCLUDED) {
      allocation = this.get('allocations').max(function(allocation) {
        return allocation.get('created');
      });

      if (allocation === -Infinity) {
        return null;
      }

      return allocation;
    }
    return null;
  },
  fetchAllocations: function(){
    var allocations = this.get('allocations');
    allocations.urlRoot = this.url()+'/allocations/';
    return allocations.fetch();
  },
  fetchMessages: function(){
    var messages = this.get('messages');
    messages.urlRoot = this.url()+'/messages/?page_size=2000&ordering=-created';
    return messages.fetch();
  },
  fetchResearch: function() {
    var research = this.get('research');
    research.urlRoot = this.url()+'/research/';
    return research.fetch();
  },
  fetchMilestones: function() {
    var milestones = this.get('milestones');
    milestones.urlRoot = this.url()+'/milestones/';
    return milestones.fetch();
  },
  fetchFiles: function(){
    var sensitiveDataToken = TCAS.reqres.request('sensitiveDataToken');

    if (sensitiveDataToken) {
      return this.get('files').fetch({
        data: {
          'sensitive_data_token': sensitiveDataToken
        }
      });
    } else {
      return this.get('files').fetch();
    }
  },
  fetchReminders: function(){
    return this.get('reminders').fetch();
  },
  fetchCosts: function(){
    return this.get('costs').fetch();
  },
  fetchBookingRecords: function() {
    return this.get('bookingRecords').fetch();
  },
  fetchForecastingRecords: function() {
    return this.get('forecastingRecords').fetch();
  },
  fetchPledgingRecords: function() {
    return this.get('pledgingRecords').fetch();
  },
  fetchClientPayments: function() {
    return this.get('clientPayments').fetch();
  },
  toJSON: function(){
    var data = Backbone.Model.prototype.toJSON.call(this);
    if(this.get('asset')) {
      data.asset = this.get('asset').toJSON();
    }

    if(this.get('allocations')) {
      data.allocations = this.get('allocations').toJSON();
    }

    return data;
  },
  checkResearchSignedOff: function() {
    var that = this;
    if(!that.get('milestones').isEmpty()) {
      if(that.get('milestones').findWhere({
          milestone_type: 6 // jshint ignore:line
        })) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  makeRef: function() {
    //jshint camelcase:false
    var id;
    if(this.get('legacy_id')) {
      id = this.get('legacy_id');
    } else if (this.get('id')){
      id = this.get('id');
    } else {
      id = '00000';
    }

    var asset_holder_name = this.get('asset').get('asset_holder_name');
    asset_holder_name = asset_holder_name.replace('; ', ' ');
    asset_holder_name = asset_holder_name.replace(';', ' ');
    asset_holder_name = asset_holder_name.split(' ');
    this.set('ref', id + ' ' + asset_holder_name[asset_holder_name.length-1]);
  }
});

CaseFile.STATUSES = {
  UNALLOCATED: 'unallocated',
  RESEARCH: 'research',
  IN_CLAIMS: 'in claims',
  ABANDONED: 'abandoned',
  CONCLUDED: 'concluded'
};

CaseFile.STATUS_IDS = {
  UNALLOCATED: 0,
  RESEARCH: 1,
  IN_CLAIMS: 2,
  ABANDONED: 3,
  CONCLUDED: 4
};

CaseFile.STATUS_NAMES = {
  '0': 'Unallocated',
  '1': 'Research',
  '2': 'In claims',
  '3': 'Abandoned',
  '4': 'Concluded'
};

CaseFile.CREATED_BY_CSV = 0;
CaseFile.CREATED_BY_FORM = 1;

module.exports = CaseFile;