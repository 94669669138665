'use strict';
require('babel-polyfill');
require('./marionette-shim');
require('./handlebars-helper');
require('./handlebars-partial');
require('./views/behaviours');
var Handlebars = require('hbsfy/runtime');

var MainController = require('./controller/main-controller');
var EventsController = require('./controller/events-controller');
var CasesController = require('./controller/cases-controller');
var EmailsController = require('./controller/emails-controller');
var SurveyController = require('./controller/survey-controller');
var UsersController = require('./controller/users-controller');
var TimetrackController = require('./controller/timetrack-controller');
var ReportingController = require('./controller/reporting-controller');
var WebsitesController = require('./controller/websites-controller');
var Router = require('./router');

var NavigationSidebarView = require('./views/nav-sidebar-slider');
var NavSidebarFixedView = require('./views/nav-sidebar-fixed');
var SearchView = require('./views/search-view');
var ProfileWidgetView = require('./views/profile-widget');
var CookiesBar = require('./views/cookies-bar');
var SensitiveDataModal = require('./views/users/sensitive-data-modal');
var ArchiveDataModal = require('./views/users/archive-data-modal');

var Session = require('./models/users/session');
var SensitiveDataTokens = require('./models/users/sensitivedatatokens');
var ArchiveDataTokens = require('./models/users/archivedatatokens');
var CategoryGroups = require('./models/cases/categorygroups');
var Categories = require('./models/cases/categories');
var CaseFile = require('./models/cases/casefile');
var CaseFiles = require('./models/cases/casefiles');
var CaseProspects = require('./models/cases/caseprospects');
var CaseProspect = require('./models/cases/caseprospect');
var Contacts = require('./models/users/contacts');
var Contact = require('./models/users/contact');
var FinancialServices = require('./models/cases/financialservices');
var Users = require('./models/users/users');
var User = require('./models/users/user');
var Teams = require('./models/users/teams');
var TeamAllocations = require('./models/users/teamallocations');
var Events = require('./models/cases/events');

// add a serializeAll function to jquery to include disabled fields
(function($) {
  $.fn.serializeAll = function() {
    var data = $(this).serializeArray();

    $(':disabled[name]', this).each(function() {
      data.push({ name: this.name, value: $(this).val() });
    });

    return data;
  };
})(jQuery);

$(function() {
  var app = new Backbone.Marionette.Application();
  //configure jQuery to use csrfToken in headers for ajax requess
  app.csrfToken = window.TCASBootstrapData.csrfToken;
  app.countries = window.TCASBootstrapData.countries;
  $.ajaxSetup({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRFToken', app.csrfToken);
      // if there is a valid sensitive data token, send it in a header
      var token = app.sensitiveDataTokens.getValidToken();
      if (token) {
        xhr.setRequestHeader('X-SDToken', token);
      }
      // if there is a valid archive data token, send it in a header
      token = app.archiveDataTokens.getValidToken();
      if (token) {
        xhr.setRequestHeader('X-ArchiveToken', token);
      }
    },
  });

  app.session = new Session();
  app.sensitiveDataTokens = new SensitiveDataTokens();
  app.archiveDataTokens = new ArchiveDataTokens();
  app.categories = new Categories();
  app.categoryGroups = new CategoryGroups();
  app.casefiles = new CaseFiles();
  app.users = new Users();
  app.prospects = new CaseProspects();
  app.contacts = new Contacts();
  app.financialServices = new FinancialServices();
  app.teams = new Teams();
  app.teamAllocations = new TeamAllocations();
  app.events = new Events();

  app.addRegions({
    profile: '#profile-widget',
    search: '#search',
    mainBody: '#tcas-container',
    navSidebarFixed: '#nav-sidebar-fixed',
    navSidebar: '#nav-sidebar',
  });

  function fetchData(callback) {
    // fetch data tokens first
    $.when
      .apply($, [app.sensitiveDataTokens.fetch(), app.archiveDataTokens.fetch()])
      .done(function() {
        /*jshint camelcase:false */
        app.financialServices.fetch();
        app.categoryGroups.fetch({
          success: function() {
            app.categories.fetch({
              success: function(categories) {
                $('.category-field').each(function() {
                  var $categoryField = $(this);
                  var categoryId = parseInt($categoryField.attr('data-category-id'));
                  var field = $categoryField.attr('data-category-field');
                  var category = categories.get(categoryId);
                  $categoryField.html(Handlebars.Utils.escapeExpression(category.get(field)));
                });

                app.teamAllocations.urlRoot =
                  '/api/teamallocations/' + '?page_size=1000&is_active=True';
                app.users.urlRoot = '/api/users/?page_size=10000&groups=';
                app.users.urlRoot += User.CASE_WORKER_GROUPS.join('&groups=');
                app.users.urlRoot +=
                  '&groups=' + User.GROUPS.AFFILIATE + '&groups=' + User.GROUPS.ACCOUNTS;

                app.teamAllocations.fetch({
                  success: function() {
                    app.users.fetch();
                    app.teams.fetch();
                    if (callback) {
                      callback();
                    }
                  },
                });
              },
            });
          },
        });
      });
  }

  var _sync = Backbone.sync;
  Backbone.sync = function(method, model, options) {
    // Add trailing slash to backbone model views
    var _url = _.isFunction(model.url) ? model.url() : model.url;
    _url = options.url ? options.url : _url;
    var que = _url.indexOf('?') > -1 ? true : false;

    if (que) {
      return _sync(method, model, options);
    } else {
      _url += _url.charAt(_url.length - 1) == '/' ? '' : '/';
      options = _.extend(options, {
        url: _url,
      });
      return _sync(method, model, options);
    }
  };

  app.on('start', function() {
    if (Backbone.history) {
      Backbone.history.start({ pushState: true, silent: true });
      var route = Backbone.history.fragment || 'login';
      Backbone.history.fragment = '';

      //test for a valid fragment, if not valid, use 404 page
      if (!this.router.hasRoute(route)) {
        app.commands.execute('404', route);
        route = null;
      }

      app.session.on(
        'authenticated',
        function() {
          fetchData(function() {
            if (route) {
              if (route.indexOf('users/reset_password') === 0 || route.indexOf('users/reset_2fa') === 0) {
                route = 'login';
              }
              if (route === 'login') {
                if (
                  app.session.hasPerm('view_allocated_casefile') ||
                  app.session.hasPerm('view_team_casefile') ||
                  app.session.hasPerm('view_supervised_casefile') ||
                  app.session.hasPerm('view_all_casefile')
                ) {
                  route = 'reminders';
                } else {
                  if (app.session.hasPerm('view_accounts_casefile')) {
                    route = 'accounts/cases';
                  } else {
                    route = 'client/cases';
                  }
                }
              } else if (
                route.indexOf('/survey') === -1 &&
                route.indexOf('client/cases') === -1 &&
                route.indexOf('accounts/cases') === -1 &&
                ['email', '/survey'].indexOf(route) === -1 &&
                !(
                  app.session.hasPerm('view_allocated_casefile') ||
                  app.session.hasPerm('view_team_casefile') ||
                  app.session.hasPerm('view_supervised_casefile') ||
                  app.session.hasPerm('view_all_casefile')
                )
              ) {
                // must be a client or accounts, make sure they get directed to cases list
                if (app.session.hasPerm('view_accounts_casefile')) {
                  route = 'accounts/cases';
                } else {
                  route = 'client/cases';
                }
              }
              app.router.navigate(route, { trigger: true });
              if (route === 'logout') {
                route = 'reminders';
              }
            }
            $('html')
              .addClass('authenticated')
              .removeClass('unauthenticated');

            app.navSidebar.currentView.render();
            app.navSidebarFixed.currentView.render();
            //render search view only if user is not a client
            if (app.session.hasPerm('search_casefile')) {
              var searchView = new SearchView();
              app.search.show(searchView);
            } else {
              app.search.empty();
            }
            //var profileWidgetView = new ProfileWidgetView({model: app.session});
            app.profile.currentView.render();

            // app.slidebars = new $.slidebars().slidebars;
          });
        },
        this
      );
      app.session.on(
        'unauthenticated',
        function() {
          if (route) {
            if (
              route.indexOf('users/reset_password') === 0 ||
              route.indexOf('users/reset_2fa') === 0 ||
              route === 'login/forgot-password' ||
              (route.indexOf('survey/') === 0 && route.indexOf('survey/report') === -1)
            ) {
              app.router.navigate(route, { trigger: true, replace: true });
              // route = 'reminders';
            } else {
              this.router.navigate('login', { trigger: true, replace: true });
            }

            $('html').removeClass('authenticated');

            if (route.indexOf('survey/') === 0) {
              $('html').addClass('unauthenticated');
            }
          }
        },
        this
      );

      app.session.fetch();

      // check if cookies bar needs to be shown
      var cookiesAccepted = localStorage.getItem('cookiesAccepted');
      if (!cookiesAccepted) {
        var cookiesBar = new CookiesBar();
        cookiesBar.render();
      }
    }
  });

  app.on('start', function() {
    $.validator.setDefaults({
      highlight: function(element) {
        $(element)
          .closest('.form-group')
          .addClass('has-error');
      },
      unhighlight: function(element) {
        $(element)
          .closest('.form-group')
          .removeClass('has-error');
      },
      errorElement: 'span',
      errorClass: 'help-block',
      errorPlacement: function(error, element) {
        if (element.parent('.input-group').length) {
          error.insertAfter(element.parent());
        } else {
          if (element.parents('.form-group')) {
            error.appendTo(element.parents('.form-group'));
          } else {
            error.insertAfter(element);
          }
        }
      },
    });
    // add passwordComplexity validation rule
    $.validator.addMethod(
      'passwordComplexity',
      function(value, element) {
        return (
          this.optional(element) ||
          (/[a-z]/.test(value) && // has a lowercase letter
          /[A-Z]/.test(value) && // has an uppercase letter
          /[!£$\?@#*]/.test(value) && // contains a symbol
            /\d/.test(value))
        ); // has a digit
      },
      'Passwords are case sensitive, must be at least 8 characters and contain at least an upper and lowercase letter, a number and at least one symbol (! £ $ ? @ # *).'
    );

    // add secretComplexity validation rule
    $.validator.addMethod(
      'secretComplexity',
      function(value, element) {
        return value.length >= 8 && value.length <= 16;
      },
      'Secret word or phrase case is sensitive, can contain spaces and must be between 8 and 16 characters long.'
    );

    // add file name alphanumeric rule
    $.validator.addMethod(
      'alphaNumericFile',
      function(value, element) {
        return this.optional(element) || /^[a-zA-Z0-9'_()&+£%. -]+\.[a-zA-Z0-9]{3,4}$/.test(value);
      },
      'File names must include a file extension and may only contain ' +
        'characters consisting of: A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, ' +
        'fullstops, apostrophes or spaces.'
    );
  });

  app.addInitializer(function() {
    var navSidebarView = new NavigationSidebarView();
    app.navSidebar.show(navSidebarView);
    var navSidebarFixedView = new NavSidebarFixedView();
    app.navSidebarFixed.show(navSidebarFixedView);
    var profileWidgetView = new ProfileWidgetView({
      model: app.session,
      sensitiveDataTokens: app.sensitiveDataTokens,
      archiveDataTokens: app.archiveDataTokens,
    });
    app.profile.show(profileWidgetView);
  });

  //configuring commands and requests
  app.addInitializer(function() {
    //deferred object user map, keys in format user[id]
    app.deferredUsers = {};
    app.deferredProspects = {};
    app.deferredContacts = {};

    app.commands.setHandler('404', function(route) {
      app.mainController.notFound(route);
    });

    app.commands.setHandler('403', function(route) {
      app.mainController.noPermission(route);
    });

    app.commands.setHandler('enableSensitiveData', function(callback) {
      var sensitiveDataModal = new SensitiveDataModal({
        collection: app.sensitiveDataTokens,
      });
      sensitiveDataModal.render();
      app.listenTo(sensitiveDataModal, 'passwordSuccess', function() {
        if (callback) {
          callback();
        }
      });
    });

    app.commands.setHandler('enableArchiveData', function(callback) {
      var archiveDataModal = new ArchiveDataModal({
        collection: app.archiveDataTokens,
      });
      archiveDataModal.render();
      app.listenTo(archiveDataModal, 'passwordSuccess', function() {
        if (callback) {
          callback();
        }
      });
    });

    app.commands.setHandler('trackEvent', function(event, caseId) {
      var eventData = {
        event: event,
        case: caseId,
      };
      app.events.create(eventData, { wait: true });
    });

    app.commands.setHandler('popoutCase', function(casefile, isEdit) {
      function openCasefilePopup(casefile) {
        window.open(
          '/cases/' + casefile.get('id') + (isEdit ? '/edit' : ''),
          'Case detail ' + casefile.get('ref'),
          'width=1024,height=800,toolbar=no,location=no,directories=no,' +
            'status=yes,menubar=no,scrollbars=yes,copyhistory=no,resizable=yes'
        );
      }
      if (typeof casefile === 'number') {
        var caseId = casefile;
        casefile = new CaseFile({ id: caseId });
        casefile.fetch().then(function() {
          openCasefilePopup(casefile);
        });
      } else if (casefile instanceof CaseFile) {
        openCasefilePopup(casefile);
      }
    });

    app.commands.setHandler('popoutURL', function(url, title, width, height) {
      window.open(
        url,
        title,
        'width=' +
          width +
          ',height=' +
          height +
          ',toolbar=no,location=no,directories=no,' +
          'status=yes,menubar=no,scrollbars=yes,copyhistory=no,resizable=yes',
        false
      );
    });

    // broadcast 'resize' events with the new dimensions when the window resizes
    var $window = $(window);
    $window.on(
      'resize',
      _.debounce(function() {
        app.vent.trigger('resize', $window.width(), $window.height());
      }, 200)
    );

    // listen for caseprospect change events
    // case prospects may be edited, the global collection should be kept in
    // sync, in case a handlebar helper needs a prospect name
    app.listenTo(app.vent, 'change:caseprospect', function(caseProspect) {
      var prospect = app.prospects.get(caseProspect.get('id'));
      if (prospect) {
        prospect.set(caseProspect.attributes);
      }
    });

    app.reqres.setHandler('user', function(userId) {
      var def = app.deferredUsers['user' + userId];
      userId = parseInt(userId);

      function findUser(userId) {
        var user = app.users.get(userId);
        if (user) {
          def.resolve(user);
        } else {
          //fetch user
          user = new User({ id: userId });
          user.fetch({
            success: function(model) {
              app.users.add(model);
              def.resolve(model);
            },
          });
        }
      }

      if (!def) {
        def = app.deferredUsers['user' + userId] = new $.Deferred();

        //if users have not been fetched yet, wait for sync event
        if (app.users.length === 0) {
          app.listenToOnce(app.users, 'sync', function() {
            findUser(userId);
          });
        } else {
          findUser(userId);
        }
      }

      return def.promise();
    });

    app.reqres.setHandler('categoryGroup', function(categoryId) {
      var category = app.categories.get(parseInt(categoryId, 10));
      if (category) {
        var categoryGroupId = category.get('category_group');
        var categoryGroup = app.categoryGroups.get(categoryGroupId);

        return categoryGroup;
      }
      return null;
    });

    app.reqres.setHandler('prospect', function(prospectId) {
      var def = app.deferredProspects['prospect' + prospectId];
      prospectId = parseInt(prospectId);

      function findProspect(prospectId) {
        var prospect = app.prospects.get(prospectId);
        if (prospect) {
          def.resolve(prospect);
        } else {
          //fetch user
          prospect = new CaseProspect({ id: prospectId });
          prospect.fetch({
            success: function(model) {
              def.resolve(model);
              app.prospects.add(model);
            },
          });
        }
      }

      if (!def) {
        def = app.deferredProspects['prospect' + prospectId] = new $.Deferred();
        findProspect(prospectId);
      }

      return def.promise();
    });

    app.reqres.setHandler('supervisorId', function(userId) {
      var teamAllocation = app.teamAllocations.find(function(allocation) {
        return allocation.get('user') === userId && allocation.get('is_active');
      });
      if (teamAllocation) {
        return teamAllocation.get('supervisor');
      }

      return null;
    });

    app.reqres.setHandler('contact', function(contactId) {
      var def = app.deferredContacts['contact' + contactId];
      contactId = parseInt(contactId);

      function findContact(contactId) {
        var contact = app.contacts.get(contactId);
        if (contact) {
          def.resolve(contact);
        } else {
          //fetch user
          contact = new Contact({ id: contactId });
          contact.fetch({
            success: function(model) {
              def.resolve(model);
              app.contacts.add(model);
            },
          });
        }
      }

      if (!def) {
        def = app.deferredContacts['contact' + contactId] = new $.Deferred();
        findContact(contactId);
      }

      return def.promise();
    });

    app.reqres.setHandler('teamId', function(userId) {
      var allocation = app.teamAllocations.find(function(teamAllocation) {
        return teamAllocation.get('is_active') && teamAllocation.get('user') === userId;
      });

      if (allocation) {
        return allocation.get('team');
      }
      return null;
    });

    app.reqres.setHandler('sensitiveDataToken', function() {
      return app.sensitiveDataTokens.getValidToken();
    });

    app.reqres.setHandler('archiveDataToken', function() {
      return app.archiveDataTokens.getValidToken();
    });
  });

  //configuring application routes
  app.addInitializer(function() {
    this.router = new Router();
    app.listenTo(this.router, 'route', function(route) {
      app.vent.trigger('route:change', route);
    });

    this.mainController = new MainController({ app: this });
    this.eventsController = new EventsController({ app: this });
    this.casesController = new CasesController({ app: this });
    this.emailsController = new EmailsController({ app: this });
    this.surveyController = new SurveyController({ app: this });
    this.usersController = new UsersController({ app: this });
    this.timetrackController = new TimetrackController({ app: this });
    this.reportingController = new ReportingController({ app: this });
    this.websitesController = new WebsitesController({ app: this });
    this.router.processAppRoutes(this.mainController, this.mainController.appRoutes);
    this.router.processAppRoutes(this.eventsController, this.eventsController.appRoutes);
    this.router.processAppRoutes(this.casesController, this.casesController.appRoutes);
    this.router.processAppRoutes(this.emailsController, this.emailsController.appRoutes);
    this.router.processAppRoutes(this.surveyController, this.surveyController.appRoutes);
    this.router.processAppRoutes(this.usersController, this.usersController.appRoutes);
    this.router.processAppRoutes(this.timetrackController, this.timetrackController.appRoutes);
    this.router.processAppRoutes(this.reportingController, this.reportingController.appRoutes);
    this.router.processAppRoutes(this.websitesController, this.websitesController.appRoutes);

    //intercept anchor link clicks to test for backbone routes
    $('body').on('click', 'a[href^="/"]', function() {
      //do nothing if anchor is disabled
      if ($(this).hasClass('disabled')) {
        return false;
      }
      var frag = $(this)
        .attr('href')
        .substr(1);
      if (app.router.hasRoute(frag)) {
        app.router.navigate(frag, { trigger: true });
        return false;
      }
    });
  });

  window.TCAS = app;

  app.start();
});
