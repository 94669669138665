'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-in-claims-row.hbs');

var IndividualMonthlyInClaimsRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click .popout': 'onClickPopoutCase'
  },
  onClickPopoutCase: function() {
    TCAS.commands.execute('popoutCase', this.model);

    return false;
  },
  serializeData: function() {
    var data = this.model.toJSON();
    // find the research report (6) milestones and fa sent (7) milestones
    var milestones = this.getOption('milestones');
    if (milestones.length) {
      var inClaims = milestones.where({ milestone_type: 13 })[0];
      var researchReport = milestones.where({ milestone_type: 6 })[0];
      data.inClaims = inClaims.toJSON();
      data.researchReport = researchReport.toJSON();
      data.isResearchReportThisYear =
        data.researchReport.created.getFullYear() === new Date().getFullYear();
    }
    return data;
  }
});

module.exports = IndividualMonthlyInClaimsRowView;
