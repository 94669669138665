// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"checkbox-inline pull-left\">\n          <label><input type=\"checkbox\" class=\"select-file\" /></label>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div>\n          "
    + container.escapeExpression(((helper = (helper = helpers.original_file_name || (depth0 != null ? depth0.original_file_name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"original_file_name","hash":{},"data":data}) : helper)))
    + " <span class=\"label label-danger label-tooltip\"\n            title=\"This file was detected as infected during a virus scan. Downloading has been disabled for your protection.\">Infected!</span>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "\n          <span class=\"label label-warning\">New</span>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div>\n          <a "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.file_download : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            class=\"file-link "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.file_download : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = helpers.original_file_name || (depth0 != null ? depth0.original_file_name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"original_file_name","hash":{},"data":data}) : helper)))
    + "</a>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.file_download || (depth0 != null ? depth0.file_download : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"file_download","hash":{},"data":data}) : helper)))
    + "\" ";
},"9":function(container,depth0,helpers,partials,data) {
    return "downloadable";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <span class=\"label label-warning label-tooltip\"\n          title=\"This file has yet to be scanned for viruses, download at your own risk, or check back later.\">Pending\n          scan</span>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " <span class=\"label label-warning\">New</span>";
},"14":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"label label-default label-tooltip\" title=\"This file relates to client correspondence\"><i\n            class=\"fa fa-envelope-o\"></i></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"label label-default label-tooltip\" title=\"This file was an attachment to a case note/email.\"><i\n            class=\"fa fa-comment\"></i></span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.clients_allowed : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-success label-tooltip\" data-html=\"true\"\n          title=\"This file is viewable by at least one client.\">Client viewable</span>";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"clients-allowed-container pull-right\">\n      <div class=\"form-group\">\n        <label class=\"control-label\" for=\"clients-allowed-for-file-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n          Clients allowed access:\n        </label>\n        <input type=\"text\" name=\"clients_allowed\" class=\"form-control clients-allowed\"\n          "
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"edit_file",{"name":"hasPerm","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data})) != null ? stack1 : "")
    + " id=\"clients-allowed-for-file-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" />\n      </div>\n      <div class=\"pull-right\">\n        <button type=\"button\" class=\"btn btn-default btn-xs cancel-clients-allowed\">Cancel</button>\n        <button type=\"button\" class=\"btn btn-primary btn-xs save-clients-allowed\" data-loading-text=\"Saving...\"\n          "
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"edit_file",{"name":"hasPerm","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data})) != null ? stack1 : "")
    + ">Save</button>\n      </div>\n    </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "";
},"24":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\" ";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"btn-group pull-right\">\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"edit_file",{"name":"hasPerm","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <button type=\"button\" class=\"btn btn-success btn-xs clients\"><i class=\"fa fa-users\"></i> Clients</button>\n    </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "      <button type=\"button\" class=\"btn btn-default btn-xs edit\"><i class=\"fa fa-pencil\"></i> Edit</button>\n      <button type=\"button\" class=\"btn btn-danger btn-xs delete-file\"><i class=\"fa fa-trash-o\"></i> Delete</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"row\" draggable=\"true\" data-file-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"col-md-8\">\n    <div class=\"row\">\n      <div class=\"col-md-9\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.enableSelection : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfected : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"col-md-3\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPending : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.correspondence : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_message_file : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div class=\"col-md-4\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showClients : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>";
},"useData":true});
