'use strict';

var moment = require('moment');
var template = require('../../../templates/reporting/timetrack-task-breakdown.hbs');
var TimeTrack = require('../../../models/timetrack/timetrack');

var TimetrackTaskBreakdownTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var activityData = this.getOption('activityData');
    var maxNumTasks = 0;

    activityData.forEach(function(data) {
      if (data.tasks.length > maxNumTasks) {
        maxNumTasks = data.tasks.length;
      }
    });

    return {
      collection: activityData,
      maxNumTasks: maxNumTasks
    };
  }
});

module.exports = TimetrackTaskBreakdownTableView;
