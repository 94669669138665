'use strict';
var Milestone = Backbone.Model.extend({
  urlRoot: '/api/milestones/',
  parse: function(response){
    response.created = new Date(response.created);
    response.modified = new Date(response.modified);
    return response;
  }
});

module.exports = Milestone;