'use strict';
var template = require('../../templates/users/user-create-caseload.hbs');
var alertTemplate = require('../../templates/alert.hbs');

var UserCaseloadView = Backbone.Marionette.CompositeView.extend({
  template: template,
  className: 'panel-body',
  ui: {
    'submit': '#save-caseload',
    'alertsArea': '.alerts-area'
  },
  events: {
    'click @ui.submit': 'submitForm'
  },
  submitForm: function(e) {
    e.preventDefault();
    var that = this;

    var errorCount = 0;
    // loop over collection and save all caseload models
    this.options.caseload.forEach(function(item) {
      //get input selector
      var value = $('#'+item.get('id')).val();

      if(value === '') {
        value = 0;
      }

      if(parseInt(item.get('value')) !== parseInt(value)) {
        item.set('value', value);
        item.save({}, {
          url: '/api/caseload/' + item.get('id') + '/',
          error: function() {
            errorCount = errorCount + 1;
          }
        });
      }
    });

    if(errorCount === 0) {
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'Caseload was saved!',
          type: 'success'
        }));
    } else {
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'An error occurred, one or more of your ' +
          'caseload values were not saved!',
          type: 'danger'
        }));
    }

    return false;
  },
  serializeData: function() {
    var data = this.options.user.toJSON();
    if(this.options.caseload) {
      data.caseload = this.options.caseload.toJSON();
    }
    return data;
  }
});

module.exports = UserCaseloadView;
