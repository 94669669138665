'use strict';
var Milestone = require('./milestone');
var BaseCollection = require('../base-collection');

var Milestones = BaseCollection.extend({
  urlRoot: '/api/milestones/',
  model: Milestone,
  comparator: function(milestone){
    return -milestone.get('created');
  }
});

module.exports = Milestones