'use strict';
var template = require('../../templates/emails/emails.hbs');
var Spinner = require('spin');
var EmailListView = require('./email-list');
var EmailDetailView = require('./email-detail');
var EmailMessages = require('../../models/emails/emailmessages');

var EmailsMainView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    inbox: '.email-inbox-container',
    detail: '.email-detail-container'
  },
  ui: {
    emailsPage: '.emails-page',
    resizeHandle: '.email-frame-divider'
  },
  events: {
    'mousedown @ui.resizeHandle': 'onStartResize',
    'mousemove': 'onResizeDrag',
    'mouseup': 'onStopResize'
  },
  onStartResize: function() {
    this.isResizing = true;
  },
  onResizeDrag: function(e) {
    if (this.isResizing) {
      var y = e.clientY;
      var relativeY = y - this.ui.emailsPage.offset().top;
      // make sure at least 100px available in either panel
      if (relativeY < 100) {
        relativeY = 100
      } else if (relativeY > (this.ui.emailsPage.height() - 100)) {
        relativeY = this.ui.emailsPage.height() - 100;
      }
      this.percent = (relativeY / this.ui.emailsPage.height()) * 100;
      this.resizeFrames();
    }
  },
  onStopResize: function() {
    this.isResizing = false;
  },
  onScroll: function(){
    var that = this;
    var scrollTop = this.inbox.$el.scrollTop();
    var scrollHeight = this.inbox.$el[0].scrollHeight;
    var height = this.inbox.$el.height();
    var isMaxScroll = (scrollHeight - height - scrollTop) < 5;
    if (isMaxScroll && this.collection.next) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.spinner.spin(this.inbox.$el[0]);
      this.collection.fetchNext();
    }
  },
  resizeFrames: function() {
    this.inbox.$el.css('bottom', (100 - this.percent) + '%');
    this.detail.$el.css('top', this.percent + '%');
    this.ui.resizeHandle.css('top', this.percent + '%');
    this.ui.emailsPage.height($(window).height() - 180);
  },
  onSync: function() {
    if (this.spinner) {
      this.spinner.stop();
    }
  },
  onShow: function() {
    var that = this;
    var emails = new EmailMessages();
    emails.setFilters({
      recipient_email_address: TCAS.session.get('email')
    });
    emails.fetch();

    this.collection = emails;

    this.inbox.show(new EmailListView({
      collection: emails
    }));

    this.listenTo(this.inbox.currentView, 'show:email',
      this.onShowEmailDetail);
    this.listenTo(emails, 'sync', this.onSync);

    this.ui.emailsPage.height($(window).height() - 180);

    $(window).on('resize.Emails', function() {
      that.resizeFrames();
    });
    var lazyScroll = _.bind(_.debounce(this.onScroll, 100), this);
    this.inbox.$el.on('scroll', lazyScroll);
  },
  onDestroy: function() {
    $(window).off('resize.Emails');
    this.inbox.$el.off('scroll');
  },
  onShowEmailDetail: function(email) {
    this.detail.show(new EmailDetailView({
      model: email
    }));
    this.detail.$el.scrollTop(0);
    this.resizeFrames();
  }
});

module.exports = EmailsMainView;
