'use strict';

var template = require('../templates/cookies-bar.hbs');
var InfoModalView = require('./info-modal');

var CookiesBarView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'cookies-bar',
  ui: {
    accept: '.accept',
    info: '.info'
  },
  events: {
    'click @ui.accept': 'onClickAccept',
    'click @ui.info': 'onClickInfo'
  },
  onClickAccept: function() {
    var $el = this.$el;
    var that = this;
    this.$el.removeClass('open');
    localStorage.setItem('cookiesAccepted', true);
    setTimeout(function() {
      $el.remove();
      that.destroy();
    }, 2000);
  },
  onClickInfo: function() {
    var infoModal = new InfoModalView({
      title: 'Cookie Details',
      body: '<p>This website sets 2 cookies in your browser, which are required for the website to work correctly. There are no tracking or third party cookies. The cookies set are described below:</p>' +
        '<table class="table"><tbody><tr><th class="col-xs-3">Cookie name</th><th class="col-xs-9">Information</th></tr>' +
        '<tr><td>sessionid</td><td>Needed for session authentication to work, to allow users to sign in and remember them between page reloads within the session. This cookie just contains a unique string.</td></tr>' +
        '<tr><td>csrftoken</td><td>This cookie is needed as a preventative measure to make form submissions secure, specifically to prevent cross-site request forgery. This cookie also contains a unique string.</td></tr>' +
        '</tbody></table>'
    });
    infoModal.render();
  },
  onRender: function() {
    $('body').append(this.$el);
    var $el = this.$el;
    setTimeout(function() {
      $el.addClass('open');
    }, 1000);
  }
});

module.exports = CookiesBarView;
