// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"unarchive-btn\" class=\"btn btn-sm btn-warning unarchive\" data-loading-text=\"Unarchiving...\">Unarchive Case</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"revert-from-abandon-btn\" class=\"btn btn-sm btn-info\">Revert Status</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"abandon-btn\" class=\"btn btn-sm btn-danger\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Abandon</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"8":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"abandon-btn\" class=\"btn btn-sm btn-danger\" disabled=\"disabled\">Abandon Pending</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"handback-btn\" class=\"btn btn-sm btn-danger\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Hand Back</button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"handback-btn\" class=\"btn btn-sm btn-danger\" disabled=\"disabled\">Hand Back Pending</button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"revert-research-btn\" class=\"btn btn-sm btn-info\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Flag as Case\n    Allocated</button>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"revert-claims-btn\" class=\"btn btn-sm btn-info\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Flag as In\n    Claims</button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"revert-fasent-btn\" class=\"btn btn-sm btn-info\">Un-mark FA Sent</button>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"revert-disclosure-btn\" class=\"btn btn-sm btn-info\">Un-mark Disclosure Sent</button>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"revert-booked-btn\" class=\"btn btn-sm btn-info\">Un-mark Case Booked</button>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"sign-off-btn\" class=\"btn btn-sm btn-success\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Sign Off Research\n    Report</button>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button class=\"btn btn-sm btn-success apply-sign-off\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Accept Research\n    Report Sign Off Request</button>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"sign-off-btn\" class=\"btn btn-sm btn-success\" disabled>Research Report Sign Off Pending</button>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"fa-sent-btn\" class=\"btn btn-sm btn-success\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">FA Sent</button>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"inclaims-btn\" class=\"btn btn-sm btn-success\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">In\n    Claims</button>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button class=\"btn btn-sm btn-success apply-claims\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Accept In Claims\n    Request</button>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"inclaims-btn\" class=\"btn btn-sm btn-success\" disabled>In Claims Pending</button>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"disclosure-sent-btn\" class=\"btn btn-sm btn-success\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Disclosure\n    Sent</button>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button class=\"btn btn-sm btn-success apply-disclosure\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Accept Disclosure\n    Sent Request</button>\n";
},"45":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"disclosure-sent-btn\" class=\"btn btn-sm btn-success\" disabled>Disclosure Sent Pending</button>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"case-booked-btn\" class=\"btn btn-sm btn-success\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Case\n    Booked</button>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.program(52, data, 0),"data":data})) != null ? stack1 : "");
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button class=\"btn btn-sm btn-success apply-booked\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Accept Case Booked\n    Request</button>\n";
},"52":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"case-booked-btn\" class=\"btn btn-sm btn-success\" disabled>Case Booked Pending</button>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button id=\"conclude-btn\" class=\"btn btn-sm btn-success\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Conclude\n    Case</button>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.program(59, data, 0),"data":data})) != null ? stack1 : "");
},"57":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button class=\"btn btn-sm btn-success apply-concluded\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.case_manager : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Accept Case\n    Concluded Request</button>\n";
},"59":function(container,depth0,helpers,partials,data) {
    return "  <button id=\"conclude-btn\" class=\"btn btn-sm btn-success\" disabled>Concluded Pending</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"perm-buttons pull-left\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showUnarchive : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revertFromAbandon : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAbandon : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAbandonPending : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.handBack : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showHandbackPending : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revertResearch : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revertClaims : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revertFASent : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revertDisclosure : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revertBooked : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n\n<div class=\"perm-buttons pull-right\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showSignOff : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showSignOffPending : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showFaSent : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showInClaims : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showInClaimsPending : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDisclosureSent : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDisclosureSentPending : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCaseBooked : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCaseBookedPending : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showConclude : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showConcludePending : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
