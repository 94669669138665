'use strict';
var template = require('../../templates/users/user-target-details.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var UserTargetListView = require('./user-target-list');
var UserTarget = require('../../models/reporting/usertarget');

var UserTargetDetailsView = Backbone.Marionette.LayoutView.extend({
  template: template,
  ui: {
    alertsArea: '.alerts-area',
    year: '.year'
  },
  regions: {
    userTargetList: '.user-target-list'
  },
  events: {
    'change @ui.year': 'onYearChanged'
  },
  behaviors: {
    SubmitForm: {
      message: 'user target details form',
      selector: 'form',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          minimum_revenue: {
            required: true,
            number: true
          },
          performance_revenue: {
            required: true,
            number: true
          },
          stretch_revenue: {
            required: true,
            number: true
          },
          research_reports_per_month: {
            required: true,
            number: true
          },
          cases_booked_per_month: {
            required: true,
            number: true
          },
          asset_case_conversion_rate: {
            required: true,
            number: true
          },
          la_case_conversion_rate: {
            required: true,
            number: true
          }
        }
      }
    }
  },
  onYearChanged: function() {
    var year = parseInt(this.ui.year.val(), 10);
    this.year = year;

    this.render();
  },
  submitData: function(data) {
    var that = this;
    var year = parseInt(data.year, 10);
    var userId = this.getOption('user').get('id');

    // re-normalise conversion ratios
    data.asset_case_conversion_rate = data.asset_case_conversion_rate / 100;
    data.la_case_conversion_rate = data.la_case_conversion_rate / 100;

    // find the model for the given year, if it does exist create one
    var model = this.getOption('userTargets').find(function(target) {
      return parseInt(target.get('year'), 10) === year &&
        target.get('user') === userId;
    });

    function onSuccess() {
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'User targets successfully.',
          type: 'success'
        })
      );
    }

    if (model) {
      model.save(data, { wait: true, success: onSuccess });
    } else {
      data.user = this.getOption('user').get('id');
      that.getOption('userTargets').create(data,
        { wait: true, success: onSuccess });
    }
  },
  onRender: function() {
    this.userTargetList.show(new UserTargetListView({
      collection: this.getOption('userTargets'),
      year: this.year
    }));
  },
  serializeData: function() {
    if (!this.year) {
      this.year = new Date().getFullYear();
    }
    var year = this.year;
    var userId = this.getOption('user').get('id');

    var model = this.getOption('userTargets').find(function(target) {
      return parseInt(target.get('year'), 10) === year &&
        target.get('user') === userId;
    });

    if (!model) {
      model = new UserTarget({
        year: year,
        user: this.getOption('user').get('id')
      });
    }

    var data = model.toJSON();
    data.user = this.getOption('user').toJSON();
    data.asset_case_conversion_rate = data.asset_case_conversion_rate * 100;
    data.la_case_conversion_rate = data.la_case_conversion_rate * 100;

    data.years = [];
    for (var i = 2015; i <= 2050; i += 1) {
      data.years.push(i);
    }

    return data;
  }
});

module.exports = UserTargetDetailsView;
