'use strict';

var ConfirmModal = require('../confirm-modal');
var Milestone = require('../../models/cases/milestone');
var MilestoneView = require('./milestone');
var MilestoneConditionsModalView = require('./milestone-conditions-modal');
var template = require('../../templates/milestones/milestone-list.hbs');
var MilestoneTypes = require('../../models/cases/milestonetypes');
var Request = require('../../models/cases/request');
var Spinner = require('spin');

var MilestoneListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: MilestoneView,
  childViewContainer: '.milestones',
  childViewOptions: function(model, index) {
    return {
      model: model,
      casefile: this.getOption('casefile'),
      isClient: this.getOption('isClient')
    };
  },
  initialize: function() {
    var that = this;
    this.casefile = this.options.casefile;
    this.spinner = new Spinner();

    this.listenTo(TCAS.vent, 'milestone:change:start', function() {
      that.spinner.spin(that.el);
    });

    this.listenTo(TCAS.vent, 'milestone:change:end', function() {
      setTimeout(function() {
        that.spinner.stop();
      }, 500);
    });
  },
  onShow: function() {
    var that = this;

    this.listenTo(this, 'childview:remove:milestone', function(childView) {
      TCAS.vent.trigger('milestone:change:start');
      this.removeMilestone(childView, that);
    });

    this.listenTo(this, 'childview:apply:milestone', function(childView) {
      TCAS.vent.trigger('milestone:change:start');
      this.applyMilestone(childView, that);
    });

    this.listenTo(this, 'childview:not-applicable:milestone', function(childView) {
      TCAS.vent.trigger('milestone:change:start');
      this.notApplicableMilestone(childView, that);
    });

    this.listenTo(TCAS.vent, 'status:change', that.render);
  },
  removeMilestone: function(childView, that) {
    var confirmModal = new ConfirmModal({
      body: 'Are you sure you want to remove this milestone?'
    });
    this.listenTo(confirmModal, 'confirmYes', function() {

      var milestone = childView.model.get('milestone');
      milestone.save({is_active: false}, { // jshint ignore:line
        success:function() {
          childView.model.set({
            checked: false,
            created: null,
            milestone: null
          });
          childView.render();
          TCAS.vent.trigger('casefile:status:changed');
          TCAS.vent.trigger('milestone:change:end');
        }
      });
    });
    this.listenTo(confirmModal, 'confirmNo', function() {
      TCAS.vent.trigger('milestone:change:end');
    });
    confirmModal.render();
  },
  applyMilestone: function(childView) {
    var that = this;
    var data = this.checkConditions(childView.model, that);
    if(!data.apply) {
      that.showConditionsModal(data);
      TCAS.vent.trigger('milestone:change:end');
    } else {
      that.saveMilestone(childView, that);
    }
  },
  notApplicableMilestone: function(childView, that) {
    var milestone = new Milestone({
      case: that.options.casefile.get('id'),
      milestone_type: parseInt(childView.model.get('id')), // jshint ignore:line
      owner: TCAS.session.get('id'),
      user: that.options.casefile.getCurrentAllocation().get('case_manager'),
      is_not_applicable: true
    });
    milestone.save().then(function() {
      childView.model.set('created', milestone.get('created'));
      childView.model.set('is_not_applicable', true);
      childView.model.set('checked', true);
      childView.model.set('milestone', milestone);
      childView.render();
      TCAS.vent.trigger('status:change');
      TCAS.vent.trigger('milestone:change:end');
    });
  },
  saveMilestone: function(childView, that) {
    if(childView.model.get('request_required')) {
      that.sendRequest(childView);
      return;
    } else {
      var milestone = new Milestone({
        case: this.options.casefile.get('id'),
        milestone_type: parseInt(childView.model.get('id')), // jshint ignore:line
        owner: TCAS.session.get('id'),
        user: this.options.casefile.getCurrentAllocation().get('case_manager')
      });
      milestone.save().then(function() {
        childView.model.set('created', milestone.get('created'));
        childView.model.set('checked', true);
        childView.model.set('milestone', milestone);
        childView.render();
        TCAS.vent.trigger('status:change');
        TCAS.vent.trigger('milestone:change:end');
      });
    }
  },
  checkConditions: function(milestoneType, that) {
    var data = {};
    data.apply = true;
    data.conditionList = new MilestoneTypes([]);
    data.casefile = this.getOption('casefile');

    milestoneType.get('conditions').forEach(function(condition) {
      var cond = that.collection.findWhere({id: condition});
      data.conditionList.add(cond);
      if(cond && !cond.get('checked')) {
        data.apply = false;
      }
    });
    return data;
  },
  showConditionsModal: function(data) {
    var conditionsModal = new MilestoneConditionsModalView(data);
    conditionsModal.render();
  },
  sendRequest: function(childView) {
    var description, action;
    if(childView.model.get('name') === 'Research Report Approved') {
      description = Request.DESCRIPTIONS.REQUEST_SIGN_OFF;
      action = Request.ACTIONS.SIGN_OFF;
    } else if (childView.model.get('name') === 'Conditions of Approval Report Signed Off') {
      description = Request.DESCRIPTIONS.REQUEST_CONDITIONS_OF_APPROVAL_REPORT;
      action = Request.ACTIONS.CONDITIONS_OF_APPROVAL_REPORT;
    } else if(childView.model.get('name') === 'Case Concluded') {
      description = Request.DESCRIPTIONS.REQUEST_CONCLUDE_CASE;
      action = Request.ACTIONS.CONCLUDE_CASE;
    } else if(childView.model.get('name') === 'In Claims') {
      description = Request.DESCRIPTIONS.REQUEST_IN_CLAIMS;
      action = Request.ACTIONS.IN_CLAIMS;
    } else if(childView.model.get('name') === 'Letter of Full Disclosure Sent') {
      description = Request.DESCRIPTIONS.REQUEST_DISCLOSURE_SENT;
      action = Request.ACTIONS.DISCLOSURE_SENT;
    } else if(childView.model.get('name') === 'Case Booked') {
      description = Request.DESCRIPTIONS.REQUEST_CASE_BOOKED;
      action = Request.ACTIONS.CASE_BOOKED;
    }

    var request = new Request({
      case: this.casefile.get('id'),
      owner: TCAS.session.get('id'),
      user: this.casefile.getCurrentAllocation().get('case_manager'),
      description: description,
      type: Request.TYPES.CASE,
      action: action,
      status: Request.STATUSES.PENDING
    });
    request.save({}, {
      success: function() {
        childView.model.set('pending', true);
        TCAS.vent.trigger('milestone:change:end');
        TCAS.vent.trigger('collect:milestones');
      },
      error: function() {
        console.log('request failed');
        TCAS.vent.trigger('milestone:change:end');
      }
    });
  },
  serializeData: function() {
    var data = this.collection.toJSON();
    data.isClient = this.getOption('isClient');

    return data;
  }
});

module.exports = MilestoneListView;