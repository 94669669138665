'use strict';

var Reminder = require('./reminder');
var BaseCollection = require('../base-collection');

var Reminders = BaseCollection.extend({
  urlRoot: '/api/reminders/',
  model: Reminder,
  comparator: function(reminder){
    return reminder.get('due_date').getTime();
  }
});

module.exports = Reminders;