// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "          <th><a href=\"#\" class=\"sortable\" data-sort=\"asset.assetvalue.capital_value\">Capital Value <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"clearfix\"></div>\n<div class=\"table-responsive\">\n  <table class=\"table casefiles hide\">\n    <thead>\n      <tr>\n        <th></th>\n        <th></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"ref\">Ref. <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"status\">Status <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"view_assetvalue",{"name":"hasPerm","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <th><a href=\"#\" class=\"sortable\" data-sort=\"allocation.created\">Date Allocated <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"allocation.case_manager.first_name\">Case Manager <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n      </tr>\n    </thead>\n  </table>\n</div>\n<div class=\"loading-bar\"></div>\n\n<div class=\"page-info-bar hide\">\n  <div class=\"page-info\">\n    <div class=\"row\">\n      <div class=\"col-md-4\">\n        <span class=\"case-count\"></span> of <span class=\"case-total\"></span>\n      </div>\n      <div class=\"col-md-8\">\n        <div class=\"progress\">\n          <div class=\"progress-bar\" role=\"progressbar\" style=\"width: 0%;\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});
