'use strict';

var template = require('../../templates/cases/reminders.hbs');
var Reminder = require('../../models/cases/reminder');
var Reminders = require('../../models/cases/reminders');
var RemindersTableView = require('./reminder-list');

var RemindersView = Backbone.Marionette.LayoutView.extend({
  className: 'reminders-page',
  template: template,
  ui: {
    'newReminder': 'button.new-reminder'
  },
  events: {
    'click @ui.newReminder': 'onClickNewReminder',
  },
  regions: {
    activeReminders: '.reminders-active',
    closedReminders: '.reminders-closed'
  },
  onClickNewReminder: function() {
    this.remindersView.showAddForm(true);
  },
  onShow: function(){
    var reminders = new Reminders([], {
      urlRoot: '/api/reminders/?page_size=1000&fao=' +
               this.getOption('userId') + '&status=' +
               Reminder.STATUSES.PENDING
    });
    var remindersClosed = new Reminders([], {
      urlRoot: '/api/reminders/?fao=' + this.getOption('userId') +
               '&status=' + Reminder.STATUSES.DISMISSED + '&status=' +
               Reminder.STATUSES.COMPLETE + '&ordering=-modified'
    });
    reminders.fetch();
    remindersClosed.fetch();

    this.listenTo(reminders, 'change:status', function(model, status){
      if (status === Reminder.STATUSES.COMPLETE ||
        status === Reminder.STATUSES.DISMISSED) {
        remindersClosed.add(model);
      }
    });

    this.remindersView = new RemindersTableView({
      collection: reminders,
      showCasefile: true,
      showAddForm: false,
      userId: this.getOption('userId')
    });
    this.listenTo(this.remindersView, 'reminder:add', function() {
      this.remindersView.showAddForm(false);
    });
    this.closedRemindersView = new RemindersTableView({
      collection: remindersClosed,
      showCasefile: true,
      showAddForm: false
    });

    this.activeReminders.show(this.remindersView);
    this.closedReminders.show(this.closedRemindersView);
  }
});

module.exports = RemindersView;