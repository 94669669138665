// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "      <td>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <td>\n        "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n      </td>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,depth0,">=",(depths[1] != null ? depths[1].conversionRateAssetTarget : depths[1]),{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,depth0,{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "class=\"bg-success\"";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,depth0,">=",(depths[1] != null ? depths[1].conversionRateLATarget : depths[1]),{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,depth0,{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"table table-condensed table-responsive\">\n  <thead>\n  <tr>\n    <th></th>\n    <th>Jan</th>\n    <th>Feb</th>\n    <th>Mar</th>\n    <th>Apr</th>\n    <th>May</th>\n    <th>Jun</th>\n    <th>Jul</th>\n    <th>Aug</th>\n    <th>Sep</th>\n    <th>Oct</th>\n    <th>Nov</th>\n    <th>Dec</th>\n  </tr>\n  </thead>\n  <tbody>\n  <tr>\n    <td>Actual</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalPerMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td>Actual YTD</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalYTD : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td colspan=\"13\" class=\"table-separator\"></td>\n  </tr>\n  <tr>\n    <td>Actual from "
    + container.escapeExpression(((helper = (helper = helpers.thisYear || (depth0 != null ? depth0.thisYear : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"thisYear","hash":{},"data":data}) : helper)))
    + " Research Reports</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.researchThisYear : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td>Actual YTD</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.researchThisYearYTD : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td colspan=\"13\" class=\"table-separator\"></td>\n  </tr>\n  <tr>\n    <td>Asset case conversion rate<br/>Research report > In Claims</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.conversionRateAsset : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td>Council & B/V case conversion rate<br/>Research report > In Claims</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.conversionRateLA : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  </tbody>\n</table>";
},"useData":true,"useDepths":true});
