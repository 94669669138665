// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"text-danger\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "&#x2714;";
},"5":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-xs btn-default edit\">Edit</button> <button type=\"button\" class=\"btn btn-xs btn-danger delete\"><i class=\"fa fa-trash-o\"></i></button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date_made : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.modified : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.payee || (depth0 != null ? depth0.payee : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"payee","hash":{},"data":data}) : helper)))
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.amount : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.amount : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3((helpers.prospectFullName || (depth0 && depth0.prospectFullName) || alias2).call(alias1,(depth0 != null ? depth0.case_prospect : depth0),{"name":"prospectFullName","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.invoice_number || (depth0 != null ? depth0.invoice_number : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"invoice_number","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_international : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"change_clientpayment",{"name":"hasPerm","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
