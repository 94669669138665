'use strict';

var CaseProspectRelation = require('./caseprospectrelation');
var BaseCollection = require('../base-collection');

var CaseProspectRelationss = BaseCollection.extend({
  urlRoot: '/api/caseprospectrelations/?page_size=0',
  model: CaseProspectRelation
});

module.exports = CaseProspectRelationss;