'use strict';
var template = require('../../templates/teams/team-members-list.hbs');
var templateInactive = require('../../templates/teams/team-members-inactive-list.hbs');
var TeamMembersRowView = require('./team-members-row');

var TeamMembersListView = Backbone.Marionette.CompositeView.extend({
  getTemplate: function() {
    if (this.getOption('showActive')) {
      return template;
    } else {
      return templateInactive;
    }
  },
  childView: TeamMembersRowView,
  childViewContainer: 'tbody',
  childViewOptions: function(model) {
    var team = this.getOption('team');
    return { team: team };
  },
  childEvents: {
    'add:teamallocation': 'onAddTeamAllocation',
  },
  filter: function(teamAllocation) {
    return teamAllocation.get('is_active') === this.getOption('showActive');
  },
  onAddTeamAllocation: function(childView, user, supervisor, group) {
    this.collection.create(
      {
        user: user,
        supervisor: supervisor,
        group: group,
        team: this.getOption('team').get('id'),
      },
      {
        success: function() {
          TCAS.vent.trigger('member:save:success');
        },
        error: function() {
          TCAS.vent.trigger('member:save:failed');
        },
      }
    );
  },
});

module.exports = TeamMembersListView;
