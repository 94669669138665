// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <form>\n          <div class=\"alert alert-info\">\n            Please enter your new password below.<br/><br/>\n            Your password must be at least 8 characters, contain at an upper and lowercase letter, a number and at least one symbol (! £ $ ? @ # *).'\n          </div>\n          <div class=\"form-group\">\n            <label for=\"user-password\">Password</label>\n            <input type=\"password\" id=\"user-password\" class=\"form-control\" name=\"password\" placeholder=\"Type your password here...\" required/>\n          </div>\n          <div class=\"form-group\">\n            <label for=\"user-password2\">Password again</label>\n            <input type=\"password\" id=\"user-password2\" class=\"form-control\" name=\"password2\" placeholder=\"Type password again...\" required/>\n          </div>\n          <button type=\"submit\" class=\"btn btn-primary\">Save</button>\n        </form>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-danger\" role=\"alert\">\n          <strong>Oops!</strong> This set password request has already been used.<br/>Please use the forgot password link found <a href=\"/login\">here</a>.\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"login-layout\">\n  <div class=\"login-body\">\n    <div class=\"login-panel\">\n      <div class=\"page-header\">\n        <img class=\"treethorpe-logo\" src=\"/static/frontend/img/TRE_Logo_Colour_RGB_Small.png\" alt=\"Treethorpe logo\" />\n      </div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_active : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>";
},"useData":true});
