'use strict';

var CategoryGroup = Backbone.Model.extend({
  urlRoot: '/api/categorygroup/',
  isAssetCase: function() {
    return [2, 3].indexOf(this.get('id')) === -1;
  },
  isCouncilCase: function() {
    return [2, 3].indexOf(this.get('id')) !== -1;
  }
});

module.exports = CategoryGroup;