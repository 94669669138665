'use strict';
var template = require('../../templates/users/user-list.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var UserRowView = require('./user-row');
var ConfirmModal = require('../confirm-modal');

var UserListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: UserRowView,
  childViewContainer: 'tbody',
  ui: {
    sortableField: 'a.sortable',
    includeInactiveUsers: '.include-inactive-users',
    alertsArea: '.alerts-area'
  },
  events: {
    'click @ui.sortableField': 'sortField',
    'click @ui.includeInactiveUsers': 'onChangeIncludeInactive'
  },
  childEvents: {
    'click:reset2FA': 'onClickReset2FA',
    'click:unlock': 'onClickUnlock'
  },
  onClickReset2FA: function(view) {
    var that = this;
    var fullName = view.model.getFullName();
    var confirmModal = new ConfirmModal({
      body: 'Are you sure you want to reset the 2 factor authentication for ' + fullName + '?'
    });
    this.listenTo(confirmModal, 'confirmYes', function() {
      var url = view.model.get('userdetail').url();
      url += '/reset_2fa/';
      $.getJSON(url, function() {
        view.model.fetch().then(function() {
          that.ui.alertsArea.html(
            alertTemplate({
              strong: 'Success!',
              message: 'The 2 Factor Authentication has been reset for this user.',
              type: 'success'
            })
          );
        });
      });
    });
    confirmModal.render();
  },
  onClickUnlock: function(view) {
    var that = this;
    var fullName = view.model.getFullName();
    var confirmModal = new ConfirmModal({
      body: 'Are you sure you want to unlock the account for ' + fullName + '?'
    });
    this.listenTo(confirmModal, 'confirmYes', function() {
      var url = view.model.get('userdetail').url();
      url += '/unlock/';
      $.getJSON(url, function() {
        view.model.fetch().then(function() {
          that.ui.alertsArea.html(
            alertTemplate({
              strong: 'Success!',
              message: 'The user account has been unlocked.',
              type: 'success'
            })
          );
        });
      });
    });
    confirmModal.render();
  },
  addChild: function(child, ChildView, index) {
    if (!this.ui.includeInactiveUsers.is(':checked') && !child.get('is_active')) {
      return;
    }

    Backbone.Marionette.CompositeView.prototype.addChild.apply(this,
      arguments);
  },
  onChangeIncludeInactive: function() {
    this.includeInactiveUsers = this.ui.includeInactiveUsers.is(':checked');
    this.render();
  },
  sortField: function(e) {
    var orderBy = $(e.currentTarget).attr('data-sort');
    orderBy = orderBy.replace(/\./g, '__');
    if (this.collection.orderBy && this.collection.orderBy[0] === orderBy) {
      orderBy = '-' + orderBy;
    }
    this.collection.orderBy = [orderBy];
    this.collection.fetch();
    return false;
  },
  serializeData: function() {
    return {
      includeInactiveUsers: this.includeInactiveUsers
    };
  }
});

module.exports = UserListView;
