'use strict';

var template = require('../../templates/survey/question-response-form.hbs');

var QuestionResponseFormView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var question = this.getOption('question');
    var answer = this.getOption('answer');
    var data = {
      question: question.toJSON(),
      editable: this.getOption('editable')
    };

    if (answer) {
      data.answer = answer.toJSON();
    }

    return data;
  }
});

module.exports = QuestionResponseFormView;
