'use strict';

var _ = require('lodash');
var template =
  require('../../../templates/reporting/individual-yearly-research-table.hbs');

var IndividualYearlyResearchTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var data = {};

    var researchCases = [];
    for (var i = 0; i < 12; i += 1) {
      researchCases[i] = [];
    }

    // group case ids by month
    this.getOption('researchMilestones').each(function(milestone) {
      var created = new Date(milestone.get('created'));
      researchCases[created.getMonth()].push(milestone.get('case'));
    });

    var researchByMonth = researchCases.map(function(researchCasesInMonth) {
      return _.unique(researchCasesInMonth).length;
    });
    var researchYTD = [];
    var targetResearchYTD = [];
    var targetResearchPerMonth =
      this.getOption('userTarget').get('research_reports_per_month');
    var total = 0;
    for (i = 0; i < 12; i += 1) {
      total += researchByMonth[i];
      researchYTD[i] = total;
      targetResearchYTD[i] = targetResearchPerMonth * (i + 1);
    }
    data.researchByMonth = researchByMonth;
    data.researchYTD = researchYTD;
    data.targetResearchPerMonth = targetResearchPerMonth
    data.targetResearchYTD = targetResearchYTD;

    return data;
  }
});

module.exports = IndividualYearlyResearchTableView;
