'use strict';
var Dropzone = require('dropzone');

var template = require('../../templates/messages/message-create.hbs');
var templateClient =
  require('../../templates/messages/message-create-client.hbs');
var templateAccounts = require('../../templates/messages/message-create-accounts.hbs');
var templateEmailChecklist = require('../../templates/modal-confirm-email-check.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var ConfirmModal = require('../confirm-modal');
var FileSelectView = require('../cases/file-select');
var Message = require('../../models/cases/message');
var User = require('../../models/users/user');

$(function(){
  $.validator.addMethod('maxMessageNesting', function(value, element, params){
    var $parentMessage = $(element).parents('.message-list')
      .find('.message.active');
    if ($parentMessage.length) {
      var matches = /nest-(\d)/.exec($parentMessage.attr('class'));
      if (matches) {
        var parentLevel = matches[1];
        return parentLevel <= params - 1;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, $.validator.format('Messages can not be nested more than {0} levels.'));
});

var MessageCreateView = Backbone.Marionette.LayoutView.extend({
  behaviors: {
    SubmitForm: {
      submitHandler: 'save'
    },
    Validate: {
      validateOptions: {
        rules: {
          content: {
            required: true,
            maxMessageNesting: 5
          },
          subject: {
            maxlength: 200
          },
          fao_clients: {
            required: true
          }
        },
        ignore: '.ignore'
      }
    }
  },
  regions: {
    fileSelectionRegion: '.file-selection-container'
  },
  ui: {
    form: 'form',
    submitBtn: 'button.submit',
    draftBtn: 'button.draft',
    attachBtn: 'button.attach',
    attachExistingBtn: 'button.attach-existing',
    faoSelect: '.fao-select',
    faoClientsSelect: '.fao-clients-select',
    faoClientsContainer: '.fao-clients-container',
    dropzonePreviews: '.dropzone-previews',
    message: 'textarea',
    subject: 'input.subject'
  },
  events: {
    'click @ui.clientViewable': 'onChangeClientViewable',
    'click @ui.draftBtn': 'saveDraft',
    'click @ui.attachExistingBtn': 'onClickAttachExisting'
  },
  collectionEvents: {
    'change:selected': 'onMessageSelectionChange'
  },
  initialize: function() {
    this.listenToOnce(TCAS.users, 'sync', this.setupSelect2);
    this.listenTo(this.getOption('clients'), 'sync', this.setupSelect2);
  },
  getTemplate: function(){
    if (TCAS.session.hasGroup(User.GROUPS.CLIENT)) {
      return templateClient;
    } else if (TCAS.session.hasGroup(User.GROUPS.ACCOUNTS)) {
      return templateAccounts;
    } else {
      return template;
    }
  },
  onMessageSelectionChange: function(message, selected){
    if (selected) {
      this.ui.message.focus();
    }
  },
  onClickAttachExisting: function() {
    this.fileSelectView = new FileSelectView({
      casefile: this.getOption('casefile'),
      multiple: true
    });
    this.fileSelectionRegion.show(this.fileSelectView);
  },
  doSave: function(data) {
    var that = this;
    var selectedMessage = this.collection.find(function(message){
      return message.get('selected');
    });
    data.casefile = this.getOption('casefile').get('id');
    if (selectedMessage) {
      data.message = selectedMessage.get('id');
      data.type = Message.TYPES.REPLY;
    } else {
      data.type = Message.TYPES.MESSAGE;
      data.parents = [];
    }
    data.owner = TCAS.session.get('id');
    if (data.fao) {
      data.fao = data.fao.split(',');
    } else {
      data.fao = [];
    }
    /* jshint ignore:start */
    if (data.fao_clients) {
      data.fao_clients = data.fao_clients.split(',');
    } else {
      data.fao_clients = [];
    }

    function doCreateMessage(fileIds) {
      /* jshint ignore:end */
      data.attachments = fileIds;
      that.collection.create(data, {
        wait: true,
        success: function (message) {
          that.collection.each(function (message) {
            message.set('highlight', false);
          });
          message.set('highlight', true);
          message.trigger('requestScroll', message);
          that.render();
        }
      });
    }

    if (this.dropzone.getQueuedFiles().length) {
      this.listenTo(this, 'attachment:success', function(fileIds) {
        this.stopListening(this, 'attachment:success');
        if (this.fileSelectView) {
          var files = this.fileSelectView.getSelectedFile();
          fileIds = fileIds.concat(files);
        }
        doCreateMessage(fileIds);
      });
      this.dropzone.processQueue();
    } else {
      if (this.fileSelectView) {
        var files = this.fileSelectView.getSelectedFile();
        doCreateMessage(files);
      } else {
        doCreateMessage([]);
      }
    }
  },
  save: function(data) {
    if (data.fao_clients && data.fao_clients.length &&
        !TCAS.session.hasAnyGroups([User.GROUPS.CLIENT, User.GROUPS.ACCOUNTS])) {
      // show email check list dialog
      var confirmModal = new ConfirmModal({
        template: templateEmailChecklist
      });
      this.listenTo(confirmModal, 'confirmYes', function() {
        data.is_published = true;
        this.doSave(data);
      });
      confirmModal.render();
    } else {
      data.is_published = true;
      this.doSave(data);
    }
  },
  saveDraft: function() {
    if (this.ui.form.valid()) {
      var data = this.ui.form.serializeAll();

      var formData = {};
      _.map(data, function(n, i) {
        formData[n.name] = n.value;
      });
      this.doSave(formData);
    }
  },
  setupSelect2: function() {
    var that = this;
    var $faoSelect = this.ui.faoSelect;
    var $faoClientsSelect = this.ui.faoClientsSelect;
    var clientId = this.getOption('clientId');

    var caseWorkers = TCAS.users.filter(function(user){
      return user.hasAnyGroups(User.CASE_WORKER_GROUPS);
    });
    caseWorkers = _.map(caseWorkers, function(user){
      return {id: user.get('id'), text: user.getFullName()};
    });

    if (this.getOption('clients')) {
      var clients = this.getOption('clients').filter(function(client) {
        return client.get('contact').get('email');
      }).map(function (client) {
          return {id: client.get('id'), text: client.getFullName()};
        });
    }

    // need to defer as the placeholder wasn't showing up
    _.defer(function() {
      $faoSelect.select2({
        multiple: true,
        allowClear: true,
        placeholder: 'Select people to notify...',
        data: caseWorkers
      });
      if (clients) {
        $faoClientsSelect.select2({
          multiple: true,
          allowClear: true,
          placeholder: 'Select Clients to notify...',
          data: clients
        });
        if (clientId) {
          that.ui.faoClientsSelect.select2('val', clientId);
          that.ui.faoClientsSelect.select2('enable', false);
        }
      }
    });
  },
  onShow: function() {
    if (!TCAS.session.hasAnyGroups([User.GROUPS.CLIENT, User.GROUPS.ACCOUNTS])) {
      this.ui.message.summernote({
        height: 200,
        toolbar: [
          // [groupName, [list of button]]
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['paragraph', 'ul', 'ol']],
          ['insert', ['link']],
          ['codeview', ['codeview']],
          ['undo', ['undo', 'redo']]
        ]
      });
    }
  },
  onRender: function() {
    var that = this;
    this.fileIds = [];
    this.setupSelect2();
    var casefile = this.getOption('casefile');
    var caseId = casefile.get('id');

    //configure dropzone
    this.dropzone = new Dropzone(this.ui.attachBtn[0], {
      url: '/api/cases/' + caseId + '/files/',
      headers: {'X-CSRFToken': TCAS.csrfToken},
      previewsContainer: this.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      clickable: this.ui.attachBtn[0],
      maxFilesize: 500,
      maxFiles: 10,
      parallelUploads: 10,
      acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.eml,.msg,.ged',
      autoProcessQueue: false,
      accept: function(file, done){
        var alphaNumericPattern = new RegExp(
          '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
        );
        // check for only alphanumeric characters
        if (alphaNumericPattern.test(file.name)) {
          done();
        } else {
          done('This file has unexpected characters, ' +
            'file names must use the correct file naming structure and ' +
            'only contain characters consisting of: ' +
            'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
            'apostrophes, commas or spaces.');
        }
      }
    });

    this.dropzone.on('success', function(file, response){
      that.fileIds.push(response.id);
      that.$(file.previewElement).find('.progress-bar')
        .addClass('progress-bar-success');
    });
    this.dropzone.on('queuecomplete', function() {
      that.trigger('attachment:success', that.fileIds);
      // fetch files again on the case so the new files will be seen
      casefile.fetchFiles();
    });
    this.dropzone.on('error', function(file){
      that.$(file.previewElement).find('button').removeClass('hide');
      that.trigger('attachment:error');
    });
    this.dropzone.on('uploadprogress', function(file, progress){
      console.log('file upload progress: '+progress);
    });
    this.dropzone.on('sending', function(file, xhr, formData) {
      formData.append('is_message_file', true);
      formData.append('casefile',  caseId);
      if (that.getOption('clientId')) {
        var clientIds = [that.getOption('clientId')];
      } else if (that.getOption('enableFAOClients')) {
        clientIds = that.ui.faoClientsSelect.select2('val');
      }

      if (clientIds) {
        clientIds.forEach(function(clientId) {
          formData.append('clients_allowed', clientId);
        });
      }

      // Show the total progress bar when upload starts
      that.$(file.previewElement).find('.progress').removeClass('hide');
    });
  },
  serializeData: function(){
    var data = {
      isClient: TCAS.session.hasAnyGroups([User.GROUPS.CLIENT, User.GROUPS.ACCOUNTS]),
      clientId: this.getOption('clientId'),
      enableFAOClients: this.getOption('enableFAOClients')
    };

    if(this.getOption('casefile').getCurrentAllocation()) {
      data.fao = this.getOption('casefile').getCurrentAllocation()
        .get('case_manager');
    }

    if (data.clientId) {
      data.subject = TCAS.session.getFullName() +
        ' sent you a message related to your Treethorpe Case ref. ' +
        this.getOption('casefile').get('ref');
    }

    return data;
  },
});

module.exports = MessageCreateView;