'use strict';
var template = require('../../templates/users/user-row.hbs');
var User = require('../../models/users/user');

var UserRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  className: 'user-row',
  ui: {
    reset2FA: '.reset-2fa',
    unlock: '.unlock'
  },
  triggers: {
    'click @ui.reset2FA': 'click:reset2FA',
    'click @ui.unlock': 'click:unlock'
  },
  modelEvents: {
    'sync': 'render'
  },
  model: User,
  serializeData: function() {
    var data = this.model.toJSON();
    data.admin = this.model.hasGroup(User.GROUPS.ADMIN);
    data.group = this.model.getGroup();
    return data;
  }
});

module.exports = UserRowView;