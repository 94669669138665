'use strict';

var Contact = Backbone.Model.extend({
  urlRoot: '/api/contacts/',
  getFullName: function(includeMiddleName) {
    var s = this.get('first_name');
    if (includeMiddleName && this.get('middle_name')) {
      s += ' ' + this.get('middle_name');
    }
    s += ' ' + this.get('last_name');
    return s;
  },
  getFullNameWithSurnameAtBirth: function(includeMiddleName) {
    var s = this.get('first_name');
    if (includeMiddleName && this.get('middle_name')) {
      s += ' ' + this.get('middle_name');
    }
    s += ' ' + this.get('last_name').toUpperCase();

    if (this.get('surname_at_birth')) {
      s += ' (' + this.get('surname_at_birth').toUpperCase() + ')';
    }
    return s;
  },
});

module.exports = Contact;
