'use strict';
var Dropzone = require('dropzone');
var moment = require('moment');

var template = require('../../templates/cases/casefile-detail.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var MessageListView = require('../messages/message-list');
var ResearchListView = require('../research/research-list');
var AllocationsPanelView = require('../allocations/allocation-panel');
var CaseManagerFilterView = require('../allocations/casemanager-filter');
var HistoryView = require('../history/casefile-history');
var MilestoneListView = require('../milestones/milestone-list');
var MilestoneTypes = require('../../models/cases/milestonetypes');
var LifecycleLayout = require('../milestones/lifecycle-layout');
var CaseFileDetailsView = require('./casefile-details-list');
var FileListView = require('./file-list');
var ClientListView = require('../clients/client-list');
var CorrespondenceLayoutView = require('../clients/correspondence-layout');
var ReminderListView = require('./reminder-list');
var CostListView = require('../costs/cost-list');
var ClientPaymentListView = require('../clients/client-payment-list');
var BookingRecordListView = require('../financials/bookingrecord-list');
var ForecastingRecordListView = require('../financials/forecastingrecord-list');
var PledgingRecordListView = require('../financials/pledgingrecord-list');
var FinancialServiceEnquiriesListView = require('../financials/financialserviceenquiries-list');
var ConfirmModalView = require('../confirm-modal');
var InfoModalView = require('../info-modal');
var CaseProspect = require('../../models/cases/caseprospect');
var CaseProspects = require('../../models/cases/caseprospects');
var CaseProspectRelations = require('../../models/cases/caseprospectrelations');
var Reminder = require('../../models/cases/reminder');
var ClientMilestones = require('../../models/cases/clientmilestones');
var Cost = require('../../models/cases/cost');
var FinancialServiceEnquiry = require('../../models/cases/financialserviceenquiry');
var FinancialServiceEnquiries = require('../../models/cases/financialserviceenquiries');
var Events = require('../../models/cases/events');
var Request = require('../../models/cases/request');
var Requests = require('../../models/cases/requests');
var User = require('../../models/users/user');

var CaseFileDetailView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    detailsRegion: '.details-list',
    lifecycleRegion: '.milestone-btn-container',
    remindersRegion: '.reminders-container',
    messageListRegion: '.message-list-container',
    researchListRegion: '.research-list',
    allocationListRegion: '.allocation-list',
    fileListRegion: '.file-list-container',
    milestonesListRegion: '.milestones-list-container',
    enquiriesRegion: '.enquiries-container',
    correspondenceRegion: '.correspondence-container',
    correspondenceMessagesRegion: '.correspondence-messages-container',
    clientListRegion: '.client-list-container',
    contactListRegion: '.contact-list-container',
    costsOutRegion: '.costs-out-container',
    costsIncomeRegion: '.costs-income-container',
    costsDisbursementRegion: '.costs-disbursement-container',
    historyListRegion: '.history-list-container',
    bookingHistoryRegion: '.booking-history-container',
    forecastingRegion: '.forecasting-container',
    pledgingRegion: '.pledging-container',
    financialsCaseManagerFilterRegion: '.filter-casemanager-container',
    clientPaymentsRegion: '.client-payments-container',
  },
  ui: {
    detailsPanel: '#details',
    notesPanel: '#notes',
    correspondencePanel: '#correspondence',
    researchPanel: '#research',
    allocationPanel: '#allocation',
    filesPanel: '#files',
    milestonesPanel: '#milestones',
    remindersPanel: '#reminders',
    financialsPanel: '#financials',
    clientsPanel: '#clients',
    costsPanel: '#costs',
    enquiriesPanel: '#enquiries',
    caseProgress: '.casefile-milestones .progress-bar',
    dropzonePreviews: '.casefile-files .dropzone-previews',
    addFile: '.casefile-files .add-file',
    downloadFiles: '.download-files',
    selectAllFiles: '.select-all-files',
    historyPanel: '#history',
    messagesPanelTitle: '.casefile-notes .panel-title',
    clientsPanelTitle: '.clients-title',
    correspondencePanelTitle: '.casefile-correspondence .panel-title',
    filesPanelTitle: '.casefile-files .files-title',
    clientTypeFilters: '.client-type-filter input',
  },
  events: {
    'change @ui.clientTypeFilters': 'onClientTypeFilterChange',
    'click @ui.downloadFiles': 'onClickDownloadFiles',
    'click @ui.selectAllFiles': 'onSelectAllFiles',
  },
  onClientTypeFilterChange: function() {
    var view = this.clientListRegion.currentView;
    var allowedTypes = [];
    this.ui.clientTypeFilters.each(function() {
      var type = $(this).val();
      if ($(this).is(':checked')) {
        allowedTypes.push(type);
      }
    });
    view.options.filter = function(child, index, collection) {
      return allowedTypes.indexOf(child.get('type')) !== -1;
    };
    view.render();
  },
  onClickDownloadFiles: function() {
    var fileIds = this.selectedFiles.map(function(file) {
      return file.get('id');
    });
    var caseRef = this.model.get('ref');
    function downloadFiles() {
      window.location =
        '/api/files/download_zip/?file_name=files_' + caseRef + '&file_ids=' + fileIds.join(',');
    }

    // request sensitive data session if there isn't one
    var sensitiveDataToken = TCAS.reqres.request('sensitiveDataToken');

    if (!sensitiveDataToken) {
      // show dialog asking for download password
      TCAS.commands.execute('enableSensitiveData', function() {
        sensitiveDataToken = TCAS.reqres.request('sensitiveDataToken');
        downloadFiles();
      });
    } else {
      downloadFiles();
    }
  },
  onSelectAllFiles: function() {
    var isSelected = this.ui.selectAllFiles.is(':checked');
    this.fileListRegion.currentView.selectAllFiles(isSelected);
  },
  calculatePeopleCount: function(caseProspects) {
    // show numbers in client filters
    var countAncestors = 0;
    var countBeneficiaries = 0;
    var countClients = 0;
    caseProspects.each(function(caseProspect) {
      if (caseProspect.get('type') === CaseProspect.TYPES.client) {
        countClients += 1;
      } else if (caseProspect.get('type') === CaseProspect.TYPES.beneficiary) {
        countBeneficiaries += 1;
      } else if (caseProspect.get('type') === CaseProspect.TYPES.ancestor) {
        countAncestors += 1;
      }
    });
    this.$('.prospect-count-client').html('<span class="badge">' + countClients + '</span>');
    this.$('.prospect-count-beneficiary').html(
      '<span class="badge">' + countBeneficiaries + '</span>'
    );
    this.$('.prospect-count-ancestor').html('<span class="badge">' + countAncestors + '</span>');
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.isClient = TCAS.session.hasGroup(User.GROUPS.CLIENT);
    return data;
  },
  collectMilestones: function(that) {
    that.model.fetchMilestones().then(function() {
      // fetch milestone types based on the CategoryGroup
      var milestoneTypes = new MilestoneTypes([], {
        urlRoot:
          '/api/categories/' + that.model.get('category') + '/milestones/?ordering=ordering_id',
      });
      milestoneTypes.fetch().then(function() {
        var countApplied = 0;

        // check which milestones already exist
        milestoneTypes.forEach(function(milestoneType) {
          milestoneType.set('checked', false);
          var milestone = that.model.get('milestones').findWhere({
            milestone_type: milestoneType.get('id'), // jshint ignore:line
            is_active: true, // jshint ignore:line
          });
          if (milestone) {
            milestoneType.set('milestone', milestone);
            if (milestone.get('is_not_applicable')) {
              milestoneType.set('is_not_applicable', true);
            }
            milestoneType.set('checked', true);
            milestoneType.set('created', milestone.get('created'));
            countApplied += 1;
          }
        });

        that.listenTo(milestoneTypes, 'change', function() {
          // recalculate progress percentage
          var countApplied = 0;
          var percent = 0;

          milestoneTypes.forEach(function(milestoneType) {
            if (milestoneType.get('checked') || milestoneType.get('is_not_applicable')) {
              countApplied += 1;
            }
          });

          if (countApplied) {
            percent = Math.floor((countApplied / milestoneTypes.length) * 100);
          }

          that.ui.caseProgress
            .css('width', percent + '%')
            .text(percent + '%')
            .attr('aria-valuenow', percent);
        });

        var percent = 0;
        if (countApplied) {
          percent = Math.floor((countApplied / milestoneTypes.length) * 100);
        }

        that.ui.caseProgress
          .css('width', percent + '%')
          .text(percent + '%')
          .attr('aria-valuenow', percent);

        // check which requests exist (set pending true)
        var requests = new Requests([], {
          urlRoot: '/api/requests/?case=' + that.model.get('id') + '&is_active=true',
        });
        var request;

        requests.fetch().then(function() {
          // pending research sign off
          if (
            requests.where({
              action: Request.ACTIONS.SIGN_OFF,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            var signoff = milestoneTypes.findWhere({ name: 'Research Report Approved' });
            request = requests.findWhere({
              action: Request.ACTIONS.SIGN_OFF,
              status: Request.STATUSES.PENDING,
            });
            signoff.set('pending', true);
            signoff.set('request', request);
          }

          // pending Conditions of Approval Report Signed Off
          if (
            requests.where({
              action: Request.ACTIONS.CONDITIONS_OF_APPROVAL_REPORT,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            var conditionsOfApprovalReport = milestoneTypes.findWhere({
              name: 'Conditions of Approval Report Signed Off',
            });
            request = requests.findWhere({
              action: Request.ACTIONS.CONDITIONS_OF_APPROVAL_REPORT,
              status: Request.STATUSES.PENDING,
            });
            conditionsOfApprovalReport.set('pending', true);
            conditionsOfApprovalReport.set('request', request);
          }

          // pending conclude
          if (
            requests.where({
              action: Request.ACTIONS.CONCLUDE_CASE,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            var conclude = milestoneTypes.findWhere({ name: 'Case Concluded' });
            request = requests.findWhere({
              action: Request.ACTIONS.CONCLUDE_CASE,
              status: Request.STATUSES.PENDING,
            });
            conclude.set('pending', true);
            conclude.set('request', request);
          }

          // pending in claims
          if (
            requests.where({
              action: Request.ACTIONS.IN_CLAIMS,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            var inClaims = milestoneTypes.findWhere({ name: 'In Claims' });
            request = requests.findWhere({
              action: Request.ACTIONS.IN_CLAIMS,
              status: Request.STATUSES.PENDING,
            });
            inClaims.set('pending', true);
            inClaims.set('request', request);
          }

          // pending disclosure sent
          if (
            requests.where({
              action: Request.ACTIONS.DISCLOSURE_SENT,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            var disclosureSent = milestoneTypes.findWhere({
              name: 'Letter of Full Disclosure Sent',
            });
            request = requests.findWhere({
              action: Request.ACTIONS.DISCLOSURE_SENT,
              status: Request.STATUSES.PENDING,
            });
            disclosureSent.set('pending', true);
            disclosureSent.set('request', request);
          }

          // pending case booked
          if (
            requests.where({
              action: Request.ACTIONS.CASE_BOOKED,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            var caseBooked = milestoneTypes.findWhere({ name: 'Case Booked' });
            request = requests.findWhere({
              action: Request.ACTIONS.CASE_BOOKED,
              status: Request.STATUSES.PENDING,
            });
            caseBooked.set('pending', true);
            caseBooked.set('request', request);
          }

          // pending handback
          var pendingHandback = false;
          if (
            requests.where({
              action: Request.ACTIONS.HAND_BACK,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            pendingHandback = true;
          }

          // pending abandon
          var pendingAbandon = false;
          if (
            requests.where({
              action: Request.ACTIONS.ABANDON_CASE,
              status: Request.STATUSES.PENDING,
            }).length > 0
          ) {
            pendingAbandon = true;
          }

          that.milestones = milestoneTypes;

          // RENDER MILESTONE RELATED VIEWS IF REQUIRED
          var lifecycleDash = new LifecycleLayout({
            milestoneTypes: that.milestones,
            casefile: that.model,
            pendingAbandon: pendingAbandon,
            pendingHandback: pendingHandback,
          });
          that.lifecycleRegion.show(lifecycleDash);

          if (that.milestonesListRegion.hasView()) {
            var milestoneListView = new MilestoneListView({
              collection: that.milestones,
              casefile: that.model,
            });
            that.milestonesListRegion.show(milestoneListView);
          }
        });
      });
    });
  },
  onShow: function() {
    TCAS.casefile = this;
    var that = this;
    var lastViewed = this.getOption('lastViewed');

    this.listenTo(TCAS.vent, 'casefile:status:changed', function() {
      that.model.fetch().then(function() {
        var caseFileDetailsView = new CaseFileDetailsView({
          casefile: that.model,
        });
        that.detailsRegion.show(caseFileDetailsView);
      });
    });

    this.listenTo(TCAS.vent, 'collect:milestones', function(view) {
      that.model.fetch().then(function() {
        that.collectMilestones(that);
        that.detailsRegion.show(
          new CaseFileDetailsView({
            casefile: that.model,
          })
        );

        if (view !== undefined) {
          view.render();
        }
      });
    });
    var caseFileDetailsView = new CaseFileDetailsView({
      casefile: that.model,
    });
    that.detailsRegion.show(caseFileDetailsView);
    that.$el.collapse();

    var bookingRecords = that.model.get('bookingRecords');
    var forecastingRecords = that.model.get('forecastingRecords');
    var pledgingRecords = that.model.get('pledgingRecords');
    function financialComparator(record) {
      return new Date(record.get('date')).getTime() * -1;
    }
    bookingRecords.comparator = financialComparator;
    forecastingRecords.comparator = financialComparator;
    pledgingRecords.comparator = financialComparator;
    var clientPayments = that.model.get('clientPayments');
    var caseProspects = new CaseProspects([], {
      urlRoot: '/api/caseprospects/?casefile=' + this.model.get('id') + '&page_size=1000',
    });
    caseProspects.fetch();
    var caseProspectRelations = new CaseProspectRelations([], {
      urlRoot: '/api/caseprospectrelations/?casefile=' + this.model.get('id') + '&page_size=1000',
    });
    caseProspectRelations.fetch();

    /// GET MILESTONES
    if (TCAS.session.hasPerm('add_milestone')) {
      that.collectMilestones(that);
    }

    // NOTES PANEL
    this.ui.notesPanel.on('show.bs.collapse', function(e) {
      if (e.namespace !== 'bs.collapse') {
        return false;
      }
    });

    //it's been requested to always show notes
    this.ui.notesPanel.collapse('show');

    // RESEARCH PANEL
    this.ui.researchPanel.on('show.bs.collapse', function(e) {
      if (e.namespace !== 'bs.collapse') {
        return false;
      }
      that.model.fetchResearch().then(function() {
        var research = that.model.get('research');
        var researchListView = new ResearchListView({
          collection: research,
          casefile: that.model,
        });
        that.researchListRegion.show(researchListView);
      });
    });

    // ALLOCATION PANEL
    this.ui.allocationPanel.on('show.bs.collapse', function(e) {
      if (e.namespace !== 'bs.collapse') {
        return false;
      }
      that.model.fetchAllocations().then(function() {
        var allocations = that.model.get('allocations');
        var allocationsPanelView = new AllocationsPanelView({
          allocations: allocations,
          casefile: that.model,
        });
        that.allocationListRegion.show(allocationsPanelView);
      });
    });

    // MILESTONES PANEL
    this.ui.milestonesPanel.on('show.bs.collapse', function(e) {
      if (e.namespace !== 'bs.collapse') {
        return false;
      }

      if (that.milestones !== undefined) {
        var milestoneListView = new MilestoneListView({
          collection: that.milestones,
          casefile: that.model,
        });
        that.milestonesListRegion.show(milestoneListView);
      } else {
        that.listenTo(that.milestones, 'sync', function() {
          var milestoneListView = new MilestoneListView({
            collection: that.milestones,
            casefile: that.model,
          });
          that.milestonesListRegion.show(milestoneListView);
        });
      }
    });

    // REMINDERS
    if (TCAS.session.hasPerm('view_reminder')) {
      var reminders = that.model.get('reminders');
      if (reminders.urlRoot.indexOf('?') === -1) {
        reminders.urlRoot = reminders.urlRoot + '?status=' + Reminder.STATUSES.PENDING;
      } else {
        reminders.urlRoot = reminders.urlRoot + '&status=' + Reminder.STATUSES.PENDING;
      }

      var reminderListView = new ReminderListView({
        collection: reminders,
        casefile: that.model,
        showCasefile: false,
        showAddForm: true,
      });
      this.remindersRegion.show(reminderListView);
      reminders.fetch();
    }

    // PROSPECTS
    if (TCAS.session.hasPerm('view_correspondence')) {
      // CORRESPONDENCE
      var correspondence = that.model.get('correspondence');
      that.correspondenceRegion.show(
        new CorrespondenceLayoutView({
          collection: correspondence,
          casefile: that.model,
          clients: caseProspects,
        })
      );
      correspondence.fetch({
        success: function() {
          //only include active correspondence
          var inactiveCorrespondence = correspondence.where({ is_active: false });
          _.each(inactiveCorrespondence, function(model) {
            if (!model.get('is_active')) {
              correspondence.remove(model);
            }
          });
        },
      });

      var highlightProspect = this.getOption('highlightProspect') || false;

      // fetch case messages and filter out to only show client messages
      that.model.fetchMessages().then(function() {
        var messages = that.model.get('messages');

        that.listenTo(messages, 'destroy', function(model) {
          messages.fetch();
        });

        // trigger a re-sort when published_date has changed
        that.listenTo(messages, 'change:published_date', function() {
          messages.sort();
        });

        // work out how many new messages since last visit to show as badge in messages accordion header
        var newMessageCount = 0;
        messages.each(function(message) {
          if (message.get('fao_clients').length === 0) {
            var created = new Date(message.get('created'));
            if (message.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
              newMessageCount += 1;
            }
          }
        });
        if (newMessageCount > 0) {
          that.ui.messagesPanelTitle.find('.badge').remove();
          that.ui.messagesPanelTitle.append(
            ' <span class="badge badge-danger" title="New messages since last viewed">' +
              newMessageCount +
              '</span>'
          );
          that.ui.messagesPanelTitle.find('.badge').tooltip();
        }

        var caseNotesListView = new MessageListView({
          collection: messages,
          casefile: that.model,
          clients: caseProspects,
          highlightMessage: that.getOption('highlightMessage') || false,
          enableFAOClients: false,
          lastViewed: lastViewed,
          filterFunction: function(message) {
            return message.get('fao_clients').length === 0;
          },
        });
        that.messageListRegion.show(caseNotesListView);

        if (that.getOption('highlightMessage')) {
          var message = messages.get(that.getOption('highlightMessage'));
          if (message && message.get('fao_clients').length === 0) {
            $.scrollTo(that.ui.messagesPanelTitle, 800);
          }
        }

        // work out how many new messages since last visit to show as badge in correspondence accordion header
        var newMessageCount = 0;
        messages.each(function(message) {
          if (message.get('fao_clients').length > 0) {
            var created = new Date(message.get('created'));
            if (message.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
              newMessageCount += 1;
            }
          }
        });
        if (newMessageCount > 0) {
          that.ui.clientsPanelTitle.append(
            ' <span class="badge badge-danger" title="New messages since last viewed">' +
              newMessageCount +
              '</span>'
          );
          that.ui.clientsPanelTitle.find('.badge').tooltip();
        }

        if (that.getOption('highlightMessage')) {
          var message = messages.get(that.getOption('highlightMessage'));
          if (message && message.get('fao_clients').length > 0) {
            that.ui.correspondencePanel.collapse('show');
            _.defer(function() {
              $.scrollTo(that.ui.correspondencePanelTitle, 800);
            });
          }
        }

        var clientMessageList = new MessageListView({
          collection: messages,
          casefile: that.model,
          clients: caseProspects,
          highlightMessage: that.getOption('highlightMessage') || false,
          enableFAOClients: true,
          lastViewed: lastViewed,
          filterFunction: function(message) {
            return message.get('fao_clients').length > 0;
          },
        });
        that.correspondenceMessagesRegion.show(clientMessageList);

        var clientMilestones = new ClientMilestones([], {
          urlRoot:
            '/api/clientmilestones/?casefile=' +
            that.model.get('id') +
            '&is_active=True&page_size=10000',
        });
        clientMilestones.fetch().then(function() {
          that.clientListRegion.show(
            new ClientListView({
              collection: caseProspects,
              clientRelations: caseProspectRelations,
              messages: messages,
              clientMilestones: clientMilestones,
              clientPayments: clientPayments,
              casefile: that.model,
              lastViewed: lastViewed,
              highlightProspect: highlightProspect,
              showContacts: false,
              filter: function(model) {
                return (
                  model.get('type') !== CaseProspect.TYPES.contact &&
                  model.get('type') !== CaseProspect.TYPES.accounts &&
                  model.get('type') !== CaseProspect.TYPES.ancestor
                );
              },
            })
          );
          that.contactListRegion.show(
            new ClientListView({
              collection: caseProspects,
              clientRelations: caseProspectRelations,
              messages: messages,
              clientMilestones: clientMilestones,
              clientPayments: clientPayments,
              casefile: that.model,
              lastViewed: lastViewed,
              highlightProspect: highlightProspect,
              showContacts: true,
              filter: function(model) {
                return (
                  model.get('type') === CaseProspect.TYPES.contact ||
                  model.get('type') === CaseProspect.TYPES.accounts
                );
              },
            })
          );
        });
        clientPayments.fetch({
          success: onSuccessRemoveInactive,
        });
        if (highlightProspect) {
          that.ui.clientsPanel.collapse('show');
        }
        that.calculatePeopleCount(caseProspects);
        that.listenTo(caseProspects, 'change:type', function() {
          that.calculatePeopleCount(caseProspects);
        });
      });
    }

    // ENQUIRIES PANEL
    this.ui.enquiriesPanel.on('show.bs.collapse', function(e) {
      if (e.namespace !== 'bs.collapse') {
        return false;
      }
      var enquiries = new FinancialServiceEnquiries();
      // ensure pre-existing financial services appear first
      enquiries.comparator = function(a, b) {
        var aFinancialService = TCAS.financialServices.find(function(service) {
          return service.get('name') === a.get('financial_service_name');
        });
        var bFinancialService = TCAS.financialServices.find(function(service) {
          return service.get('name') === b.get('financial_service_name');
        });
        var aIndex = TCAS.financialServices.indexOf(aFinancialService);
        var bIndex = TCAS.financialServices.indexOf(bFinancialService);

        if (aIndex === -1 && bIndex === -1) {
          if (a.get('id') && b.get('id')) {
            if (a.get('id') < b.get('id')) {
              return -1;
            } else if (a.get('id') > b.get('id')) {
              return 1;
            }
          } else if (a.get('id') > 0) {
            return -1;
          } else if (b.get('id') > 0) {
            return;
          }
        } else if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else if (aIndex < bIndex) {
          return -1;
        } else if (aIndex > bIndex) {
          return 1;
        }
        return 0;
      };
      enquiries.setFilters({
        casefile: that.model.get('id'),
      });
      enquiries.fetch().then(function() {
        // populate enquiries with any that are missing
        TCAS.financialServices.forEach(function(financialService) {
          var enquiry = enquiries.find(function(enq) {
            return enq.get('financial_service_name') === financialService.get('name');
          });
          if (!enquiry) {
            enquiries.push(
              new FinancialServiceEnquiry({
                financial_service_name: financialService.get('name'),
              })
            );
          }
        });
        // add a few more rows for custom entries
        for (var i = 0; i < 5; i += 1) {
          enquiries.push(
            new FinancialServiceEnquiry({
              financial_service_name: '',
            })
          );
        }
        enquiries.sort();
        var enquiriesView = new FinancialServiceEnquiriesListView({
          collection: enquiries,
          financialServices: TCAS.financialServices,
          casefile: that.model,
        });
        that.enquiriesRegion.show(enquiriesView);
      });
    });

    // COSTS PANEL
    if (TCAS.session.hasPerm('view_cost')) {
      this.ui.costsPanel.on('show.bs.collapse', function(e) {
        if (e.namespace !== 'bs.collapse') {
          return false;
        }

        that.model.fetchCosts().then(function() {
          //only include active costs
          var costs = that.model.get('costs');
          var inactiveCosts = costs.where({ is_active: false });
          _.each(inactiveCosts, function(cost) {
            if (!cost.get('is_active')) {
              costs.remove(cost);
            }
          });
        });
      });
      var costs = that.model.get('costs');
      var outgoingCostsView = new CostListView({
        collection: costs,
        casefile: this.model,
        filterType: Cost.TYPES.cost,
        clients: caseProspects,
        dateColumn: 'Date Paid',
        payeeColumn: 'Paid To',
        payeePlaceholder: 'Paid to...',
      });
      var incomingCostsView = new CostListView({
        collection: costs,
        casefile: this.model,
        filterType: Cost.TYPES.income,
        clients: caseProspects,
        dateColumn: 'Date Received',
        payeeColumn: 'Received From',
        payeePlaceholder: 'Received from...',
      });
      var disbursementCostsView = new CostListView({
        collection: costs,
        casefile: this.model,
        filterType: Cost.TYPES.disbursement,
        clients: caseProspects,
        dateColumn: 'Date Paid',
        payeeColumn: 'Paid To',
        payeePlaceholder: 'Paid to...',
      });
      this.costsOutRegion.show(outgoingCostsView);
      this.costsIncomeRegion.show(incomingCostsView);
      this.costsDisbursementRegion.show(disbursementCostsView);
    }

    function onSuccessRemoveInactive(collection) {
      //only include active costs
      var inactive = collection.where({ is_active: false });
      _.each(inactive, function(item) {
        if (!item.get('is_active')) {
          collection.remove(item);
        }
      });
    }

    // FINANCES PANEL
    if (TCAS.session.hasPerm('view_clientpayment')) {
      this.ui.financialsPanel.on('show.bs.collapse', function(e) {
        // check the event target, date input field popups also trigger this
        if (e.target.id === 'financials') {
          // bookingRecords, forecastingRecords, pledgingRecords and
          // clientPayments all require fetching and removing !is_active
          bookingRecords.fetch({
            success: onSuccessRemoveInactive,
          });
          forecastingRecords.fetch({
            success: onSuccessRemoveInactive,
          });
          pledgingRecords.fetch({
            success: onSuccessRemoveInactive,
          });
          clientPayments.fetch({
            success: onSuccessRemoveInactive,
          });
        }
      });

      var bookingRecordListView = new BookingRecordListView({
        collection: bookingRecords,
        casefile: this.model,
      });
      this.bookingHistoryRegion.show(bookingRecordListView);
      var forecastingRecordListView = new ForecastingRecordListView({
        collection: forecastingRecords,
        casefile: this.model,
      });
      this.forecastingRegion.show(forecastingRecordListView);
      var pledgingRecordListView = new PledgingRecordListView({
        collection: pledgingRecords,
        casefile: this.model,
      });
      this.pledgingRegion.show(pledgingRecordListView);
      var casemanagerFilterView = new CaseManagerFilterView();
      this.listenTo(casemanagerFilterView, 'casemanagerSelected', function(casemanager) {
        // jshint ignore:start
        bookingRecords.setFilters({ case_manager: casemanager });
        bookingRecords.fetch({ success: onSuccessRemoveInactive });
        forecastingRecords.setFilters({ case_manager: casemanager });
        forecastingRecords.fetch({ success: onSuccessRemoveInactive });
        pledgingRecords.setFilters({ case_manager: casemanager });
        pledgingRecords.fetch({ success: onSuccessRemoveInactive });
        // jshint ignore:end
      });
      this.financialsCaseManagerFilterRegion.show(casemanagerFilterView);

      var clientPaymentsView = new ClientPaymentListView({
        collection: clientPayments,
        clients: caseProspects,
        casefile: this.model,
      });
      this.clientPaymentsRegion.show(clientPaymentsView);
    }

    //setup file list view
    if (TCAS.session.hasPerm('view_files')) {
      var fileListView = new FileListView({
        collection: that.model.get('files'),
        casefile: that.model,
        clients: caseProspects,
        isClient: TCAS.session.hasGroup(User.GROUPS.CLIENT),
        lastViewed: lastViewed,
        enableSelection: true,
      });
      this.listenTo(fileListView, 'fileSelectionChanged', function(selectedFiles) {
        this.ui.downloadFiles.prop('disabled', selectedFiles.length === 0);
        this.selectedFiles = selectedFiles;
      });
      this.listenTo(fileListView, 'fileDrag', function() {
        this.ui.clientsPanel.collapse('show');
      });
      this.fileListRegion.show(fileListView);
      this.model.fetchFiles().then(function() {
        // work out how many new files since last visit to show as badge in files accordion header
        var newFilesCount = 0;
        that.model.get('files').each(function(file) {
          var created = new Date(file.get('created'));
          if (file.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
            newFilesCount += 1;
          }
        });
        if (newFilesCount > 0) {
          that.ui.filesPanelTitle.find('.badge').remove();
          that.ui.filesPanelTitle.append(
            ' <span class="badge badge-danger" title="New files since last viewed">' +
              newFilesCount +
              '</span>'
          );
          that.ui.filesPanelTitle.find('.badge').tooltip();
        }
      });
      this.listenTo(caseProspects, 'sync', function() {
        that.fileListRegion.show(fileListView);
      });
    }

    // CASE HISTORY PANEL
    this.ui.historyPanel.on('show.bs.collapse', function(e) {
      if (e.namespace !== 'bs.collapse') {
        return false;
      }
      var events = new Events([], {
        urlRoot: '/api/cases/' + that.model.get('id') + '/events/?page_size=10000',
      });
      events.fetch().then(function() {
        var casefileHistoryView = new HistoryView({
          history: events,
          casefile: that.model,
        });
        that.historyListRegion.show(casefileHistoryView);
      });
    });

    //configure dropzone
    this.dropzone = new Dropzone(this.el, {
      url: '/api/cases/' + this.model.get('id') + '/files/',
      headers: { 'X-CSRFToken': TCAS.csrfToken },
      previewsContainer: this.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      maxFilesize: 500,
      acceptedFiles:
        'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.eml,.msg,.ged',
      clickable: this.ui.addFile[0],
      accept: function(file, done) {
        var alphaNumericPattern = new RegExp("^[a-zA-Z0-9'_()&+£%., -]+\\.[a-zA-Z0-9]{3,4}$");
        // check for only alphanumeric characters
        if (alphaNumericPattern.test(file.name)) {
          // check if file already has ref and date
          var looseCaseRefPattern = new RegExp(' - \\d{6,8} - .+\\.[a-zA-Z]{3,4}$');
          var caseRefPattern = new RegExp(
            '^' + that.model.get('ref') + ' - ' + '\\d{6,8}' + ' - .+\\.[a-zA-Z]{3,4}$',
            'i'
          );
          var caseRefTodayPattern = new RegExp(
            '^' +
              that.model.get('ref') +
              ' - ' +
              moment().format('YYMMDD') +
              ' - .+\\.[a-zA-Z]{3,4}$',
            'i'
          );
          if (looseCaseRefPattern.test(file.name)) {
            if (!caseRefPattern.test(file.name)) {
              var infoModal = new InfoModalView({
                title: 'File name format is incorrect',
                body:
                  'This file "' +
                  file.name +
                  '" has a case ref and date format but it is not correct for this case (' +
                  that.model.get('ref') +
                  '). Please rename the file using the correct format.',
              });
              infoModal.render();
              that.dropzone.removeFile(file);
            } else {
              if (!caseRefTodayPattern.test(file.name)) {
                // show warning confirmation
                var confirmModal = new ConfirmModalView({
                  title: "File name is not using today's date",
                  body:
                    'This file "' +
                    file.name +
                    '" is not using today\'s date, are you sure you want to proceed and use this name?',
                });
                that.listenTo(confirmModal, 'confirmYes', function() {
                  done();
                });
                that.listenTo(confirmModal, 'confirmNo', function() {
                  that.dropzone.removeFile(file);
                });
                confirmModal.render();
              } else {
                done();
              }
            }
          } else {
            done();
          }
        } else {
          done(
            'This file has unexpected characters, ' +
              'file names must use the correct file naming structure and ' +
              'only contain characters consisting of: ' +
              'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
              'apostrophes, commas or spaces.'
          );
        }
      },
    });
    this.dropzone.on('success', function(file) {
      that.model
        .get('files')
        .fetch()
        .then(function() {
          that.$(file.previewElement).remove();
          that.fileListRegion.currentView.render();
        });
    });
    this.dropzone.on('error', function(file) {
      that
        .$(file.previewElement)
        .find('button')
        .removeClass('hide');
    });
    this.dropzone.on('uploadprogress', function(file, progress) {
      console.log('file upload progress: ' + progress);
    });
    this.dropzone.on('sending', function(file, xhr, formData) {
      // Show the total progress bar when upload starts
      that
        .$(file.previewElement)
        .find('.progress')
        .removeClass('hide');
      formData.append('is_casefile_direct_upload', 1);
    });
    this.dropzone.on('dragleave', function() {
      that.ui.addFile.removeClass('list-group-item-success');
    });
    this.dropzone.on('drop', function() {
      that.ui.addFile.removeClass('list-group-item-success');
    });
  },
});

module.exports = CaseFileDetailView;
