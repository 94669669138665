'use strict';
var BaseCollection = require('../base-collection');
var PledgingRecord = require('./pledgingrecord');

var PledgingRecords = BaseCollection.extend({
  model: PledgingRecord,
  urlRoot: '/api/pledgingrecords/',
  comparator: function(pledgingRecord){
    return new Date(pledgingRecord.get('date')).getTime();
  }
});

module.exports = PledgingRecords;
