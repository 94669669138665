'use strict';
var template = require('../../templates/users/reset-2fa.hbs');

var Reset2faView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    form: 'form',
    panel: '.login-panel',
    button: 'button.btn-primary'
  },
  behaviors: {
    SubmitForm: {
      message: 'reset 2fa',
      selector: 'form',
      submitHandler: 'onSubmit'
    },
  },
  onSubmit: function (data) {
    var that = this;
    var resetId = this.getOption('resetId');
    this.ui.button.button('loading');
    var activationCode = this.getOption('activationCode');
    data.activation = activationCode;
    $.ajax('/api/twofactorreset/' + resetId + '/reset/', {
      contentType: 'application/json',
      data: JSON.stringify(data),
      dataType: 'json',
      type: 'POST',
      success: function (response) {
        that.ui.form.addClass('hidden');
        that.ui.panel.append(
          '<div class="alert alert-success alert-dismissible" role="alert">' +
          '<button type="button" class="close" data-dismiss="alert" ' +
          'aria-label="Close"><span aria-hidden="true">&times;</span>' +
          '</button>' +
          'Your Two Factor Authentication has been successfully reset.' +
          '</div>' +
          '<a class="btn btn-primary" href="/login">Login</a>'
        );
        that.ui.button.button('reset');
      },
      error: function (jqXHR, status) {
        that.ui.form.prepend(
          '<div class="alert alert-danger alert-dismissible" role="alert">' +
          '<button type="button" class="close" data-dismiss="alert" ' +
          'aria-label="Close"><span aria-hidden="true">&times;</span>' +
          '</button>' + jqXHR.responseJSON + '</div>'
        );
        that.ui.button.button('reset');
      }
    });
  }
});

module.exports = Reset2faView;
