// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.getTeamName || (depth0 && depth0.getTeamName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.team : depth0),{"name":"getTeamName","hash":{},"data":data}));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.events : depth0)) != null ? stack1.length : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "0";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                                <tr>\n                                    <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n                                    <td>"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,(depth0 != null ? depth0.event : depth0),{"name":"getEventName","hash":{},"data":data}))
    + "</td>\n                                    <td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.legacy_id : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3((helpers.eventParser || (depth0 && depth0.eventParser) || alias2).call(alias1,(depth0 != null ? depth0.details : depth0),{"name":"eventParser","hash":{},"data":data}))
    + "</td>\n                                </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "<span class=badge>Legacy</span> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<tr data-toggle=\"collapse\" data-target=\"#bucket-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"case-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n<!--<tr>-->\n    <td>"
    + alias4((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n    <td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.team : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n    <td>"
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "</td>\n\n    <td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.events : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n\n</tr>\n\n<tr class=\"report-expanded\">\n    <td colspan=\"11\">\n        <div class=\"collapse\" id=\"bucket-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"row\">\n                <div class=\"col-md-8 col-md-offset-1\">\n\n                    <table class=\"table table-condensed\">\n                        <thead>\n                        <tr>\n                            <th>Actioned Date</th>\n                            <th>Event</th>\n                            <th>Details</th>\n                        </tr>\n                        </thead>\n                        <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.events : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </tbody>\n                    </table>\n                </div>\n            </div>\n        </div>\n    </td>\n</tr>";
},"useData":true});
