'use strict';

var template = require('../../templates/milestones/milestone.hbs');
var Milestone = require('../../models/cases/milestone');
var User = require('../../models/users/user');

var MilestoneView = Backbone.Marionette.ItemView.extend({
  className: 'milestone',
  tagName: 'tr',
  template: template,
  ui: {
    'removeMilestone': '.remove-milestone',
    'removeMilestoneNotification': '#remove-milestone-notification',
    'removeMilestoneModal': '#milestone-remove-modal',
    'createdDate': '.milestone-created-date',
    'applyMilestone': '.apply-milestone',
    'notApplicable': '.not-applicable-milestone',
    'saveMilestone': '.save-milestone'
  },
  events: {
    'click @ui.removeMilestone': 'promptRemove',
    'click @ui.applyMilestone': 'applyMilestone',
    'click @ui.notApplicable': 'notApplicableMilestone',
    'click @ui.saveMilestone': 'saveMilestone'
  },
  promptRemove: function() {
    this.trigger('remove:milestone');
  },
  applyMilestone: function() {
    this.trigger('apply:milestone');
  },
  notApplicableMilestone: function() {
    this.trigger('not-applicable:milestone');
  },
  initialize: function() {
    var that = this;
    this.editable = true;

    this.listenTo(TCAS.vent, 'milestone:change:start', function() {
      that.ui.applyMilestone.prop('disabled', true);
      that.ui.saveMilestone.prop('disabled', true);
    });

    this.listenTo(TCAS.vent, 'milestone:change:end', function() {
      if(!that.model.get('pending')) {
        that.ui.applyMilestone.prop('disabled', false);
        that.ui.saveMilestone.prop('disabled', false);
      }
    });
  },
  onRender: function() {
    this.ui.createdDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });
  },
  saveMilestone: function() {
    var that = this;

    var milestone = this.model.get('milestone');

    milestone.fetch({
      success: function() {
        var date = that.ui.createdDate.datepicker('getUTCDate');
        milestone.save({
          created: date.toISOString()
        }, {
          wait: true,
          success: function() {
            that.model.set('created', date.toISOString());
            that.render();
          },
          error: function() {
            console.log('milestone update failed');
          }
        });
      }
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.editable = this.editable;
    data.isClient = this.getOption('isClient');

    // include active case manager in serialize data, new milestones should not
    // be applied if case is unassigned
    var allocation = this.getOption('casefile').getCurrentAllocation();
    if (allocation && allocation.get('case_manager')) {
      data.case_manager = allocation.get('case_manager'); // jshint ignore:line
    }

    data.canApply = false;

    if (TCAS.session.hasPerm('add_milestone')) {
      if (TCAS.session.hasGroup([User.GROUPS.ROOT, User.GROUPS.ADMIN, User.GROUPS.OPERATIONS_MANAGER,
          User.GROUPS.COMMERCIAL_MANAGER, User.GROUPS.GENERAL_MANAGER])) {
        data.canApply = true;
      } else if (data.case_manager) {
        if (data.case_manager === TCAS.session.get('id')) {
          data.canApply = true;
        } else {
          var caseManager = TCAS.users.find(function(user) {
            return user.get('id') === data.case_manager;
          });
          if (caseManager) {
            if (TCAS.session.hasGroup([User.GROUPS.TEAM_LEADER, User.GROUPS.TEAM_SECOND_LEADER]) &&
              TCAS.session.get('userdetail').team === caseManager.get('userdetail').get('team')) {
              data.canApply = true;
            }
          }
        }
      }
    }

    // council cases require slightly different names for specific milestones
    // PR/NoK FA Sent - is renamed from ‘FA Sent’ 7
    // Signed PR/NoK FA received – is renamed from ‘FA Received’ 8
    // PR/NoK Disclosure sent - renamed from ‘Disclosure Sent’ 26
    var milestoneType = this.model.get('id');

    if ([7, 8, 26].indexOf(milestoneType) !== -1) {
      var category = this.getOption('casefile').get('category');
      var categoryGroup = TCAS.reqres.request('categoryGroup', category);
      if (categoryGroup.isCouncilCase()) {
        if (milestoneType === 7) {
          data.name = 'PR/NoK Engagement Pack Sent';
        } else if (milestoneType === 8) {
          data.name = 'PR/NoK Engagement Pack Received';
        } else if (milestoneType === 26) {
          data.name = 'PR/NoK Letter of Full Disclosure Sent';
        }
      }
    }

    return data;
  }
});

module.exports = MilestoneView;