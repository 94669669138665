// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.clientId || (depth0 != null ? depth0.clientId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"clientId","hash":{},"data":data}) : helper)))
    + "\" disabled";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<td><div class=\"help-block relates-to-correspondence\" data-initial-text=\"Select related correspondence above (if appropriate)\">Select related correspondence above (if appropriate)</div></td>\n<td>\n  <div class=\"form-group\">\n    <select name=\"is_incoming\" class=\"form-control input-sm\">\n      <option value=\"False\" selected=\"selected\">Outgoing</option>\n      <option value=\"True\">Incoming</option>\n    </select>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control input-sm\" name=\"date\" placeholder=\"DD/MM/YYYY\" value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"/>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control input-sm\" name=\"case_prospect\" placeholder=\"Choose correspondent\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.clientId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control input-sm\" name=\"description\" placeholder=\"Description\"/>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <div class=\"upload-file-container\">\n      <button type=\"button\" class=\"btn btn-default btn-sm add-file\"><i class=\"fa fa-plus\"></i> Drag and drop a file or click here</button>\n      <div class=\"help-block\">You can drag and drop a file from your computer, or drag an existing attached file listed under Files</div>\n    </div>\n    <div class=\"dropzone-previews\"></div>\n    <div class=\"file-previews\"></div>\n    <input type=\"hidden\" name=\"evidence\"/>\n  </div>\n</td>\n<td>\n  <button type=\"submit\" class=\"btn btn-success btn-sm\" data-loading-text=\"Saving...\">Add</button>\n</td>";
},"useData":true});
