'use strict';

var BaseCollection = require('../base-collection');
var Answer = require('./answer');

var Answers = BaseCollection.extend({
  urlRoot: '/api/answers/',
  model: Answer
});

module.exports = Answers;