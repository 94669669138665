'use strict';

require('fullcalendar');
var moment = require('moment');
var template = require('../../templates/events/calendar.hbs');
var EventFormView = require('./event-form');
var ReminderFormView = require('./reminder-form');
var EventDetailsModalView = require('./event-details');
var CalendarEvent = require('../../models/events/calendarevent');
var Invites = require('../../models/events/invites');
var Reminder = require('../../models/cases/reminder');

var CalendarView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'tcas-calendar',
  ui: {
    calendar: '.calendar-container',
    filterMe: '.filter-me',
    filterCompanyMeetings: '.filter-company-meetings',
    filterCompanyAbsences: '.filter-company-absences',
    newEventBtn: '.new-event'
  },
  events: {
    'click @ui.newEventBtn': 'onClickNewEvent',
    'click @ui.filterMe': 'onClickFilterMe',
    'click @ui.filterCompanyMeetings': 'onClickFilterCompanyMeetings',
    'click @ui.filterCompanyAbsences': 'onClickFilterCompanyAbsences'
  },
  onClickNewEvent: function() {
    new EventFormView({
      collection: this.getOption('calendarEvents'),
      calendarEventCategories: this.getOption('calendarEventCategories'),
      calendarEventTypes: this.getOption('calendarEventTypes')
    }).render();
  },
  onClickFilterMe: function() {
    this.ui.filterMe.toggleClass('active');
    this.ui.calendar.fullCalendar('refetchEvents');
  },
  onClickFilterCompanyMeetings: function() {
    this.ui.filterCompanyMeetings.toggleClass('active');
    this.ui.calendar.fullCalendar('refetchEvents');
  },
  onClickFilterCompanyAbsences: function() {
    this.ui.filterCompanyAbsences.toggleClass('active');
    this.ui.calendar.fullCalendar('refetchEvents');
  },
  onClickEvent: function(event, model) {
    var that = this;
    var invites = new Invites();
    invites.setFilters({ event: model.get('id') });
    invites.fetch().then(function() {
      var eventDetailsModal = new EventDetailsModalView({
        event: event,
        model: model,
        invites: invites,
        calendarEventTypes: that.getOption('calendarEventTypes')
      });
      eventDetailsModal.render();
      that.listenTo(eventDetailsModal, 'reminder:delete', function(reminder) {
        reminder.destroy({ wait: true });
        eventDetailsModal.close();
        that.ui.calendar.fullCalendar('refetchEvents');
      });
      that.listenTo(eventDetailsModal, 'calendarevent:delete', function(calendarEvent) {
        calendarEvent.save({ is_active: false }, { wait: true }).then(function() {
          that.getOption('calendarEvents').remove(calendarEvent);
          eventDetailsModal.close();
          that.ui.calendar.fullCalendar('refetchEvents');
        });
      });
      that.listenTo(eventDetailsModal, 'calendarevent:change', function(calendarEvent) {
        var allDay = calendarEvent.get('is_all_day');
        eventDetailsModal.close();
        event.title = calendarEvent.get('title');
        event.start = new Date(calendarEvent.get('start'));
        event.end = allDay ? moment(calendarEvent.get('end')).add(1, 'day').format('YYYY-MM-DD') : new Date(calendarEvent.get('end'));
        event.users = calendarEvent.get('users');
        event.allDay = allDay;
        that.ui.calendar.fullCalendar('updateEvent', event);
        that.onClickEvent(event, calendarEvent);
      });
      that.listenTo(eventDetailsModal, 'event:edit', function(view) {
        if (view.model instanceof CalendarEvent) {
          new EventFormView({
            collection: that.getOption('calendarEvents'),
            calendarEventCategories: that.getOption('calendarEventCategories'),
            calendarEventTypes: that.getOption('calendarEventTypes'),
            calendarEvent: view.model
          }).render();
          that.listenTo(view.model, 'change', function() {
            var allDay = view.model.get('is_all_day');
            eventDetailsModal.close();
            event.title = view.model.get('title');
            event.start = new Date(view.model.get('start'));
            event.end = allDay ? moment(view.model.get('end')).add(1, 'day').format('YYYY-MM-DD') : new Date(view.model.get('end'));
            event.users = view.model.get('users');
            event.allDay = allDay;
            that.ui.calendar.fullCalendar('updateEvent', event);
            that.onClickEvent(event, view.model);
          });
        } else {
          // render reminder edit form in a modal
          new ReminderFormView({
            model: view.model
          }).render();
          that.listenTo(view.model, 'change', function() {
            eventDetailsModal.close();
            event.title = view.model.get('message');
            event.start = view.model.get('due_date');
            that.ui.calendar.fullCalendar('updateEvent', event);
            that.onClickEvent(event, view.model);
          });
        }
      });

      // var eventDetailsModal = new EventDetailsModalView({
      //   event: event,
      //   model: model,
      //   invites: invites,
      //   calendarEventTypes: that.getOption('calendarEventTypes')
      // });
      // eventDetailsModal.render();
      // that.listenTo(eventDetailsModal, 'calendarevent:delete', function(calendarEvent) {
      //   calendarEvent.save({ is_active: false }, { wait: true }).then(function() {
      //     that.getOption('calendarEvents').remove(calendarEvent);
      //     eventDetailsModal.close();
      //     that.ui.calendar.fullCalendar('refetchEvents');
      //   });
      // });
      // that.listenTo(eventDetailsModal, 'event:edit', function(view) {
      //   if (view.model instanceof CalendarEvent) {
      //     new EventFormView({
      //       collection: that.getOption('calendarEvents'),
      //       calendarEventCategories: that.getOption('calendarEventCategories'),
      //       calendarEventTypes: that.getOption('calendarEventTypes'),
      //       calendarEvent: view.model
      //     }).render();
      //     that.listenTo(view.model, 'change', function() {
      //       var allDay = view.model.get('is_all_day');
      //       eventDetailsModal.close();
      //       event.title = view.model.get('title');
      //       event.start = new Date(view.model.get('start'));
      //       event.end = allDay ? moment(view.model.get('end')).add(1, 'day').format('YYYY-MM-DD') : new Date(view.model.get('end'));
      //       event.users = view.model.get('users');
      //       event.allDay = allDay;
      //       that.ui.calendar.fullCalendar('updateEvent', event);
      //       that.onClickEvent(event, model);
      //     });
      //   }
      // });
    });
  },
  onShow: function() {
    var that = this;
    var reminders = this.getOption('reminders');
    var calendarEventCategories = this.getOption('calendarEventCategories');
    var calendarEventTypes = this.getOption('calendarEventTypes');
    var calendarEvents = this.getOption('calendarEvents');
    var meetingEventTypes = [];
    var absenceEventTypes = [];
    calendarEventTypes.each(function(eventType) {
      if (eventType.get('category') === 2 || eventType.get('category') === 3) {
        meetingEventTypes.push(eventType.get('id'));
      } else {
        absenceEventTypes.push(eventType.get('id'));
      }
    });
    var colorMap = {
      'reminders': '#94679C',
      '1': '#D1517A', // 1 - Public Holiday
      '2': '#7A9B62', // 2 - Meeting
      '3': '#595CA5', // 3 - Appointment
      '4': '#4097DB'  // 4 - Out of Office
    };

    function convertReminder(reminder) {
      return {
        title: reminder.get('message'),
        start: reminder.get('due_date'),
        allDay: true,
        id: 'reminder_' + reminder.get('id'),
        users: [reminder.get('fao')],
        isRecurring: false,
        color: colorMap['reminders']
      };
    }

    function convertCalendarEvent(calendarEvent) {
      var eventType = calendarEventTypes.get(calendarEvent.get('event_type'));
      var category = calendarEventCategories.get(eventType.get('category'));
      var title = calendarEvent.get('title');
      if (eventType.get('is_private') && calendarEvent.get('users').indexOf(TCAS.session.id) === -1 &&
          !TCAS.session.hasPerm('add_leave_events')) {
        title = eventType.get('public_name');
      }
      var allDay = calendarEvent.get('is_all_day');
      var event = {
        title: title,
        start: allDay ? moment(calendarEvent.get('start')).format('YYYY-MM-DD') : new Date(calendarEvent.get('start')),
        allDay: allDay,
        id: 'calendarevent_' + calendarEvent.get('id'),
        users: calendarEvent.get('users'),
        color: colorMap[category.get('id') + ''],
        isRecurring: calendarEvent.get('is_recurring'),
        recurEvery: calendarEvent.get('recur_every'),
        recurInterval: calendarEvent.get('recur_interval')
      };
      if (calendarEvent.get('end')) {
        event.end = allDay ? moment(calendarEvent.get('end')).add(1, 'day').format('YYYY-MM-DD') : new Date(calendarEvent.get('end'));
      }

      return event;
    }

    this.ui.calendar.fullCalendar({
      defaultView: this.getOption('date') ? 'agendaDay' : 'month',
      defaultDate: this.getOption('date') ? this.getOption('date') : null,
      header: {
        left:   'title',
        center: 'agendaDay,agendaWeek,month',
        right:  'newEvent today prev,next'
      },
      themeSystem: 'bootstrap3',
      height: 'auto',
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        dow: [ 1, 2, 3, 4, 5 ],

        start: '09:00',
        end: '17:30'
      },
      customButtons: {
        newEvent: {
          text: 'New event',
          click: function() {
            that.onClickNewEvent();
          }
        }
      },
      timeFormat: 'HH:mm',
      nowIndicator: true,
      eventClick: function(calEvent, jsEvent, view) {
        var eventType = calEvent.id.split('_')[0];
        var eventId = calEvent.id.split('_')[1];

        if (eventType === 'reminder') {
          var reminder = reminders.get(parseInt(eventId, 10));
          that.onClickEvent(calEvent, reminder);
        } else {
          var calendarEvent = calendarEvents.get(parseInt(eventId, 10));
          that.onClickEvent(calEvent, calendarEvent);
        }
      },
      dayClick: function(date, jsEvent, view) {
        var clickedDate = date.format();
        if (that.clickedDate !== clickedDate) {
          that.clickedDate = clickedDate;
          that.clickTime = new Date().getTime();
        } else {
          var currentTime = new Date().getTime();
          var timeDiff = currentTime - that.clickTime;
          that.clickTime = currentTime;

          if (timeDiff < 1000) {
            // counts as double click, load the day in a popup
            var url = '/calendar/day/' + clickedDate;
            TCAS.commands.execute('popoutURL', url, 'Calendar', 1024, 800);
          }
        }
      },
      events: function(start, end, timezone, callback) {
        var includeCompanyMeetings = that.ui.filterCompanyMeetings.hasClass('active');
        var includeCompanyAbsences = that.ui.filterCompanyAbsences.hasClass('active');
        var includeMyEvents = that.ui.filterMe.hasClass('active');

        var events = [];
        if (reminders && includeMyEvents) {
          events = reminders.filter(function(reminder) {
            var reminderMoment = moment(reminder.get('due_date'));
            return (reminderMoment.isSameOrAfter(start) && reminderMoment.isSameOrBefore(end));
          }).map(convertReminder);
        }

        if (calendarEvents.length) {
          // split calendar events by type as separate eventSources
          // meetings first
          var filteredCalendarEvents = calendarEvents.filter(function(calendarEvent) {
            var eventStart = moment(calendarEvent.get('start'));
            var eventEnd = moment(calendarEvent.get('end'));
            var eventType = calendarEvent.get('event_type');
            var eventTypeModel = calendarEventTypes.get(eventType);
            var isEventForMe = (calendarEvent.get('users').indexOf(TCAS.session.get('id')) !== -1 &&
              eventTypeModel.get('category') !== 1) ||
              calendarEvent.get('attendees').indexOf(TCAS.session.get('id')) !== -1;

            if ((isEventForMe && !includeMyEvents) || (!isEventForMe && (
                (!includeCompanyMeetings && meetingEventTypes.indexOf(eventType) !== -1) ||
                (!includeCompanyAbsences && absenceEventTypes.indexOf(eventType) !== -1)))) {
              return false;
            }

            return eventStart.isBetween(start, end, 'day', '[]') ||
              eventEnd.isBetween(start, end, 'day', '[]') ||
              (eventStart.isBefore(start) && eventEnd.isAfter(end));
          });
          events = events.concat(filteredCalendarEvents.map(convertCalendarEvent));

          // generate recurring calendar events for this period
          var recurringEvents = calendarEvents.filter(function(calendarEvent) {
            var eventType = calendarEvent.get('event_type');
            var eventTypeModel = calendarEventTypes.get(eventType);
            var isEventForMe =
              (calendarEvent.get('users').indexOf(TCAS.session.get('id')) !== -1 &&
               eventTypeModel.get('category') !== 1) ||
              calendarEvent.get('attendees').indexOf(TCAS.session.get('id')) !== -1;

            if ((isEventForMe && !includeMyEvents) || (!isEventForMe && (
                (!includeCompanyMeetings && meetingEventTypes.indexOf(eventType) !== -1) ||
                (!includeCompanyAbsences && absenceEventTypes.indexOf(eventType) !== -1)))) {
              return false;
            }
            return calendarEvent.get('is_recurring');
          });

          recurringEvents.forEach(function(calendarEvent) {
            var eventStart = moment(moment(calendarEvent.get('start')));
            var eventEnd = moment(moment(calendarEvent.get('end')));
            var recurInterval = calendarEvent.get('recur_interval');
            var recurEvery = calendarEvent.get('recur_every');

            // ignore events that start after this period
            if (eventStart.isAfter(end)) {
              return;
            }

            while (eventStart.isBefore(end)) {
              if (recurInterval === 'four_weeks') {
                eventStart.add(recurEvery * 4, 'weeks');
                eventEnd.add(recurEvery * 4, 'weeks');
              } else {
                eventStart.add(recurEvery, recurInterval);
                eventEnd.add(recurEvery, recurInterval);
              }


              if (eventStart.isBefore(end)) {
                var calendarEventClone = calendarEvent.clone();
                calendarEventClone.set('start', eventStart.toISOString());
                calendarEventClone.set('end', eventEnd.toISOString());

                events.push(convertCalendarEvent(calendarEventClone));
              }
            }
          });
        }

        callback(events);
      }
    });

    if (calendarEvents) {
      this.listenTo(calendarEvents, 'add', function(calendarEvent) {
        this.ui.calendar.fullCalendar('refetchEvents');
      });
    }

    var showEvent = this.getOption('showEvent');
    if (showEvent) {
      if (event instanceof Reminder) {
        var event = convertReminder(showEvent);
      } else {
        event = convertCalendarEvent(showEvent);
      }

      this.ui.calendar.fullCalendar('gotoDate', event.start);
      if (event.end) {
        this.ui.calendar.fullCalendar('select', event.start, event.end);
      } else {
        this.ui.calendar.fullCalendar('select', event.start);
      }

      this.onClickEvent(event, showEvent);
    }
  }
});

module.exports = CalendarView;
