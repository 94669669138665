'use strict';

var GeneralFile = Backbone.Model.extend({
  urlRoot: '/api/generalfiles/'
});

GeneralFile.TYPE_CSV_IMPORT = 0;
GeneralFile.TYPE_TEMPLATE = 1;

GeneralFile.TYPES = {
  0: 'csv import',
  1: 'template'
};

module.exports = GeneralFile;