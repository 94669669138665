// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <a class=\"popout\" href=\""
    + alias3((helpers.caseUrl || (depth0 && depth0.caseUrl) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"caseUrl","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.caseField || (depth0 && depth0.caseField) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),"ref",{"name":"caseField","hash":{},"data":data}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        None\n    ";
},"5":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-briefcase\" title=\"Added as case note\"></i> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.start : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.start : depth0),"HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.end : depth0),"HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.hours || (depth0 != null ? depth0.hours : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"hours","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.owner : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.timeTrackActivity || (depth0 && depth0.timeTrackActivity) || alias2).call(alias1,(depth0 != null ? depth0.activity : depth0),{"name":"timeTrackActivity","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.timeTrackTask || (depth0 && depth0.timeTrackTask) || alias2).call(alias1,(depth0 != null ? depth0.task : depth0),{"name":"timeTrackTask","hash":{},"data":data}))
    + "</td>\n<td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_case_message : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"note","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"useData":true});
