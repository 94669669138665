'use strict';

var Handlebars = require('hbsfy/runtime');
var CaseFile = require('../models/cases/casefile');
var User = require('../models/users/user');
var template = require('../templates/filter-view.hbs');

var FilterView = Backbone.Marionette.ItemView.extend({
  tagName: 'form',
  className: 'case-filter-form',
  template: template,
  ui: {
    filter: '.submit-filter',
    reset: '.reset-filter',
    csvExport: '.csv-export',
    category: '#filter-category',
    categoryGroup: '#filter-category-group',
    caseManager: '#filter-case-manager',
    team: '#filter-team',
    allocation: '#filter-allocation',
    allocationOperator: '#filter-allocation-operator',
    workedOn: '#filter-worked-on',
    status: '#filter-status',
    faSent: '#filter-fa-sent',
    disclosureSent: '#filter-disclosure-sent',
    capitalValue: '#filter-value',
    capitalValueOperator: '#filter-value-operator',
  },
  events: {
    'click @ui.filter': 'filterSubmit',
    'click @ui.reset': 'resetFilter',
    'click @ui.csvExport': 'exportResults',
  },
  updateFilters: function() {
    var category = this.ui.category.select2('val');
    if (category.length > 0) {
      category = category.join(',');
    }
    var categoryGroup = this.ui.categoryGroup.select2('val');
    if (categoryGroup && categoryGroup > 0) {
      categoryGroup = categoryGroup.join(',');
    }
    var caseManager = this.ui.caseManager.select2('val');
    if (caseManager.length) {
      caseManager = caseManager.join(',');
    }
    var team = this.ui.team.select2('val');
    if (team.length) {
      team = team.join(',');
    }
    var allocation = this.ui.allocation.val();
    var workedOn = this.ui.workedOn.val();
    var status = this.ui.status.val();
    var faSent = this.ui.faSent.val();
    var disclosureSent = this.ui.disclosureSent.val();
    var capitalValue = this.ui.capitalValue.val();
    var capitalValueOperator = this.ui.capitalValueOperator.val();

    var filters = {};
    if (category) {
      filters.category__in = category;
    }
    if (categoryGroup) {
      filters.category__category_group__in = categoryGroup;
    }
    if (caseManager && this.ui.caseManager.length > 0) {
      filters.case_manager__in = caseManager;
    }
    if (team.length) {
      filters.team__in = team;
    }
    if (allocation) {
      if (this.ui.allocationOperator.val() === '<') {
        filters.before_allocation_date = allocation;
      } else {
        filters.after_allocation_date = allocation;
      }
    }
    if (workedOn && this.ui.workedOn.length > 0) {
      filters.worked_on = workedOn;
    }
    if (status) {
      filters.status__in = status;
    }
    if (faSent) {
      filters.fa_sent = faSent;
    }
    if (disclosureSent) {
      filters.disclosure_sent = disclosureSent;
    }
    if (capitalValue) {
      if (capitalValueOperator === '=') {
        filters.capital_value = capitalValue;
      } else if (capitalValueOperator === '<') {
        filters.max_capital_value = capitalValue;
      } else if (capitalValueOperator === '>') {
        filters.min_capital_value = capitalValue;
      }
    }

    if (this.getOption('filterTeam')) {
      filters.team = this.getOption('filterTeam');
    }
    this.collection.setFilters(filters);
  },
  filterSubmit: function() {
    this.updateFilters();
    this.ui.csvExport.prop('disabled', false);
    this.ui.csvExport.html('Export');
    var $button = this.ui.filter;
    $button.button('loading');
    this.collection.fetch().then(function() {
      $button.button('reset');
    });

    return false;
  },
  resetFilter: function() {
    //clear the form values
    this.ui.category.select2('val', '');
    this.ui.categoryGroup.select2('val', '');
    this.ui.caseManager.select2('val', '');
    this.ui.team.select2('val', '');
    this.ui.allocation.val('');
    this.ui.workedOn.val('');
    this.ui.status.val('');
    this.ui.faSent.val('');
    this.ui.disclosureSent.val('');
    this.ui.capitalValue.val('');
    this.ui.capitalValueOperator.val('');

    this.ui.csvExport.prop('disabled', false);
    this.ui.csvExport.html('Export');

    this.collection.fetch({ url: this.collection.urlRoot });
  },
  exportResults: function() {
    // check for valid sensitive data token,
    // only allow CSV export if in sensitive data session
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doExportResults(sdToken);
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }
  },
  doExportResults: function(sdToken) {
    var url = '/api/casefilescsv';
    this.ui.csvExport.button('loading');
    this.updateFilters();
    var params = [];
    _.forOwn(this.collection.filters, function(value, filter) {
      params.push(filter + '=' + value);
    });
    url += '?sdtoken=' + encodeURIComponent(sdToken);
    if (params.length) {
      url += '&' + params.join('&');
    }

    window.location.href = url;
    this.ui.csvExport.button('reset');
  },
  onShow: function() {
    var that = this;

    function formatCategory(category, container, query) {
      var s = category.code + ' (' + category.name + ')';
      s = s.replace(new RegExp(query.term, 'gi'), function(q) {
        return '<u>' + q + '</u>';
      });

      return s;
    }

    function formatCategoryGroup(categoryGroup, container, query) {
      var s = categoryGroup.name;
      s = s.replace(new RegExp(query.term, 'gi'), function(q) {
        return '<u>' + q + '</u>';
      });

      return s;
    }

    function formatTeam(team, container, query) {
      var s = Handlebars.Utils.escapeExpression(team.name); // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q) {
        return '<u>' + q + '</u>';
      });

      return s;
    }

    function formatUser(user, container, query) {
      var s = Handlebars.Utils.escapeExpression(user.first_name + ' ' + user.last_name); // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q) {
        return '<u>' + q + '</u>';
      });
      if (!user.is_active) {
        s += ' <span class="badge">Inactive</span>';
      }

      return s;
    }

    this.ui.category.select2({
      placeholder: 'Category name/code',
      minimumInputLength: 1,
      multiple: true,
      ajax: {
        url: '/api/categories/',
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page) {
          return { search: term, page: page };
        },
        results: function(data) {
          return { results: data.results, more: !!data.next };
        },
        cache: true,
      },
      formatResult: formatCategory,
      formatSelection: formatCategory,
      allowClear: true,
    });

    this.ui.categoryGroup.select2({
      placeholder: 'Case type',
      multiple: true,
      ajax: {
        url: '/api/categorygroups/',
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page) {
          return { search: term, page: page };
        },
        results: function(data) {
          return { results: data.results, more: !!data.next };
        },
        cache: true,
      },
      formatResult: formatCategoryGroup,
      formatSelection: formatCategoryGroup,
      allowClear: true,
    });

    var caseManagersUrl = '/api/users/?groups=' + User.CASE_WORKER_GROUPS.join('&groups=');
    if (this.getOption('filterTeam')) {
      caseManagersUrl += '&userdetail__team=' + this.getOption('filterTeam');
    }
    var teamsUrl = '/api/teams/';

    this.ui.caseManager.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      multiple: true,
      ajax: {
        url: caseManagersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page) {
          return { search: term, page: page };
        },
        results: function(data) {
          return { results: data.results, more: !!data.next };
        },
        cache: true,
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
    });

    this.ui.team.select2({
      placeholder: 'Team name',
      multiple: true,
      ajax: {
        url: teamsUrl,
        minimumInputLength: 1,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page) {
          return { search: term, page: page };
        },
        results: function(data) {
          return { results: data.results, more: !!data.next };
        },
        cache: true,
      },
      formatResult: formatTeam,
      formatSelection: formatTeam,
      allowClear: true,
    });

    var caseStatuses = [
      CaseFile.STATUS_IDS.UNALLOCATED,
      CaseFile.STATUS_IDS.RESEARCH,
      CaseFile.STATUS_IDS.IN_CLAIMS,
      CaseFile.STATUS_IDS.ABANDONED,
      CaseFile.STATUS_IDS.CONCLUDED,
    ];

    this.ui.status.select2({
      multiple: true,
      allowClear: true,
      data: caseStatuses.map(function(id) {
        return { id: id, text: CaseFile.STATUS_NAMES[id] };
      }),
    });

    this.ui.allocation.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    });

    _.defer(function() {
      that.$('.select2-input').blur();
    });

    if ($(window).width() < 768) {
      this.$('button').addClass('btn-sm');
    }
  },
  serializeData: function() {
    var data = CaseFile.STATUS_IDS;
    data.excludeFilters = this.getOption('excludeFilters');
    return data;
  },
});

module.exports = FilterView;
