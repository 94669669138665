// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td>\n  "
    + alias3((helpers.capitalize || (depth0 && depth0.capitalize) || alias2).call(alias1,(depth0 != null ? depth0.relationship_type : depth0),{"name":"capitalize","hash":{},"data":data}))
    + "\n</td>\n<td>\n  "
    + alias3((helpers.prospectFullNameOnly || (depth0 && depth0.prospectFullNameOnly) || alias2).call(alias1,(depth0 != null ? depth0.person_a : depth0),true,{"name":"prospectFullNameOnly","hash":{},"data":data}))
    + "\n</td>\n<td>\n  "
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "\n</td>\n<td><div class=\"btn-group\"><button type=\"button\" class=\"btn btn-default btn-xs edit\">Edit</button><button type=\"button\" class=\"btn btn-danger btn-xs delete\"><i class=\"fa fa-trash\"></i></button></div></td>";
},"useData":true});
