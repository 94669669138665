'use strict';
var template = require('../templates/nav-sidebar-fixed.hbs');

var NavSidebarFixedView = Backbone.Marionette.ItemView.extend({
  tagName: 'div',
  className: 'navbar navbar-right',
  template: template,
  events: {
    'click .sb-toggle-left': 'onClickOpenSidebar',
    'click a[href]': 'onClickLink'
  },
  initialize: function() {
    this.listenTo(TCAS.vent, 'route:change', this.render);
  },
  onClickOpenSidebar: function() {
    var $html = $('html');
    $html.addClass('sb-active');
    setTimeout(function() {
      $html.addClass('sb-open');
    }, 400);
  },
  onClickLink: function(e) {
    e.preventDefault();
    var href = this.$(e.currentTarget).attr('href');
    var title = this.$(e.currentTarget).attr('title');
    TCAS.commands.execute('popoutURL', href, title, 1024, 800);
    return false;
  },
  serializeData: function() {
    var path = location.pathname;
    var isCaseActive = path.indexOf('/cases') === 0 ||
      path.indexOf('/reminders') === 0;
    var isRequestsActive = path.indexOf('/requests') === 0;
    var isTemplatesActive = path.indexOf('/templates') === 0;
    var isTimeTrackActive = path.indexOf('/timetrack') === 0;
    var isReportingActive = path.indexOf('/reporting') === 0;
    var isWebsitesActive = path === '/websites';
    var isAdminActive = false;
    var isTeamActive = path.indexOf('/team') === 0;
    var adminPaths = ['/categories', '/users', '/permissions', '/templates'];
    for (var i = 0; i < adminPaths.length; i += 1) {
      if (path.indexOf(adminPaths[i]) === 0) {
        isAdminActive = true;
        break;
      }
    }

    var data = {
      isCaseActive: isCaseActive,
      isRequestsActive: isRequestsActive,
      isTemplatesActive: isTemplatesActive,
      isTimeTrackActive: isTimeTrackActive,
      isReportingActive: isReportingActive,
      isWebsitesActive: isWebsitesActive,
      isAdminActive: isAdminActive,
      isTeamActive: isTeamActive,
      route: location.pathname
    };

    return data;
  }
});

NavSidebarFixedView.ITEMS = {
  CASES             : 'cases',
  CASES_MY          : 'mycases',
  CASES_ADD         : 'addcases',
  CASES_ALL         : 'allcases',
  CASES_ALLOCATE    : 'allocatecases',
  REQUESTS          : 'requests',
  TIME              : 'time',
  REPORTING         : 'reporting',
  ADMIN             : 'admin'
};

module.exports = NavSidebarFixedView;
