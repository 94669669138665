'use strict';

var AssetValue = Backbone.Model.extend({
  urlRoot: '/api/assetvalues/',
  parse: function(response) {
    response.created = new Date(response.created);
    response.modified = new Date(response.modified);
    return response;
  },
  getTotal: function() {
    return this.get('capital_value') + this.get('income_value');
  },
  getTargetRevenue: function() {
    return (this.get('commission') / 100) * this.getTotal();
  }
});

module.exports = AssetValue;