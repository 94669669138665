'use strict';

var template = require('../../templates/websites/websites-table.hbs');
var WebsiteRowView = require('./website-row');
var Website = require('../../models/websites/website');

var WebsitesTableView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childViewContainer: 'tbody.items',
  childView: WebsiteRowView,
  ui: {
    addWebsite: 'tbody.add'
  },
  initNewWebsite: function() {
    this.newWebsiteModel = new Website();
    this.addWebsiteRegion = new Backbone.Marionette.Region({
      el: this.ui.addWebsite
    });
    this.newWebsiteRowView = new WebsiteRowView({
      model: this.newWebsiteModel
    });
    this.newWebsiteRowView.editMode = true;
    this.addWebsiteRegion.show(this.newWebsiteRowView);
    this.listenTo(this.newWebsiteModel, 'sync', function() {
      this.collection.add(this.newWebsiteModel);
      this.stopListening(this.newWebsiteModel);
      this.initNewWebsite();
    });
  },
  onShow: function() {
    this.initNewWebsite();
  }
});

module.exports = WebsitesTableView;
