'use strict';

var moment = require('moment');
var Chart = require('chart.js');
var AnnotationPlugin = require('chartjs-plugin-annotation');
var TimeTrack = require('../../../models/timetrack/timetrack');
var Util = require('../../../util');

var TimetrackDailyHoursBarChartView = Backbone.Marionette.ItemView.extend({
  template: false,
  onRender: function() {
    this.$el.html('<canvas width="800" height="400"></canvas>');

    var entries = this.getOption('collection');
    var fromDateMoment = this.getOption('fromDateMoment');
    var toDateMoment = this.getOption('toDateMoment');
    var availableColours = [
      '#137D79',
      '#595CA5',
      '#00A8B0',
      '#7A9B62',
      '#4097DB',
      '#D1517A',
      '#ADC763',
      '#94679C',
      '#F8DEAE',
      '#E8A43D'
    ];
    var activityOrder = [
      TimeTrack.ACTIVITY_ADMINISTRATION,
      TimeTrack.ACTIVITY_CASE_WORK,
      TimeTrack.ACTIVITY_MANAGEMENT,
      TimeTrack.ACTIVITY_GENERAL,
      TimeTrack.ACTIVITY_LEGAL,
      TimeTrack.ACTIVITY_HR,
      TimeTrack.ACTIVITY_OFFSITE,
      TimeTrack.ACTIVITY_BUSINESS_DEVELOPMENT,
      TimeTrack.ACTIVITY_OPERATIONS
    ];
    var activityTypes = [];
    var owners = [];
    entries.forEach(function(entry) {
      if (activityTypes.indexOf(entry.get('activity')) === -1) {
        activityTypes.push(entry.get('activity'));
      }
      if (owners.indexOf(entry.get('owner')) === -1) {
        owners.push(entry.get('owner'));
      }
    });
    // order activityTypes based on activityOrder
    activityTypes.sort(function(a, b){
      var indexA = activityOrder.indexOf(a);
      var indexB = activityOrder.indexOf(b);
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      } else {
        return 0;
      }
    });

    var activitySeriesData = activityTypes.map(function(activityType, index) {
      var data = [];

      var day = moment(fromDateMoment);
      while(!day.isAfter(toDateMoment)) {
        var dateISO = day.format('YYYY-MM-DD');
        var minutes = 0;
        entries.forEach(function(entry) {
          if (entry.get('activity') === activityType && entry.get('start').indexOf(dateISO) !== -1) {
            minutes += entry.totalMinutes();
          }
        });
        data.push({ t: dateISO, y: minutes / 60 });

        day.add(1, 'day');
      }

      return {
        label: TimeTrack.ACTIVITIES[activityType],
        backgroundColor: availableColours[activityType],
        data: data
      };
    });

    var context = this.$('canvas')[0].getContext('2d');
    var chart = new Chart(context, {
      type: 'bar',
      data: {
        datasets: activitySeriesData
      },
      options: {
        title: {
          display: true,
          fontSize: 18,
          fontColor: '#555',
          fontFamily: "'Questrial', 'Arial', 'Helvetica', sans-serif",
          text: 'Actual Hours Logged ' + fromDateMoment.format('DD/MM/YYYY') + ' - ' +
                toDateMoment.format('DD/MM/YYYY')
        },
        maxBarThickness: 30,
        scales: {
          xAxes: [{
            stacked: true,
            type: 'time',
            distribution: 'series',
            time: {
              unit: 'day'
            }
          }],
          yAxes: [{
            id: 'y-axis',
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: (Math.ceil(7.5 * owners.length) + 1)
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label ||
                data.labels[tooltipItem.datasetIndex];

              if (label) {
                label += ': ';
              }

              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
              label += Util.formatHoursAsTime(value);

              return label;
            }
          }
        },
        annotation: {
          drawTime: 'afterDatasetsDraw',
          annotations: [{
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis',
            value: (7.5 * owners.length),
            borderColor: '#7A9B62',
            borderWidth: 2,
            borderDash: [2, 2],
            borderDashOffset: 5
          }]
        }
      }
    });
  }
});

module.exports = TimetrackDailyHoursBarChartView;
