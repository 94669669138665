'use strict';

var template = require('../../templates/clients/relationship-list.hbs');
var RelationshipRow = require('./relationship-row');
var ConfirmModalView = require('../confirm-modal');

var RelationshipListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: RelationshipRow,
  childViewContainer: '.relationship-items',
  childViewOptions: function() {
    return {
      clients: this.getOption('clients'),
      clientId: this.model.get('id'),
      relations: this.collection
    };
  },
  ui: {
    saveBtn: 'button.save',
    type: 'select.relation-type',
    personA: 'input.person-a',
    description: 'input.relationship-description'
  },
  events: {
    'click @ui.saveBtn': 'onClickSave'
  },
  collectionEvents: {
    'change:is_active': 'render'
  },
  childEvents: {
    'relationship:delete': 'onRelationshipDelete',
    'relationship:save': 'onRelationshipSave'
  },
  filter: function(relation) {
    return relation.get('is_active');
  },
  onClickSave: function() {
    var that = this;
    var relationshipType = this.ui.type.val();
    var personA = this.ui.personA.val()
    var description = this.ui.description.val();
    this.ui.saveBtn.button('loading');

    // jshint ignore:start
    this.collection.create({
      person_a: personA,
      person_b: this.model.get('id'),
      relationship_type: relationshipType,
      description: description
    },
    {
      wait: true,
      success: function() {
        that.ui.saveBtn.button('reset');
      }
    });
    // jshint ignore:end
  },
  onRelationshipDelete: function(view) {
    var confirmModal = new ConfirmModalView({
      body: 'Are you sure you want to remove this relationship?'
    });
    confirmModal.render();
    this.listenTo(confirmModal, 'confirmYes', function() {
      view.model.save({ is_active: false }, { wait: true }); // jshint ignore:line
    });
  },
  onRender: function() {
    var clientId = this.model.get('id');
    var clients = this.getOption('clients');
    var existingRelations = this.collection;

    function compareFunction(a, b) {
      var aName = a.getFullName(true);
      var bName = b.getFullName(true);
      return aName.localeCompare(bName);
    }

    function queryProspects(query) {
      var clientsSorted = clients.toArray().sort(compareFunction);
      var clientsSelectData = clientsSorted.filter(function(prospect) {
        // can't be related to yourself
        if (prospect.get('id') === clientId) {
          return false;
        }

        // ignore prospects that are already in relations
        if (existingRelations.some(
          function(relation) {
            return relation.get('is_active') && relation.get('person_a') === prospect.get('id');
          }
        )) {
          return false;
        }
        if (query.term.length > 0) {
          return prospect.getFullName(true).toLowerCase()
            .indexOf(query.term.toLowerCase()) !== -1;
        }
        return true;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: prospect.getFullName(true)
        };
      });

      query.callback({results: clientsSelectData, more: false});
    }

    this.ui.personA.select2({
      query: queryProspects,
      initSelection : function (element, callback) {
        var id = element.val();
        var data = { id: id, text: clients.get(id).getFullName(true) };
        callback(data);
      }
    });
  }
});

module.exports = RelationshipListView;
