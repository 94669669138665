// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <tr "
    + ((stack1 = (helpers.ifOdd || (depth0 && depth0.ifOdd) || alias2).call(alias1,(data && data.index),{"name":"ifOdd","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      <th>"
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.id : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "</th>\n      <th>Actual</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalByMonth : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tr>\n    <tr "
    + ((stack1 = (helpers.ifOdd || (depth0 && depth0.ifOdd) || alias2).call(alias1,(data && data.index),{"name":"ifOdd","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalYTD : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tr>\n"
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "class=\"row-alternate\"";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <td>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "      <tr>\n        <td colspan=\"14\" class=\"table-separator\"></td>\n      </tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table class=\"table table-condensed table-responsive\">\n  <thead>\n  <tr>\n    <th></th>\n    <th></th>\n    <th>Jan</th>\n    <th>Feb</th>\n    <th>Mar</th>\n    <th>Apr</th>\n    <th>May</th>\n    <th>Jun</th>\n    <th>Jul</th>\n    <th>Aug</th>\n    <th>Sep</th>\n    <th>Oct</th>\n    <th>Nov</th>\n    <th>Dec</th>\n  </tr>\n  </thead>\n  <tbody>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tbody>\n</table>";
},"useData":true});
