// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "          <th class=\"col-md-2\">FAO</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "          <th class=\"col-md-2\">Case</th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <tbody>\n        <tr>\n          <td>\n            <div class=\"form-group\">\n              <input type=\"text\" name=\"due_date\" class=\"form-control input-sm reminder-due-date\"\n                placeholder=\"DD/MM/YYYY\" value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.due_date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n            </div>\n          </td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showUserColumn : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          <td "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCasefile : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <div class=\"form-group\">\n              <input type=\"text\" name=\"message\" placeholder=\"Reminder message...\"\n                class=\"form-control input-sm reminder-message\" />\n            </div>\n          </td>\n          <td>\n            <button type=\"submit\" class=\"btn btn-sm btn-success\" data-loading-text=\"Saving...\">Add Reminder</button>\n          </td>\n        </tr>\n      </tbody>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "          <td>\n            <div class=\"form-group create-fao-select-container\">\n            </div>\n          </td>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "colspan=\"2\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form>\n  <div class=\"table-responsive\">\n    <table class=\"table table-condensed table-hover\">\n      <thead>\n        <tr>\n          <th class=\"col-md-2\">Due date</th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showUserColumn : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          <th class=\"col-md-5\">Message</th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCasefile : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          <th>Action</th>\n        </tr>\n      </thead>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAddForm : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <tbody class=\"reminder-rows\"></tbody>\n    </table>\n  </div>\n</form>";
},"useData":true});
