'use strict';

var template = require('../../../templates/reporting/timetrack-report.hbs');
var moment = require('moment');
var Users = require('../../../models/users/users');
var Teams = require('../../../models/users/teams');
var TeamAllocations = require('../../../models/users/teamallocations');
var Timetracks = require('../../../models/timetrack/timetracks');
var TimeTrack = require('../../../models/timetrack/timetrack');
var CalendarEvents = require('../../../models/events/calendarevents');
var TimetrackListView = require('../../timetrack/timetrack-list');
var TimetrackDailyBreakdownTableView = require('./timetrack-daily-breakdown-table');
var TimetrackTaskBreakdownTableView = require('./timetrack-task-breakdown-table');
var TimetrackDailyHoursBarChartView = require('./timetrack-daily-hours-bar-chart');
var TimetrackPieChartView = require('./timetrack-pie-chart');
var TimetrackIndividualPieChartsView = require('./timetrack-individual-pie-charts-list-view');
var TimetrackTaskPieChartsView = require('./timetrack-task-pie-charts');
var CaseManagerSelectView = require('../../allocations/casemanager-select');

var TimeTrackReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'timetrack-report',
  regions: {
    caseManagerRegion: '.casemanager-select-container',
    timetrackDailyBreakdownRegion: '.timetrack-daily-breakdown',
    dailyHoursBarChartRegion: '.timetrack-daily-hours-bar-chart',
    timetrackTaskBreakdownRegion: '.timetrack-task-breakdown',
    activityPieChartRegion: '.timetrack-activity-pie-chart',
    individualActivityPieChartsRegion: '.timetrack-individual-activity-pie-charts',
    activityTaskPieChartsRegion: '.timetrack-activity-task-pie-charts',
    granularRegion: '.timetrack-granular'
  },
  ui: {
    submit: '.submit-btn',
    dateFrom: '.date-from',
    dateTo: '.date-to',
    team: '.team',
    reportName: '.report-for-name',
    alertArea: '#alert-area',
    reportingArea: '.reporting-area',
    timetrackReportTable: '.timetrack-report-table',
    tabSummary: '.tab-timetrack-summary',
    tabGranular: '.tab-timetrack-granular',
    exportGranularCSV: '.export-granular-csv'
  },
  events: {
    'click @ui.exportGranularCSV': 'onClickExportGranularCSV'
  },
  behaviors: {
    SubmitForm: {
      selector: 'form.timetrack-filters',
      submitHandler: 'generateReport'
    }
  },
  initialize: function() {
    if (!TCAS.session.hasPerm('filter_timetrack_report_team')) {
      var teamId = TCAS.reqres.request('teamId', TCAS.session.get('id'));
      this.selectedTeams = [teamId];
    }
  },
  onClickExportGranularCSV: function() {
    // check for valid sensitive data token,
    // only allow CSV export if in sensitive data session
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doExportGranularCSV(sdToken);
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }
  },
  doExportGranularCSV: function(sdToken) {
    var fromDateMoment;
    var toDateMoment;

    var userIds = this.caseManagerSelectView.getSelectedCaseManager();
    if (userIds) {
      userIds = userIds.split(',');
      for (var i = 0; i < userIds.length; i += 1) {
        userIds[i] = parseInt(userIds[i], 10);
      }
    }

    var teams = [];
    if (this.ui.team.val()) {
      teams = this.ui.team.val().split(',');
      teams = teams.map(function (team) {
        return parseInt(team, 10);
      });
    }

    /* jshint ignore:start */
    if (this.ui.dateFrom.val()) {
      fromDateMoment = moment(
        this.ui.dateFrom.val().split('/').reverse().join('-')
      );
    } else {
      fromDateMoment = moment().startOf('year');
    }
    if (this.ui.dateTo.val()) {
      toDateMoment = moment(
        this.ui.dateTo.val().split('/').reverse().join('-')
      );
    } else {
      toDateMoment = moment();
    }

    var filters = {};

    filters.start_a = fromDateMoment.toDate().toISOString();
    filters.start_b = toDateMoment.toDate().toISOString();
    if (userIds.length) {
      filters.owner__in = userIds;
    }
    if (teams.length) {
      filters.team__in = teams
    }

    var url = '/api/timetrack/csv/';
    this.ui.exportGranularCSV.button('loading');
    var params = [];
    _.forOwn(filters, function(value, filter){
      params.push(filter + '=' + value);
    });
    url += '?sdtoken=' + encodeURIComponent(sdToken);
    if (params.length) {
      url += '&' + params.join('&');
    }

    window.location.href = url;
    this.ui.exportGranularCSV.button('reset');
  },
  generateReport: function(data) {
    var that = this;
    var fromDateMoment;
    var toDateMoment;
    var teamAllocations = this.getOption('teamAllocations');
    var timetracks = new Timetracks();
    var bankHolidayEvents = new CalendarEvents();
    var absenceEvents = new CalendarEvents();
    var userIds = this.caseManagerSelectView.getSelectedCaseManager();
    if (userIds) {
      userIds = userIds.split(',');
      for (var i = 0; i < userIds.length; i += 1) {
        userIds[i] = parseInt(userIds[i], 10);
      }
    }
    this.userIds = userIds;
    var users = this.getOption('users');
    this.filteredUsers = users.toArray();
    this.includeInactiveUsers = data.include_inactive === '1'; // jshint ignore:line
    if (!this.includeInactiveUsers) {
      this.filteredUsers = this.filteredUsers.filter(function (user) {
        return user.get('is_active');
      });
    }
    if (data.team) {
      var teams = data.team.split(',');
      teams = teams.map(function (team) {
        return parseInt(team, 10);
      });
      this.selectedTeams = teams;
    } else {
      this.selectedTeams = [];
    }
    var selectedTeams = this.selectedTeams;

    /* jshint ignore:start */
    if (data.date_from) {
      fromDateMoment = moment(
        data.date_from.split('/').reverse().join('-')
      );
    } else {
      fromDateMoment = moment().startOf('year');
    }
    if (data.date_to) {
      toDateMoment = moment(
        data.date_to.split('/').reverse().join('-')
      );
    } else {
      toDateMoment = moment();
    }

    this.fromDate = fromDateMoment.toDate();
    if (toDateMoment) {
      this.toDate = toDateMoment.toDate();
    }

    var filters = {
      date_range_0: fromDateMoment.format('DD/MM/YYYY'),
      date_range_1: toDateMoment.format('DD/MM/YYYY'),
      is_active: true,
      page_size: 100000
    };

    if (toDateMoment) {
      filters.date_before = toDateMoment.format('DD/MM/YYYY');
    }

    bankHolidayEvents.setFilters({
      event_type: 1, // 1 - Bank holiday
      start_0: fromDateMoment.format('DD/MM/YYYY'),
      start_1: toDateMoment.format('DD/MM/YYYY')
    });
    absenceEvents.setFilters({
      event_type__is_timetrack_modifier: true,
      start_0: fromDateMoment.format('DD/MM/YYYY'),
      start_1: toDateMoment.format('DD/MM/YYYY')
    });

    if (userIds) {
      filters.owner__in = userIds.join(','); // jshint ignore:line
      this.filteredUsers = users.filter(function(u) {
        return userIds.indexOf(u.get('id')) !== -1;
      });
    } else if (selectedTeams.length) {
      filters.team__in = selectedTeams.join(','); // jshint ignore:line

      // set the team name in heading
      var teamNames = this.getOption('teams').filter(function(team) {
        return selectedTeams.indexOf(team.get('id')) !== -1;
      }).map(function(team) {
        return team.get('name');
      }).join(', ');
      this.teamNames = teamNames;
      this.ui.reportName.text(' - ' + teamNames).removeClass('hidden');
    } else {
      this.teamNames = null;
      this.ui.reportName.text('').addClass('hidden');
    }

    timetracks.setFilters(filters);

    this.ui.submit.button('loading');

    $.when.apply($, [timetracks.fetch(), bankHolidayEvents.fetch(), absenceEvents.fetch()]).then(function() {
      that.ui.reportingArea.removeClass('hidden');
      if (selectedTeams.length) {
        // filter the users only those from these teams
        that.filteredUsers = that.filteredUsers.filter(function(user) {
          var teamAllocation = teamAllocations.find(function(teamAllocation) {
            return teamAllocation.get('user') === user.get('id');
          });
          return teamAllocation && selectedTeams.indexOf(teamAllocation.get('team')) !== -1;
        });
      }

      // activity order indicates the preferred order to list the activities
      var activityOrder = [
        TimeTrack.ACTIVITY_ADMINISTRATION,
        TimeTrack.ACTIVITY_CASE_WORK,
        TimeTrack.ACTIVITY_MANAGEMENT,
        TimeTrack.ACTIVITY_GENERAL,
        TimeTrack.ACTIVITY_LEGAL,
        TimeTrack.ACTIVITY_HR,
        TimeTrack.ACTIVITY_OFFSITE,
        TimeTrack.ACTIVITY_BUSINESS_DEVELOPMENT,
        TimeTrack.ACTIVITY_OPERATIONS
      ];

      var activityMap = {};
      var activityTypes = [];

      // collect minutes by activity under specific tasks
      timetracks.forEach(function(entry) {
        var activityType = entry.get('activity');
        var task = entry.get('task');
        if (!activityMap.hasOwnProperty(activityType)) {
          activityMap[activityType] = {};
          activityTypes.push(activityType);
        }

        if (!activityMap[activityType].hasOwnProperty(task)) {
          activityMap[activityType][task] = 0;
        }
        activityMap[activityType][task] += entry.totalMinutes();
      });

      // order activityTypes based on activityOrder
      activityTypes.sort(function(a, b){
        var indexA = activityOrder.indexOf(a);
        var indexB = activityOrder.indexOf(b);
        if (indexA < indexB) {
          return -1;
        } else if (indexA > indexB) {
          return 1;
        } else {
          return 0;
        }
      });

      var availableColours = [
        '#137D79',
        '#595CA5',
        '#00A8B0',
        '#7A9B62',
        '#4097DB',
        '#D1517A',
        '#ADC763',
        '#94679C',
        '#F8DEAE',
        '#E8A43D'
      ];

      var activityData = activityTypes.map(function(activityType) {
        var tasksMap = activityMap[activityType];
        var taskOrder = TimeTrack.ACTIVITY_TASKS[activityType];
        var taskData = [];
        var totalMinutes = 0;

        taskOrder.forEach(function(taskType) {
          if (tasksMap.hasOwnProperty(taskType)) {
            taskData.push({
              task: taskType,
              name: TimeTrack.TASKS[taskType],
              minutes: tasksMap[taskType]
            });
            totalMinutes += tasksMap[taskType];
          }
        });

        return {
          activity: activityType,
          name: TimeTrack.ACTIVITIES[activityType],
          tasks: taskData,
          totalMinutes: totalMinutes
        };
      });
      that.activityPieChartRegion.show(new TimetrackPieChartView({
        data: activityData.map(function(activity) {
          return activity.totalMinutes;
        }),
        labels: activityData.map(function(activity) {
          return activity.name;
        }),
        backgroundColor: activityData.map(function(activity) {
          return availableColours[activity.activity];
        }),
        title: 'Spread of Actual Activity'
      }));

      // create collection view to render individual activity pie charts for users
      if (that.filteredUsers.length > 1) {
        var individualPieChartsView = new TimetrackIndividualPieChartsView({
          collection: new Users(that.filteredUsers),
          timetracks: timetracks,
          activityOrder: activityOrder,
          availableColours: availableColours
        });
        that.individualActivityPieChartsRegion.show(individualPieChartsView);
      } else {
        that.individualActivityPieChartsRegion.empty();
      }

      var activityCollection = new Backbone.Collection(activityData);

      that.timetrackDailyBreakdownRegion.show(new TimetrackDailyBreakdownTableView({
        collection: timetracks,
        fromDateMoment: fromDateMoment,
        toDateMoment: toDateMoment,
        bankHolidayEvents: bankHolidayEvents,
        absenceEvents: absenceEvents
      }));

      that.dailyHoursBarChartRegion.show(new TimetrackDailyHoursBarChartView({
        collection: timetracks,
        fromDateMoment: fromDateMoment,
        toDateMoment: toDateMoment
      }));

      that.timetrackTaskBreakdownRegion.show(new TimetrackTaskBreakdownTableView({
        activityData: activityData
      }));

      that.activityTaskPieChartsRegion.show(new TimetrackTaskPieChartsView({
        collection: activityCollection,
        start: that.fromDate,
        end: that.toDate,
        userIds: that.filteredUsers.map(function(user) {
          return user.get('id');
        }),
        teamIds: that.selectedTeams
      }));

      that.granularRegion.show(new TimetrackListView({
        collection: timetracks
      }));

      that.ui.submit.button('reset');
    });
  },
  onRender: function() {
    var selectOptions = { multiple: true };
    if (!TCAS.session.hasPerm('filter_timetrack_report_team')) {
      var teamAllocations = this.getOption('teamAllocations');
      var teamAllocation = teamAllocations.find(function(teamAllocation) {
        return teamAllocation.get('user') === TCAS.session.get('id');
      });
      if (teamAllocation) {
        selectOptions.team = teamAllocation.get('team');
      }
    }

    if (!TCAS.session.hasPerm('filter_timetrack_report_user')) {
      selectOptions.user = TCAS.session.get('id');
      selectOptions.disabled = true;
    } else if (this.userIds) {
      selectOptions.user = this.userIds;
    }

    this.caseManagerSelectView = new CaseManagerSelectView(selectOptions);
    // this.listenTo(this.caseManagerSelectView, 'selected', this.checkInputs);
    this.caseManagerRegion.show(this.caseManagerSelectView);

    var teamOptions = {
      data: this.getOption('teams').map(function(team) {
        return {
          id: team.get('id'),
          text: team.get('name')
        };
      }),
      multiple: true
    };
    this.ui.team.val(this.selectedTeams).select2(teamOptions);
  },
  serializeData: function() {
    var data = {
      isTeamDisabled: !TCAS.session.hasPerm('filter_timetrack_report_team')
    };

    return data;
  }
});

module.exports = TimeTrackReportView;