'use strict';

var Chart = require('chart.js');
var template = require('../../../templates/reporting/survey-report-pie-chart.hbs');

var SurveyPieChartView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'survey-pie-chart',
  ui: {
    canvas: '.survey-pie-chart-canvas',
  },
  onRender: function() {
    var answers = this.getOption('answers');
    var question = this.getOption('question');

    var data = [];
    var labels = [];

    question.get('choices').forEach(function(choice) {
      labels.push(choice.choice);
      var count = 0;

      answers.forEach(function(answer) {
        if (answer.get('answer_multiple_choice') === choice.id) {
          count += 1;
        }
      });

      data.push(count);
    });

    var title = 'Question ' + question.get('number') + ': ' + question.get('question_short');
    // max 80 chars per line
    if (title.length > 50) {
      var titleSplit = title.split(' ');
      var words = [];
      var lines = [];
      titleSplit.forEach(function(word) {
        words.push(word);
        if (words.join(' ').length > 50) {
          lines.push(words.slice(0, words.length - 1).join(' '));
          words = [words[words.length - 1]];
        }
      });
      if (words.length) {
        lines.push(words.join(' '));
      }
      title = lines;
    }

    var availableColours = [
      '#137D79',
      '#595CA5',
      '#00A8B0',
      '#7A9B62',
      '#4097DB',
      '#D1517A',
      '#ADC763',
      '#94679C',
      '#F8DEAE',
      '#E8A43D',
    ];

    var backgroundColors = availableColours.slice(0, labels.length);

    var context = this.ui.canvas[0].getContext('2d');
    var chart = new Chart(context, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: this.getOption('backgroundColor')
              ? this.getOption('backgroundColor')
              : backgroundColors,
          },
        ],
      },
      options: {
        title: {
          display: true,
          fontSize: 18,
          fontColor: '#555',
          fontFamily: "'Questrial', 'Arial', 'Helvetica', sans-serif",
          text: title,
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label =
                data.datasets[tooltipItem.datasetIndex].label || data.labels[tooltipItem.index];

              if (label) {
                label += ': ';
              }

              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              label += value;

              return label;
            },
          },
        },
        pieceLabel: {
          // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          render: 'percentage',
          fontSize: 16,
          fontStyle: 'bold',
          fontColor: '#fff',
        },
      },
    });

    this.$('[data-toggle="popover"]').popover({
      html: true,
      placement: 'auto',
      content: function() {
        var content = $(this).attr('data-popover-content');
        return $(content).html();
      },
      title: question.get('number') + ': ' + question.get('question'),
    });
  },
  serializeData: function() {
    var answers = this.getOption('answers');
    var question = this.getOption('question');
    var choices = question.get('choices');
    var results = [];
    var choicesTotal = choices.map(function(choice) {
      return 0;
    });
    var uniqueCaseManagers = [];
    answers.forEach(function(answer) {
      if (answer.get('question') === question.get('id')) {
        var choice = answer.get('answer_multiple_choice');

        for (var i = 0; i < choices.length; i += 1) {
          if (choice === choices[i].id) {
            choicesTotal[i] += 1;
          }
        }

        var caseManager = answer.get('case_manager');
        if (uniqueCaseManagers.indexOf(caseManager) === -1) {
          uniqueCaseManagers.push(caseManager);
        }
      }
    });

    // group the answers by question and case manager
    uniqueCaseManagers.forEach(function(caseManagerId) {
      var row = [caseManagerId];
      question.get('choices').forEach(function(choice) {
        var count = answers.filter(function(answer) {
          return (
            answer.get('answer_multiple_choice') === choice.id &&
            answer.get('case_manager') === caseManagerId
          );
        }).length;
        row.push(count);
      });
      results.push(row);
    });

    return {
      question: question.toJSON(),
      results: results,
      caseManagers: uniqueCaseManagers,
      choicesTotal: choicesTotal,
    };
  },
});

module.exports = SurveyPieChartView;
