'use strict';

var BaseCollection = require('../base-collection');
var FinancialService = require('./financialservice');

var FinancialServices = BaseCollection.extend({
  urlRoot: '/api/financialservices/',
  model: FinancialService,
});

module.exports = FinancialServices;
