'use strict';

var template = require('../../templates/teams/company-target-details.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var CompanyTarget = require('../../models/reporting/companytarget');

var CompanyTargetDetailsView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    alertsArea: '.alerts-area',
    year: '.year'
  },
  events: {
    'change @ui.year': 'onYearChanged'
  },
  behaviors: {
    SubmitForm: {
      selector: 'form',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          year: {
            required: true,
          },
          performance_revenue: {
            required: true,
            number: true
          },
          stretch_revenue: {
            required: true,
            number: true
          },
          average_asset_case_size: {
            required: true,
            number: true
          },
          average_la_case_size: {
            required: true,
            number: true
          },
          booked_asset_volume: {
            required: true,
            number: true
          },
          booked_la_volume: {
            required: true,
            number: true
          }
        }
      }
    }
  },
  onYearChanged: function() {
    var year = parseInt(this.ui.year.val(), 10);
    this.year = year;

    // if year exists in collection, re-render
    var targetForYear = this.collection.find(function(companyTarget) {
      return parseInt(companyTarget.get('year'), 10) === year;
    });

    if (targetForYear) {
      this.render();
    }
  },
  submitData: function(data) {
    var that = this;
    var year = parseInt(data.year, 10);

    // find the model for the given year, if it does exist create one
    var model = this.collection.find(function(companyTarget) {
      return parseInt(companyTarget.get('year'), 10) === year;
    });

    function onSuccess() {
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'Company targets successfully.',
          type: 'success'
        })
      );
    }

    if (model) {
      model.save(data, { wait: true, success: onSuccess });
    } else {
      this.collection.create(data, { wait: true, success: onSuccess });
    }
  },
  serializeData: function() {
    if (!this.year) {
      this.year = new Date().getFullYear();
    }
    var year = this.year;

    var model = this.collection.find(function(companyTarget) {
      return parseInt(companyTarget.get('year'), 10) === year;
    });

    if (!model) {
      model = new CompanyTarget({
        year: year
      });
    }

    this.model = model;

    var data = model.toJSON();
    data.years = [];
    for (var i = 2015; i <= 2050; i += 1) {
      data.years.push(i);
    }

    return data;
  }
});

module.exports = CompanyTargetDetailsView;
