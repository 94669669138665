'use strict';

var CaseFileRowView = require('./casefile-row');
var template = require('../../templates/cases/client-casefile-row.hbs');

var ClientCaseFileRowView = CaseFileRowView.extend({
  template: template,
  triggers: {
    'click': 'case:click'
  }
});

module.exports = ClientCaseFileRowView;