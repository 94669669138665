'use strict';

var AllocationListView = require('./allocation-list');
var template = require('../../templates/allocations/allocation-panel.hbs');
var User = require('../../models/users/user');
var CaseFile = require('../../models/cases/casefile');
var Allocation = require('../../models/cases/allocation');

var AllocationPanelView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    'caseManager': '#case-manager',
    'partner': '#partner',
    'allocateCase': '#allocate-case',
    'form': 'form'
  },
  events: {
    'submit @ui.form': 'allocateCase'
  },
  onRender: function() {

    this.listCases = new Backbone.Marionette.Region({
      el: this.$('#allocations-list')[0]
    });

    this.renderAllocations();

    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      return s;
    }

    var caseManagerUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.caseManager.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseManagerUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      initSelection: function(element, callback) {
        var id = $(element).val();
        if (id !== '') {
          $.getJSON('/api/users/'+id+'/', function(data) {
            callback(data);
          });
        }
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true
    });

    this.ui.partner.select2({
      placeholder: 'Partner name',
      minimumInputLength: 1,
      ajax: {
        url: caseManagerUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true
    });
  },
  allocateCase: function() {
    var that = this;
    var caseManager = this.ui.caseManager.select2('val');
    var partner = this.ui.partner.select2('val');
    if(caseManager === '') {
      return;
    }

    var allocation = new Allocation({
      case: that.options.casefile.get('id'),
      case_manager: caseManager, // jshint ignore:line
      owner: TCAS.session.get('id')
    });

    if (partner !== '') {
      allocation.set('partner', partner);
    }

    allocation.save({}, {
      success: function() {
        that.options.allocations.fetch().then(function() {
          that.renderAllocations();
        });
        that.options.casefile.set('status', CaseFile.STATUS_IDS.RESEARCH);
        TCAS.vent.trigger('status:change');
      },
      error: function() {
      }
    });
    return false;
  },
  renderAllocations: function() {
    var allocationListView = new AllocationListView({
      collection: this.options.allocations,
      casefile: this.options.casefile
    });
    this.listCases.show(allocationListView);
  },
  serializeData: function() {
    var allocation = this.getOption('casefile').getCurrentAllocation();
    var data = {
      currentAllocation: allocation ? allocation.toJSON() : null
    };

    return data;
  }
});

module.exports = AllocationPanelView;