'use strict';

var template = require('../../../templates/reporting/pipeline-report.hbs');
var moment = require('moment');
var Teams = require('../../../models/users/teams');
var TeamAllocations = require('../../../models/users/teamallocations');
var CaseFiles = require('../../../models/cases/casefiles');
var BookingRecords = require('../../../models/reporting/bookingrecords');
var ForecastingRecords = require('../../../models/reporting/forecastingrecords');
var PledgingRecords = require('../../../models/reporting/pledgingrecords');
var Milestones = require('../../../models/cases/milestones');
var UserTargets = require('../../../models/reporting/usertargets');
var CaseManagerSelectView = require('../../allocations/casemanager-select');
var PipelineGranularListView = require('./pipeline-granular-list');

var PipelineReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'pipeline-report',
  regions: {
    caseManagerRegion: '.casemanager-select-container',
    partnerRegion: '.partner-select-container',
    granularRegion: '.pipeline-granular',
  },
  ui: {
    submit: '.submit-btn',
    bookingDateFrom: '.booking-date-from',
    bookingDateTo: '.booking-date-to',
    team: '.team',
    category: '.category',
    reportName: '.report-for-name',
    alertArea: '#alert-area',
    reportingArea: '.reporting-area',
    pipelineReportTable: '.pipeline-report-table',
    pipelineStatsTable: '.pipeline-report-stats-table',
    tabSummary: '.tab-pipeline-summary',
    tabGranular: '.tab-pipeline-granular',
  },
  behaviors: {
    SubmitForm: {
      selector: 'form.pipeline-filters',
      submitHandler: 'generateReport',
    },
  },
  initialize: function() {
    this.filteredUsers = [];
    this.teams = new Teams();
    this.userTargets = new UserTargets();
    this.userTargets.setFilters({ page_size: 1000 });
    this.bookingAssetRecords = new BookingRecords();
    this.bookingLARecords = new BookingRecords();
    this.forecastingAssetRecords = new ForecastingRecords();
    this.forecastingLARecords = new ForecastingRecords();
    this.pledgingAssetRecords = new PledgingRecords();
    this.pledgingLARecords = new PledgingRecords();
    this.disclosureMilestones = new Milestones();
    this.casefiles = new CaseFiles();
    this.listenTo(this.teams, 'sync', this.render);
    this.listenTo(TCAS.archiveDataTokens, 'sync', this.render);
    this.teams.fetch();
    // default from date is beginning of the current year
    this.fromDate = moment()
      .startOf('year')
      .toDate();

    if (!TCAS.session.hasPerm('filter_pipeline_report_team')) {
      var teamId = TCAS.reqres.request('teamId', TCAS.session.get('id'));
      this.selectedTeams = [teamId];
    }
  },
  generateReport: function(data) {
    var that = this;
    var categories = this.getOption('categories');
    var fromBookingDateMoment;
    var toBookingDateMoment;
    var userIds = this.caseManagerSelectView.getSelectedCaseManager();
    if (userIds) {
      userIds = userIds.split(',');
      for (var i = 0; i < userIds.length; i += 1) {
        userIds[i] = parseInt(userIds[i], 10);
      }
    }
    var partnerIds = this.partnerSelectView.getSelectedCaseManager();
    if (partnerIds) {
      partnerIds = partnerIds.split(',');
      for (var i = 0; i < partnerIds.length; i += 1) {
        partnerIds[i] = parseInt(partnerIds[i], 10);
      }
    }
    this.userIds = userIds;
    this.partnerIds = partnerIds;
    var users = this.getOption('users');
    this.caseType = data.case_type; // jshint ignore:line
    this.category = parseInt(data.category, 10);
    this.filteredUsers = users.toArray();
    this.includeInactiveUsers = data.include_inactive === '1'; // jshint ignore:line
    if (!this.includeInactiveUsers) {
      this.filteredUsers = this.filteredUsers.filter(function(user) {
        return user.get('is_active');
      });
    }
    if (data.team) {
      var teams = data.team.split(',');
      teams = teams.map(function(team) {
        return parseInt(team, 10);
      });
      this.selectedTeams = teams;
    } else {
      this.selectedTeams = [];
    }
    var selectedTeams = this.selectedTeams;

    /* jshint ignore:start */
    if (data.booking_date_from) {
      fromBookingDateMoment = moment(
        data.booking_date_from
          .split('/')
          .reverse()
          .join('-')
      );
    }
    if (data.booking_date_to) {
      toBookingDateMoment = moment(
        data.booking_date_to
          .split('/')
          .reverse()
          .join('-')
      );
    }
    /* jshint ignore:end */

    // assume filtering based on start of year, but use booking date filters
    // if they're provided
    var now = moment();
    var startOfYearMoment = moment().startOf('year');
    // assume friday is the previous week unless it isn't
    var fridayMoment = moment()
      .day(-2)
      .startOf('day');
    if (now.day() >= 5) {
      fridayMoment = moment(now)
        .day(5)
        .startOf('day');
    }

    if (fromBookingDateMoment) {
      startOfYearMoment = fromBookingDateMoment;
    }
    // special handling where an end date is given, treat end date like friday
    if (toBookingDateMoment) {
      now = moment(toBookingDateMoment);
      fridayMoment = moment(toBookingDateMoment);
    }

    var bookingRecords = new BookingRecords();
    var forecastingRecords = new ForecastingRecords();
    var pledgingRecords = new PledgingRecords();
    var teamAllocations = new TeamAllocations();
    this.fromDate = startOfYearMoment.toDate();
    if (toBookingDateMoment) {
      this.toDate = now.toDate();
    } else {
      this.toDate = null;
    }

    this.userTargets.setFilters({
      year: this.toDate ? this.toDate.getFullYear() : new Date().getFullYear(),
    });

    this.friday = fridayMoment.toDate();
    this.bookingAssetRecords.reset();
    this.bookingLARecords.reset();
    this.forecastingAssetRecords.reset();
    this.forecastingLARecords.reset();
    this.pledgingAssetRecords.reset();
    this.pledgingLARecords.reset();
    this.casefiles.reset();
    this.disclosureMilestones.reset();

    var filters = {
      date_after: startOfYearMoment.format('DD/MM/YYYY'),
      is_active: true,
      page_size: 10000,
    };
    if (toBookingDateMoment) {
      filters.date_before = toBookingDateMoment.format('DD/MM/YYYY');
    }
    if (!this.includeInactiveUsers) {
      filters.case_manager__is_active = true;
    }
    if (partnerIds) {
      filters.partner__in = partnerIds.join(',');
    }
    if (userIds) {
      filters.case_manager__in = userIds.join(','); // jshint ignore:line
      this.filteredUsers = users.filter(function(u) {
        return userIds.indexOf(u.get('id')) !== -1;
      });
    } else if (selectedTeams.length) {
      filters.team__in = selectedTeams.join(','); // jshint ignore:line
      teamAllocations.setFilters({
        date_range_0: startOfYearMoment.format('DD/MM/YYYY'),
        date_range_1: now.format('DD/MM/YYYY'),
        team__in: selectedTeams.join(','),
      });

      // set the team name in heading
      var teamNames = this.teams
        .filter(function(team) {
          return selectedTeams.indexOf(team.get('id')) !== -1;
        })
        .map(function(team) {
          return team.get('name');
        })
        .join(', ');
      this.teamNames = teamNames;
      this.ui.reportName.text(' - ' + teamNames).removeClass('hidden');
    } else {
      this.teamNames = null;
      this.ui.reportName.text('').addClass('hidden');
    }

    /* jshint ignore:start */
    if (this.category) {
      // filter financials based on case category
      filters.case__category = this.category;
    }
    /* jshint ignore:end */

    bookingRecords.setFilters(filters);
    forecastingRecords.setFilters(filters);
    pledgingRecords.setFilters(filters);

    this.ui.submit.button('loading');

    var fetches = [
      bookingRecords.fetch(),
      forecastingRecords.fetch(),
      pledgingRecords.fetch(),
      this.userTargets.fetch(),
    ];

    if (selectedTeams.length) {
      fetches.push(teamAllocations.fetch());
    }

    // fetch bookingRecords, forecast records and pledges during this period
    $.when.apply($, fetches).done(function() {
      if (selectedTeams.length) {
        // filter the users only those from these teams
        that.filteredUsers = that.filteredUsers.filter(function(user) {
          var hasTeamAllocation = teamAllocations.some(function(teamAllocation) {
            return teamAllocation.get('user') === user.get('id') && selectedTeams.indexOf(teamAllocation.get('team')) !== -1;
          });
          return hasTeamAllocation;
        });
      }

      var caseIds = bookingRecords.map(function(bookingRecord) {
        return bookingRecord.get('case');
      });
      caseIds = caseIds.concat(
        forecastingRecords.map(function(record) {
          return record.get('case');
        }),
        pledgingRecords.map(function(record) {
          return record.get('case');
        })
      );
      caseIds = _.uniq(caseIds);
      that.casefiles.setFilters({
        id__in: caseIds.length ? caseIds.join(',') : 0,
        is_active: true,
        page_size: 10000,
      });
      that.disclosureMilestones.setFilters({
        case__in: caseIds.length ? caseIds.join(',') : 0,
        milestone_type: 26,
        is_active: true,
        page_size: 10000,
      });
      $.when(that.casefiles.fetch(), that.disclosureMilestones.fetch()).done(function() {
        // map records to asset and la cases
        that.casefiles.forEach(function(casefile) {
          var caseId = casefile.get('id');
          var category = categories.get(casefile.get('category'));
          var categoryGroupId = category.get('category_group');
          var filteredBookingRecords = bookingRecords.where({
            case: caseId,
          });
          var filteredForecastingRecords = forecastingRecords.where({
            case: caseId,
          });
          var filteredPledgingRecords = pledgingRecords.where({
            case: caseId,
          });
          if ([2, 3].indexOf(categoryGroupId) === -1) {
            if (that.caseType === 'asset' || that.caseType == 0) {
              that.bookingAssetRecords.add(filteredBookingRecords);
              that.forecastingAssetRecords.add(filteredForecastingRecords);
              that.pledgingAssetRecords.add(filteredPledgingRecords);
            }
          } else {
            if (that.caseType === 'council' || that.caseType == 0) {
              that.bookingLARecords.add(filteredBookingRecords);
              that.forecastingLARecords.add(filteredForecastingRecords);
              that.pledgingLARecords.add(filteredPledgingRecords);
            }
          }
        });

        that.render();
        that.ui.reportingArea.removeClass('hidden');
        that.ui.submit.button('reset');
      });
    });
  },
  onRender: function() {
    // disable filtering users unless the session user has permission
    var selectOptions = { multiple: true };
    if (!TCAS.session.hasPerm('filter_pipeline_report_user')) {
      selectOptions.user = TCAS.session.get('id');
      selectOptions.disabled = true;
    } else if (this.userIds) {
      selectOptions.user = this.userIds;
    }
    if (!TCAS.session.hasPerm('filter_pipeline_report_team')) {
      var teamAllocations = this.getOption('teamAllocations');
      var teamAllocation = teamAllocations.find(function(teamAllocation) {
        return teamAllocation.get('user') === TCAS.session.get('id');
      });
      if (teamAllocation) {
        selectOptions.team = teamAllocation.get('team');
      }
    }
    this.caseManagerSelectView = new CaseManagerSelectView(selectOptions);
    // this.listenTo(this.caseManagerSelectView, 'selected', this.checkInputs);
    this.caseManagerRegion.show(this.caseManagerSelectView);

    selectOptions.user = null;
    if (this.partnerIds) {
      selectOptions.user = this.partnerIds;
    }
    this.partnerSelectView = new CaseManagerSelectView(selectOptions);
    this.partnerRegion.show(this.partnerSelectView);

    this.ui.category.select2();

    var teamOptions = {
      data: this.teams.map(function(team) {
        return {
          id: team.get('id'),
          text: team.get('name'),
        };
      }),
      multiple: true,
    };
    this.ui.team.val(this.selectedTeams).select2(teamOptions);

    // build up a collection of all booking records,
    // forecasting records and pledging records
    var Records = Backbone.Collection.extend({
      modelId: function(attrs) {
        if (attrs.hasOwnProperty('vat')) {
          return 'BookingRecord-' + attrs.id;
        } else if (attrs.hasOwnProperty('status')) {
          return 'ForecastingRecord-' + attrs.id;
        } else {
          return 'PledgingRecord-' + attrs.id;
        }
      },
    });
    var granularCollection = new Records();
    granularCollection.add(this.bookingAssetRecords.toArray());
    granularCollection.add(this.bookingLARecords.toArray());
    granularCollection.add(this.forecastingAssetRecords.toArray());
    granularCollection.add(this.forecastingLARecords.toArray());
    granularCollection.add(this.pledgingAssetRecords.toArray());
    granularCollection.add(this.pledgingLARecords.toArray());

    this.granularView = new PipelineGranularListView({
      collection: granularCollection,
      users: this.filteredUsers,
      casefiles: this.casefiles,
      disclosureMilestones: this.disclosureMilestones,
      teams: this.teams,
      teamAllocations: this.getOption('teamAllocations'),
      categories: this.getOption('categories'),
    });
    var granularView = this.granularView;
    this.granularRegion.show(this.granularView);

    this.ui.bookingDateFrom.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    });
    this.ui.bookingDateTo.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    });

    // setup the floating pipeline stats
    var $table = this.ui.pipelineReportTable;
    var $statsTable = this.ui.pipelineStatsTable;
    _.defer(function() {
      var $tds = $statsTable.find('td');
      for (var i = 0; i < $tds.length; i += 1) {
        var width = $table
          .find('th')
          .eq(i)
          .outerWidth();
        var totalWidth = $statsTable.outerWidth();
        $($tds[i]).css('width', (width / totalWidth) * 100 + '%');
      }
    });

    this.granularView.resizeTotals();
    this.ui.tabGranular.on('shown.bs.tab', function(e) {
      granularView.resizeTotals();
    });
  },
  onDestroy: function() {
    this.ui.tabGranular.off('shown.bs.tab');
  },
  serializeData: function() {
    var data = {
      team: this.team,
      teams: this.teams.toJSON(),
      teamNames: this.teamNames,
      user: this.user,
      fromDate: this.fromDate,
      toDate: this.toDate,
      friday: this.friday,
      includeInactiveUsers: this.includeInactiveUsers,
      categories: this.getOption('categories').toJSON(),
      category: this.category,
      caseType: this.caseType,
    };

    if (!TCAS.session.hasPerm('filter_pipeline_report_team')) {
      data.isTeamDisabled = true;
    }

    var userTargets = this.userTargets;
    var bookingAssetRecords = this.bookingAssetRecords;
    var bookingLARecords = this.bookingLARecords;
    var forecastingAssetRecords = this.forecastingAssetRecords;
    var forecastingLARecords = this.forecastingLARecords;
    var pledgingAssetRecords = this.pledgingAssetRecords;
    var pledgingLARecords = this.pledgingLARecords;
    var userData = this.filteredUsers.map(function(user) {
      var userId = user.get('id');
      var userTarget = userTargets.find(function(userTarget) {
        return userTarget.get('user') === userId;
      });
      var bookedAssetTotal = 0;
      var bookedLATotal = 0;
      var pendingAssetTotal = 0;
      var pendingLATotal = 0;
      var confirmedAssetTotal = 0;
      var confirmedLATotal = 0;
      var pledgedAssetTotal = 0;
      var pledgedLATotal = 0;
      var atRiskAssetTotal = 0;
      var atRiskLATotal = 0;

      function reducer(total, record) {
        if (record.get('case_manager') === userId) {
          return total + record.get('revenue');
        }
        return total;
      }

      bookingAssetRecords.forEach(function(record) {
        if (record.get('case_manager') === userId) {
          // treat booking records after last Friday like confirmed forecasting records
          if (moment(record.get('date')).isAfter(moment(data.friday))) {
            confirmedAssetTotal += record.get('revenue');
          } else {
            bookedAssetTotal += record.get('revenue');
          }
        }
      });
      bookingLARecords.forEach(function(record) {
        if (record.get('case_manager') === userId) {
          // treat booking records after last Friday like confirmed forecasting records
          if (moment(record.get('date')).isAfter(moment(data.friday))) {
            confirmedLATotal += record.get('revenue');
          } else {
            bookedLATotal += record.get('revenue');
          }
        }
      });

      forecastingAssetRecords.forEach(function(record) {
        if (record.get('case_manager') === userId) {
          if (record.get('is_at_risk')) {
            atRiskAssetTotal += record.get('revenue');
          } else if (record.get('status') === 'pending') {
            pendingAssetTotal += record.get('revenue');
          } else {
            confirmedAssetTotal += record.get('revenue');
          }
        }
      });
      forecastingLARecords.forEach(function(record) {
        if (record.get('case_manager') === userId) {
          if (record.get('is_at_risk')) {
            atRiskLATotal += record.get('revenue');
          } else if (record.get('status') === 'pending') {
            pendingLATotal += record.get('revenue');
          } else {
            confirmedLATotal += record.get('revenue');
          }
        }
      });

      pledgingAssetRecords.forEach(function(record) {
        if (record.get('case_manager') === userId) {
          if (record.get('is_at_risk')) {
            atRiskAssetTotal += record.get('revenue');
          } else {
            pledgedAssetTotal += record.get('revenue');
          }
        }
      });
      pledgingLARecords.forEach(function(record) {
        if (record.get('case_manager') === userId) {
          if (record.get('is_at_risk')) {
            atRiskLATotal += record.get('revenue');
          } else {
            pledgedLATotal += record.get('revenue');
          }
        }
      });

      var assetSubTotal =
        bookedAssetTotal +
        pendingAssetTotal +
        confirmedAssetTotal +
        pledgedAssetTotal +
        atRiskAssetTotal;
      var laSubTotal =
        bookedLATotal + pendingLATotal + confirmedLATotal + pledgedLATotal + atRiskLATotal;
      var total = assetSubTotal + laSubTotal;

      return {
        user: user.toJSON(),
        bookedAssetTotal: bookedAssetTotal,
        bookedLATotal: bookedLATotal,
        pendingAssetTotal: pendingAssetTotal,
        pendingLATotal: pendingLATotal,
        confirmedAssetTotal: confirmedAssetTotal,
        confirmedLATotal: confirmedLATotal,
        pledgedAssetTotal: pledgedAssetTotal,
        pledgedLATotal: pledgedLATotal,
        assetSubTotal: assetSubTotal,
        laSubTotal: laSubTotal,
        atRiskAssetTotal: atRiskAssetTotal,
        atRiskLATotal: atRiskLATotal,
        total: total,
        percentPerformanceTarget: userTarget ? total / userTarget.get('performance_revenue') : 0,
        percentStretchTarget: userTarget ? total / userTarget.get('stretch_revenue') : 0,
      };
    });
    data.userData = userData;

    // calculate grand totals
    var bookedTotal = 0;
    var pendingTotal = 0;
    var confirmedTotal = 0;
    var pledgedTotal = 0;
    var atRiskTotal = 0;
    var total = 0;

    userData.forEach(function(rowData) {
      bookedTotal += rowData.bookedAssetTotal + rowData.bookedLATotal;
      pendingTotal += rowData.pendingAssetTotal + rowData.pendingLATotal;
      confirmedTotal += rowData.confirmedAssetTotal + rowData.confirmedLATotal;
      pledgedTotal += rowData.pledgedAssetTotal + rowData.pledgedLATotal;
      atRiskTotal += rowData.atRiskAssetTotal + rowData.atRiskLATotal;
      total += rowData.total;
    });
    data.bookedTotal = bookedTotal;
    data.pendingTotal = pendingTotal;
    data.confirmedTotal = confirmedTotal;
    data.pledgedTotal = pledgedTotal;
    data.atRiskTotal = atRiskTotal;
    data.total = total;

    data.bookedConfirmedTotal = bookedTotal + confirmedTotal;
    data.bookedConfirmedPendingTotal = bookedTotal + confirmedTotal + pendingTotal;
    data.maximumPotential =
      bookedTotal + confirmedTotal + pendingTotal + pledgedTotal + atRiskTotal;
    data.maximumPotentialMinusAtRisk = bookedTotal + confirmedTotal + pendingTotal + pledgedTotal;

    var archiveDataToken = TCAS.request('archiveDataToken');
    data.isArchiveDataSession = !!archiveDataToken;

    if ($(window).width() < 768) {
      data.isMobile = true;
    }

    return data;
  },
});

module.exports = PipelineReportView;
