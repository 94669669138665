'use strict';

var Handlebars = require('hbsfy/runtime');
var template = require('../../templates/cases/file-select.hbs');
var Files = require('../../models/cases/files');
var File = require('../../models/cases/file');

var FileSelectView = Backbone.Marionette.ItemView.extend({
  className: 'file-select',
  template: template,
  ui: {
    select: '.file-select'
  },
  getSelectedFile: function() {
    var file = this.ui.select.val();
    if (this.getOption('multiple')) {
      if (!file) {
        return [];
      }
      return file.split(',');
    }
    return file;
  },
  onShow: function() {
    function formatFile(file, container, query){
      var s = Handlebars.Utils.escapeExpression(file.original_file_name); // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });

      return s;
    }

    var that = this;

    var filesUrl = '/api/cases/'+this.getOption('casefile').get('id')+'/files/';

    this.ui.select.select2({
      placeholder: 'File name',
      minimumInputLength: 0,
      multiple: this.getOption('multiple'),
      ajax: {
        url: filesUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatFile,
      formatSelection: formatFile,
      allowClear: true,
      initSelection: function(element, callback) {
        if(that.getOption('file')) {
          var file = that.getOption('file');
          if (Array.isArray(file)) {
            var files = new Files();
            files.setFilters({
              'id__in': file.join(',')
            });
            files.fetch().then(function(){
              callback(files.toJSON());
            });
          } else {
            var file = new File({
              id: that.getOption('file')
            });
            file.fetch().then(function () {
              callback(user.toJSON());
            });
          }
        }
      }
    });
    this.ui.select.on('change', function(e) {
      that.trigger('selected', e.val);
    });
  }
});

module.exports = FileSelectView;