// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "      <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,">=",(depths[1] != null ? depths[1].targetResearchPerMonth : depths[1]),{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "class=\"bg-success\"";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].targetResearchYTD : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data,"blockParams":blockParams}),{"name":"with","hash":{},"fn":container.program(5, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "          <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[1][0],">=",blockParams[0][0],{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(blockParams[1][0], depth0))
    + "</td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"table table-condensed table-responsive\">\n  <thead>\n  <tr>\n    <th></th>\n    <th>Jan</th>\n    <th>Feb</th>\n    <th>Mar</th>\n    <th>Apr</th>\n    <th>May</th>\n    <th>Jun</th>\n    <th>Jul</th>\n    <th>Aug</th>\n    <th>Sep</th>\n    <th>Oct</th>\n    <th>Nov</th>\n    <th>Dec</th>\n  </tr>\n  </thead>\n  <tbody>\n  <tr>\n    <td>Actual</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.researchByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td>Actual YTD</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.researchYTD : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "  </tr>\n  </tbody>\n</table>";
},"useData":true,"useDepths":true,"useBlockParams":true});
