// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "      <th>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <th>% "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isHoliday : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n      <td>"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</td>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.activities : stack1),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "      <td>"
    + alias4(((helper = (helper = helpers.totalMinutes || (depth0 != null ? depth0.totalMinutes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalMinutes","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "</td>\n      <td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isWorkingDay : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "</td>\n      <td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isWorkingDay : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "</td>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = blockParams[0][0]) != null ? stack1.activities : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "class=\"holiday\"";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isWeekend : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "class=\"weekend\"";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <td>"
    + container.escapeExpression(((helper = (helper = helpers.minutes || (depth0 != null ? depth0.minutes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"minutes","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.targetMinutes || (depth0 != null ? depth0.targetMinutes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"targetMinutes","hash":{},"data":data}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatPercent || (depth0 && depth0.formatPercent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.percentageOfTarget : depth0),{"name":"formatPercent","hash":{},"data":data}));
},"17":function(container,depth0,helpers,partials,data) {
    return "        <td>"
    + container.escapeExpression((helpers.formatPercent || (depth0 && depth0.formatPercent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.percentage : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "        <td>\n          "
    + container.escapeExpression((helpers.formatPercent || (depth0 && depth0.formatPercent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"formatPercent","hash":{},"data":data}))
    + "\n        </td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"action-buttons\">\n  <button type=\"button\" class=\"btn btn-default btn-sm export-csv\" data-loading-text=\"Exporting...\">Export CSV</button>\n</div>\n<table class=\"table table-hover table-condensed\">\n  <thead>\n  <tr>\n    <th>Date</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.activityColumns : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    <th>Totals</th>\n    <th>Target</th>\n    <th>% of Target logged</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.activityColumns : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "  </tr>\n  </thead>\n  <tbody class=\"table-items\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.collection : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "  </tbody>\n  <tbody class=\"average-percentage\">\n    <tr><td colspan=\""
    + alias3(((helper = (helper = helpers.numColumns || (depth0 != null ? depth0.numColumns : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"numColumns","hash":{},"data":data,"blockParams":blockParams}) : helper)))
    + "\"></td></tr>\n    <tr>\n      <td colspan=\""
    + alias3((helpers.add || (depth0 && depth0.add) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.activityTypes : depth0)) != null ? stack1.length : stack1),3,{"name":"add","hash":{},"data":data,"blockParams":blockParams}))
    + "\" align=\"right\">Average of target submission</td>\n      <td>"
    + alias3((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.averagePercentageOfTarget : depth0),{"name":"formatPercent","hash":{},"data":data,"blockParams":blockParams}))
    + "</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.averagePercentage : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <td colspan=\""
    + alias3((helpers.add || (depth0 && depth0.add) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.activityTypes : depth0)) != null ? stack1.length : stack1),4,{"name":"add","hash":{},"data":data,"blockParams":blockParams}))
    + "\" align=\"right\">Average of actual submissions</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.averageActualPercentage : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n  </tbody>\n</table>";
},"useData":true,"useBlockParams":true});
