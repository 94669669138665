'use strict';
var BaseCollection = require('../base-collection');
var Cost = require('./cost');

var Costs = BaseCollection.extend({
  model: Cost,
  comparator: function(cost){
    return new Date(cost.get('date')).getTime() * -1;
  }
});

module.exports = Costs;