'use strict';
var EmailMessage = require('./emailmessage');
var BaseCollection = require('../base-collection');

var EmailMessages = BaseCollection.extend({
  urlRoot: '/api/emailmessages/',
  model: EmailMessage
});

module.exports = EmailMessages;
