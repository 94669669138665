// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "    <thead>\n    <tr>\n      <th colspan=\""
    + alias3((helpers.add || (depth0 && depth0.add) || alias2).call(alias1,(depths[1] != null ? depths[1].maxNumTasks : depths[1]),1,{"name":"add","hash":{},"data":data}))
    + "\" class=\"activity-name\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</th>\n    </tr>\n    <tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tasks : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <th>Totals</th>\n    </tr>\n    </thead>\n    <tbody>\n    <tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tasks : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <td>"
    + alias3(((helper = (helper = helpers.totalMinutes || (depth0 != null ? depth0.totalMinutes : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalMinutes","hash":{},"data":data}) : helper)))
    + "</td>\n    </tr>\n    <tr class=\"task-percentage\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tasks : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <td></td>\n    </tr>\n    </tbody>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <th "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</th>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? depths[1].tasks : depths[1])) != null ? stack1.length : stack1),"<",(depths[2] != null ? depths[2].maxNumTasks : depths[2]),{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return "colspan=\""
    + container.escapeExpression((helpers.minus || (depth0 && depth0.minus) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].maxNumTasks : depths[2]),(data && data.index),{"name":"minus","hash":{},"data":data}))
    + "\"";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <td "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(((helper = (helper = helpers.minutes || (depth0 != null ? depth0.minutes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"minutes","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <td "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((helpers.percentage || (depth0 && depth0.percentage) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.minutes : depth0),(depths[1] != null ? depths[1].totalMinutes : depths[1]),{"name":"percentage","hash":{},"data":data}))
    + "</td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<table class=\"table table-hover table-condensed\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.collection : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  </thead>\n</table>";
},"useData":true,"useDepths":true});
