// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].year : depths[1]),"==",depth0,{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<form class=\"form-horizontal company-target-form\">\n  <div class=\"panel-body\">\n    <h4>Revenue Targets</h4>\n    <div id=\"revenue-targets\">\n      <div class=\"form-group\">\n        <label for=\"year\" class=\"col-sm-2 control-label\">Year</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <select name=\"year\" class=\"form-control year\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.years : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"performance-revenue\" class=\"col-sm-2 control-label\">Performance Target / year</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <span class=\"input-group-addon\" id=\"basic-addon2\">£</span>\n              <input type=\"number\" id=\"performance-revenue\" name=\"performance_revenue\" step=\"1\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.performance_revenue || (depth0 != null ? depth0.performance_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"performance_revenue","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Performance...\" aria-describedby=\"basic-addon2\" />\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"stretch-revenue\" class=\"col-sm-2 control-label\">Stretch Target / year</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <span class=\"input-group-addon\" id=\"basic-addon3\">£</span>\n              <input type=\"number\" id=\"stretch-revenue\" name=\"stretch_revenue\" step=\"1\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.stretch_revenue || (depth0 != null ? depth0.stretch_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stretch_revenue","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Stretch...\" aria-describedby=\"basic-addon3\" />\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"average-asset-case-size\" class=\"col-sm-2 control-label\">Average asset case size</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <span class=\"input-group-addon\" id=\"basic-addon4\">£</span>\n              <input type=\"number\" id=\"average-asset-case-size\" name=\"average_asset_case_size\" step=\"0.01\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.average_asset_case_size || (depth0 != null ? depth0.average_asset_case_size : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"average_asset_case_size","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Case size...\" aria-describedby=\"basic-addon4\" />\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"average-la-case-size\" class=\"col-sm-2 control-label\">Average council case size</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <span class=\"input-group-addon\" id=\"basic-addon5\">£</span>\n              <input type=\"number\" id=\"average-la-case-size\" name=\"average_la_case_size\" step=\"0.01\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.average_la_case_size || (depth0 != null ? depth0.average_la_case_size : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"average_la_case_size","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Case size...\" aria-describedby=\"basic-addon5\" />\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"panel-body\">\n    <h4>Benchmark Targets</h4>\n    <div id=\"benchmark-targets\">\n      <div class=\"form-group\">\n        <label for=\"booked-asset-volume\" class=\"col-sm-2 control-label\">Booked asset volume / month</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-100\">\n            <input type=\"number\" id=\"booked-asset-volume\" name=\"booked_asset_volume\" step=\"1\" class=\"form-control\"\n                   value=\""
    + alias4(((helper = (helper = helpers.booked_asset_volume || (depth0 != null ? depth0.booked_asset_volume : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"booked_asset_volume","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Volume...\" />\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"booked-la-volume\" class=\"col-sm-2 control-label\">Booked council volume / month</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-100\">\n            <input type=\"number\" id=\"booked-la-volume\" name=\"booked_la_volume\" step=\"1\" class=\"form-control\"\n                   value=\""
    + alias4(((helper = (helper = helpers.booked_la_volume || (depth0 != null ? depth0.booked_la_volume : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"booked_la_volume","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Volume...\" />\n          </div>\n          <input type=\"hidden\" name=\"user\" value=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" />\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <div class=\"alerts-area\"></div>\n        <div class=\"col-sm-2 col-sm-offset-2\">\n          <button class=\"btn btn-success save-targets\" type=\"submit\">Save</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</form>";
},"useData":true,"useDepths":true});
