// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"form-group\">\n        <div class=\"alert alert-danger\">\n          This user account has been locked due to too many failed login attempts. To unlock the account, please <a\n            href=\"#\" class=\"alert-link unlock-account\">Click Here</a>\n        </div>\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data}) : helper)))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    return "";
},"13":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"form-group\">\n        <label for=\"password1\" class=\"col-sm-2 control-label\">Password</label>\n        <div class=\"col-sm-6\">\n          <input type=\"password\" class=\"form-control\" id=\"password1\"\n                 name=\"password\" placeholder=\"Enter a password\">\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"password2\" class=\"col-sm-2 control-label\">Confirm Password</label>\n        <div class=\"col-sm-6\">\n          <input type=\"password\" class=\"form-control\" id=\"password2\"\n                 name=\"password2\" placeholder=\"Confirm the password\">\n        </div>\n      </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)))
    + "\"";
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)));
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.title : stack1), depth0))
    + "\"";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.first_name : stack1), depth0))
    + "\"";
},"23":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.last_name || (depth0 != null ? depth0.last_name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"last_name","hash":{},"data":data}) : helper)))
    + "\"";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.organisation : stack1), depth0))
    + "\"";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.phone : stack1), depth0))
    + "\"";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.extension : stack1), depth0))
    + "\"";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.address1 : stack1), depth0))
    + "\"";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.address2 : stack1), depth0))
    + "\"";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.city : stack1), depth0))
    + "\"";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.post_code : stack1), depth0))
    + "\"";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.county : stack1), depth0))
    + "\"";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.country : stack1), depth0))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form class=\"form-horizontal user-create-details-form\">\n  <div class=\"panel-body\">\n    <div class=\"alerts-area\"></div>\n    <div class=\"clearfix\"></div>\n    <h4>Log in credentials</h4>\n    <div class=\"row\">\n      <div class=\"col-md-offset-2 col-sm-4\">\n        <div class=\"checkbox-group checkbox\">\n          <label>\n            <input type=\"checkbox\" name=\"admin\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.admin : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> <span class=\"badge admin-badge\">Admin</span>\n          </label>\n          <label>\n            <input type=\"checkbox\" name=\"inactive\" class=\"inactive-checkbox\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_active : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <span class=\"badge\">Inactive</span>\n          </label>\n        </div>\n      </div>\n      <div class=\"col-sm-2\">\n        <div class=\"pull-right\">\n          <button type=\"button\" class=\"btn btn-sm btn-danger reset-2fa\">Reset 2FA</button>\n        </div>\n      </div>\n    </div>\n    <div class=\"clearfix\"></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_locked : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"form-group\">\n      <label for=\"group\" class=\"col-sm-2 control-label\">Group</label>\n      <div class=\"col-sm-6\">\n        <select class=\"form-control\" id=\"group\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.roles : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"username\" class=\"col-sm-2 control-label\">Username</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"username\"\n               name=\"username\" placeholder=\"Username\"\n               "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n               data-cur-username=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNewUser : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"form-group\">\n      <label for=\"email\" class=\"col-sm-2 control-label\">Email</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"email\"\n               name=\"email\" placeholder=\"Email Address\"\n               "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n               data-cur-email=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      </div>\n    </div>\n  </div>\n  <div class=\"panel-body\">\n    <h4>Contact</h4>\n    <div class=\"form-group\">\n      <label for=\"title\" class=\"col-sm-2 control-label\">Title</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"title\"\n               name=\"title\" placeholder=\"Mr./Mrs./Ms./Dr.\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"first_name\" class=\"col-sm-2 control-label\">First Name</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"first_name\"\n               name=\"first_name\" placeholder=\"First Name\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.first_name : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"last_name\" class=\"col-sm-2 control-label\">Last Name</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"last_name\"\n               name=\"last_name\" placeholder=\"Last Name\"\n               "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.last_name : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"organisation\" class=\"col-sm-2 control-label\">Organisation</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"organisation\"\n               name=\"organisation\" placeholder=\"Organisation Name\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.organisation : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"phone\" class=\"col-sm-2 control-label\">Phone</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"phone\"\n               name=\"phone\" placeholder=\"Contact Number\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.phone : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"extension\" class=\"col-sm-2 control-label\">Extension</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"extension\"\n               name=\"extension\" placeholder=\"Extension\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.extension : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"address_line_1\" class=\"col-sm-2 control-label\">Address</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"address1\"\n               name=\"address1\" placeholder=\"Address Line 1\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.address1 : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"address_line_2\" class=\"col-sm-2 control-label\">Address</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"address2\"\n               name=\"address2\" placeholder=\"Address Line 2\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.address2 : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"city\" class=\"col-sm-2 control-label\">City/Town</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"city\"\n               name=\"city\" placeholder=\"City/Town\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.city : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"post_code\" class=\"col-sm-2 control-label\">Post/Zip Code</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"post_code\"\n               name=\"post_code\" placeholder=\"Post Code\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.post_code : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"county\" class=\"col-sm-2 control-label\">County/State</label>\n      <div class=\"col-sm-6\">\n        <input type=\"text\" class=\"form-control\" id=\"county\"\n               name=\"county\" placeholder=\"County\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.county : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label for=\"country\" class=\"col-sm-2 control-label\">Country</label>\n      <div class=\"col-sm-6\" id=\"country-input-container\">\n        <input type=\"text\" class=\"form-control\" id=\"country\"\n               name=\"country\" placeholder=\"Country\"\n               "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.country : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      </div>\n    </div>\n  </div>\n  <div class=\"panel-body\">\n    <h4>Email signature</h4>\n    <div class=\"form-group\">\n      <label for=\"signature\" class=\"col-sm-2 control-label\">Signature</label>\n      <div class=\"col-sm-6\">\n        <textarea id=\"signature\" class=\"form-control signature\" name=\"signature\" placeholder=\"Signature\"></textarea>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <div class=\"col-sm-2 col-sm-offset-2\">\n        <button class=\"btn btn-success\" id=\"submit\">Save</button>\n      </div>\n    </div>\n  </div>\n</form>";
},"useData":true});
