'use strict';

var moment = require('moment');

function padZero(number) {
  var s = number + '';
  while (s.length < 2) {
    s = "0" + s;
  }

  return s;
}

var Util = {
  escapeClass: function(word) {
    if (word) {
      return word.replace(/[^\w\d-]/g, '-');
    } else {
      return word;
    }
  },

  expectArgs: function(expected, given) {
    expected = _.difference(expected, _.keys(given));
    if (expected.length) {
      throw 'Some required attributes have not been supplied: ' +
        expected.join(', ');
    }
    var illDefined = _.chain(given)
      .pairs()
      .where(function(kv) { return kv[1] === undefined; })
      .map(function(kv) { return kv[0]; })
    .value();
    if (illDefined.length) {
      throw 'Some arguments were passed as null: ' + illDefined.join(', ');
    }
  },

  formatCurrency: function(number, simplify) {
    var formattedValue = number.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP'
    });
    var formattedDisplayValue = '£' + Math.round(number).toLocaleString('en-GB')

    var display = formattedValue;

    if (simplify) {
      display = '<span class="simple-currency" title="' + formattedValue + '">' +
        formattedDisplayValue + '</span>';
    }

    return display;
  },

  formatMinutesAsTime: function(minutes) {
    var duration = moment.duration(minutes, 'minutes');
    return Math.floor(duration.asHours()) + ':' + padZero(duration.minutes());
  },

  formatHoursAsTime: function(hours) {
    var duration = moment.duration(hours, 'hours');
    return Math.floor(duration.asHours()) + ':' + padZero(duration.minutes());
  }
};

module.exports = Util;
