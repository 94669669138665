'use strict';
var template = require('../templates/login-banner.hbs');

var LoginBannerView = Backbone.View.extend({
  template: template,
  render: function() {
    this.$el.html(this.template());
    return this;
  }
});

module.exports = LoginBannerView;
