'use strict';

var template = require('../../../templates/reporting/individual-yearly-report.hbs');
var moment = require('moment');
var CaseFiles = require('../../../models/cases/casefiles');
var Milestones = require('../../../models/cases/milestones');
var BookingRecords = require('../../../models/reporting/bookingrecords');
var UserTargets = require('../../../models/reporting/usertargets');
var CaseManagerSelectView = require('../../allocations/casemanager-select');
var IndividualYearlyRevenueTableView = require('./individual-yearly-revenue-table');
var IndividualYearlyResearchTableView = require('./individual-yearly-research-table');
var IndividualYearlyInClaimsTableView = require('./individual-yearly-in-claims-table');
var IndividualYearlyDisclosureTableView = require('./individual-yearly-disclosure-table');
var IndividualYearlyBookedTableView = require('./individual-yearly-booked-table');
var IndividualYearlyConcludedTableView = require('./individual-yearly-concluded-table');

var IndividualYearlyReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'individual-yearly-report',
  ui: {
    year: '.year',
    submit: '.submit-btn',
    alertArea: '#alert-area',
    user: '#user',
    userFullName: '.user-full-name',
    reportingArea: '.reporting-area',
  },
  regions: {
    caseManagerRegion: '.casemanager-select-container',
    revenueRegion: '.revenue-table-container',
    researchRegion: '.research-table-container',
    inClaimsRegion: '.in-claims-table-container',
    disclosureRegion: '.disclosure-table-container',
    bookedRegion: '.booked-table-container',
    concludedRegion: '.concluded-table-container',
  },
  events: {
    'change @ui.year': 'checkInputs',
  },
  behaviors: {
    SubmitForm: {
      submitHandler: 'generateReport',
    },
    Validate: {
      validateOptions: {
        rules: {
          year: {
            required: true,
            number: true,
          },
          case_manager: {
            // jshint ignore:line
            required: true,
          },
        },
      },
    },
  },
  initialize: function() {
    this.listenTo(TCAS.archiveDataTokens, 'sync', this.render);
  },
  checkInputs: function() {
    this.ui.submit.prop(
      'disabled',
      this.ui.year.val() === '' || this.caseManagerSelectView.getSelectedCaseManager() === ''
    );
  },
  generateReport: function() {
    var that = this;
    var year = parseInt(this.ui.year.val(), 10);
    var user = this.caseManagerSelectView.getSelectedCaseManager();
    var userFullName = this.caseManagerSelectView.getSelectedCaseManagerName();
    this.ui.userFullName.html(userFullName);

    // clear regions so old data is not displayed
    this.revenueRegion.empty();
    this.researchRegion.empty();
    this.inClaimsRegion.empty();
    this.disclosureRegion.empty();
    this.bookedRegion.empty();
    this.concludedRegion.empty();

    // 1st january this year to next year
    var startDate = new Date(year, 0, 1);
    var endDate = moment(new Date(year, 0, 1))
      .endOf('year')
      .toDate();

    // fetch user targets, data is used in multiple tables
    var userTargets = new UserTargets();
    userTargets.setFilters({
      user: user,
      page_size: 1000,
      year: year,
    });

    userTargets.fetch().then(function() {
      var userTarget = userTargets.at(0);

      // fetch data for this time period
      that.refreshRevenue(startDate, endDate, user, userTarget);
      that.refreshResearch(startDate, endDate, user, userTarget);
      that.refreshInClaims(startDate, endDate, user, userTarget);
      that.refreshDisclosure(startDate, endDate, user);
      that.refreshBooked(startDate, endDate, user, userTarget);
      that.refreshConcluded(startDate, endDate, user);

      that.ui.reportingArea.removeClass('hidden');
    });
  },
  refreshRevenue: function(startDate, endDate, user, userTarget) {
    var that = this;
    var categories = this.getOption('categories');

    // fetch all booked entries from case financials for this user
    var bookingRecords = new BookingRecords();
    var bookingLARecords = new BookingRecords();
    var bookingAssetRecords = new BookingRecords();
    var bookingCases = new CaseFiles();
    bookingRecords.setFilters({
      date_after: moment(startDate).format('DD/MM/YYYY'),
      date_before: moment(endDate).format('DD/MM/YYYY'),
      case_manager: user,
      is_active: true,
      page_size: 10000,
    });
    bookingRecords.fetch().then(function() {
      // now fetch the corresponding casefiles for these records
      var caseIds = bookingRecords.map(function(bookingRecord) {
        return bookingRecord.get('case');
      });
      bookingCases.setFilters({
        id__in: caseIds.length ? caseIds.join(',') : 0,
        is_active: true,
        page_size: 10000,
      });
      bookingCases.fetch({
        success: function() {
          // separate cases and booking records into LA and asset
          bookingCases.each(function(bookedCase) {
            var category = categories.get(bookedCase.get('category'));
            var categoryGroupId = category.get('category_group');
            var filteredBookingRecords = bookingRecords.where({
              case: bookedCase.get('id'),
            });
            if ([2, 3].indexOf(categoryGroupId) === -1) {
              bookingAssetRecords.add(filteredBookingRecords);
            } else {
              bookingLARecords.add(filteredBookingRecords);
            }
          });

          that.revenueTable = new IndividualYearlyRevenueTableView({
            bookingAssetRecords: bookingAssetRecords,
            bookingLARecords: bookingLARecords,
            userTarget: userTarget,
          });
          that.revenueRegion.show(that.revenueTable);
        },
      });
    });
  },
  refreshResearch: function(startDate, endDate, user, userTarget) {
    var that = this;

    // fetch all research signed off (pk 6) milestones for this user
    var researchMilestones = new Milestones();
    researchMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 6,
      is_active: true,
      page_size: 10000,
    });
    researchMilestones.fetch({
      success: function() {
        that.researchTable = new IndividualYearlyResearchTableView({
          researchMilestones: researchMilestones,
          userTarget: userTarget,
        });
        that.researchRegion.show(that.researchTable);
      },
    });
  },
  refreshInClaims: function(startDate, endDate, user, userTarget) {
    var that = this;
    var categories = this.getOption('categories');

    // fetch all research signed off (pk 6) milestones for this user
    var inClaimsMilestones = new Milestones();
    inClaimsMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 13,
      is_active: true,
      page_size: 10000,
    });
    inClaimsMilestones.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these milestones
        // as well as all other milestones for the case
        var caseIds = inClaimsMilestones.map(function(milestone) {
          return milestone.get('case');
        });
        var researchMilestonesForInClaims = new Milestones();
        researchMilestonesForInClaims.setFilters({
          case__in: caseIds.length ? caseIds.join(',') : 0,
          milestone_type: 6,
          is_active: true,
          page_size: 10000,
        });

        var researchMilestonesThisYear = new Milestones();
        researchMilestonesThisYear.setFilters({
          created_after: moment(startDate).format('DD/MM/YYYY'),
          created_before: moment(endDate).format('DD/MM/YYYY'),
          user: user,
          milestone_type: 6,
          is_active: true,
          page_size: 10000,
        });

        $.when(researchMilestonesForInClaims.fetch(), researchMilestonesThisYear.fetch()).done(
          function() {
            var caseIdsForResearch = researchMilestonesThisYear.map(function(milestone) {
              return milestone.get('case');
            });
            var casefiles = new CaseFiles();
            var ids = caseIds.concat(caseIdsForResearch);
            casefiles.setFilters({
              id__in: ids.length ? ids.join(',') : 0,
              is_active: true,
              page_size: 10000,
            });
            casefiles.fetch().then(function() {
              that.inClaimsTable = new IndividualYearlyInClaimsTableView({
                inClaimsMilestones: inClaimsMilestones,
                researchMilestonesForInClaims: researchMilestonesForInClaims,
                researchMilestonesThisYear: researchMilestonesThisYear,
                casefiles: casefiles,
                categories: categories,
                userTarget: userTarget,
                year: startDate.getFullYear(),
              });
              that.inClaimsRegion.show(that.inClaimsTable);
            });
          }
        );
      },
    });
  },
  refreshDisclosure: function(startDate, endDate, user) {
    var that = this;

    // fetch all disclosure sent (pk 26) milestones for this user
    var disclosureMilestones = new Milestones();
    disclosureMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 26,
      is_active: true,
      page_size: 10000,
    });
    disclosureMilestones.fetch({
      success: function() {
        that.disclosureTable = new IndividualYearlyDisclosureTableView({
          disclosureMilestones: disclosureMilestones,
        });
        that.disclosureRegion.show(that.disclosureTable);
      },
    });
  },
  refreshBooked: function(startDate, endDate, user, userTarget) {
    // fetch all the booking records
    var that = this;
    var categories = this.getOption('categories');

    // fetch all booked entries from case financials for this user
    var bookingRecords = new BookingRecords();
    var bookingLARecords = new BookingRecords();
    var bookingAssetRecords = new BookingRecords();
    var bookingCases = new CaseFiles();
    bookingRecords.setFilters({
      date_after: moment(startDate).format('DD/MM/YYYY'),
      date_before: moment(endDate).format('DD/MM/YYYY'),
      case_manager: user,
      is_active: true,
      page_size: 10000,
    });
    bookingRecords.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these records
        var caseIds = bookingRecords.map(function(bookingRecord) {
          return bookingRecord.get('case');
        });
        bookingCases.setFilters({
          id__in: caseIds.length ? caseIds.join(',') : 0,
          is_active: true,
          page_size: 10000,
        });
        bookingCases.fetch({
          success: function() {
            // separate cases and booking records into LA and asset collections
            bookingCases.each(function(bookedCase) {
              var category = categories.get(bookedCase.get('category'));
              var categoryGroupId = category.get('category_group');
              var filteredBookingRecords = bookingRecords.where({
                case: bookedCase.get('id'),
              });
              if ([2, 3].indexOf(categoryGroupId) === -1) {
                bookingAssetRecords.add(filteredBookingRecords);
              } else {
                bookingLARecords.add(filteredBookingRecords);
              }
            });

            that.bookedTable = new IndividualYearlyBookedTableView({
              bookingAssetRecords: bookingAssetRecords,
              bookingLARecords: bookingLARecords,
              userTarget: userTarget,
            });
            that.bookedRegion.show(that.bookedTable);
          },
        });
      },
    });
  },
  refreshConcluded: function(startDate, endDate, user) {
    var that = this;

    // fetch all concluded (pk 35) milestones for this user
    var concludedMilestones = new Milestones();
    concludedMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 35,
      is_active: true,
      page_size: 10000,
    });
    concludedMilestones.fetch({
      success: function() {
        that.concludedTable = new IndividualYearlyConcludedTableView({
          concludedMilestones: concludedMilestones,
        });
        that.concludedRegion.show(that.concludedTable);
      },
    });
  },
  onRender: function() {
    // disable filtering users unless the session user has permission
    var selectOptions = {};
    if (!TCAS.session.hasPerm('filter_individual_report_user')) {
      selectOptions.user = TCAS.session.get('id');
      selectOptions.disabled = true;
    }
    if (!TCAS.session.hasPerm('filter_pipeline_report_team')) {
      selectOptions.team = TCAS.session.get('userdetail').team;
    }
    this.caseManagerSelectView = new CaseManagerSelectView(selectOptions);
    this.listenTo(this.caseManagerSelectView, 'selected', this.checkInputs);
    this.caseManagerRegion.show(this.caseManagerSelectView);

    // set the date filters to this year
    var today = new Date();
    this.ui.year.val(today.getFullYear());

    this.checkInputs();
  },
  serializeData: function() {
    var archiveDataToken = TCAS.request('archiveDataToken');
    var data = { isArchiveDataSession: !!archiveDataToken };
    return data;
  },
});

module.exports = IndividualYearlyReportView;
