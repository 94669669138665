'use strict';

var moment = require('moment');
var template = require('../../../templates/reporting/timetrack-daily-breakdown.hbs');
var TimeTrack = require('../../../models/timetrack/timetrack');

var TimetrackDailyBreakdownTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    exportBtn: '.export-csv',
    table: 'table'
  },
  events: {
    'click @ui.exportBtn': 'onClickExport'
  },
  onClickExport: function() {
    // check for valid sensitive data token,
    // only allow CSV export if in sensitive data session
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doExport(sdToken);
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }
  },
  doExport: function(sdToken) {
    var $button = this.ui.exportBtn;
    $button.button('loading');

    var results = [];
    var row = []
    this.ui.table.find('th').each(function() {
      row.push('"'+$(this).text()+'"');
    });
    results.push(row.join(','));

    this.ui.table.find('.table-items tr').each(function() {
      row = [];
      $(this).find('td').each(function(index) {
        var $td = $(this);
        var value = $td.text();
        row.push(value);
      });
      results.push(row.join(','));
    });

    var csvData = {
      'content': results.join('\n'),
      'filename': 'timetrack_breakdown'
    };

    $.ajax({
      url: '/api/tocsv',
      data: csvData,
      type: 'post',
      success: function(data) {
        $button.button('reset');
        window.location = data;
      },
      error: function(e) {
        console.log(e);
      }
    });
  },
  serializeData: function() {
    var fromDateMoment = this.getOption('fromDateMoment');
    var toDateMoment = this.getOption('toDateMoment');
    var bankHolidayEvents = this.getOption('bankHolidayEvents');
    var absenceEvents = this.getOption('absenceEvents');
    var datesMap = {};
    var collectionData = [];
    var owners = [];
    var activityTypes = [];
    // activity order indicates the preferred order to list the activities
    var activityOrder = [
      TimeTrack.ACTIVITY_ADMINISTRATION,
      TimeTrack.ACTIVITY_CASE_WORK,
      TimeTrack.ACTIVITY_MANAGEMENT,
      TimeTrack.ACTIVITY_GENERAL,
      TimeTrack.ACTIVITY_LEGAL,
      TimeTrack.ACTIVITY_HR,
      TimeTrack.ACTIVITY_OFFSITE,
      TimeTrack.ACTIVITY_BUSINESS_DEVELOPMENT,
      TimeTrack.ACTIVITY_OPERATIONS
    ];
    var activityTotals = {};
    var totalMinutes = 0;

    // aggregate data by day
    this.collection.forEach(function(entry) {
      var start = moment(entry.get('start'));
      var date = start.format('DD/MM/YYYY');
      if (!datesMap[date]) {
        datesMap[date] = [];
      }
      datesMap[date].push(entry);
      if (owners.indexOf(entry.get('owner')) === -1) {
        owners.push(entry.get('owner'));
      }
    });

    var day = moment(fromDateMoment);
    while(!day.isAfter(toDateMoment)) {
      var targetMinutes = 450 * owners.length;
      var maxTargetMinutes = targetMinutes;
      var dateString = day.format('DD/MM/YYYY');
      var isWeekend = day.day() === 0 || day.day() === 6;
      var isHoliday = false;
      var startOfDay = moment(day).startOf('day');
      var endOfDay = moment(day).endOf('day');

      if (isWeekend) {
        targetMinutes = 0;
      } else {
        // check bank holidays, bank holidays apply for everyone
        bankHolidayEvents.each(function(calendarEvent) {
          var start = moment(calendarEvent.get('start'));
          var end = moment(calendarEvent.get('end'));
          if (day.isBetween(start, end, 'day')) {
            isHoliday = true;
            if (calendarEvent.get('is_all_day')) {
              targetMinutes = 0;
            } else {
              if (start.isSame(day, 'day')) {
                // event starts this day, take off remaining minutes to end of day
                var diff = endOfDay.diff(start, 'minutes');
                targetMinutes -= (diff * owners.length);
              } else if (end.isSame(day, 'day')) {
                // event ends this day, take off minutes from start of day
                diff = end.diff(startOfDay, 'minutes');
                targetMinutes -= (diff * owners.length);
              } else {
                // event doesn't start or end today, so whole day must be off
                targetMinutes = 0;
              }
            }
          }
        });

        if (targetMinutes > 0) {
          // for each owner find the relevant absences for them
          owners.forEach(function(ownerId) {
            // need to find the smallest and largest time overlap
            // of events within the day
            var earliest = null;
            var latest = null;
            absenceEvents.each(function (calendarEvent) {
              if (calendarEvent.get('users').indexOf(ownerId) === -1) {
                return;
              }

              var start = moment(calendarEvent.get('start'));
              var end = moment(calendarEvent.get('end'));
              // is between check is failing
              if (day.isBetween(start, end, 'day', '[]')) {
                if (calendarEvent.get('is_all_day')) {
                  earliest = startOfDay;
                  latest = endOfDay;
                } else {
                  if (start.isSame(day, 'day')) {
                    if (!earliest || start.isBefore(earliest)) {
                      earliest = start;
                    }
                  } else {
                    earliest = startOfDay;
                  }
                  if (end.isSame(day, 'day')) {
                    if (!latest || end.isAfter(latest)) {
                      latest = end;
                    }
                  } else {
                    latest = endOfDay;
                  }
                }
              }
            });

            if (earliest && latest) {
              var diff = latest.diff(earliest, 'minutes');
              // clamp diff to max of 450 mins
              if (diff > 450) {
                diff = 450;
              }
              targetMinutes -= diff;
            }
          });
        }
      }

      var data = {
        date: dateString,
        totalMinutes: 0,
        targetMinutes: targetMinutes,
        percentageOfTarget: 0,
        activitiesMap: {},
        isWeekend: isWeekend,
        isHoliday: isHoliday || (!isWeekend && targetMinutes < maxTargetMinutes),
        isWorkingDay: !isWeekend && !isHoliday
      };
      if (datesMap[dateString]) {
        datesMap[dateString].forEach(function(entry) {
          var activity = entry.get('activity');
          if (activityTypes.indexOf(activity) === -1) {
            activityTypes.push(activity);
            activityTotals[activity] = 0;
          }

          if (!data.activitiesMap.hasOwnProperty(activity)) {
            data.activitiesMap[activity] = { minutes: 0, percentage: 0 };
          }
          var minutes = entry.totalMinutes();
          data.activitiesMap[activity].minutes += minutes;
          data.totalMinutes += minutes;
          activityTotals[activity] += minutes;
          totalMinutes += minutes;
        });
        data.percentageOfTarget = data.totalMinutes / targetMinutes;
        for (var key in data.activitiesMap) {
          if (data.activitiesMap.hasOwnProperty(key)) {
            data.activitiesMap[key].percentage = data.activitiesMap[key].minutes / data.totalMinutes;
          }
        }
      }

      collectionData.push(data);
      day.add(1, 'day');
    }

    // order activityTypes based on activityOrder
    activityTypes.sort(function(a, b){
      var indexA = activityOrder.indexOf(a);
      var indexB = activityOrder.indexOf(b);
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      } else {
        return 0;
      }
    });

    //re-map activitiesMap into activites array to produce correct column order
    collectionData = collectionData.map(function(data) {
      data.activities = activityTypes.map(function(activityType) {
        if (data.activitiesMap.hasOwnProperty(activityType)) {
          return data.activitiesMap[activityType];
        }
        return {
          minutes: 0,
          percentage: 0
        };
      });
      return data;
    });

    var activityColumns = activityTypes.map(function(activityType) {
      return TimeTrack.ACTIVITIES[activityType];
    });

    var percentageOfTarget = {};
    var countDays = 0;
    var countWorkingDays = 0;
    var totalPercentageOfTarget = 0;
    activityTypes.forEach(function(activityType) {
      percentageOfTarget[activityType] = 0;
    });

    collectionData.forEach(function(record) {
      var hasWorked = false;
      if (record.isWorkingDay) {
        countDays += 1;
        totalPercentageOfTarget += record.percentageOfTarget;
      }
      activityTypes.forEach(function(activityType) {
        if (record.activitiesMap.hasOwnProperty(activityType)) {
          percentageOfTarget[activityType] += record.activitiesMap[activityType].percentage;
          if (record.activitiesMap[activityType].minutes > 0) {
            hasWorked = true;
          }
        }
      });

      if (hasWorked) {
        countWorkingDays += 1;
      }
    });

    var averagePercentage = activityTypes.map(function(activityType) {
      return percentageOfTarget[activityType] / countDays;
    });
    var averageActualPercentage = activityTypes.map(function(activityType) {
      return percentageOfTarget[activityType] / countWorkingDays;
    });

    var serializedData = {
      collection: collectionData,
      activityColumns: activityColumns,
      activityTypes: activityTypes,
      activityOrder: activityOrder,
      numColumns: 4 + (activityTypes.length * 2),
      averagePercentage: averagePercentage,
      averageActualPercentage: averageActualPercentage,
      averagePercentageOfTarget: totalPercentageOfTarget / countDays
    };

    return serializedData;
  }
});

module.exports = TimetrackDailyBreakdownTableView;
