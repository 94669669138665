// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td><input type=\"text\" class=\"form-control input-sm\" name=\"date_made\" placeholder=\"DD/MM/YYYY\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date_made : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" /></td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control input-sm\" name=\"payee\" value=\""
    + alias3(((helper = (helper = helpers.payee || (depth0 != null ? depth0.payee : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"payee","hash":{},"data":data}) : helper)))
    + "\" />\n  </div>\n</td>\n<td><input type=\"number\" class=\"form-control input-sm\" name=\"amount\" placeholder=\"Amount...\" value=\""
    + alias3((helpers.fixedDecimalPlaces || (depth0 && depth0.fixedDecimalPlaces) || alias2).call(alias1,(depth0 != null ? depth0.amount : depth0),2,{"name":"fixedDecimalPlaces","hash":{},"data":data}))
    + "\" /></td>\n<td><input type=\"text\" class=\"form-control input-sm\" name=\"description\" placeholder=\"Description...\" value=\""
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control input-sm\" name=\"case_prospect\" value=\""
    + alias3(((helper = (helper = helpers.case_prospect || (depth0 != null ? depth0.case_prospect : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"case_prospect","hash":{},"data":data}) : helper)))
    + "\" />\n  </div>\n</td>\n<td><input type=\"text\" class=\"form-control input-sm\" name=\"invoice_number\" placeholder=\"Invoice number...\" value=\""
    + alias3(((helper = (helper = helpers.invoice_number || (depth0 != null ? depth0.invoice_number : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"invoice_number","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td><input type=\"checkbox\" class=\"form-control\" name=\"is_international\" value=\"1\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_international : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n<td><button type=\"button\" class=\"btn btn-xs btn-default cancel\">Cancel</button> <button type=\"button\" class=\"btn btn-xs btn-primary save\" data-loading-text=\"Saving...\">Save</button></td>";
},"useData":true});
