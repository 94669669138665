'use strict';

var template = require('../../../templates/reporting/pipeline-granular-row.hbs');
var BookingRecord = require('../../../models/reporting/bookingrecord');
var ForecastingRecord = require('../../../models/reporting/forecastingrecord');

var PipelineGranularRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click a.popout': 'onClickPopoutCase'
  },
  onClickPopoutCase: function() {
    TCAS.commands.execute('popoutCase', this.getOption('casefile'));
    return false;
  },
  serializeData: function() {
    var record = this.model.toJSON();
    var data = {
      record: record,
      category: this.getOption('category').toJSON()
    };
    data.user = this.getOption('user').toJSON();
    if (this.getOption('team')) {
      data.team = this.getOption('team').toJSON();
    }

    data.casefile = this.getOption('casefile').toJSON();
    var categoryGroup = TCAS.reqres.request('categoryGroup', data.casefile.category);
    if (categoryGroup.isAssetCase()) {
      data.caseType = 'Asset';
    } else {
      data.caseType = 'Council';
    }

    if (this.getOption('disclosure')) {
      data.disclosure = this.getOption('disclosure').toJSON();
    }

    if (this.model instanceof BookingRecord) {
      data.confirmedRevenue = this.model.get('revenue');
      data.isBooked = true;
    } else if (this.model.get('is_at_risk')) {
      data.atRiskRevenue = this.model.get('revenue');
    } else if (this.model instanceof ForecastingRecord) {
      if (this.model.get('status') === 'pending') {
        data.pendingRevenue = this.model.get('revenue');
      } else {
        data.confirmedRevenue = this.model.get('revenue');
      }
    } else {
      // must be a PledgingRecord
      data.pledgedRevenue = this.model.get('revenue');
    }

    data.dateString = new Date(this.model.get('date')).toISOString();

    data.displayPence = this.getOption('displayPence');

    this.data = data;

    return data;
  }
});

module.exports= PipelineGranularRowView;
