'use strict';

var template = require('../../templates/users/user-target-row.hbs');

var UserTargetRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  modelEvents: {
    change: 'render'
  }
});

module.exports = UserTargetRowView;
