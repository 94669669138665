'use strict';
var template = require('../../templates/cases/casefile-import-row.hbs');
var CaseFile = require('../../models/cases/casefile');

var CaseFileImportRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  className: 'case-row',
  model: CaseFile,
  events: {
    'click': 'selectModel'
  },
  selectModel: function() {
    var checked = this.model.get('checked');
    checked = !checked;
    this.model.set('checked', checked);
    this.checkModel();
  },
  checkModel: function() {
    var checked = this.model.get('checked');
    var conflict = this.model.get('conflict');

    if(conflict) {
      if(checked) {
        this.$el.removeClass('danger');
        this.$el.addClass('success');
      } else {
        this.$el.removeClass('success');
        this.$el.addClass('danger');
      }
    } else {
      if(checked) {
        this.$el.removeClass('danger');
      } else {
        this.$el.addClass('danger');
      }
    }

    this.render();
  },
  onShow: function() {
    this.checkModel();
  },
  serializeData: function() {
    var data = this.model.toJSON();
    if (this.model.get('asset_value')) {
      data.asset_value = this.model.get('asset_value').toJSON();
    }

    return data;
  }
});

module.exports = CaseFileImportRowView;