'use strict';
var template = require('../../templates/users/client-row.hbs');
var User = require('../../models/users/user');

var ClientUserRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  className: 'client-row',
  ui: {
    reset2FA: '.reset-2fa',
    unlock: '.unlock',
  },
  triggers: {
    'click @ui.reset2FA': 'click:reset2FA',
    'click @ui.unlock': 'click:unlock',
  },
  modelEvents: {
    sync: 'render',
  },
  serializeData: function() {
    var data = this.model.toJSON();
    var group = this.model.getGroup();
    if (group === User.GROUPS.CLIENT) {
      data.isClient = true;
    } else if (group === User.GROUPS.ACCOUNTS) {
      data.isAccounts = true;
    }

    // get a link direct to the client contact card in the case
    var caseProspect = this.getOption('caseProspect');
    if (caseProspect) {
      data.caseProspectUrl =
        '/cases/' + caseProspect.get('casefile') + '/prospects/' + caseProspect.get('id');
    }

    return data;
  },
});

module.exports = ClientUserRowView;
