// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td></td>\n<td>"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.totalAmount : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td></td>\n<td></td>\n<td>"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.totalVAT : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td></td>\n<td></td>";
},"useData":true});
