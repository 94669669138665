'use strict';

var template = require('../../templates/milestones/lifecycle-layout.hbs');
var StatusFlagActions = require('./status-flags-actions');

var LifecycleLayout = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    lifecycle: '#lifecycle-actions'
  },
  onShow: function() {
    this.lifecycle.show(new StatusFlagActions(this.options));
  }

});

module.exports = LifecycleLayout;