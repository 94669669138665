// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "draft";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"fa fa-clock-o pull-right\" title=\"Timetrack entry\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <div class=\"btn-group pull-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canEdit : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(alias1,"flag_message",{"name":"hasPerm","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canEdit : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.fao_clients : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "          <button type=\"button\" class=\"btn btn-default btn-xs pin "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_pinned : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" title=\"Keep the text expanded\"><i class=\"fa fa-thumb-tack\"></i></button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_published : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <button type=\"button\" class=\"btn btn-default btn-xs edit-message "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.editMode : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" title=\"Edit\"><i class=\"fa fa-pencil\"></i></button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "active";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <button type=\"button\" class=\"btn btn-default btn-xs edit-message "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.editMode : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" title=\"Edit\"><i class=\"fa fa-pencil\"></i></button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <button type=\"button\" class=\"btn btn-default btn-xs flag "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_flagged : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_flagged : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " title=\"Flag for review\"><i class=\"fa fa-flag\"></i></button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"unflag_message",{"name":"hasPerm","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "";
},"17":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_published : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"delete_published_message",{"name":"hasPerm","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "              <button type=\"button\" class=\"btn btn-danger btn-xs delete\" title=\"Delete\"><i class=\"fa fa-trash\"></i></button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "            <button type=\"button\" class=\"btn btn-danger btn-xs delete\"><i class=\"fa fa-trash\"></i></button>\n            <button type=\"button\" class=\"btn btn-success btn-xs publish\" data-loading-text=\"Saving...\">Publish</button>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "    <div>\n      <span class=\"label label-default draft\">Draft</span>\n    </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-default\">Email reply</span>";
},"29":function(container,depth0,helpers,partials,data) {
    return " <span class=\"label label-warning\">New</span>";
},"31":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.fao : depth0)) != null ? stack1.length : stack1),((stack1 = (depth0 != null ? depth0.fao_clients : depth0)) != null ? stack1.length : stack1),{"name":"or","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.fao_clients : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <tr class=\"message-to\">\n          <th>To:</th>\n          <td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.fao : depth0),{"name":"each","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.fao_clients_objects : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n";
},"33":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "              <div data-contact-id=\""
    + alias3((helpers.userContactId || (depth0 && depth0.userContactId) || alias2).call(alias1,depth0,{"name":"userContactId","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.userFullNameOnly || (depth0 && depth0.userFullNameOnly) || alias2).call(alias1,depth0,{"name":"userFullNameOnly","hash":{},"data":data}))
    + " <span class=\"label label-info\">Staff</span> "
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"preview_email_message",{"name":"hasPerm","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"34":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return "<a class=\"preview-email\" href=\"/emails/message_fao/"
    + container.escapeExpression(container.lambda((depths[1] != null ? depths[1].id : depths[1]), depth0))
    + "\" target=\"_blank\" title=\"Preview email\"><i class=\"fa fa-envelope\"></i></a>";
},"36":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "              "
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fao_clients_objects : depth0),{"name":"each","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<div data-contact-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.last_name : stack1), depth0))
    + " "
    + alias2((helpers.prospectObjectRoleLabel || (depth0 && depth0.prospectObjectRoleLabel) || alias4).call(alias3,depth0,{"name":"prospectObjectRoleLabel","hash":{},"data":data}))
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias4).call(alias3,"preview_email_message",{"name":"hasPerm","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=container.escapeExpression;

  return "<a class=\"preview-email\" href=\"/emails/message_fao_client/"
    + alias1(container.lambda((depths[1] != null ? depths[1].id : depths[1]), depth0))
    + "/client/"
    + alias1(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" title=\"Preview email\"><i class=\"fa fa-envelope\"></i></a>";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.subject : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <tr class=\"message-subject\">\n            <th>Subject:</th>\n            <td>"
    + container.escapeExpression(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subject","hash":{},"data":data}) : helper)))
    + "</td>\n          </tr>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <tr>\n        <th>CC:</th>\n        <td>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.cc_email_addresses : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </td>\n      </tr>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "            <div>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.attachments : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    return "        <tr>\n          <th>Attachments:</th>\n          <td>\n            <a href=\"#\" class=\"show-attachments\">Show attachments</a>\n            <div class=\"message-attachments-container\"></div>\n          </td>\n        </tr>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"form-group\">\n      <textarea class=\"edit-message-content form-control\" rows=\"3\">"
    + container.escapeExpression((helpers.reformatMessage || (depth0 && depth0.reformatMessage) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.content : depth0),{"name":"reformatMessage","hash":{},"data":data}))
    + "</textarea>\n    </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_published : depth0),{"name":"unless","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <button class=\"btn btn-primary save\">Save</button>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.fao_clients : depth0)) != null ? stack1.length : stack1),1,{"name":"eq","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"form-group\">\n          <label>Subject</label>\n          <input type=\"text\" class=\"form-control subject\" name=\"subject\" placeholder=\"Type your subject here...\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.subject : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n        </div>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subject","hash":{},"data":data}) : helper)))
    + "\"";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <div class=\"row\">\n        <div class=\"col-md-6\">\n          <div class=\"form-group\">\n            <label for=\"fao\" class=\"control-label\">FAO users</label>\n            <input type=\"text\" class=\"fao-select form-control\" id=\"fao\" name=\"fao\" />\n          </div>\n        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fao_clients_objects : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"message-attachments-container\"></div>\n      <div class=\"form-group\">\n        <button type=\"button\" class=\"btn btn-default btn-xs attach\"><i class=\"fa fa-plus\"></i> Attach file</button>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"dropzone-previews\"></div>\n      <div class=\"attach-existing-container\"></div>\n";
},"55":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"col-md-6\">\n            <div class=\"form-group fao-clients-container\">\n              <label for=\"fao-client\" class=\"control-label\">FAO clients</label>\n              <input type=\"text\" class=\"fao-clients-select form-control\" name=\"fao_clients\" />\n            </div>\n          </div>\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "          <button type=\"button\" class=\"btn btn-default btn-xs attach-existing\"><i class=\"fa fa-plus\"></i> Attach existing</button>\n";
},"59":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression((helpers.formatMessage || (depth0 && depth0.formatMessage) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.content : depth0),{"name":"formatMessage","hash":{},"data":data}))
    + "\n    <div class=\"read-more-bar\">\n      <span class=\"badge\">Read more</span>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"message-header "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_published : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n  <div class=\"pull-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTimeTrack : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "  </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_published : depth0),{"name":"unless","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <table>\n    <tbody>\n    <tr>\n      <th>From:</th>\n      <td>"
    + alias3((helpers.contactFullName || (depth0 && depth0.contactFullName) || alias2).call(alias1,(depth0 != null ? depth0.author : depth0),{"name":"contactFullName","hash":{},"data":data}))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_email_reply : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n    </tr>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.cc_email_addresses : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <tr>\n      <th>Date:</th>\n      <td><time datetime=\""
    + alias3(((helper = (helper = helpers.published_date || (depth0 != null ? depth0.published_date : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"published_date","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.published_date : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "</time></td>\n    </tr>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.editMode : depth0),{"name":"unless","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n  </table>\n</div>\n<div class=\"message-content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.editMode : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.program(59, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});
