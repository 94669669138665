'use strict';
var User = require('../../models/users/user');
var ClientCaseFileRowView = require('./client-casefile-row');
var CaseFileNoRowsView = require('./casefile-no-rows');
var Spinner = require('spin');
var template = require('../../templates/cases/client-casefile-list.hbs');

var ClientCaseFilesTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'div',
  id: 'casefiles',
  template: template,
  childView: ClientCaseFileRowView,
  childViewContainer: '.table',
  emptyView: CaseFileNoRowsView,
  events: {
    'click @ui.sortableField': 'sortField'
  },
  childEvents: {
    'case:click': function(view) {
      if (this.getOption('isAccounts')) {
        TCAS.router.navigate('accounts/cases/' + view.model.get('id'), { trigger: true });
      } else {
        TCAS.router.navigate('client/cases/' + view.model.get('id'), { trigger: true });
      }
    }
  },
  collectionEvents: {
    'sync': 'onSync'
  },
  ui: {
    loadingBar: 'div.loading-bar',
    sortableField: 'a.sortable',
    table: 'table',
    pageInfo: '.page-info-bar',
    caseCount: '.case-count',
    caseTotal: '.case-total'
  },
  initialize: function(){
    var lazyScroll = _.bind(_.debounce(this.onScroll, 100), this);
    $(window).scroll(lazyScroll);
  },
  sortField: function(e){
    var orderBy = $(e.currentTarget).attr('data-sort');
    orderBy = orderBy.replace(/\./g, '__');
    if (this.collection.orderBy && this.collection.orderBy[0] === orderBy) {
      orderBy = '-' + orderBy;
    }
    this.collection.orderBy = [orderBy];
    this.collection.fetch();
    return false;
  },
  onScroll: function(){
    var that = this;
    var scrollTop = $(window).scrollTop();
    var isMaxScroll = (document.body.scrollHeight - $(window).height()) ===
      scrollTop;
    if (isMaxScroll && this.collection.next) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.spinner.spin(this.ui.loadingBar[0]);
      this.collection.fetchNext();
    }
  },
  onSync: function(){
    this.$('.sortable').removeClass('active reverse');
    if (this.collection.orderBy) {
      _(this.collection.orderBy).each(function(sortBy){
        sortBy = sortBy.replace(/__/g, '.');
        var isReversed = sortBy.charAt(0) === '-';
        if (isReversed) {
          sortBy = sortBy.substr(1);
        }
        this.$('[data-sort="'+sortBy+'"]').addClass('active')
          .toggleClass('reverse', isReversed);

      }, this);
    }
    this.ui.table.removeClass('hide');

    var that = this;
    that.ui.caseCount.html(that.collection.length);
    that.ui.caseTotal.html(that.collection.total);
    var progressWidth = that.ui.pageInfo.find('.progress').width();
    that.ui.pageInfo.removeClass('hide');
    that.ui.pageInfo.find('.progress-bar').attr('aria-valuenow',
    that.collection.length).attr('aria-valuemax', that.collection.length);
    that.ui.pageInfo.find('.progress-bar').width(
      Math.floor((that.collection.length/that.collection.total) *
        progressWidth)).is(':visible');

    if (this.spinner) {
      this.spinner.stop();
    }
  },
  onShow: function(){
    this.onSync();
  },
  serializeData: function(){
    var data = this.collection.toJSON();
    if (this.collection.orderBy) {
      data.sortField = this.collection.orderBy[0];
    }

    return data;
  }
});

module.exports = ClientCaseFilesTableView;