// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "Are you sure?";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n    <div class=\"modal-header\">\n      <h4 class=\"modal-title\" id=\"confirm-modal-label\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h4>\n    </div>\n    <div class=\"modal-body\">\n      "
    + container.escapeExpression((helpers.safeString || (depth0 && depth0.safeString) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.body : depth0),{"name":"safeString","hash":{},"data":data}))
    + "\n    </div>\n    <div class=\"modal-footer\">\n      <button type=\"button\" class=\"btn btn-default confirm-no\" data-dismiss=\"modal\">No</button>\n      <button type=\"button\" class=\"btn btn-primary confirm-yes\" data-dismiss=\"modal\">Yes</button>\n    </div>\n  </div>\n</div>";
},"useData":true});
