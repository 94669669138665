'use strict';

var Handlebars = require('hbsfy/runtime');
var template = require('../../templates/clients/client-row.hbs');
var ClientDetailsView = require('./client-details');
var Util = require('../../util');

var ClientRowView = Backbone.Marionette.LayoutView.extend({
  className: 'client-row',
  tagName: 'tbody',
  template: template,
  regions: {
    clientDetails: '.client-details-container',
  },
  ui: {
    entitlement: '.client-entitlement',
    vat: '.client-vat',
    feePercentage: '.client-fee-percentage',
    treethorpeRevenue: '.client-treethorpe-revenue',
    clientDistribution: '.client-distribution',
    clientName: '.client-name',
    clientPRNoK: '.client-pr-nok',
  },
  modelEvents: {
    'change:type': 'onProspectRoleChanged',
    'change:is_missing': 'onProspectRoleChanged',
    'change:has_access': 'onHasAccessChanged',
    'change:entitlement_denominator': 'refreshData',
    'change:entitlement_numerator': 'refreshData',
    'change:is_vat_applicable': 'refreshData',
    'change:fee_percentage_agreed': 'refreshData',
    'change:contact': 'refreshData',
    'change:milestones': 'refreshData',
  },
  initialize: function() {
    var that = this;
    var clientId = this.model.get('id');
    this.$el.attr('data-id', this.model.get('id'));
    this.listenTo(this.model.get('milestones'), 'add', function(milestone) {
      this.getOption('clientMilestones').add(milestone);
      // if new milestone is PR/NoK, update the tick
      if (
        milestone.get('client') === clientId &&
        milestone.get('client_milestone_type') === 2 &&
        milestone.get('is_active') &&
        !milestone.get('is_not_applicable')
      ) {
        that.ui.clientPRNoK.html('&#x2714;');
      }
      this.refreshData(this.model);
    });
    this.listenTo(this.model.get('milestones'), 'change:is_active', function(milestone) {
      if (
        milestone.get('client') === clientId &&
        milestone.get('client_milestone_type') === 2 &&
        !milestone.get('is_active')
      ) {
        that.ui.clientPRNoK.empty();
      }
      this.refreshData(this.model);
    });
    this.listenTo(this.model.get('milestones'), 'change', function(milestone) {
      if (
        milestone.get('client') === clientId &&
        milestone.get('client_milestone_type') === 2 &&
        !milestone.get('is_active')
      ) {
        that.ui.clientPRNoK.empty();
      }
      this.refreshData(this.model);
    });
    this.listenTo(this.getOption('clientMilestones'), 'add', function(milestone) {
      // if new milestone is PR/NoK, update the tick
      if (
        milestone.get('client') === clientId &&
        milestone.get('client_milestone_type') === 2 &&
        milestone.get('is_active') &&
        !milestone.get('is_not_applicable')
      ) {
        that.ui.clientPRNoK.html('&#x2714;');
      }
      this.refreshData(this.model);
    });
  },
  onProspectRoleChanged: function(model) {
    var labelClass = 'default';
    var name = 'Contact';

    if (model.isClient()) {
      labelClass = 'success';
      name = 'Client';
    } else if (model.isBeneficiary()) {
      labelClass = 'primary';
      name = 'Beneficiary';
    } else if (model.isAncestor()) {
      labelClass = 'info';
      name = 'Ancestor';
    }
    if (model.get('is_missing')) {
      labelClass = 'danger';
    } else if (model.get('is_deceased_asset_holder')) {
      labelClass = 'warning';
    }

    this.$('.client-type-td')
      .empty()
      .append('<span class="label label-' + labelClass + '">' + name + '</span>');
  },
  onHasAccessChanged: function(model, hasAccess) {
    if (hasAccess) {
      this.$('.client-type-td').html(
        '<span class="label label-success">Client <i class="fa fa-user"></i></span>'
      );
    } else {
      this.$('.client-type-td').html('<span class="label label-success">Client</span>');
    }
  },
  refreshData: function(model) {
    var data = this.serializeData();

    this.ui.clientName.text(model.get('contact').getFullNameWithSurnameAtBirth(true));
    if (data.entitlement_numerator) {
      this.ui.entitlement.html(
        Handlebars.Utils.escapeExpression(
          data.entitlement_numerator + '/' + data.entitlement_denominator
        )
      );
    } else {
      this.ui.entitlement.html('');
    }

    this.ui.feePercentage.html(
      Handlebars.Utils.escapeExpression(data.fa_received ? data.fee_percentage_agreed : 0) + '%'
    );
    this.ui.treethorpeRevenue.html(
      data.fa_received ? Util.formatCurrency(data.treethorpe_revenue) : '£0.00'
    );
    this.ui.clientDistribution.html(
      data.fa_received
        ? Util.formatCurrency(data.client_distribution)
        : Util.formatCurrency(data.client_distribution_before_fees)
    );
    if (data.is_vat_applicable) {
      this.ui.vat.html(
        '<span title="' + Handlebars.Utils.escapeExpression(data.vat_rate) + '%">&#x2714;</span>'
      );
    } else {
      this.ui.vat.html('');
    }
    this.$('[title]').tooltip();
  },
  onRender: function() {
    this.$('[title]').tooltip();
  },
  onShow: function() {
    var that = this;
    this.$('.collapse').on('show.bs.collapse', function(e) {
      // only apply if it's the correct target,
      // datepicker show event is also triggering this callback,
      // so make sure target has collapse class
      if (that.$(e.target).hasClass('collapse')) {
        that.$el.addClass('active');
        that.clientDetails.show(new ClientDetailsView(that.options));
      }
    });
    this.$('.collapse').on('hide.bs.collapse', function() {
      that.$el.removeClass('active');
    });
  },
  onDestroy: function() {
    this.$('.collapse').off('show.bs.collapse');
    this.$('.collapse').off('hide.bs.collapse');
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.showTableExpanded = this.getOption('showTableExpanded');
    var milestones = this.getOption('clientMilestones');
    var clientId = this.model.get('id');
    if (milestones.length) {
      var faSent = milestones.find(function(milestone) {
        return (
          milestone.get('client_milestone_type') === 1 &&
          milestone.get('client') === clientId &&
          !milestone.get('is_not_applicable') &&
          milestone.get('is_active')
        );
      });
      var prNoK = milestones.find(function(milestone) {
        return (
          milestone.get('client_milestone_type') === 2 &&
          milestone.get('client') === clientId &&
          !milestone.get('is_not_applicable') &&
          milestone.get('is_active')
        );
      });
      var faReceived = milestones.find(function(milestone) {
        return (
          milestone.get('client_milestone_type') === 3 &&
          milestone.get('client') === clientId &&
          !milestone.get('is_not_applicable') &&
          milestone.get('is_active')
        );
      });
      var disclosureSent = milestones.find(function(milestone) {
        return (
          milestone.get('client_milestone_type') === 4 &&
          milestone.get('client') === clientId &&
          !milestone.get('is_not_applicable') &&
          milestone.get('is_active')
        );
      });
      var paymentMade = milestones.find(function(milestone) {
        return (
          milestone.get('client_milestone_type') === 5 &&
          milestone.get('client') === clientId &&
          !milestone.get('is_not_applicable') &&
          milestone.get('is_active')
        );
      });
      var questionnaireReturned = milestones.find(function(milestone) {
        return (
          milestone.get('client_milestone_type') === 6 &&
          milestone.get('client') === clientId &&
          !milestone.get('is_not_applicable') &&
          milestone.get('is_active')
        );
      });
      /* jshint ignore:start */
      if (faSent) {
        data.fa_sent = faSent.toJSON();
      }
      if (prNoK) {
        data.pr_nok = prNoK.toJSON();
      }
      if (faReceived) {
        data.fa_received = faReceived.toJSON();
      }
      if (disclosureSent) {
        data.disclosure_sent = disclosureSent.toJSON();
      }
      if (paymentMade) {
        data.payment_made = paymentMade.toJSON();
      }
      if (questionnaireReturned) {
        data.questionnaire_returned = questionnaireReturned.toJSON();
      }
      /* jshint ignore:end */
    }

    // work out number of unseen messages from this client to display in badge
    var lastViewed = this.getOption('lastViewed');
    var clientContactId = this.model.get('contact').get('id');
    var newClientMessages = this.getOption('messages').filter(function(message) {
      var created = new Date(message.get('created'));
      return (
        message.get('author') === clientContactId &&
        created > lastViewed &&
        message.get('owner') !== TCAS.session.get('id')
      );
    });
    data.notificationBadgeCount = newClientMessages.length;

    var revenueAndDistribution = this.model.calculateRevenueAndClientDistribution(
      this.getOption('casefile')
    );
    data.treethorpe_revenue = revenueAndDistribution.revenue;
    data.client_distribution = revenueAndDistribution.clientDistribution;
    data.client_distribution_before_fees = revenueAndDistribution.clientDistributionBeforeFees;

    return data;
  },
});

module.exports = ClientRowView;
