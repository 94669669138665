'use strict';

var template = require('../../templates/clients/client-payment-row.hbs');
var templateEdit =
  require('../../templates/clients/client-payment-row-edit.hbs');
var ConfirmModalView = require('../confirm-modal');

var ClientPaymentRowView = Backbone.Marionette.ItemView.extend({
  className: 'client-payment-row',
  tagName: 'tr',
  template: template,
  events: {
    'click @ui.deleteBtn': 'deletePayment',
    'click @ui.editBtn': 'edit',
    'click @ui.saveBtn': 'save',
    'click @ui.cancelEditBtn': 'cancelEdit'
  },
  ui: {
    deleteBtn: 'button.delete',
    editBtn: 'button.edit',
    saveBtn: 'button.save',
    cancelEditBtn: 'button.cancel',
    dateMade: 'input[name="date_made"]',
    payee: 'input[name="payee"]',
    amount: 'input[name="amount"]',
    description: 'input[name="description"]',
    prospect: 'input[name="case_prospect"]',
    invoiceNumber: 'input[name="invoice_number"]',
    international: 'input[name="is_international"]'
  },
  initialize: function() {
    this.editMode = false;
  },
  edit: function(e){
    e.stopImmediatePropagation();
    this.template = templateEdit;
    this.editMode = true;
    this.render();

    var clients = this.getOption('clients');

    function queryProspects(query){

      function getProspectName(prospect) {
        var name = prospect.getFullName();
        if (prospect.get('contact').get('post_code')) {
          var post = prospect.get('contact').get('post_code');
          name += ' (' + post.trim()+ ')';
        }

        return name;
      }

      var results = clients.filter(function(prospect){
        var name = getProspectName(prospect);
        return name.toLowerCase().indexOf(query.term.toLowerCase()) !== -1;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: getProspectName(prospect)
        };
      });
      query.callback({ results: results, more: false });
    }

    this.ui.dateMade.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
      todayHighlight: true
    });

    this.ui.prospect.select2({
      query: queryProspects,
      initSelection : function (element, callback) {
        var id = element.val();
        var data = {id: id, text: clients.get(id).getFullName()};
        callback(data);
      }
    });

    this.ui.payee.autoComplete({
      source: function(term, suggest){
        term = term.toLowerCase();
        var matches = [];
        clients.each(function(client) {
          var fullName = client.getFullName();
          if (fullName.toLowerCase().indexOf(term) !== -1) {
            matches.push(fullName);
          }
        });
        suggest(matches);
      },
      minChars: 2
    });
  },
  cancelEdit: function(e){
    e.stopImmediatePropagation();
    this.template = template;
    this.editMode = false;
    this.render();
  },
  save: function(e) {
    e.stopImmediatePropagation();
    var that = this;
    var data = {};
    var date = this.ui.dateMade.val().split('/');
    /* jshint ignore:start */
    data.date_made = date[2] + '-' + date[1] + '-' + date[0];
    data.payee = this.ui.payee.val();
    data.amount = this.ui.amount.val();
    data.description = this.ui.description.val();
    data.case_prospect = this.ui.prospect.val();
    data.invoice_number = this.ui.invoiceNumber.val();
    data.is_international = this.ui.international.is(':checked');
    /* jshint ignore:end */

    this.model.save(data, { wait: true, success: function() {
      that.editMode = false;
      that.template = template;
      that.render();
    }});
  },
  deletePayment: function(e) {
    e.stopImmediatePropagation();

    //jshint camelcase:false
    var confirmModal = new ConfirmModalView({
      body: 'Are you sure you want to delete this payment?'
    });
    confirmModal.render();
    this.listenTo(confirmModal, 'confirmYes', function() {
      this.model.save({is_active: false}, {patch: true, wait: true});
    });
  }
});

module.exports = ClientPaymentRowView;
