// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.allocate_count || (depth0 != null ? depth0.allocate_count : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"allocate_count","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "-";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td></td>\n<td>"
    + alias3((helpers.categoryField || (depth0 && depth0.categoryField) || alias2).call(alias1,(depth0 != null ? depth0.category : depth0),"code",{"name":"categoryField","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.current_allocation || (depth0 != null ? depth0.current_allocation : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"current_allocation","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allocate_count : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
