'use strict';

var template = require('../../templates/events/event-reminder-form.hbs');

var ReminderFormView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'modal fade',
  events: {
    'click .popout-case': 'popoutCase'
  },
  behaviors: {
    SubmitForm: {
      selector: 'form',
      submitHandler: 'submitHandler'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          message: {
            required: true
          },
          due_date: {
            required: true
          }
        }
      }
    }
  },
  popoutCase: function(e){
    e.preventDefault();
    TCAS.commands.execute('popoutCase', this.model.get('case'));

    return false;
  },
  submitHandler: function(data) {
    var that = this;

    this.model.save(data, { wait: true, success: function() {
      that.$el.modal('hide');
    }});
  },
  onRender: function() {
    this.$el.appendTo('body');
    this.$el.modal({
      backdrop: 'static'
    });
  }
});

module.exports = ReminderFormView;
