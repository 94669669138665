// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <form>\n          <div class=\"alert alert-info\">\n            To reset your 2 factor authentication method use the button below.\n          </div>\n\n          <p class=\"help-block\">\n            Click the button below to reset your two factor authentication method.<br/><br/>\n            After this you can sign in and re-create your two factor authentication method.\n          </p>\n\n          <button type=\"submit\" class=\"btn btn-primary\" data-loading-text=\"Resetting...\">Reset 2FA</button>\n        </form>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-danger\" role=\"alert\">\n          <strong>Oops!</strong> This set reset request has already been used.\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"login-layout\">\n  <div class=\"login-body\">\n    <div class=\"login-panel\">\n      <div class=\"page-header\">\n        <img class=\"treethorpe-logo\" src=\"/static/frontend/img/TRE_Logo_Colour_RGB_Small.png\" alt=\"Treethorpe logo\" />\n      </div>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_active : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>";
},"useData":true});
