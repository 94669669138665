'use strict';
var LoginLayout = require('../views/login-layout');
var NotFoundView = require('../views/not-found');
var NoPermissionView = require('../views/no-permission');

var MainController = Backbone.Marionette.Controller.extend({
  login: function(subpath) {
    var app = this.getOption('app');
    if (app.session.isAuth()) {
      app.router.navigate('reminders', {trigger: true});
      return;
    }
    var loginLayout = new LoginLayout({
      app: app,
      expandForgotPassword: subpath === 'forgot-password'
    });
    app.mainBody.show(loginLayout);
  },
  logout: function(){
    var app = this.getOption('app');
    app.session.signOut();
  },
  notFound: function(route){
    var app = this.getOption('app');
    var notFoundView = new NotFoundView({
      route: route
    });
    app.mainBody.show(notFoundView);
    //ensure the route url is reflected
    app.router.navigate('not-found');
    document.title = 'Page Not Found';
  },
  noPermission: function(route) {
    var app = this.getOption('app');
    var noPermissionView = new NoPermissionView({
      route: route
    });
    app.mainBody.show(noPermissionView);
    app.router.navigate('no-permission');
    document.title = 'Access Denied';
  },
  appRoutes: {
    'login(/:subpath)': 'login',
    'logout': 'logout',
    'not-found': 'notFound',
    'no-permission': 'noPermission'
  }
});

module.exports = MainController;