'use strict';
var template = require('../../templates/cases/casefile-row.hbs');
var CaseFile = require('../../models/cases/casefile');
var MessageListView = require('../messages/message-list');
var AllocationListView = require('../allocations/allocation-list');

var CaseFileRowView = Backbone.Marionette.LayoutView.extend({
  template: template,
  tagName: 'tbody',
  className: 'casefile-wrapper',
  model: CaseFile,
  ui: {
    tabContent: '.tab-content',
    popoutBtn: '.popout',
    popoutEdit: '.popout-edit',
    checkAllocate: '#allocate-checkbox-area',
    allocateCheckbox: '#allocate-checkbox',
    deleteBtn: '#delete-casefile',
    supervisorName: '.supervisor-name'
  },
  events: {
    'click @ui.popoutBtn': 'popoutCase',
    'click @ui.popoutEdit': 'popoutEditCase',
    'click @ui.checkAllocate': 'allocationClicked',
    'click @ui.deleteBtn': 'deleteCase'
  },
  regions: {
    messageListRegion: '.message-list-container',
    allocationRegion: '.allocation-list-container'
  },
  initialize: function() {
    var that = this;
    this.model.set('checked', false);
    this.listenTo(this.model, 'select:checkbox', function() {
      that.ui.allocateCheckbox.prop('checked', that.model.get('checked'));
    });
  },
  allocationClicked: function(e) {
    e.stopPropagation();
    this.model.set('checked', !this.model.get('checked'));
  },
  popoutCase: function(e){
    TCAS.commands.execute('popoutCase', this.model);

    return false;
  },
  popoutEditCase: function(e){
    TCAS.commands.execute('popoutCase', this.model, true);

    return false;
  },
  deleteCase: function() {
    this.trigger('remove:casefile');
  },
  onScroll: function(e){
    e.stopPropagation();
    e.preventDefault();
  },
  onShow: function(){
    var that = this;

    this.$el.on('show.bs.collapse', function(){
      that.$el.addClass('active');

      that.allocationRegion.show(new AllocationListView({
        collection: that.model.get('allocations')
      }));
      that.model.fetchMessages().then(function(){
        var messages = that.model.get('messages');
        var messageListView = new MessageListView({
          collection: messages,
          casefile: that.model,
          filterFunction: function(message) {
            return message.get('fao_clients').length === 0;
          }
        });
        that.messageListRegion.show(messageListView);
      });
    });
    this.$el.on('hide.bs.collapse', function(){
      that.$el.removeClass('active');
    });
  },
  serializeData: function(){
    var that = this;
    var data = this.model.toJSON();
    var allocation = this.model.getCurrentAllocation();

    data.showCheckbox = false;
    data.showCheckbox =
      this.model.get('status') === CaseFile.STATUS_IDS.UNALLOCATED ||
        this.model.get('status') === CaseFile.STATUS_IDS.RESEARCH;

    if (allocation) {
      data.allocation = allocation.toJSON();
      data.allocation.supervisor = TCAS.reqres.request('supervisorId',
        allocation.get('case_manager'));
    }

    if(!this.model.get('milestones').isEmpty()) {
      data.researchSignedOff = that.model.checkResearchSignedOff();
    } else if (!that.hasFetchedMilestones) {
      that.listenToOnce(that.model.get('milestones'), 'sync', function(){
        that.render();
      });
      that.model.fetchMilestones();
      that.hasFetchedMilestones = true;
    }

    return data;
  }
});

module.exports = CaseFileRowView;