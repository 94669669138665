'use strict';

var template = require('../../templates/allocations/casemanager-filter.hbs');
var CaseManagerSelectView = require('./casemanager-select');

var CaseManagerFilterView = Backbone.Marionette.LayoutView.extend({
  template: template,
  ui: {
    form: 'form',
    filterBtn: '.filter-btn'
  },
  regions: {
    caseManagerRegion: '.case-manager-select-container'
  },
  events: {
    'submit': 'onFormSubmit'
  },
  onFormSubmit: function() {
    this.trigger('casemanagerSelected',
      this.caseManagerRegion.currentView.getSelectedCaseManager());
    return false;
  },
  onShow: function() {
    this.caseManagerRegion.show(
      new CaseManagerSelectView({ user: this.getOption('user') })
    );
  }
});

module.exports = CaseManagerFilterView;
