'use strict';

var template = require('../../templates/financials/pledgingrecord-list.hbs');

var PledgingRecordRowView = require('./pledgingrecord-row');
var PledgingRecord = require('../../models/reporting/pledgingrecord');

var PledgingRecordListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: PledgingRecordRowView,
  childViewContainer: 'tbody.table-items',
  collectionEvents: {
    'change:is_active': function(model, isActive){
      if (!isActive) {
        this.collection.remove(model);
      }
    },
    'add remove sync': 'render'
  },
  setupForm: function() {
    var caseManager = null;
    var allocation = this.getOption('casefile').getCurrentAllocation();
    if (allocation) {
      caseManager = allocation.get('case_manager');
    }
    // jshint ignore:start
    var newModel = new PledgingRecord({
      case: this.getOption('casefile').get('id'),
      case_manager: caseManager
    });
    // jshint ignore:end
    this.newPledgingrecordView = new PledgingRecordRowView({
      model: newModel,
      editMode: true
    });
    this.listenTo(this.newPledgingrecordView, 'saved', function() {
      this.collection.add(newModel);
      this.stopListening(this.newPledgingrecordView);
      this.setupForm();
    });

    this.formRegion.show(this.newPledgingrecordView);
  },
  onRender: function() {
    if (this.formRegion) {
      this.formRegion.empty();
    }
    this.formRegion = new Backbone.Marionette.Region({
      el: this.$('.table-add')
    });

    if (TCAS.session.hasPerm('add_pledgingrecord')) {
      this.setupForm();
    }
  },
  serializeData: function() {
    var data = {};
    var totalRevenue = 0;
    var totalDisbursements = 0;
    var total = 0;

    this.collection.forEach(function(record) {
      totalRevenue += record.get('revenue');
      totalDisbursements += record.get('disbursements');
      total += record.get('revenue') + record.get('disbursements');
    });
    data.totalRevenue = totalRevenue;
    data.totalDisbursements = totalDisbursements;
    data.total = total;

    return data;
  }
});

module.exports = PledgingRecordListView;
