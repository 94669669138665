'use strict';

var template =
  require('../../../templates/reporting/company-activity-booked-table.hbs');

var CompanyActivityBookedTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var bookingRecords = this.getOption('bookingRecords');
    var users = this.getOption('users');
    var data = [];

    users.forEach(function(user) {
      var userId = user.get('id');
      var userBookingRecords = bookingRecords.filter(function(bookingRecord) {
        return bookingRecord.get('case_manager') === userId;
      });

      var bookingByMonth = [];
      for (var i = 0; i < 12; i += 1) {
        bookingByMonth[i] = [];
      }

      // group records by month
      userBookingRecords.forEach(function(bookingRecord) {
        var created = new Date(bookingRecord.get('date'));
        bookingByMonth[created.getMonth()].push(bookingRecord);
      });

      var totalByMonth = [];
      var totalYTD = [];

      for (i = 0; i < 12; i += 1) {
        var total = 0;
        bookingByMonth[i].forEach(function(booking) {
          if (booking.get('case_element') === 1) {
            total += 1;
          }
        });
        totalByMonth[i] = total;

        totalYTD[i] = total;
        if (i > 0) {
          totalYTD[i] += totalYTD[i - 1];
        }
      }

      var dataRow = {
        user: user,
        totalByMonth: totalByMonth,
        totalYTD: totalYTD
      };
      data.push(dataRow);
    });

    this.trigger('dataReady', data);

    return data;
  }
});

module.exports = CompanyActivityBookedTableView;
