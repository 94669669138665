'use strict';
var template = require('../../templates/timetrack/timetrack-main.hbs');
var TimeTrackList = require('../../views/timetrack/timetrack-list');
var TimeTracks = require('../../models/timetrack/timetracks');
var TimeTrackCreate = require('./timetrack-create');
var alertTemplate = require('../../templates/alert.hbs');
var moment = require('moment');

var TimeTrackMainView = Backbone.Marionette.LayoutView.extend({
  template: template,
  ui: {
    'calendarWidget': '#calendar-widget',
    'alertsArea': '#alert-area',
    'timetrackTotal': '.timetrack-total'
  },
  regions: {
    'timeTrackList': '#timetrack-list',
    'timetrackCreate': '#create-timetrack'
  },
  onRender: function() {
    var that = this;

    this.timeTrackList.show(new TimeTrackList({
      collection: new TimeTracks([])
    }));
    this.timetrackCreate.show(new TimeTrackCreate());

    this.ui.calendarWidget.datepicker({format: 'dd/mm/yyyy'})
      .on('changeDate', function(e) {
        if(!(e.date instanceof Date)) {
          return;
        }

        TCAS.vent.trigger('widget:change:date', e.date);

        var s0 = new Date(e.date);
        s0.setHours(0,0,0,0);

        var s1 = new Date(e.date);
        s1.setHours(23,59,59,999);

        that.timeTrackList.currentView.collection.urlRoot =
          '/api/timetrack/' +
          '?start_a=' + encodeURIComponent(s0.toISOString()) +
          '&start_b=' + encodeURIComponent(s1.toISOString()) +
          '&owner=' + TCAS.session.get('id') +
          '&ordering=-start';

        that.timeTrackList.currentView.collection.fetch().then(function() {
          that.timeTrackList.currentView.render();
          var totalTime = that.getTotalHours(that);
          that.ui.timetrackTotal.text('Total time for date: ' + totalTime);
        });
    });

    this.listenTo(TCAS.vent, 'form:change:date', function(date) {
      if(!(date instanceof Date)) {
        return;
      }
      date.setHours(0,0,0,0);
      if(date.getTime() ===
        that.ui.calendarWidget.datepicker('getDate').getTime()) {
        return;
      } else {
        that.ui.calendarWidget.datepicker('setDate', date);
      }
    });

    this.listenTo(TCAS.vent, 'select:timetrack', function(entry) {
      that.timeTrackList.currentView.collection.each(function(item) {
        if(item.get('id') !== entry.get('id')) {
          item.set('checked', false);
        }
      });
      that.timetrackCreate.show(new TimeTrackCreate({entry: entry}));
    });

    this.listenTo(TCAS.vent, 'deselect:timetrack', function() {
      that.timetrackCreate.show(new TimeTrackCreate());
    });

    this.listenTo(TCAS.vent, 'save:timetrack', function(entry) {
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'Timetrack entry was created successfully.',
          type: 'success'
        }));
      that.timeTrackList.currentView.collection.add(entry);
      that.timeTrackList.currentView.render();
      that.timetrackCreate.show(new TimeTrackCreate());

      var date = new Date(entry.get('start'));
      date.setHours(0,0,0,0);
      that.timetrackCreate.currentView.ui.date.datepicker('setDate', date);
      that.ui.calendarWidget.datepicker('setDate', date);
    });

    this.listenTo(TCAS.vent, 'fail:timetrack', function() {
      that.ui.alertsArea.html(
        alertTemplate({
          strong: 'Errror!',
          message: 'Timetrack entry could not be saved.',
          type: 'danger'
        }));
    });

    var d = new Date();
    d.setHours(0,0,0,0);
    this.ui.calendarWidget.datepicker('setDate', d);

    this.listenTo(this.timeTrackList.currentView.collection, 'change',
      function() {
        var totalTime = that.getTotalHours(that);
        that.ui.timetrackTotal.text('Total time for date: ' + totalTime);
    });

  },
  getTotalHours: function(context) {
    var totalTime = 0;
    context.timeTrackList.currentView.collection.each(function(entry) {
      function timeDiff(start, end) {
        if(!start || !end) {
          return 0;
        }
        var dateStart = moment(start);
        var dateEnd = moment(end);
        return dateEnd.diff(dateStart);
      }
      totalTime += timeDiff(entry.get('start'), entry.get('end'));
    });
    return moment.utc(totalTime).format('HH:mm');
  }
});

module.exports = TimeTrackMainView;