// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "          <div class=\"form-group\">\n            <label class=\"control-label col-sm-3\">Case</label>\n            <div class=\"col-sm-9\">\n              <p class=\"form-control-static\">\n                <a href=\""
    + alias3((helpers.caseUrl || (depth0 && depth0.caseUrl) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"caseUrl","hash":{},"data":data}))
    + "\" class=\"popout-case\">"
    + alias3((helpers.caseField || (depth0 && depth0.caseField) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),"ref",{"name":"caseField","hash":{},"data":data}))
    + "</a>\n              </p>\n            </div>\n          </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n    <div class=\"modal-header\">\n      <h4 class=\"modal-title\">Edit reminder</h4>\n    </div>\n\n    <form class=\"form-horizontal\">\n      <div class=\"modal-body\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"form-group\">\n          <label for=\"due-date\" class=\"control-label col-sm-3\">Due date</label>\n          <div class=\"col-sm-4\">\n            <input type=\"date\" id=\"due-date\" class=\"form-control input-sm due-date\" name=\"due_date\" value=\""
    + alias4(((helper = (helper = helpers.due_date || (depth0 != null ? depth0.due_date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"due_date","hash":{},"data":data}) : helper)))
    + "\" />\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <label for=\"message\" class=\"control-label col-sm-3\">Reminder</label>\n          <div class=\"col-sm-9\">\n            <input type=\"text\" class=\"form-control input-sm reminder-message\" name=\"message\" value=\""
    + alias4(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "\"/>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"modal-footer\">\n        <button type=\"submit\" class=\"btn btn-primary\">Save</button>\n        <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n      </div>\n    </form>\n  </div>\n</div>";
},"useData":true});
