'use strict';
var MainBodyLayout = require('../views/main-body-layout');
var TemplatesMain = require('../views/templates_repository/repository-main');
var CaseReportView = require('../views/reporting/report-casefile');
var TimetrackReportView = require('../views/reporting/timetrack/timetrack');
var TimetrackEditsReportView = require('../views/reporting/report-timetrack-edits');
var IndividualMonthlyReportView = require('../views/reporting/individual/individual-monthly');
var IndividualYearlyReportView = require('../views/reporting/individual/individual-yearly');
var CompanyActivityReportView = require('../views/reporting/company/company-activity');
var CompanyRevenueReportView = require('../views/reporting/company/company-revenue');
var CommercialTrackingReportView = require('../views/reporting/commercial/commercial');
var PipelineReportView = require('../views/reporting/pipeline/pipeline');
var ReportAuthenticationLogView = require('../views/reporting/report-authenticationlog');

var ReportingController = Backbone.Marionette.Controller.extend({
  initialize: function(options) {
    this.app = options.app;
  },
  appRoutes: {
    'templates/admin': 'templatesAdmin',
    templates: 'templatesMain',
    'reporting/cases/individual/monthly(/)': 'individualMonthlyReport',
    'reporting/cases/individual/yearly(/)': 'individualYearlyReport',
    'reporting/cases/company/activity(/)': 'companyActivityReport',
    'reporting/cases/company/revenue(/)': 'companyRevenueReport',
    'reporting/cases/pipeline': 'pipelineReport',
    'reporting/cases(/)': 'reportCases',
    'reporting/commercial-tracking(/)': 'commercialTrackingReport',
    'reporting/timetrack-edits(/)': 'reportTimetrackEdits',
    'reporting/authentication(/)': 'reportAuthentication',
  },
  templatesMain: function() {
    if (!TCAS.session.hasPerm('view_templates')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'The Templates Hub' });
    app.mainBody.show(mainBodyLayout);

    var templatesMain = new TemplatesMain({
      showCreate: false,
    });
    mainBodyLayout.content.show(templatesMain);
    document.title = 'The Templates Hub';
  },
  templatesAdmin: function() {
    if (!TCAS.session.hasPerm('view_templates')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'The Templates Hub' });
    app.mainBody.show(mainBodyLayout);

    var templatesMain = new TemplatesMain({
      showCreate: true,
    });
    mainBodyLayout.content.show(templatesMain);
    document.title = 'The Templates Hub';
  },
  individualMonthlyReport: function() {
    var that = this;

    if (!TCAS.session.hasPerm('view_individual_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    if (TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderIndividualMonthlyReport);
    } else {
      console.log('not waiting');
      that.renderIndividualMonthlyReport();
    }
  },
  individualYearlyReport: function() {
    var that = this;

    if (!TCAS.session.hasPerm('view_individual_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    if (TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderIndividualYearlyReport);
    } else {
      console.log('not waiting');
      that.renderIndividualYearlyReport();
    }
  },
  companyActivityReport: function() {
    var that = this;

    if (!TCAS.session.hasPerm('view_company_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    if (TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderCompanyActivityReport);
    } else {
      console.log('not waiting');
      that.renderCompanyActivityReport();
    }
  },
  companyRevenueReport: function() {
    var that = this;

    if (!TCAS.session.hasPerm('view_company_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    if (TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderCompanyRevenueReport);
    } else {
      console.log('not waiting');
      that.renderCompanyRevenueReport();
    }
  },
  pipelineReport: function() {
    var that = this;

    if (!TCAS.session.hasPerm('view_pipeline_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    if (TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderPipelineReport);
    } else {
      console.log('not waiting');
      that.renderPipelineReport();
    }
  },
  reportCases: function() {
    if (!TCAS.session.hasPerm('view_case_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var that = this;
    if (TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderReportCases);
    } else {
      console.log('not waiting');
      that.renderReportCases();
    }
  },
  commercialTrackingReport: function() {
    if (!TCAS.session.hasPerm('view_commercial_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }
    var that = this;
    if (TCAS.users.length === 0) {
      that.listenToOnce(TCAS.users, 'sync', that.renderCommercialTrackingReport);
    } else {
      this.renderCommercialTrackingReport();
    }
  },
  reportTimetrackEdits: function() {
    if (!TCAS.session.hasPerm('view_timetrack_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var that = this;
    if (TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderReportTimetrackEdits);
    } else {
      console.log('no waiting');
      that.renderReportTimetrackEdits();
    }
  },
  reportAuthentication: function() {
    if (!TCAS.session.hasPerm('view_authenticationlog')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var that = this;
    that.renderReportAuthenticationLog();
  },
  renderReportCases: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'Case Reporting' });
    app.mainBody.show(mainBodyLayout);
    var caseReport = new CaseReportView();
    mainBodyLayout.content.show(caseReport);
    document.title = 'Case Report';
  },
  renderReportTimetrackEdits: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'Timetrack Reporting' });
    app.mainBody.show(mainBodyLayout);
    var timetrackEditsReport = new TimetrackEditsReportView();
    mainBodyLayout.content.show(timetrackEditsReport);
    document.title = 'TimeTrack Edits Report';
  },
  renderReportAuthenticationLog: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'Authentication Log' });
    app.mainBody.show(mainBodyLayout);
    var authenticationReport = new ReportAuthenticationLogView();
    mainBodyLayout.content.show(authenticationReport);
    document.title = 'Authentication Log';
  },
  renderIndividualMonthlyReport: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({
      title: 'Individual Cases Monthly Report',
    });
    app.mainBody.show(mainBodyLayout);
    var individualMonthlyReport = new IndividualMonthlyReportView({
      categories: app.categories,
    });
    mainBodyLayout.content.show(individualMonthlyReport);
    document.title = 'Individual Cases Monthly Report';
  },
  renderIndividualYearlyReport: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({
      title: 'Individual Cases Yearly Report',
    });
    app.mainBody.show(mainBodyLayout);
    var individualYearlyReport = new IndividualYearlyReportView({
      categories: app.categories,
    });
    mainBodyLayout.content.show(individualYearlyReport);
    document.title = 'Individual Cases Yearly Report';
  },
  renderCompanyActivityReport: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({
      title: 'Company Activity Report',
    });
    app.mainBody.show(mainBodyLayout);
    var companyActivityReport = new CompanyActivityReportView({
      categories: app.categories,
      teamAllocations: app.teamAllocations,
      users: app.users,
    });
    mainBodyLayout.content.show(companyActivityReport);
    document.title = 'Company Activity Report';
  },
  renderCompanyRevenueReport: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({
      title: 'Company Revenue Report',
    });
    app.mainBody.show(mainBodyLayout);
    var companyRevenueReport = new CompanyRevenueReportView({
      categories: app.categories,
      teamAllocations: app.teamAllocations,
      users: app.users,
    });
    mainBodyLayout.content.show(companyRevenueReport);
    document.title = 'Company Revenue Report';
  },
  renderPipelineReport: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({
      title: 'Pipeline Report',
    });
    app.mainBody.show(mainBodyLayout);
    var pipelineReport = new PipelineReportView({
      categories: app.categories,
      users: app.users,
      teamAllocations: app.teamAllocations,
    });
    mainBodyLayout.content.show(pipelineReport);
    document.title = 'Pipeline Report';
  },
  renderCommercialTrackingReport: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({
      title: 'Commercial Relationship Tracking Report',
    });
    app.mainBody.show(mainBodyLayout);
    var commercialReport = new CommercialTrackingReportView({
      categories: app.categories,
      users: app.users,
    });
    mainBodyLayout.content.show(commercialReport);
    document.title = 'Commercial Relationship Tracking Report';
  },
});

module.exports = ReportingController;
