'use strict';
var template = require('../../templates/teams/team-members-row.hbs');
var templateInactive = require('../../templates/teams/team-members-inactive-row.hbs');
var moment = require('moment');
var User = require('../../models/users/user');
var ConfirmModal = require('../confirm-modal');

var TeamMembersRowView = Backbone.Marionette.ItemView.extend({
  getTemplate: function() {
    if (this.model.get('is_active')) {
      return template;
    } else {
      return templateInactive;
    }
  },
  tagName: 'tr',
  model: User,
  ui: {
    'saveMember': '#save',
    'saveDates': '.save-dates',
    'removeMember': '#remove',
    'group': '#group',
    'supervisor': '#supervisor',
    'startDate': '.start-date',
    'endDate': '.end-date'
  },
  events: {
    'click @ui.saveMember': 'saveTeamMember',
    'click @ui.removeMember': 'removeTeamMember',
    'click @ui.saveDates': 'saveDates'
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.roles = [];
    _(User.GROUP_NAMES).each(function(name, index){
      if(name === User.GROUP_NAMES[User.GROUPS.ADMIN]) {
        return;
      }
      if (_(User.CASE_WORKER_GROUPS).contains(index)) {
        return data.roles.push({id: index, name: name});
      }
    });

    return data;
  },
  saveTeamMember: function() {
    var that = this;
    var supervisor = this.ui.supervisor.val();
    var group = parseInt(this.ui.group.val());
    var user = parseInt(this.model.get('user'));

    if (group === User.GROUPS.TEAM_LEADER) {
      if (this.model.get('group') === User.GROUPS.TEAM_LEADER) {
        that.saveUser(user, group, supervisor);
      } else {
        // check if there is a team leader for the team already
        if (this.options.team.get('team_leader') !== null) {
          var confirmModal = new ConfirmModal({
            title: 'You are setting a Team Member',
            body: 'You are setting a new Team Leader for this team. <br><br>' +
            'If you continue, the existing Team Leader will be demoted to ' +
            'Second Leader and the selected user will become the new Team ' +
            'Leader.' +
            '<br><br>Are you sure you want to continue?'
          });
          this.listenTo(confirmModal, 'confirmYes', function() {
            that.saveUser(user, group, supervisor);
          });
          this.listenTo(confirmModal, 'confirmNo', function() {
            that.render();
          });
          confirmModal.render();
        } else {
          var checkModal = new ConfirmModal({
            title: 'You are setting a Team Member',
            body: 'You are setting a new Team Leader for this team. <br><br>' +
            'Are you sure you want to continue?'
          });
          this.listenTo(checkModal, 'confirmYes', function() {
            that.saveUser(user, group, supervisor);
          });
          this.listenTo(checkModal, 'confirmNo', function() {
            that.render();
          });
          checkModal.render();
        }
      }
    } else {
      that.saveUser(user, group, supervisor);
    }
  },
  saveUser: function(user, group, supervisor) {
    if (this.model.get('supervisor') !== parseInt(supervisor, 10) ||
        this.model.get('group') !== parseInt(group, 10)) {
      // set this team allocation inactive and create a new one with new supervisor
      this.model.save({ 'is_active': false }, { wait: true });
      this.trigger('add:teamallocation', user, supervisor, group);
    }
  },
  saveDates: function() {
    var that = this;
    this.ui.saveDates.button('loading');
    var startDate = this.model.get('start_date');
    if (this.ui.startDate.length) {
      startDate = this.ui.startDate.datepicker('getUTCDate');
    }

    var endDate = this.ui.endDate.datepicker('getUTCDate');

    this.model.save({
      'start_date': moment(startDate).format('YYYY-MM-DD'),
      'end_date': moment(endDate).format('YYYY-MM-DD')
    }, {
      wait: true,
      success: function() {
        that.ui.saveDates.button('reset');
      }
    });
  },
  removeTeamMember: function() {
    var that = this;
    if (this.model.get('group') === User.GROUPS.TEAM_LEADER) {
      var confirmModal = new ConfirmModal({
        title: 'You are removing a Team Leader',
        body: 'You are removing the Team Leader for this team. ' +
        '<br><br>Are you sure you want to continue?'
      });
      that.listenTo(confirmModal, 'confirmYes', function() {
        that.removeUser(that);
      });
      confirmModal.render();
    } else {
      that.removeUser(that);
    }
  },
  removeUser: function(that) {
    this.model.save({ 'is_active': false }).then(function(){
      TCAS.vent.trigger('member:removed');
    });
  },
  onRender: function() {
    var that = this;

    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      return s;
    }

    var caseWorkersUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.supervisor.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseWorkersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
      initSelection: function(element, callback) {
        var user = new User({
          id: that.model.get('supervisor')
        });
        user.fetch().then(function() {
          callback(user.toJSON());
        });

      }
    });

    if (!this.model.get('is_active')) {
      this.ui.startDate.datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy'
      });
    }
    this.ui.endDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });
  }
});

module.exports = TeamMembersRowView;
