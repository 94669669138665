// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td>\n    <input type=\"text\" class=\"form-control name\" name=\"financial_service_name\" value=\""
    + alias4(((helper = (helper = helpers.financial_service_name || (depth0 != null ? depth0.financial_service_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"financial_service_name","hash":{},"data":data}) : helper)))
    + "\"\n        "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isCustom : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n</td>\n<td>\n    <input type=\"checkbox\" class=\"not-applicable\" name=\"is_not_applicable\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_not_applicable : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n</td>\n<td>\n    <div class=\"form-group\">\n        <input type=\"text\" class=\"form-control input-sm date-sent\" name=\"date\" placeholder=\"DD/MM/YYYY\"\n            value=\""
    + alias4((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n    </div>\n</td>\n<td>\n    <input type=\"checkbox\" class=\"is-responded\" name=\"is_enquiry_responded\"\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_enquiry_responded : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n</td>\n<td>\n    <textarea class=\"form-control notes\" name=\"notes\" placeholder=\"Notes...\" rows=\"1\">"
    + alias4(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notes","hash":{},"data":data}) : helper)))
    + "</textarea>\n</td>\n<td>\n    <input type=\"checkbox\" class=\"is-complete\" name=\"is_enquiry_complete\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_enquiry_complete : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n</td>\n<td class=\"buttons\">\n    <button type=\"button\" class=\"btn btn-xs btn-primary save\" data-loading-text=\"Saving...\">Save</button>\n</td>";
},"useData":true});
