'use strict';
var template = require('../../templates/users/user-create.hbs');
var UserDetailsView = require('./user-create-details');
var UserCaseloadView = require('./user-create-caseload');
var UserTargetDetailsView = require('./user-target-details');

var CreateUserView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    userDetails: '#user-details',
    userCaseload: '#user-caseload',
    userTarget: '#user-target'
  },
  onShow: function() {
    var that = this;

    this.userDetails.show(new UserDetailsView(that.options));
    this.userCaseload.show(new UserCaseloadView(that.options));
    if (TCAS.session.hasPerm('add_usertarget')) {
      this.userTarget.show(new UserTargetDetailsView({
        userTargets: this.getOption('userTargets'),
        user: this.getOption('user')
      }));
    }
  }
});

module.exports = CreateUserView;
