// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.content_type || (depth0 != null ? depth0.content_type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content_type","hash":{},"data":data}) : helper)))
    + " | "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"row\">\n    <div class=\"col-md-5\">\n        <label>Available Permissions</label>\n        <select class=\"form-control\" id=\"available-permissions\" size=\"15\" multiple>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.availablePermissions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n        <strong><small>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.availablePermissions : depth0)) != null ? stack1.length : stack1), depth0))
    + " permission(s)</small></strong>\n    </div>\n\n    <div class=\"col-md-2 btn-group-vertical\" id=\"perm-buttons-group\">\n        <button class=\"btn btn-default\" id=\"add-perm\"> >> </button>\n        <button class=\"btn btn-default\" id=\"remove-perm\"> << </button>\n    </div>\n\n    <div class=\"col-md-5\">\n        <label>Group Permissions</label>\n        <select class=\"form-control\" id=\"group-permissions\" size=\"15\" multiple>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.groupPermissions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n        <strong><small>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.groupPermissions : depth0)) != null ? stack1.length : stack1), depth0))
    + " permission(s)</small></strong>\n    </div>\n</div>\n<button class=\"btn btn-success pull-right\" id=\"save\">Save Changes</button>";
},"useData":true});
