'use strict';

var template = require('../../templates/survey/thanks.hbs');

var SurveyThanksView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'survey-thanks'
});

module.exports = SurveyThanksView;
