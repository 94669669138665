// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <li role=\"presentation\"><a href=\"#user-target\" aria-controls=\"messages\" role=\"tab\" data-toggle=\"tab\">Targets</a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<ul class=\"nav nav-tabs\" role=\"tablist\">\n  <li role=\"presentation\" class=\"active\"><a href=\"#user-details\" aria-controls=\"user-details\" role=\"tab\" data-toggle=\"tab\">Details</a></li>\n  <li role=\"presentation\"><a href=\"#user-caseload\" aria-controls=\"profile\" role=\"tab\" data-toggle=\"tab\">Caseload</a></li>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"add_usertarget",{"name":"hasPerm","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n\n<div class=\"tab-content\">\n  <div role=\"tabpanel\" class=\"tab-pane active\" id=\"user-details\"></div>\n  <div role=\"tabpanel\" class=\"tab-pane\" id=\"user-caseload\"></div>\n  <div role=\"tabpanel\" class=\"tab-pane\" id=\"user-target\"></div>\n</div>";
},"useData":true});
