'use strict';
var ReminderRowView = require('./reminder-row');
var Reminder = require('../../models/cases/reminder');
var CaseManagerSelectView = require('../allocations/casemanager-select');
var template = require('../../templates/cases/reminder-list.hbs');

//jshint camelcase:false
var RemindersTableView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    dueDate: 'input[name="due_date"]',
    message: 'input[name="message"]'
  },
  childView: ReminderRowView,
  childViewContainer: 'tbody.reminder-rows',
  childViewOptions: function(){
    return {
      showCasefile: this.getOption('showCasefile'),
      showUserColumn: this.getOption('casefile')
    };
  },
  behaviors: {
    SubmitForm: {
      submitHandler: 'saveReminder'
    },
    Validate: {
      validateOptions: {
        rules: {
          message: 'required',
          due_date: 'required'
        }
      }
    }
  },
  showAddForm: function(show) {
    this.options.showAddForm = show;
    this.render();
    this.ui.message.focus();
  },
  saveReminder: function(data){
    var that = this;
    if (this.getOption('casefile')) {
      data.case = this.getOption('casefile').get('id');

      if (this.faoSelect) {
        data.fao = this.faoSelect.getSelectedCaseManager();
      } else {
        data.fao = this.getOption('casefile').getCurrentAllocation()
          .get('case_manager');
      }
    } else {
      data.fao = this.getOption('userId');
    }

    var date = this.ui.dueDate.val().split('/');
    data.due_date = date[2] + '-' + date[1] + '-' + date[0];
    data.owner = TCAS.session.get('id');
    data.status = Reminder.STATUSES.PENDING;

    this.collection.create(data, {
      wait: true,
      success: function(reminder) {
        that.ui.message.val('');
        that.trigger('reminder:add', reminder);
      }
    });
  },
  onRender: function(){
    this.ui.dueDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });

    if (this.getOption('casefile')) {
      var currentAllocation = this.getOption('casefile').getCurrentAllocation();
      this.faoRegion = new Backbone.Marionette.Region({
        el: this.$('.create-fao-select-container')
      });
      this.faoSelect = new CaseManagerSelectView({
        user: currentAllocation ? currentAllocation.get('case_manager') : null
      });
      this.faoRegion.show(this.faoSelect);
    }
  },
  serializeData: function(){
    var data = {today: new Date()};
    data.showCasefile = this.getOption('showCasefile');
    data.showAddForm = this.getOption('showAddForm');
    data.showUserColumn = this.getOption('casefile');

    return data;
  }
});

module.exports = RemindersTableView;