'use strict';

var template = require('../../templates/websites/website-preview.hbs');

var WebsitePreviewView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'website-preview col-md-6 col-lg-4'
});

module.exports = WebsitePreviewView;
