'use strict';

var Dropzone = require('dropzone');

var template = require('../../templates/cases/casefile-detail-accounts.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var MessageListView = require('../messages/message-list');
var FileListView = require('./file-list');
var CostListView = require('../costs/cost-list');
var ClientPaymentListView = require('../clients/client-payment-list');
var BookingRecordListView = require('../financials/bookingrecord-list');
var ForecastingRecordListView = require('../financials/forecastingrecord-list');
var PledgingRecordListView = require('../financials/pledgingrecord-list');
var CaseManagerFilterView = require('../allocations/casemanager-filter');
var CaseProspects = require('../../models/cases/caseprospects');
var Cost = require('../../models/cases/cost');
var User = require('../../models/users/user');

var CaseFileDetailAccountsView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    messageListRegion: '.message-list-container',
    fileListRegion: '.file-list-container',
    costsOutRegion: '.costs-out-container',
    costsIncomeRegion: '.costs-income-container',
    costsDisbursementRegion: '.costs-disbursement-container',
    bookingHistoryRegion: '.booking-history-container',
    forecastingRegion: '.forecasting-container',
    pledgingRegion: '.pledging-container',
    financialsCaseManagerFilterRegion: '.filter-casemanager-container',
    clientPaymentsRegion: '.client-payments-container'
  },
  ui: {
    filesPanel: '#files',
    costsPanel: '#costs',
    financialsPanel: '#financials',
    dropzonePreviews: '.dropzone-previews',
    addFile: '.add-file',
    messagesPanelTitle: '.casefile-notes .panel-title',
    filesPanelTitle: '.casefile-files .files-title',
  },
  onShow: function() {
    var that = this;
    var lastViewed = this.getOption('lastViewed');
    var bookingRecords = this.model.get('bookingRecords');
    var forecastingRecords = this.model.get('forecastingRecords');
    var pledgingRecords = this.model.get('pledgingRecords');
    function financialComparator(record) {
      return new Date(record.get('date')).getTime() * -1;
    }
    bookingRecords.comparator = financialComparator;
    forecastingRecords.comparator = financialComparator;
    pledgingRecords.comparator = financialComparator;
    var clientPayments = that.model.get('clientPayments');
    var caseProspects = new CaseProspects([], {
      urlRoot: '/api/caseprospects/?casefile=' + this.model.get('id')
    });
    caseProspects.fetch().then(function() {
      var client = caseProspects.find(function(caseProspect) {
        return caseProspect.get('user') ? caseProspect.get('user').get('id') == TCAS.session.get('id') : false;
      });
      that.client = client;

      // fetch and render messages
      that.model.fetchMessages().then(function(){
        var messages = that.model.get('messages');

        // work out how many new messages since last visit to show as badge in messages accordion header
        var newMessageCount = 0;
        messages.each(function(message) {
          var created = new Date(message.get('created'));
          if (message.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
            newMessageCount += 1;
          }
        });
        if (newMessageCount > 0) {
          that.ui.messagesPanelTitle.append(' <span class="badge badge-danger" title="New messages since last viewed">' + newMessageCount + '</span>');
          that.ui.messagesPanelTitle.find('.badge').tooltip();
        }

        var messageListView = new MessageListView({
          collection: messages,
          casefile: that.model,
          clientId: client.get('id'),
          highlightMessage: that.getOption('highlightMessage') || false,
          filterFunction: function(message) {
            return message.get('fao_clients').indexOf(client.get('id')) !== -1;
          },
          lastViewed: lastViewed
        });
        that.messageListRegion.show(messageListView);
      });
    });

    // COSTS PANEL
    if (TCAS.session.hasPerm('view_cost')) {
      this.ui.costsPanel.on('show.bs.collapse', function (e) {

        if (e.namespace !== 'bs.collapse') {
          return false;
        }

        that.model.fetchCosts().then(function() {
          //only include active costs
          var costs = that.model.get('costs');
          var inactiveCosts = costs.where({'is_active': false});
          _.each(inactiveCosts, function(cost){
            if (!cost.get('is_active')) {
              costs.remove(cost);
            }
          });
        });
      });
      var costs = that.model.get('costs');
      var outgoingCostsView = new CostListView({
        collection: costs,
        casefile: this.model,
        filterType: Cost.TYPES.cost,
        clients: caseProspects,
        dateColumn: 'Date Paid',
        payeeColumn: 'Paid To',
        payeePlaceholder: 'Paid to...'
      });
      var incomingCostsView = new CostListView({
        collection: costs,
        casefile: this.model,
        filterType: Cost.TYPES.income,
        clients: caseProspects,
        dateColumn: 'Date Received',
        payeeColumn: 'Received From',
        payeePlaceholder: 'Received from...'
      });
      var disbursementCostsView = new CostListView({
        collection: costs,
        casefile: this.model,
        filterType: Cost.TYPES.disbursement,
        clients: caseProspects,
        dateColumn: 'Date Paid',
        payeeColumn: 'Paid To',
        payeePlaceholder: 'Paid to...'
      });
      this.costsOutRegion.show(outgoingCostsView);
      this.costsIncomeRegion.show(incomingCostsView);
      this.costsDisbursementRegion.show(disbursementCostsView);
    }

    function onSuccessRemoveInactive(collection) {
      //only include active costs
      var inactive = collection.where({'is_active': false});
      _.each(inactive, function(item){
        if (!item.get('is_active')) {
          collection.remove(item);
        }
      });
    }

    if (TCAS.session.hasPerm('view_clientpayment')) {

      this.ui.financialsPanel.on('show.bs.collapse', function(e) {
        // check the event target, date input field popups also trigger this
        if (e.target.id === 'financials') {
          // bookingRecords, forecastingRecords, pledgingRecords and
          // clientPayments all require fetching and removing !is_active
          bookingRecords.fetch({
            success: onSuccessRemoveInactive
          });
          forecastingRecords.fetch({
            success: onSuccessRemoveInactive
          });
          pledgingRecords.fetch({
            success: onSuccessRemoveInactive
          });
          clientPayments.fetch({
            success: onSuccessRemoveInactive
          });
        }
      });

      var bookingRecordListView = new BookingRecordListView({
        collection: bookingRecords,
        casefile: this.model
      });
      this.bookingHistoryRegion.show(bookingRecordListView);
      var forecastingRecordListView = new ForecastingRecordListView({
        collection: forecastingRecords,
        casefile: this.model
      });
      this.forecastingRegion.show(forecastingRecordListView);
      var pledgingRecordListView = new PledgingRecordListView({
        collection: pledgingRecords,
        casefile: this.model
      });
      this.pledgingRegion.show(pledgingRecordListView);
      var casemanagerFilterView = new CaseManagerFilterView();
      this.listenTo(casemanagerFilterView, 'casemanagerSelected',
        function (casemanager) {
          // jshint ignore:start
          bookingRecords.setFilters({ case_manager: casemanager });
          bookingRecords.fetch({ success: onSuccessRemoveInactive });
          forecastingRecords.setFilters({ case_manager: casemanager });
          forecastingRecords.fetch({ success: onSuccessRemoveInactive });
          pledgingRecords.setFilters({ case_manager: casemanager });
          pledgingRecords.fetch({ success: onSuccessRemoveInactive });
          // jshint ignore:end
        }
      );
      this.financialsCaseManagerFilterRegion.show(casemanagerFilterView);

      var clientPaymentsView = new ClientPaymentListView({
        collection: clientPayments,
        clients: caseProspects,
        casefile: this.model
      });
      this.clientPaymentsRegion.show(clientPaymentsView);
    }

    var fileListView = new FileListView({
      collection: this.model.get('files'),
      casefile: this.model,
      clients: caseProspects,
      isClient: true,
      lastViewed: lastViewed
    });
    this.listenTo(fileListView, 'fileDrag', function () {
      this.ui.clientsPanel.collapse('show');
    });
    this.fileListRegion.show(fileListView);
    this.model.fetchFiles().then(function() {
      // work out how many new files since last visit to show as badge in files accordion header
      var newFilesCount = 0;
      that.model.get('files').each(function(file) {
        var created = new Date(file.get('created'));
        if (file.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
          newFilesCount += 1;
        }
      });
      if (newFilesCount > 0) {
        that.ui.filesPanelTitle.find('.badge').remove();
        that.ui.filesPanelTitle.append(' <span class="badge badge-danger" title="New files since last viewed">' + newFilesCount + '</span>');
        that.ui.filesPanelTitle.find('.badge').tooltip();
      }
    });
    this.listenTo(caseProspects, 'sync', function() {
      that.fileListRegion.show(fileListView);
    });

    //configure dropzone
    this.dropzone = new Dropzone(this.ui.addFile[0], {
      url: '/api/cases/' + this.model.get('id') + '/files/',
      headers: {'X-CSRFToken': TCAS.csrfToken},
      previewsContainer: this.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      maxFilesize: 500,
      acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,.ged',
      clickable: this.ui.addFile[0],
      accept: function(file, done){
        var alphaNumericPattern = new RegExp(
          '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
        );
        // check for only alphanumeric characters
        if (alphaNumericPattern.test(file.name)) {
          done();
        } else {
          done('This file has unexpected characters, ' +
            'file names must use the correct file naming structure and ' +
            'only contain characters consisting of: ' +
            'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
            'apostrophes, commas or spaces.');
        }
      }
    });
    this.dropzone.on('success', function(file){
      that.model.get('files').fetch().then(function(){
        that.$(file.previewElement).remove();
        that.fileListRegion.currentView.render();
      });
    });
    this.dropzone.on('error', function(file){
      that.$(file.previewElement).find('button').removeClass('hide');
    });
    this.dropzone.on('uploadprogress', function(file, progress){
      console.log('file upload progress: '+progress);
    });
    this.dropzone.on('sending', function(file, xhr, formData) {
      // Show the total progress bar when upload starts
      that.$(file.previewElement).find('.progress').removeClass('hide');
      formData.append('is_casefile_direct_upload', 1);
    });
    this.dropzone.on('dragleave', function(){
      that.ui.addFile.removeClass('list-group-item-success');
    });
    this.dropzone.on('drop', function(){
      that.ui.addFile.removeClass('list-group-item-success');
    });

    // listen for messsages show event to calculate message sizes
    this.$('#client-messages').on('shown.bs.collapse', function () {
      that.messageListRegion.currentView.checkMessageHeight();
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.caseManagerId = this.model.getCurrentAllocation().get('case_manager');
    return data;
  }
});

module.exports = CaseFileDetailAccountsView;
