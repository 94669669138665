'use strict';

var template = require('../templates/modal-confirm.hbs');

var ConfirmModal = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'modal fade',
  events: {
    'click .confirm-yes': 'confirmYes',
    'click .confirm-no': 'confirmNo'
  },
  initialize: function(){
    this.$el.attr('tabindex', '-1');
    this.$el.attr('role', 'dialog');
    this.$el.attr('aria-labelledby', 'confirm-modal-label');
    this.$el.attr('aria-hidden', 'true');
  },
  confirmYes: function(){
    this.trigger('confirmYes');
  },
  confirmNo: function(){
    this.trigger('confirmNo');
  },
  serializeData: function(){
    return this.options;
  },
  onRender: function(){
    this.$el.appendTo('body');
    this.$el.modal({
      backdrop: 'static'
    });
  }
});

module.exports = ConfirmModal;