'use strict';
var TimetrackItemView = require('./timetrack-item');
var template = require('../../templates/timetrack/timetrack-list.hbs');
var TimetrackNoRowsView = require('./timetrack-no-rows');

var TimetrackListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childViewContainer: 'tbody',
  childView: TimetrackItemView,
  emptyView: TimetrackNoRowsView
});

module.exports = TimetrackListView;