'use strict';
var template = require('../../templates/cases/casefile-create.hbs');
var Asset = require('../../models/cases/asset');
var AssetValue = require('../../models/cases/assetvalue');
var Contact = require('../../models/users/contact');
var CaseFile = require('../../models/cases/casefile');
var CaseFiles = require('../../models/cases/casefiles');
var Allocation = require('../../models/cases/allocation');
var User = require('../../models/users/user');
var Category = require('../../models/cases/category');
var Contacts = require('../../models/users/contacts');
var CountriesSelectView = require('../countries-select');

var CreateCasefileView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    dateMissing: '#date_missing',
    dob: '#date_of_birth',
    dod: '#date_of_death',
    lastChecked: '#last_checked',
    allocate: '#allocate',
    category: '#category',
    form: '#create-casefile-form'
  },
  initialize: function() {
    var that = this;

    var allocated;
    if(this.model.get('allocations') &&
        this.model.get('allocations').length > 0) {
      allocated = this.model.get('allocations').findWhere({ is_active: true });
    }
    if(allocated !== undefined) {
      that.allocated = allocated.get('case_manager');
    }

    if(this.model.get('category')) {
      that.category = this.model.get('category');
    }

    this.create = this.options.method === 'create' ? true : false;
    this.edit = this.options.method === 'edit' ? true: false;

  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.currentUser = TCAS.session.toJSON();
    data.create = this.create;
    data.edit = this.edit;
    return data;
  },
  submitData: function(data) {
    //jshint camelcase:false
    // get widget values
    var missingDate = null;
    if (this.ui.dateMissing.val()) {
      missingDate = this.ui.dateMissing.val();
    }
    var dob = null;
    if (this.ui.dob.val()) {
      dob = this.ui.dob.val();
    }
    var dod = null;
    if (this.ui.dod.val()) {
      dod = this.ui.dod.val();
    }
    var lastChecked = null;
    if (this.ui.lastChecked.val()) {
      lastChecked = this.ui.lastChecked.val();
    }
    var allocate = this.ui.allocate.select2('val');
    var category = this.ui.category.select2('val');

    // get casefile items (asset, asset value, casefile, contacts
    var casefile = this.model;
    var asset = casefile.get('asset') ? casefile.get('asset') : new Asset();
    var contact = asset.getFirstAddress()
      ? asset.getFirstAddress() : new Contact();
    var assetValue = asset.getFirstValuation()
      ? asset.getFirstValuation() : new AssetValue();
    var allocation = casefile.getCurrentAllocation();

    asset.set({
      asset_nature: data.asset_nature,
      asset_number: data.asset_number,
      custodian_name: data.custodian_name,
      asset_holder_name: data.asset_holder_name,
      missing_date: missingDate,
      date_of_birth: dob,
      date_of_death: dod,
      last_checked: lastChecked
    });

    contact.set({
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      post_code: data.post_code,
      country: data.country
    });

    if (data.legacy_address) {
      contact.set('legacy_address', data.legacy_address);
    }

    assetValue.set({
      capital_value: data.capital_value,
      income_value: data.income_value
    });

    casefile.set({
      asset: asset,
      category: category,
      message: data.message,
      owner: TCAS.session.get('id'),
      creation_method: CaseFile.CREATED_BY_FORM
    });

    var new_allocation = null;
    if (allocation) {
      if (allocate !== allocation.get('id')) {
        new_allocation = new Allocation({
          case_manager: allocate,
          owner: TCAS.session.get('id')
        });
      }
    } else {
      if (allocate !== '') {
        new_allocation = new Allocation({
          case_manager: allocate,
          owner: TCAS.session.get('id')
        });
      }
    }

    if (this.create) {
      contact.save({}, {
        success: function() {

          asset.set('addresses', new Contacts([]));
          asset.get('addresses').push(contact);

          casefile.save({}, {
            success: function () {
              //asset and asset contact already saved as casefile
              assetValue.set('asset', casefile.get('asset').get('id'));
              assetValue.save({}, {
                error: function () {
                  console.log('error saving asset value');
                }
              });

              if (new_allocation) {
                new_allocation.set('case', casefile.get('id'));
                new_allocation.save({}, {
                  error: function () {
                    console.log('error saving allocation');
                  }
                });
              }
              TCAS.router.navigate(
                'cases/' + casefile.get('id'), {trigger: true});
            },
            error: function () {
              console.log('error saving casefile');
            }
          });
        },
        error: function() {
          console.log('error updating casefile');
        }
      });

    }

    if(this.edit) {
      contact.save({}, {
        success: function () {

          var d1 = asset.save({}, {
            error: function () {
              console.log('error updating asset');
            }
          });

          var d2 = casefile.save({
            ref: data.ref
          }, {
            error: function() {
              console.log('error updating casefile');
            }
          });

          var d3 = assetValue.save({}, {
            error: function () {
              console.log('error updating assetvalue');
            }
          });

          if (new_allocation) {
            new_allocation.set('case', casefile.get('id'));
            new_allocation.save({}, {
              error: function () {
                console.log('error saving allocation');
              }
            });
          }

          $.when(d1, d2 ,d3).done(function() {
            TCAS.router.navigate(
              'cases/' + casefile.get('id'), {trigger: true});
          });

        },
        error: function () {
          console.log('error updating casefile');
        }
      });
    }
    return false;
  },
  behaviors: {
    SubmitForm: {
      message: 'create casefile form',
      selector: '.create-casefile-form',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          ref: {
            required: true
          },
          category: {
            required: true
          },
          allocate: {
            required: true
          },
          asset_nature: {
            maxlength: 128
          },
          asset_number: {
            required: true,
            maxlength: 64,
            number: true
          },
          custodian_name: {
            required: true,
            maxlength: 64
          },
          asset_holder_name: {
            required: true,
            maxlength: 150
          },
          capital_value: {
            required: true,
            number: true
          },
          income_value: {
            required: true,
            number: true
          },
          address1: {
            maxlength: 50,
            required: true
          },
          address2: {
            maxlength: 50
          },
          city: {
            maxlength: 50
          },
          post_code: {
            maxlength: 15
          },
          country: {
            maxlength: 50,
            required: true
          }
        },
        messages: {
          category: {
            required: 'This field is required.'
          },
          allocate: {
            required: 'This field is required.'
          }
        },
        ignore: []
      },
      selector: '.create-casefile-form'
    }
  },
  onShow: function() {
    var that = this;

    function formatCategory(category, container, query){
      var s = category.code + ' (' + category.name + ')';
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });

      return s;
    }

    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
//      s = user.id;
      return s;
    }

    this.ui.category.select2({
      placeholder: 'Category name/code',
      minimumInputLength: 1,
      ajax: {
        url: '/api/categories/',
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatCategory,
      formatSelection: formatCategory,
      allowClear: true,
      initSelection: function(element, callback) {
        if(that.category !== undefined) {
          var category = new Category({id: that.category});
          category.fetch().then(function() {
            callback(category.toJSON());
          });
        }
      }
    }).on('change', function() { $(this).valid(); });

    var caseWorkersUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.allocate.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseWorkersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
      initSelection: function(element, callback) {
        if(that.allocated !== undefined) {
          var user = new User({
            id: that.allocated
          });
          user.fetch().then(function() {
            callback(user.toJSON());
          });
        }
      }
    }).on('change', function() { $(this).valid(); });

    if(this.edit) {
      that.ui.allocate.select2('val', 0);
      that.ui.category.select2('val', 0);
    }

    var asset = this.model.get('asset');
    var address = asset.getFirstAddress();
    var currentCountry = address ? address.get('country') : null;

    this.countriesRegion = new Backbone.Marionette.Region({
      el: this.$('.countries')
    });
    this.countriesRegion.show(new CountriesSelectView({
      countries: TCAS.countries,
      selectedCountry: currentCountry ? currentCountry : 'GBR',
      className: 'form-control input-sm'
    }));
  }
});

module.exports = CreateCasefileView;
