// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                <option value=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"panel panel-default\">\n    <div class=\"panel-heading\" role=\"tab\" id=\"new-category-heading\">\n        <a href=\"#new-category\" data-toggle=\"collapse\" aria-expanded=\"true\" aria-controls=\"new-category\">\n            <h4 class=\"panel-title\">\n                <i class=\"fa fa-plus\"/> Add a new Category\n            </h4>\n        </a>\n    </div>\n    <div id=\"new-category\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"new-category-heading\">\n        <div class=\"panel-body new-category\">\n            <form class=\"form-horizontal new-category-form\">\n\n                <div class=\"form-group\">\n                    <label class=\"control-label col-md-2\" for=\"category\">Category</label>\n                    <div class=\"col-md-2\">\n                        <input class=\"form-control\" name=\"category\"/>\n                    </div>\n                </div>\n\n                <div class=\"form-group\">\n                    <label class=\"control-label col-md-2\" for=\"description\">Description</label>\n                    <div class=\"col-md-2\">\n                        <input class=\"form-control\" name=\"description\"/>\n                    </div>\n                </div>\n\n                <div class=\"form-group\">\n                    <label class=\"control-label col-md-2\" for=\"limit\">Default Limit</label>\n                    <div class=\"col-md-2\">\n                        <input class=\"form-control\" name=\"limit\"/>\n                    </div>\n                </div>\n\n                <div class=\"form-group\">\n                    <label class=\"control-label col-md-2\" for=\"research\">Research Groups</label>\n                    <div class=\"col-md-7\">\n                        <select class=\"form-control research-select input-sm\" name=\"research\" multiple=\"multiple\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.researchFields : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </select>\n                    </div>\n                </div>\n\n                <div class=\"form-group\">\n                    <label class=\"control-label col-md-2\" for=\"milestones\">Milestones category group</label>\n                    <div class=\"col-md-2\">\n                        <select class=\"form-control category-group\" name=\"category_group\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categoryGroups : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </select>\n                    </div>\n                </div>\n\n                <div class=\"form-group\">\n                    <div class=\"col-md-2 col-md-offset-2\">\n                        <button class=\"btn btn-success save-category\">Save</button>\n                    </div>\n                </div>\n\n            </form>\n            <div class=\"alert-area\"></div>\n        </div>\n    </div>\n</div>\n\n<div class=\"category-list\"></div>";
},"useData":true});
