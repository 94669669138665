// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<td>\n    <div class=\"form-group\">\n        <input class=\"form-control category input-sm\" name=\"category\" value=\""
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + "\"/>\n        <span class=\"help-block category-help\"></span>\n    </div>\n</td>\n<td>\n    <div class=\"form-group\">\n        <input class=\"form-control description input-sm\" name=\"description\" value=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\"/>\n        <span class=\"help-block description-help\"></span>\n    </div>\n</td>\n<td>\n  "
    + alias4((helpers.categoryGroup || (depth0 && depth0.categoryGroup) || alias2).call(alias1,(depth0 != null ? depth0.category_group : depth0),{"name":"categoryGroup","hash":{},"data":data}))
    + "\n</td>\n<td>\n    <div class=\"form-group\">\n        <input class=\"form-control limit input-sm\" name=\"limit\" value=\""
    + alias4(((helper = (helper = helpers.default_limit || (depth0 != null ? depth0.default_limit : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"default_limit","hash":{},"data":data}) : helper)))
    + "\"/>\n        <span class=\"help-block limit-help\"></span>\n    </div>\n</td>\n<td>\n    <div class=\"form-group\">\n        <select class=\"form-control research-select input-sm\" name=\"research\" multiple=\"multiple\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.research_types : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n        <span class=\"help-block research-help\"></span>\n    </div>\n</td>\n<td>\n    <button class=\"btn btn-success btn-sm save\">Save</button>\n</td>";
},"useData":true});
