'use strict';
var template = require('../../templates/cases/casefile-allocate.hbs');
var Allocation = require('../../models/cases/allocation');
var CaseFileAllocationTableView = require('./casefile-allocate-list');
var CaseFiles = require('../../models/cases/casefiles');
var User = require('../../models/users/user');
var CaseLoadCollection = require('../../models/cases/caseload-collection');
var ConfirmModal = require('../confirm-modal');
var alertTemplate = require('../../templates/alert.hbs');
var CaseLoadListView = require('../caseload/caseload-list');
var Spinner = require('spin');

var CaseFileAllocateView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    allocate: '#allocate',
    allocateCases: '#allocate-cases',
    relCaseload: '#rel-caseload-title',
    fullCaseload: '#full-caseload-title',
    alertsArea: '.alerts-area',
    showAll: '.show-all-cases'
  },
  events: {
    'click @ui.allocateCases': 'allocateCaseFiles',
    'change @ui.allocate': 'loadManagerCaseload',
    'click @ui.showAll': 'showAllCases'
  },
  serializeData: function() {
    var that = this;
    var data = {};

    if(that.getOption('user')) {
      data.user = that.getOption('user');
    }
    return data;
  },
  showAllCases: function() {
    var that = this;


    var casefiles = new CaseFiles([], {
      urlRoot: '/api/cases/?status=0&ordering=-id&page_size=1000'
    });

    var spinner = new Spinner().spin(this.$el[0]);
    casefiles.fetch({
      success: function() {
        spinner.stop();
        that.listCases.empty();
        var caseFileAllocationTable = new CaseFileAllocationTableView({
          collection: casefiles
        });
        that.listCases.show(caseFileAllocationTable);

        that.listenTo(that.listCases.currentView, 'selected:item',
          that.loadRelevantCaseload);
      }
    });

  },
  onShow: function() {
    var that = this;
    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      return s;
    }

    var caseWorkersUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.allocate.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseWorkersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
      initSelection: function(element, callback) {
        if(that.getOption('user')) {
          var user = new User({
            id: that.getOption('user')
          });
          user.fetch().then(function() {
            var data = {id: element.val(), text: user.toJSON()};
            //callback(user.toJSON());
            console.log(user.toJSON());
            callback(user.toJSON());
          });
          that.loadManagerCaseload();
        }
      }
    });

  },
  allocateCaseFiles: function() {
    //jshint camelcase:false
    console.log('allocate casefiles');
    var caseFiles = this.listCases.currentView.collection.where({checked: true});
    var caseManager = this.ui.allocate.select2('val');
    var supervisor = TCAS.users.get(
      TCAS.users.get(caseManager).get('supervisor'));


    var that = this;
    _(caseFiles).each(function(caseFile) {

      var allocation = new Allocation({
        case: caseFile.get('id'),
        case_manager: caseManager,
        supervisor: supervisor,
        owner: TCAS.session.get('id')
      });

      allocation.save();
    });

    var successModal = new ConfirmModal({
      title: 'Cases allocated successfully!',
      body: 'Would you like to return to the cases masterlist?'
    });
    successModal.on('confirmYes', function() {
      TCAS.router.navigate('/cases/masterlist', {trigger: true});
    });
    successModal.on('confirmNo', function() {
      if(that.options.casefiles) {
        that.options.casefiles = false;
      }
      that.render();
      that.ui.alertsArea.html(alertTemplate({
        strong: 'Success!',
        message: 'Cases were allocated successfully.',
        type: 'success'
      }));

    });
    successModal.render();

  },
  onRender: function() {
    var that = this;
    this.listCases = new Backbone.Marionette.Region({
      el: this.$('#list-casefiles')[0]
    });

    this.fullCaseload = new Backbone.Marionette.Region({
      el: this.$('#user-full-caseload')[0]
    });

    this.relCaseload = new Backbone.Marionette.Region({
      el: this.$('#user-caseload')[0]
    });

    //this.successModal = new Backbone.Marionette.Region({
    //  el: this.$('.modal-entry')[0]
    //});

    var casefiles = null;

    if(this.getOption('casefiles')) {
      casefiles = that.getOption('casefiles');
      var caseFileAllocationTable = new CaseFileAllocationTableView({
        collection: new CaseFiles(casefiles)
      });
      that.listCases.show(caseFileAllocationTable);

      //that.loadManagerCaseload();
      //that.loadRelevantCaseload();

      that.listenTo(that.listCases.currentView, 'selected:item',
        that.loadRelevantCaseload);

    } else {
      casefiles = new CaseFiles([], {
        urlRoot: '/api/cases/?status=0&ordering=-id&page_size=40'
      });

      casefiles.fetch({
        success: function() {
          that.listCases.empty();
          var caseFileAllocationTable = new CaseFileAllocationTableView({
            collection: casefiles
          });
          that.listCases.show(caseFileAllocationTable);

          that.listenTo(that.listCases.currentView, 'selected:item',
            that.loadRelevantCaseload);

        }
      });
    }
  },
  loadManagerCaseload: function() {
    var manager = this.ui.allocate.select2('val');
    var that = this;
    var caseLoadCollection = new CaseLoadCollection([], {
      urlRoot: '/api/caseload/?user=' + manager
    });

    var user = TCAS.users.findWhere({id: parseInt(manager)});

    var fullName = user.get('first_name') + ' ' + user.get('last_name');
    this.ui.relCaseload.text(fullName + '\'s case load for the selected cases');
    this.ui.fullCaseload.text(fullName + '\'s full case load');

    caseLoadCollection.fetch({
      success: function() {
        var caseLoadList = new CaseLoadListView({
          collection: caseLoadCollection
        });
        that.fullCaseload.show(caseLoadList);
      }
    }).then(function() {
      that.loadRelevantCaseload();
    });

  },
  loadRelevantCaseload: function() {
    var manager = this.ui.allocate.select2('val');
    if (manager === '') { return; }

    var casefiles = this.listCases.currentView.collection.where(
      {checked: true});

    var relCaseload = new CaseLoadCollection([]);

    var u = {};
    var that = this;
    for(var i= 0, l=casefiles.length; i < l; ++i) {
      if(u.hasOwnProperty(casefiles[i].get('category'))) {
        u[casefiles[i].get('category')] += 1;
      } else {
        u[casefiles[i].get('category')] = 1;
      }

      relCaseload.add(
        that.fullCaseload.currentView.collection.findWhere(
          {category: casefiles[i].get('category')})
      );
    }

    for(var prop in u) {
      if (u.hasOwnProperty(prop)) {
        relCaseload.findWhere({category: parseInt(prop)})
          .set('allocate_count', parseInt(u[prop]));
      }
    }

    if (relCaseload.length === 0) {
      that.relCaseload.empty();
      return;
    }

    var relCaseLoadList = new CaseLoadListView({
      collection: relCaseload
    });

    that.relCaseload.show(relCaseLoadList);
    $('#rel-caseload').collapse('show');
  }

});

module.exports = CaseFileAllocateView;
