// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.start : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.start : stack1),"HHmm",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.end : stack1),"HHmm",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.note : stack1), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    return "hide";
},"13":function(container,depth0,helpers,partials,data) {
    return "Update";
},"15":function(container,depth0,helpers,partials,data) {
    return "Submit";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form class=\"create-timetrack-form\">\n    <fieldset>\n\n        <div class=\"form-group col-md-2\">\n            <label for=\"date\" class=\"control-label\">Date</label>\n            <input type=\"text\" class=\"form-control input-sm\" id=\"date\"\n                   placeholder=\"Today\" name=\"date\"\n                   "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.start : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        </div>\n\n        <div class=\"form-group col-md-2\">\n            <label for=\"start\" class=\"control-label\">Start</label>\n            <input type=\"text\" class=\"form-control input-sm\" id=\"start\" name=\"start\"\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.start : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        </div>\n\n        <div class=\"form-group col-md-2\">\n            <label for=\"finish\" class=\"control-label\">Finish</label>\n            <input type=\"text\" class=\"form-control input-sm\" id=\"finish\" name=\"finish\"\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.end : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        </div>\n\n        <div class=\"form-group col-md-2\">\n            <label for=\"hours\" class=\"control-label\">Hours</label>\n            <input type=\"text\" class=\"form-control input-sm\" id=\"hours\" name=\"hours\" readonly>\n        </div>\n\n        <div class=\"form-group col-md-3\">\n            <label for=\"activity\" class=\"control-label\">Activity</label>\n            <select class=\"form-control input-sm\" id=\"activity\" name=\"activity\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.activities : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        <div class=\"form-group col-md-4\">\n            <label for=\"case\" class=\"control-label\">Case</label>\n            <input type=\"text\" class=\"form-control input-sm\" id=\"case\" name=\"case\">\n        </div>\n\n        <div class=\"form-group col-md-3\">\n            <label for=\"task\" class=\"control-label\">Task</label>\n            <select class=\"form-control input-sm\" id=\"task\" name=\"task\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tasks : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        <div class=\"form-group col-md-12 no-margin\">\n            <label for=\"description\" class=\"control-label\">Description</label>\n            <textarea type=\"text\" rows=\"3\" class=\"form-control input-sm\" id=\"description\" name=\"description\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.note : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</textarea>\n        </div>\n        <div class=\"form-group col-md-12 case-message-checkbox-container "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.case_message : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n          <div class=\"checkbox\">\n            <label for=\"case-message-checkbox\">\n              <input type=\"checkbox\" id=\"case-message-checkbox\" name=\"is_case_message\" /> Also add as note to the case\n            </label>\n          </div>\n        </div>\n\n        <div class=\"col-md-12\">\n            <strong><small>Entries which are edited after 1 hour of being created will be logged.</small></strong>\n        </div>\n\n\n        <div class=\"form-group col-md-12\">\n          <button class=\"btn btn-success pull-right\" id=\"add-timetrack-entry\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.id : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "</button>\n        </div>\n\n    </fieldset>\n</form>";
},"useData":true});
