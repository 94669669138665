'use strict';
var template = require('../../templates/timetrack/timetrack-item.hbs');
var Timetrack = require('../../models/timetrack/timetrack');
var moment = require('moment');

var TimetrackItem = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  model: Timetrack,
  initialize: function() {
    this.model.set('checked', false);
  },
  events: {
    'click': 'select',
    'click .popout': 'popoutCase'
  },
  popoutCase: function(e){
    e.preventDefault();
    TCAS.commands.execute('popoutCase', this.model.get('case'));

    return false;
  },
  serializeData: function() {
    var data = this.model.toJSON();

    data.hours = this.changeTime(
      this.model.get('start'),
      this.model.get('end'));

    return data;
  },
  select: function() {
    this.model.set('checked', !this.model.get('checked'));

  },
  onRender: function() {
    var that = this;
    this.model.on('change:checked', function() {
      if(that.model.get('checked')) {
        TCAS.vent.trigger('select:timetrack', that.model);
        that.$el.addClass('success');
      } else {
        TCAS.vent.trigger('deselect:timetrack');
        if(that.$el.hasClass('success')) {
          that.$el.removeClass('success');
        }
      }
    });
  },
  changeTime: function(start, end) {
    if(!start || !end) {
      return 'error';
    }
    var dateStart = moment(new Date(start));
    var dateEnd = moment(new Date(end));

    var diff = moment.utc(dateEnd.diff(dateStart));

    return diff.format('HH:mm');
  }
});

module.exports = TimetrackItem;