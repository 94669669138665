// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),(depths[1] != null ? depths[1].group : depths[1]),{"name":"eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.supervisor : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.roles : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td><input type=\"text\" class=\"form-control start-date\" name=\"start_date\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.start_date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" /></td>\n<td><input type=\"text\" class=\"form-control end-date\" name=\"end_date\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.end_date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" /></td>\n<td><button class=\"btn btn-success btn-sm save-dates\" data-loading-text=\"Saving...\">Save</button></td>";
},"useData":true,"useDepths":true});
