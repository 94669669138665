'use strict';
var BaseCollection = require('../base-collection');
var CompanyTarget = require('./companytarget');

var CompanyTargets = BaseCollection.extend({
  model: CompanyTarget,
  urlRoot: '/api/companytargets/',
  comparator: 'id'
});

module.exports = CompanyTargets;
