'use strict';

var template =
  require('../../../templates/reporting/company-activity-case-size-table.hbs');

var CompanyActivityCaseSizeTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var bookingRecords = this.getOption('bookingRecords');
    var users = this.getOption('users');
    var data = [];

    users.forEach(function(user) {
      var userId = user.get('id');
      var userBookingRecords = bookingRecords.filter(function(bookingRecord) {
        return bookingRecord.get('case_manager') === userId;
      });

      var bookingsByMonth = [];
      for (var i = 0; i < 12; i += 1) {
        bookingsByMonth[i] = [];
      }

      // group records by month
      userBookingRecords.forEach(function(bookingRecord) {
        var created = new Date(bookingRecord.get('date'));
        bookingsByMonth[created.getMonth()].push(bookingRecord);
      });

      var averageByMonth = [];
      var averageYTD = [];
      var totalByMonth = [];
      var numCasesByMonth = [];
      var total = 0;
      var totalCases = 0;

      for (i = 0; i < 12; i += 1) {
        numCasesByMonth[i] = 0;
        var totalInMonth = 0;
        var caseIds = [];
        bookingsByMonth[i].forEach(function(booking) {
          if (booking.get('case_element') === 1) {
            caseIds.push(booking.get('case'));
          }
          var revenue = booking.get('revenue');
          totalInMonth += revenue;
          total += revenue;
        });
        totalCases += caseIds.length;
        numCasesByMonth[i] = caseIds.length;

        averageByMonth[i] = 0;
        if (caseIds.length > 0) {
          averageByMonth[i] = totalInMonth / caseIds.length;
        }

        averageYTD[i] = 0;
        if (totalCases > 0) {
          averageYTD[i] = total / totalCases;
        }

        totalByMonth[i] = 0;
        if (totalInMonth > 0) {
          totalByMonth[i] = totalInMonth;
        }
      }

      var dataRow = {
        user: user,
        averageByMonth: averageByMonth,
        averageYTD: averageYTD,
        totalByMonth: totalByMonth,
        numCasesByMonth: numCasesByMonth
      };

      data.push(dataRow);
    });

    this.trigger('dataReady', data);

    return data;
  }
});

module.exports = CompanyActivityCaseSizeTableView;
