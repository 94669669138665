'use strict';

var template = require('../../templates/websites/website-row.hbs');
var templateEdit = require('../../templates/websites/website-row-edit.hbs');
var ConfirmModal = require('../confirm-modal');

var WebsiteRowView = Backbone.Marionette.ItemView.extend({
  getTemplate: function() {
    if (this.editMode) {
      return templateEdit;
    }
    return template;
  },
  tagName: 'tr',
  className: 'website-table-row',
  ui: {
    editBtn: 'button.edit',
    deleteBtn: 'button.delete',
    saveBtn: 'button.save',
    cancelBtn: 'button.cancel',
    name: 'input[name="name"]',
    description: 'textarea[name="description"]',
    url: 'input[name="url"]',
    image: 'input[name="image_url"]'
  },
  events: {
    'click @ui.editBtn': 'onClickEdit',
    'click @ui.deleteBtn': 'onClickDelete',
    'click @ui.saveBtn': 'onClickSave',
    'click @ui.cancelBtn': 'onClickCancel'
  },
  onClickEdit: function() {
    this.editMode = true;
    this.render();
  },
  onClickDelete: function() {
    // show confirm modal before deleting
    var confirmModal = new ConfirmModal({
      body: 'Are you sure you want to delete this useful link?'
    });
    this.listenTo(confirmModal, 'confirmYes', this.doDelete);
  },
  doDelete: function() {
    this.model.destroy({ wait: true });
  },
  onClickSave: function() {
    var that = this;
    var name = this.ui.name.val();
    var description = this.ui.description.val();
    var url = this.ui.url.val();
    var imageUrl = this.ui.image.val();

    this.ui.saveBtn.button('loading');

    this.model.save({
      name: name,
      description: description,
      url: url,
      image_url: imageUrl
    }, {
      wait: true,
      success: function() {
        that.editMode = false;
        that.render();
      }
    });
  },
  onClickCancel: function() {
    this.editMode = false;
    this.render();
  }
});

module.exports = WebsiteRowView;
