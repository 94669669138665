'use strict';

var template = require('../../templates/financials/pledgingrecord-row.hbs');
var templateEdit = require('../../templates/financials/pledgingrecord-row-edit.hbs');
var ConfirmModalView = require('../confirm-modal');
var CaseManagerSelectView = require('../allocations/casemanager-select');

var PledgingRecordRowView = Backbone.Marionette.ItemView.extend({
  className: 'pledingrecord',
  tagName: 'tr',
  events: {
    'click @ui.editBtn': 'editRow',
    'click @ui.cancelBtn': 'cancelEdit',
    'click @ui.deleteBtn': 'deleteRow',
    'click @ui.saveBtn': 'saveRow'
  },
  ui: {
    editBtn: 'button.edit',
    deleteBtn: 'button.delete',
    cancelBtn: 'button.cancel',
    saveBtn: 'button.save',
    date: 'input[name="date"]',
    revenue: 'input[name="revenue"]',
    disbursements: 'input[name="disbursements"]',
    description: 'textarea[name="description"]',
    atRisk: 'input[name="is_at_risk"]',
    csoaRaised: 'input[name="is_csoa_raised"]',
    caseManagerContainer: '.casemanager-container',
    buttons: '.buttons'
  },
  initialize: function(options) {
    if (options.editMode) {
      this.editMode = options.editMode;
    }
  },
  getTemplate: function() {
    if (this.editMode) {
      return templateEdit;
    } else {
      return template;
    }
  },
  editRow: function() {
    this.editMode = true;
    this.$el.addClass('edit');
    this.render();
  },
  cancelEdit: function() {
    this.editMode = false;
    this.$el.removeClass('edit');
    this.render();
  },
  deleteRow: function() {
    //jshint camelcase:false
    var confirmModal = new ConfirmModalView({
      body: 'Are you sure you want to delete this forecasting record?'
    });
    confirmModal.render();
    this.listenTo(confirmModal, 'confirmYes', function(){
      this.model.save({ is_active: false }, { patch: true, wait: true });
    });
  },
  saveRow: function() {
    var hasError = false;
    if (this.ui.date.val() === '') {
      this.ui.date.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (this.ui.revenue.val() === '') {
      this.ui.revenue.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (this.ui.disbursements.val() === '') {
      this.ui.disbursements.parents('.form-group').addClass('has-error').append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (this.ui.description.val() === '') {
      this.ui.description.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (hasError) {
      return false;
    }

    var that = this;
    this.ui.saveBtn.button('loading');
    var data = this.model.toJSON();
    var date = this.ui.date.val().split('/');
    data.date = date[2] + '-' + date[1] + '-' + date[0];
    data.case_manager = this.caseManagerSelect.getSelectedCaseManager();
    data.revenue = parseFloat(this.ui.revenue.val());
    data.disbursements = parseFloat(this.ui.disbursements.val());
    data.description = this.ui.description.val();
    data.is_at_risk = this.ui.atRisk.is(':checked'); // jshint ignore:line
    data.is_csoa_raised = this.ui.csoaRaised.is(':checked');
    this.model.save(data, {
      success: function(){
        that.cancelEdit();
        that.trigger('saved');
      },
      error: function(model, response) {
        if (response.responseJSON) {
          if (response.responseJSON.non_field_errors) {
            that.ui.buttons.append('<div class="alert alert-danger">' +
              response.responseJSON.non_field_errors + '</div>'
            );
            that.ui.saveBtn.button('reset');
          } else if (response.responseJSON.detail) {
            that.ui.buttons.append('<div class="alert alert-danger">' +
              response.responseJSON.detail + '</div>'
            );
            that.ui.saveBtn.button('reset');
          }
        }
      }
    });
  },
  onRender: function() {
    if (this.editMode) {
      this.ui.date.datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy'
      });

      this.caseManagerRegion = new Backbone.Marionette.Region({
        el: this.ui.caseManagerContainer
      });
      this.caseManagerSelect = new CaseManagerSelectView({ user: this.model.get('case_manager') });
      this.caseManagerRegion.show(this.caseManagerSelect);
    }

    this.$el.removeClass('bg-danger disclosure-sent');
    if (this.model.get('is_at_risk')) {
      this.$el.addClass('bg-danger');
    } else if (!this.model.isNew()) {
      this.$el.addClass('disclosure-sent');
    }

    this.$('[title]').tooltip({
      container: 'body'
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.total = data.revenue + data.disbursements;
    return data;
  }
});

module.exports = PledgingRecordRowView;
