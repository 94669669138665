// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit event";
},"3":function(container,depth0,helpers,partials,data) {
    return "Create event";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_admin_only : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.ifAdmin || (depth0 != null ? depth0.ifAdmin : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"ifAdmin","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.ifAdmin) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                  <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.title : stack1), depth0))
    + "\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "checked";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.start : stack1),"YYYY-MM-DD",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.start : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.start : stack1),"HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.end : stack1),"YYYY-MM-DD",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.end : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.end : stack1),"HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"25":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.recur_every : stack1), depth0));
},"29":function(container,depth0,helpers,partials,data) {
    return "1";
},"31":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n    <div class=\"modal-header\">\n      <h4 class=\"modal-title\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.id : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h4>\n    </div>\n    <form class=\"form-horizontal\">\n      <div class=\"modal-body\">\n        <div class=\"form-group\">\n          <label for=\"event-category\" class=\"control-label col-sm-3\">Category</label>\n          <div class=\"col-sm-9\">\n            <select id=\"event-category\" name=\"event_category\" class=\"form-control input-sm event-category\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.eventCategories : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n        <div class=\"form-group\">\n          <label for=\"event-type\" class=\"control-label col-sm-3\">Type</label>\n\n          <div class=\"col-sm-9\">\n            <select id=\"event-type\" name=\"event_type\" class=\"form-control input-sm event-type\">\n            </select>\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <label for=\"event-title\" class=\"control-label col-sm-3\">Title</label>\n          <div class=\"col-sm-9\">\n            <input type=\"text\" id=\"event-title\" name=\"title\" class=\"form-control input-sm event-title\"\n                 "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n          </div>\n        </div>\n\n        <hr />\n\n        <div class=\"form-group\">\n          <div class=\"col-sm-9 col-sm-offset-3\">\n            <div class=\"checkbox-inline\">\n              <label><input type=\"checkbox\" name=\"is_all_day\" class=\"is-all-day\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.is_all_day : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> All day event?</label>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <label for=\"start-date\" class=\"control-label col-sm-3\">Start</label>\n          <div class=\"col-sm-4\">\n            <input type=\"date\" class=\"form-control input-sm start-date\" name=\"start_date\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.start : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n          </div>\n          <div class=\"col-sm-4 group-start-time\">\n            <input type=\"text\" class=\"form-control input-sm start-time\" id=\"start-time\" name=\"start_time\" placeholder=\"HH:MM\"\n                   "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.is_all_day : stack1),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <label for=\"end-date\" class=\"control-label col-sm-3\">End</label>\n          <div class=\"col-sm-4\">\n            <input type=\"date\" class=\"form-control input-sm end-date\" name=\"end_date\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.end : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n          </div>\n          <div class=\"col-sm-4 group-end-time\">\n            <input type=\"text\" class=\"form-control input-sm end-time\" id=\"end-time\" name=\"end_time\" placeholder=\"HH:MM\"\n                   "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.is_all_day : stack1),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <div class=\"col-sm-9 col-sm-offset-3\">\n            <div class=\"checkbox-inline\">\n              <label><input type=\"checkbox\" name=\"is_recurring\" class=\"is-recurring\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.is_recurring : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Recurring?</label>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"group-recurring hidden\">\n          <div class=\"form-group\">\n            <label for=\"recur-every\" class=\"control-label col-sm-3\">Recur every</label>\n            <div class=\"col-sm-4\">\n              <input type=\"number\" id=\"recur-every\" class=\"form-control input-sm recur-every\" name=\"recur_every\" value=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.recur_every : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\n            </div>\n          </div>\n\n          <div class=\"form-group\">\n            <label for=\"recur-interval\" class=\"control-label col-sm-3\">Recur Interval</label>\n            <div class=\"col-sm-4\">\n              <select id=\"recur-interval\" name=\"recur_interval\" class=\"form-control input-sm recur-interval\">\n                <option value=\"days\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.recur_interval : stack1),"days",{"name":"ifEqual","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Days</option>\n                <option value=\"weeks\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.recur_interval : stack1),"weeks",{"name":"ifEqual","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Weeks</option>\n                <option value=\"months\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.recur_interval : stack1),"months",{"name":"ifEqual","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Months (by date)</option>\n                <option value=\"four_weeks\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.recur_interval : stack1),"four_weeks",{"name":"ifEqual","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Months (every 4 weeks)</option>\n                <option value=\"years\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.event : depth0)) != null ? stack1.recur_interval : stack1),"years",{"name":"ifEqual","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Years</option>\n              </select>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"group-invitees hidden\">\n          <hr />\n          <div class=\"form-group\">\n            <label class=\"control-label col-sm-3\">Attendees</label>\n            <div class=\"col-sm-9\">\n              <div class=\"invitees-select-container\"></div>\n            </div>\n          </div>\n        </div>\n        <div class=\"group-user hidden\">\n          <hr />\n          <div class=\"form-group\">\n            <label class=\"control-label col-sm-3\">Who</label>\n            <div class=\"col-sm-9\">\n              <div class=\"user-select-container\"></div>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer\">\n        <button type=\"submit\" class=\"btn btn-primary\">Save</button>\n        <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n      </div>\n    </form>\n  </div>\n</div>";
},"useData":true});
