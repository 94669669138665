'use strict';

var Dropzone = require('dropzone');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var templateFilePreview = require(
  '../../templates/clients/correspondence-file-preview.hbs');
var template = require('../../templates/financials/bookingrecord-row.hbs');
var templateEdit = require('../../templates/financials/bookingrecord-row-edit.hbs');
var ConfirmModalView = require('../confirm-modal');
var CaseManagerSelectView = require('../allocations/casemanager-select');
var FileSelectView = require('../cases/file-select');

var BookingRecordRowView = Backbone.Marionette.ItemView.extend({
  className: 'bookingrecord',
  tagName: 'tr',
  getTemplate: function() {
    if (this.editMode) {
      return templateEdit;
    } else {
      return template;
    }
  },
  events: {
    'click @ui.editBtn': 'editRow',
    'click @ui.cancelBtn': 'cancelEdit',
    'click @ui.deleteBtn': 'deleteRow',
    'click @ui.saveBtn': 'saveRow',
    'click @ui.fileLink': 'onClickFileLink',
    'click @ui.attachExistingFile': 'onClickAttachExistingFile'
  },
  ui: {
    editBtn: 'button.edit',
    deleteBtn: 'button.delete',
    cancelBtn: 'button.cancel',
    saveBtn: 'button.save',
    date: 'input[name="date"]',
    caseElement: 'input[name="case_element"]',
    revenue: 'input[name="revenue"]',
    vat: 'input[name="vat"]',
    disbursements: 'input[name="disbursements"]',
    description: 'textarea[name="description"]',
    caseManagerContainer: '.casemanager-container',
    attachFile: 'button.add-file',
    attachExistingFile: 'button.add-existing-file',
    uploadContainer: '.upload-file-container',
    dropzonePreviews: '.dropzone-previews',
    filePreviews: '.file-previews',
    csoa: 'input[name="csoa"]',
    fileLink: 'a.file-link',
    buttons: '.buttons',
    fileSelectContainer: '.file-select-container'
  },
  initialize: function(options) {
    if (options.editMode) {
      this.editMode = options.editMode;
    }
    this.listenTo(options.casefile.get('files'), 'sync', this.render);
  },
  onClickFileLink: function(e){
    e.stopImmediatePropagation();

    if (!this.ui.fileLink.hasClass('downloadable')) {
      var files = this.getOption('casefile').get('files');
      this.trigger('requestFileDownload',
        files.get(this.model.get('csoa')));
    }
  },
  onClickAttachExistingFile: function(e) {
    e.stopImmediatePropagation();

    var fileRegion = new Backbone.Marionette.Region({
      el: this.ui.fileSelectContainer
    });
    this.fileSelectView = new FileSelectView({
      casefile: this.getOption('casefile'),
      multiple: false
    });
    fileRegion.show(this.fileSelectView);
  },
  editRow: function() {
    this.editMode = true;
    this.$el.addClass('edit');
    this.render();
  },
  cancelEdit: function() {
    this.editMode = false;
    this.$el.removeClass('edit');
    this.render();
  },
  deleteRow: function() {
    //jshint camelcase:false
    var confirmModal = new ConfirmModalView({
      body: 'Are you sure you want to delete this booking record?'
    });
    confirmModal.render();
    this.listenTo(confirmModal, 'confirmYes', function(){
      this.model.save({ is_active: false }, { patch: true, wait: true });
    });
  },
  saveRow: function() {
    var that = this;
    var hasError = false;
    if (this.ui.date.val() === '') {
      this.ui.date.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (this.ui.revenue.val() === '') {
      this.ui.revenue.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (this.ui.vat.val() === '') {
      this.ui.vat.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (this.ui.disbursements.val() === '') {
      this.ui.disbursements.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (this.ui.description.val() === '') {
      this.ui.description.parents('.form-group').addClass('has-error')
        .append('<p class="help-block">This field is required.</p>');
      hasError = true;
    }
    if (hasError) {
      return false;
    }

    var that = this;
    this.ui.saveBtn.button('loading');
    var data = this.model.toJSON();
    var date = this.ui.date.val().split('/');
    data.date = date[2] + '-' + date[1] + '-' + date[0];
    data.case_element = parseInt(this.ui.caseElement.val(), 10);
    data.revenue = parseFloat(this.ui.revenue.val());
    data.vat = parseFloat(this.ui.vat.val());
    data.disbursements = parseFloat(this.ui.disbursements.val());
    data.description = this.ui.description.val();
    data.case_manager = this.caseManagerSelect.getSelectedCaseManager();
    if (this.ui.csoa.val()) {
      data.csoa = this.ui.csoa.val();
    }
    if (this.fileSelectView) {
      data.csoa = this.fileSelectView.getSelectedFile();
    }

    function performSave() {
      that.model.save(data, {
        success: function () {
          that.cancelEdit();
          that.trigger('saved');
        },
        error: function (model, response) {
          if (response.responseJSON) {
            if (response.responseJSON.non_field_errors) {
              that.ui.buttons.append('<div class="alert alert-danger">' +
                response.responseJSON.non_field_errors + '</div>'
              );
              that.ui.saveBtn.button('reset');
            } else if (response.responseJSON.detail) {
              that.ui.buttons.append('<div class="alert alert-danger">' +
                response.responseJSON.detail + '</div>'
              );
              that.ui.saveBtn.button('reset');
            }
          }
        }
      });
    }

    if (this.isFileQueued) {
      this.listenToOnce(this, 'fileUploaded', function(fileId){
        data.csoa = fileId;
        performSave();
      });

      this.dropzone.processQueue();
    } else {
      performSave();
    }
  },
  onRender: function() {
    var that = this;
    if (this.editMode) {
      this.ui.date.datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy'
      });
      this.caseManagerRegion = new Backbone.Marionette.Region({
        el: this.ui.caseManagerContainer
      });
      this.caseManagerSelect = new CaseManagerSelectView({ user: this.model.get('case_manager') });
      this.caseManagerRegion.show(this.caseManagerSelect);

      //configure dropzone
      if (this.dropzone) {
        this.dropzone.destroy();
      }
      this.dropzone = new Dropzone(this.el, {
        url: '/api/cases/' + that.model.get('case') + '/files/',
        headers: {'X-CSRFToken': TCAS.csrfToken},
        previewsContainer: that.ui.dropzonePreviews[0],
        previewTemplate: templateDropzoneFile(),
        clickable: that.ui.attachFile[0],
        maxFilesize: 500,
        acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.eml,.msg,.ged',
        autoProcessQueue: false,
        accept: function (file, done) {
          var alphaNumericPattern = new RegExp(
            '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
          );
          // check for only alphanumeric characters
          if (alphaNumericPattern.test(file.name)) {
            done();
          } else {
            done('This file has unexpected characters, ' +
              'file names must use the correct file naming structure and ' +
              'only contain characters consisting of: ' +
              'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
              'apostrophes, commas or spaces.');
          }
        }
      });
      this.dropzone.on('success', function (file, response) {
        that.trigger('fileUploaded', response.id);
        that.isFileQueued = false;
        that.getOption('casefile').get('files').fetch();
      });
      this.dropzone.on('sending', function (file, xhr, formData) {
        formData.append('is_csoa_file', true);
        formData.append('casefile',  that.model.get('case'));
        // Show the total progress bar when upload starts
        that.$(file.previewElement).find('.progress').removeClass('hide');
        that.$(file.previewElement).find('button').addClass('hide');
      });
      this.dropzone.on('addedfile', function (file) {
        _(this.files).each(function(f){
          if (f != file) {
            this.removeFile(f);
          }
        }, this);

        that.isFileQueued = true;
        that.ui.csoa.val(-1);
        that.$(file.previewElement).find('button').removeClass('hide');
        that.ui.uploadContainer.addClass('hide');
      });
      this.dropzone.on('error', function (file) {
        that.ui.csoa.val('');
      });
      this.dropzone.on('removedfile', function () {
        that.isFileQueued = false;
        that.ui.csoa.val('');
        that.ui.uploadContainer.removeClass('hide');
      });
      this.dropzone.on('dragover', function () {
        that.$el.addClass('success');
      });
      this.dropzone.on('dragleave', function () {
        that.$el.removeClass('success');
      });
      this.dropzone.on('drop', function (e) {
        that.$el.removeClass('success');
        if (_(e.dataTransfer.types).contains('text/plain')) {
          var data = JSON.parse(e.dataTransfer.getData('text/plain'));
          that.ui.filePreviews.empty().append(templateFilePreview(data));
          that.ui.csoa.val(data.id);
          that.ui.uploadContainer.addClass('hide');
          e.preventDefault();
        }
      });
    }
    this.$('[title]').tooltip({
      container: 'body'
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    if (!data.case_element) {
      data.case_element = 1;
    }
    var files = this.getOption('casefile').get('files');
    if (files.length && data.csoa) {
      var file = files.find(function(file) {
        return file.get('id') === data.csoa;
      });
      data.csoa = file ? file.toJSON() : null;
    }
    data.total = data.revenue + data.vat + data.disbursements;
    return data;
  }
});

module.exports = BookingRecordRowView;
