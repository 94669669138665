// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-warning alert-dismissible\" role=\"alert\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\"><i class=\"fa fa-close\"></i></span></button>\n  <strong>Warning!</strong> You do not have Archived Cases session enabled, so reports will not contain any data\n  from archived cases.\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fromDate : depth0),"YYYY-MM-DD",{"name":"formatDate","hash":{},"data":data}))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return " value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.toDate : depth0),"YYYY-MM-DD",{"name":"formatDate","hash":{},"data":data}))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"9":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isArchiveDataSession : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div>\n  <div class=\"panel panel-default\" role=\"tablist\">\n    <div class=\"panel-heading\" id=\"timetrack-filter-heading\" role=\"tab\">\n      <a href=\"#\" id=\"timetrack-filter-toggle\" data-toggle=\"collapse\" data-target=\"#timetrack-filter-panel\"\n        aria-expanded=\"true\" aria-controls=\"filter-accordion\">\n        <h4 class=\"panel-title\">Filter</h4>\n      </a>\n    </div>\n    <div id=\"survey-filter-panel\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"timetrack-filter-heading\">\n      <div class=\"panel-body\">\n        <form class=\"survey-filters\">\n          <fieldset>\n            <div class=\"row\">\n              <div class=\"form-group col-sm-3\">\n                <label for=\"timetrack-date-from\">From</label>\n                <input type=\"date\" name=\"date_from\" class=\"form-control date-from\" id=\"timetrack-date-from\" placeholder=\"DD/MM/YYYY\"\n                  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fromDate : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n              </div>\n              <div class=\"form-group col-sm-3\">\n                <label for=\"timetrack-date-to\">To</label>\n                <input type=\"date\" name=\"date_to\" class=\"form-control date-to\" id=\"timetrack-date-to\" placeholder=\"DD/MM/YYYY\"\n                  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.toDate : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n              </div>\n              <div class=\"form-group col-sm-3\">\n                <label for=\"pipeline-team\">Team</label>\n                <input type=\"text\" class=\"form-control team\" name=\"team\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTeamDisabled : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n              </div>\n              <div class=\"form-group col-sm-3\">\n                <label class=\"case-manager-label\">Case manager</label>\n                <div class=\"casemanager-select-container\"></div>\n              </div>\n            </div>\n            <div class=\"row\">\n              <div class=\"form-group col-sm-3 inactive-users-checkbox-container\">\n                <label class=\"control-label\">Users</label>\n                <div class=\"checkbox\">\n                  <label for=\"include-inactive-users\">\n                    <input type=\"checkbox\" name=\"include_inactive\" id=\"include-inactive-users\" value=\"1\" class=\"include-inactive\"\n                      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.includeInactiveUsers : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Include <span class=\"badge\">Inactive</span>\n                  </label>\n                </div>\n              </div>\n            </div>\n\n            <button type=\"submit\" class=\"btn btn-success btn-sm submit-btn\" id=\"survey-report-submit\" data-loading-text=\"Please wait...\">\n              Generate Report\n            </button>\n            <button type=\"button\" class=\"btn btn-default btn-sm export-csv\" id=\"survey-export-csv\" data-loading-text=\"Please wait...\">\n              Export CSV\n            </button>\n          </fieldset>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div id=\"reporting-area\" class=\"reporting-area hidden\">\n  <h3 class=\"report-for-name\"></h3>\n  <div class=\"survey-report-container\"></div>\n</div>";
},"useData":true});
