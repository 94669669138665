'use strict';
var template = require('../../templates/reporting/report-timetrack-edits-row.hbs');

var ReportTimeTrackEditsRow = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tbody',
  serializeData: function() {
    var data = this.model.toJSON();

    if(data.events) {
      data.events = data.events.toJSON();
    }

    return data;
  }
});

module.exports = ReportTimeTrackEditsRow;