// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"text-danger\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<form action=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"table-responsive\">\n    <table class=\"table table-condensed\">\n      <thead>\n      <tr>\n        <th class=\"col-xs-1\">Date</th>\n        <th class=\"col-xs-1\">Week</th>\n        <th class=\"col-xs-1\">Modified</th>\n        <th class=\"col-xs-1\">Case Manager</th>\n        <th class=\"col-xs-1\">Revenue</th>\n        <th class=\"col-xs-1\">Disbursements</th>\n        <th class=\"col-xs-1\">Confirmed</th>\n        <th class=\"col-xs-2\">Description</th>\n        <th class=\"col-xs-1\">At risk</th>\n        <th class=\"col-xs-1\">CSOA raised?</th>\n        <th class=\"col-xs-1\">Total</th>\n        <th></th>\n      </tr>\n      </thead>\n      <tbody class=\"table-add\"></tbody>\n      <tbody class=\"table-items\"></tbody>\n      <tbody class=\"table-total\">\n      <tr>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.totalRevenue : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.totalRevenue : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n        <td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.totalDisbursements : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.totalDisbursements : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n        <td></td>\n      </tr>\n      </tbody>\n\n    </table>\n  </div>\n</form>";
},"useData":true});
