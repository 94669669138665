'use strict';

var AllocationView = require('./allocation');
var template = require('../../templates/allocations/allocation-list.hbs');

var AllocationListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: AllocationView,
  childViewContainer: '.allocations'
});

module.exports = AllocationListView;