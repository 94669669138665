'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-disclosure-sent-list.hbs');
var IndividualMonthlyDisclosureSentRowView = require('./individual-monthly-disclosure-sent-row');
var Milestones = require('../../../models/cases/milestones');

var IndividualMonthlyDisclosureSentListView =
  Backbone.Marionette.CompositeView.extend({
    template: template,
    ui: {
      tablePad: '.table-pad',
      tableTotal: '.table-total'
    },
    initialize: function() {
      this.minRows = 0;
      this.listenTo(this.getOption('milestones'), 'sync', this.render);
    },
    collectionEvents: {
      'add': 'calculateTotals',
      'remove': 'calculateTotals'
    },
    childView: IndividualMonthlyDisclosureSentRowView,
    childViewContainer: 'tbody.table-data',
    childViewOptions: function(model) {
      var caseId = model.get('id');
      return {
        milestones: new Milestones(this.getOption('milestones').filter(
          function(m) {
            return m.get('case') === caseId;
          }
        ))
      };
    },
    setMinRows: function(minRows) {
      if (minRows >= this.minRows) {
        this.minRows = minRows;
      }

      this.ui.tablePad.empty();
      for (var i = 0; i < (this.minRows - this.collection.length); i += 1) {
        this.ui.tablePad.append('<tr><td colspan="3">&nbsp;</td></tr>');
      }
    },
    calculateTotals: function() {
      this.ui.tableTotal.html('<tr><td>Totals</td><td></td><td>' +
        this.collection.length + '</td></tr>'
      );
    }
  }
);

module.exports = IndividualMonthlyDisclosureSentListView;
