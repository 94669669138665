// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showFile : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <span class=\"badge\">Survey Completed by "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.answers : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.is_case_manager_submission : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "Case Manager";
},"5":function(container,depth0,helpers,partials,data) {
    return "Client";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.client : depth0)) != null ? stack1.questionnaire_sent_date : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-default btn-sm edit\"><i class=\"fa fa-pencil\"></i> Edit</button>";
},"12":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"form-group\">\n    <label class=\"control-label\">Date</label>\n    <div>"
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</div>\n  </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"form-group questionnaire-file\">\n    <label class=\"control-label\">Scanned paper questionnaire</label>\n    <div class=\"file-preview\"></div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isComplete : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"text-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showEdit : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"btn-group\">\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showEdit : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n\n<div class=\"questions-container\"></div>\n\n<hr />\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDateField : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showFile : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
