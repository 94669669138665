'use strict';
var LoginView = require('../views/login');
var template = require('../templates/login-layout.hbs');

var LoginLayout = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    loginForm: '#login'
  },
  onShow: function(){
    this.loginForm.show(new LoginView(this.options));
  }
});


module.exports = LoginLayout;
