// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge\">FA Sent <i class=\"fa fa-check\"></i></span>&nbsp;";
},"3":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge\">Disclosure Sent <i class=\"fa fa-check\"></i></span>&nbsp;";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge\">Case Booked <i class=\"fa fa-check\"></i></span>";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.currentAllocation : depth0)) != null ? stack1.case_manager : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        Not Currently Allocated\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.currentAllocation : depth0)) != null ? stack1.partner : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.currentAllocation : depth0)) != null ? stack1.partner : stack1),{"name":"userFullName","hash":{},"data":data}))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                        None\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "";
},"18":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n                <td>Category:</td>\n                <td>"
    + container.escapeExpression((helpers.categoryField || (depth0 && depth0.categoryField) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.category : depth0),"code",{"name":"categoryField","hash":{},"data":data}))
    + "</td>\n            </tr>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.missing_date : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_birth : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_death : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr>\n                <td>Custodian:</td>\n                <td>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.custodian_name : stack1), depth0))
    + "</td>\n            </tr>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.contactAddress || (depth0 && depth0.contactAddress) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"contactAddress","hash":{},"data":data}));
},"30":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <tr>\n                <td></td>\n                <td>\n                    <a href=\"/cases/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "/edit\" class=\"btn btn-default btn-sm casefile-edit\"><i\n                            class=\"fa fa-pencil\"></i> Edit</a>\n                </td>\n            </tr>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"col-md-6\" id=\"asset-value\">\n        <form class=\"form-horizontal add-valuation-form\">\n            <div class=\"form-group\">\n                <label class=\"control-label col-md-6\" for=\"#capital-value\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isLACase : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + ":</label>\n                <div class=\"col-md-6\">\n                    <input class=\"form-control input-sm\" id=\"capital-value\" name=\"capitalValue\"\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.capital_value : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label class=\"control-label col-md-6\" for=\"#valuation-date\">Valuation Date:</label>\n                <div class=\"col-md-6\">\n                    <input class=\"form-control input-sm\" id=\"valuation-date\" placeholder=\"None\" name=\"valuationDate\"\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.valuation_date : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label class=\"control-label col-md-6\" for=\"#commission\">Target Commission:</label>\n                <div class=\"col-md-6\">\n                    <div class=\"input-group input-group-sm\">\n                        <input class=\"form-control\" placeholder=\"None\" id=\"commission\" name=\"commission\"\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.commission : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n                        <div class=\"input-group-addon\">%</div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isLACase : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <label class=\"control-label col-md-6\" for=\"#income-value\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAssetCase : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(47, data, 0),"data":data})) != null ? stack1 : "")
    + ":</label>\n                <div class=\"col-md-6\">\n                    <input class=\"form-control input-sm\" placeholder=\"None\" id=\"income-value\" name=\"incomeValue\"\n                        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isLACase : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data})) != null ? stack1 : "")
    + " />\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label class=\"control-label col-md-6\" for=\"#expected-completion-date\">Expected Completion Date:</label>\n                <div class=\"col-md-6\">\n                    <input class=\"form-control input-sm\" placeholder=\"None\" id=\"expected-completion-date\"\n                        name=\"expectedCompletion\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.expected_completion_date : stack1),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <div class=\"col-md-offset-6 col-md-6\">\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || helpers.helperMissing).call(alias1,"add_assetvalue",{"name":"hasPerm","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </form>\n    </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "Net Estate Value\n                    (DoD)";
},"35":function(container,depth0,helpers,partials,data) {
    return "Capital Value";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        value=\""
    + container.escapeExpression((helpers.fixedDecimalPlaces || (depth0 && depth0.fixedDecimalPlaces) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.capital_value : stack1),2,{"name":"fixedDecimalPlaces","hash":{},"data":data}))
    + "\" ";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.valuation_date : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" ";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            value=\""
    + container.escapeExpression((helpers.fixedDecimalPlaces || (depth0 && depth0.fixedDecimalPlaces) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.commission : stack1),2,{"name":"fixedDecimalPlaces","hash":{},"data":data}))
    + "\" ";
},"43":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"45":function(container,depth0,helpers,partials,data) {
    return "Past Recoverable Income\n                    Value";
},"47":function(container,depth0,helpers,partials,data) {
    return "Income Value";
},"49":function(container,depth0,helpers,partials,data) {
    return " value=\"0\" ";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.income_value : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                        value=\""
    + container.escapeExpression((helpers.fixedDecimalPlaces || (depth0 && depth0.fixedDecimalPlaces) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.income_value : stack1),2,{"name":"fixedDecimalPlaces","hash":{},"data":data}))
    + "\" ";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                        value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.most_recent_valuation : depth0)) != null ? stack1.expected_completion_date : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" ";
},"56":function(container,depth0,helpers,partials,data) {
    return "                    <button class=\"btn-sm btn btn-primary\" id=\"save-valuation-changes\">Update</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", buffer = 
  "<div class=\"row casefile-detail-status\">\n    <div class=\"col-md-12\">\n        <span\n            class=\"bg-info v-padding-5 col-md-3 status-bar "
    + alias3((helpers.caseStatusColour || (depth0 && depth0.caseStatusColour) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),(depth0 != null ? depth0.fa_sent : depth0),(depth0 != null ? depth0.disclosure_sent : depth0),(depth0 != null ? depth0.is_archived : depth0),{"name":"caseStatusColour","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.caseStatusName || (depth0 && depth0.caseStatusName) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"caseStatusName","hash":{"isArchived":(depth0 != null ? depth0.is_archived : depth0)},"data":data}))
    + "</span>&nbsp;\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fa_sent : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disclosure_sent : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.case_booked : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-md-6\">\n        <table class=\"table-no-borders\">\n            <tr>\n                <td><strong>Case Manager:</strong></td>\n                <td>\n                    <strong>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.currentAllocation : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </strong>\n                </td>\n            </tr>\n            <tr>\n                <td><strong>Allocated Partner:</strong></td>\n                <td>\n                    <strong>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.currentAllocation : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </strong>\n                </td>\n            </tr>\n            <tr>\n                <td>Reference:</td>\n                <td class=\"pre\">"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</td>\n            </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "            <tr>\n                <td>Missing since:</td>\n                <td>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.missing_date : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            </tr>\n            <tr>\n                <td>Date of Birth:</td>\n                <td>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_birth : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            </tr>\n            <tr>\n                <td>Date of Death:</td>\n                <td>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_death : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            </tr>\n            <tr>\n                <td>Reg. asset holder name(s):</td>\n                <td>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_holder_name : stack1), depth0))
    + "</td>\n            </tr>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <tr>\n                <td>Reg. asset holder address:</td>\n                <td>\n                    <div class=\"asset-holder-addresses\">"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                </td>\n            </tr>\n            <tr>\n                <td>Notes on allocation:</td>\n                <td>"
    + alias3(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "</td>\n            </tr>\n";
  stack1 = ((helper = (helper = helpers.ifAdmin || (depth0 != null ? depth0.ifAdmin : depth0)) != null ? helper : alias2),(options={"name":"ifAdmin","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.ifAdmin) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </table>\n    </div>\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_assetvalue",{"name":"hasPerm","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
