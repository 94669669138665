'use strict';

var template = require('../../../templates/reporting/commercial-tracking-report.hbs');
var _ = require('lodash');
var moment = require('moment');
var CaseFiles = require('../../../models/cases/casefiles');
var CaseFile = require('../../../models/cases/casefile');
var BookingRecords = require('../../../models/reporting/bookingrecords');
var ForecastingRecords = require('../../../models/reporting/forecastingrecords');
var PledgingRecords = require('../../../models/reporting/pledgingrecords');
var Milestones = require('../../../models/cases/milestones');
var CommercialTrackingGranularReportView = require('./commercial-granular');

var CommercialTrackingReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'commercial-tracking-report',
  regions: {
    granularRegion: '.commercial-granular',
  },
  ui: {
    generateReportBtn: '.submit-btn',
    exportBtn: '.csv',
    form: '.report-form',
    alertArea: '#alert-area',
    reportingArea: '.reporting-area',
    commercialSummaryTable: '.commercial-summary-report-table',
    commercialSummaryStatsTable: '.commercial-summary-report-stats-table',
    tabSummary: '.tab-commercial-summary',
    tabGranular: '.tab-commercial-granular',
  },
  events: {
    'submit @ui.form': 'generateReport',
    'click @ui.exportBtn': 'onClickExport',
  },
  initialize: function(options) {
    this.listenTo(TCAS.archiveDataTokens, 'sync', this.render);
  },
  generateReport: function() {
    var that = this;
    that.ui.generateReportBtn.button('loading');
    var categories = this.getOption('categories')
      .toJSON()
      .filter(function(category) {
        return category.category_group === 2 || category.category_group === 4;
      });
    var users = this.getOption('users');
    var bookingRecords = new BookingRecords();
    var forecastingRecords = new ForecastingRecords();
    var pledgingRecords = new PledgingRecords();
    var casefiles = new CaseFiles();
    this.bookingRecords = bookingRecords;
    this.forecastingRecords = forecastingRecords;
    this.pledgingRecords = pledgingRecords;
    this.casefiles = casefiles;
    var beginningOfYear = moment().startOf('year');
    this.years = [];
    var yearStart = 2013;

    var categoryDataById = categories.reduce(function(acc, category) {
      var data = category;
      data.booking = [];
      data.forecast = [];
      data.pledge = [];
      data.caseValue = [];
      data.commission = [];
      data.bookedIncome = 0;
      data.revenuePotential = 0;
      data.totalCases = 0;
      data.ongoingCases = 0;
      data.newCasesThisYear = 0;
      data.concludedOrBookedCases = 0;
      data.successRate = 0;
      data.avgValueOfCases = 0;
      data.avgGeneratedFeeIncome = 0;
      acc[data.id] = data;

      return acc;
    }, {});

    // filter only Local Authority and Corporate groups
    var filters = {
      case__category__category_group__in: [2, 4],
      date_after: yearStart + '-01-01',
      page_size: 10000,
      is_active: true,
    };
    bookingRecords.setFilters(filters);
    forecastingRecords.setFilters(filters);
    pledgingRecords.setFilters(filters);
    casefiles.setFilters({
      category__category_group__in: [2, 4],
      created_after: yearStart + '-01-01',
      page_size: 10000,
    });

    function financialsTotal(acc, record) {
      return acc + record.get('revenue');
    }

    var fetches = [
      bookingRecords.fetch(),
      forecastingRecords.fetch(),
      pledgingRecords.fetch(),
      casefiles.fetch(),
    ];

    // fetch bookingRecords, forecast records and pledges during this period
    $.when.apply($, fetches).done(function() {
      // check if there are any additional casefiles that need to be fetched
      // that may have been created before the yearStart
      var caseIds = casefiles.map(function(casefile) {
        return casefile.get('id');
      });
      var missingCaseIds = bookingRecords.reduce(function(acc, record) {
        if (caseIds.indexOf(record.get('case')) === -1 && acc.indexOf(record.get('case')) === -1) {
          acc.push(record.get('case'));
        }
        return acc;
      }, []);
      missingCaseIds = forecastingRecords.reduce(function(acc, record) {
        if (caseIds.indexOf(record.get('case')) === -1 && acc.indexOf(record.get('case')) === -1) {
          acc.push(record.get('case'));
        }
        return acc;
      }, missingCaseIds);
      missingCaseIds = pledgingRecords.reduce(function(acc, record) {
        if (caseIds.indexOf(record.get('case')) === -1 && acc.indexOf(record.get('case')) === -1) {
          acc.push(record.get('case'));
        }
        return acc;
      }, missingCaseIds);

      if (missingCaseIds.length > 0) {
        var missingCases = new CaseFiles();
        missingCases.setFilters({
          id__in: missingCaseIds,
          page_size: 10000,
        });
        missingCases.fetch().then(function() {
          casefiles.add(missingCases.models);
          renderReport();
        });
      } else {
        renderReport();
      }

      function renderReport() {
        // sort casefiles by date created
        casefiles.comparator = 'created';
        casefiles.sort();

        // collate casefile ids to query milestones
        var caseIds = casefiles.map(function(casefile) {
          return casefile.get('id');
        });
        var milestones = new Milestones();
        milestones.setFilters({
          case__in: caseIds.join(','),
          milestone_type: 35, // 35 is case concluded milestone
          is_active: true,
          page_size: 10000,
        });
        that.milestones = milestones;

        milestones.fetch().then(function() {
          casefiles.forEach(function(casefile) {
            var caseId = casefile.get('id');
            var categoryId = casefile.get('category');
            var filteredBookingRecords = bookingRecords.where({
              case: caseId,
            });
            var filteredForecastingRecords = forecastingRecords.where({
              case: caseId,
            });
            var filteredPledgingRecords = pledgingRecords.where({
              case: caseId,
            });

            var data = categoryDataById[categoryId];
            data.booking = data.booking.concat(filteredBookingRecords);
            data.forecast = data.forecast.concat(filteredForecastingRecords);
            data.pledge = data.pledge.concat(filteredPledgingRecords);
            var currentValuation = casefile.get('asset').getCurrentValuation();
            if (currentValuation) {
              data.caseValue.push(currentValuation.getTotal());
              data.commission.push(currentValuation.get('commission'));
            } else {
              data.caseValue.push(0);
              data.commission.push(0);
            }

            var isBooked =
              casefile.get('case_booked') &&
              filteredForecastingRecords.length === 0 &&
              filteredPledgingRecords.length === 0;

            // revenue potential should only be calculated for the current active cases
            if (
              isBooked ||
              casefile.get('status') === CaseFile.STATUS_IDS.CONCLUDED ||
              casefile.get('status') === CaseFile.STATUS_IDS.ABANDONED
            ) {
              data.concludedOrBookedCases += 1;
            } else {
              data.ongoingCases += 1;

              // if there is any booked, forecast or pledged records use those for revenue potential
              if (
                filteredBookingRecords.length > 0 ||
                filteredForecastingRecords.length > 0 ||
                filteredPledgingRecords.length > 0
              ) {
                var forecastingTotal = filteredForecastingRecords.reduce(financialsTotal, 0);
                var pledgingTotal = filteredPledgingRecords.reduce(financialsTotal, 0);
                data.revenuePotential += forecastingTotal + pledgingTotal;
              } else if (currentValuation) {
                data.revenuePotential +=
                  currentValuation.getTotal() *
                  (currentValuation.get('commission')
                    ? currentValuation.get('commission') / 100
                    : 0.25);
              }
            }

            data.totalCases += 1;

            if (moment(casefile.get('created')).isAfter(beginningOfYear)) {
              data.newCasesThisYear += 1;
            }
          });

          var categoriesSorted = categories.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
          that.categoriesSorted = categoriesSorted.map(function(category) {
            var data = categoryDataById[category.id];
            var bookedIncome = 0;
            data.booking.forEach(function(booking) {
              bookedIncome += booking.get('revenue');
            });
            data.bookedIncome = bookedIncome;
            data.avgValueOfCases =
              data.caseValue.reduce(function(acc, item) {
                return acc + item;
              }, 0) / data.totalCases;
            if (isNaN(data.avgValueOfCases)) {
              data.avgValueOfCases = 0;
            }
            data.avgGeneratedFeeIncome = data.bookedIncome / data.concludedOrBookedCases;
            if (isNaN(data.avgGeneratedFeeIncome) || data.concludedOrBookedCases === 0) {
              data.avgGeneratedFeeIncome = 0;
            }
            data.successRate = data.concludedOrBookedCases / data.totalCases;
            if (isNaN(data.successRate)) {
              data.successRate = 0;
            }

            category.categoryData = data;

            return category;
          });

          that.render();
          that.ui.reportingArea.removeClass('hidden');
          that.ui.generateReportBtn.button('reset');

          that.granularRegion.show(
            new CommercialTrackingGranularReportView({
              categories: categories,
              casefiles: casefiles,
              milestones: milestones,
              bookingRecords: bookingRecords,
              forecastingRecords: forecastingRecords,
              pledgingRecords: pledgingRecords,
              yearStart: yearStart,
            })
          );
        });
      }
    });

    return false;
  },
  onClickExport: function() {
    var $button = this.ui.exportBtn;
    $button.button('loading');

    var results = [];
    var row = [];
    this.ui.commercialSummaryTable.find('thead th').each(function() {
      row.push('"' + $(this).text() + '"');
    });
    results.push(row.join(','));

    this.ui.commercialSummaryTable.find('tbody tr').each(function() {
      row = [];
      $(this)
        .find('td')
        .each(function(index) {
          var $td = $(this);
          var value = $td.text();
          if ([4, 6, 8, 9].indexOf(index) !== -1) {
            // remove £ and , from currency values
            var dataValue = $td.attr('data-value');
            if (dataValue) {
              value = dataValue;
            } else {
              value = value.replace('£', '').replace(',', '');
            }
          }
          row.push(value);
        });
      results.push(row.join(','));
    });

    var csvData = {
      content: results.join('\n'),
      filename: 'commercial_relationship_tracking',
    };

    $.ajax({
      url: '/api/tocsv',
      data: csvData,
      type: 'post',
      success: function(data) {
        $button.button('reset');
        window.location = data;
      },
      error: function(e) {
        console.log(e);
      },
    });
  },
  onRender: function() {
    // setup the floating pipeline stats
    var $table = this.ui.commercialSummaryTable;
    var $statsTable = this.ui.commercialSummaryStatsTable;
    _.defer(function() {
      var $tds = $statsTable.find('td');
      for (var i = 0; i < $tds.length; i += 1) {
        var width = $table
          .find('th')
          .eq(i)
          .outerWidth();
        var totalWidth = $statsTable.outerWidth();
        $($tds[i]).css('width', (width / totalWidth) * 100 + '%');
      }
    });
  },
  serializeData: function() {
    var data = {
      categories: this.categoriesSorted ? this.categoriesSorted : [],
      casesActive: this.casesActive,
      casesBooked: this.casesBooked,
    };
    data.totalCases = 0;
    data.ongoingCases = 0;
    data.newCasesThisYear = 0;
    data.revenuePotential = 0;
    data.concludedOrBookedCases = 0;
    data.bookedIncome = 0;
    data.successRate = 0;
    data.avgValueOfCases = 0;
    data.avgGeneratedFeeIncome = 0;

    if (this.categoriesSorted) {
      // work out totals for final stats table
      this.categoriesSorted.forEach(function(category) {
        var categoryData = category.categoryData;
        data.totalCases += categoryData.totalCases;
        data.ongoingCases += categoryData.ongoingCases;
        data.newCasesThisYear += categoryData.newCasesThisYear;
        data.revenuePotential += categoryData.revenuePotential;
        data.concludedOrBookedCases += categoryData.concludedOrBookedCases;
        data.bookedIncome += categoryData.bookedIncome;
        data.avgValueOfCases += categoryData.avgValueOfCases;
      });
      // calculate average fields
      data.successRate = data.concludedOrBookedCases / data.totalCases;
      if (isNaN(data.successRate)) {
        data.successRate = 0;
      }
      data.avgValueOfCases = data.avgValueOfCases / this.categoriesSorted.length;
      if (isNaN(data.avgValueOfCases)) {
        data.avgValueOfCases = 0;
      }
      data.avgGeneratedFeeIncome = data.bookedIncome / data.concludedOrBookedCases;
      if (isNaN(data.avgGeneratedFeeIncome)) {
        data.avgGeneratedFeeIncome = 0;
      }
    }

    var archiveDataToken = TCAS.request('archiveDataToken');
    data.isArchiveDataSession = !!archiveDataToken;

    if ($(window).width() < 768) {
      data.isMobile = true;
    }

    return data;
  },
});

module.exports = CommercialTrackingReportView;
