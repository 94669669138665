// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a href=\"#cases-sub-menu\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCaseActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + " data-toggle=\"collapse\" aria-expanded=\"false\" aria-controls=\"cases-sub-menu\">\n            <i class=\"fa fa-fw fa-briefcase\"></i>Cases\n        </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " class=\"active\" ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/client/cases",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    return "\n            class=\"active\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return "in";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/client/cases\" title=\"My Cases\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    href=\"/client/cases\" "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/client/cases",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">My\n                    Cases</a></li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/accounts/cases\" title=\"Cases\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    href=\"/accounts/cases\" "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/accounts/cases",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Cases</a></li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/cases/my\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a href=\"/cases/my\"\n                    "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/cases/my",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">My Cases</a></li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/cases/new\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a href=\"/cases/new\"\n                    "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/cases/new",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Add Cases</a></li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/cases/masterlist\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a href=\"/cases/masterlist\"\n                    "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/cases/masterlist",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">All Cases</a></li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/cases/allocate\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a href=\"/cases/allocate\"\n                    "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/cases/allocate",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Allocate Cases</a></li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/cases/import\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a href=\"/cases/import\"\n                    "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/cases/import",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Import Cases</a></li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/reminders/cases\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a href=\"/reminders\"\n                    "
    + ((stack1 = (helpers.ifStartsWith || (depth0 && depth0.ifStartsWith) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reminders",(depth0 != null ? depth0.route : depth0),{"name":"ifStartsWith","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Reminders</a></li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <li>\n        <a href=\"/requests\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n            "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(alias1,"/requests",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/requests\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRequestsActive : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-pencil-square-o\"></i>Requests</a>\n    </li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return " class=\"active\"\n            ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <li>\n        <a href=\"/templates\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n            "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(alias1,"/templates",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/templates\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTemplatesActive : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-file-text\"></i> Templates</a>\n    </li>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "\n            active=\"active\" ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <li>\n        <a href=\"/calendar\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n            "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(alias1,"/calendar",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/calendar\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTemplatesActive : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-calendar\"></i> Calendar</a>\n    </li>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <li>\n        <a href=\"/websites\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n            "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(alias1,"/websites",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/websites\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTemplatesActive : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><i class=\"fa fa-fw fa-link\"></i> Useful Links</a>\n    </li>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a href=\"#timetrack-sub-menu\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isTimeTrackActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-toggle=\"collapse\"\n            aria-expanded=\"false\" aria-controls=\"timetrack-sub-menu\">\n            <i class=\"fa fa-fw fa-clock-o\"></i>Time\n        </a>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/timetrack/overview\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/timetrack/overview",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/timetrack/overview\">Overview</a></li>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/timetrack/entry\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/timetrack/entry",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/timetrack/entry\">Track\n                    Entry</a></li>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/timetrack/report\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/timetrack/report",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/timetrack/report\">TimeTrack\n                    Report</a></li>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a href=\"#reporting-sub-menu\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isReportingActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-toggle=\"collapse\"\n            aria-expanded=\"false\" aria-controls=\"reporting-sub-menu\">\n            <i class=\"fa fa-fw fa-bar-chart-o\"></i>Reporting\n        </a>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <hr />\n            <li><a href=\"/reporting/cases/individual/monthly\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"/reporting/cases/individual/monthly",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/cases/individual/monthly\">Individual\n                    Cases Monthly Report</a></li>\n            <hr />\n            <li><a href=\"/reporting/cases/individual/yearly\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"/reporting/cases/individual/yearly",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/cases/individual/yearly\">Individual\n                    Cases Yearly Report</a></li>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <hr />\n            <li><a href=\"/reporting/cases/company/activity\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"/reporting/cases/company/activity",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/cases/company/activity\">Company\n                    Activity Report</a></li>\n            <hr />\n            <li><a href=\"/reporting/cases/company/revenue\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"/reporting/cases/company/revenue",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/cases/company/revenue\">Company\n                    Revenue Report</a></li>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/reporting/cases/pipeline\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reporting/cases/pipeline",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/cases/pipeline\">Pipeline\n                    Report</a></li>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/reporting/cases\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reporting/cases",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/cases\">Case\n                    Report</a></li>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/reporting/commercial-tracking\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reporting/commercial-tracking",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/commercial-tracking\">Commercial\n                    Relationship Tracking Report</a></li>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/reporting/timetrack-edits\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reporting/timetrack-edits",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/timetrack-edits\">TimeTrack\n                    Edits Report</a></li>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/survey/report/1\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/survey/report/1",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/survey/report/1\">Client\n                    Feedback Report</a></li>\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/reporting/authentication\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/reporting/authentication",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/reporting/authentication\">Authentication\n                    Log</a></li>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a href=\"#admin-sub-menu\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAdminActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-toggle=\"collapse\" aria-expanded=\"false\"\n            aria-controls=\"admin-sub-menu\">\n            <i class=\"fa fa-fw fa-wrench\"></i>Admin\n        </a>\n";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/categories\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/categories",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/categories\">Edit Categories</a></li>\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/users/new\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/users/new",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/users/new\">Add User</a></li>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <hr />\n            <li><a href=\"/users\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"/users",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/users\">List Users</a></li>\n            <hr />\n            <li><a href=\"/clients\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"/clients",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/clients\">List Clients</a></li>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/permissions\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/permissions",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/permissions\">Permissions</a></li>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/company/targets\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/company/targets",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/company/targets\">Company\n                    targets</a></li>\n";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/websites/admin\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/websites/admin",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/websites/admin\">Useful\n                    Links</a></li>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/templates/admin\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/templates/admin",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/templates/admin\">Templates</a></li>\n";
},"77":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a href=\"#team-sub-menu\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isTeamActive : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-toggle=\"collapse\" aria-expanded=\"false\"\n            aria-controls=\"team-sub-menu\">\n            <i class=\"fa fa-fw fa-group\"></i>Teams\n        </a>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <hr />\n            <li><a href=\"/team\" class=\"popout-link\"><i class=\"fa fa-external-link\"></i></a><a\n                    "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/team",(depth0 != null ? depth0.route : depth0),{"name":"ifEqual","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " href=\"/team\">View Teams</a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<ul class=\"nav navbar-nav\" id=\"navigation-menu\">\n    <li>\n        <a class=\"sb-toggle-left\">\n            <i class=\"fa fa-fw fa-bars\"></i>\n        </a>\n    </li>\n\n    <li>\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_casefile\", \"add_casefile\",\n        \"view_all_casefile\", \"add_allocation\", \"view_reminder\",\n        \"view_client_casefile\", \"view_accounts_casefile\"]"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <ul class=\"nav tcas-sub-menu collapse "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCaseActive : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"cases-sub-menu\">\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_client_casefile",{"name":"hasPerm","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_accounts_casefile",{"name":"hasPerm","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_allocated_casefile",{"name":"hasPerm","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_casefile",{"name":"hasPerm","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_all_casefile\", \"view_team_casefile\"]"},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_allocation",{"name":"hasPerm","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_casefile",{"name":"hasPerm","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_reminder",{"name":"hasPerm","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <hr />\n        </ul>\n    </li>\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_request",{"name":"hasPerm","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_templates",{"name":"hasPerm","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_calendar",{"name":"hasPerm","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_website",{"name":"hasPerm","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <li>\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_timetrack\", \"add_timetrack\"]"},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <ul class=\"nav tcas-sub-menu collapse "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTimeTrackActive : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"timetrack-sub-menu\">\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_timetrack",{"name":"hasPerm","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_timetrack",{"name":"hasPerm","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_timetrack_report",{"name":"hasPerm","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n    </li>\n\n    <li>\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"view_individual_report\", \"view_company_report\", \"view_pipeline_report\",\n        \"view_case_report\", \"view_commercial_report\", \"view_timetrack_report\", \"view_authenticationlog\"]"},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <ul class=\"nav tcas-sub-menu collapse "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isReportingActive : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"reporting-sub-menu\">\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_individual_report",{"name":"hasPerm","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_company_report",{"name":"hasPerm","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_pipeline_report",{"name":"hasPerm","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_case_report",{"name":"hasPerm","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_commercial_report",{"name":"hasPerm","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_timetrack_report",{"name":"hasPerm","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_survey_report",{"name":"hasPerm","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_authenticationlog",{"name":"hasPerm","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <hr />\n        </ul>\n    </li>\n\n    <li>\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"change_category\", \"add_user\", \"view_users\", \"add_template\", \"change_permission\",\n        \"add_website\"]"},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <ul class=\"nav tcas-sub-menu collapse "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdminActive : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"admin-sub-menu\">\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_casefile",{"name":"hasPerm","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_user",{"name":"hasPerm","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_users",{"name":"hasPerm","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"change_permission",{"name":"hasPerm","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_companytarget",{"name":"hasPerm","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_website",{"name":"hasPerm","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_template",{"name":"hasPerm","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <hr />\n        </ul>\n    </li>\n\n    <li>\n"
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias2).call(alias1,{"name":"hasPerms","hash":{"perms":"[\"change_team\", \"change_permission\"]"},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <ul class=\"nav tcas-sub-menu collapse "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTeamActive : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"team-sub-menu\">\n\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"change_team",{"name":"hasPerm","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <hr />\n        </ul>\n    </li>\n</ul>";
},"useData":true});
