'use strict';
var BaseCollection = require('../base-collection');
var TeamTarget = require('./teamtarget');

var TeamTargets = BaseCollection.extend({
  model: TeamTarget,
  urlRoot: '/api/teamtargets/',
  comparator: 'id'
});

module.exports = TeamTargets;
