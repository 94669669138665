'use strict';
var template = require('../../templates/reporting/report-casefile.hbs');
var Spinner = require('spin');
var ReportCaseFileList = require('./report-casefile-list');
var alertTemplate = require('../../templates/alert.hbs');
var Events = require('../../models/cases/events');
var ReportList = require('../../models/reporting/report-list');
var ReportItem = require('../../models/reporting/report-item');
var User = require('../../models/users/user');

var CaseFileReportView = Backbone.Marionette.CompositeView.extend({
  template: template,
  initialize: function() {
    this.reportGeneral = null;
    this.reportPersonnel = null;
    this.listenTo(TCAS.archiveDataTokens, 'sync', this.render);
  },
  ui: {
    start: '#start',
    end: '#end',
    submit: '#submit',
    submitPersonnel: '#submit-personnel',
    submitTeam: '#submit-team',
    csvExport: '#export-csv',
    alertArea: '#alert-area',
    user: '#user',
    team: '#team',
    reportType: '.report-type',
    companyGroup: '.company-group',
    personnelGroup: '.personnel-group',
    teamGroup: '.team-group',
  },
  events: {
    'click @ui.submit': 'generateReport',
    'click @ui.submitPersonnel': 'generatePersonnelReport',
    'click @ui.submitTeam': 'generateTeamReport',
    'click @ui.csvExport': 'exportReportCSV',
    'change @ui.reportType': 'reportTypeChange',
  },
  reportTypeChange: function() {
    var that = this;
    var type = this.ui.reportType.val();
    switch (type) {
      case 'company':
        that.ui.companyGroup.show();
        that.ui.personnelGroup.hide();
        that.ui.teamGroup.hide();
        break;
      case 'personnel':
        that.ui.companyGroup.hide();
        that.ui.personnelGroup.show();
        that.ui.teamGroup.hide();
        break;
      case 'team':
        that.ui.companyGroup.hide();
        that.ui.personnelGroup.hide();
        that.ui.teamGroup.show();
        break;
    }
  },
  onRender: function() {
    this.ui.start.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    });

    this.ui.end.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    });

    this.eventsList = new Backbone.Marionette.Region({
      el: this.$('#reporting-area')[0],
    });

    function formatUser(user, container, query) {
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q) {
        return '<u>' + q + '</u>';
      });
      return s;
    }

    function formatTeam(team, container, query) {
      var s = team.name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q) {
        return '<u>' + q + '</u>';
      });
      return s;
    }

    var caseWorkersUrl = '/api/users/?groups=' + User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.user.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseWorkersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page) {
          return { search: term, page: page };
        },
        results: function(data) {
          return { results: data.results, more: !!data.next };
        },
        cache: true,
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
    });

    this.ui.team.select2({
      placeholder: 'Team name',
      minimumInputLength: 1,
      ajax: {
        url: '/api/teams/',
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page) {
          return { search: term, page: page };
        },
        results: function(data) {
          return { results: data.results, more: !!data.next };
        },
        cache: true,
      },
      formatResult: formatTeam,
      formatSelection: formatTeam,
      allowClear: true,
    });

    this.ui.companyGroup.hide();
    this.ui.personnelGroup.hide();
    this.ui.teamGroup.hide();
  },
  generateReport: function(e) {
    e.preventDefault();
    var that = this;
    var start = this.ui.start.val();
    var end = this.ui.end.val();

    if (start === '' || end === '') {
      that.ui.alertArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'Please ensure both dates are selected.',
          type: 'danger',
        })
      );
      return false;
    }

    var events = new Events([], {
      urlRoot:
        '/api/events/' +
        '?created_after=' +
        start +
        '&created_before=' +
        end +
        '&type=0' +
        '&page_size=100000',
    });

    events.fetch().then(function() {
      var data = that.processEvents(events);
      that.reportGeneral = data;
      that.reportPersonnel = null;
      that.reportTeam = null;
      var reportList = new ReportCaseFileList({
        showUser: false,
        showTeams: false,
        collection: data,
      });
      that.eventsList.show(reportList);
    });
    return false;
  },
  generatePersonnelReport: function(e) {
    e.preventDefault();
    var that = this;
    var start = this.ui.start.val();
    var end = this.ui.end.val();

    if (start === '' || end === '') {
      that.ui.alertArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'Please ensure both dates are selected.',
          type: 'danger',
        })
      );
      return false;
    }

    var urlRoot =
      '/api/events/' +
      '?created_after=' +
      start +
      '&created_before=' +
      end +
      '&type=0' +
      '&page_size=100000';

    if (this.ui.user.val()) {
      console.log(that.ui.user.val());
      urlRoot += '&user=' + that.ui.user.val();
    }

    var events = new Events([], { urlRoot: urlRoot });
    events.fetch().then(function() {
      var data = that.processEventsByUser(events);
      that.reportPersonnel = data;
      that.reportGeneral = null;
      that.reportTeam = null;
      var reportList = new ReportCaseFileList({
        showUsers: true,
        showTeams: true,
        collection: data,
      });
      that.eventsList.show(reportList);
    });
    return false;
  },
  generateTeamReport: function(e) {
    e.preventDefault();
    var that = this;
    var start = this.ui.start.val();
    var end = this.ui.end.val();

    if (start === '' || end === '') {
      that.ui.alertArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'Please ensure both dates are selected.',
          type: 'danger',
        })
      );
      return false;
    }

    var urlRoot =
      '/api/events/' +
      '?created_after=' +
      start +
      '&created_before=' +
      end +
      '&type=0' +
      '&page_size=100000';

    if (this.ui.team.val()) {
      console.log(that.ui.team.val());
      urlRoot += '&user__userdetail__team=' + that.ui.team.val();
    }

    var events = new Events([], { urlRoot: urlRoot });
    events.fetch().then(function() {
      var data = that.processEventsByTeam(events);
      that.reportPersonnel = null;
      that.reportGeneral = null;
      that.reportTeam = data;
      var reportList = new ReportCaseFileList({
        showTeams: true,
        showUsers: false,
        collection: data,
      });
      that.eventsList.show(reportList);
    });
    return false;
  },
  processEvents: function(events) {
    var that = this;
    var i = 0;

    var monthBuckets = events.groupBy(function(event) {
      var created = event.get('created');
      var date = new Date(created);
      var mon =
        (date.getMonth() + 1).toString().length > 1
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1).toString();
      var bucketID = mon + '/' + date.getFullYear();
      return bucketID;
    });

    var coll = new ReportList();
    _.forEach(monthBuckets, function(item, key) {
      i = i + 1;
      var reportItem = new ReportItem({
        id: i,
        key: that.fixMonth(key),
      });

      var eventBuckets = _.groupBy(item, function(event) {
        return event.get('event');
      });

      _.forEach(eventBuckets, function(item, key) {
        var evCollection = new Events(item);
        reportItem.set('event_' + key, evCollection);
      });

      coll.add(reportItem);
    });
    coll.setSortOrder('key');
    coll.orderBy = 'key';
    return coll;
  },
  processEventsByUser: function(events) {
    var that = this;
    var coll = new ReportList();
    var i = 0;

    var teamBuckets = events.groupBy(function(event) {
      var user = TCAS.users.findWhere({ id: event.get('user') });
      if (user === undefined) {
        return -1;
      }
      return user.get('userdetail').get('team');
    });

    _.forEach(teamBuckets, function(teamBucket, teamId) {
      // group by users

      var userBuckets = _.groupBy(teamBucket, function(event) {
        return event.get('user');
      });

      _.forEach(userBuckets, function(userBucket, userId) {
        var monthBuckets = _.groupBy(userBucket, function(event) {
          var created = event.get('created');
          var date = new Date(created);
          var mon =
            (date.getMonth() + 1).toString().length > 1
              ? date.getMonth() + 1
              : '0' + (date.getMonth() + 1).toString();
          var bucketID = mon + '/' + date.getFullYear();
          return bucketID;
        });

        _.forEach(monthBuckets, function(monthBucket, monthId) {
          monthId = that.fixMonth(monthId);

          var eventBuckets = _.groupBy(monthBucket, function(event) {
            return event.get('event');
          });

          var reportItem = new ReportItem();

          _.forEach(eventBuckets, function(item, key) {
            i = i + 1;

            var evCollection = new Events(item);
            reportItem.set('event_' + key, evCollection);
            reportItem.set('id', i);
            reportItem.set('key', monthId);

            reportItem.set('user', userId);
            if (teamId > -1) {
              reportItem.set('team', teamId);
            } else {
              reportItem.set('team', '-');
            }
          });

          coll.add(reportItem);
        });
      });
    });

    coll.setSortOrder('key');
    coll.orderBy = 'key';
    return coll;
  },
  processEventsByTeam: function(events) {
    var that = this;
    var coll = new ReportList();
    var i = 0;

    var teamBuckets = events.groupBy(function(event) {
      var user = TCAS.users.findWhere({ id: event.get('user') });
      if (user === undefined) {
        return -1;
      }
      return user.get('userdetail').get('team');
    });

    _.forEach(teamBuckets, function(teamBucket, teamId) {
      // group by users

      var monthBuckets = _.groupBy(teamBucket, function(event) {
        var created = event.get('created');
        var date = new Date(created);
        var mon =
          (date.getMonth() + 1).toString().length > 1
            ? date.getMonth() + 1
            : '0' + (date.getMonth() + 1).toString();
        var bucketID = mon + '/' + date.getFullYear();
        return bucketID;
      });

      _.forEach(monthBuckets, function(monthBucket, monthId) {
        monthId = that.fixMonth(monthId);

        var eventBuckets = _.groupBy(monthBucket, function(event) {
          return event.get('event');
        });

        var reportItem = new ReportItem();

        _.forEach(eventBuckets, function(item, key) {
          i = i + 1;
          var evCollection = new Events(item);
          reportItem.set('event_' + key, evCollection);
          reportItem.set('id', i);
          reportItem.set('key', monthId);
          reportItem.set('team', teamId);
        });

        coll.add(reportItem);
      });
    });

    coll.setSortOrder('key');
    coll.orderBy = 'key';
    return coll;
  },
  fixMonth: function(monthId) {
    var arr = monthId.split('/');
    var mon = CaseFileReportView.MONTHS[arr[0]];

    return mon + ' ' + arr[1];
  },
  calcIncome: function(coll) {
    var total = 0.0;
    coll.forEach(function(value) {
      var obj;
      try {
        obj = JSON.parse(value.get('details'));
      } catch (err) {
        obj = value.get('details');
      }
      total += obj.income_value;
    });
    return total;
  },
  exportReportCSV: function(e) {
    e.preventDefault();
    // check for valid sensitive data token,
    // only allow CSV export if in sensitive data session
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doExportReportCSV(sdToken);
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }

    return false;
  },
  doExportReportCSV: function() {
    var spinner = new Spinner().spin(this.$el[0]);
    var data = null;
    var that = this;
    var csvString = '';

    if (that.reportGeneral !== null && that.reportPersonnel === null && that.reportTeam === null) {
      // general report
      data = that.reportGeneral;

      //csvString = 'Month, Sign Off, Assets Value, Percentage Fee, ' +
      //  'FA Sent, In Claims, Disclosure Sent, Case Booked, Case Concluded' +
      //  '\n';
      csvString =
        'Month, Allocations, Sign Off, Valuations, ' +
        'FA Sent, In Claims, ' +
        'Disclosure Sent, Case Booked, Case Concluded, Case Handback, ' +
        'Add New Case' +
        '\n';

      data.forEach(function(item) {
        csvString += '"' + item.get('key') + '",';
        csvString += item.get('event_0') ? '"' + item.get('event_0').length + '",' : '"0",';
        csvString += item.get('event_1') ? '"' + item.get('event_1').length + '",' : '"0",';
        csvString += item.get('event_2')
          ? '"' + that.calcIncome(item.get('event_2')) + '",'
          : '"0",';
        //csvString += item.get('event_3') ?
        //'"' + item.get('event_3').length + '",' : '"0",';
        csvString += item.get('event_4') ? '"' + item.get('event_4').length + '",' : '"0",';
        csvString += item.get('event_5') ? '"' + item.get('event_5').length + '",' : '"0",';
        csvString += item.get('event_6') ? '"' + item.get('event_6').length + '",' : '"0",';
        csvString += item.get('event_7') ? '"' + item.get('event_7').length + '",' : '"0",';
        csvString += item.get('event_8') ? '"' + item.get('event_8').length + '",' : '"0",';
        csvString += item.get('event_9') ? '"' + item.get('event_9').length + '",' : '"0",';
        csvString += item.get('event_10') ? '"' + item.get('event_10').length + '"' : '"0"';

        csvString += '\n';
      });
    }
    if (that.reportPersonnel !== null && that.reportGeneral === null && that.reportTeam === null) {
      // personnel report
      data = that.reportPersonnel;

      //csvString='User, Team, Month, Sign Off, Assets Value, Percentage Fee, '+
      //  'FA Sent, In Claims, Disclosure Sent, Case Booked, Case Concluded' +
      //  '\n';

      csvString =
        'User, Team, Month, Allocations, Sign Off, Valuations, ' +
        'FA Sent, ' +
        'In Claims, Disclosure Sent, Case Booked, Case Concluded, ' +
        'Case Handback, Add New Case' +
        '\n';

      data.forEach(function(item) {
        var user = TCAS.users.get(item.get('user'));
        var team = item.get('team');

        if (typeof user === 'undefined') {
          user = item.get('user');
        } else {
          user = user.getFullName();
        }

        team = TCAS.teams.get(team);
        if (team) {
          team = team.get('name');
        } else {
          team = '-';
        }

        csvString += '"' + user + '",';
        csvString += '"' + team + '",';
        csvString += '"' + item.get('key') + '",';
        csvString += item.get('event_0') ? '"' + item.get('event_0').length + '",' : '"0",';
        csvString += item.get('event_1') ? '"' + item.get('event_1').length + '",' : '"0",';
        csvString += item.get('event_2')
          ? '"' + that.calcIncome(item.get('event_2')) + '",'
          : '"0",';
        //csvString += item.get('event_3') ?
        //'"' + item.get('event_3').length + '",' : '"0",';
        csvString += item.get('event_4') ? '"' + item.get('event_4').length + '",' : '"0",';
        csvString += item.get('event_5') ? '"' + item.get('event_5').length + '",' : '"0",';
        csvString += item.get('event_6') ? '"' + item.get('event_6').length + '",' : '"0",';
        csvString += item.get('event_7') ? '"' + item.get('event_7').length + '",' : '"0",';
        csvString += item.get('event_8') ? '"' + item.get('event_8').length + '",' : '"0",';
        csvString += item.get('event_9') ? '"' + item.get('event_9').length + '",' : '"0",';
        csvString += item.get('event_10') ? '"' + item.get('event_10').length + '"' : '"0"';

        csvString += '\n';
      });
    }

    if (that.reportPersonnel === null && that.reportGeneral === null && that.reportTeam !== null) {
      // personnel report
      data = that.reportTeam;

      //csvString = 'Team, Month, Sign Off, Assets Value, Percentage Fee, '+
      //'FA Sent, In Claims, Disclosure Sent, Case Booked, Case Concluded' +
      //'\n';

      csvString =
        'Team, Month, Allocations, Sign Off, Valuations, ' +
        'FA Sent, In Claims, ' +
        'Disclosure Sent, Case Booked, Case Concluded, Case Handback, ' +
        'Add New Case' +
        '\n';

      data.forEach(function(item) {
        var team = item.get('team');

        team = TCAS.teams.get(team);
        if (team) {
          team = team.get('name');
        } else {
          team = '-';
        }

        csvString += '"' + team + '",';
        csvString += '"' + item.get('key') + '",';
        csvString += item.get('event_0') ? '"' + item.get('event_0').length + '",' : '"0",';
        csvString += item.get('event_1') ? '"' + item.get('event_1').length + '",' : '"0",';
        csvString += item.get('event_2')
          ? '"' + that.calcIncome(item.get('event_2')) + '",'
          : '"0",';
        //csvString += item.get('event_3') ?
        //'"' + item.get('event_3').length + '",' : '"0",';
        csvString += item.get('event_4') ? '"' + item.get('event_4').length + '",' : '"0",';
        csvString += item.get('event_5') ? '"' + item.get('event_5').length + '",' : '"0",';
        csvString += item.get('event_6') ? '"' + item.get('event_6').length + '",' : '"0",';
        csvString += item.get('event_7') ? '"' + item.get('event_7').length + '",' : '"0",';
        csvString += item.get('event_8') ? '"' + item.get('event_8').length + '",' : '"0",';
        csvString += item.get('event_9') ? '"' + item.get('event_9').length + '",' : '"0",';
        csvString += item.get('event_10') ? '"' + item.get('event_10').length + '"' : '"0"';

        csvString += '\n';
      });
    }

    var csvData = {
      content: csvString,
      filename: 'casefile_report',
    };

    $.ajax({
      url: '/api/tocsv',
      data: csvData,
      type: 'post',
      success: function(data) {
        spinner.stop();
        window.location = data;
      },
      error: function(e) {
        console.log(e);
      },
    });

    return false;
  },
  serializeData: function() {
    var archiveDataToken = TCAS.request('archiveDataToken');
    var data = { isArchiveDataSession: !!archiveDataToken };
    return data;
  },
});

CaseFileReportView.MONTHS = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

module.exports = CaseFileReportView;
