'use strict';

var Dropzone = require('dropzone');

var template = require('../../templates/cases/casefile-detail-client.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var MessageListView = require('../messages/message-list');
var MilestoneListView = require('../milestones/milestone-list');
var ClientMilestoneListView = require('../milestones/client-milestone-list');
var ClientInfoView = require('../clients/client-info');
var FileListView = require('./file-list');
var SurveyResponseFormView = require('../survey/survey-response-form');
var CaseProspects = require('../../models/cases/caseprospects');
var MilestoneTypes = require('../../models/cases/milestonetypes');
var ClientMilestoneTypes = require('../../models/cases/clientmilestonetypes');
var Survey = require('../../models/survey/survey');
var Questions = require('../../models/survey/questions');
var Answers = require('../../models/survey/answers');
var Util = require('../../util');

var CaseFileDetailClientView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    messageListRegion: '.message-list-container',
    fileListRegion: '.file-list-container',
    milestonesListRegion: '.milestones-list-container',
    clientMilestonesListRegion: '.client-milestones-list-container',
    contactCardRegion: '.client-contact-card-container',
    clientQuestionnaireRegion: '.client-questionnaire-container'
  },
  ui: {
    filesPanel: '#files',
    dropzonePreviews: '.dropzone-previews',
    addFile: '.add-file',
    caseProgress: '.casefile-progress .progress-bar',
    clientProgress: '.client-progress .progress-bar',
    caseProgressTitle: '.casefile-progress .panel-title',
    clientProgressTitle: '.client-progress .panel-title',
    messagesPanelTitle: '.casefile-notes .panel-title',
    filesPanelTitle: '.casefile-files .files-title',
    panelQuestionnaire: '.panel-questionnaire',
    entitlementFraction: '.entitlement-fraction',
    entitlementValue: '.entitlement-value'
  },
  showClientQuestionnaire: function() {
    var that = this;
    // ensure the questionnaire panel is shown
    this.ui.panelQuestionnaire.removeClass('hidden');
    var clientId = this.client.get('id');
    var survey = new Survey();
    survey.set('id', 1);
    var questions = new Questions();
    var answers = new Answers();
    questions.setFilters({
      survey: 1
    });
    answers.setFilters({
      question__survey: 1,
      client: clientId
    });
    // fetch questions and answers
    $.when.apply($, [questions.fetch(), answers.fetch()]).done(function() {
      that.clientQuestionnaireRegion.show(new SurveyResponseFormView({
        collection: questions,
        answers: answers,
        client: that.client,
        casefileId: that.model.get('id'),
        surveyId: 1,
        showDateField: false,
        showFile: false,
        showEdit: answers.length > 0,
        editable: answers.length === 0,
        showEmailSurveyButton: false,
        showThanksPageOnSubmit: true
      }));
      // if no answers yet, add a badge to the tab to highlight survey is still to be completed
      if (answers.length === 0) {
        that.ui.panelQuestionnaire.find('.panel-title').append(' <span class="badge badge-danger" title="Questionnaire still to be completed">1</span>');
        that.$('[title]').tooltip();
      }
    });
  },
  onShow: function() {
    var that = this;
    var lastViewed = this.getOption('lastViewed');

    var caseProspects = new CaseProspects([], {
      urlRoot: '/api/caseprospects/?casefile=' + this.model.get('id')
    });
    caseProspects.fetch().then(function() {
      var client = caseProspects.find(function(caseProspect) {
        return caseProspect.get('user') ? caseProspect.get('user').get('id') == TCAS.session.get('id') : false;
      });
      that.client = client;

      that.contactCardRegion.show(new ClientInfoView({ model: client }));

      // calculate and show client distribution
      var entitlementUrl = client.url() + '/entitlement_info/';
      $.getJSON(entitlementUrl, function(data) {
        that.ui.entitlementFraction.html(data.entitlement_numerator + ' / ' + data.entitlement_denominator);
        that.ui.entitlementValue.html(Util.formatCurrency(data.client_distribution));
      });

      // fetch and render messages
      that.model.fetchMessages().then(function(){
        var messages = that.model.get('messages');

        // work out how many new messages since last visit to show as badge in messages accordion header
        var newMessageCount = 0;
        messages.each(function(message) {
          var created = new Date(message.get('created'));
          if (message.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
            newMessageCount += 1;
          }
        });
        if (newMessageCount > 0) {
          that.ui.messagesPanelTitle.append(' <span class="badge badge-danger" title="New messages since last viewed">' + newMessageCount + '</span>');
          that.ui.messagesPanelTitle.find('.badge').tooltip();
        }

        var messageListView = new MessageListView({
          collection: messages,
          casefile: that.model,
          clientId: client.get('id'),
          highlightMessage: that.getOption('highlightMessage') || false,
          filterFunction: function(message) {
            return message.get('fao_clients').indexOf(client.get('id')) !== -1;
          },
          lastViewed: lastViewed
        });
        that.messageListRegion.show(messageListView);
      });

      // fetch and render client milestones
      client.fetchMilestones().then(function() {
        var achievedMilestones = 0;
        var milestoneTypes = new ClientMilestoneTypes();
        var newMilestonesCount = 0;
        var isClientPaymentMade = false;
        milestoneTypes.fetch().then(function () {
          // check which milestones already exist
          milestoneTypes.forEach(function (milestoneType) {
            milestoneType.set('checked', false);
            var milestone = client.get('milestones').find(function(milestone) {
              return milestone.get('client_milestone_type') === milestoneType.get('id') &&
                (milestone.get('is_active') || milestone.get('is_not_applicable'));
            });
            if (milestone) {
              var created = new Date(milestone.get('created'));
              if (created > lastViewed) {
                newMilestonesCount += 1;
              }

              milestoneType.set('milestone', milestone);
              if (milestone.get('is_active')) {
                milestoneType.set('checked', true);
              }
              if (milestone.get('is_not_applicable')) {
                milestoneType.set('is_not_applicable', true);
              }

              milestoneType.set('created', milestone.get('created'));
              achievedMilestones += 1;

              if (milestoneType.get('id') === 5) { // client payment made
                isClientPaymentMade = true;
              }
            }
          });

          if (newMilestonesCount > 0) {
            that.ui.clientProgressTitle.find('.badge').remove();
            that.ui.clientProgressTitle.append(' <span class="badge badge-danger" title="New client milestones since last viewed">' + newMilestonesCount + '</span>');
            that.ui.clientProgressTitle.find('.badge').tooltip();
          }

          var milestoneListView = new ClientMilestoneListView({
            collection: milestoneTypes,
            client: client,
            casefile: that.model,
            isClient: true
          });
          that.clientMilestonesListRegion.show(milestoneListView);

          var percent = Math.floor((achievedMilestones / milestoneTypes.length) * 100);
          that.ui.clientProgress.css('width', percent + '%').text(percent + '%').attr('aria-valuenow', percent);

          if (isClientPaymentMade) {
            that.showClientQuestionnaire();
          }
        });
      });
    });

    // fetch and render overall case milestones, limiting to only client viewable milestones
    this.model.fetchMilestones().then(function() {
      var milestoneTypes = new MilestoneTypes([], {
        urlRoot: '/api/categories/' + that.model.get('category') +
        '/milestones/?ordering=ordering_id&client_viewable=1'
      });

      var newMilestonesCount = 0;

      milestoneTypes.fetch().then(function () {
        var countApplied = 0;
        // check which milestones already exist
        milestoneTypes.forEach(function (milestoneType) {
          milestoneType.set('checked', false);
          var milestone = that.model.get('milestones').findWhere({
            milestone_type: milestoneType.get('id'), // jshint ignore:line
            is_active: true // jshint ignore:line
          });
          if (milestone) {
            var created = new Date(milestone.get('created'));
            if (created > lastViewed) {
              newMilestonesCount += 1;
            }
            milestoneType.set('milestone', milestone);
            if (milestone.get('is_not_applicable')) {
              milestoneType.set('is_not_applicable', true);
            }
            milestoneType.set('checked', true);
            milestoneType.set('created', milestone.get('created'));
            countApplied += 1;
          }
        });

        if (newMilestonesCount > 0) {
          that.ui.caseProgressTitle.find('.badge').remove();
          that.ui.caseProgressTitle.append(' <span class="badge badge-danger" title="New case milestones since last viewed">' + newMilestonesCount + '</span>');
          that.ui.caseProgressTitle.find('.badge').tooltip();
        }

        var milestoneListView = new MilestoneListView({
          collection: milestoneTypes,
          casefile: that.model,
          isClient: true
        });
        that.milestonesListRegion.show(milestoneListView);
        var percent = 0;
        if (countApplied) {
          percent = Math.floor((countApplied / milestoneTypes.length) * 100);
        }

        that.ui.caseProgress.css('width', percent + '%').text(percent + '%').attr('aria-valuenow', percent);
      });
    });

    var fileListView = new FileListView({
      collection: this.model.get('files'),
      casefile: this.model,
      clients: caseProspects,
      isClient: true,
      lastViewed: lastViewed
    });
    this.listenTo(fileListView, 'fileDrag', function () {
      this.ui.clientsPanel.collapse('show');
    });
    this.fileListRegion.show(fileListView);
    this.model.fetchFiles().then(function() {
      // work out how many new files since last visit to show as badge in files accordion header
      var newFilesCount = 0;
      that.model.get('files').each(function(file) {
        var created = new Date(file.get('created'));
        if (file.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
          newFilesCount += 1;
        }
      });
      if (newFilesCount > 0) {
        that.ui.filesPanelTitle.find('.badge').remove();
        that.ui.filesPanelTitle.append(' <span class="badge badge-danger" title="New files since last viewed">' + newFilesCount + '</span>');
        that.ui.filesPanelTitle.find('.badge').tooltip();
      }
    });
    this.listenTo(caseProspects, 'sync', function() {
      that.fileListRegion.show(fileListView);
    });

    //configure dropzone
    this.dropzone = new Dropzone(this.ui.addFile[0], {
      url: '/api/cases/' + this.model.get('id') + '/files/',
      headers: {'X-CSRFToken': TCAS.csrfToken},
      previewsContainer: this.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      maxFilesize: 500,
      acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,.ged',
      clickable: this.ui.addFile[0],
      accept: function(file, done){
        var alphaNumericPattern = new RegExp(
          '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
        );
        // check for only alphanumeric characters
        if (alphaNumericPattern.test(file.name)) {
          done();
        } else {
          done('This file has unexpected characters, ' +
            'file names must use the correct file naming structure and ' +
            'only contain characters consisting of: ' +
            'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
            'apostrophes, commas or spaces.');
        }
      }
    });
    this.dropzone.on('success', function(file){
      that.model.get('files').fetch().then(function(){
        that.$(file.previewElement).remove();
        that.fileListRegion.currentView.render();
      });
    });
    this.dropzone.on('error', function(file){
      that.$(file.previewElement).find('button').removeClass('hide');
    });
    this.dropzone.on('uploadprogress', function(file, progress){
      console.log('file upload progress: '+progress);
    });
    this.dropzone.on('sending', function(file, xhr, formData) {
      // Show the total progress bar when upload starts
      that.$(file.previewElement).find('.progress').removeClass('hide');
      formData.append('is_casefile_direct_upload', 1);
    });
    this.dropzone.on('dragleave', function(){
      that.ui.addFile.removeClass('list-group-item-success');
    });
    this.dropzone.on('drop', function(){
      that.ui.addFile.removeClass('list-group-item-success');
    });

    // listen for messsages show event to calculate message sizes
    this.$('#client-messages').on('shown.bs.collapse', function () {
      that.messageListRegion.currentView.checkMessageHeight();
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.caseManagerId = this.model.getCurrentAllocation().get('case_manager');
    return data;
  }
});

module.exports = CaseFileDetailClientView;
