'use strict';

var template = require(
  '../../templates/milestones/milestones-conditions-modal.hbs');

var MilestoneConditionsModalView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    conditionsList: '.conditions-modal-list',
    modal: '.conditions-modal'
  },
  onRender: function() {
    var conditions = this.getOption('conditionList');
    var html = '';

    // if this is a council case, some milestones require renaming
    var casefile = this.getOption('casefile');
    var categoryGroup = TCAS.reqres.request('categoryGroup', casefile.get('category'));
    var isCouncilCase = categoryGroup.isCouncilCase();

    conditions.forEach(function(condition) {
      if(condition.get('checked')) {
        html += '<tr>';
      } else {
        html += '<tr class="danger">';
      }
      html += '<td>';

      var milestoneType = condition.get('id');
      if (isCouncilCase && [7, 8, 26].indexOf(milestoneType) !== -1) {
        if (milestoneType === 7) {
          html += 'PR/NoK Engagement Pack Sent';
        } else if (milestoneType === 8) {
          html += 'PR/NoK Engagement Pack Received';
        } else if (milestoneType === 26) {
          html += 'PR/NoK Letter of Full Disclosure Sent';
        }
      } else {
        html += condition.get('name');
      }
      html += '</td>';

      if(condition.get('checked')) {
        html += '<td>&#x2714;</td>';
      } else {
        html += '<td><i class="fa fa-times"></i></td>';
      }
      html += '</tr>';
    });

    this.ui.conditionsList.html(html);
    this.$el.appendTo('body');
    this.ui.modal.modal('show');
  }
});

module.exports = MilestoneConditionsModalView;
