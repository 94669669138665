// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"3":function(container,depth0,helpers,partials,data) {
    return "1";
},"5":function(container,depth0,helpers,partials,data) {
    return "0";
},"7":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.revenue : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "  <td></td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<td><a href=\"/cases/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.casefile : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"popout\" target=\"_blank\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.casefile : depth0)) != null ? stack1.ref : stack1), depth0))
    + "</a></td>\n<td>"
    + alias2((helpers.leadingZeros || (depth0 && depth0.leadingZeros) || helpers.helperMissing).call(alias3,3,(depth0 != null ? depth0.case_element : depth0),{"name":"leadingZeros","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.date : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isFirstForCase : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.revenue : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
