'use strict';

var template = require('../../templates/cases/file-download-modal.hbs');

var FileDownloadModal = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'modal fade',
  events: {
    'submit form': 'onSubmit'
  },
  ui: {
    password: 'input[name="password"]',
    modalBody: '.modal-body'
  },
  initialize: function(){
    this.$el.attr('tabindex', '-1');
    this.$el.attr('role', 'dialog');
    this.$el.attr('aria-labelledby', 'confirm-modal-label');
    this.$el.attr('aria-hidden', 'true');
  },
  onSubmit: function(){
    var that = this;
    var password = this.ui.password.val();
    this.model.fetch({
      url: '/api/files/' + this.model.get('id') + '/?files_password=' + password,
      success: function(model){
        if (model.has('file_download')) {
          that.$('.form-group').html('<a href="'+model.get('file_download')+'" class="file-link">'+model.get('original_file_name')+'</a>');
          that.ui.modalBody.find('button').remove();
          that.trigger('passwordSuccess', password);
        } else {
          that.ui.modalBody.find('.alert').remove();
          that.ui.modalBody.find('.form-group').prepend('<div class="alert alert-danger alert-dismissable" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
            'Sorry, the password is incorrect, please try again</alert>');
        }
      }
    });
    return false;
  },
  onRender: function(){
    // only append to body once
    if (!this.$el.parent().length){
      this.$el.appendTo('body');
      this.$el.modal({
        backdrop: 'static'
      });
    } else {
      this.$el.modal('show');
    }

  }
});

module.exports = FileDownloadModal;