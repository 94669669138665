// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "      "
    + container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <td>"
    + container.escapeExpression((helpers.formatPercent || (depth0 && depth0.formatPercent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"table table-responsive\">\n  <thead>\n  <tr>\n    <th></th>\n    <th>Jan</th>\n    <th>Feb</th>\n    <th>Mar</th>\n    <th>Apr</th>\n    <th>May</th>\n    <th>Jun</th>\n    <th>Jul</th>\n    <th>Aug</th>\n    <th>Sep</th>\n    <th>Oct</th>\n    <th>Nov</th>\n    <th>Dec</th>\n  </tr>\n  </thead>\n  <tbody>\n  <tr>\n    <td>Actual - Asset</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.revenueAssetsByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td>Actual - Council & BV</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.revenueLAByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr class=\"row-bold\">\n    <td>Actual YTD</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.totalCumulativeByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td class=\"table-separator\" colspan=\"13\"></td>\n  </tr>\n  <tr>\n    <td>Minimum Revenue Phased Plan</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.minimumRevenuePhasedPlan : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr class=\"row-blue\">\n    <td>Minimum Revenue Phased YTD Plan %</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.minimumRevenuePhasedYTDPlan : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td class=\"table-separator\" colspan=\"13\"></td>\n  </tr>\n  <tr>\n    <td>Performance Target Phased Plan</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.performanceRevenuePhasedPlan : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr class=\"row-blue\">\n    <td>Performance Target Phased YTD Plan %</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.performanceRevenuePhasedYTDPlan : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr>\n    <td class=\"table-separator\" colspan=\"13\"></td>\n  </tr>\n  <tr>\n    <td>Stretch Target Phased Plan</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.stretchRevenuePhasedPlan : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  <tr class=\"row-blue\">\n    <td>Stretch Target Phased YTD Plan %</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.stretchRevenuePhasedYTDPlan : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </tr>\n  </tbody>\n</table>";
},"useData":true});
