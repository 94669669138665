'use strict';
var TemplatesRowView = require('./templates-row');
var template =
  require('../../templates/templates_repository/templates-list.hbs');
var GeneralFiles = require('../../models/reporting/generalfiles');

var TemplatesListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childViewContainer: 'tbody',
  childView: TemplatesRowView,
  childViewOptions: function() {
    return {
      showCreate: this.options.showCreate
    };
  },
  initialize: function() {
    var that = this;
    if(that.fullCollection === undefined) {
      that.fullCollection = that.collection;
    }
  },
  serializeData: function() {
    var data = {
      groupings: this.groupings
    };
    return data;
  },
  onShow: function() {
    var that = this;
    this.listenTo(TCAS.vent, 'remove:template', function(id) {
      that.collection.remove(id);
      that.render();
    });
  }
});

module.exports = TemplatesListView;