'use strict';

var Permissions = require('./permissions');
var User = require('./user');

var Session = Backbone.Model.extend({
  url: '/api/session/',
  initialize: function(){
    var that = this;

    this.permissions = new Permissions({});

    this.listenTo(this, 'change:id', function(user){
      if (user.get('id') > 0) {
        //fetch user permissions
        this.permissions = new Permissions({user: user});
        this.permissions.fetch().then(function(){
          that.trigger('authenticated');
        });
      } else {
        this.permissions.reset();
        this.trigger('unauthenticated');
      }
    });
    this.listenTo(this, 'error', function(){
      if (!this.isAuth()) {
        this.trigger('unauthenticated');
      }
    });
  },
  signOut: function() {
    var that = this;
    this.destroy({ wait: true, success: function() {
      that.clear();
      // no callbacks would have been triggered since destroying removes
      // listeners
      that.trigger('unauthenticated');
      that.initialize();
    }});
  },
  isAuth: function(){
    return this.get('id') > 0;
  },
  hasPerm: function(permissionName){

    if(this.get('is_superuser')) {
      return true;
    }

    var permission = this.permissions.find(function(p){
      return p.get('codename') === permissionName;
    });

    return !!permission;
  },
  isAdmin: function() {
    return this.hasGroup(User.GROUPS.ADMIN) || this.get('is_superuser');
  },
  hasGroup: function(groupId){
    if ((typeof groupId === 'number')) {
      groupId = [groupId];
    }
    return this.hasAnyGroups(groupId);
  },
  hasAnyGroups: function(groups){
    var userGroupIds = _.map(this.get('groups'), function(group){
      return group;
    });
    return _.intersection(userGroupIds, groups).length > 0;
  },
  getFullName: function() {
    var userdetail = this.get('userdetail');
    return userdetail.contact.first_name + ' ' + userdetail.contact.last_name;
  }
});

module.exports = Session;