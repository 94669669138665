'use strict';
var moment = require('moment');
var CreateCaseFileView = require('../views/cases/casefile-create');
var CaseFileDetailView = require('../views/cases/casefile-detail');
var CaseFileDetailClientView = require('../views/cases/casefile-detail-client');
var CaseFileDetailAccountsView = require('../views/cases/casefile-detail-accounts');
var ListCaseFiles = require('../views/cases/casefile-list');
var ListClientCaseFiles = require('../views/cases/client-casefile-list');
var CaseFileDeleted = require('../views/cases/casefile-deleted');
var CategoryLayoutView = require('../views/categories/category-layout');
var MainBodyLayout = require('../views/main-body-layout');
var MyCaseFilesLayout = require('../views/cases/my-casefiles-layout');
var CaseFile = require('../models/cases/casefile');
var CaseFiles = require('../models/cases/casefiles');
var Event = require('../models/cases/event');
var Events = require('../models/cases/events');
var ResearchTypes = require('../models/cases/researchtypes');
var RemindersView = require('../views/cases/reminders');
var RequestsView = require('../views/cases/requests');
var AllocateCaseFile = require('../views/cases/casefile-allocate');
var ImportCaseFiles = require('../views/cases/casefile-import');

var CasesController = Backbone.Marionette.Controller.extend({
  initialize: function(options) {
    this.app = options.app;
  },
  appRoutes: {
    'categories(/)': 'editCategories',
    'cases/new(/)': 'createCase',
    'cases/masterlist(/)': 'caseMasterlist',
    'cases/my(/)': 'myCases',
    'cases/import(/)': 'importCases',
    'cases/allocate(/)': 'allocateCasefile',
    'cases/(:id)/edit(/)': 'editCase',
    'cases/(:id)/messages/(:messageid)(/)': 'caseMessage',
    'cases/(:id)/prospects/(:prospectid)(/)': 'caseProspect',
    'cases/(:id)(/)': 'caseDetail',
    'accounts/cases(/)': 'accountsCases',
    'accounts/cases/(:id)(/)': 'caseDetailAccounts',
    'client/cases(/)': 'clientCases',
    'client/cases/(:id)(/)': 'caseDetailClient',
    'client/cases/(:id)/messages/(:messageid)(/)': 'caseMessageClient',
    'reminders(/)': 'reminders',
    'requests(/)': 'requests',
  },
  reminders: function() {
    if (!TCAS.session.hasPerm('view_reminder')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({
      title: 'Reminders',
      contentView: new RemindersView({ userId: TCAS.session.get('id') }),
    });
    this.app.mainBody.show(mainBodyLayout);

    document.title = 'Reminders';
  },
  createCase: function() {
    if (!TCAS.session.hasPerm('add_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var createCasefileView = new CreateCaseFileView({
      model: new CaseFile({}, { parse: true }),
      method: 'create',
    });

    var mainBodyLayout = new MainBodyLayout({
      title: 'Create new case',
      contentView: createCasefileView,
    });

    this.app.mainBody.show(mainBodyLayout);
    document.title = 'Create Case';
  },
  editCase: function(caseId) {
    if (!TCAS.session.hasPerm('change_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var that = this;
    var casefile = new CaseFile({
      id: caseId,
    });
    casefile.fetch().then(function() {
      var editCasefileView = new CreateCaseFileView({
        model: casefile,
        method: 'edit',
      });

      var mainBodyLayout = new MainBodyLayout({
        title: 'Edit case: ' + casefile.get('ref'),
        contentView: editCasefileView,
      });
      that.app.mainBody.show(mainBodyLayout);
    });
    document.title = 'Edit Case';
  },
  caseMasterlist: function() {
    if (!TCAS.session.hasPerm('view_all_casefile') && !TCAS.session.hasPerm('view_team_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var casefiles = new CaseFiles();
    var options = { collection: casefiles };

    // restrict to team fetching if user doesn't have view_all_casefile perm
    if (!TCAS.session.hasPerm('view_all_casefile')) {
      casefiles.setFilters({ team: TCAS.session.get('userdetail').team });
      options.filterTeam = TCAS.session.get('userdetail').team;
    }
    casefiles.fetch();

    var listCaseFiles = new ListCaseFiles(options);
    var mainBodyLayout = new MainBodyLayout({
      title: 'Case List',
      contentView: listCaseFiles,
    });
    this.app.mainBody.show(mainBodyLayout);
    document.title = 'Case Masterlist';
  },
  myCases: function() {
    if (!TCAS.session.hasPerm('view_allocated_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var casefiles = new CaseFiles([], {
      urlRoot: '/api/cases/?case_manager_or_partner=' + this.app.session.id,
    });
    casefiles.fetch();

    var listCaseFiles = new ListCaseFiles({
      collection: casefiles,
      excludeFilters: ['team', 'case_manager', 'worked_on'],
      hideAllocateForm: true,
    });
    var reminders = new RemindersView({ userId: TCAS.session.get('id') });

    var myCaseFilesLayout = new MyCaseFilesLayout({
      remindersView: reminders,
      caseFilesView: listCaseFiles,
    });

    this.app.mainBody.show(myCaseFilesLayout);
    document.title = 'My Cases';
  },
  importCases: function() {
    if (!TCAS.session.hasPerm('add_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var importCaseFiles = new ImportCaseFiles();
    var mainBodyLayout = new MainBodyLayout({
      title: 'Casefile Import',
      contentView: importCaseFiles,
    });
    this.app.mainBody.show(mainBodyLayout);
    document.title = 'Import Cases';
  },
  caseDetail: function(caseId, messageId, prospectId) {
    if (!TCAS.session.hasPerm('view_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var casefile = new CaseFile({
      id: caseId,
    });

    var that = this;

    casefile.fetch({
      success: function() {
        // first sync events to find last time the case was viewed
        var events = new Events([], {
          urlRoot:
            '/api/events/?case=' +
            caseId +
            '&event=' +
            Event.CASE_VIEWED +
            '&user=' +
            TCAS.session.get('id') +
            '&page_size=10000',
        });

        events.fetch().then(function() {
          var lastViewed = events.last();
          var caseFileDetail = new CaseFileDetailView({
            model: casefile,
            highlightMessage: messageId,
            highlightProspect: prospectId,
            lastViewed: lastViewed
              ? moment(new Date(lastViewed.get('created')))
                  .subtract(2, 'minutes')
                  .toDate()
              : new Date(0),
          });
          var mainBodyLayout = new MainBodyLayout({
            title: 'Case Details',
            contentView: caseFileDetail,
          });
          that.app.mainBody.show(mainBodyLayout);
          document.title = casefile.get('ref');

          // track case view event
          TCAS.commands.execute('trackEvent', Event.CASE_VIEWED, casefile.get('id'));
        });
      },
      error: function() {
        var sdToken = that.app.reqres.request('sensitiveDataToken');
        var maybeArchived = true;
        if (sdToken) {
          maybeArchived = false;
        }
        var mainBodyLayout = new MainBodyLayout({
          contentView: new CaseFileDeleted({
            maybeArchived: maybeArchived,
          }),
        });
        that.app.mainBody.show(mainBodyLayout);
        document.title = maybeArchived ? 'Case Archived or Deleted' : 'Case Deleted';
      },
    });
  },
  caseDetailClient: function(caseId, messageId, prospectId) {
    if (!TCAS.session.hasPerm('view_client_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var casefile = new CaseFile({
      id: caseId,
    });

    var that = this;

    function showCaseDetail() {
      // first sync events to find last time the case was viewed
      var events = new Events([], {
        urlRoot: '/api/events/?case=' + caseId + '&event=' + Event.CASE_VIEWED + '&page_size=10000',
      });
      events.fetch().then(function() {
        casefile.fetch({
          success: function() {
            var lastViewed = events.last();
            var caseFileDetail = new CaseFileDetailClientView({
              model: casefile,
              highlightMessage: messageId,
              lastViewed: lastViewed
                ? moment(new Date(lastViewed.get('created')))
                    .subtract(2, 'minutes')
                    .toDate()
                : new Date(0),
            });
            var mainBodyLayout = new MainBodyLayout({
              title: 'My Case',
              contentView: caseFileDetail,
            });
            that.app.mainBody.show(mainBodyLayout);
            document.title = 'My Case';

            // track case view event
            TCAS.commands.execute('trackEvent', Event.CASE_VIEWED, casefile.get('id'));
          },
          error: function() {
            var sdToken = that.app.reqres.request('sensitiveDataToken');
            var maybeArchived = true;
            if (sdToken) {
              maybeArchived = false;
            }
            var mainBodyLayout = new MainBodyLayout({
              contentView: new CaseFileDeleted({
                maybeArchived: maybeArchived,
              }),
            });
            that.app.mainBody.show(mainBodyLayout);
            document.title = maybeArchived ? 'Case Archived or Deleted' : 'Case Deleted';
          },
        });
      });
    }

    if (TCAS.users.length) {
      showCaseDetail();
    } else {
      this.listenToOnce(TCAS.users, 'sync', function() {
        showCaseDetail();
      });
    }
  },
  caseDetailAccounts: function(caseId, messageId, prospectId) {
    if (!TCAS.session.hasPerm('view_accounts_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var casefile = new CaseFile({
      id: caseId,
    });

    var that = this;

    function showCaseDetail() {
      // first sync events to find last time the case was viewed
      var events = new Events([], {
        urlRoot: '/api/events/?case=' + caseId + '&event=' + Event.CASE_VIEWED + '&page_size=10000',
      });
      events.fetch().then(function() {
        casefile.fetch({
          success: function() {
            var lastViewed = events.last();
            var caseFileDetail = new CaseFileDetailAccountsView({
              model: casefile,
              highlightMessage: messageId,
              lastViewed: lastViewed
                ? moment(new Date(lastViewed.get('created')))
                    .subtract(2, 'minutes')
                    .toDate()
                : new Date(0),
            });
            var mainBodyLayout = new MainBodyLayout({
              title: 'Case ' + casefile.get('ref'),
              contentView: caseFileDetail,
            });
            that.app.mainBody.show(mainBodyLayout);
            document.title = 'Case ' + casefile.get('ref');

            // track case view event
            TCAS.commands.execute('trackEvent', Event.CASE_VIEWED, casefile.get('id'));
          },
          error: function() {
            var sdToken = that.app.reqres.request('sensitiveDataToken');
            var maybeArchived = true;
            if (sdToken) {
              maybeArchived = false;
            }
            var mainBodyLayout = new MainBodyLayout({
              contentView: new CaseFileDeleted({
                maybeArchived: maybeArchived,
              }),
            });
            that.app.mainBody.show(mainBodyLayout);
            document.title = maybeArchived ? 'Case Archived or Deleted' : 'Case Deleted';
          },
        });
      });
    }

    if (TCAS.users.length) {
      showCaseDetail();
    } else {
      this.listenToOnce(TCAS.users, 'sync', function() {
        showCaseDetail();
      });
    }
  },
  accountsCases: function() {
    var that = this;
    if (!TCAS.session.hasPerm('view_accounts_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var casefiles = new CaseFiles([], {
      urlRoot: '/api/cases/?client=' + this.app.session.id,
    });
    casefiles.fetch().then(function() {
      if (casefiles.length === 1) {
        // only one case so go directly to the case detail
        TCAS.router.navigate('accounts/cases/' + casefiles.at(0).get('id'), { trigger: true });
      } else {
        var listClientCaseFiles = new ListClientCaseFiles({
          collection: casefiles,
          isAccounts: true,
        });

        var mainBodyLayout = new MainBodyLayout({
          title: 'Welcome to TCAS: Accounts Access Portal',
          subtitle:
            'Please click on your case below to access case details and contact the Case Manager',
          contentView: listClientCaseFiles,
        });
        that.app.mainBody.show(mainBodyLayout);

        document.title = 'Accounts Cases';
      }
    });
  },
  clientCases: function() {
    var that = this;
    if (!TCAS.session.hasPerm('view_client_casefile')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var casefiles = new CaseFiles([], {
      urlRoot: '/api/cases/?client=' + this.app.session.id,
    });
    casefiles.fetch().then(function() {
      if (casefiles.length === 1) {
        // only one case so go directly to the case detail
        TCAS.router.navigate('client/cases/' + casefiles.at(0).get('id'), { trigger: true });
      } else {
        var listClientCaseFiles = new ListClientCaseFiles({
          collection: casefiles,
        });

        var mainBodyLayout = new MainBodyLayout({
          title: 'Welcome to TCAS: Client Access Portal',
          subtitle:
            'Please click on your case below to access case details and contact your Case Manager',
          contentView: listClientCaseFiles,
        });
        that.app.mainBody.show(mainBodyLayout);

        document.title = 'Client Cases';
      }
    });
  },
  caseMessage: function(caseId, messageId) {
    this.caseDetail(caseId, messageId);
  },
  caseMessageClient: function(caseId, messageId) {
    this.caseDetailClient(caseId, messageId);
  },
  caseProspect: function(caseId, prospectId) {
    this.caseDetail(caseId, null, prospectId);
  },
  requests: function() {
    if (!TCAS.session.hasPerm('view_request')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({
      title: 'Requests',
      contentView: new RequestsView(),
    });
    this.app.mainBody.show(mainBodyLayout);
    document.title = 'Requests';
  },
  allocateCasefile: function(user, casefiles) {
    if (!TCAS.session.hasPerm('add_allocation')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var allocateCasefileView = new AllocateCaseFile({
      user: user,
      casefiles: casefiles,
    });
    var mainBodyLayout = new MainBodyLayout({
      title: 'Allocate Casefiles',
      contentView: allocateCasefileView,
    });
    this.app.mainBody.show(mainBodyLayout);
    document.title = 'Allocate Cases';
  },
  editCategories: function() {
    if (!TCAS.session.hasPerm('change_category')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    function genView() {
      var researchTypes = new ResearchTypes([]);
      researchTypes.fetch().then(function() {
        var categoriesList = new CategoryLayoutView({
          categories: TCAS.categories,
          categoryGroups: TCAS.categoryGroups,
          researchTypes: researchTypes,
        });

        var mainBodyLayout = new MainBodyLayout({
          title: 'Modify Categories',
          contentView: categoriesList,
        });
        TCAS.mainBody.show(mainBodyLayout);
      });
    }

    if (TCAS.categories) {
      genView();
    } else {
      this.listenToOnce(TCAS.categories, 'sync', genView);
    }
    document.title = 'Edit Categories';
  },
});

module.exports = CasesController;
