'use strict';
var HistoryRowView = require('./casefile-history-row');
var Event = require('../../models/cases/event');
var template = require('../../templates/history/casefile-history-list.hbs');
var alertTemplate = require('../../templates/alert.hbs');


var CaseFileHistoryView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: HistoryRowView,
  ui: {
    'alertsArea': '#alert-area'
  },
  childViewContainer: 'tbody.history-container',
  childEvents: {
    'child:saved': function() {
      this.render();

      this.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'Event saved successfully.',
          type: 'success'
        })
      );
    },
    'child:removed': function() {
      this.render();

      this.ui.alertsArea.html(
        alertTemplate({
          strong: 'Success!',
          message: 'Event removed successfully.',
          type: 'success'
        })
      );
    },
    'child:error': function() {
      this.render();

      this.ui.alertsArea.html(
        alertTemplate({
          strong: 'Error!',
          message: 'An error occured.',
          type: 'danger'
        })
      );
    }
  },
  filter: function(child, index) {
    // don't render certain events
    return[
      Event.CLIENT_FA_SENT,
      Event.CLIENT_PR_NOK,
      Event.CLIENT_FA_RECEIVED,
      Event.CLIENT_DISCLOSURE_SENT,
      Event.CLIENT_PAYMENT_MADE,
      Event.CLIENT_QUESTIONNAIRE_RETURNED,
      Event.CLIENT_EDIT
    ].indexOf(child.get('event')) === -1;
  },
});

module.exports = CaseFileHistoryView;