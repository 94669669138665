'use strict';

var SubmitForm = Backbone.Marionette.Behavior.extend({
  defaults: {
    'selector': 'form',
    'submitHandler': 'submitData'
  },
  events: function() {
    var eventKey = 'submit '+this.options.selector;
    var eventMap = {};
    eventMap[eventKey] = 'submitForm';
    return eventMap;
  },
  submitForm: function() {
    if (this.view.$(this.options.selector).valid()) {
      var data = this.view.$(this.options.selector).serializeAll();

      var dataObject = {};
      _.map(data, function(n, i) {
        dataObject[n.name] = n.value;
      });

      if (typeof this.view[this.options.submitHandler] !== 'function') {
        throw 'View is missing submit handler named '
          + this.options.submitHandler;
      }
      this.view[this.options.submitHandler](dataObject);
    }
    return false;
  }
});

var Validate = Backbone.Marionette.Behavior.extend({
  defaults: {
    selector: 'form',
    validateOptions: {}
  },
  onBeforeDestroy: function() {
    // cleanup listeners so no zombie views remain
    this.$('input,textarea,select').off('change');
  },
  onRender: function() {
    var that = this;
    var validateOptions = this.options.validateOptions;

    if (typeof this.options.validateOptions === 'function') {
      validateOptions = this.options.validateOptions(this);
    }

    this.view.$(this.options.selector).validate(validateOptions);

    var requiredFuncs = [];
    for (var propName in validateOptions.rules) {
      if (validateOptions.rules[propName].required) {
        var required = validateOptions.rules[propName].required;
        var isRequired = required;
        var $elem = this.view.$('input[name="' + propName + '"]');
        if (typeof required === 'function') {
          requiredFuncs.push({ required: required, elem: $elem });
          if (required($elem[0])) {
            isRequired = true;
          }
        } else if (typeof required === 'string') {
          requiredFuncs.push({ required: required, elem: $elem});
          isRequired = that.view.$(required).length > 0;
        }
        if (isRequired) {
          this.view.$('[name="' + propName + '"]').parents('.form-group')
            .find('.control-label')
            .append('<span class="required-indicator"> *</span>');
        }
      }
    }
    if (requiredFuncs.length) {
      this.$('input,textarea,select').on('change', function(){
        _.forEach(requiredFuncs, function(funcDetails) {
          var isRequired = false;
          if (typeof funcDetails.required === 'string') {
            isRequired = that.view.$(funcDetails.required).length > 0;
          } else {
            isRequired = funcDetails.required(funcDetails.elem);
          }

          if (isRequired) {
            funcDetails.elem.parents('.form-group').find('.required-indicator').remove();
            funcDetails.elem.parents('.form-group').find('.control-label')
              .append('<span class="required-indicator"> *</span>');
          } else {
            funcDetails.elem.parents('.form-group').find('.required-indicator')
              .remove();
          }
        }, this);
      });
    }
  }
});

var behaviors = {
  'SubmitForm': SubmitForm,
  'Validate': Validate
};

Backbone.Marionette.Behaviors.behaviorsLookup = function(){
  return behaviors;
};

module.exports = behaviors;