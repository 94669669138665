'use strict';

var template = require('../../templates/costs/cost-list.hbs');
var Clipboard = require('clipboard');
var CostRowView = require('./cost-row');
var CostRowTotalView = require('./cost-row-total');

var CostListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: CostRowView,
  childViewContainer: 'tbody.cost-items',
  childViewOptions: function() {
    return {
      payeePlaceholder: this.getOption('payeePlaceholder'),
      clients: this.getOption('clients')
    };
  },
  ui: {
    date: 'input[name="date"]',
    client: 'input[name="client"]',
    submitBtn: 'button[type="submit"]',
    form: 'form',
    copyBtn: 'button.copy',
  },
  behaviors: {
    Validate: {
      validateOptions: {
        rules: {
          date: {
            required: true
          },
          amount: {
            required: true,
            number: true,
            min: 0.01
          },
          payee: {
            required: true,
            maxlength: 64
          },
          client: {
            required: false,
            maxlength: 64
          },
          reason: {
            required: true,
            maxlength: 64
          },
          vat: {
            required: true,
            number: true,
            min: 0
          }
        },
        messages: {
          amount: {
            min: 'Must be a positive amount.'
          }
        }
      }
    },
    SubmitForm: {
      submitHandler: 'saveCost'
    }
  },
  saveCost: function(data){
    var that = this;
    data.case = this.getOption('casefile').get('id');
    var date = data.date.split('/');
    data.date = date[2] + '-' + date[1] + '-' + date[0];
    this.ui.submitBtn.button('loading');
    this.collection.create(data, {
      wait: true,
      success: function(){
        that.ui.submitBtn.button('reset');
        that.ui.form[0].reset();
      }
    });
  },
  addChild: function(child, ChildView, index){
    var filterType = this.getOption('filterType');
    if (filterType && child.get('type') === filterType) {
      Backbone.Marionette.CompositeView.prototype.addChild.apply(this,
        arguments);
    }
  },
  collectionEvents: {
    'change:is_active': function(model, isActive){
      if (!isActive) {
        this.collection.remove(model);
      }
    }
  },
  onRender: function(){
    function queryProspects(query){
      function getProspectName(prospect) {
        var name = prospect.getFullName();
        if (prospect.get('contact').get('post_code')) {
          var post = prospect.get('contact').get('post_code');
          name += ' (' + post.trim()+ ')';
        }

        return name;
      }

      var results = clients.filter(function(prospect){
        var name = getProspectName(prospect);
        return name.toLowerCase().indexOf(query.term.toLowerCase()) !== -1;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: getProspectName(prospect)
        };
      });
      query.callback({results: results, more: false});
    }
    var that = this;
    var clients = this.getOption('clients');
    this.costTotalRegion = new Backbone.Marionette.Region({
      el: this.$('tbody.cost-total')
    });
    this.costTotalRegion.show(new CostRowTotalView({
      collection: this.collection,
      filterType: this.getOption('filterType')
    }));
    this.ui.date.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy'
    });
    this.ui.client.select2({
      query: queryProspects,
      initSelection : function (element, callback) {
        var id = element.val();
        var data = null;
        if (id) {
          data = { id: id, text: clients.get(id).getFullName() };
        }
        callback(data);
      }
    });
    this.ui.copyBtn.tooltip({
      title: 'Copied!',
      placement: 'bottom',
      trigger: 'manual'
    });
    if (this.clipboard) {
      this.clipboard.destroy();
    }
    this.clipboard = new Clipboard(this.ui.copyBtn[0]);
    this.clipboard.on('success', function(e) {
      that.ui.copyBtn.tooltip('show');
      e.clearSelection();
    });
  },
  serializeData: function(){
    var data = {
      filterType: this.getOption('filterType'),
      date: new Date(),
      url: this.collection.url(),
      dateColumn: this.getOption('dateColumn'),
      payeeColumn: this.getOption('payeeColumn'),
      payeePlaceholder: this.getOption('payeePlaceholder')
    };
    data.tableId = 'table'+(Math.floor(Math.random() * 100000));
    return data;
  }
});

module.exports = CostListView;