// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <th><a href=\"#\" class=\"sortable\" data-sort=\"fa_sent\">FA Sent <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"pr_nok\">PR/NoK <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"fa_received\">FA Received <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"disclosure_sent\">Disclosure Sent <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"payment_made\">Payment Made <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"questionnaire_returned\">Questionnaire Returned <i\n              class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"collapse-table\"><i class=\"fa fa-chevron-left\"></i></a></th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <th><a href=\"#\" class=\"sortable\" data-sort=\"pr_nok\">PR/NoK <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"expand-table\"><i class=\"fa fa-chevron-right\"></i></a></th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<div class=\"target-summary pull-right\">\n  <div class=\"panel panel-default\">\n    <div class=\"panel-body\">\n      <table class=\"table table-responsive table-condensed\">\n        <tbody>\n          <tr>\n            <td class=\"text-right\">Revenue from Engaged Clients:</td>\n            <td class=\"revenue-engaged\"></td>\n          </tr>\n          <tr>\n            <td class=\"text-right\">Potential Revenue (Outstanding FAs):</td>\n            <td class=\"revenue-potential\"></td>\n          </tr>\n          <tr>\n            <td class=\"text-right\">Target Revenue:</td>\n            <td class=\"target-revenue\"></td>\n          </tr>\n          <tr>\n            <td class=\"text-right\">Percentage of Target met:</td>\n            <td class=\"percentage-of-target\"></td>\n          </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "  <button class=\"btn btn-default new-contact\">Add new contact</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "  <button class=\"btn btn-primary new-beneficiary\">Add new beneficiary</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"table-responsive\">\n  <table class=\"table table-hover clients\">\n    <thead>\n      <tr>\n        <th></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"fullname\">Name <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"type\">Type <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"entitlement\">Entitlement<br />Fraction <i\n              class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"fee\">Fee<br />Percentage <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"vat_rate\">VAT <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"treethorpe_revenue\">Treethorpe<br />Revenue <i\n              class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"client_distribution\">Client<br />Distribution <i\n              class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTableExpanded : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      </tr>\n    </thead>\n  </table>\n</div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showContacts : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showContacts : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n<div class=\"loading-overlay\"></div>\n<div class=\"clearfix\"></div>";
},"useData":true});
