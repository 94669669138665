'use strict';

var moment = require('moment');
var template = require('../../../templates/reporting/survey-report.hbs');
var Questions = require('../../../models/survey/questions');
var Answers = require('../../../models/survey/answers');
var Teams = require('../../../models/users/teams');
var SurveyReportAnswerListView = require('./survey-report-answer-list');
var CaseManagerSelectView = require('../../allocations/casemanager-select');

var SurveyReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'survey-report',
  regions: {
    caseManagerRegion: '.casemanager-select-container',
    surveyReportRegion: '.survey-report-container',
  },
  ui: {
    submit: '.submit-btn',
    dateFrom: '.date-from',
    dateTo: '.date-to',
    team: '.team',
    includeInactive: '.include-inactive',
    alertArea: '#alert-area',
    reportingArea: '.reporting-area',
    reportName: '.report-for-name',
    exportCSV: '.export-csv',
  },
  events: {
    'click @ui.exportCSV': 'onClickExportCSV',
  },
  behaviors: {
    SubmitForm: {
      selector: 'form.survey-filters',
      submitHandler: 'generateReport',
    },
  },
  initialize: function() {
    this.teams = new Teams();
    this.listenTo(this.teams, 'sync', this.render);
    this.listenTo(TCAS.archiveDataTokens, 'sync', this.render);
    this.teams.fetch();

    if (!TCAS.session.hasPerm('filter_survey_report_team')) {
      var teamId = TCAS.reqres.request('teamId', TCAS.session.get('id'));
      this.selectedTeams = [teamId];
    }
  },
  fetchSurveyAnswers: function(data, callback) {
    var that = this;
    var questions = new Questions();
    var answers = new Answers();
    var surveyId = this.getOption('survey').get('id');

    var fromDateMoment;
    var toDateMoment;
    var userIds = this.caseManagerSelectView.getSelectedCaseManager();
    if (userIds) {
      userIds = userIds.split(',');
      for (var i = 0; i < userIds.length; i += 1) {
        userIds[i] = parseInt(userIds[i], 10);
      }
    }
    this.userIds = userIds;
    var users = this.getOption('users');
    this.filteredUsers = users.toArray();
    this.includeInactiveUsers = data.include_inactive === '1'; // jshint ignore:line
    if (!this.includeInactiveUsers) {
      this.filteredUsers = this.filteredUsers.filter(function(user) {
        return user.get('is_active');
      });
    }
    if (data.team) {
      var teams = data.team.split(',');
      teams = teams.map(function(team) {
        return parseInt(team, 10);
      });
      this.selectedTeams = teams;
    } else {
      this.selectedTeams = [];
    }
    var selectedTeams = this.selectedTeams;

    /* jshint ignore:start */
    if (data.date_from) {
      fromDateMoment = moment(
        data.date_from
          .split('/')
          .reverse()
          .join('-')
      );
    } else {
      fromDateMoment = moment().startOf('year');
    }
    if (data.date_to) {
      toDateMoment = moment(
        data.date_to
          .split('/')
          .reverse()
          .join('-')
      );
    } else {
      toDateMoment = moment();
    }

    this.fromDate = fromDateMoment.toDate();
    if (toDateMoment) {
      this.toDate = toDateMoment.toDate();
    }

    var filters = {
      question__survey: surveyId,
      date_range_0: fromDateMoment.format('DD/MM/YYYY'),
      date_range_1: toDateMoment.format('DD/MM/YYYY'),
      page_size: 100000,
    };

    if (toDateMoment) {
      filters.date_before = toDateMoment.format('DD/MM/YYYY');
    }
    if (userIds) {
      filters.case_manager__in = userIds.join(','); // jshint ignore:line
      this.filteredUsers = users.filter(function(u) {
        return userIds.indexOf(u.get('id')) !== -1;
      });
    }
    if (selectedTeams.length) {
      filters.team__in = selectedTeams.join(','); // jshint ignore:line

      // set the team name in heading
      var teamNames = this.getOption('teams')
        .filter(function(team) {
          return selectedTeams.indexOf(team.get('id')) !== -1;
        })
        .map(function(team) {
          return team.get('name');
        })
        .join(', ');
      this.teamNames = teamNames;
    } else {
      this.teamNames = null;
    }

    questions.setFilters({
      survey: surveyId,
    });
    answers.setFilters(filters);

    $.when.apply($, [questions.fetch(), answers.fetch()]).done(function() {
      callback(questions, answers);
    });
  },
  onClickExportCSV: function() {
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doExportCSV(sdToken);
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }
  },
  doExportCSV: function(sdToken) {
    var dateFrom = this.ui.dateFrom.val();
    var dateTo = this.ui.dateTo.val();
    var team = this.ui.team.val();
    var fromDateMoment;
    var toDateMoment;

    this.ui.exportCSV.button('loading');
    if (dateFrom) {
      fromDateMoment = moment(
        dateFrom
          .split('/')
          .reverse()
          .join('-')
      );
    } else {
      fromDateMoment = moment().startOf('year');
    }
    if (dateTo) {
      toDateMoment = moment(
        dateTo
          .split('/')
          .reverse()
          .join('-')
      );
    } else {
      toDateMoment = moment();
    }

    if (team) {
      var teams = team.split(',');
      teams = teams.map(function(t) {
        return parseInt(t, 10);
      });
    } else {
      teams = [];
    }

    var userIds = this.caseManagerSelectView.getSelectedCaseManager();
    if (userIds) {
      userIds = userIds.split(',');
      for (var i = 0; i < userIds.length; i += 1) {
        userIds[i] = parseInt(userIds[i], 10);
      }
    }

    var filters = {
      date_range_0: fromDateMoment.format('YYYY-MM-DD'),
      date_range_1: toDateMoment.format('YYYY-MM-DD'),
      page_size: 100000,
    };
    if (teams.length) {
      filters.team__in = teams;
    }
    if (userIds.length) {
      filters.casemanager__in = userIds;
    }

    var url = '/api/surveys/1/csv/';
    var params = [];
    _.forOwn(filters, function(value, filter) {
      params.push(filter + '=' + value);
    });
    url += '?sdtoken=' + encodeURIComponent(sdToken);
    if (params.length) {
      url += '&' + params.join('&');
    }

    window.location.href = url;
    this.ui.exportCSV.button('reset');
  },
  generateReport: function(data) {
    var that = this;
    this.ui.submit.button('loading');

    this.fetchSurveyAnswers(data, function(questions, answers) {
      that.ui.reportingArea.removeClass('hidden');
      that.surveyReportRegion.show(
        new SurveyReportAnswerListView({
          collection: questions,
          answers: answers,
        })
      );
      // work out report name based on number of survey submissions and cases
      var clientIds = [];
      var casefileIds = [];
      answers.each(function(answer) {
        if (clientIds.indexOf(answer.get('client')) === -1) {
          clientIds.push(answer.get('client'));
        }
        if (casefileIds.indexOf(answer.get('casefile')) === -1) {
          casefileIds.push(answer.get('casefile'));
        }
      });
      var dateFrom = moment(data.date_from);
      var dateTo = moment();
      if (data.date_to) {
        dateTo = moment(data.date_to);
      }
      that.ui.reportName.html(
        'Responses received between ' +
          dateFrom.format('DD/MM/YYYY') +
          ' - ' +
          dateTo.format('DD/MM/YYYY') +
          '<br/>From ' +
          clientIds.length +
          (clientIds.length === 1 ? ' client' : ' clients') +
          ' across ' +
          casefileIds.length +
          (casefileIds.length === 1 ? ' case' : ' cases')
      );
      that.ui.submit.button('reset');
    });
  },
  onRender: function() {
    var selectOptions = { multiple: true };
    if (!TCAS.session.hasPerm('filter_survey_report_team')) {
      var teamAllocations = this.getOption('teamAllocations');
      var teamAllocation = teamAllocations.find(function(teamAllocation) {
        return teamAllocation.get('user') === TCAS.session.get('id');
      });
      if (teamAllocation) {
        selectOptions.team = teamAllocation.get('team');
      }
    }

    if (!TCAS.session.hasPerm('filter_survey_report_user')) {
      selectOptions.user = TCAS.session.get('id');
      selectOptions.disabled = true;
    } else if (this.userIds) {
      selectOptions.user = this.userIds;
    }

    this.caseManagerSelectView = new CaseManagerSelectView(selectOptions);
    // this.listenTo(this.caseManagerSelectView, 'selected', this.checkInputs);
    this.caseManagerRegion.show(this.caseManagerSelectView);

    var teamOptions = {
      data: this.getOption('teams').map(function(team) {
        return {
          id: team.get('id'),
          text: team.get('name'),
        };
      }),
      multiple: true,
    };
    this.ui.team.val(this.selectedTeams).select2(teamOptions);
  },
  serializeData: function() {
    var archiveDataToken = TCAS.request('archiveDataToken');
    return {
      fromDate:
        this.fromDate ||
        moment()
          .startOf('year')
          .toDate(),
      isArchiveDataSession: !!archiveDataToken,
    };
  },
});

module.exports = SurveyReportView;
