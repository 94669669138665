// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "  <td><i class=\"fa fa-warning\"></i></td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <td>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.checked : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " &#x2714; ";
},"6":function(container,depth0,helpers,partials,data) {
    return " <i class=\"fa fa-times\"></i> ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_birth : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_death : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.conflict : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n<td>"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3((helpers.categoryField || (depth0 && depth0.categoryField) || alias2).call(alias1,(depth0 != null ? depth0.category : depth0),"code",{"name":"categoryField","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.asset_value : depth0)) != null ? stack1.capital_value : stack1), depth0))
    + " </td>\n<td>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.custodian_name : stack1), depth0))
    + "</td>\n<td>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_holder_name : stack1), depth0))
    + "</td>\n<td>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_nature : stack1), depth0))
    + "</td>\n<td>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_number : stack1), depth0))
    + "</td>\n<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.missing_date : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_birth : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.date_of_death : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.last_checked : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(alias4(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.address1 : stack1), depth0))
    + ",\n  "
    + alias3(alias4(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.address2 : stack1), depth0))
    + ",\n  "
    + alias3(alias4(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.city : stack1), depth0))
    + ",\n  "
    + alias3(alias4(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.post_code : stack1), depth0))
    + ",\n  "
    + alias3(alias4(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.country : stack1), depth0))
    + "</td>\n";
},"useData":true});
