// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.revenue || (depth0 != null ? depth0.revenue : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"revenue","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "0.00";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.vat || (depth0 != null ? depth0.vat : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"vat","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.disbursements || (depth0 != null ? depth0.disbursements : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"disbursements","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-xs btn-default cancel\">Cancel</button> <button type=\"button\" class=\"btn btn-xs btn-primary save\" data-loading-text=\"Saving...\">Save</button>";
},"11":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-success btn-sm save\" data-loading-text=\"Saving...\">Add</button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td>\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control input-sm\" name=\"date\" placeholder=\"DD/MM/YYYY\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n  </div>\n</td>\n<td></td>\n<td></td>\n<td>\n  <div class=\"form-group\">\n    <input type=\"number\" class=\"form-control input-sm\" name=\"case_element\" placeholder=\"Case element...\" value=\""
    + alias3(((helper = (helper = helpers.case_element || (depth0 != null ? depth0.case_element : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"case_element","hash":{},"data":data}) : helper)))
    + "\" /></td>\n  </div>\n<td>\n  <div class=\"form-group\">\n    <div class=\"casemanager-container\"></div>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control input-sm\" name=\"revenue\" type=\"number\" placeholder=\"Revenue...\" value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revenue : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control input-sm\" name=\"vat\" type=\"number\" placeholder=\"VAT...\" value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.vat : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control input-sm\" name=\"disbursements\" type=\"number\" placeholder=\"Disbursements...\" value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disbursements : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <div class=\"upload-file-container\">\n      <button type=\"button\" class=\"btn btn-default btn-sm add-file\"><i class=\"fa fa-plus\"></i> Drag and drop a file or click here</button>\n      <div class=\"help-block\">You can drag and drop a file from your computer, or drag an existing attached file listed under Files</div>\n    </div>\n    <div class=\"dropzone-previews\"></div>\n    <div class=\"file-previews\"></div>\n    <input type=\"hidden\" name=\"csoa\"/>\n    <button type=\"button\" class=\"btn btn-default btn-sm add-existing-file\"><i class=\"fa fa-plus\"></i> Add existing file</button>\n    <div class=\"file-select-container\"></div>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <textarea class=\"form-control input-sm\" name=\"description\" placeholder=\"Description...\" rows=\"1\" maxlength=\"140\">"
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</textarea>\n  </div>\n</td>\n<td></td>\n<td class=\"buttons\">\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "\n</td>";
},"useData":true});
