// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<form>\n<div class=\"row\" draggable=\"false\" data-file-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"col-md-8 form-group\">\n    <input type=\"text\" name=\"original_file_name\" class=\"file-name form-control\" value=\""
    + alias4(((helper = (helper = helpers.original_file_name || (depth0 != null ? depth0.original_file_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"original_file_name","hash":{},"data":data}) : helper)))
    + "\"/>\n  </div>\n  <div class=\"col-md-4 text-right\">\n    <button type=\"button\" class=\"btn btn-default btn-xs cancel\">Cancel</button>\n    <button type=\"submit\" class=\"btn btn-primary btn-xs save\" data-loading-text=\"Saving...\">Save</button>\n  </div>\n</div>\n</form>";
},"useData":true});
