'use strict';
var template = require('../../templates/cases/casefile-import.hbs');
var CaseFile = require('../../models/cases/casefile');
var Contact = require('../../models/users/contact');
var Asset = require('../../models/cases/asset');
var AssetValue = require('../../models/cases/assetvalue');
var CaseFiles = require('../../models/cases/casefiles');
var Allocations = require('../../models/cases/allocations');
var CaseFileImportTableView = require('./casefile-import-list');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var Spinner = require('spin');
var alertTemplate = require('../../templates/alert.hbs');
var moment = require('moment');
var Dropzone = require('dropzone');

var CaseFileImportView = Backbone.Marionette.CompositeView.extend({
  template: template,
  className: 'casefile-import',
  ui: {
    warningArea: '.warning-area',
    submitImport: '.submit-import',
    overwriteImport: '.overwrite-import',
    dropzonePreviews: '.dropzone-previews',
    addFile: '.add-file',
    fileInput: '.dz-hidden-input',
    conflictsTitle: '#conflicts-title',
    approvedTitle: '#approved-title',
  },
  events: {
    'click @ui.submitImport': 'submitImport',
    'click @ui.overwriteImport': 'overwriteImport',
  },
  parseCSV: function(file) {
    var that = this;

    Papa.parse(file, {
      complete: function(results) {
        for (var index = results.data.length - 1; index > -1; index--) {
          if (results.data[index].length === 1) {
            results.data.splice(index, 1);
          }
        }
        // remove the column headers
        results.data.splice(0, 1);

        var caseFilesCon = new CaseFiles(); //conflicts
        var caseFilesApp = new CaseFiles(); //approved

        _(results.data).forEach(function(row) {
          var id = parseInt(row[0]);
          // if the id isnt an id, end (for example, carriage return)
          if (isNaN(id)) {
            return;
          }

          //jshint camelcase:false
          var asset_holder_name = '';
          if (row[8] !== '') {
            asset_holder_name += row[8] + ';';
          }
          if (row[9] !== '') {
            asset_holder_name += row[9] + ';';
          }
          if (row[10] !== '') {
            asset_holder_name += row[10] + ';';
          }
          if (row[11] !== '') {
            asset_holder_name += row[11] + ';';
          }

          if (asset_holder_name.substr(asset_holder_name.length - 1) === ';') {
            asset_holder_name = asset_holder_name.substring(0, asset_holder_name.length - 1);
          }

          var asset = new Asset({
            asset_nature: row[2],
            asset_number: row[3],
            custodian_name: row[18],
            asset_holder_name: asset_holder_name,
            missing_date: moment(row[4], 'DD-MM-YYYY'),
            last_checked: moment(row[5], 'DD-MM-YYYY'),
            date_of_birth: row[20] !== '' ? moment(row[20], 'DD-MM-YYYY') : null,
            date_of_death: row[21] !== '' ? moment(row[21], 'DD-MM-YYYY') : null,
            owner: TCAS.session.get('id'),
          });

          var legacy_addr = row[17];

          asset.get('addresses').add(
            new Contact({
              address1: row[12],
              address2: row[13],
              city: row[14],
              post_code: row[15],
              country: row[16],
              legacy_address: legacy_addr,
            })
          );

          var assetValue = new AssetValue({
            asset: asset,
            capital_value: row[6],
            income_value: row[7],
          });

          var caseFile = new CaseFile({
            legacy_id: parseInt(id),
            category: TCAS.categories.findWhere({ code: row[1] }).get('id'),
            asset: asset,
            status: 0,
            owner: TCAS.session.get('id'),
            message: row[19],
            allocations: new Allocations(),
            creation_method: 0 /* created using csv */,
            asset_value: assetValue,
          });
          var ref = row[11] + ' ' + parseInt(id);
          caseFile.set('ref', ref);

          $.getJSON('/api/cases/?legacy_id=' + id, function(data) {
            if (data.count === 0) {
              caseFile.set('conflict', false);
              caseFile.set('checked', true);
              caseFilesApp.add(caseFile);
              that.ui.approvedTitle.html('There are ' + caseFilesApp.length + ' ready cases');
            } else {
              caseFile.set('conflict', true);
              caseFile.set('checked', false);
              caseFilesCon.add(caseFile);
              that.ui.conflictsTitle.html(
                'There are ' + caseFilesCon.length + ' conflicting cases'
              );
            }
          });
        });

        var caseFileImportTableApp = new CaseFileImportTableView({
          collection: caseFilesApp,
        });
        that.casesTableRegionApp.show(caseFileImportTableApp);

        var caseFileImportTableCon = new CaseFileImportTableView({
          collection: caseFilesCon,
        });
        that.casesTableRegionCon.show(caseFileImportTableCon);
      },
      error: function(err) {
        console.log('error parsing the csv file');
        console.log(err);
      },
    });
  },
  onRender: function() {
    this.casesTableRegionApp = new Backbone.Marionette.Region({
      el: this.$('#casefiles-table-approved')[0],
    });
    this.casesTableRegionCon = new Backbone.Marionette.Region({
      el: this.$('#casefiles-table-conflicts')[0],
    });
  },
  onShow: function() {
    var that = this;
    //configure dropzone
    this.dropzone = new Dropzone(this.el, {
      url: '/api/generalfiles/',
      headers: { 'X-CSRFToken': TCAS.csrfToken },
      previewsContainer: this.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      autoProcessQueue: false,
      maxFilesize: 500,
      acceptedFiles:
        'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.ged',
      clickable: this.ui.addFile[0],
      accept: function(file, done) {
        // check for only alphanumeric characters
        var alphaNumericPattern = new RegExp("^[a-zA-Z0-9'_()&+£%., -]+.[a-zA-Z0-9]{3,4}$");
        if (alphaNumericPattern.test(file.name)) {
          that.parseCSV(file);
          done();
        } else {
          done(
            'This file has unexpected characters, ' +
              'file names must use the correct file naming structure and ' +
              'only contain characters consisting of: ' +
              'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
              'apostrophes, commas or spaces.'
          );
        }
      },
    });
    this.dropzone.on('error', function(file) {
      that
        .$(file.previewElement)
        .find('button')
        .removeClass('hide');
    });
    this.dropzone.on('uploadprogress', function(file, progress) {
      console.log('file upload progress: ' + progress);
    });
    this.dropzone.on('sending', function(file, xhr, formData) {
      // Show the total progress bar when upload starts
      that
        .$(file.previewElement)
        .find('.progress')
        .removeClass('hide');
      formData.append('name', file.name);
      formData.append('type', 0);
      formData.append('owner', TCAS.session.get('id'));
    });
    this.dropzone.on('dragover', function() {
      that.ui.addFile.addClass('list-group-item-success');
    });
    this.dropzone.on('dragleave', function() {
      that.ui.addFile.removeClass('list-group-item-success');
    });
    this.dropzone.on('drop', function() {
      that.ui.addFile.removeClass('list-group-item-success');
    });
  },
  submitImport: function() {
    if (this.dropzone.getQueuedFiles().length === 0) {
      this.ui.warningArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'No new files have been detected.',
          type: 'warning',
        })
      );
      return;
    }

    var caseFiles = this.casesTableRegionApp.currentView.collection;
    caseFiles.reset(caseFiles.where({ checked: true }));

    if (caseFiles.length === 0) {
      this.ui.warningArea.html(
        alertTemplate({
          strong: 'Warning!',
          message:
            'No cases have been selected for import. ' +
            'They can be selected by selecting them from the Cases panel below.',
          type: 'warning',
        })
      );
      return;
    }

    this.ui.submitImport.prop('disabled', true);
    this.ui.submitImport.value = 'Importing... Please Wait';

    if (!this.spinner) {
      this.spinner = new Spinner();
    }
    this.spinner.spin(this.ui.submitImport[0]);
    var that = this;

    caseFiles.forEach(function(caseFile) {
      if (caseFile.get('checked')) {
        var assetValue = caseFile.get('asset_value');
        caseFile.unset('asset_value');

        var ret = caseFile.save().then(function() {
          if (ret === false) {
            caseFile.set('added', false);
          } else {
            caseFile.set('added', true);
            assetValue.set('asset', caseFile.get('asset').get('id'));
            assetValue.save();

            if (caseFile === caseFiles.last()) {
              that.spinner.stop();
              that.ui.warningArea.html(
                alertTemplate({
                  strong: 'Success!',
                  message: 'Cases imported successfully.',
                  type: 'success',
                })
              );

              that.ui.submitImport.prop('disabled', false);
              that.ui.submitImport.value = 'Submit Case Files';

              window.scrollTo(0, 0);
            }
          }
        });
      }
    });

    this.casesTableRegionCon.empty();

    var caseFileImportTableApp = new CaseFileImportTableView({
      collection: caseFiles,
    });
    this.casesTableRegionApp.show(caseFileImportTableApp);
    this.dropzone.processQueue();
  },
  overwriteImport: function() {
    if (this.dropzone.getQueuedFiles().length === 0) {
      this.ui.warningArea.html(
        alertTemplate({
          strong: 'Warning!',
          message: 'No new files have been detected.',
          type: 'warning',
        })
      );
      return;
    }

    var caseFiles = this.casesTableRegionCon.currentView.collection;

    if (caseFiles.where({ checked: true }).length === 0) {
      this.ui.warningArea.html(
        alertTemplate({
          strong: 'Warning!',
          message:
            'No casefiles have been selected to be overwritten. ' +
            'They can be selected from the conflicting cases panel below.',
          type: 'warning',
        })
      );
      return;
    }

    this.ui.overwriteImport.prop('disabled', true);
    this.ui.overwriteImport.value = 'Importing... Please Wait';

    if (!this.spinner) {
      this.spinner = new Spinner();
    }
    this.spinner.spin(this.el);
    var that = this;

    caseFiles.reset(caseFiles.where({ checked: true }));

    caseFiles.forEach(function(caseFile) {
      if (caseFile.get('checked')) {
        $.getJSON('/api/cases/?legacy_id=' + caseFile.get('legacy_id'), function(data) {
          var sCaseFile = new CaseFile(data.results[0], { parse: true });

          var sAsset = sCaseFile.get('asset');
          var sContact = sAsset.getFirstAddress();
          var sAssetValue = sAsset.getFirstValuation()
            ? sAsset.getFirstValuation()
            : new AssetValue();

          sAsset.set({
            asset_nature: caseFile.get('asset').get('asset_nature'),
            asset_number: caseFile.get('asset').get('asset_number'),
            custodian_name: caseFile.get('asset').get('custodian_name'),
            asset_holder_name: caseFile.get('asset').get('asset_holder_name'),
            missing_date: caseFile.get('asset').get('missing_date'),
            last_checked: caseFile.get('asset').get('last_checked'),
            owner: TCAS.session.get('id'),
          });

          sContact.set({
            address1: caseFile
              .get('asset')
              .getFirstAddress()
              .get('address1'),
            address2: caseFile
              .get('asset')
              .getFirstAddress()
              .get('address2'),
            city: caseFile
              .get('asset')
              .getFirstAddress()
              .get('city'),
            post_code: caseFile
              .get('asset')
              .getFirstAddress()
              .get('post_code'),
            country: caseFile
              .get('asset')
              .getFirstAddress()
              .get('country'),
          });

          if (
            caseFile
              .get('asset')
              .getFirstAddress()
              .has('legacy_address')
          ) {
            sContact.set(
              'legacy_address',
              caseFile
                .get('asset')
                .getFirstAddress()
                .get('legacy_address')
            );
          }

          sAssetValue.set({
            capital_value: caseFile.get('asset_value').get('capital_value'),
            income_value: caseFile.get('asset_value').get('income_value'),
            asset: sAsset.get('id'),
          });

          sCaseFile.set({
            legacy_id: caseFile.get('legacy_id'),
            category: caseFile.get('category'),
            status: 0,
            owner: TCAS.session.get('id'),
            message: caseFile.get('message'),
            allocations: new Allocations(),
            checked: true,
            creation_method: 0 /* created using csv */,
            ref: caseFile.get('ref'),
          });

          sCaseFile.save(
            {},
            {
              success: function() {
                sAssetValue.save();

                if (caseFile === caseFiles.last()) {
                  that.spinner.stop();
                  that.ui.warningArea.html(
                    alertTemplate({
                      strong: 'Success!',
                      message: 'Cases imported successfully.',
                      type: 'success',
                    })
                  );

                  that.ui.overwriteImport.prop('disabled', false);
                  that.ui.overwriteImport.value = 'Submit Case Files';
                  that.ui.conflictsTitle.html(
                    'There are ' + caseFiles.length + ' conflicting cases'
                  );
                }
              },
            }
          );
        });
      }
    });
    this.dropzone.processQueue();
  },
});

module.exports = CaseFileImportView;
