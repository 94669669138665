'use strict';
var BaseCollection = require('../base-collection');
var ClientPayment = require('./clientpayment');

var ClientPayments = BaseCollection.extend({
  model: ClientPayment,
  comparator: function(clientPayment){
    return new Date(clientPayment.get('date_made')).getTime() * -1;
  }
});

module.exports = ClientPayments;
