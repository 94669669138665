// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " &#x2714; ";
},"3":function(container,depth0,helpers,partials,data) {
    return " <i class=\"fa fa-circle-o\"></i> ";
},"5":function(container,depth0,helpers,partials,data) {
    return " <i class=\"fa fa-times\"></i> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.checked : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td><a href=\""
    + alias3((helpers.caseUrl || (depth0 && depth0.caseUrl) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"caseUrl","hash":{},"data":data}))
    + "\" class=\"popout\">"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</a></td>\n<td>"
    + alias3((helpers.categoryField || (depth0 && depth0.categoryField) || alias2).call(alias1,(depth0 != null ? depth0.category : depth0),"code",{"name":"categoryField","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.last_worked_on : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + alias3(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.addresses : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.country : stack1), depth0))
    + "</td>";
},"useData":true});
