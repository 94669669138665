'use strict';

var template = require('../../templates/users/archive-data-modal.hbs');

var ArchiveDataModal = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'modal fade',
  events: {
    'submit form': 'onSubmit'
  },
  ui: {
    password: 'input[name="archive_password"]',
    alert: '.alert',
    modalBody: '.modal-body',
    button: 'button'
  },
  initialize: function(){
    this.$el.attr('tabindex', '-1');
    this.$el.attr('role', 'dialog');
    this.$el.attr('aria-labelledby', 'confirm-modal-label');
    this.$el.attr('aria-hidden', 'true');
  },
  onSubmit: function(){
    var that = this;
    var password = this.ui.password.val();
    this.ui.button.button('loading');
    this.collection.create({ 'archive_password': password }, {
      wait: true,
      success: function() {
        that.trigger('passwordSuccess');
        that.ui.alert.addClass('hide');
        that.$el.modal('hide');
      },
      error: function(model, response){
        var errors = response.responseJSON;
        for (var i = 0; i < errors.length; i += 1) {
          that.ui.alert.append('<div>' + errors[i] + '</div>');
          that.ui.alert.removeClass('hide');
        }
        that.ui.button.button('reset');
      }
    });
    return false;
  },
  onRender: function(){
    // only append to body once
    if (!this.$el.parent().length){
      this.$el.appendTo('body');
      this.$el.modal({
        backdrop: 'static'
      });
    } else {
      this.$el.modal('show');
    }

  }
});

module.exports = ArchiveDataModal;
