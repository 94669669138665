'use strict';
var template = require('../../templates/cases/casefile-deleted.hbs');

var CaseFileDeletedView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    return this.options;
  }
});

module.exports = CaseFileDeletedView;