'use strict';
var Contacts = require('../users/contacts');
var AssetValues = require('./assetvalues');

var Asset = Backbone.Model.extend({
  urlRoot: '/api/assets/',
  initialize: function() {
    //only initialise addresses if it doesn't exist
    if (!this.has('addresses')) {
      this.set('addresses', new Contacts());
    }
  },
  parse: function(response) {
    //jshint camelcase: false
    response.created = new Date(response.created);
    response.modified = new Date(response.modified);
    response.addresses = new Contacts(response.addresses, { parse: true });
    response.asset_values = new AssetValues(response.asset_values, { parse: true });
    return response;
  },
  toJSON: function() {
    //jshint camelcase: false
    var data = Backbone.Model.prototype.toJSON.call(this);
    data.addresses = this.get('addresses').toJSON();
    if (this.has('asset_values')) {
      data.asset_values = this.get('asset_values').toJSON();
    }
    if (data.missing_date && typeof data.missing_date !== 'string') {
      data.missing_date = data.missing_date.format('YYYY-MM-DD');
    }
    if (data.last_checked && typeof data.last_checked !== 'string') {
      data.last_checked = data.last_checked.format('YYYY-MM-DD');
    }
    if (data.date_of_birth && typeof data.date_of_birth !== 'string') {
      data.date_of_birth = data.date_of_birth.format('YYYY-MM-DD');
    }
    if (data.date_of_death && typeof data.date_of_death !== 'string') {
      data.date_of_death = data.date_of_death.format('YYYY-MM-DD');
    }
    return data;
  },
  getCurrentValuation: function() {
    return this.get('asset_values').findWhere({ is_most_recent: true });
  },
  getFirstValuation: function() {
    var that = this;
    if (this.get('asset_values').length > 0) {
      if (that.get('asset_values').constructor === Array) {
        return that.get('asset_values')[0];
      } else {
        return that.get('asset_values').at(0);
      }
    }
    return null;
  },
  getFirstAddress: function() {
    var that = this;
    if (this.get('addresses') && this.get('addresses').length > 0) {
      if (that.get('addresses').constructor === Array) {
        return that.get('addresses')[0];
      } else {
        return that.get('addresses').at(0);
      }
    }
    return null;
  },
});

module.exports = Asset;
