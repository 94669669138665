'use strict';
var template = require('../../templates/teams/team-main.hbs');
var Teams = require('../../models/users/teams');
var TeamList = require('./team-list');
var TeamCreate = require('./team-create');

var TeamListView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    addTeam: '#add-team-panel',
    listTeams: '#teams-panel',
  },
  onShow: function() {
    var that = this;
    this.addTeam.show(new TeamCreate());

    this.loadTeams(that);

    TCAS.vent.on('team:saved', function() {
      that.loadTeams(that);
    });
  },
  loadTeams: function(that) {
    var teams = new Teams([]);
    teams.fetch().then(function() {
      that.listTeams.show(
        new TeamList({
          collection: teams,
        })
      );
    });
  },
});

module.exports = TeamListView;
