'use strict';
var template = require('../../templates/templates_repository/templates-row.hbs');
var GeneralFile = require('../../models/reporting/generalfile');

var TemplatesRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  model: GeneralFile,
  intialize: function() {
    this.edit = false;
  },
  ui: {
    name: '.template-name',
    description: '.template-description',
    category: '.template-category',
    grouping: '.template-grouping',
    save: '.save-template',
    edit: '.edit-template',
    remove: '.remove-template',
  },
  events: {
    'click @ui.save': 'saveChanges',
    'click @ui.edit': 'editModel',
    'click @ui.remove': 'removeModel',
  },
  saveChanges: function() {
    var that = this;
    var name = this.ui.name.val();
    var description = this.ui.description.val();
    var category = this.ui.category.val();
    var grouping = this.ui.grouping.val();

    this.model.set({
      name: name,
      description: description,
      category: category,
      grouping: grouping,
    });

    this.model.unset('file');
    this.model.unset('file_download');

    this.model.save(
      {},
      {
        success: function() {
          that.editModel();
        },
        error: function() {
          console.log('error');
        },
      }
    );
  },
  editModel: function() {
    this.edit = !this.edit;
    this.render();
  },
  removeModel: function() {
    var that = this;

    this.model.set('is_active', false);
    this.model.unset('file');
    this.model.unset('file_download');

    this.model.save(
      {},
      {
        success: function() {
          TCAS.vent.trigger('remove:template', that.model.get('id'));
        },
        error: function() {
          console.log('error');
        },
      }
    );
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.edit = this.edit;
    data.showCreate = this.options.showCreate;
    return data;
  },
});

module.exports = TemplatesRowView;
