'use strict';
var template = require('../../templates/teams/team-details.hbs');
var Users = require('../../models/users/users');
var TeamAllocations = require('../../models/users/teamallocations');
var TeamTargets = require('../../models/reporting/teamtargets');
var TeamTarget = require('../../models/reporting/teamtarget');
var TeamMembersView = require('./team-members-list');
var TeamTargetDetailsView = require('./team-target-details');
var User = require('../../models/users/user');
var alertTemplate = require('../../templates/alert.hbs');
var ConfirmModal = require('../confirm-modal');

var CreateTeamView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'col-sm-10 col-sm-offset-1',
  regions: {
    currentMembersRegion: '#members',
    pastMembersRegion: '#past-members',
    targetsRegion: '#team-targets-panel'
  },
  ui: {
    'selectUser': '#select-user',
    'addUser': '#add-user',
    'detailsAlertArea': '.details-alert-area',
    'membersAlertArea': '.members-alert-area'
  },
  events: {
    'click @ui.addUser': 'addUserToTeam'
  },
  serializeData: function() {
    var data = this.getOption('team').toJSON();
    return data;
  },
  onShow: function() {
    var that = this;

    this.listenTo(TCAS.vent, 'team:changed', function() {
      that.loadMembers();
      that.loadTeamTargets();
    });

    this.listenTo(TCAS.vent, 'member:removed', function() {
      that.loadMembers();
      that.ui.membersAlertArea.append(
        alertTemplate({
          strong: 'Success!',
          message: 'User was removed from the team.',
          type: 'success'
        })
      );
    });

    this.listenTo(TCAS.vent, 'member:save:success', function() {
      that.loadMembers();
      that.ui.membersAlertArea.append(
        alertTemplate({
          strong: 'Success!',
          message: 'User was updated successfully.',
          type: 'success'
        })
      );
    });

    this.listenTo(TCAS.vent, 'member:save:failed', function() {
      that.ui.membersAlertArea.append(
        alertTemplate({
          strong: 'Error!',
          message: 'An error occurred. User was not saved successfully.',
          type: 'danger'
        })
      );
    });

    this.loadMembers();
    this.loadTeamTargets();

    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      return s;
    }

    var usersUrl = '/api/users/?';
    usersUrl += 'groups='+User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.selectUser.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: usersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
      initSelection: function(element, callback) {
        if(that.getOption('user')) {
          var user = new User({
            id: that.getOption('user')
          });
          user.fetch().then(function() {
            var data = {id: element.val(), text: user.toJSON()};
            callback(user.toJSON());
          });
          that.loadManagerCaseload();
        }
      }
    });
  },
  addUserToTeam: function() {
    var that = this;
    var userId = this.ui.selectUser.select2('val');
    var user = new User({ id: userId });

    user.fetch().then(function() {
      var userTeamAllocations = new TeamAllocations([], {
        urlRoot: '/api/teamallocations/?user=' + user.get('id')
      });
      userTeamAllocations.fetch().then(function() {
        var currentTeamAllocation = userTeamAllocations.find(function(teamAllocation) {
          return teamAllocation.get('user') === user.get('id') && teamAllocation.get('is_active');
        });

        // check if user already exists in the team
        var isAlreadyInTeam = that.teamAllocations.some(function(teamAllocation) {
          return teamAllocation.get('user') === user.get('id') && teamAllocation.get('is_active');
        });
        if (isAlreadyInTeam) {
          that.ui.membersAlertArea.append(
            alertTemplate({
              strong: 'Warning!',
              message: 'The user already belongs to this team!',
              type: 'danger'
            })
          );
          return false;
        }

        var teamLeader = null;
        if (that.options.team.has('team_leader')) {
          teamLeader = that.options.team.get('team_leader');
        }

        // team is null, can check team leader and add without confirm
        if (teamLeader !== null && user.hasGroup(User.GROUPS.TEAM_LEADER)) {
          var confirmModal = new ConfirmModal({
            title: 'You are adding another Team Leader',
            body: 'You are adding another Team Leader to this team, ' +
            'continuing will demote the existing Team Leader to ' +
            'Second Leader and add the new user as the Team Leader.<br><br>' +
            'Are you sure you want to continue?'
          });
          that.listenTo(confirmModal, 'confirmYes', function() {
            that.addUser(user);
          });
          confirmModal.render();
        } else if (currentTeamAllocation) {
          var teamChangeConfirm = new ConfirmModal({
            title: 'The user already belongs to a team.',
            body: 'Continuing will remove the user from their current team to ' +
            'this team.<br><br>' +
            'Are you sure you want to continue?'
          });
          that.listenTo(teamChangeConfirm, 'confirmYes', function() {
            if(teamLeader !== null &&
              user.hasGroup(User.GROUPS.TEAM_LEADER)) {
              var leaderConfirm = new ConfirmModal({
                title: 'You are adding another Team Member',
                body: 'You are adding another Team Leader to this team, ' +
                'continuing will demote the existing Team Leader to ' +
                'Second Leader and add the new user as the Team Leader.<br><br>' +
                'Are you sure you want to continue?'
              });
              that.listenTo(leaderConfirm, 'confirmYes', function() {
                that.addUser(user);
              });
              leaderConfirm.render();
            } else {
              that.addUser(user);
            }
          });
          teamChangeConfirm.render();
        } else {
          that.addUser(user);
        }
      });
    });
  },
  addUser: function(user) {
    var that = this;
    this.teamAllocations.create({
      user: user.get('id'),
      team: this.getOption('team').get('id')
    }, {
      success: function() {
        that.ui.membersAlertArea.append(
          alertTemplate({
            strong: 'Success!',
            message: 'User was successfully added to the team.',
            type: 'success'
          })
        );
        that.loadMembers();
      },
      error: function() {
        that.ui.membersAlertArea.append(
          alertTemplate({
            strong: 'Error!',
            message: 'An error occurred. User was not added to the team.',
            type: 'danger'
          })
        );
      }
    });
  },
  loadMembers: function() {
    var that = this;
    var team = this.getOption('team');
    // var users = new Users([], {
    //   urlRoot: '/api/users/?user_teamallocation__team=' + this.options.team.get('id')
    // });
    this.teamAllocations = new TeamAllocations([], {
      urlRoot: '/api/teamallocations/?team=' + this.options.team.get('id')
    });
    // users.fetch().then(function() {
      that.teamAllocations.fetch().then(function(){
        that.options.team.fetch().then(function() {
          that.currentMembersRegion.show(new TeamMembersView({
            collection: that.teamAllocations,
            team: team,
            showActive: true
          }));
          that.pastMembersRegion.show(new TeamMembersView({
            collection: that.teamAllocations,
            team: team,
            showActive: false
          }));
        });
      });
    // });
  },
  loadTeamTargets: function() {
    // only if has permission
    if (!TCAS.session.hasPerm('view_teamtarget')) {
      return;
    }

    var that = this;
    var teamId = this.options.team.get('id');
    var teamTargets = new TeamTargets();
    teamTargets.setFilters({ team: teamId });
    teamTargets.fetch().then(function() {
      that.targetsRegion.show(new TeamTargetDetailsView({
        collection: teamTargets,
        teamId: teamId
      }));
    });
  },
  behaviors: {
    SubmitForm: {
      selector: '#save-team-details',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          name: {
            required: true
          },
          location: {
            required: true
          }
        },
        ignore: []
      },
      selector: '#save-team-details'
    }
  },
  submitData: function(data) {
    var that = this;

    if(this.options.team.get('name') !== data.name) {
      that.options.team.set('name', data.name);
    }

    if(this.options.team.get('location') !== data.location) {
      that.options.team.set('location', data.location);
    }

    this.options.team.save({}, {
      success: function() {
        that.ui.detailsAlertArea.html(
          alertTemplate({
            strong: 'Success!',
            message: 'Team details were updated successfully.',
            type: 'success'
          })
        );
      },
      error: function() {
        that.ui.detailsAlertArea.html(
          alertTemplate({
            strong: 'Error!',
            message: 'An error occurred. Team details were not updated.',
            type: 'danger'
          })
        );
      }
    });
  }
});

module.exports = CreateTeamView;
