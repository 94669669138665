'use strict';

var BaseCollection = require('../base-collection');
var SurveyScannedFile = require('./surveyscannedfile');

var SurveyScannedFiles = BaseCollection.extend({
  urlRoot: '/api/surveyscannedfiles/',
  model: SurveyScannedFile
});

module.exports = SurveyScannedFiles;