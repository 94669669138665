'use strict';

var template = require('../../templates/financials/forecastingrecord-list.hbs');

var ForecastingRecordRowView = require('./forecastingrecord-row');
var ForecastingRecord = require('../../models/reporting/forecastingrecord');

var ForecastingRecordListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: ForecastingRecordRowView,
  childViewContainer: 'tbody.table-items',
  collectionEvents: {
    'change:is_active': function(model, isActive){
      if (!isActive) {
        this.collection.remove(model);
      }
    },
    'add remove sync': 'render'
  },
  setupForm: function() {
    var caseManager = null;
    var allocation = this.getOption('casefile').getCurrentAllocation();
    if (allocation) {
      caseManager = allocation.get('case_manager');
    }
    var newModel = new ForecastingRecord({
      case: this.getOption('casefile').get('id'),
      case_manager: caseManager
    });
    this.newForecastingRecordView = new ForecastingRecordRowView({
      model: newModel,
      editMode: true
    });
    this.listenTo(this.newForecastingRecordView, 'saved', function() {
      this.collection.add(newModel);
      this.stopListening(this.newForecastingRecordView);
      this.setupForm();
    });

    this.formRegion.show(this.newForecastingRecordView);
  },
  onRender: function() {
    if (this.formRegion) {
      this.formRegion.empty();
    }

    this.formRegion = new Backbone.Marionette.Region({
      el: this.$('.table-add')
    });

    if (TCAS.session.hasPerm('add_forecastingrecord')) {
      this.setupForm();
    }
  },
  serializeData: function() {
    var data = {};
    var totalRevenue = 0;
    var totalDisbursements = 0;
    var total = 0;

    this.collection.forEach(function(record) {
      totalRevenue += record.get('revenue');
      totalDisbursements += record.get('disbursements');
      total += record.get('revenue') + record.get('disbursements');
    });
    data.totalRevenue = totalRevenue;
    data.totalDisbursements = totalDisbursements;
    data.total = total;

    return data;
  }
});

module.exports = ForecastingRecordListView;
