'use strict';

var BaseCollection = require('../base-collection');
var CalendarEvent = require('./calendarevent');

var CalendarEvents = BaseCollection.extend({
  urlRoot: '/api/calendarevents/',
  model: CalendarEvent
});

module.exports = CalendarEvents;
