'use strict';

var moment = require('moment');
var Clipboard = require('clipboard');
var template = require('../../../templates/reporting/pipeline-granular-list.hbs');
var PipelineGranularRowView = require('./pipeline-granular-row');
var BookingRecord = require('../../../models/reporting/bookingrecord');
var ForecastingRecord = require('../../../models/reporting/forecastingrecord');
var PledgingRecord = require('../../../models/reporting/pledgingrecord');
var Util = require('../../../util');

var PipelineGranularListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    confirmedTotal: '.confirmed-total',
    pendingTotal: '.pending-total',
    pledgedTotal: '.pledged-total',
    atRiskTotal: '.at-risk-total',
    bookedConfirmedTotal: '.booked-confirmed-total',
    bookedConfirmedPendingTotal: '.booked-confirmed-pending-total',
    maximumPotentialTotal: '.maximum-potential-total',
    maximumMinusAtRiskTotal: '.maximum-minus-at-risk-total',
    form: 'form',
    caseRef: '.case-ref',
    disclosureDateDirection: '.disclosure-date-direction',
    disclosureDate: '.disclosure-date',
    confirmed: '.confirmed',
    pending: '.pending',
    pledged: '.pledged',
    atRisk: '.at-risk',
    isBooked: '.is-booked',
    exportBtn: '.export',
    resetBtn: '.reset',
    table: 'table',
    filterPanel: '.panel-collapse',
    sortable: 'a.sortable',
    granularTable: '.pipeline-granular-table',
    totalsTable: '.pipeline-granular-totals-table',
    copyBtn: '.copy-table',
    displayPence: '.display-pence',
  },
  events: {
    'submit @ui.form': 'onFilterSubmit',
    'click @ui.exportBtn': 'onClickExport',
    'click @ui.resetBtn': 'onClickResetFilters',
    'click @ui.sortable': 'onClickSortColumn',
    'click @ui.displayPence': 'onClickDisplayPence',
  },
  childView: PipelineGranularRowView,
  childViewContainer: 'tbody.table-items',
  childViewOptions: function(model, index) {
    var userId = model.get('case_manager');
    var user = this.getOption('users').find(function(user) {
      return user.get('id') === userId;
    });
    var casefile = this.getOption('casefiles').find(function(casefile) {
      return casefile.get('id') === model.get('case');
    });
    var teamAllocations = this.getOption('teamAllocations');
    var teamAllocation = teamAllocations.find(function(teamAllocation) {
      return teamAllocation.get('user') === user.get('id');
    });
    var team = this.getOption('teams').find(function(team) {
      return teamAllocation && team.get('id') === teamAllocation.get('team');
    });
    var category = this.getOption('categories').find(function(category) {
      return category.get('id') === casefile.get('category');
    });
    var disclosure = this.getOption('disclosureMilestones').find(function(milestone) {
      return milestone.get('case') === model.get('case');
    });
    return {
      user: user,
      team: team,
      casefile: casefile,
      category: category,
      disclosure: disclosure,
      displayPence: this.displayPence,
    };
  },
  filter: function(child) {
    var isAllowed = true;
    var bookingMoment = moment(child.get('date'));
    var filterMoment;

    if (this.filterCasefile && child.get('case') !== this.filterCasefile) {
      return false;
    }

    if (this.disclosureDate) {
      var disclosure = this.getOption('disclosureMilestones').find(function(milestone) {
        return milestone.get('case') === child.get('case');
      });

      if (disclosure) {
        var milestoneMoment = moment(disclosure.get('created'));
        filterMoment = moment(
          this.disclosureDate
            .split('/')
            .reverse()
            .join('-')
        );
        if (this.disclosureDateDirection === 'before' && milestoneMoment.isAfter(filterMoment)) {
          return false;
        } else if (
          this.disclosureDateDirection === 'after' &&
          milestoneMoment.isBefore(filterMoment)
        ) {
          return false;
        }
      } else {
        return false;
      }
    }

    // in below section, UI used to have include and exclude options,
    // template now only has exclude, left the login in for include in case
    // they want to revert back to include.
    if (this.confirmed) {
      if (
        this.confirmed === 'include' &&
        (child.get('is_at_risk') ||
          child.get('status') === 'pending' ||
          !(child instanceof ForecastingRecord || child instanceof BookingRecord))
      ) {
        return false;
      } else if (
        this.confirmed === 'exclude' &&
        (child instanceof BookingRecord ||
          (child instanceof ForecastingRecord && child.get('status') === 'confirmed'))
      ) {
        return false;
      }
    }

    if (this.pending) {
      if (
        this.pending === 'include' &&
        (!(child instanceof ForecastingRecord) ||
          child.get('is_at_risk') ||
          child.get('status') === 'confirmed')
      ) {
        return false;
      } else if (
        this.pending === 'exclude' &&
        (child instanceof ForecastingRecord && child.get('status') === 'pending')
      ) {
        return false;
      }
    }

    if (this.pledged) {
      if (
        this.pledged === 'include' &&
        (!(child instanceof PledgingRecord) || child.get('is_at_risk'))
      ) {
        return false;
      } else if (
        this.pledged === 'exclude' &&
        (child instanceof PledgingRecord || child.get('is_at_risk'))
      ) {
        return false;
      }
    }

    if (this.atRisk) {
      if (this.atRisk === 'include') {
        if (!child.get('is_at_risk')) {
          return false;
        }
      } else if (this.atRisk === 'exclude') {
        if (child.get('is_at_risk')) {
          return false;
        }
      }
    }

    if (this.isBooked) {
      if (
        (this.isBooked === 'include' && !(child instanceof BookingRecord)) ||
        (this.isBooked === 'exclude' && child instanceof BookingRecord)
      ) {
        return false;
      }
    }

    return isAllowed;
  },
  onFilterSubmit: function() {
    this.filterCasefile = parseInt(this.ui.caseRef.val());
    this.disclosureDateDirection = this.ui.disclosureDateDirection.val();
    this.disclosureDate = this.ui.disclosureDate.val();
    this.confirmed = this.ui.confirmed.val();
    this.pending = this.ui.pending.val();
    this.pledged = this.ui.pledged.val();
    this.atRisk = this.ui.atRisk.val();
    this.isBooked = this.ui.isBooked.val();
    this.render();

    return false;
  },
  onClickResetFilters: function() {
    this.filterCasefile = null;
    this.disclosureDateDirection = null;
    this.disclosureDate = null;
    this.confirmed = null;
    this.pending = null;
    this.pledged = null;
    this.atRisk = null;
    this.isBooked = null;

    this.render();
  },
  onClickSortColumn: function(e) {
    var sortField = $(e.currentTarget).attr('data-sort');
    if (this.sortField === sortField) {
      this.isSortReversed = !this.isSortReversed;
    }
    var isSortReversed = this.isSortReversed;
    var users = this.getOption('users');
    var casefiles = this.getOption('casefiles');
    var teams = this.getOption('teams');
    var teamAllocations = this.getOption('teamAllocations');

    this.sortField = sortField;

    this.collection.comparator = function(a, b) {
      var compare = 0;
      var aUser;
      var bUser;

      if (sortField === 'date') {
        var aDate = new Date(a.get('date'));
        var bDate = new Date(b.get('date'));
        if (aDate.getTime() < bDate.getTime()) {
          compare = -1;
        } else if (aDate.getTime() > bDate.getTime()) {
          compare = 1;
        }
      } else if (sortField === 'case_manager') {
        aUser = users.find(function(user) {
          return user.get('id') === a.get('case_manager');
        });
        bUser = users.find(function(user) {
          return user.get('id') === b.get('case_manager');
        });

        compare = aUser.getFullName().localeCompare(bUser.getFullName());
      } else if (sortField === 'ref' || sortField === 'caseType') {
        var aCase = casefiles.find(function(casefile) {
          return casefile.get('id') === a.get('case');
        });
        var bCase = casefiles.find(function(casefile) {
          return casefile.get('id') === b.get('case');
        });

        if (sortField === 'ref') {
          compare = aCase.get('ref').localeCompare(bCase.get('ref'));
        } else if (sortField === 'caseType') {
          var aCategoryGroup = TCAS.reqres.categoryGroup(aCase.get('category'));
          var bCategoryGroup = TCAS.reqres.categoryGroup(bCase.get('category'));
          var aCaseType = 'asset';
          var bCaseType = 'asset';

          if ([2, 3].indexOf(aCategoryGroup.get('id')) !== -1) {
            aCaseType = 'council';
          }

          if ([2, 3].indexOf(bCategoryGroup.get('id')) !== -1) {
            bCaseType = 'council';
          }

          compare = aCaseType.localeCompare(bCaseType);
        }
      } else if (sortField === 'team') {
        aUser = users.find(function(user) {
          return user.get('id') === a.get('case_manager');
        });
        bUser = users.find(function(user) {
          return user.get('id') === b.get('case_manager');
        });

        var aTeam = teams.find(function(team) {
          var teamAllocation = teamAllocations.find(function(teamAllocation) {
            return teamAllocation.get('user') === aUser.get('id');
          });
          return team.get('id') === teamAllocation.get('team');
        });
        var bTeam = teams.find(function(team) {
          var teamAllocation = teamAllocations.find(function(teamAllocation) {
            return teamAllocation.get('user') === bUser.get('id');
          });
          return team.get('id') === teamAllocation.get('team');
        });

        compare = aTeam.get('name').localeCompare(bTeam.get('name'));
      } else if (
        ['confirmed', 'pending', 'pledged', 'at-risk', 'is_csoa_raised'].indexOf(sortField) !== -1
      ) {
        // revenue related sorting
        var aRevenue = -10000000000;
        var bRevenue = -10000000000;

        if (sortField === 'confirmed') {
          if (
            a instanceof BookingRecord ||
            (a instanceof ForecastingRecord && a.get('status') === 'confirmed')
          ) {
            aRevenue = a.get('revenue');
          }
          if (
            b instanceof BookingRecord ||
            (b instanceof ForecastingRecord && b.get('status') === 'confirmed')
          ) {
            bRevenue = b.get('revenue');
          }
        } else if (sortField === 'pending') {
          if (
            a instanceof ForecastingRecord &&
            a.get('status') === 'pending' &&
            !a.get('is_at_risk')
          ) {
            aRevenue = a.get('revenue');
          }
          if (
            b instanceof ForecastingRecord &&
            b.get('status') === 'pending' &&
            !b.get('is_at_risk')
          ) {
            bRevenue = b.get('revenue');
          }
        } else if (sortField === 'pledged') {
          if (a instanceof PledgingRecord && !b.get('is_at_risk')) {
            aRevenue = a.get('revenue');
          }
          if (b instanceof PledgingRecord && !b.get('is_at_risk')) {
            bRevenue = b.get('revenue');
          }
        } else if (sortField === 'is_at_risk') {
          if (a.get('is_at_risk')) {
            aRevenue = a.get('revenue');
          }
          if (b.get('is_at_risk')) {
            bRevenue = b.get('revenue');
          }
        } else if (sortField === 'is_csoa_raised') {
          if (a instanceof BookingRecord || a.get('is_csoa_raised')) {
            aRevenue = a.get('revenue');
          }
          if (b instanceof BookingRecord || b.get('is_csoa_raised')) {
            bRevenue = b.get('revenue');
          }
        }

        if (aRevenue < bRevenue) {
          compare = -1;
        } else if (aRevenue > bRevenue) {
          compare = 1;
        }
      }

      if (isSortReversed) {
        compare *= -1;
      }

      return compare;
    };
    this.collection.sort();

    return false;
  },
  onClickExport: function() {
    var $button = this.ui.exportBtn;
    $button.button('loading');

    var results = [];
    var row = [];
    this.ui.granularTable.find('th').each(function() {
      row.push('"' + $(this).text() + '"');
    });
    results.push(row.join(','));

    this.ui.granularTable.find('.table-items tr').each(function() {
      row = [];
      $(this)
        .find('td')
        .each(function(index) {
          var $td = $(this);
          var value = $td.text();
          if (index === 12 || index === 14) {
            value = $td.html() === '✔' ? 'yes' : 'no';
          } else if (index >= 8 && index <= 11) {
            // remove £ and , from currency values
            var dataValue = $td.attr('data-value');
            if (dataValue) {
              value = dataValue;
            } else {
              value = value.replace('£', '').replace(',', '');
            }
          }
          if (index >= 5 && index <= 11) {
            row.push(value);
          } else {
            row.push('"' + value + '"');
          }
        });
      results.push(row.join(','));
    });

    var csvData = {
      content: results.join('\n'),
      filename: 'pipeline_granular',
    };

    $.ajax({
      url: '/api/tocsv',
      data: csvData,
      type: 'post',
      success: function(data) {
        $button.button('reset');
        window.location = data;
      },
      error: function(e) {
        console.log(e);
      },
    });
  },
  onClickDisplayPence: function() {
    this.displayPence = this.ui.displayPence.is(':checked');
    this.render();
  },
  resizeTotals: function() {
    // setup the floating pipeline stats
    var $table = this.ui.granularTable;
    var $statsTable = this.ui.totalsTable;
    _.defer(function() {
      var $tds = $statsTable.find('td');
      for (var i = 0; i < $tds.length; i += 1) {
        var width = $table
          .find('th')
          .eq(i)
          .outerWidth();
        var totalWidth = $statsTable.outerWidth();
        // $($tds[i]).css('width', ((width / totalWidth) * 100) + '%');
        $($tds[i]).css('width', width + 'px');
      }
    });
  },
  onRender: function() {
    // calculate the totals from the child views data and display
    var confirmedTotal = 0;
    var pendingTotal = 0;
    var pledgedTotal = 0;
    var atRiskTotal = 0;
    var that = this;

    this.children.each(function(view) {
      var data = view.data;

      if (data.confirmedRevenue) {
        confirmedTotal += data.confirmedRevenue;
      } else if (data.pendingRevenue) {
        pendingTotal += data.pendingRevenue;
      } else if (data.pledgedRevenue) {
        pledgedTotal += data.pledgedRevenue;
      } else if (data.atRiskRevenue) {
        atRiskTotal += data.atRiskRevenue;
      }
    });

    this.ui.confirmedTotal.html(Util.formatCurrency(confirmedTotal, true));
    this.ui.confirmedTotal.toggleClass('text-danger', confirmedTotal < 0);
    this.ui.pendingTotal.html(Util.formatCurrency(pendingTotal, true));
    this.ui.pendingTotal.toggleClass('text-danger', pendingTotal < 0);
    this.ui.pledgedTotal.html(Util.formatCurrency(pledgedTotal, true));
    this.ui.pledgedTotal.toggleClass('text-danger', pledgedTotal < 0);
    this.ui.atRiskTotal.html(Util.formatCurrency(atRiskTotal, true));
    this.ui.atRiskTotal.toggleClass('text-danger', atRiskTotal < 0);

    var bookedConfirmedTotal = confirmedTotal;
    var bookedConfirmedPendingTotal = confirmedTotal + pendingTotal;
    var maximumPotentialTotal = confirmedTotal + pendingTotal + pledgedTotal + atRiskTotal;
    var maximumMinusAtRiskTotal = maximumPotentialTotal - atRiskTotal;
    this.ui.bookedConfirmedTotal.html(Util.formatCurrency(bookedConfirmedTotal, true));
    this.ui.bookedConfirmedTotal.toggleClass('text-danger', bookedConfirmedTotal < 0);
    this.ui.bookedConfirmedPendingTotal.html(
      Util.formatCurrency(bookedConfirmedPendingTotal, true)
    );
    this.ui.bookedConfirmedPendingTotal.toggleClass('text-danger', bookedConfirmedPendingTotal < 0);
    this.ui.maximumPotentialTotal.html(Util.formatCurrency(maximumPotentialTotal, true));
    this.ui.maximumPotentialTotal.toggleClass('text-danger', maximumPotentialTotal < 0);
    this.ui.maximumMinusAtRiskTotal.html(Util.formatCurrency(maximumMinusAtRiskTotal, true));
    this.ui.maximumMinusAtRiskTotal.toggleClass('text-danger', maximumMinusAtRiskTotal < 0);

    this.ui.caseRef.select2();
    this.ui.disclosureDate.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    });

    this.ui.filterPanel.on('show.bs.collapse', function() {
      that.isFilterPanelOpen = true;
    });

    this.listenTo(TCAS.vent, 'resize', function() {
      this.resizeTotals();
    });

    this.resizeTotals();

    this.ui.copyBtn.tooltip({
      title: 'Copied!',
      placement: 'bottom',
      trigger: 'manual',
    });
    if (this.clipboard) {
      this.clipboard.destroy();
    }
    this.clipboard = new Clipboard(this.ui.copyBtn[0]);
    this.clipboard.on('success', function(e) {
      that.ui.copyBtn.tooltip('show');
      e.clearSelection();
    });
  },
  serializeData: function() {
    var data = {};
    data.casefiles = this.getOption('casefiles').toJSON();
    data.filterCasefile = this.filterCasefile;
    data.disclosureDateDirection = this.disclosureDateDirection;
    data.disclosureDate = this.disclosureDate;
    data.fromBookingDate = this.fromBookingDate;
    data.toBookingDate = this.toBookingDate;
    data.confirmed = this.confirmed;
    data.pending = this.pending;
    data.pledged = this.pledged;
    data.atRisk = this.atRisk;
    data.isBooked = this.isBooked;
    data.isFilterPanelOpen = this.isFilterPanelOpen;
    data.tableId = 'table' + Math.floor(Math.random() * 100000);
    data.displayPence = this.displayPence;

    if ($(window).width() < 768) {
      data.isMobile = true;
    }

    return data;
  },
});

module.exports = PipelineGranularListView;
