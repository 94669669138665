'use strict';

var template = require('../../templates/milestones/status-flags-actions.hbs');
var CaseFile = require('../../models/cases/casefile');
var Milestone = require('../../models/cases/milestone');
var MilestoneTypes = require('../../models/cases/milestonetypes');
var Requests = require('../../models/cases/requests');
var Request = require('../../models/cases/request');
var User = require('../../models/users/user');
var MilestoneConditionsModalView = require('./milestone-conditions-modal');

var StatusFlagsActions = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    abandonBtn: '#abandon-btn',
    inClaimsBtn: '#inclaims-btn',
    caseConcludedBtn: '#conclude-btn',
    faSentBtn: '#fa-sent-btn',
    disclosureSentBtn: '#disclosure-sent-btn',
    caseBookedBtn: '#case-booked-btn',
    signOffBtn: '#sign-off-btn',
    handBackBtn: '#handback-btn',
    // revert buttons
    revertResearchBtn: '#revert-research-btn',
    revertClaimsBtn: '#revert-claims-btn',
    revertFASentBtn: '#revert-fasent-btn',
    revertDisclosureBtn: '#revert-disclosure-btn',
    revertBookedBtn: '#revert-booked-btn',
    revertStatus: '#revert-from-abandon-btn',
    // admin apply request
    applySignOffBtn: '.apply-sign-off',
    applyConcludeBtn: '.apply-concluded',
    applyClaimsBtn: '.apply-claims',
    applyDisclosureBtn: '.apply-disclosure',
    applyBookedBtn: '.apply-booked',
    // special actions
    unarchiveBtn: '.unarchive',
  },
  events: {
    'click @ui.abandonBtn': 'abandon',
    'click @ui.inClaimsBtn': 'inClaims',
    'click @ui.caseConcludedBtn': 'concludeCase',
    'click @ui.faSentBtn': 'faSent',
    'click @ui.disclosureSentBtn': 'disclosureSent',
    'click @ui.caseBookedBtn': 'booked',
    'click @ui.signOffBtn': 'signOff',
    'click @ui.handBackBtn': 'handBack',
    // click revert buttons
    'click @ui.revertResearchBtn': 'revertResearch',
    'click @ui.revertClaimsBtn': 'revertClaims',
    'click @ui.revertFASentBtn': 'revertFASent',
    'click @ui.revertDisclosureBtn': 'revertDisclosure',
    'click @ui.revertBookedBtn': 'revertBooked',
    'click @ui.revertStatus': 'revertStatus',
    // admin apply buttons
    'click @ui.applySignOffBtn': 'applySignOff',
    'click @ui.applyConcludeBtn': 'applyConcluded',
    'click @ui.applyClaimsBtn': 'applyClaims',
    'click @ui.applyDisclosureBtn': 'applyDisclosure',
    'click @ui.applyBookedBtn': 'applyBooked',
    // special actions
    'click @ui.unarchiveBtn': 'unarchiveCase',
  },
  onRender: function() {
    var that = this;
    this.listenToOnce(TCAS.vent, 'status:change', function() {
      that.render();
    });
    this.listenToOnce(TCAS.vent, 'casefile:status:changed', function() {
      that.render();
    });
  },
  serializeData: function() {
    var data = {};
    var status = this.getOption('casefile').get('status');

    data.isAdmin = TCAS.session.hasGroup(User.GROUPS.ADMIN);

    // include active case manager in serialize data, new milestones should not
    // be applied if case is unassigned
    var allocation = this.getOption('casefile').getCurrentAllocation();
    if (allocation && allocation.get('case_manager')) {
      data.case_manager = allocation.get('case_manager'); // jshint ignore:line
    }

    var abandoned = false,
      unallocated = false,
      concluded = false,
      claims = false,
      research = false;

    switch (status) {
      case CaseFile.STATUS_IDS.ABANDONED:
        abandoned = true;
        break;
      case CaseFile.STATUS_IDS.UNALLOCATED:
        unallocated = true;
        break;
      case CaseFile.STATUS_IDS.CONCLUDED:
        concluded = true;
        break;
      case CaseFile.STATUS_IDS.IN_CLAIMS:
        claims = true;
        break;
      case CaseFile.STATUS_IDS.RESEARCH:
        research = true;
        break;
    }

    var pendingHandback = this.options.pendingHandback;
    var pendingAbandon = this.options.pendingAbandon;

    var signOffM = this.options.milestoneTypes.findWhere({ name: 'Research Report Approved' });
    var faSentM = this.options.milestoneTypes.findWhere({ name: 'Engagement Pack Sent' });
    var inClaimsM = this.options.milestoneTypes.findWhere({ name: 'In Claims' });
    var disclosureSentM = this.options.milestoneTypes.findWhere({
      name: 'Letter of Full Disclosure Sent',
    });
    var caseBookedM = this.options.milestoneTypes.findWhere({ name: 'Case Booked' });
    var concludedM = this.options.milestoneTypes.findWhere({ name: 'Case Concluded' });

    if (abandoned) {
      data.revertFromAbandon = true;
      return data;
    }

    if (concluded && this.getOption('casefile').get('is_archived')) {
      data.showUnarchive = true;
      return data;
    }

    if (faSentM.get('checked') && TCAS.session.hasGroup(User.GROUPS.ADMIN)) {
      data.revertFASent = true;
    }
    if (disclosureSentM.get('checked') && TCAS.session.hasGroup(User.GROUPS.ADMIN)) {
      data.revertDisclosure = true;
    }
    if (caseBookedM.get('checked') && TCAS.session.hasGroup(User.GROUPS.ADMIN)) {
      data.revertBooked = true;
    }

    if (unallocated) {
      if (pendingAbandon) {
        data.showAbandonPending = true;
      } else {
        data.showAbandon = true;
      }
    }

    if (research) {
      if (inClaimsM.get('pending')) {
        data.showInClaimsPending = true;
      } else {
        data.showInClaims = true;
      }

      if (signOffM.get('pending')) {
        data.showSignOffPending = true;
      } else if (!signOffM.get('checked')) {
        data.showSignOff = true;
      }

      if (!faSentM.get('checked')) {
        data.showFaSent = true;
      }

      if (pendingAbandon) {
        data.showAbandonPending = true;
      } else {
        data.showAbandon = true;
      }

      if (pendingHandback) {
        data.showHandbackPending = true;
      } else {
        data.handBack = true;
      }

      return data;
    }

    if (claims) {
      if (disclosureSentM.get('pending')) {
        data.showDisclosureSentPending = true;
      } else if (!disclosureSentM.get('checked')) {
        data.showDisclosureSent = true;
      }

      if (caseBookedM.get('pending')) {
        data.showCaseBookedPending = true;
      } else if (!caseBookedM.get('checked')) {
        data.showCaseBooked = true;
      }

      if (concludedM.get('pending')) {
        data.showConcludePending = true;
      } else if (!concludedM.get('checked')) {
        data.showConclude = true;
      }

      if (pendingAbandon) {
        data.showAbandonPending = true;
      } else {
        data.showAbandon = true;
      }

      if (pendingHandback) {
        data.showHandbackPending = true;
      } else {
        data.handBack = true;
      }

      if (TCAS.session.hasGroup(User.GROUPS.ADMIN)) {
        data.revertResearch = true;
      }
      return data;
    }

    if (concluded && TCAS.session.hasGroup(User.GROUPS.ADMIN)) {
      data.revertClaims = true;
      data.revertResearch = true;
    }

    return data;
  },
  checkConditions: function(milestoneType, that) {
    var data = {};
    data.apply = true;
    data.conditionList = new MilestoneTypes([]);
    data.casefile = this.getOption('casefile');

    milestoneType.get('conditions').forEach(function(condition) {
      var cond = that.options.milestoneTypes.findWhere({ id: condition });
      // condition may not exist in milestone types, this can happen depending
      // on whether it's an Asset or LA case
      if (cond) {
        data.conditionList.add(cond);
        if (!cond.get('checked')) {
          data.apply = false;
        }
      }
    });
    return data;
  },
  showConditionsModal: function(data) {
    var conditionsModal = new MilestoneConditionsModalView(data);
    conditionsModal.render();
  },
  saveMilestone: function(milestoneType) {
    var milestone = new Milestone({
      case: this.options.casefile.get('id'),
      milestone_type: parseInt(milestoneType.get('id')), // jshint ignore:line
      owner: TCAS.session.get('id'),
      user: this.options.casefile.getCurrentAllocation().get('case_manager'),
    });
    milestone.save().then(function() {
      milestoneType.set({
        created: milestone.get('created'),
        checked: true,
        milestone: milestone,
      });
      TCAS.vent.trigger('status:change');
    });
  },
  inClaims: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'In Claims' });

    var data = this.checkConditions(milestoneType, that);
    if (!data.apply) {
      that.showConditionsModal(data);
    } else {
      that.sendRequest(milestoneType, that);
    }
  },
  faSent: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Engagement Pack Sent' });

    var data = this.checkConditions(milestoneType, that);
    if (!data.apply) {
      that.showConditionsModal(data);
    } else {
      that.options.casefile.set('fa_sent', true);
      that.options.casefile.save().then(function() {
        that.saveMilestone(milestoneType, that);
      });
    }
  },
  disclosureSent: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({
      name: 'Letter of Full Disclosure Sent',
    });

    var data = this.checkConditions(milestoneType, that);
    if (!data.apply) {
      that.showConditionsModal(data);
    } else {
      that.sendRequest(milestoneType, that);
    }
  },
  booked: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Case Booked' });

    var data = this.checkConditions(milestoneType, that);
    if (!data.apply) {
      that.showConditionsModal(data);
    } else {
      that.sendRequest(milestoneType, that);
    }
  },
  signOff: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({
      name: 'Research Report Approved',
    });

    var data = this.checkConditions(milestoneType, that);
    if (!data.apply) {
      that.showConditionsModal(data);
    } else {
      that.sendRequest(milestoneType, that);
    }
  },
  handBack: function() {
    var that = this;
    var request = new Request({
      case: that.options.casefile.get('id'),
      owner: TCAS.session.get('id'),
      user: that.options.casefile.getCurrentAllocation().get('case_manager'),
      description: Request.DESCRIPTIONS.REQUEST_HAND_BACK,
      type: Request.TYPES.CASE,
      action: Request.ACTIONS.HAND_BACK,
      status: Request.STATUSES.PENDING,
    });
    request.save(
      {},
      {
        success: function() {
          that.ui.handBackBtn.prop('disabled', true);
          that.ui.handBackBtn.text('Hand Back: Pending');
        },
      }
    );
  },
  abandon: function() {
    var that = this;
    var request = new Request({
      case: this.options.casefile.get('id'),
      owner: TCAS.session.get('id'),
      user: that.options.casefile.getCurrentAllocation().get('case_manager'),
      description: Request.DESCRIPTIONS.REQUEST_ABANDON_CASE,
      type: Request.TYPES.CASE,
      action: Request.ACTIONS.ABANDON_CASE,
      status: Request.STATUSES.PENDING,
    });
    request.save(
      {},
      {
        success: function() {
          that.ui.abandonBtn.prop('disabled', true);
          that.ui.abandonBtn.text('Abandon Case: Pending');
        },
      }
    );
  },
  concludeCase: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Case Concluded' });

    var data = this.checkConditions(milestoneType, that);
    if (!data.apply) {
      that.showConditionsModal(data);
    } else {
      that.sendRequest(milestoneType, that);
    }
  },
  revertResearch: function() {
    // want to revert to in research, current must be in claims
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'In Claims' });
    var milestone = milestoneType.get('milestone');
    milestone.save(
      { is_active: false },
      {
        // jshint ignore:line
        success: function() {
          TCAS.vent.trigger('collect:milestones');
        },
      }
    );
  },
  revertClaims: function() {
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Case Concluded' });
    var milestone = milestoneType.get('milestone');
    milestone.save(
      { is_active: false },
      {
        // jshint ignore:line
        success: function() {
          TCAS.vent.trigger('collect:milestones');
        },
      }
    );
  },
  revertFASent: function() {
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Engagement Pack Sent' });
    var milestone = milestoneType.get('milestone');
    milestone.save(
      { is_active: false },
      {
        // jshint ignore:line
        success: function() {
          TCAS.vent.trigger('collect:milestones');
        },
      }
    );
  },
  revertDisclosure: function() {
    var milestoneType = this.options.milestoneTypes.findWhere({
      name: 'Letter of Full Disclosure Sent',
    });
    var milestone = milestoneType.get('milestone');
    milestone.save(
      { is_active: false },
      {
        // jshint ignore:line
        success: function() {
          TCAS.vent.trigger('collect:milestones');
        },
      }
    );
  },
  revertBooked: function() {
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Case Booked' });
    var milestone = milestoneType.get('milestone');
    milestone.save(
      { is_active: false },
      {
        // jshint ignore:line
        success: function() {
          TCAS.vent.trigger('collect:milestones');
        },
      }
    );
  },
  applySignOff: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Research Report Approved' });
    this.acceptRequest(milestoneType, that);
  },
  applyConcluded: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Case Concluded' });
    this.acceptRequest(milestoneType, that);
  },
  applyClaims: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'In Claims' });
    this.acceptRequest(milestoneType, that);
  },
  applyDisclosure: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({
      name: 'Letter of Full Disclosure Sent',
    });
    this.acceptRequest(milestoneType, that);
  },
  applyBooked: function() {
    var that = this;
    var milestoneType = this.options.milestoneTypes.findWhere({ name: 'Case Booked' });
    this.acceptRequest(milestoneType, that);
  },
  revertStatus: function() {
    var that = this;

    if (
      that.options.milestoneTypes.findWhere({
        checked: true,
        name: 'In Claims',
      })
    ) {
      that.options.casefile.set('status', CaseFile.STATUS_IDS.IN_CLAIMS);
      that.options.casefile.save().then(function() {
        TCAS.vent.trigger('status:change');
      });
    } else if (
      that.options.milestoneTypes.findWhere({
        checked: true,
        name: 'Case Allocated',
      })
    ) {
      that.options.casefile.set('status', CaseFile.STATUS_IDS.RESEARCH);
      that.options.casefile.save().then(function() {
        TCAS.vent.trigger('status:change');
      });
    } else {
      that.options.casefile.set('status', CaseFile.STATUS_IDS.UNALLOCATED);
      that.options.casefile.save().then(function() {
        TCAS.vent.trigger('status:change');
      });
    }
  },
  unarchiveCase: function() {
    var that = this;
    this.ui.unarchiveBtn.button('loading');
    var caseFile = this.options.casefile;
    var url = caseFile.url() + '/unarchive/';
    $.get(url, function(data, textStatus) {
      if (textStatus === 'success' && !data.is_archived) {
        window.location.reload();
      } else {
        that.ui.unarchiveBtn.button('reset');
      }
    });
  },
  sendRequest: function(milestoneType, that) {
    var description, action;
    if (milestoneType.get('name') === 'Research Report Approved') {
      description = Request.DESCRIPTIONS.REQUEST_SIGN_OFF;
      action = Request.ACTIONS.SIGN_OFF;
    }
    if (milestoneType.get('name') === 'Case Concluded') {
      description = Request.DESCRIPTIONS.REQUEST_CONCLUDE_CASE;
      action = Request.ACTIONS.CONCLUDE_CASE;
    }
    if (milestoneType.get('name') === 'In Claims') {
      description = Request.DESCRIPTIONS.REQUEST_IN_CLAIMS;
      action = Request.ACTIONS.IN_CLAIMS;
    }
    if (milestoneType.get('name') === 'Letter of Full Disclosure Sent') {
      description = Request.DESCRIPTIONS.REQUEST_DISCLOSURE_SENT;
      action = Request.ACTIONS.DISCLOSURE_SENT;
    }
    if (milestoneType.get('name') === 'Case Booked') {
      description = Request.DESCRIPTIONS.REQUEST_CASE_BOOKED;
      action = Request.ACTIONS.CASE_BOOKED;
    }

    var request = new Request({
      case: that.options.casefile.get('id'),
      owner: TCAS.session.get('id'),
      user: that.options.casefile.getCurrentAllocation().get('case_manager'),
      description: description,
      request_type: Request.TYPES.CASE, // jshint ignore:line
      action: action,
      status: Request.STATUSES.PENDING,
    });
    request.save(
      {},
      {
        success: function() {
          milestoneType.set('pending', true);
          TCAS.vent.trigger('collect:milestones', that);
        },
        error: function() {
          console.log('request failed');
        },
      }
    );
  },
  acceptRequest: function(milestoneType, that) {
    var request = milestoneType.get('request');
    request.set('status', Request.STATUSES.APPROVED);
    request.set('actioned_by', TCAS.session.get('id'));
    request.save(
      {},
      {
        success: function() {
          //milestoneType.unset('pending');
          TCAS.vent.trigger('collect:milestones', that);
        },
        error: function() {
          console.log('request failed');
        },
      }
    );
  },
});

module.exports = StatusFlagsActions;
