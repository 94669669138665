// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row\">\n  <div class=\"col-md-4\">\n    <div class=\"panel panel-default\">\n      <div class=\"panel-heading\">\n        <h3 class=\"panel-title\">Personal Details</h3>\n      </div>\n      <div class=\"panel-body\">\n        <div class=\"table-responsive\">\n          <table class=\"table table-condensed\">\n            <tbody>\n            <tr>\n              <td class=\"col-sm-4 col-xs-6\">Title</td>\n              <td class=\"col-sm-8 col-xs-6\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.title : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>First name</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.first_name : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Middle name</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.middle_name : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Last name</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.last_name : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Maiden name/Surname at birth</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.surname_at_birth : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Former name(s)</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.former_names : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Gender</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.gender : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Date of Birth</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.date_of_birth : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>National Insurance Number</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.ni_number : stack1), depth0))
    + "</td>\n            </tr>\n            </tbody>\n          </table>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"col-md-4\">\n    <div class=\"panel panel-default\">\n      <div class=\"panel-heading\">\n        <h3 class=\"panel-title\">Address</h3>\n      </div>\n      <div class=\"panel-body\">\n        <div class=\"table-responsive\">\n          <table class=\"table table-condensed\">\n            <tbody>\n            <tr>\n              <td class=\"col-sm-4 col-xs-6\">Address line 1</td>\n              <td class=\"col-sm-8 col-xs-6\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.address1 : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Address line 2</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.address2 : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>City</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.city : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Post code</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.post_code : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Country</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.country : stack1), depth0))
    + "</td>\n            </tr>\n            </tbody>\n          </table>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"col-md-4\">\n    <div class=\"panel panel-default\">\n      <div class=\"panel-heading\">\n        <h3 class=\"panel-title\">Contact</h3>\n      </div>\n      <div class=\"panel-body\">\n        <div class=\"table-responsive\">\n          <table class=\"table table-condensed\">\n            <tbody>\n            <tr>\n              <td class=\"col-sm-4 col-xs-6\">Phone</td>\n              <td class=\"col-sm-8 col-xs-6\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.phone : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Mobile</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.mobile : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n              <td>Email</td>\n              <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.email : stack1), depth0))
    + "</td>\n            </tr>\n            </tbody>\n          </table>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});
