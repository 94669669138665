'use strict';

var _ = require('lodash');
var template =
  require('../../../templates/reporting/company-activity-summary-table.hbs');

var CompanyActivitySummaryTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  initialize: function() {
    this.data = {};
  },
  setData: function(data, section) {
    _.extend(this.data, data);
    this.render();
  },
  serializeData: function() {
    var data = _.extend({}, this.data);
    var averageByMonth;
    var averageYTD;
    var totalRevenue = 0;
    var totalCases = 0;
    var i;

    var target = this.getOption('target');

    data.phasedTarget = [];
    data.phasedStretch = [];
    data.targetAverageAssetCaseSize = target.get('average_asset_case_size');
    data.targetAverageLACaseSize = target.get('average_la_case_size');
    data.targetBookedAssetVolume = target.get('booked_asset_volume');
    data.targetBookedAssetVolumeYTD = [];
    data.targetBookedLAVolume = target.get('booked_la_volume');
    data.targetBookedLAVolumeYTD = [];
    for (i = 0; i < 12; i += 1) {
      data.phasedTarget[i] = (target.get('performance_revenue') / 12) * (i + 1);
      data.phasedStretch[i] = (target.get('stretch_revenue') / 12) * (i + 1);
      data.targetBookedAssetVolumeYTD[i] =
        data.targetBookedAssetVolume * (i + 1);
      data.targetBookedLAVolumeYTD[i] = data.targetBookedLAVolume * (i + 1);
    }

    if (this.data.assetRevenueByMonth && this.data.numAssetCasesByMonth) {
      averageByMonth = [];
      averageYTD = [];
      totalRevenue = 0;
      totalCases = 0;

      for (i = 0; i < this.data.assetRevenueByMonth.length; i += 1) {
        totalRevenue += this.data.assetRevenueByMonth[i];

        if (this.data.numAssetCasesByMonth[i] > 0) {
          averageByMonth[i] =
            this.data.assetRevenueByMonth[i] / this.data.numAssetCasesByMonth[i];
          totalCases += this.data.numAssetCasesByMonth[i];
        } else {
          averageByMonth[i] = 0;
        }

        averageYTD[i] = 0;
        if (totalCases > 0) {
          averageYTD[i] = totalRevenue / totalCases;
        }
      }
      data.averageAssetCaseSizeByMonth = averageByMonth;
      data.averageAssetCaseSizeYTD = averageYTD;
    }

    if (this.data.laRevenueByMonth && this.data.numLACasesByMonth) {
      averageByMonth = [];
      averageYTD = [];
      totalRevenue = 0;
      totalCases = 0;

      for (i = 0; i < this.data.laRevenueByMonth.length; i += 1) {
        totalRevenue += this.data.laRevenueByMonth[i];

        if (this.data.numLACasesByMonth[i] > 0) {
          averageByMonth[i] =
            this.data.laRevenueByMonth[i] / this.data.numLACasesByMonth[i];
          totalCases += this.data.numLACasesByMonth[i];
        } else {
          averageByMonth[i] = 0;
        }

        averageYTD[i] = 0;
        if (totalCases > 0) {
          averageYTD[i] = totalRevenue / totalCases;
        }
      }
      data.averageLACaseSizeByMonth = averageByMonth;
      data.averageLACaseSizeYTD = averageYTD;
    }

    return data;
  }
});

module.exports = CompanyActivitySummaryTableView;
