// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td><input type=\"text\" class=\"form-control input-sm\" name=\"date\" placeholder=\"DD/MM/YYYY\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"/></td>\n<td><input type=\"number\" class=\"form-control input-sm\" name=\"amount\" placeholder=\"Amount...\" value=\""
    + alias3((helpers.fixedDecimalPlaces || (depth0 && depth0.fixedDecimalPlaces) || alias2).call(alias1,(depth0 != null ? depth0.amount : depth0),2,{"name":"fixedDecimalPlaces","hash":{},"data":data}))
    + "\"/></td>\n<td><input type=\"text\" class=\"form-control input-sm\" name=\"payee\" placeholder=\""
    + alias3(((helper = (helper = helpers.payeePlaceholder || (depth0 != null ? depth0.payeePlaceholder : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"payeePlaceholder","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.payee || (depth0 != null ? depth0.payee : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"payee","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td><input type=\"text\" class=\"form-control input-sm\" name=\"client\" value=\""
    + alias3(((helper = (helper = helpers.client || (depth0 != null ? depth0.client : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"client","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td><textarea type=\"text\" class=\"form-control input-sm\" name=\"reason\" placeholder=\"Description...\" rows=\"1\" maxlength=\"64\">"
    + alias3(((helper = (helper = helpers.reason || (depth0 != null ? depth0.reason : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"reason","hash":{},"data":data}) : helper)))
    + "</textarea></td>\n<td><input type=\"number\" class=\"form-control input-sm\" name=\"vat\" placeholder=\"VAT\" value=\""
    + alias3((helpers.fixedDecimalPlaces || (depth0 && depth0.fixedDecimalPlaces) || alias2).call(alias1,(depth0 != null ? depth0.vat : depth0),2,{"name":"fixedDecimalPlaces","hash":{},"data":data}))
    + "\"/></td>\n<td><button type=\"button\" class=\"btn btn-xs btn-default cancel\">Cancel</button> <button type=\"button\" class=\"btn btn-xs btn-primary save\" data-loading-text=\"Saving...\">Save</button></td>";
},"useData":true});
