'use strict';

var Dropzone = require('dropzone');
var template = require('../../templates/messages/message.hbs');
var templateEmailChecklist = require('../../templates/modal-confirm-email-check.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var ConfirmModal = require('../confirm-modal');
var FileSelectView = require('../cases/file-select');
var Message = require('../../models/cases/message');
var User = require('../../models/users/user');
var File = require('../../models/cases/file');
var Files = require('../../models/cases/files');
var FileList = require('../cases/file-list');

var MessageView = Backbone.Marionette.ItemView.extend({
  className: 'message',
  template: template,
  events: {
    'click': 'onClick',
    'mouseover': 'onMouseOver',
    'click @ui.editBtn': 'editMessage',
    'click @ui.pinBtn': 'togglePinMessage',
    'click @ui.publishBtn': 'publish',
    'click @ui.saveBtn': 'saveMessage',
    'click @ui.deleteBtn': 'deleteMessage',
    'click @ui.flagBtn': 'toggleFlagMessage',
    'click @ui.showAttachments': 'onClickShowAttachments',
    'click @ui.attachExistingBtn': 'onClickAttachExisting',
    'click @ui.previewEmail': 'onClickPreviewEmail'
  },
  ui: {
    editMessageContent: '.edit-message-content',
    messageContent: '.message-content',
    subject: 'input.subject',
    clientViewable: '.client-viewable',
    showAttachments: '.show-attachments',
    messageAttachments: '.message-attachments-container',
    attachExistingBtn: 'button.attach-existing',
    attachExistingContainer: '.attach-existing-container',
    previewEmail: '.preview-email',
    editBtn: '.edit-message',
    pinBtn: '.pin',
    deleteBtn: '.delete',
    flagBtn: '.flag',
    publishBtn: '.publish',
    saveBtn: '.save',
    attachBtn: 'button.attach',
    faoSelect: '.fao-select',
    faoClientsSelect: '.fao-clients-select',
    dropzonePreviews: '.dropzone-previews'
  },
  modelEvents: {
    'change:selected': 'selectedChanged',
    'change:highlight': 'highlightChanged',
    'change:client_viewable': 'clientViewableChanged',
    'change:is_pinned': 'isPinnedChanged',
    'change:is_flagged': 'isFlaggedChanged'
  },
  initialize: function(){
    if (this.model.get('parents').length) {
      this.$el.addClass('nest-'+this.model.get('parents').length);
    }
    this.$el.attr('id', 'message-'+this.model.get('id'));
    this.$el.toggleClass('pinned', this.model.get('is_pinned'));
    this.$el.toggleClass('message-from-client', this.model.get('is_from_client'));

    this.options.editMode = false;
  },
  selectedChanged: function(model, selected){
    this.$el.toggleClass('active', selected);
    if (selected){
      this.$el.removeClass('highlight');
      this.$el.css('background-color', '');
    }
  },
  clientViewableChanged: function(model, isClientViewable) {
    this.$el.toggleClass('client-viewable', isClientViewable);
  },
  isPinnedChanged: function(model, isPinned) {
    this.$el.toggleClass('pinned', isPinned);
    this.ui.pinBtn.toggleClass('active', isPinned);
  },
  isFlaggedChanged: function(model, isFlagged) {
    if (isFlagged) {
      this.ui.flagBtn.addClass('active');
      this.ui.flagBtn.prop('disabled', !TCAS.session.hasPerm('unflag_message'));
    } else {
      this.ui.flagBtn.removeClass('active');
      this.ui.flagBtn.prop('disabled', !TCAS.session.hasPerm('flag_message'));
    }
  },
  onClick: function(){
    if(!this.options.editMode) {
      if (this.model.get('fao_clients').length === 0) {
        this.model.set('selected', !this.model.get('selected'));
      } else {
        this.$el.addClass('expanded');
      }
    }
  },
  onClickAttachExisting: function(e) {
    e.preventDefault();
    e.stopPropagation();

    this.fileSelectionRegion = new Backbone.Marionette.Region({
      el: this.ui.attachExistingContainer
    });
    this.fileSelectView = new FileSelectView({
      casefile: this.getOption('casefile'),
      multiple: true
    });
    this.fileSelectionRegion.show(this.fileSelectView);
  },
  onMouseOver: function(){
    //mouse over should immediately clear any background transitions
    this.$el.removeClass('highlight unhighlight');
  },
  onClickShowAttachments: function(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    var that = this;

    function showAttachments(files) {
      // files is supposed to be an array of casefile File models
      var collection = new Files(files, {
        urlRoot: that.getOption('casefile').url()+'/files/?page_size=1000',
        comparator: 'original_file_name'
      });
      that.listenTo(collection, 'sync', function() {
        showAttachments(collection.filter(function(file) {
          return attachments.indexOf(file.get('id')) !== -1;
        }));
      });
      var region = new Backbone.Marionette.Region({
        el: that.ui.messageAttachments
      });
      var fileList = new FileList({
        collection: collection,
        casefile: that.getOption('casefile'),
        isSimple: true,
        editMode: that.options.editMode
      });
      region.show(fileList);
      that.listenTo(fileList, 'removeFile', function(file) {
        var confirmModal = new ConfirmModal({
          body: 'Are you sure you want to remove this attachment?<br/>Remember to click Save to apply your changes.'
        });
        that.listenTo(confirmModal, 'confirmYes', function() {
          if (!that.attachmentsToRemove) {
            that.attachmentsToRemove = [];
          }
          that.attachmentsToRemove.push(file.get('id'));
          collection.remove(file);
        });
        confirmModal.render();
      });
      that.ui.showAttachments.addClass('hidden');
    }

    var files = this.getOption('casefile').get('files');
    var attachments = this.model.get('attachments');
    if (files.length) {
      showAttachments(files.filter(function(file) {
        return attachments.indexOf(file.get('id')) !== -1;
      }));
    } else {
      this.getOption('casefile').fetchFiles().then(function() {
        var files = that.getOption('casefile').get('files');
        showAttachments(files.filter(function(file) {
          return attachments.indexOf(file.get('id')) !== -1;
        }));
      });
    }
  },
  onClickPreviewEmail: function(e) {
    e.preventDefault();
    e.stopPropagation();
    var previewURL = this.$(e.currentTarget).attr('href');
    TCAS.commands.execute('popoutURL', previewURL, 'TCAS Email Preview', 800, 800);
    return false;
  },
  editMessage: function(e) {
    e.preventDefault();
    e.stopPropagation();
    this.options.editMode = !this.options.editMode;
    this.render();
  },
  togglePinMessage: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.model.save({ is_pinned: !this.model.get('is_pinned') },
      { wait: true }
    );
  },
  toggleFlagMessage: function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.model.get('is_flagged') && !TCAS.session.hasPerm('unflag_message')) {
      return false;
    } else if (!TCAS.session.hasPerm('flag_message')) {
      return false;
    }

    var confirmMsg = 'Are you sure you want to flag this for review?';
    if (this.model.isClientEmail()) {
      confirmMsg = 'Are you sure you want to flag this email for review?';
    }

    if (this.model.get('is_flagged')) {
      // skip confirm dialog for unflagging
      this.model.save({ is_flagged: false }, { wait: true });
    } else {
      var confirmModal = new ConfirmModal({
        body: confirmMsg
      });
      this.listenTo(confirmModal, 'confirmYes', function() {
        this.model.save({ is_flagged: true }, { wait: true });
      });
      confirmModal.render();
    }
  },
  unflagMessage: function(e) {
    e.preventDefault();
    e.stopPropagation();
  },
  saveMessage: function() {
    var that = this;
    var message = this.ui.editMessageContent.val();
    var subject = null;
    if (this.ui.subject.length) {
      subject = this.ui.subject.val();
    }
    this.model.urlRoot = '/api/messages/';
    var attachments = this.model.get('attachments');
    if (this.attachmentsToRemove && this.attachmentsToRemove.length) {
      attachments = attachments.filter(function(fileId) {
        return that.attachmentsToRemove.indexOf(fileId) === -1;
      });
    }
    var fao = this.ui.faoSelect.val();
    if (fao && fao.length) {
      fao = fao.split(',');
    } else {
      fao = [];
    }

    function doSaveMessage(fileIds) {
      /* jshint ignore:end */

      that.model.save({ attachments: fileIds, content: message, subject: subject, fao: fao }, {
        success: function() {
          that.options.editMode = false;
          that.render();
          that.$el.append(
            '<div class="alert alert-success alert-buffer">' +
            'Edits saved!' +
            '<a class="close" data-dismiss="alert" href="#">&times;</a>' +
            '</div>'
          );
          that.model.set('selected', false);
        },
        error: function() {
          that.render();
          that.$el.append(
            '<div class="alert alert-danger alert-buffer">' +
            'An error occurred and your message was not saved.' +
            '<a class="close" data-dismiss="alert" href="#">&times;</a>' +
            '</div>'
          );
          that.model.set('selected', false);
        }
      });
    }

    // if there were existing attachments added, ensure the file is marked client viewable
    if (this.model.get('fao_clients').length && this.fileSelectView && this.fileSelectView.getSelectedFile()) {
      var clientIds = this.model.get('fao_clients');
      var newAttachments = this.fileSelectView.getSelectedFile();
      var files = this.getOption('casefile').get('files');
      newAttachments.forEach(function(fileId) {
        if (attachments.indexOf(fileId) == -1) {
          attachments.push(fileId);
        }
        var file = files.find(function(f) {
          return f.get('id') == fileId;
        });
        var clientsAllowed = file.get('clients_allowed');
        clientIds.forEach(function(clientId) {
          if (clientsAllowed.indexOf(clientId) == -1) {
            clientsAllowed.push(clientId);
          }
        });
        file.save({ 'clients_allowed': clientsAllowed }, { wait: true, patch: true });
      });
    }

    if (!this.model.get('is_published') && this.dropzone.getQueuedFiles().length) {
      this.listenTo(this, 'attachment:success', function(fileIds) {
        this.stopListening(this, 'attachment:success');
        var newFileIds = attachments.concat(fileIds);

        doSaveMessage(newFileIds);
      });
      this.dropzone.processQueue();
    } else {
      doSaveMessage(attachments);
    }


  },
  deleteMessage: function(e) {
    e.preventDefault();
    e.stopPropagation();
    var confirmMsg = 'Are you sure you want to delete this note?'
    if (this.model.isClientEmail()) {
      confirmMsg = 'Are you sure you want to delete this email?';
    }

    var confirmModal = new ConfirmModal({
      body: confirmMsg
    });
    this.listenTo(confirmModal, 'confirmYes', function() {
      this.model.destroy({ wait: true });
    });
    confirmModal.render();
  },
  publish: function(e) {
    e.preventDefault();
    e.stopPropagation();
    var that = this;
    var confirmModal = new ConfirmModal({
      template: templateEmailChecklist
    });
    this.listenTo(confirmModal, 'confirmYes', function() {
      this.model.save({ is_published: true });
    });
    confirmModal.render();
  },
  highlightChanged: function(model, highlight){
    var that = this;

    this.$el.toggleClass('highlight', highlight);

    if (highlight) {
      setTimeout(function () {
        that.$el.addClass('unhighlight');
        that.$el.removeClass('highlight');
        setTimeout(function () {
          that.model.set('highlight', false);
          that.$el.removeClass('unhighlight');
        }, 5000);
      }, 1000);
    }
  },
  setupSelect2: function() {
    var that = this;
    var $faoSelect = this.ui.faoSelect;
    var $faoClientsSelect = this.ui.faoClientsSelect;
    var clientId = this.getOption('clientId');

    var caseWorkers = TCAS.users.filter(function(user){
      return user.hasAnyGroups(User.CASE_WORKER_GROUPS);
    });
    caseWorkers = _.map(caseWorkers, function(user){
      return {id: user.get('id'), text: user.getFullName()};
    });

    if (this.getOption('clients')) {
      var clients = this.getOption('clients').filter(function(client) {
        return client.get('contact').get('email');
      }).map(function (client) {
        return { id: client.get('id'), text: client.getFullName() };
      });
    }

    // need to defer as the placeholder wasn't showing up
    _.defer(function() {
      $faoSelect.select2({
        multiple: true,
        allowClear: true,
        placeholder: 'Select people to notify...',
        data: caseWorkers
      });
      if (that.model.get('fao').length) {
        that.ui.faoSelect.select2('val', that.model.get('fao'));
      }
      if (clients) {
        $faoClientsSelect.select2({
          multiple: true,
          allowClear: true,
          placeholder: 'Select Clients to notify...',
          data: clients
        });
        if (that.model.get('fao_clients').length) {
          that.ui.faoClientsSelect.select2('val', that.model.get('fao_clients'));
          that.ui.faoClientsSelect.select2('enable', false);
        }
      }
    });
  },
  checkMessageHeight: function () {
    if (this.ui.messageContent.outerHeight() < this.ui.messageContent[0].scrollHeight) {
      this.$el.addClass('has-hidden-text');
    }
  },
  onShow: function () {
    this.checkMessageHeight();
  },
  onRender: function() {
    var that = this;
    var caseId = this.model.get('casefile');
    this.$el.toggleClass('message--edit', !!this.options.editMode);
    if (this.options.editMode) {
      this.ui.editMessageContent.summernote({
        height: 200,
        toolbar: [
          // [groupName, [list of button]]
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['paragraph', 'ul', 'ol']],
          ['insert', ['link']],
          ['codeview', ['codeview']],
          ['undo', ['undo', 'redo']]
        ]
      });
      this.ui.editMessageContent.summernote('code', this.model.get('content').replace(/(?:\r\n|\r|\n)/g, '<br/>'));

      if (!this.model.get('is_published')) {
        // expand attachments so they can be edited
        if (this.model.get('attachments').length) {
          this.onClickShowAttachments();
        }

        this.setupSelect2();

        //configure dropzone
        this.dropzone = new Dropzone(this.ui.attachBtn[0], {
          url: '/api/cases/' + this.model.get('casefile') + '/files/',
          headers: {'X-CSRFToken': TCAS.csrfToken},
          previewsContainer: this.ui.dropzonePreviews[0],
          previewTemplate: templateDropzoneFile(),
          clickable: this.ui.attachBtn[0],
          maxFilesize: 500,
          maxFiles: 10,
          parallelUploads: 10,
          acceptedFiles: 'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.eml,.msg,.ged',
          autoProcessQueue: false,
          accept: function (file, done) {
            var alphaNumericPattern = new RegExp(
              '^[a-zA-Z0-9\'_()&+£%., -]+\.[a-zA-Z0-9]{3,4}$'
            );
            // check for only alphanumeric characters
            if (alphaNumericPattern.test(file.name)) {
              done();
            } else {
              done('This file has unexpected characters, ' +
                'file names must use the correct file naming structure and ' +
                'only contain characters consisting of: ' +
                'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
                'apostrophes, commas or spaces.');
            }
          }
        });
        this.attachmentsToAdd = [];
        this.dropzone.on('success', function (file, response) {
          that.attachmentsToAdd.push(response.id);
          that.$(file.previewElement).find('.progress-bar')
            .addClass('progress-bar-success');
        });
        this.dropzone.on('queuecomplete', function () {
          that.trigger('attachment:success', that.attachmentsToAdd);
          that.getOption('casefile').fetchFiles();
        });
        this.dropzone.on('error', function (file) {
          that.$(file.previewElement).find('button').removeClass('hide');
          that.trigger('attachment:error');
        });
        this.dropzone.on('sending', function (file, xhr, formData) {
          formData.append('is_message_file', true);
          formData.append('casefile', caseId);
          if (that.model.get('fao_clients').length) {
            that.model.get('fao_clients').forEach(function (clientId) {
              formData.append('clients_allowed', clientId);
            });
          }

          // Show the total progress bar when upload starts
          that.$(file.previewElement).find('.progress').removeClass('hide');
        });
      }
    }

    this.$('.label-tooltip,.preview-email,.remove').tooltip({
      container: 'body',
      trigger : 'hover'
    });

    // hide anyone in the To: field if they are the author
    this.$('.message-to [data-contact-id="' + this.model.get('author') + '"]')
      .hide();

    this.$('[title]').tooltip({
      placement: 'left',
      container: this.el,
      trigger: 'hover'
    });
  },
  serializeData: function() {
    var that = this;
    var data = this.model.toJSON();
    data.editMode = this.options.editMode || false;
    data.canEdit = data.owner === TCAS.session.get('id') || TCAS.session.isAdmin();
    data.isTimeTrack = data.type === Message.TYPES.TIMETRACK;
    data.isClient = this.options.isClient;
    if (this.getOption('lastViewed')) {
      if (new Date(data.created) > this.getOption('lastViewed') && data.owner !== TCAS.session.get('id')) {
        data.isNew = true;
      }
    }

    if (this.getOption('clients') && this.model.get('fao_clients').length > 0) {
      var clients = this.getOption('clients');
      data.fao_clients_objects = [];
      this.model.get('fao_clients').forEach(function(clientId) {
        var client = clients.find(function(client) {
          return client.get('id') === clientId;
        });
        if (client) {
          data.fao_clients_objects.push(client.toJSON());
        }
      });
    }

    return data;
  }
});

module.exports = MessageView;