'use strict';
var Correspondence = require('./correspondence');
var BaseCollection = require('../base-collection');

var Correspondences = BaseCollection.extend({
  urlRoot: '/api/correspondence/',
  model: Correspondence,
  comparator: function(correspondence){
    if (correspondence.has('response_to_correspondence')) {
      var relatedId = correspondence.get('response_to_correspondence');
      var relatedCorrespondence = this.get(relatedId);
      var relatedComparator = this.comparator(relatedCorrespondence);
      return relatedComparator + correspondence.get('id');
    } else {
      return (correspondence.get('id') * 1000000000 +
        new Date(correspondence.get('date')).getTime()/1000) * -1;
    }
  }
});

module.exports = Correspondences;