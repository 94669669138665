// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<br/><small>(Relates to "
    + container.escapeExpression(((helper = (helper = helpers.response_to_correspondence || (depth0 != null ? depth0.response_to_correspondence : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"response_to_correspondence","hash":{},"data":data}) : helper)))
    + ")</small>";
},"3":function(container,depth0,helpers,partials,data) {
    return "Incoming";
},"5":function(container,depth0,helpers,partials,data) {
    return "Outgoing";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<a "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.evidence : depth0)) != null ? stack1.file_download : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"file-link "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.evidence : depth0)) != null ? stack1.file_download : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.evidence : depth0)) != null ? stack1.original_file_name : stack1), depth0))
    + "</a>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.evidence : depth0)) != null ? stack1.file_download : stack1), depth0))
    + "\"";
},"10":function(container,depth0,helpers,partials,data) {
    return "downloadable";
},"12":function(container,depth0,helpers,partials,data) {
    return "None";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td class=\"col-xs-1\">"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.response_to_correspondence : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td class=\"col-xs-1\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_incoming : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td class=\"col-xs-1\">"
    + alias4((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td class=\"col-xs-1\">"
    + alias4((helpers.prospectFullName || (depth0 && depth0.prospectFullName) || alias2).call(alias1,(depth0 != null ? depth0.case_prospect : depth0),{"name":"prospectFullName","hash":{},"data":data}))
    + "</td>\n<td class=\"col-xs-3\">"
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</td>\n<td class=\"col-xs-2\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.evidence : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td class=\"col-xs-2\"><button type=\"button\" class=\"btn btn-xs btn-default edit\">Edit</button> <button type=\"button\" class=\"btn btn-xs btn-danger delete\"><i class=\"fa fa-trash-o\"></i> Delete</button></td>";
},"useData":true});
