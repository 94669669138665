'use strict';
var template = require('../../templates/users/reset-password.hbs');
var LoginBanner = require('../login-banner');

var ResetPasswordView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    form: 'form',
    password: '#user-password',
    password2: '#user-password2',
    panel: '.login-panel'
  },
  behaviors: {
    SubmitForm: {
      message: 'create casefile form',
      selector: 'form',
      submitHandler: 'onSubmit'
    },
  },
  onSubmit: function(data){
    var that = this;
    var resetPasswordId = this.getOption('resetPasswordId');
    var activationCode = this.getOption('activationCode');
    data.activation = activationCode;
    $.ajax('/api/passwordreset/'+resetPasswordId+'/set_password/', {
      contentType: 'application/json',
      data: JSON.stringify(data),
      dataType: 'json',
      type: 'POST',
      success: function(response){
        that.ui.form.addClass('hidden');
        that.ui.panel.append(
          '<div class="alert alert-success alert-dismissible" role="alert">' +
          '<button type="button" class="close" data-dismiss="alert" ' +
          'aria-label="Close"><span aria-hidden="true">&times;</span>' +
          '</button>' +
          'Password has been successfully changed, you can now ' +
          'login using your new password.' +
          '</div>' +
          '<a class="btn btn-primary" href="/login">Login</a>'
        );
      },
      error: function(jqXHR, status) {
        that.ui.form.prepend(
          '<div class="alert alert-danger alert-dismissible" role="alert">' +
          '<button type="button" class="close" data-dismiss="alert" ' +
          'aria-label="Close"><span aria-hidden="true">&times;</span>' +
          '</button>' + jqXHR.responseJSON.detail + '</div>'
        );
      }
    });
  },
  onShow: function(){
    this.ui.password.focus();
    var password = this.ui.password[0];
    var password2 = this.ui.password2[0];
    password.addEventListener('input', function (event) {
      var value = password.value;
      if (/[a-z]/.test(value) // has a lowercase letter
          && /[A-Z]/.test(value) // has an uppercase letter
          && /[!£$\?@#*]/.test(value) // contains a symbol
          && /\d/.test(value)) {
        if (value.length >= 8) {
          password.setCustomValidity('');
        } else {
          password.setCustomValidity('Password must be at least 8 characters.');
        }
      } else {
        password.setCustomValidity('Your password does not conform to the password rules.');
      }
    });
    password2.addEventListener('input', function (event) {
      if (password2.value !== password.value) {
        password2.setCustomValidity('Passwords do not match.');
      } else {
        password2.setCustomValidity('');
      }
    });

  }
});

module.exports = ResetPasswordView;
