'use strict';
var ReportItem = require('./report-item');
var BaseCollection = require('../base-collection');

var ReportList = BaseCollection.extend({
  model: ReportItem,
  comparators: {
    'key': function(reportItem) {
      var key = reportItem.get('key');
      var keyArray = key.split(' ');
      keyArray[0] = ReportItem.MONTHS[keyArray[0]];
      return new Date(keyArray[1], keyArray[0]);
    },
    '-key': function(reportItem) {
      var key = reportItem.get('key');
      var keyArray = key.split(' ');
      keyArray[0] = ReportItem.MONTHS[keyArray[0]];
      return -(new Date(keyArray[1], keyArray[0]));
    },
    'user': function(reportItemA, reportItemB) {
      var userA = TCAS.users.get(reportItemA.get('user')).getFullName();
      var userB = TCAS.users.get(reportItemB.get('user')).getFullName();
      if(userA > userB) { return -1; }
      if(userB > userA) { return 1; }
      return 0;
    },
    '-user': function(reportItemA, reportItemB) {
      var userA = TCAS.users.get(reportItemA.get('user')).getFullName();
      var userB = TCAS.users.get(reportItemB.get('user')).getFullName();
      if(userA > userB) { return 1; }
      if(userB > userA) { return -1; }
      return 0;
    },
    'team': function(reportItem) {
      return reportItem.get('team');
    },
    'allocation': function(reportItem) {
      if(reportItem.get('event_0')) {
        return reportItem.get('event_0').length;
      } else {
        return 0;
      }
    },
    '-allocation': function(reportItem) {
      if(reportItem.get('event_0')) {
        return -reportItem.get('event_0').length;
      } else {
        return 0;
      }
    },
    'signoff': function(reportItem) {
      if(reportItem.get('event_1')) {
        return reportItem.get('event_1').length;
      } else {
        return 0;
      }
    },
    '-signoff': function(reportItem) {
      if(reportItem.get('event_1')) {
        return -reportItem.get('event_1').length;
      } else {
        return 0;
      }
    },
    'valuation': function(reportItem) {
      if(reportItem.get('event_1')) {
        return -reportItem.get('event_2').length;
      } else {
        return 0;
      }
    },
    '-valuation': function(reportItem) {
      if(reportItem.get('event_1')) {
        return -reportItem.get('event_2').length;
      } else {
        return 0;
      }
    },
    'fasent': function(reportItem) {
      if(reportItem.get('event_4')) {
        return reportItem.get('event_4').length;
      } else {
        return 0;
      }
    },
    '-fasent': function(reportItem) {
      if(reportItem.get('event_4')) {
        return -reportItem.get('event_4').length;
      } else {
        return 0;
      }
    },
    'claims': function(reportItem) {
      if(reportItem.get('event_5')) {
        return reportItem.get('event_5').length;
      } else {
        return 0;
      }
    },
    '-claims': function(reportItem) {
      if(reportItem.get('event_5')) {
        return -reportItem.get('event_5').length;
      } else {
        return 0;
      }
    },
    'disclosure': function(reportItem) {
      if(reportItem.get('event_6')) {
        return reportItem.get('event_6').length;
      } else {
        return 0;
      }
    },
    '-disclosure': function(reportItem) {
      if(reportItem.get('event_6')) {
        return -reportItem.get('event_6').length;
      } else {
        return 0;
      }
    },
    'booked': function(reportItem) {
      if(reportItem.get('event_7')) {
        return reportItem.get('event_7').length;
      } else {
        return 0;
      }
    },
    '-booked': function(reportItem) {
      if(reportItem.get('event_7')) {
        return -reportItem.get('event_7').length;
      } else {
        return 0;
      }
    },
    'concluded': function(reportItem) {
      if(reportItem.get('event_8')) {
        return reportItem.get('event_8').length;
      } else {
        return 0;
      }
    },
    '-concluded': function(reportItem) {
      if(reportItem.get('event_8')) {
        return -reportItem.get('event_8').length;
      } else {
        return 0;
      }
    },
    'handback': function(reportItem) {
      if(reportItem.get('event_9')) {
        return reportItem.get('event_9').length;
      } else {
        return 0;
      }
    },
    '-handback': function(reportItem) {
      if(reportItem.get('event_9')) {
        return -reportItem.get('event_9').length;
      } else {
        return 0;
      }
    },
    'newcase': function(reportItem) {
      if(reportItem.get('event_10')) {
        return reportItem.get('event_10').length;
      } else {
        return 0;
      }
    },
    '-newcase': function(reportItem) {
      if(reportItem.get('event_10')) {
        return -reportItem.get('event_10').length;
      } else {
        return 0;
      }
    }
  },
  setSortOrder: function(sortOrder) {
    if(this.comparators.hasOwnProperty(sortOrder)) {
      this.comparator = this.comparators[sortOrder];
    }
    this.sort();
  }
});

module.exports = ReportList;