// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#\" class=\"sortable\" data-sort=\"user\">User <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#\" class=\"sortable\" data-sort=\"team\">Team <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<table id='reports-table' class=\"table table-condensed table-striped\">\n    <thead>\n        <tr>\n            <th>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.show_user : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</th>\n            <th>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.show_team : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"key\">Month/Year <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"allocation\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,0,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"signoff\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,1,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"valuation\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,2,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"fasent\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,4,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"claims\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,5,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"disclosure\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,6,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"booked\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,7,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"concluded\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,8,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"handback\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,9,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n            <th><a href=\"#\" class=\"sortable\" data-sort=\"newcase\">"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,10,{"name":"getEventName","hash":{},"data":data}))
    + " <i class=\"fa fa-caret-down\"></i><i class=\"fa fa-caret-up\"></i></a></th>\n        </tr>\n    </thead>\n    <!--<tbody class=\"history-container\"></tbody>-->\n</table>";
},"useData":true});
