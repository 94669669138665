'use strict';

var template = require('../../templates/costs/cost-row.hbs');
var templateEdit = require('../../templates/costs/cost-row-edit.hbs');
var ConfirmModalView = require('../confirm-modal');

var CostRowView = Backbone.Marionette.ItemView.extend({
  className: 'cost',
  tagName: 'tr',
  events: {
    'click @ui.editBtn': 'editCost',
    'click @ui.cancelBtn': 'cancelEdit',
    'click @ui.deleteBtn': 'deleteCost',
    'click @ui.saveBtn': 'saveCost'
  },
  ui: {
    editBtn: 'button.edit',
    deleteBtn: 'button.delete',
    cancelBtn: 'button.cancel',
    saveBtn: 'button.save',
    date: 'input[name="date"]',
    amount: 'input[name="amount"]',
    payee: 'input[name="payee"]',
    client: 'input[name="client"]',
    reason: 'textarea[name="reason"]',
    vat: 'input[name="vat"]'
  },
  getTemplate: function(){
    if (this.editMode) {
      return templateEdit;
    } else {
      return template;
    }
  },
  editCost: function(){
    this.editMode = true;
    this.$el.addClass('edit');
    this.render();
  },
  cancelEdit: function(){
    this.editMode = false;
    this.$el.removeClass('edit');
    this.render();
  },
  deleteCost: function(){
    // jshint camelcase:false
    var costType = this.model.get('type');
    if (costType === 'income') {
      costType = 'money received';
    }
    var confirmModal = new ConfirmModalView({
      body: 'Are you sure you want to delete this ' + costType + '?'
    });
    confirmModal.render();
    this.listenTo(confirmModal, 'confirmYes', function(){
      this.model.save({is_active: false}, {patch: true, wait: true});
    });
  },
  saveCost: function(){
    var that = this;
    this.ui.saveBtn.button('loading');
    var data = this.model.toJSON();
    var date = this.ui.date.val().split('/');
    data.date = date[2] + '-' + date[1] + '-' + date[0];
    data.amount = this.ui.amount.val();
    data.payee = this.ui.payee.val();
    data.client = this.ui.client.val();
    data.reason = this.ui.reason.val();
    data.vat = this.ui.vat.val();
    this.model.save(data, {success: function(){
      that.cancelEdit();
    }});
  },
  onRender: function(){
    function queryProspects(query){
      function getProspectName(prospect) {
        var name = prospect.getFullName();
        if (prospect.get('contact').get('post_code')) {
          var post = prospect.get('contact').get('post_code');
          name += ' (' + post.trim()+ ')';
        }

        return name;
      }

      var results = clients.filter(function(prospect){
        var name = getProspectName(prospect);
        return name.toLowerCase().indexOf(query.term.toLowerCase()) !== -1;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: getProspectName(prospect)
        };
      });
      query.callback({results: results, more: false});
    }

    if (this.editMode) {
      var clients = this.getOption('clients');
      this.ui.client.select2({
        query: queryProspects,
        initSelection : function (element, callback) {
          var id = element.val();
          var data = null;
          if (id) {
            data = {
              id: id,
              text: clients.get(id).getFullName()
            };
          }
          callback(data);
        }
      });

      this.ui.date.datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy'
      });
    }
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.payeePlaceholder = this.getOption('payeePlaceholder');

    return data;
  }
});

module.exports = CostRowView;