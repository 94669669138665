'use strict';
var template = require('../templates/search-view.hbs');
var Spinner = require('spin');

var SearchView = Backbone.Marionette.ItemView.extend({
  className: 'search-widget',
  template: template,
  behaviors: {
    SubmitForm: {
      submitHandler: 'search',
    },
  },
  ui: {
    submit: 'button[type="submit"]',
    searchField: 'input',
    results: '.search-results',
    mobileSearchBtn: '.search-mobile',
    form: 'form',
  },
  events: {
    'click @ui.submit': 'searchForm',
    mouseover: 'onMouseOver',
    mouseout: 'onMouseOut',
    'keyup @ui.searchField': 'onKeyPress',
    keyup: 'onKeyUp',
  },
  search: function(formData) {
    var that = this;
    //only search if query has changed
    if (this.query !== formData.query) {
      this.query = formData.query;
      if (this.query.length > 1) {
        var query = encodeURIComponent(formData.query);
        this.page = 1;
        this.ui.results.load('/search/?q=' + query, function() {
          $(this).removeClass('hide');
          that.ui.results
            .find('.search-result')
            .first()
            .addClass('highlight');
          that.ui.results.scrollTop(0);

          that.$('a[data-toggle="tab"]').off('shown.bs.tab');
          that.$('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
            // clear existing highlights and highlight first result in tab
            that.$('.search-result').removeClass('highlight');
            var tabContentId = that.$(e.target).attr('href');
            that
              .$(tabContentId)
              .find('.search-result')
              .first()
              .addClass('highlight');
          });
        });
      }
    }
  },
  onMouseOver: function() {
    //disable window scrolling
    $('html').addClass('no-scroll');
  },
  onMouseOut: function() {
    $('html').removeClass('no-scroll');
  },
  onKeyUp: function(e) {
    var $selected = this.ui.results.find('.search-result.highlight');
    var $activeTab = this.ui.results.find('.nav-tabs li.active');

    if (e.keyCode === 39) {
      // right arrow
      var $right = $activeTab.next();
      if ($right.length) {
        $right.find('a').click();
      }
    } else if (e.keyCode === 37) {
      // left arrow
      var $left = $activeTab.prev();
      if ($left.length) {
        $left.find('a').click();
      }
    }

    if ($selected.length) {
      if (e.keyCode === 40) {
        //down arrow
        if ($selected.next().length) {
          $selected = $selected.next();
          $selected
            .addClass('highlight')
            .siblings()
            .removeClass('highlight');
          if ($selected.position().top + $selected.height() > this.ui.results.height()) {
            this.ui.results.scrollTo($selected);
          }
        }
      } else if (e.keyCode === 38) {
        //up arrow
        if ($selected.prev().length) {
          $selected = $selected.prev();
          $selected
            .addClass('highlight')
            .siblings()
            .removeClass('highlight');
          if ($selected.position().top < this.ui.results.scrollTop()) {
            this.ui.results.scrollTo($selected);
          }
        }
      } else if (e.keyCode === 13) {
        //enter key, load highlighted result
        $selected.find('a').click();
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    }
  },
  onKeyPress: _.debounce(function(e) {
    var text = this.ui.searchField.val();
    if (text.length > 1) {
      this.ui.form.submit();
    } else if (!text.length) {
      this.ui.results.addClass('hide');
    }
  }, 200),
  onScroll: function() {
    var that = this;
    var isMaxScroll =
      this.ui.results.scrollTop() + this.ui.results.innerHeight() >=
      this.ui.results[0].scrollHeight;

    if (isMaxScroll) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.page += 1;
      $.get('/search?q=' + this.query + '&page=' + this.page, function(data) {
        that.$('.search-results').append(data);
        that.spinner.stop();
      }).fail(function() {
        that.page -= 1;
      });
    }
  },
  onDestroy: function() {
    $('body').off('.SearchView');
  },
  onShow: function() {
    var that = this;
    this.ui.results.height($(window).height() - 64);
    // var lazyScroll = _.bind(_.debounce(this.onScroll, 100), this);
    // $(this.ui.results).scroll(lazyScroll);
    $('body').on('click.SearchView', function(e) {
      if ($(e.target).parents('.' + that.className).length === 0) {
        that.ui.results.addClass('hide');
      }
    });
  },
});

module.exports = SearchView;
