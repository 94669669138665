'use strict';

var template =
  require('../../../templates/reporting/company-activity-milestones-by-month-table.hbs');

var CompanyActivityMilestonesByMonthTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var data = [];
    var milestones = this.getOption('milestones');
    var users = this.getOption('users');

    // for each user compute the number of research cases and ytd
    users.forEach(function(user) {
      var userId = user.get('id');
      var userMilestones = milestones.filter(function(milestone) {
        return milestone.get('user') === userId;
      });

      var milestonesByMonth = [];
      for (var i = 0; i < 12; i += 1) {
        milestonesByMonth[i] = 0;
      }

      userMilestones.forEach(function(milestone) {
        var created = new Date(milestone.get('created'));
        milestonesByMonth[created.getMonth()] += 1;
      });

      var milestonesYTD = [];

      var total = 0;
      for (i = 0; i < 12; i += 1) {
        total += milestonesByMonth[i];
        milestonesYTD[i] = total;
      }
      var rowData = {
        user: user,
        milestonesByMonth: milestonesByMonth,
        milestonesYTD: milestonesYTD
      };
      data.push(rowData);
    });

    // inform parent view that data is ready for use in the summary table,
    // trigger(event, <array of data rows>, <section>, <array of field names>)
    this.trigger(
      'dataReady',
      data
    );

    return data;
  }
});

module.exports = CompanyActivityMilestonesByMonthTableView;
