// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <td>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,">=",(depths[1] != null ? depths[1].targetBookedAssetVolume : depths[1]),{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "class=\"bg-success\"";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].targetBookedAssetVolumeYTD : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data,"blockParams":blockParams}),{"name":"with","hash":{},"fn":container.program(7, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "          <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[1][0],">=",blockParams[0][0],{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(blockParams[1][0], depth0))
    + "</td>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,">=",(depths[1] != null ? depths[1].targetBookedLAVolume : depths[1]),{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].targetBookedLAVolumeYTD : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data,"blockParams":blockParams}),{"name":"with","hash":{},"fn":container.program(7, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,depth0,">=",(depths[1] != null ? depths[1].targetAverageAssetCaseSize : depths[1]),{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,depth0,{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "</td>\n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <td "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,depth0,">=",(depths[1] != null ? depths[1].targetAverageLACaseSize : depths[1]),{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression((helpers.formatSimpleCurrency || (depth0 && depth0.formatSimpleCurrency) || alias2).call(alias1,depth0,{"name":"formatSimpleCurrency","hash":{},"data":data}))
    + "</td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"table table-condensed table-responsive\">\n  <thead>\n  <tr>\n    <th></th>\n    <th></th>\n    <th>Jan</th>\n    <th>Feb</th>\n    <th>Mar</th>\n    <th>Apr</th>\n    <th>May</th>\n    <th>Jun</th>\n    <th>Jul</th>\n    <th>Aug</th>\n    <th>Sep</th>\n    <th>Oct</th>\n    <th>Nov</th>\n    <th>Dec</th>\n  </tr>\n  </thead>\n  <tbody>\n    <tr>\n      <th>Research reports</th>\n      <th>Actual</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.researchByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.researchYTD : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <td colspan=\"14\" class=\"table-separator\"></td>\n    </tr>\n    <tr class=\"row-alternate\">\n      <th>Case entered In Claims</th>\n      <th>Actual</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.inClaimsByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr class=\"row-alternate\">\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.inClaimsYTD : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <td colspan=\"14\" class=\"table-separator\"></td>\n    </tr>\n    <tr>\n      <th>Disclosure</th>\n      <th>Actual</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.disclosureByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.disclosureYTD : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <td colspan=\"14\" class=\"table-separator\"></td>\n    </tr>\n    <tr class=\"row-alternate\">\n      <th>Booked</th>\n      <th>Actual - Asset</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.bookedAssetByMonth : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr class=\"row-alternate\">\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.bookedAssetYTD : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr class=\"row-alternate\">\n      <th></th>\n      <th>Actual - Council/BV</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.bookedLAByMonth : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr class=\"row-alternate\">\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.bookedLAYTD : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <td colspan=\"14\" class=\"table-separator\"></td>\n    </tr>\n    <tr>\n      <th>Case Concluded</th>\n      <th>Actual</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.concludedByMonth : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.concludedYTD : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <td colspan=\"14\" class=\"table-separator\"></td>\n    </tr>\n    <tr class=\"row-alternate\">\n      <th>Average Case Size - Asset</th>\n      <th>Actual</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.averageAssetCaseSizeByMonth : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr class=\"row-alternate\">\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.averageAssetCaseSizeYTD : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <td colspan=\"14\" class=\"table-separator\"></td>\n    </tr>\n    <tr>\n      <th>Average Case Size - Council & B/V</th>\n      <th>Actual</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.averageLACaseSizeByMonth : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n    <tr>\n      <th></th>\n      <th>Actual YTD</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.averageLACaseSizeYTD : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </tr>\n  </tbody>\n</table>";
},"useData":true,"useDepths":true,"useBlockParams":true});
