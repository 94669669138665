'use strict';

var Dropzone = require('dropzone');
var moment = require('moment');

var template = require('../../templates/clients/client-details.hbs');
var templateDropzoneFile = require('../../templates/cases/file-uploading.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var User = require('../../models/users/user');
var Survey = require('../../models/survey/survey');
var Questions = require('../../models/survey/questions');
var Answers = require('../../models/survey/answers');
var Event = require('../../models/cases/event');
var Events = require('../../models/cases/events');
var ClientMilestoneTypes = require('../../models/cases/clientmilestonetypes');
var CaseProspect = require('../../models/cases/caseprospect');
var CaseProspectRelations = require('../../models/cases/caseprospectrelations');
var ConfirmModal = require('../confirm-modal');
var CountriesSelectView = require('../countries-select');
var ClientMilestoneListView = require('../milestones/client-milestone-list');
var ClientPaymentListView = require('./client-payment-list');
var MessageListView = require('../messages/message-list');
var FileListView = require('../cases/file-list');
var CorrespondenceLayoutView = require('./correspondence-layout');
var RelationshipListView = require('./relationship-list');
var SurveyResponseFormView = require('../survey/survey-response-form');

var Spinner = require('spin');
var Clipboard = require('clipboard');
var alertTemplate = require('../../templates/alert.hbs');

var ClientDetailsView = Backbone.Marionette.LayoutView.extend({
  className: 'client-details',
  template: template,
  regions: {
    relationshipsRegion: '.client-relationships',
    paymentsRegion: '.client-payments',
    milestonesListRegion: '.client-milestones .panel-body',
    messagesRegion: '.client-messages .panel-body',
    correspondenceRegion: '.client-letters',
    clientFilesRegion: '.client-files-container',
    clientQuestionnaireRegion: '.client-questionnaire .panel-body',
  },
  ui: {
    filesTab: '.client-files-tab',
    questionnaireTab: '.client-questionnaire-tab',
    formAddress: 'form.client-address-form',
    formFinancials: 'form.client-financials-form',
    formNotes: 'form.client-notes-form',
    deleteBtn: 'button.delete-client',
    resendBtn: 'button.resend',
    resetPasswordBtn: 'button.reset-password',
    unlockBtn: 'button.unlock',
    saveAddressBtn: 'button.save-address',
    saveFinancialsBtn: 'button.save-financials',
    saveNotesBtn: 'button.save-notes',
    copyBtn: 'button.copy',
    convertToRole: 'a.convert-role',
    panelBody: '.panel-body',
    clientAccess: '#client-access',
    notes: '.client-notes',
    contactTitle: 'form.client-address-form input[name="title"]',
    addressAlertArea: 'form.client-address-form .alert-area',
    financialsAlertArea: 'form.client-financials-form .alert-area',
    notesAlertArea: 'form.client-notes-form .alert-area',
    correspondenceOtherCheck: '.correspondence-other-check',
    correspondenceOther: '.correspondence-other',
    isMissing: '.beneficiary-missing',
    isDeceasedAssetHolder: '.deceased-asset-holder',
    isVATApplicable: '.is-vat-applicable',
    vatRateGroup: '.vat-rate-group',
    dropzonePreviews: '.dropzone-previews',
    addFile: '.add-file',
    header: '.client-actions-bar h4',
    actionBtns: '.client-actions-bar .btn-group',
  },
  events: {
    'click @ui.deleteBtn': 'deleteClient',
    'click @ui.convertToRole': 'onClickConvert',
    'click @ui.resendBtn': 'onClickResend',
    'click @ui.resetPasswordBtn': 'onClickResetPassword',
    'click @ui.unlockBtn': 'onClickUnlock',
    'change @ui.correspondenceOtherCheck': 'onToggleOther',
    'change @ui.isVATApplicable': 'onToggleVATApplicable',
    'mouseout @ui.copyBtn': 'onMouseOutCopyButton',
    'submit @ui.formAddress': 'saveAddress',
    'submit @ui.formFinancials': 'saveFinancials',
    'submit @ui.formNotes': 'saveNotes',
    'keyup form.client-address-form input': 'onAddressChanged',
    'keyup form.client-address-form textarea': 'onAddressChanged',
    'keyup form.client-notes-form textarea': 'onNotesChanged',
    'change @ui.contactTitle': 'onAddressChanged',
    'change .correspondence-pref input[type="checkbox"]': 'onAddressChanged',
    'change form.client-address-form select': 'onAddressChanged',
    'keyup form.client-financials-form input': 'onFinancialsChanged',
    'change form.client-financials-form input[type="checkbox"]': 'onFinancialsChanged',
  },
  modelEvents: {
    'change:highlight': 'highlightChanged',
    'change:type': 'onTypeChanged',
    'change:has_access': 'onChangeHasAccess',
  },
  initialize: function(options) {
    this.listenTo(this.model.get('contact'), 'change', this.render);
    this.$el.attr('id', 'client-' + this.model.get('id'));
    this.activeTab = 'client-contact-information';
  },
  onToggleOther: function() {
    this.ui.correspondenceOther.toggleClass(
      'hide',
      !this.ui.correspondenceOtherCheck.is(':checked')
    );
  },
  onToggleVATApplicable: function() {
    this.ui.vatRateGroup.toggleClass('hidden', !this.ui.isVATApplicable.is(':checked'));
  },
  onMouseOutCopyButton: function() {
    this.ui.copyBtn.tooltip('hide');
  },
  onTypeChanged: function(model, type) {
    if (type === CaseProspect.TYPES.client) {
      this.collectMilestones();
    }
  },
  onChangeHasAccess: function(model, hasAccess) {
    if (hasAccess) {
      this.$('.label-success').html('Client <i class="fa fa-user"></i>');
    } else {
      this.$('.label-success').html('Client');
    }
  },
  onAddressChanged: _.debounce(function() {
    this.saveAddress();
  }, 2000),
  onFinancialsChanged: _.debounce(function() {
    this.saveFinancials();
  }, 2000),
  onNotesChanged: _.debounce(function() {
    this.saveNotes();
  }, 2000),
  collectMilestones: function() {
    var that = this;
    this.model.fetchMilestones().then(function() {
      // fetch milestone types based on the CategoryGroup
      var milestoneTypes = new ClientMilestoneTypes();
      milestoneTypes.fetch().then(function() {
        var isClientPaymentMade = false;

        // check which milestones already exist
        milestoneTypes.forEach(function(milestoneType) {
          milestoneType.set('checked', false);
          var milestone = that.model.get('milestones').findWhere({
            client_milestone_type: milestoneType.get('id'),
            is_active: true,
          });
          if (milestone) {
            milestoneType.set('milestone', milestone);
            milestoneType.set('checked', true);
            milestoneType.set('created', milestone.get('created'));
            milestoneType.set('is_not_applicable', milestone.get('is_not_applicable'));
            if (milestoneType.get('id') === 5) {
              // client payment made
              isClientPaymentMade = true;
            }
          }
        });

        that.milestones = milestoneTypes;

        var milestoneListView = new ClientMilestoneListView({
          collection: that.milestones,
          client: that.model,
          casefile: that.getOption('casefile'),
        });
        that.listenTo(milestoneListView, 'milestoneSaved', function(milestone) {
          that.getOption('clientMilestones').add(milestone);
          if (milestone.get('client_milestone_type') === 5 && milestone.get('is_active')) {
            // Client payment made milestone
            that.showClientQuestionnaire();
          } else {
            that.$('.questionnaire-tab').addClass('hidden');
          }
        });
        if (that.milestonesListRegion) {
          that.milestonesListRegion.show(milestoneListView);
        }

        if (isClientPaymentMade) {
          that.showClientQuestionnaire();
        }
      });
    });
  },
  showClientQuestionnaire: function() {
    var that = this;
    // ensure the questionnaire tab is shown
    this.$('.questionnaire-tab').removeClass('hidden');
    var clientId = this.model.get('id');
    var survey = new Survey();
    survey.set('id', 1);
    var questions = new Questions();
    var answers = new Answers();
    questions.setFilters({
      survey: 1,
    });
    answers.setFilters({
      question__survey: 1,
      client: clientId,
    });
    // fetch questions and answers
    $.when.apply($, [questions.fetch(), answers.fetch()]).done(function() {
      that.clientQuestionnaireRegion.show(
        new SurveyResponseFormView({
          collection: questions,
          answers: answers,
          client: that.model,
          casefileId: that.getOption('casefile').get('id'),
          surveyId: 1,
          showDateField: true,
          showFile: true,
          showEmailSurveyButton: answers.length === 0,
          showEdit: answers.length > 0,
          showThanksPageOnSubmit: false,
          editable: answers.length === 0,
        })
      );

      // if no answers yet, add a badge to the tab to highlight survey is still to be completed
      if (answers.length === 0) {
        that.ui.questionnaireTab.append(
          ' <span class="badge badge-danger" title="Questionnaire still to be completed">1</span>'
        );
        that.$('[title]').tooltip();
      }
    });
  },
  deleteClient: function() {
    var prospectType = this.model.get('type');
    var confirmDialog = new ConfirmModal({
      body: 'Are you sure you want to ' + 'remove this ' + prospectType + '?',
    });
    confirmDialog.render();
    this.listenTo(confirmDialog, 'confirmYes', function() {
      this.model.destroy();
    });
  },
  convertCaseProspect: function(newRole) {
    var name = this.model.get('contact').getFullName();
    var prospectType = this.model.get('type');
    var confirmDialog = new ConfirmModal({
      body:
        'Are you sure you want to convert the ' +
        prospectType +
        " '" +
        name +
        "' to " +
        newRole +
        '?',
    });
    confirmDialog.render();
    this.listenTo(confirmDialog, 'confirmYes', function() {
      var spinner = new Spinner().spin(this.$el[0]);
      var changes = { type: newRole };
      // check if contact is being upgraded or downgraded to cause it to switch lists
      if (prospectType === CaseProspect.TYPES.contact || newRole === CaseProspect.TYPES.contact) {
        // find highest order_position so changed item will end up at bottom of list
        var orderPosition = 0;
        this.collection.forEach(function(caseProspect) {
          if (caseProspect.get('order_position') > orderPosition) {
            orderPosition = caseProspect.get('order_position');
          }
        });
        orderPosition += 1;
        changes.order_position = orderPosition;
      }

      if (newRole !== CaseProspect.TYPES.client) {
        changes.has_access = false;
      }
      if (newRole === 'contact') {
        changes.entitlement_numerator = 0;
        changes.entitlement_denominator = 0;
      }
      this.model.save(changes, {
        success: function() {
          spinner.stop();
        },
        patch: true,
        wait: true,
      });
    });
  },
  onClickConvert: function(e) {
    var $link = this.$(e.currentTarget);
    if ($link.parent().hasClass('disabled')) {
      return false;
    }
    var roleType = $link.attr('data-role-type');
    this.convertCaseProspect(roleType);
    return false;
  },
  onClickResend: function() {
    var that = this;
    var url = this.model.url();
    url = url + '/resend_invite/';
    $.getJSON(url, function(response) {
      if (response.status === 'sent') {
        that
          .$('.client-actions-bar')
          .append(alertTemplate({ type: 'success', message: 'Invite has been sent!' }));
        // re-sync client events to update the invited date label
        that.syncClientEvents();
      } else {
        that
          .$('.client-actions-bar')
          .append(alertTemplate({ type: 'error', message: 'Unable to resend invite.' }));
      }
    });
  },
  onClickResetPassword: function() {
    var that = this;
    var url = this.model.url();
    url = url + '/reset_password/';
    $.getJSON(url, function(response) {
      if (response.status === 'sent') {
        that
          .$('.client-actions-bar')
          .append(
            alertTemplate({ type: 'success', message: 'Password reset email has been sent!' })
          );
        // re-sync client events to update the invited date label
        that.syncClientEvents();
      } else {
        that
          .$('.client-actions-bar')
          .append(alertTemplate({ type: 'error', message: 'Unable to reset password.' }));
      }
    });
  },
  onClickUnlock: function() {
    var that = this;
    var url = this.model.url();
    url = url + '/unlock/';
    $.getJSON(url, function(response) {
      if (response.status === 'sent') {
        that
          .$('.client-actions-bar')
          .append(
            alertTemplate({ type: 'success', message: 'Account has been unlocked!' })
          );
        // re-sync client events to update the invited date label
        that.syncClientEvents();
        // remove Locked labels
        $('.client-row[data-id="' + that.model.get('id') + '"]').find('.label.locked').remove();
      } else {
        that
          .$('.client-actions-bar')
          .append(alertTemplate({ type: 'error', message: 'Unable to unlock account.' }));
      }
    });
  },
  syncClientEvents: function() {
    var that = this;
    var events = new Events();
    events.setFilters({
      user: this.model.get('user').get('id'),
    });
    events.comparator = '-created';
    events.fetch().then(function() {
      // find most recent invited event
      var lastViewed = null;
      var lastInvited = null;
      events.each(function(event) {
        if (event.get('event') === Event.CASE_VIEWED) {
          lastViewed = event;
        } else if (event.get('event') === Event.CLIENT_INVITED_TO_VIEW_CASE) {
          lastInvited = event;
        }
      });
      var $meta = that.ui.header.find('.client-meta');
      $meta.empty();
      if (lastInvited) {
        $meta.append(
          '<span class="label label-default">Invited: ' +
            moment(lastInvited.get('created')).format('DD/MM/YYYY HH:mm') +
            '</span>'
        );
        if (!lastViewed) {
          that.ui.actionBtns
            .find('.delete-client')
            .after(
              '<button type="button" class="btn btn-sm btn-default resend">Resend invite</button>'
            );
        }
      }
      if (lastViewed) {
        $meta.append(
          '<span class="label label-default">Last viewed: ' +
            moment(lastViewed.get('created')).format('DD/MM/YYYY HH:mm') +
            '</span>'
        );
      }
    });
  },
  saveFinancials: function() {
    var that = this;
    if (this.ui.formFinancials.valid()) {
      var formData = this.ui.formFinancials.serializeArray();
      var $saveBtn = this.ui.saveFinancialsBtn;
      $saveBtn.button('loading');
      var spinner = new Spinner().spin(this.$el[0]);

      var data = {};
      _.map(formData, function(n, i) {
        data[n.name] = n.value;
      });

      data.is_missing = this.ui.isMissing.is(':checked');
      data.is_deceased_asset_holder = this.ui.isDeceasedAssetHolder.is(':checked');
      data.is_vat_applicable = this.ui.isVATApplicable.is(':checked');

      this.model.save(data, {
        wait: true,
        patch: true,
        success: function() {
          $saveBtn.button('reset');
          spinner.stop();
          that.ui.financialsAlertArea.empty();
          setTimeout(function() {
            that.ui.financialsAlertArea.append(
              alertTemplate({
                strong: 'Success!',
                message: 'Financial details were updated successfully.',
                type: 'success',
              })
            );
          }, 200);
        },
      });
    }

    return false;
  },
  saveNotes: function() {
    var that = this;
    var notes = this.ui.notes.val();
    var $saveBtn = this.ui.saveNotesBtn;
    $saveBtn.button('loading');
    var spinner = new Spinner().spin(this.$el[0]);

    this.model.save(
      { notes: notes },
      {
        wait: true,
        patch: true,
        success: function() {
          $saveBtn.button('reset');
          spinner.stop();
          that.ui.notesAlertArea.empty();
          setTimeout(function() {
            that.ui.notesAlertArea.append(
              alertTemplate({
                strong: 'Success!',
                message: 'Notes were updated successfully.',
                type: 'success',
              })
            );
          }, 200);
        },
      }
    );
    return false;
  },
  saveAddress: function() {
    // get current focused input so it can refocused after saving
    var focusName = this.$(':focus').attr('name');
    var formData = this.ui.formAddress.serializeArray();

    var data = {};
    _.map(formData, function(n, i) {
      data[n.name] = n.value;
    });

    if (data.date_of_birth === '') {
      data.date_of_birth = null;
    }
    if (data.date_of_death === '') {
      data.date_of_death = null;
    }

    var that = this;
    var contact = this.model.get('contact');
    var hasAccess = !!data.has_access;
    var hadAccess = this.model.get('has_access');
    var emailChanged = contact.get('email') !== data.email;
    var $saveAddressBtn = this.ui.saveAddressBtn;
    var correspondenceFields = ['is_email', 'is_telephone', 'is_letter', 'is_other', 'other'];
    var correspondencePrefs = this.model.has('correspondence_preference')
      ? this.model.get('correspondence_preference').toJSON()
      : {};
    correspondenceFields.forEach(function(field) {
      correspondencePrefs[field] = data.hasOwnProperty(field) ? data[field] : false;
      delete data[field];
    });

    if (hasAccess && !hadAccess) {
      var name = this.model.get('contact').getFullName();
      var confirmDialog = new ConfirmModal({
        body:
          'Are you sure you want to ' + "give the client '" + name + "' access to view this case?",
      });
      confirmDialog.render();
      this.listenTo(confirmDialog, 'confirmYes', function() {
        $saveAddressBtn.button('loading');
        var spinner = new Spinner().spin(that.$el[0]);
        var contactData = contact.toJSON();
        contactData = _.extend(contactData, data);
        that.model.save(
          {
            has_access: hasAccess,
            contact: contactData,
            correspondence_preference: correspondencePrefs,
          },
          {
            wait: true,
            patch: true,
            success: function(model) {
              TCAS.vent.trigger('change:contact', model.get('contact'));
              _.delay(function() {
                $saveAddressBtn.button('reset');
                spinner.stop();
                // that.render();
                that.ui.addressAlertArea.empty();
                setTimeout(function() {
                  that.ui.addressAlertArea.append(
                    alertTemplate({
                      strong: 'Success!',
                      message:
                        'Contact details were updated successfully. ' +
                        'Client has been granted access to the case details and ' +
                        'an email has been sent to them.',
                      type: 'success',
                    })
                  );
                }, 200);
                var $field = that.$('[name="' + focusName + '"]');
                $field.focus();
                if ($field.attr('type') === 'text') {
                  var fieldLength = $field.val().length;
                  $field[0].setSelectionRange(fieldLength, fieldLength);
                }
              }, 500);
            },
          }
        );
      });
    } else {
      $saveAddressBtn.button('loading');
      var spinner = new Spinner().spin(that.$el[0]);
      var contactData = contact.toJSON();
      contactData = _.extend(contactData, data);
      that.model.save(
        {
          has_access: hasAccess,
          contact: contactData,
          correspondence_preference: correspondencePrefs,
        },
        {
          wait: true,
          patch: true,
          success: function(model) {
            TCAS.vent.trigger('change:caseprospect', model);
            _.delay(function() {
              $saveAddressBtn.button('reset');
              spinner.stop();
              that.ui.addressAlertArea.empty();
              setTimeout(function() {
                if (!hasAccess && hadAccess) {
                  that.ui.addressAlertArea.append(
                    alertTemplate({
                      strong: 'Success!',
                      message:
                        'Contact details were updated successfully. ' +
                        'Client access has been revoked.',
                      type: 'success',
                    })
                  );
                } else if (hasAccess && hadAccess && emailChanged) {
                  that.ui.addressAlertArea.append(
                    alertTemplate({
                      strong: 'Success!',
                      message:
                        'Contact details were updated successfully. An ' +
                        'email providing access has been sent to the clients ' +
                        'new email.',
                      type: 'success',
                    })
                  );
                } else {
                  that.ui.addressAlertArea.append(
                    alertTemplate({
                      strong: 'Success!',
                      message: 'Contact details were updated successfully.',
                      type: 'success',
                    })
                  );
                }
              }, 200);

              var $field = that.$('[name="' + focusName + '"]');
              $field.focus();
              if ($field.attr('type') === 'text') {
                var fieldLength = $field.val().length;
                $field[0].setSelectionRange(fieldLength, fieldLength);
              }
            }, 500);
          },
        }
      );
    }

    return false;
  },
  highlightChanged: function(model, highlight) {
    var that = this;

    this.$el.toggleClass('highlight', highlight);

    if (highlight) {
      setTimeout(function() {
        that.$el.addClass('highlight-out');
        setTimeout(function() {
          that.model.set('highlight', false);
          that.$el.removeClass('highlight-out');
        }, 5000);
      }, 3000);
    }
  },
  onRender: function() {
    var that = this;
    var casefile = this.getOption('casefile');
    var clientId = this.model.get('id');
    var lastViewed = that.getOption('lastViewed');

    this.countriesRegion = new Backbone.Marionette.Region({
      el: this.$('.countries'),
    });
    this.countriesRegion.show(
      new CountriesSelectView({
        countries: TCAS.countries,
        selectedCountry: this.model.get('contact').get('country'),
        className: 'form-control input-sm',
      })
    );
    var titles = [
      { id: 'Mr', text: 'Mr' },
      { id: 'Mrs', text: 'Mrs' },
      { id: 'Ms', text: 'Ms' },
      { id: 'Miss', text: 'Miss' },
      { id: 'Master', text: 'Master' },
      { id: 'Dr', text: 'Dr' },
    ];
    this.ui.contactTitle.select2({
      data: { results: titles },
      createSearchChoice: function(term) {
        if (
          term.length >= 3 &&
          !titles.some(function(title) {
            return title.text.toLowerCase().indexOf(term.toLowerCase()) !== -1;
          })
        ) {
          return { id: term, text: term };
        }
        return null;
      },
    });
    this.ui.copyBtn.tooltip({
      title: 'Copied!',
      placement: 'bottom',
      trigger: 'manual',
    });
    if (this.clipboard) {
      this.clipboard.destroy();
    }
    this.clipboard = new Clipboard(this.ui.copyBtn[0]);
    this.clipboard.on('success', function(e) {
      that.ui.copyBtn.tooltip('show');
    });

    this.ui.formAddress.validate({
      /*jshint camelcase: false */
      ignore: '.ignore',
      rules: {
        first_name: {
          required: true,
        },
        last_name: {
          required: true,
        },
        email: {
          required: 'input.client-access:checked',
          email: true,
        },
        other: {
          required: {
            depends: function(element) {
              return $(element)
                .parents('form')
                .find('.correspondence-other-check')
                .is(':checked');
            },
          },
        },
      },
      messages: {
        email: {
          required: 'Email is required to grant client access',
        },
      },
    });
    this.ui.formFinancials.validate({
      invalidHandler: function(event, validator) {
        if (
          'entitlement_numerator' in validator.invalid ||
          'entitlement_denominator' in validator.invalid
        ) {
          that
            .$('input[name="entitlement_numerator"]')
            .parents('.form-group')
            .addClass('has-error');
        }
      },
      rules: {
        entitlement_numerator: {
          min: {
            param: 1,
            depends: function(element) {
              return (
                $(element)
                  .parents('form')
                  .find('[name="entitlement_denominator"]')
                  .val() > 0
              );
            },
          },
        },
        entitlement_denominator: {
          min: {
            param: 1,
            depends: function(element) {
              var numerator = $(element)
                .parents('form')
                .find('[name="entitlement_numerator"]')
                .val();
              return numerator > 0;
            },
          },
        },
      },
    });

    var relations = new CaseProspectRelations();
    relations.setFilters({
      person_b: this.model.get('id'),
    });
    relations.fetch().then(function() {
      that.relationshipsRegion.show(
        new RelationshipListView({
          clients: that.getOption('collection'),
          model: that.model,
          collection: relations,
        })
      );
    });

    this.paymentsRegion.show(
      new ClientPaymentListView({
        collection: this.getOption('clientPayments'),
        clients: this.getOption('collection'),
        casefile: this.getOption('casefile'),
        filterClient: this.model,
      })
    );

    var messages = casefile.get('messages');
    var messageListView = new MessageListView({
      collection: messages,
      casefile: casefile,
      clients: that.getOption('collection'),
      highlightMessage: false,
      clientId: that.model.get('id'),
      enableFAOClients: true,
      lastViewed: lastViewed,
    });
    that.messagesRegion.show(messageListView);

    // correspondence
    var correspondence = casefile.get('correspondence');
    correspondence.fetch({
      success: function() {
        //only include active correspondence
        var inactiveCorrespondence = correspondence.where({ is_active: false });
        _.each(inactiveCorrespondence, function(model) {
          if (!model.get('is_active')) {
            correspondence.remove(model);
          }
        });
      },
    });
    this.correspondenceRegion.show(
      new CorrespondenceLayoutView({
        collection: correspondence,
        casefile: casefile,
        clients: that.getOption('collection'),
        clientId: that.model.get('id'),
      })
    );

    // files
    var fileListView = new FileListView({
      collection: casefile.get('files'),
      casefile: casefile,
      clients: that.getOption('collection'),
      isClient: false,
      lastViewed: lastViewed,
      filter: function(file) {
        return file.get('clients_allowed').indexOf(clientId) !== -1;
      },
    });
    that.clientFilesRegion.show(fileListView);

    casefile.fetchFiles().then(function() {
      // work out how many new files since last visit to show as badge in files accordion header
      var newFilesCount = 0;
      casefile.get('files').each(function(file) {
        if (file.get('clients_allowed').indexOf(clientId) !== -1) {
          var created = new Date(file.get('created'));
          if (file.get('owner') !== TCAS.session.get('id') && created > lastViewed) {
            newFilesCount += 1;
          }
        }
      });
      if (newFilesCount > 0) {
        that.ui.filesTab.find('.badge').remove();
        that.ui.filesTab.append(
          ' <span class="badge badge-danger" title="New files since last viewed">' +
            newFilesCount +
            '</span>'
        );
        that.ui.filesTab.find('.badge').tooltip();
      }
    });

    // Load client events if the case prospect has a user
    if (that.model.has('user')) {
      that.syncClientEvents();
    }

    /// GET MILESTONES
    if (TCAS.session.hasPerm('add_clientmilestone')) {
      this.collectMilestones();
    }

    this.$('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
      var tabHref = that.$(e.target).attr('href');
      if (tabHref.indexOf('client-contact-information') !== -1) {
        that.activeTab = 'client-contact-information';
      } else if (tabHref.indexOf('client-financials') !== -1) {
        that.activeTab = 'client-financials';
      } else if (tabHref.indexOf('client-relationships') !== -1) {
        that.activeTab = 'client-relationships';
      } else if (tabHref.indexOf('client-files') !== -1) {
        that.activeTab = 'client-files';
      } else if (tabHref.indexOf('client-messages')) {
        that.activeTab = 'client-messages';
        messageListView.checkMessageHeight();
      } else {
        that.activeTab = 'client-milestones';
      }
    });

    if (this.dropzone) {
      this.dropzone.destroy();
    }

    this.dropzone = new Dropzone(this.el, {
      url: '/api/cases/' + casefile.get('id') + '/files/',
      headers: { 'X-CSRFToken': TCAS.csrfToken },
      previewsContainer: this.ui.dropzonePreviews[0],
      previewTemplate: templateDropzoneFile(),
      maxFilesize: 500,
      acceptedFiles:
        'image/*,application/pdf,text/plain,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/x-iwork-pages-sffpages,application/vnd.ms-outlook,message/rfc822,.eml,.msg,.ged',
      clickable: this.ui.addFile[0],
      accept: function(file, done) {
        var alphaNumericPattern = new RegExp("^[a-zA-Z0-9'_()&+£%., -]+.[a-zA-Z0-9]{3,4}$");
        // check for only alphanumeric characters
        if (alphaNumericPattern.test(file.name)) {
          done();
        } else {
          done(
            'This file has unexpected characters, ' +
              'file names must use the correct file naming structure and ' +
              'only contain characters consisting of: ' +
              'A-Z, a-z, 0-9, -, _, ( ), £, %, +, &, fullstops, ' +
              'apostrophes, commas or spaces.'
          );
        }
      },
    });
    this.dropzone.on('success', function(file) {
      casefile
        .get('files')
        .fetch()
        .then(function() {
          that.$(file.previewElement).remove();
          that.clientFilesRegion.currentView.render();
        });
    });
    this.dropzone.on('error', function(file) {
      that
        .$(file.previewElement)
        .find('button')
        .removeClass('hide');
    });
    this.dropzone.on('uploadprogress', function(file, progress) {
      console.log('file upload progress: ' + progress);
    });
    this.dropzone.on('sending', function(file, xhr, formData) {
      // Show the total progress bar when upload starts
      that
        .$(file.previewElement)
        .find('.progress')
        .removeClass('hide');
      formData.append('is_casefile_direct_upload', 1);
      formData.append('clients_allowed', clientId);
    });
    this.dropzone.on('dragleave', function() {
      that.ui.addFile.removeClass('list-group-item-success');
    });
    this.dropzone.on('drop', function() {
      that.ui.addFile.removeClass('list-group-item-success');
    });

    this.$('[title]').tooltip();
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.activeTab = this.activeTab;
    var revenueAndDistribution = this.model.calculateRevenueAndClientDistribution(
      this.getOption('casefile')
    );

    data.treethorpe_revenue = revenueAndDistribution.revenue;
    data.client_distribution = revenueAndDistribution.clientDistribution;
    data.vat = revenueAndDistribution.vat;
    data.isContact = data.type === CaseProspect.TYPES.contact;
    data.isClient = data.type === CaseProspect.TYPES.client;
    data.isAccounts = data.type === CaseProspect.TYPES.accounts;

    // work out number of unseen messages from this client to display in badge
    var lastViewed = this.getOption('lastViewed');
    var clientContactId = this.model.get('contact').get('id');
    var newClientMessages = this.getOption('messages').filter(function(message) {
      var created = new Date(message.get('created'));
      return (
        message.get('author') === clientContactId &&
        created > lastViewed &&
        message.get('owner') !== TCAS.session.get('id')
      );
    });
    data.notificationBadgeCount = newClientMessages.length;

    data.isMobile = $(window).width() < 768;

    return data;
  },
});

module.exports = ClientDetailsView;
