// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.inClaims : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.researchReport : depth0)) != null ? stack1.created : stack1),"YYYY",{"name":"formatDate","hash":{},"data":data}));
},"5":function(container,depth0,helpers,partials,data) {
    return "1";
},"7":function(container,depth0,helpers,partials,data) {
    return "0";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td><a href=\"/cases/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"popout\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</a></td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inClaims : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.researchReport : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>1</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isResearchReportThisYear : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
