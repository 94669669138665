// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " <span class=\"badge\">Inactive</span>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge admin-badge\">Admin</span>";
},"5":function(container,depth0,helpers,partials,data) {
    return " <span class=\"label label-default\">Locked</span>";
},"7":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-xs btn-danger reset-2fa\">Reset 2FA</button>";
},"9":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-xs btn-default unlock\">Unlock</button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td><a href=\"/users/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.first_name || (depth0 != null ? depth0.first_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"first_name","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.last_name || (depth0 != null ? depth0.last_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"last_name","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_active : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.admin : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a></td>\n<td>"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_locked : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias4((helpers.getRoleName || (depth0 && depth0.getRoleName) || alias2).call(alias1,(depth0 != null ? depth0.group : depth0),{"name":"getRoleName","hash":{},"data":data}))
    + "</td>\n<td><a href=\"/users/"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.supervisor : stack1), depth0))
    + "\">"
    + alias4((helpers.userFullNameOnly || (depth0 && depth0.userFullNameOnly) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.supervisor : stack1),{"name":"userFullNameOnly","hash":{},"data":data}))
    + "</a></td>\n<td>"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_2fa_enabled : stack1),((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_secret_set : stack1),{"name":"or","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.userdetail : depth0)) != null ? stack1.is_locked : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n";
},"useData":true});
