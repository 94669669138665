'use strict';
var moment = require('moment');


var Reminder = Backbone.Model.extend({
  urlRoot: '/api/reminders/',
  parse: function(response){
    //jshint camelcase:false
    response.due_date = new Date(response.due_date);
    response.created = new Date(response.created);
    response.modified = new Date(response.modified);

    return response;
  },
  toJSON: function() {
    var data = Backbone.Model.prototype.toJSON.call(this);
    var dueDate = moment(this.get('due_date'));
    data.due_date = dueDate.format('YYYY-MM-DD');

    return data;
  }
});

Reminder.STATUSES = {
  PENDING: 0,
  COMPLETE: 1,
  DISMISSED: 2
};

module.exports = Reminder;