'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-booked-list.hbs');
var IndividualMonthlyBookedRowView = require('./individual-monthly-booked-row');
var Milestones = require('../../../models/cases/milestones');
var Util = require('../../../util');

var IndividualMonthlyBookedListView =
  Backbone.Marionette.CompositeView.extend({
    template: template,
    ui: {
      tablePad: '.table-pad',
      tableTotal: '.table-total'
    },
    initialize: function() {
      this.minRows = 0;
      this.listenTo(this.getOption('casefiles'), 'sync', this.render);
      this.listenTo(this.getOption('casefiles'), 'sync', this.calculateTotals);
      this.listenTo(this, 'render:collection', this.onRenderCollection);
    },
    collectionEvents: {
      'add': 'calculateTotals',
      'remove': 'calculateTotals'
    },
    childView: IndividualMonthlyBookedRowView,
    childViewContainer: 'tbody.table-data',
    childViewOptions: function(model) {
      var caseId = model.get('case');
      var recordsForCase = this.collection.filter(function(bookingRecord) {
        return bookingRecord.get('case') === caseId;
      });
      return {
        casefile: this.getOption('casefiles').get(caseId),
        isFirstForCase: model.get('case_element') === 1
      };
    },
    setMinRows: function(minRows) {
      if (minRows >= this.minRows) {
        this.minRows = minRows;
      }

      this.ui.tablePad.empty();
      if (this.collection.length < this.minRows) {
        for (var i = 0; i < (this.minRows - this.collection.length); i += 1) {
          this.ui.tablePad.append('<tr><td colspan="5">&nbsp;</td></tr>');
        }
      }
    },
    calculateTotals: function() {
      var totalRevenue = 0;
      var totalBookings = 0;
      this.collection.each(function(bookingRecord) {
        if (bookingRecord.get('case_element') === 1) {
          totalBookings += 1;
        }

        totalRevenue += bookingRecord.get('revenue');
      });

      // display the totals
      this.ui.tableTotal.html('<tr>' +
        '<td>Totals</td><td></td><td></td><td>' + totalBookings + '</td><td ' +
        (totalRevenue < 0 ? 'class="text-danger"' : '')+'>' +
        Util.formatCurrency(totalRevenue, true) + '</td></tr>');

      // also set min rows
      this.setMinRows(this.minRows);
    }
  }
);

module.exports = IndividualMonthlyBookedListView;
