  'use strict';
var ReportTimeTrackEditsRow = require('./timetrack-edits-row');
var template = require('../../templates/reporting/report-timetrack-edits-list.hbs');

var ReportTimeTrackEditsListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: ReportTimeTrackEditsRow,
  childViewContainer: '.table'
});

module.exports = ReportTimeTrackEditsListView;