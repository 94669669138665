'use strict';
var template = require('../../templates/users/client-list.hbs');
var UserListView = require('./user-list');
var ClientRowView = require('./client-row');

var ClientUserListView = UserListView.extend({
  template: template,
  childView: ClientRowView,
  childViewOptions: function(model) {
    // find the case prospect for the model
    var caseProspect = this.getOption('caseProspects').find(function(caseProspect) {
      return caseProspect.has('user') && caseProspect.get('user').id === model.get('id');
    });
    return {
      caseProspect: caseProspect
    };
  },
  childViewContainer: 'tbody'
});

module.exports = ClientUserListView;
