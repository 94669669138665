'use strict';

var template = require('../../../templates/reporting/commercial-tracking-granular-report.hbs');
var moment = require('moment');
var CaseFile = require('../../../models/cases/casefile');

var CommercialTrackingGranularReportView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'commercial-tracking-granular-report',
  ui: {
    form: '.category-filter',
    category: '.commercial-category',
  },
  events: {
    'submit @ui.form': 'onChangeCategory',
    'click .popout': 'onClickPopoutCase',
  },
  onClickPopoutCase: function(e) {
    var id = parseInt(this.$(e.currentTarget).attr('data-case-id'), 10);
    TCAS.commands.execute('popoutCase', id);

    return false;
  },
  initialize: function() {
    this.filterCategoryId = 0;
  },
  onChangeCategory: function() {
    var categoryId = this.ui.category.val();
    this.filterCategoryId = categoryId;
    this.render();

    return false;
  },
  serializeData: function() {
    var that = this;
    var thisYear = moment().year();
    var yearStart = this.getOption('yearStart');
    var numYears = thisYear - yearStart;
    this.years = [];
    this.yearsData = [];
    for (var i = 0; i <= numYears; i += 1) {
      this.years.push(yearStart + i);
      this.yearsData.push({
        year: yearStart + i,
        activeCasesTotal: 0,
        bookedCasesTotal: 0,
        bookedValueTotal: 0,
        bookedRevenueTotal: 0,
      });
    }
    var casesActive = [];
    var casesBooked = [];
    this.casesActive = casesActive;
    this.casesBooked = casesBooked;
    var categories = this.getOption('categories');
    var milestones = this.getOption('milestones');
    var casefiles = this.getOption('casefiles');
    var bookingRecords = this.getOption('bookingRecords');
    var forecastingRecords = this.getOption('forecastingRecords');
    var pledgingRecords = this.getOption('pledgingRecords');

    function financialsTotal(acc, record) {
      return acc + record.get('revenue');
    }

    casefiles.forEach(function(casefile) {
      var caseData = casefile.toJSON();
      var caseId = casefile.get('id');
      var categoryId = casefile.get('category');
      if (that.filterCategoryId && categoryId != that.filterCategoryId) {
        return;
      }

      var filteredBookingRecords = bookingRecords.where({
        case: caseId,
      });
      var filteredForecastingRecords = forecastingRecords.where({
        case: caseId,
      });
      var filteredPledgingRecords = pledgingRecords.where({
        case: caseId,
      });

      var isBooked =
        casefile.get('case_booked') &&
        filteredForecastingRecords.length === 0 &&
        filteredPledgingRecords.length === 0;

      var assetValue = casefile.get('asset').getCurrentValuation();
      caseData.value = assetValue ? assetValue.getTotal() : 0;
      caseData.fee = assetValue ? assetValue.get('commission') / 100 : 0.25;
      if (caseData.fee === 0) {
        caseData.fee = 0.25;
      }
      caseData.bookedValueTotal = filteredBookingRecords.reduce(financialsTotal, 0);
      caseData.forecastConfirmedTotal = 0;
      caseData.forecastPendingTotal = 0;
      filteredForecastingRecords.forEach(function(record) {
        if (record.get('status') === 'confirmed') {
          caseData.forecastConfirmedTotal += record.get('revenue');
        } else {
          caseData.forecastPendingTotal += record.get('revenue');
        }
      }, 0);
      caseData.forecastValueTotal = filteredForecastingRecords.reduce(financialsTotal, 0);
      caseData.pledgedValueTotal = filteredPledgingRecords.reduce(financialsTotal, 0);
      var allocation = casefile.getCurrentAllocation();
      caseData.caseManagerId = allocation ? allocation.get('case_manager') : 0;
      caseData.statusName = CaseFile.STATUS_NAMES[caseData.status];

      if (
        isBooked ||
        caseData.status === CaseFile.STATUS_IDS.CONCLUDED ||
        caseData.status === CaseFile.STATUS_IDS.ABANDONED
      ) {
        caseData.revenuePotential = 0;
        // group revenue by year starting from 2013
        var bookedByYear = [];
        var year = yearStart;
        for (var i = 0; i <= numYears; i += 1) {
          var bookingRecordsThisYear = filteredBookingRecords.filter(function(record) {
            return record.get('date').substr(0, 4) == year;
          });
          bookedByYear[i] = bookingRecordsThisYear.reduce(function(acc, record) {
            return acc + record.get('revenue');
          }, 0);
          if (bookingRecordsThisYear.length > 0) {
            that.yearsData[i].bookedCasesTotal += 1;
            that.yearsData[i].bookedRevenueTotal += bookedByYear[i];
          }

          year += 1;
        }
        caseData.bookedByYear = bookedByYear;
        // find concluded milestone if it exists for date completed column
        var concludedMilestone = milestones.find(function(milestone) {
          return milestone.get('case') === caseData.id && milestone.get('milestone_type') === 35;
        });
        caseData.concludedDate = concludedMilestone ? concludedMilestone.get('created') : null;

        casesBooked.push(caseData);
      } else {
        if (
          caseData.bookedValueTotal > 0 ||
          caseData.forecastValueTotal > 0 ||
          caseData.pledgedValueTotal > 0
        ) {
          caseData.revenuePotential =
            caseData.forecastConfirmedTotal +
            caseData.forecastPendingTotal +
            caseData.pledgedValueTotal;
        } else {
          caseData.revenuePotential = caseData.value * caseData.fee;
        }
        // get the year allocated
        var year = moment(casefile.get('created')).year();
        var yearOffset = year - yearStart;
        if (yearOffset >= 0) {
          that.yearsData[yearOffset].activeCasesTotal += 1;
        }

        casesActive.push(caseData);
      }
    });

    var data = {
      categories: categories,
      casesActive: this.casesActive,
      casesBooked: this.casesBooked,
      years: this.years,
      yearsData: this.yearsData,
      casesActiveTotalRevenue: 0,
      casesActiveTotalValue: 0,
      casesActiveTotalBooked: 0,
      casesActiveTotalForecastConfirmed: 0,
      casesActiveTotalForecastPending: 0,
      casesActiveTotalPledged: 0,
      casesBookedTotalBooked: 0,
      casesBookedTotalValue: 0,
      filterCategoryId: this.filterCategoryId,
    };

    if (this.casesActive) {
      this.casesActive.forEach(function(caseData) {
        data.casesActiveTotalRevenue += caseData.revenuePotential;
        data.casesActiveTotalValue += caseData.value;
        data.casesActiveTotalBooked += caseData.bookedValueTotal;
        data.casesActiveTotalForecastConfirmed += caseData.forecastConfirmedTotal;
        data.casesActiveTotalForecastPending += caseData.forecastPendingTotal;
        data.casesActiveTotalPledged += caseData.pledgedValueTotal;
      });
    }
    if (this.casesBooked) {
      this.casesBooked.forEach(function(caseData) {
        data.casesBookedTotalBooked += caseData.bookedValueTotal;
        data.casesBookedTotalValue += caseData.value;
      });
    }

    return data;
  },
});

module.exports = CommercialTrackingGranularReportView;
