'use strict';
var template = require('../../templates/reporting/report-authenticationlog.hbs');
var AuthenticationLog = require('../../models/users/authenticationlog');
var AuthenticationLogListView = require('./report-authenticationlog-list');
var Spinner = require('spin');

var ReportAuthenticationLogView = Backbone.Marionette.LayoutView.extend({
  template: template,
  ui: {
    start: '#start',
    end: '#end',
    submit: '#submit'
  },
  events: {
    'click @ui.submit': 'filterLog'
  },
  regions: {
    reportArea: '#reporting-area'
  },
  onRender: function () {
    this.ui.start.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true
    });

    this.ui.end.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true
    });
  },
  filterLog: function() {
    // check for valid sensitive data token,
    // only allow CSV export if in sensitive data session
    var sdToken = TCAS.reqres.request('sensitiveDataToken');
    if (sdToken) {
      this.doFilterLog();
    } else {
      TCAS.commands.execute('enableSensitiveData');
    }

    return false;
  },
  doFilterLog: function() {
    var that = this;
    var start = this.ui.start.val();
    var end = this.ui.end.val();
    if (end) {
      end += ' 23:59:59.0';
    }
    var url = '/api/authenticationlogs/?page_size=100000';
    if (start) {
      url += '&created_after=' + start;
    }
    if (end) {
      url += '&created_before=' + end;
    }

    this.collection = new AuthenticationLog([], {
      urlRoot: url
    });

    this.collection.fetch().then(function(){
      // set the default ordering to most recent first
      if (!that.collection.orderBy) {
        that.collection.orderBy = '-created';
        that.collection.setSortOrder('-created');
      }

      that.reportArea.show(new AuthenticationLogListView({
        collection: that.collection
      }));
    });
    return false;
  },
  serializeData: function() {
    var data = {
      sdToken: TCAS.reqres.request('sensitiveDataToken')
    };
    return data;
  }

});

module.exports = ReportAuthenticationLogView;