'use strict';
var RequestRowView = require('./request-row');
var template = require('../../templates/cases/request-list.hbs');

var RequestsTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'table',
  className: 'table table-hover table-condensed',
  template: template,
  childView: RequestRowView
});

module.exports = RequestsTableView;