// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "  <div class=\"dropdown\">\n    <a class=\"dropdown-toggle\" href=\"#\" id=\"profile-dropdown\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.last_name : stack1), depth0))
    + "\n      <span class=\"caret\"></span>\n    </a>\n    <ul class=\"dropdown-menu dropdown-menu-right\" aria-labelledby=\"profile-dropdown\">\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias4).call(alias3,"add_sensitivedatatoken",{"name":"hasPerm","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias4).call(alias3,"add_archivedatatoken",{"name":"hasPerm","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.hasPerms || (depth0 && depth0.hasPerms) || alias4).call(alias3,{"name":"hasPerms","hash":{"perms":"[\"add_sensitivedatatoken\", \"add_archivedatatoken\"]"},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <li><a href=\"/logout\" id=\"logout-link\" class=\"logout-link\">Log Out <i class=\"fa fa-power-off\"></i></a></li>\n    </ul>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasValidSensitiveDataToken : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "          <li class=\"disabled\"><a><i class=\"fa fa-check\"></i> Sensitive data enabled</a></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "          <li><a href=\"#\" class=\"sensitive-data\">Enable sensitive data</a></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasValidArchiveDataToken : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "          <li class=\"disabled\"><a><i class=\"fa fa-check\"></i> Enable archived cases</a></li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "          <li><a href=\"#\" class=\"archive-data\">Enable archived cases</a></li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <li role=\"separator\" class=\"divider\"></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.id : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
