// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "              <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].year : depths[1]),"==",depth0,{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<form class=\"form-horizontal user-revenue-target-form\">\n  <div class=\"panel-body\">\n    <div class=\"form-group\">\n      <label for=\"year\" class=\"col-sm-2 control-label\">Year</label>\n      <div class=\"col-sm-6\">\n        <div class=\"input-200\">\n          <select name=\"year\" class=\"form-control year\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.years : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n    </div>\n    <h4>Revenue Targets</h4>\n    <div id=\"revenue-targets\">\n      <div class=\"form-group\">\n        <label for=\"minimum-revenue\" class=\"col-sm-2 control-label\">Minimum Revenue / year</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <span class=\"input-group-addon\" id=\"basic-addon1\">£</span>\n              <input type=\"number\" id=\"minimum-revenue\" name=\"minimum_revenue\" step=\"1\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.minimum_revenue || (depth0 != null ? depth0.minimum_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimum_revenue","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Minimum...\" aria-describedby=\"basic-addon1\" />\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"performance-revenue\" class=\"col-sm-2 control-label\">Performance Target / year</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <span class=\"input-group-addon\" id=\"basic-addon2\">£</span>\n              <input type=\"number\" id=\"performance-revenue\" name=\"performance_revenue\" step=\"1\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.performance_revenue || (depth0 != null ? depth0.performance_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"performance_revenue","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Performance...\" aria-describedby=\"basic-addon2\" />\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"stretch-revenue\" class=\"col-sm-2 control-label\">Stretch Target / year</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <span class=\"input-group-addon\" id=\"basic-addon3\">£</span>\n              <input type=\"number\" id=\"stretch-revenue\" name=\"stretch_revenue\" step=\"1\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.stretch_revenue || (depth0 != null ? depth0.stretch_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stretch_revenue","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Stretch...\" aria-describedby=\"basic-addon3\" />\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"panel-body\">\n    <h4>Benchmark Targets</h4>\n    <div id=\"benchmark-targets\">\n      <div class=\"form-group\">\n        <label for=\"research-reports-per-month\" class=\"col-sm-2 control-label\">Research reports / month</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-100\">\n            <input type=\"number\" id=\"research-reports-per-month\" name=\"research_reports_per_month\" step=\"1\" class=\"form-control\"\n                   value=\""
    + alias4(((helper = (helper = helpers.research_reports_per_month || (depth0 != null ? depth0.research_reports_per_month : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"research_reports_per_month","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Research reports...\" />\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"cases-booked-per-month\" class=\"col-sm-2 control-label\">Cases booked / month</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-100\">\n            <input type=\"number\" id=\"cases-booked-per-month\" name=\"cases_booked_per_month\" step=\"1\" class=\"form-control\"\n                   value=\""
    + alias4(((helper = (helper = helpers.cases_booked_per_month || (depth0 != null ? depth0.cases_booked_per_month : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cases_booked_per_month","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Cases booked...\" />\n          </div>\n          <input type=\"hidden\" name=\"user\" value=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" />\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"asset-case-conversion-rate\" class=\"col-sm-2 control-label\">Asset case conversion percentage / month</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <input type=\"number\" id=\"asset-case-conversion-rate\" name=\"asset_case_conversion_rate\" step=\"1\"\n                     class=\"form-control\" value=\""
    + alias4(((helper = (helper = helpers.asset_case_conversion_rate || (depth0 != null ? depth0.asset_case_conversion_rate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"asset_case_conversion_rate","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Percentage...\" />\n              <span class=\"input-group-addon\">%</span>\n            </div>\n          </div>\n          <p class=\"help-block\">Research to In Claims conversion rate target for Asset cases for the month.</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label for=\"la-case-conversion-rate\" class=\"col-sm-2 control-label\">Council & B/V case conversion percentage / month</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-200\">\n            <div class=\"input-group\">\n              <input type=\"number\" id=\"la-case-conversion-rate\" name=\"la_case_conversion_rate\" step=\"1\" class=\"form-control\"\n                     value=\""
    + alias4(((helper = (helper = helpers.la_case_conversion_rate || (depth0 != null ? depth0.la_case_conversion_rate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"la_case_conversion_rate","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Percentage...\" />\n              <span class=\"input-group-addon\">%</span>\n            </div>\n          </div>\n          <p class=\"help-block\">Research to In Claims conversion rate target for Council & B/V cases for the month.</p>\n        </div>\n      </div>\n\n      <div class=\"form-group\">\n        <div class=\"alerts-area\"></div>\n        <div class=\"col-sm-2 col-sm-offset-2\">\n          <button class=\"btn btn-success save-targets\" type=\"submit\">Save</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</form>\n\n<div class=\"panel-group\" id=\"accordion\" role=\"tablist\" aria-multiselectable=\"true\">\n  <div class=\"panel panel-default\">\n    <div class=\"panel-heading\" role=\"tab\" id=\"target-list-heading\">\n      <h4 class=\"panel-title\">\n        <a class=\"collapsed\" role=\"button\" data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse-target-list\" aria-expanded=\"false\" aria-controls=\"collapseOne\">\n          All user targets\n        </a>\n      </h4>\n    </div>\n    <div id=\"collapse-target-list\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"target-list-heading\">\n      <div class=\"panel-body user-target-list\">\n        target list contents\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true,"useDepths":true});
