'use strict';


var Cost = Backbone.Model.extend({
  urlRoot: '/api/costs/'
});

Cost.TYPES = {
  cost: 'cost',
  income: 'income',
  disbursement: 'disbursement'
};

module.exports = Cost;