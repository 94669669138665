'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-research-row.hbs');

var IndividualMonthlyResearchRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click .popout': 'onClickPopoutCase'
  },
  onClickPopoutCase: function() {
    TCAS.commands.execute('popoutCase', this.model);

    return false;
  },
  serializeData: function() {
    var data = this.model.toJSON();
    // find the research report (6) milestones and fa sent (7) milestones
    var milestones = this.getOption('milestones');
    if (milestones.length) {
      var researchReport = milestones.where({ milestone_type: 6 })[0];
      var faSent = milestones.where({ milestone_type: 7 })[0];

      data.researchReport = researchReport.toJSON();
      if (faSent) {
        data.faSent = faSent.toJSON();
      }
    }

    return data;
  }
});

module.exports = IndividualMonthlyResearchRowView;
