// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-warning alert-dismissible\" role=\"alert\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\"><i class=\"fa fa-close\"></i></span></button>\n  <strong>Warning!</strong> You do not have Archived Cases session enabled, so reports will not contain any data\n  from archived cases.\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isArchiveDataSession : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div>\n  <form class=\"form-inline\">\n    <div class=\"form-group\">\n      <label for=\"individual-yearly-report-year\">Year</label>\n      <input id=\"individual-yearly-report-year\" class=\"form-control year\" type=\"number\" name=\"year\" placeholder=\"YYYY\" />\n    </div>\n\n    <div class=\"form-group\">\n      <label class=\"case-manager-label\">Case manager</label>\n      <div class=\"form-group casemanager-select-container\"></div>\n      <button type=\"submit\" class=\"btn btn-success btn-sm submit-btn\" id=\"individual-yearly-report-submit\" disabled=\"disabled\"\n        data-loading-text=\"Please wait...\">\n        Generate Report\n      </button>\n    </div>\n  </form>\n</div>\n\n<div id=\"reporting-area\" class=\"reporting-area hidden\">\n  <h2>Case Activity Log for <span class=\"user-full-name\"></span></h2>\n  <div class=\"report-table-container report-table-container-summary\">\n    <h3 class=\"summary-background\">Revenue</h3>\n    <div class=\"revenue-table-container\"></div>\n  </div>\n  <div class=\"report-table-container\">\n    <h3 class=\"research-background\">RESEARCH REPORT</h3>\n    <div class=\"research-table-container\"></div>\n  </div>\n  <div class=\"report-table-container\">\n    <h3 class=\"in-claims-background\">CASES ENTERED IN CLAIMS</h3>\n    <div class=\"in-claims-table-container\"></div>\n  </div>\n  <div class=\"report-table-container\">\n    <h3 class=\"disclosure-background\">DISCLOSURE</h3>\n    <div class=\"disclosure-table-container\"></div>\n  </div>\n  <div class=\"report-table-container\">\n    <h3 class=\"booked-background\">BOOKED</h3>\n    <div class=\"booked-table-container\"></div>\n  </div>\n  <div class=\"report-table-container\">\n    <h3 class=\"concluded-background\">CONCLUDED</h3>\n    <div class=\"concluded-table-container\"></div>\n  </div>\n</div>";
},"useData":true});
