'use strict';

var User = require('../../models/users/user');
var MessageView = require('./message');
var MessageCreateView = require('./message-create');
var template = require('../../templates/messages/message-list.hbs');

var MessageListView = Backbone.Marionette.CompositeView.extend({
  className: 'message-list',
  template: template,
  childView: MessageView,
  childViewContainer: '.messages',
  childViewOptions: function() {
    return {
      isClient: TCAS.session.hasAnyGroups([User.GROUPS.CLIENT, User.GROUPS.ACCOUNTS]),
      clients: this.getOption('clients'),
      casefile: this.getOption('casefile'),
      lastViewed: this.getOption('lastViewed')
    };
  },
  events: {
    'click .messages .message': 'messageClicked'
  },
  ui: {
    'messages': '.messages'
  },
  collectionEvents: {
    'change:selected': 'messageSelectionChange',
    'requestScroll': 'onRequestScroll'
  },
  initialize: function(options) {
    if (options.clients) {
      this.listenTo(options.clients, 'sync', this.render);
    }
  },
  messageSelectionChange: function(message, selected){
    if (selected) {
      this.collection.each(function(m){
        if (m !== message) {
          m.set('selected', false);
        }
      });
    }
  },
  addChild: function(child) {
    if (this.getOption('filterFunction')) {
      var filter = this.getOption('filterFunction');
      if (!filter(child)) {
        return;
      }
    } else {
      var clientId = this.getOption('clientId');
      if (clientId && child.get('fao_clients').indexOf(clientId) === -1) {
        return;
      }
    }

    Backbone.Marionette.CollectionView.prototype.addChild
      .apply(this, arguments);
  },
  checkMessageHeight: function () {
    this.children.each(function (childView) {
      childView.checkMessageHeight();
    });
  },
  onRequestScroll: function(message){
    this.ui.messages.scrollTo('#message-'+message.get('id'), 800);
  },
  onRender: function(){
    var that = this;
    this.createRegion = new Backbone.Marionette.Region({
      el: this.$('.message-create')
    });
    var createOptions = {
      casefile: this.getOption('casefile'),
      collection: this.collection,
      enableFAOClients: this.getOption('enableFAOClients')
    };
    if (this.getOption('clientId')) {
      createOptions.clientId = this.getOption('clientId');
    }
    if (this.getOption('clients')) {
      createOptions.clients = this.getOption('clients');
    }
    this.createView = new MessageCreateView(createOptions);
    this.createRegion.show(this.createView);

    var highlightMessageId = this.getOption('highlightMessage');
    if (highlightMessageId) {
      var highlightedMessage = this.collection.get(highlightMessageId);
      if (highlightedMessage && this.getOption('filterFunction') && this.getOption('filterFunction')(highlightedMessage)) {
        highlightedMessage.set('highlight', true);
        _.defer(function(){
          that.onRequestScroll(highlightedMessage);
        });
      }
    }
  }
});

module.exports = MessageListView;