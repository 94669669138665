'use strict';
var template = require('../../templates/history/casefile-history-row.hbs');
var Event = require('../../models/cases/event');
var User = require('../../models/users/user');

var HistoryRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  initialize: function() {
    this.edit = false;
  },
  ui: {
    'created': '#createdDate',
    'user': '#user',
    'details': '#details',
    'event': '#event',
    'save': '#save',
    'remove': '#remove',
    'edit': '#edit'
  },
  events: {
    'click @ui.save': 'saveEntry',
    'click @ui.remove': 'removeEntry',
    'click @ui.edit': 'editEntry'
  },
  saveEntry: function() {
    var that = this;
    var created = this.ui.created.datepicker('getDate').toISOString();
    var user = this.ui.user.select2('val');
    var details = this.ui.details.val();
    var event = this.ui.event.val();

    this.model.set({
      created: created,
      user: user,
      details: details,
      event: event
    });
    this.model.save({}, {
      success: function() {
        that.trigger('child:saved');
      },
      error: function() {
        that.trigger('child:error');
      }
    });
  },
  removeEntry: function() {
    var that = this;
    this.model.destroy({
      success: function() {
        that.trigger('child:removed');
      },
      error: function() {
        that.trigger('child:error');
      }
    });
  },
  editEntry: function() {
    this.edit = !this.edit;
    this.render();
  },
  serializeData: function() {
    var data = this.model.toJSON();
    if(data.details) {
      data.details = Event.eventParser(data.details);
    }
    data.eventTypes = this.getTypes(Event.EVENTS);
    data.edit = this.edit;
    return data;
  },
  onRender: function() {
    var that = this;
    this.ui.event.val(this.model.get('event'));

    this.ui.created.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true
    });

    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      return s;
    }

    var caseWorkersUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.user.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseWorkersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
      initSelection: function(element, callback) {

        var user = new User({
          id: that.model.get('user')
        });
        user.fetch().then(function() {
          var data = {id: element.val(), text: user.toJSON()};
          callback(user.toJSON());
        });

      }
    });

  },
  getTypes: function(eventTypes) {
    var events = [];
    for (var key in eventTypes) {
      if(eventTypes.hasOwnProperty(key)) {
        events.push({id: key, name: eventTypes[key]});
      }
    }
    return events;
  }
});

module.exports = HistoryRowView;