'use strict';
var ClientMilestoneType = require('./milestonetype');
var BaseCollection = require('../base-collection');

var ClientMilestoneTypes = BaseCollection.extend({
  urlRoot: '/api/clientmilestonetypes/?ordering',
  model: ClientMilestoneType
});

module.exports = ClientMilestoneTypes;
