'use strict';
var MainBodyLayout = require('../views/main-body-layout');
var CreateUserView = require('../views/users/user-create');
var UserListView = require('../views/users/user-list');
var ClientUserListView = require('../views/users/client-list');
var ResetPasswordView = require('../views/users/reset-password');
var Reset2faView = require('../views/users/reset-2fa');
var TeamMain = require('../views/teams/team-main');
var TeamView = require('../views/teams/team-details');
var CompanyTargetDetailsView = require('../views/teams/company-target-details');
var PermissionsView = require('../views/permissions/permissions');
var PasswordReset = require('../models/users/passwordreset');
var TwoFactorReset = require('../models/users/twofactorreset');
var User = require('../models/users/user');
var Users = require('../models/users/users');
var CaseProspects = require('../models/cases/caseprospects');
var Spinner = require('spin');
var Team = require('../models/users/team');
var CaseloadCollection = require('../models/cases/caseload-collection');
var UserTargets = require('../models/reporting/usertargets');
var CompanyTargets = require('../models/reporting/companytargets');

var UsersController = Backbone.Marionette.Controller.extend({
  initialize: function(options) {
    this.app = options.app;
  },
  appRoutes: {
    'users/new': 'createUser',
    'users/(:id)(/)': 'editUser',
    'users/reset_password/(:resetId)/(:activation)': 'resetPassword',
    'users/reset_2fa/(:resetId)/(:activation)': 'reset2fa',
    users: 'listUsers',
    clients: 'listClients',
    team: 'listTeams',
    'team/(:id)': 'showTeam',
    'company/targets': 'companyTargets',
    permissions: 'permissions',
  },
  createUser: function() {
    if (!TCAS.session.hasPerm('add_user')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'Create User' });
    this.app.mainBody.show(mainBodyLayout);

    var user = new User({ is_active: true });
    var userCreateView = new CreateUserView({
      user: user,
      userTargets: new UserTargets(),
      isNewUser: true,
    });
    mainBodyLayout.content.show(userCreateView);
    document.title = 'Create User';
  },
  editUser: function(userId) {
    userId = parseInt(userId, 10);

    if (!TCAS.session.hasPerm('change_user')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'Edit User' });
    this.app.mainBody.show(mainBodyLayout);

    var caseload = new CaseloadCollection([], {
      urlRoot: '/api/caseload/?user=' + userId,
    });

    var userTargets = new UserTargets([], {
      comparator: function(a, b) {
        var aUserId = a.get('user');
        var bUserId = b.get('user');
        var aUser = TCAS.users.findWhere({ id: aUserId });
        var bUser = TCAS.users.findWhere({ id: bUserId });

        if (aUser && bUser) {
          return aUser.getFullName().localeCompare(bUser.getFullName());
        }
        return 0;
      },
    });
    $.when(TCAS.users.fetch(), caseload.fetch()).done(function() {
      userTargets.fetch().then(function() {
        var user = TCAS.users.findWhere({ id: userId });
        var userCreateView = new CreateUserView({
          user: user,
          users: TCAS.users,
          caseload: caseload,
          userTargets: userTargets,
          isNewUser: false,
        });
        mainBodyLayout.content.show(userCreateView);
        mainBodyLayout.setTitle('Edit User: ' + user.getFullName());
        document.title = 'Edit User';
      });
    });
  },
  resetPassword: function(resetPasswordId, activationCode) {
    var that = this;
    var resetPassword = new PasswordReset({ id: resetPasswordId });
    resetPassword.fetch({
      success: function(resetPassword) {
        var resetPasswordView = new ResetPasswordView({
          model: resetPassword,
          resetPasswordId: resetPasswordId,
          activationCode: activationCode,
        });
        that.app.mainBody.show(resetPasswordView);
      },
    });
    document.title = 'Reset Password';
  },
  reset2fa: function(resetId, activationCode) {
    var that = this;
    var reset = new TwoFactorReset({ id: resetId });
    reset.fetch({
      success: function(reset) {
        var reset2faView = new Reset2faView({
          model: reset,
          resetId: resetId,
          activationCode: activationCode,
        });
        that.app.mainBody.show(reset2faView);
      },
    });
    document.title = 'Reset Two Factor Authentication';
  },
  listClients: function() {
    if (!TCAS.session.hasPerm('view_users')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'Clients' });
    this.app.mainBody.show(mainBodyLayout);
    var spinner = new Spinner().spin(mainBodyLayout.$el[0]);
    var collection = new Users();
    var caseProspects = new CaseProspects();
    caseProspects.urlRoot = '/api/caseprospects/?page_size=10000&type=client&has_access=1';
    collection.urlRoot = '/api/users/?page_size=10000&groups=';
    collection.urlRoot +=
      User.GROUPS.CLIENT + '&groups=' + User.GROUPS.ACCOUNTS + '&client_has_access=True';
    collection.orderBy = ['-created'];
    $.when.apply($, [caseProspects.fetch(), collection.fetch()]).done(function() {
      spinner.stop();
      var clientUserListView = new ClientUserListView({
        collection: collection,
        caseProspects: caseProspects,
      });
      mainBodyLayout.content.show(clientUserListView);
    });
    document.title = 'List Clients';
  },
  listUsers: function() {
    if (!TCAS.session.hasPerm('view_users')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'Users' });
    this.app.mainBody.show(mainBodyLayout);
    var spinner = new Spinner().spin(mainBodyLayout.$el[0]);

    TCAS.users.fetch({
      success: function() {
        spinner.stop();
        var userListView = new UserListView({
          collection: TCAS.users,
        });
        mainBodyLayout.content.show(userListView);
      },
    });
    document.title = 'List Users';
  },
  listTeams: function() {
    if (!TCAS.session.hasPerm('view_team')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'List Teams' });
    this.app.mainBody.show(mainBodyLayout);
    var teamMain = new TeamMain();
    mainBodyLayout.content.show(teamMain);
    document.title = 'Teams';
  },
  showTeam: function(teamId) {
    if (!TCAS.session.hasPerm('change_team')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'Team Details' });
    this.app.mainBody.show(mainBodyLayout);

    var team = new Team({ id: teamId });
    team.fetch().then(function() {
      var teamView = new TeamView({
        team: team,
      });
      mainBodyLayout.content.show(teamView);
      document.title = 'Team: ' + team.get('location');
    });
  },
  companyTargets: function() {
    if (!TCAS.session.hasPerm('add_companytarget')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'Company Targets' });
    this.app.mainBody.show(mainBodyLayout);

    var companyTargets = new CompanyTargets();
    companyTargets.fetch().then(function() {
      var companyTargetDetails = new CompanyTargetDetailsView({
        collection: companyTargets,
      });
      mainBodyLayout.content.show(companyTargetDetails);
      document.title = 'Company Targets';
    });
  },
  permissions: function() {
    if (!TCAS.session.hasPerm('change_permission')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var mainBodyLayout = new MainBodyLayout({ title: 'Permissions' });
    this.app.mainBody.show(mainBodyLayout);

    var permissionsView = new PermissionsView();
    mainBodyLayout.content.show(permissionsView);
    document.title = 'Permissions';
  },
});

module.exports = UsersController;
