'use strict';

var template = require('../../templates/permissions/permissions-selection.hbs');
var Permissions = require('../../models/users/permissions');
var Group = require('../../models/users/group');

var PermissionsView = Backbone.Marionette.CompositeView.extend({
  template: template,
  initialize: function(options) {

    var unwantedContentTypes = [
      'log entry', 'countries', 'content type', 'queue', 'crontab', 'interval',
      'periodic task', 'task state', 'saved group result', 'task', 'worker',
      'session', 'site', 'migration history', ''
    ];

    if(options.allPerms && options.groupPerms) {
      this.availablePerms = options.allPerms;
      this.groupPerms = options.groupPerms;

      //var filteredPerms = this.availablePerms.filter(function(permission){
      //  return !_(unwantedContentTypes).contains(
      //    permission.get('content_type'));
      //});

      var filteredPerms = this.availablePerms.filter(function(permission) {
        return !_.contains(Permissions.unwantedPerms,
            permission.get('codename'));
      });

      var filteredGroupPerms = this.groupPerms.filter(function(permission) {
        return !_.contains(Permissions.unwantedPerms,
            permission.get('codename'));
      });

      //var filteredPerms = this.availablePerms.filter(function(permission){
      //  return !_.contains(Permissions.unwantedPerms,
      //    permission.get('codename'));
      //});

      this.availablePerms = new Permissions(filteredPerms);
      this.groupPerms = new Permissions(filteredGroupPerms);
      this.availablePerms.remove(options.groupPerms.models);
    }

    if(options.groupId) {
      this.groupId = options.groupId;
    }
  },
  ui: {
    'addPerm': '#add-perm',
    'removePerm': '#remove-perm',
    'availablePerms': '#available-permissions',
    'groupPerms': '#group-permissions',
    'save': '#save'
  },
  events: {
    'click @ui.addPerm': 'addPermissions',
    'click @ui.removePerm': 'removePermissions',
    'click @ui.save': 'saveChanges'
  },
  serializeData: function() {
    var data = {};
    data.availablePermissions = this.availablePerms.toJSON();
    data.groupPermissions = this.groupPerms.toJSON();
    return data;
  },
  addPermissions: function() {
    var selectedValues = [];
    $('#available-permissions :selected').each(function() {
      selectedValues.push(parseInt($(this).val()));
    });

    var selectedModels = this.availablePerms.remove(selectedValues);
    this.groupPerms.add(selectedModels);
    this.render();
  },
  removePermissions: function() {
    var selectedValues = [];
    $('#group-permissions :selected').each(function() {
      selectedValues.push(parseInt($(this).val()));
    });

    var selectedModels = this.groupPerms.remove(selectedValues);
    this.availablePerms.add(selectedModels);
    this.render();
  },
  saveChanges: function() {
    var that = this;
    var group = new Group({id: that.groupId});
    group.fetch().then(function() {
      group.set('permissions', that.groupPerms.pluck('id'));

      group.save({}, {
        success: function() {
          TCAS.vent.trigger('permsSaved');
        },
        error: function() {
          TCAS.vent.trigger('permsError');
        }
      });
    });

  }
});

module.exports = PermissionsView;