// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td><input class=\"form-control input-sm template-name\" value=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td><input class=\"form-control input-sm template-description\" value=\""
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td><input class=\"form-control input-sm template-category\" value=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td><input class=\"form-control input-sm template-grouping\" value=\""
    + alias4(((helper = (helper = helpers.grouping || (depth0 != null ? depth0.grouping : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"grouping","hash":{},"data":data}) : helper)))
    + "\" /></td>\n<td>"
    + alias4((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias4((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.owner : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td><button class=\"btn btn-success btn-sm save-template\">Save</button></td>\n<td><button class=\"btn btn-danger btn-sm remove-template\">Remove</button></td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td><a href=\""
    + alias4(((helper = (helper = helpers.file_download || (depth0 != null ? depth0.file_download : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_download","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a></td>\n<td>"
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias4(((helper = (helper = helpers.grouping || (depth0 != null ? depth0.grouping : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"grouping","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias4((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCreate : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCreate : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCreate : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.owner : depth0),{"name":"userFullName","hash":{},"data":data}));
},"6":function(container,depth0,helpers,partials,data) {
    return "<button class=\"btn btn-success btn-sm edit-template\">Edit</button>";
},"8":function(container,depth0,helpers,partials,data) {
    return "<button class=\"btn btn-danger btn-sm remove-template\">Remove</button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.edit : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
