'use strict';
var moment = require('moment');
var Util = require('../../util');

var Event = Backbone.Model.extend({
  urlRoot: '/api/events/'
});

Event.ALLOCATION = 0;
Event.RESEARCH_SIGNED_OFF = 1;
Event.ASSET_VALUATION = 2;
Event.PERCENTAGE_FEE_CALCULATION = 3;
Event.FA_SENT = 4;
Event.IN_CLAIMS = 5;
Event.DISCLOSURE_SENT = 6;
Event.CASE_BOOKED = 7;
Event.CASE_CONCLUDED = 8;
Event.CASE_HANDBACK = 9;
Event.ADD_NEW_CASE = 10;
Event.TIMETRACK_EDIT = 11;
Event.CLIENT_FA_SENT = 12;
Event.CLIENT_PR_NOK = 13;
Event.CLIENT_FA_RECEIVED = 14;
Event.CLIENT_DISCLOSURE_SENT = 15;
Event.CLIENT_PAYMENT_MADE = 16;
Event.CLIENT_QUESTIONNAIRE_RETURNED = 17;
Event.CLIENT_EDIT = 18;
Event.BOOKING_RECORD = 19;
Event.BOOKING_RECORD_EDIT = 20;
Event.FORECASTING_RECORD = 21;
Event.FORECASTING_RECORD_EDIT = 22;
Event.PLEDGING_RECORD = 23;
Event.PLEDGING_RECORD_EDIT = 24;
Event.USER_TARGET = 25;
Event.USER_TARGET_EDIT = 26;
Event.TEAM_TARGET = 27;
Event.TEAM_TARGET_EDIT = 28;
Event.COMPANY_TARGET = 29;
Event.COMPANY_TARGET_EDIT = 30;
Event.CLIENT_ENTITLEMENT_CONFIRMED = 31;
Event.CLIENT_VERIFIED_ID_SUPPLIED = 32;
Event.CLIENT_PAYMENT_INSTRUCTIONS_RECEIVED = 33;
Event.CASE_VIEWED = 34;
Event.MESSAGE_DELETED = 35;
Event.CLIENT_INITIAL_CONTACT_MADE = 36;
Event.CONDITIONS_OF_APPROVAL_REPORT_SIGNED_OFF = 37;
Event.CLIENT_QUESTIONNAIRE_SENT = 38;
Event.CLIENT_INVITED_TO_VIEW_CASE = 39;

Event.EVENTS = {
  '0': 'Case Allocated',
  '1': 'Research Report Signed-Off',
  '2': 'Asset Valuation',
  '3': 'Percentage for Fee Calculation',
  '4': 'FA Sent',
  '5': 'In Claims',
  '6': 'Disclosure sent',
  '7': 'Case booked',
  '8': 'Case concluded',
  '9': 'Case handed back',
  '10': 'Add New Case',
  '11': 'Edit TimeTrack entry',
  '12': 'Client FA Sent',
  '13': 'Client PR NoK',
  '14': 'Client FA Received',
  '15': 'Client Disclosure Sent',
  '16': 'Client Payment Made',
  '17': 'Client Questionnaire Returned',
  '18': 'Client Edit',
  '19': 'Add Booking',
  '20': 'Edit Booking',
  '21': 'Add Forecast',
  '22': 'Edit Forecast',
  '23': 'Add Pledge',
  '24': 'Edit Pledge',
  '25': 'Add User Target',
  '26': 'Edit User Target',
  '27': 'Add Team Target',
  '28': 'Edit Team Target',
  '29': 'Add Company Target',
  '30': 'Edit Company Target',
  '31': 'Client Entitlement Confirmed',
  '32': 'Client Verified ID Supplied',
  '33': 'Client Payment Instructions Received',
  '34': 'Case Viewed',
  '35': 'Message Deleted',
  '36': 'Client Initial Contact Made',
  '37': 'Conditions of Approval Report Signed Off',
  '38': 'Client Questionnaire Sent',
  '39': 'Client Invited to View Case'
};

function formatDate(date) {
  return moment(date).format('HH:mm DD/MM/YY')
}

Event.eventParser = function(json) {
  var obj, message;
  try {
    obj = JSON.parse(json);
  } catch(err) {
    return json;
  }

  var modified = new Date(obj.modified);
  var created = new Date(obj.created);
  var owner = TCAS.users.findWhere({id: obj.owner});
  if(owner) {
    owner = owner.getFullName();
  } else {
    owner = 'Unknown User';
  }

  if (obj.actioned_by) {
    var actionedBy = TCAS.users.findWhere({id: obj.actioned_by});
    if(actionedBy) {
      actionedBy = actionedBy.getFullName();
    } else {
      actionedBy = 'Unknown User';
    }
    owner = TCAS.users.findWhere({id: obj.user});
    if (owner) {
      owner = owner.getFullName();
    } else {
      owner = 'Unknown User';
    }
    message = 'Requested by ' + owner + ' on ' +
    moment(created).format('HH:mm DD/MM/YY') + '. Actioned by ' + actionedBy +
    ' on ' + moment(created).format('HH:mm DD/MM/YY') + '.';
    return message;
  } else if(obj.case_manager) {
    var user = TCAS.users.findWhere({id: obj.case_manager});
    if (user) {
      user = user.getFullName();
    } else {
      user = 'Unknown User';
    }

    if (obj.revenue) {
      var revenue = Util.formatCurrency(obj.revenue);

      if (obj.vat) {
        var financialType = 'Booking';
      } else if (obj.status) {
        // forecast
        financialType = 'Forecast';
      } else {
        // pledge
        financialType = 'Pledge';
      }
      message = financialType + ' ' + revenue + ' on ' + moment(obj.date).format('DD/MM/YY');
    } else {
      message = user + ' was allocated the case by ' +
        owner + ' on ' + moment(modified).format('HH:mm DD/MM/YY')
        + '.';
    }

    return message;
  } else if(obj.milestone_type) {
    owner = TCAS.users.findWhere({id: obj.user});
    if (owner) {
      owner = owner.getFullName();
    } else {
      owner = 'Unknown User';
    }
    message = 'Applied as a milestone by ' + owner + ' on ' +
      moment(created).format('HH:mm DD/MM/YY') + '.';
    return message;
  } else if(obj.category) {
    message = 'Case was created/added on ' +
      moment(created).format('HH:mm DD/MM/YY') + '.';
    return message;
  } else if(obj.asset) {
    message = 'Asset valued at ' + obj.capital_value + ' entered on ' +
    moment(created).format('HH:mm DD/MM/YY');
    return message;
  } else if(obj.start) {
    message = 'Timetrack edited on ' + moment(created).format('HH:mm DD/MM/YY')
    + '. New Entry says "' + obj.note + '".';
    return message;
  } else {
    return json;
  }
};

module.exports = Event;