'use strict';

var moment = require('moment');
var template = require('../../../templates/reporting/timetrack-task-individual-pie-charts.hbs');
var TimetrackPieChartView = require('./timetrack-pie-chart');
var TimeTrack = require('../../../models/timetrack/timetrack');
var Timetracks = require('../../../models/timetrack/timetracks');

var availableColours = [
  '#137D79',
  '#595CA5',
  '#00A8B0',
  '#7A9B62',
  '#4097DB',
  '#D1517A',
  '#ADC763',
  '#94679C',
  '#F8DEAE',
  '#E8A43D'
];

var TimetrackTaskIndividualPieChartsView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: TimetrackPieChartView,
  childViewContainer: '.timetrack-activity-task-individuals',
  childViewOptions: function(user) {
    var activityType = this.getOption('activityType');
    var activityName = TimeTrack.ACTIVITIES[activityType];
    var taskMap = {};

    this.timetracks.forEach(function(entry) {
      if (entry.get('activity') === activityType && entry.get('owner') === user.get('id')) {
        var task = entry.get('task');

        if (!taskMap.hasOwnProperty(task)) {
          taskMap[task] = 0;
        }
        taskMap[task] += entry.totalMinutes();
      }
    });

    var taskOrder = TimeTrack.ACTIVITY_TASKS[activityType];
    var taskData = [];

    taskOrder.forEach(function(taskType) {
      if (taskMap.hasOwnProperty(taskType)) {
        taskData.push({
          task: taskType,
          name: TimeTrack.TASKS[taskType],
          minutes: taskMap[taskType]
        });
      }
    });

    return {
      data: taskData.map(function(task) {
        return task.minutes;
      }),
      labels: taskData.map(function(task) {
        return task.name;
      }),
      title: activityName + ' - ' + user.getFullName()
    };
  },
  filter: function(user) {
    // only show users that have timetrack entries for the activityType
    var activityType = this.getOption('activityType');

    return this.timetracks.some(function(entry) {
      return entry.get('activity') === activityType && entry.get('owner') === user.get('id');
    });
  },
  onShow: function() {
    var that = this;
    var fromDateMoment;
    var toDateMoment;
    var timetracks = new Timetracks();
    this.timetracks = timetracks;
    var activityType = this.getOption('activityType');
    var users = this.getOption('users');
    var userIds = this.getOption('userIds');
    var teamIds = this.getOption('teamIds');
    var teamAllocations = this.getOption('teamAllocations');
    var filteredUsers = users.toArray();

    /* jshint ignore:start */
    if (this.getOption('start')) {
      fromDateMoment = moment(this.getOption('start'));
    } else {
      fromDateMoment = moment().startOf('year');
    }
    if (this.getOption('end')) {
      toDateMoment = moment(this.getOption('end'));
    } else {
      toDateMoment = moment();
    }

    var filters = {
      date_range_0: fromDateMoment.format('DD/MM/YYYY'),
      date_range_1: toDateMoment.format('DD/MM/YYYY'),
      is_active: true,
      page_size: 100000
    };

    if (toDateMoment) {
      filters.date_before = toDateMoment.format('DD/MM/YYYY');
    }
    if (userIds.length) {
      filters.owner__in = userIds.join(','); // jshint ignore:line
      filteredUsers = filteredUsers.filter(function(u) {
        return userIds.indexOf(u.get('id')) !== -1;
      });
    } else if (teamIds.length) {
      filters.team__in = teamIds.join(','); // jshint ignore:line

      // filter the users only those from these teams
      filteredUsers = filteredUsers.filter(function(user) {
        var teamAllocation = teamAllocations.find(function(teamAllocation) {
          return teamAllocation.get('user') === user.get('id');
        });
        return teamAllocation && teamIds.indexOf(teamAllocation.get('team')) !== -1;
      });
    }

    timetracks.setFilters(filters);

    timetracks.fetch().then(function() {
      that.collection.add(filteredUsers);

      var taskMap = {};

      timetracks.forEach(function (entry) {
        if (entry.get('activity') === activityType) {
          var task = entry.get('task');

          if (!taskMap.hasOwnProperty(task)) {
            taskMap[task] = 0;
          }
          taskMap[task] += entry.totalMinutes();
        }
      });

      var taskOrder = TimeTrack.ACTIVITY_TASKS[activityType];
      var taskData = [];

      taskOrder.forEach(function(taskType) {
        if (taskMap.hasOwnProperty(taskType)) {
          taskData.push({
            task: taskType,
            name: TimeTrack.TASKS[taskType],
            minutes: taskMap[taskType]
          });
        }
      });

      // render pie chart of activity tasks for all users
      that.allUsersChartRegion = new Backbone.Marionette.Region({
        el: that.$('.timetrack-activity-task-all-users')
      });
      that.allUsersChartRegion.show(new TimetrackPieChartView({
        data: taskData.map(function(task) {
          return task.minutes;
        }),
        labels: taskData.map(function(task) {
          return task.name;
        }),
        title: TimeTrack.ACTIVITIES[activityType]
      }));
    });
  },
  serializeData: function() {
    return {
      activityName: TimeTrack.ACTIVITIES[this.getOption('activityType')],
      start: moment(this.getOption('start')).format('DD/MM/YYYY'),
      end: moment(this.getOption('end')).format('DD/MM/YYYY')
    };
  }
});

module.exports = TimetrackTaskIndividualPieChartsView;
