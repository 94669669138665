'use strict';

var _ = require('lodash');
var FileView = require('./file-row');
var EmptyFilesView = require('./file-no-rows');

var FileListView = Backbone.Marionette.CollectionView.extend({
  className: 'file-list',
  initialize: function() {
    this.listenTo(this.collection, 'sync', this.render);
    this.selectedFiles = [];
  },
  childView: FileView,
  childViewOptions: function() {
    return {
      casefile: this.getOption('casefile'),
      clients: this.getOption('clients'),
      isClient: this.getOption('isClient'),
      isSimple: this.getOption('isSimple'),
      lastViewed: this.getOption('lastViewed'),
      editMode: this.getOption('editMode'),
      enableSelection: this.getOption('enableSelection'),
    };
  },
  emptyView: EmptyFilesView,
  collectionEvents: {
    'change:deleted': 'onFileDeleted',
  },
  childEvents: {
    fileDrag: function() {
      this.trigger('fileDrag');
    },
    requestFileDownload: function(view, file) {
      var that = this;
      // show dialog asking for download password
      TCAS.commands.execute('enableSensitiveData', function() {
        var sensitiveDataToken = TCAS.reqres.request('sensitiveDataToken');
        that.collection.fetch({
          data: {
            sensitive_data_token: sensitiveDataToken,
          },
        });
      });
    },
    removeFile: function(view, file) {
      this.trigger('removeFile', file);
    },
    fileSelectionChanged: function(view, file, isSelected) {
      if (isSelected) {
        this.selectedFiles.push(file);
      } else {
        this.selectedFiles = _.without(this.selectedFiles, file);
      }
      this.trigger('fileSelectionChanged', this.selectedFiles);
    },
  },
  selectAllFiles: function(isSelected) {
    this.$('.select-file').prop('checked', isSelected);
    if (isSelected) {
      this.selectedFiles = this.collection.toArray();
    } else {
      this.selectedFiles = [];
    }
    this.trigger('fileSelectionChanged', this.selectedFiles);
  },
  onFileDeleted: function(model, deleted) {
    if (deleted) {
      this.collection.remove(model);
    }
  },
  addChild: function(child) {
    if (!child.get('deleted')) {
      Backbone.Marionette.CollectionView.prototype.addChild.apply(this, arguments);
    }
  },
});

module.exports = FileListView;
