'use strict';
var template = require('../../templates/teams/team-create.hbs');
var Team = require('../../models/users/team');

var TeamCreateView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var data = {};
    data.owner = TCAS.session.get('id');
    return data;
  },
  submitData: function(data) {
    var team = new Team({
      name: data.name,
      location: data.location,
      owner: TCAS.session.get('id')
    });
    console.log(TCAS.session.get('id'));
    team.save().then(function() {
      TCAS.vent.trigger('team:saved');
    });
  },
  behaviors: {
    SubmitForm: {
      message: 'add team form',
      selector: '#add-team-form',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          name: {
            required: true
          },
          location: {
            required: true
          },
          owner: {
          }
        },
        ignore: []
      },
      selector: '#add-team-form'
    }
  }
});

module.exports = TeamCreateView;
