'use strict';
var moment = require('moment');

var TimeTrack = Backbone.Model.extend({
  urlRoot: '/api/timetrack/',
  totalMinutes: function() {
    var startMoment = moment(this.get('start'));
    var endMoment = moment(this.get('end'));
    return endMoment.diff(startMoment, 'minutes');
  },
  totalHours: function() {
    var startMoment = moment(this.get('start'));
    var endMoment = moment(this.get('end'));
    return endMoment.diff(startMoment, 'hours');
  }
});

module.exports = TimeTrack;

TimeTrack.TASKS = {
  0: 'Research',
  1: 'Other',
  2: 'Client Liaison',
  3: 'Training',
  4: 'Business Reporting',
  5: 'Meetings',
  6: 'Post',
  7: 'Case Administration',
  8: 'Resources Management',
  9: 'IT',
  10: 'Ad-Hoc',
  11: 'Orders',
  12: 'Expenses',
  13: 'Travel',
  14: 'Proofing',
  15: 'British Library',
  16: 'CRM Updates',
  17: 'Scanning',
  18: 'Probate Registry',
  19: 'Legal',
  20: 'Drafting Materials',
  21: 'Planning',
  22: 'Networking',
  23: 'Appraisals',
  24: 'Companies House',
  25: 'IIP',
  26: 'Correspondence',
  27: 'Study',
  28: 'Certification',
  29: 'Family Tree',
  30: 'Case Strategy',
  31: 'Recruitment',
  32: 'Policy & Procedures',
  33: 'Corporate',
  34: 'TCAS Development',
  35: 'Social Media',
  36: 'TCAS Uploads',
  37: 'Filing',
  38: 'Stock Check',
  39: 'Shopping',
  40: 'Quality Assurance',
  41: 'Accreditations',
  42: 'Compliance',
  43: 'Complaints Handling'
};

TimeTrack.ACTIVITY_CASE_WORK = 0;
TimeTrack.ACTIVITY_ADMINISTRATION = 1;
TimeTrack.ACTIVITY_MANAGEMENT = 2;
TimeTrack.ACTIVITY_GENERAL = 3;
TimeTrack.ACTIVITY_LEGAL = 4;
TimeTrack.ACTIVITY_HR = 5;
TimeTrack.ACTIVITY_OFFSITE = 6;
TimeTrack.ACTIVITY_BUSINESS_DEVELOPMENT = 7;
TimeTrack.ACTIVITY_OPERATIONS = 9;

TimeTrack.ACTIVITIES = {
  0: 'Case Work',
  1: 'Administration',
  2: 'Management',
  3: 'General',
  4: 'Legal',
  5: 'HR',
  6: 'Off-site',
  7: 'Business Development',
  9: 'Operations'
};

TimeTrack.ACTIVITY_TASKS = {
  0: [7,30,2,29,19,0,1],
  1: [4,37,6,14,17,38,36,1],
  2: [23,4,30,33,20,5,22,21,32,31,8,35,34,3,1],
  3: [10,9,5,11,27,13,1],
  4: [28,26,5,18,0,1],
  5: [23,20,12,25,3,1],
  6: [15,24,5,11,18,39,13,1],
  7: [30,2,16,20,5,22,0,13,1],
  9: [40,8,34,32,41,42,43]
};