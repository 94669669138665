// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <a href=\""
    + alias4(((helper = (helper = helpers.file_download || (depth0 != null ? depth0.file_download : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_download","hash":{},"data":data}) : helper)))
    + "\" class=\"file-link\">"
    + alias4(((helper = (helper = helpers.original_file_name || (depth0 != null ? depth0.original_file_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"original_file_name","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <p class=\"help-block\">File downloads are restricted, enter the correct password to download the file</p>\n                        <p>"
    + container.escapeExpression(((helper = (helper = helpers.original_file_name || (depth0 != null ? depth0.original_file_name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"original_file_name","hash":{},"data":data}) : helper)))
    + "</p>\n                        <label class=\"control-label\" for=\"files-password\">Password</label>\n                        <input type=\"password\" name=\"password\" class=\"form-control\" id=\"files-password\" placeholder=\"Type password...\"/>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"submit\" class=\"btn btn-primary\">Download file</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n            <h4 class=\"modal-title\">Download file</h4>\n        </div>\n        <div class=\"modal-body\">\n            <form>\n                <div class=\"form-group\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.file_download : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.file_download : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </form>\n        </div>\n    </div>\n</div>";
},"useData":true});
