'use strict';

var _ = require('lodash');
var template =
  require('../../../templates/reporting/individual-yearly-concluded-table.hbs');

var IndividualYearlyConcludedTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var data = {};

    var cases = [];
    for (var i = 0; i < 12; i += 1) {
      cases[i] = [];
    }

    // group case ids by month
    this.getOption('concludedMilestones').each(function(milestone) {
      var created = new Date(milestone.get('created'));
      cases[created.getMonth()].push(milestone.get('case'));
    });

    var concludedByMonth = cases.map(function(casesInMonth) {
      return _.unique(casesInMonth).length;
    });
    var concludedYTD = [];
    var total = 0;
    for (i = 0; i < 12; i += 1) {
      total += concludedByMonth[i];
      concludedYTD[i] = total;
    }
    data.concludedByMonth = concludedByMonth;
    data.concludedYTD = concludedYTD;

    return data;
  }
});

module.exports = IndividualYearlyConcludedTableView;
