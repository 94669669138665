'use strict';
var CaseFileAllocateRowView = require('./casefile-allocate-row');
var CaseFileNoRowsView = require('./casefile-no-rows');
var template = require('../../templates/cases/casefile-allocate-list.hbs');

var CaseFilesAllocateTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'table',
  id: 'casefiles',
  ui: {
    sortableField: 'a.sortable'
  },
  events: {
    'click @ui.sortableField': 'sortField'
  },
  initialize: function() {
    var that = this;
    this.on('childview:child:clicked', function(){
      that.trigger('selected:item');
    });
  },
  className: 'table table-hover table-condensed',
  childViewContainer: 'tbody',
  template: template,
  childView: CaseFileAllocateRowView,
  emptyView: CaseFileNoRowsView,
  sortField: function(e){
    var orderBy = $(e.currentTarget).attr('data-sort');
    orderBy = orderBy.replace(/\./g, '__');
    if (this.collection.orderBy && this.collection.orderBy[0] === orderBy) {
      orderBy = '-' + orderBy;
    }
    this.collection.orderBy = [orderBy];
    this.collection.fetch();
    return false;
  }
});

module.exports = CaseFilesAllocateTableView;