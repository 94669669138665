'use strict';
var BaseCollection = require('../base-collection');
var UserTarget = require('./usertarget');

var UserTargets = BaseCollection.extend({
  model: UserTarget,
  urlRoot: '/api/usertargets/',
  comparator: 'id'
});

module.exports = UserTargets;
