'use strict';
var Allocation = require('../../models/cases/allocation');
var CasesController = require('../../controller/cases-controller');
var ConfirmModal = require('../confirm-modal');
var User = require('../../models/users/user');
var CaseFile = require('../../models/cases/casefile');
var CaseFileRowView = require('./casefile-row');
var CaseFileNoRowsView = require('./casefile-no-rows');
var FilterView = require('../filter-view');
var Spinner = require('spin');
var template = require('../../templates/cases/casefile-list.hbs');

var CaseFilesTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'div',
  id: 'casefiles',
  template: template,
  childView: CaseFileRowView,
  childViewContainer: '.table',
  emptyView: CaseFileNoRowsView,
  events: {
    'click @ui.sortableField': 'sortField',
    'click @ui.allocateBtn': 'allocateCases',
    'click @ui.deallocateBtn': 'deallocateCases',
    'click @ui.selectAll': 'selectAll',
    'click @ui.loadMoreBtn': 'onClickLoadMore'
  },
  collectionEvents: {
    'sync': 'onSync'
  },
  ui: {
    loadingBar: 'div.loading-bar',
    loadMoreBtn: 'button.pagination-next',
    sortableField: 'a.sortable',
    table: 'table',
    pageInfo: '.page-info-bar',
    caseCount: '.case-count',
    caseTotal: '.case-total',
    allocateBtn: '#allocate-cases',
    deallocateBtn: '#deallocate-cases',
    caseManager: '#case-manager',
    selectAll: '#select-all-checkbox'
  },
  initialize: function(){
    var lazyScroll = _.bind(_.debounce(this.onScroll, 100), this);
    $(window).scroll(lazyScroll);
    this.checked = false;
  },
  onDestroy: function() {
    $(window).off('scroll');
  },
  onClickLoadMore: function() {
    if (this.collection.next) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.spinner.spin(this.ui.loadingBar[0]);
      this.ui.loadMoreBtn.button('loading');
      this.collection.fetchNext();
    }
  },
  selectAll: function() {
    var that = this;
    var selected =
      this.collection.where({status: CaseFile.STATUS_IDS.UNALLOCATED});
    this.checked = !this.checked;

    _.forEach(selected, function(casefile) {
      casefile.set('checked', that.checked);
      casefile.trigger('select:checkbox');
    });
  },
  sortField: function(e){
    var orderBy = $(e.currentTarget).attr('data-sort');
    orderBy = orderBy.replace(/\./g, '__');
    if (this.collection.orderBy && this.collection.orderBy[0] === orderBy) {
      orderBy = '-' + orderBy;
    }
    this.collection.orderBy = [orderBy];
    this.collection.fetch();
    return false;
  },
  allocateCases: function() {
    var caseManager = this.ui.caseManager.select2('val');
    var caseFiles = this.collection.where({checked: true});

    if(caseManager === '') {
      caseManager = null;
    }

    if(caseFiles.length === 0) {
      alert('No case files have been selected');
      return;
    }
    TCAS.router.navigate('/cases/allocate');
    TCAS.casesController.allocateCasefile(caseManager, caseFiles);
  },
  deallocateCases: function() {
    var caseFiles = this.collection.where({
      checked: true,
      status: CaseFile.STATUS_IDS.RESEARCH
    });

    if(caseFiles.length === 0) {
      alert('No case files have been selected');
      return;
    }

    var confirmDialog = new ConfirmModal({body: 'Are you sure you want to ' +
    'deallocate these cases?'});
    confirmDialog.render();
    var view = this;

    this.listenTo(confirmDialog, 'confirmYes', function(){
      this.spinner = new Spinner();
      this.spinner.spin(this.ui.table[0]);
      var that = this;

      caseFiles.forEach(function(caseFile) {
        var allocation = caseFile.getCurrentAllocation();
        caseFile.set('status', CaseFile.STATUS_IDS.UNALLOCATED);
        allocation.set('is_active', false);
        caseFile.save({}, {
          success: function() {
            allocation.save();
            if(caseFile.get('id') === _.last(caseFiles).get('id')) {
              that.spinner.stop();
              view.render();
            }
          }
        });
      });
    });
  },
  onScroll: function(){
    var that = this;
    var scrollTop = $(window).scrollTop();
    var isMaxScroll = (document.body.scrollHeight - $(window).height()) ===
      scrollTop;
    if (isMaxScroll && this.collection.next) {
      if (!this.spinner) {
        this.spinner = new Spinner();
      }
      this.spinner.spin(this.ui.loadingBar[0]);
      this.ui.loadMoreBtn.button('loading');
      this.collection.fetchNext();
    }
  },
  onSync: function(){
    this.$('.sortable').removeClass('active reverse');
    if (this.collection.orderBy) {
      _(this.collection.orderBy).each(function(sortBy){
        sortBy = sortBy.replace(/__/g, '.');
        var isReversed = sortBy.charAt(0) === '-';
        if (isReversed) {
          sortBy = sortBy.substr(1);
        }
        this.$('[data-sort="'+sortBy+'"]').addClass('active')
          .toggleClass('reverse', isReversed);

      }, this);
    }
    this.ui.table.removeClass('hide');

    var that = this;
    that.ui.caseCount.html(that.collection.length);
    that.ui.caseTotal.html(that.collection.total);
    var progressWidth = that.ui.pageInfo.find('.progress').width();
    that.ui.pageInfo.removeClass('hide');
    that.ui.pageInfo.find('.progress-bar').attr('aria-valuenow',
    that.collection.length).attr('aria-valuemax', that.collection.length);
    that.ui.pageInfo.find('.progress-bar').width(
      Math.floor((that.collection.length/that.collection.total) *
        progressWidth)).is(':visible');

    if (this.spinner) {
      this.spinner.stop();
    }
    this.ui.loadMoreBtn.button('reset');
    _.defer(function() {
      that.ui.loadMoreBtn.prop('disabled', !that.collection.next);
    });
  },
  onRender: function(){
    this.filterRegion = new Backbone.Marionette.Region({
      el: this.$('#filter-content')[0]
    });

    this.modalConfirm = new Backbone.Marionette.Region({
      el: this.$('.modal-entry')[0]
    });

    var filterView = new FilterView({
      collection: this.collection,
      filterTeam: this.getOption('filterTeam'),
      excludeFilters: this.getOption('excludeFilters')
    });
    this.filterRegion.show(filterView);
  },
  onShow: function(){
    var that = this;
    this.spinner = new Spinner();
    this.spinner.spin(this.ui.loadingBar[0]);

    this.on('childview:remove:casefile', function(childView) {
      var confirmModal = new ConfirmModal();
      confirmModal.on('confirmYes', function() {
        childView.model.set('is_active', false);

        childView.model.save().then(function() {
          that.collection.fetch();
        });
      });

      that.modalConfirm.show(confirmModal);
    });

    function formatUser(user, container, query){
      var s = user.first_name + ' ' + user.last_name; // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      return s;
    }

    var caseManagerUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    this.ui.caseManager.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      ajax: {
        url: caseManagerUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true
    });

  },
  serializeData: function(){
    var data = this.collection.toJSON();
    if (this.collection.orderBy) {
      data.sortField = this.collection.orderBy[0];
    }

    if(this.options.hideAllocateForm) {
      data.hideAllocateForm = true;
    }

    return data;
  }
});

module.exports = CaseFilesTableView;