'use strict';


var AuthenticationLogItem = Backbone.Model.extend({
  urlRoot: '/api/authenticationlogs/',
  getFullName: function(){
    return this.get('first_name') + ' ' +this.get('last_name');
  }
});

module.exports = AuthenticationLogItem;