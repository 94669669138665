// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-warning alert-dismissible\" role=\"alert\">\n    <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\"><i\n                class=\"fa fa-close\"></i></span></button>\n    <strong>Warning!</strong> You do not have Archived Cases session enabled, so reports will not contain any data\n    from archived cases.\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isArchiveDataSession : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div id=\"alert-area\"></div>\n\n<div>\n    <form class=\"form-inline\">\n        <div class=\"form-group\">\n            <label for=\"start\">From</label>\n            <input id=\"start\" class=\"form-control\">\n        </div>\n        <div class=\"form-group\">\n            <label for=\"end\">To</label>\n            <input id=\"end\" class=\"form-control\">\n        </div>\n\n        <div class=\"form-group\">\n            <select class=\"report-type form-control\">\n                <option value=\"None\">Select a Report...</option>\n                <option value=\"company\">Company Report</option>\n                <option value=\"personnel\">Personnel Report</option>\n                <option value=\"team\">Team Report</option>\n            </select>\n        </div>\n\n        <div class=\"form-group company-group\">\n            <button class=\"btn btn-success btn-sm\" id=\"submit\">Company Report</button>\n        </div>\n\n        <div class=\"form-group personnel-group\">\n            <input id=\"user\">\n            <button class=\"btn btn-success btn-sm\" id=\"submit-personnel\">Personnel Report</button>\n        </div>\n\n        <div class=\"form-group team-group\">\n            <input id=\"team\">\n            <button class=\"btn btn-success btn-sm\" id=\"submit-team\">Team Report</button>\n        </div>\n\n        <button class=\"pull-right btn btn-success\" id=\"export-csv\">Export Results as CSV</button>\n    </form>\n\n</div>\n<br />\n<div id=\"reporting-area\" class=\"table-responsive\"></div>";
},"useData":true});
