'use strict';
var Clipboard = require('clipboard');
var template = require('../templates/login.hbs');
var QRious = require('qrious');
var SecretChallenge = require('../models/users/secretchallenge');
var SecretChallengeView = require('./users/secret-challenge');

var LoginView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'login-panel',
  regions: {
    secretChallengeRegion: '.secret-challenge-container'
  },
  events: {
    'submit form.login-form': 'login',
    'submit form.two-factor-form': 'login2Factor',
    'submit form.two-factor-setup-form': 'set2Factor',
    'submit form.authy-form': 'login2Factor',
    'submit form.secret-form': 'saveSecret',
    'submit @ui.forgotPasswordForm': 'resetPassword',
    'click @ui.forgotPasswordLink': 'showForgotPassword',
    'click @ui.forgot2faLink': 'showForgot2fa',
    'submit @ui.forgot2faForm': 'reset2fa'
  },
  ui: {
    'username': 'form.login-form input[name="username"]',
    'password': 'form.login-form input[name="password"]',
    'token': 'form.two-factor-form input[name="token"]',
    'tokenAuthySetup': 'form.authy-form input[name="authy_token"]',
    'email': 'input[name="email"]',
    'loginBtn': '.login',
    'alert': '.alert.alert-danger',
    'copyBtn': '.copy',
    'forgotPasswordForm': 'form.forgot-password-form',
    'forgot2faForm': 'form.forgot-2fa-form',
    'forgotPasswordLink': '.forgot-password-link',
    'forgot2faLink': '.forgot-2fa-link',
    'loginFormContainer': '.login-form-container',
    'login2FAForm': 'form.two-factor-form',
    'setup2FAForm': 'form.two-factor-setup-form',
    'authySetupForm': 'form.authy-form',
    'setup2FABtn': 'button.set-2fa',
    'authySubmitBtn': 'button.authy-submit',
    'authyToken': 'form.authy-form input[name="authy_token"]',
    'qrCodeContainer': '.qrcode-container',
    'secretCode': '.two-factor-secret',
    'secretSetupContainer': '.secret-setup-container',
    'secretChallengeContainer': '.secret-challenge-container',
    'secret': 'input[name="secret"]',
    'secretSubmitBtn': 'button.secret-submit',
    'canvas': '.qrcode-canvas'
  },
  login: function(){
    var that = this;
    var app = this.getOption('app');
    var username = this.ui.username.val();
    var password = this.ui.password.val();
    this.ui.login2FAForm.find('input[name="username"]').val(username);
    this.ui.login2FAForm.find('input[name="password"]').val(password);
    this.ui.loginBtn.button('loading');
    $.post('/api/session/', { username: username, password: password }, 'json')
      .done(function(user) {
        console.log(user);
      })
      .fail(function(xhr) {

        that.ui.loginBtn.button('reset');
        that.ui.alert.empty().addClass('hide');

        if (xhr.responseJSON.detail === 'Your account requires setup of 2 factor authentication') {
          // show options for setting up 2 factor authentication, either with authy or secret
          that.ui.loginFormContainer.hide();
          that.ui.setup2FAForm.show();
          that.ui.alert.html(xhr.responseJSON.detail).removeClass('hide');
        } else if (xhr.responseJSON.detail === 'Your account requires Authy for 2 factor authentication') {
          // show authy setup form,
          // skips 2FA choice because this is internal staff who are only allowed to use Authy
          // ensure authy is checked
          that.ui.loginFormContainer.hide();
          that.ui.setup2FAForm.find('input[value="authy"]').prop('checked', true);
          that.ui.setup2FAForm.find('input[value="secret"]').prop('checked', false);
          that.set2Factor();
        } else if (xhr.responseJSON.detail === 'You need to provide a 2 factor authentication token') {
          that.ui.login2FAForm.show();
          that.ui.loginFormContainer.hide();
          that.ui.qrCodeContainer.hide();
          that.ui.token.focus();
        } else if (xhr.responseJSON.detail === 'You need to provide a secret challenge') {
          $.post('/api/secretchallenges/',
            { username: username, password: password },
            'json'
          ).done(function(secretChallenge) {
            var secretChallengeView = new SecretChallengeView({
              model: new SecretChallenge(secretChallenge),
              username: username,
              password: password,
              session: app.session
            });
            that.secretChallengeRegion.show(secretChallengeView);
            that.ui.loginFormContainer.hide();
          }).fail(function(xhr){
            that.ui.alert.html(xhr.responseJSON.detail).removeClass('hide');
            that.ui.loginBtn.button('reset');

            if (xhr.responseJSON.detail.indexOf('1 more attempt') !== -1) {
              that.ui.forgotPasswordLink.addClass('highlight');
            } else {
              that.ui.forgotPasswordLink.removeClass('highlight');
            }
          });
        } else {
          that.ui.alert.html(xhr.responseJSON.detail).removeClass('hide');

          if (xhr.responseJSON.detail.indexOf('locked') === -1) {
            if (!(/[A-Z]/.test(password) // has an uppercase letter
              && /[!£$\?@#*]/.test(password) // contains a symbol
              && /\d/.test(password))) {
              that.ui.alert.append('<br/><br/>Passwords are case sensitive, ' +
                'must be at least 8 characters and contain at least an upper and lowercase letter, ' +
                'a number and at least one symbol (! £ $ ? @ # *).');
            }
          }
        }
      });
    return false;
  },
  set2Factor: function() {
    var that = this;
    var username = this.ui.username.val();
    var password = this.ui.password.val();
    this.ui.setup2FABtn.button('loading');

    function setupAuthy() {
      // setup 2 factor authentication
      $.post('/api/twofactordevices/',
        { username: username, password: password },
        'json'
      ).done(function(data) {
        // obtain the secret from the url for display
        var matches = /secret=(.+)&period/.exec(data);
        var code = matches[1];
        that.ui.secretCode.html(code);
        that.ui.authySetupForm.show();
        that.ui.setup2FAForm.hide();
        var qr = new QRious({
          element: that.ui.canvas[0],
          value: data,
          size: 300
        });
        that.ui.setup2FABtn.button('reset');
        that.ui.loginBtn.button('reset');
      });
    }

    function setupSecret() {
      that.ui.secretSetupContainer.show();
      that.ui.loginFormContainer.hide();
      that.ui.setup2FAForm.hide();
      that.ui.authySetupForm.hide();
      that.ui.secretSetupContainer.find('input[name="username"]').val(username);
      that.ui.secretSetupContainer.find('input[name="password"]').val(password);
      that.ui.secret.focus();
      that.$('form.secret-form').validate({
        rules: {
          secret: {
            secretComplexity: true
          }
        }
      });
    }

    if (this.ui.setup2FAForm.find('input:checked').val() === 'authy') {
      setupAuthy();
    } else {
      setupSecret();
    }

    return false;
  },
  login2Factor: function() {
    var that = this;
    var app = this.getOption('app');
    var username = this.ui.username.val();
    var password = this.ui.password.val();
    var token = this.ui.token.val();
    if (this.ui.tokenAuthySetup.val()) {
      token = this.ui.tokenAuthySetup.val();
      this.ui.authySubmitBtn.button('loading');
    }
    this.ui.loginBtn.button('loading');

    $.post('/api/session/',
      { username: username, password: password, token: token },
      'json'
    ).done(function(data) {
      if (data.is_active) {
        app.session.set(data);
      } else {
        app.session.destroy();
        that.ui.alert.html('This user does not have an active account.')
          .removeClass('hide');
        that.ui.loginBtn.button('reset');
      }
    }).fail(function(xhr) {
      that.ui.alert.html(xhr.responseJSON.detail).removeClass('hide');
      that.ui.loginBtn.button('reset');
      that.ui.authySubmitBtn.button('reset');
    });

    return false;
  },
  resetPassword: function(){
    var that = this;
    $.post('/api/passwordreset/',
      {
        email: this.ui.email.val()
      },
      'json')
      .done(function(data){
        that.ui.alert.html('Please check your email for further instructions.')
          .removeClass('hide alert-danger').addClass('alert-success');
      }).error(function(jqXHR){
        var error = JSON.parse(jqXHR.responseText);
        if (error.email) {
          error = error.email.join('<br/>');
        }
        that.ui.alert.html(error).removeClass('hide alert-success')
          .addClass('alert-danger');
      });

    return false;
  },
  reset2fa: function() {
    var that = this;
    var username = this.ui.username.val();
    var password = this.ui.password.val();
    $.post('/api/twofactorreset/',
      {
        username: username,
        password: password
      },
      'json')
      .done(function(data){
        that.ui.alert.html('Please check your email for further instructions.')
          .removeClass('hide alert-danger').addClass('alert-success');
        that.ui.forgot2faForm.slideUp();
      }).error(function(jqXHR){
      var error = JSON.parse(jqXHR.responseText);
      if (error.email) {
        error = error.email.join('<br/>');
      }
      that.ui.alert.html(error).removeClass('hide alert-success')
        .addClass('alert-danger');
    });

    return false;
  },
  saveSecret: function() {
    var that = this;
    var app = this.getOption('app');
    var formData = {};
    this.ui.secretSubmitBtn.button('loading');
    this.ui.secretSetupContainer.find('input').each(function() {
      formData[this.name] = this.value;
    });
    $.post('/api/secrets/', formData).done(
      function(data) {
        if (data.is_active) {
          app.session.set(data);
        } else {
          app.session.destroy();
          that.ui.alert.html('This user does not have an active account.')
            .removeClass('hide');
          that.ui.secretSubmitBtn.button('reset');

        }
      }
    );
    return false;
  },
  showForgotPassword: function(){
    this.ui.forgotPasswordForm.slideDown();
    return false;
  },
  showForgot2fa: function() {
    this.ui.forgot2faForm.slideDown();
    return false;
  },
  serializeData: function() {
    return this.options;
  },
  onShow: function() {
    if (this.getOption('expandForgotPassword')) {
      this.$('#forgot-password-email').focus();
    } else if (this.getOption('expandForgot2fa')) {
      this.$('#forgot-2fa-email').focus();
    }
  },
  onRender: function() {
    var that = this;
    this.$('i[title]').tooltip();

    this.ui.copyBtn.tooltip({
      title: 'Copied!',
      placement: 'bottom',
      trigger: 'manual'
    });
    if (this.clipboard) {
      this.clipboard.destroy();
    }
    this.clipboard = new Clipboard(this.ui.copyBtn[0]);
    this.clipboard.on('success', function(e) {
      that.ui.copyBtn.tooltip('show');
      e.clearSelection();
    });

    this.$('form.login-form').validate({
      rules: {
        username: {
          required: true
        },
        password: {
          required: true
        }
      }
    });
  }
});

module.exports = LoginView;