'use strict';
var template = require('../../templates/reporting/report-casefile-row.hbs');

var ReportCaseFileRow = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tbody',
  events: {
    'click .popout': 'popoutCase'
  },
  popoutCase: function(e){
    TCAS.commands.execute('popoutCase', this.model);

    return false;
  },
  serializeData: function() {
    var data = this.model.toJSON();

    if(data.event_0) {
      data.event_0 = data.event_0.toJSON();
    }
    if(data.event_1) {
      data.event_1 = data.event_1.toJSON();
    }
    if(data.event_2) {
      var total = 0.0;
      data.event_2.forEach(function(value) {
        var obj;
        try {
          obj = JSON.parse(value.get('details'));
        } catch(err) {
          obj = value.get('details');
        }
        total += obj.income_value;
      });
      //data.event_2 = data.event_2.toJSON();
      data.event_2 = total;
    }
    //if(data.event_3) {
    //  data.event_3 = data.event_3.toJSON();
    //}
    if(data.event_4) {
      data.event_4 = data.event_4.toJSON();
    }
    if(data.event_5) {
      data.event_5 = data.event_5.toJSON();
    }
    if(data.event_6) {
      data.event_6 = data.event_6.toJSON();
    }
    if(data.event_7) {
      data.event_7 = data.event_7.toJSON();
    }
    if(data.event_8) {
      data.event_8 = data.event_8.toJSON();
    }
    if(data.event_9) {
      data.event_9 = data.event_9.toJSON();
    }
    if(data.event_10) {
      data.event_10 = data.event_10.toJSON();
    }

    return data;
  }
});

module.exports = ReportCaseFileRow;