'use strict';

var template =
  require('../../../templates/reporting/individual-yearly-revenue-table.hbs');

var IndividualYearlyRevenueTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var data = {};
    var userTarget = this.getOption('userTarget');
    var minimumRevenuePerYear =
      userTarget ? userTarget.get('minimum_revenue') : 0;
    var minimumRevenuePerMonth = minimumRevenuePerYear / 12;
    var performanceRevenuePerYear =
      userTarget ? userTarget.get('performance_revenue') : 0;
    var performanceRevenuePerMonth = performanceRevenuePerYear / 12;
    var stretchRevenuePerYear =
      userTarget ? userTarget.get('stretch_revenue') : 0;
    var stretchRevenuePerMonth = stretchRevenuePerYear / 12;

    // create arrays for months, accumulate totals etc
    var revenueAssets = [];
    var revenueLA = [];
    var totals = [];
    var totalCumulative = [];
    var minimumRevenuePhasedPlan = [];
    var minimumRevenuePhasedYTDPlan = [];
    var performanceRevenuePhasedPlan = [];
    var performanceRevenuePhasedYTDPlan = [];
    var stretchRevenuePhasedPlan = [];
    var stretchRevenuePhasedYTDPlan = [];

    for (var i =0; i < 12; i += 1) {
      revenueAssets[i] = 0;
      revenueLA[i] = 0;
    }

    this.getOption('bookingAssetRecords').each(function(bookingRecord) {
      var createdMonth = new Date(bookingRecord.get('date')).getMonth();
      revenueAssets[createdMonth] += bookingRecord.get('revenue');
    });
    this.getOption('bookingLARecords').each(function(bookingRecord) {
      var createdMonth = new Date(bookingRecord.get('date')).getMonth();
      revenueLA[createdMonth] += bookingRecord.get('revenue');
    });

    var cumulativeTotal = 0;
    var cumulativeMinimumRevenue = 0;
    var cumulativePerformanceRevenue = 0;
    var cumulativeStretchRevenue = 0;

    for (i = 0; i < 12; i += 1) {
      totals[i] = revenueAssets[i] + revenueLA[i];
      cumulativeTotal += totals[i];
      totalCumulative[i] = cumulativeTotal;

      cumulativeMinimumRevenue += minimumRevenuePerMonth;
      minimumRevenuePhasedPlan[i] = cumulativeMinimumRevenue;
      minimumRevenuePhasedYTDPlan[i] =
        cumulativeTotal / cumulativeMinimumRevenue;

      cumulativePerformanceRevenue += performanceRevenuePerMonth;
      performanceRevenuePhasedPlan[i] = cumulativePerformanceRevenue;
      performanceRevenuePhasedYTDPlan[i] =
        cumulativeTotal / cumulativePerformanceRevenue;

      cumulativeStretchRevenue += stretchRevenuePerMonth;
      stretchRevenuePhasedPlan[i] = cumulativeStretchRevenue;
      stretchRevenuePhasedYTDPlan[i] =
        cumulativeTotal / cumulativeStretchRevenue;
    }
    data.revenueAssetsByMonth = revenueAssets;
    data.revenueLAByMonth = revenueLA;
    data.totalCumulativeByMonth = totalCumulative;
    data.minimumRevenuePhasedPlan = minimumRevenuePhasedPlan;
    data.minimumRevenuePhasedYTDPlan = minimumRevenuePhasedYTDPlan;
    data.performanceRevenuePhasedPlan = performanceRevenuePhasedPlan;
    data.performanceRevenuePhasedYTDPlan = performanceRevenuePhasedYTDPlan;
    data.stretchRevenuePhasedPlan = stretchRevenuePhasedPlan;
    data.stretchRevenuePhasedYTDPlan = stretchRevenuePhasedYTDPlan;

    return data;
  }
});

module.exports = IndividualYearlyRevenueTableView;
