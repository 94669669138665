'use strict';
var template = require('../../templates/categories/category-row.hbs');

var CategoryRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  ui: {
    'researchSelect': '.research-select',
    'category': '.category',
    'description': '.description',
    'limit': '.limit',
    'save': '.save',
    'categoryHelp': '.category-help',
    'descriptionHelp': '.description-help',
    'limitHelp': '.limit-help',
    'researchHelp': '.research-help'
  },
  events: {
    'click @ui.save': 'submitData'
  },
  onRender: function() {
    this.ui.researchSelect.select2();
    this.ui.researchSelect.select2('val', this.model.get('research_fields'));
  },
  submitData: function() {
    var that = this;
    console.log('submitData');
    var failure = false;

    if(isNaN(that.ui.limit.val()) || that.ui.limit.val() === '') {
      failure = true;
      that.ui.limit.closest('.form-group').addClass('has-error');
      that.ui.limitHelp.html('Must be a number');
    } else {
      that.ui.limit.closest('.form-group').removeClass('has-error');
      that.ui.limitHelp.html('');
    }

    if(that.ui.category.val() === '') {
      failure = true;
      that.ui.category.closest('.form-group').addClass('has-error');
      that.ui.categoryHelp.html('Required');
    } else {
      that.ui.category.closest('.form-group').removeClass('has-error');
      that.ui.categoryHelp.html('');
    }

    if(that.ui.description.val() === '') {
      failure = true;
      that.ui.description.closest('.form-group').addClass('has-error');
      that.ui.descriptionHelp.html('Required');
    } else {
      that.ui.description.closest('.form-group').removeClass('has-error');
      that.ui.descriptionHelp.html('');
    }

    if(failure) {
      return;
    }

    this.model.set({
      research_fields: that.ui.researchSelect.select2('val'),
      code: that.ui.category.val(),
      name: that.ui.description.val(),
      default_limit: that.ui.limit.val()
    });

    this.model.save({}, {
      success: function() {
        that.$el.addClass('success');
      },
      error: function() {
        that.$el.addClass('danger');
      }
    });

  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.research_types = this.options.researchTypes.toJSON();
    return data;
  }

});

module.exports = CategoryRowView;