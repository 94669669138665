// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"alerts-area\"></div>\n<div class=\"clearfix\"></div>\n<div class=\"filter-users\">\n  <div class=\"inactive-users-checkbox-container\">\n    <div class=\"checkbox\">\n      <label for=\"include-inactive-users\">\n        <input type=\"checkbox\" name=\"include_inactive\" id=\"include-inactive-users\" class=\"include-inactive-users\"\n          value=\"1\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.includeInactiveUsers : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> Include <span class=\"badge\">Inactive</span>\n      </label>\n    </div>\n  </div>\n</div>\n<div class=\"table-responsive\">\n  <table class=\"table table-hover table-users\">\n    <thead>\n      <tr>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"first_name\">User <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"username\">Username <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"userdetail.contact.email\">Email <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"groups\">Role <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"userdetail.supervisor\">Supervisor <i class=\"fa fa-caret-down\"></i><i\n              class=\"fa fa-caret-up\"></i></a></th>\n        <th></th>\n      </tr>\n    </thead>\n    <tbody>\n    </tbody>\n  </table>\n</div>";
},"useData":true});
