// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "aria-expanded=\"false\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "collapse";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),"==",(depths[1] != null ? depths[1].filterCasefile : depths[1]),{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                      "
    + alias4(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\" ";
},"8":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"\n                          ";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.disclosureDate || (depth0 != null ? depth0.disclosureDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"disclosureDate","hash":{},"data":data}) : helper)))
    + "\" ";
},"12":function(container,depth0,helpers,partials,data) {
    return "checked";
},"14":function(container,depth0,helpers,partials,data) {
    return "    <tbody class=\"table-total\">\n      <tr>\n        <td>Total:</td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td class=\"confirmed-total\"></td>\n        <td class=\"pending-total\"></td>\n        <td class=\"pledged-total\"></td>\n        <td class=\"at-risk-total\"></td>\n        <td></td>\n        <td></td>\n        <td></td>\n      </tr>\n    </tbody>\n    <tbody class=\"table-total sums-stats\">\n      <tr>\n        <td colspan=\"2\">Booked & Confirmed:</td>\n        <td><span class=\"booked-confirmed-total\"></span></td>\n      </tr>\n      <tr>\n        <td colspan=\"2\">Booked & Confirmed & Pending:</td>\n        <td><span class=\"booked-confirmed-pending-total\"></span></td>\n      </tr>\n      <tr>\n        <td colspan=\"2\">Maximum Potential:</td>\n        <td><span class=\"maximum-potential-total\"></span></td>\n      </tr>\n      <tr>\n        <td colspan=\"2\">Maximum Potential (minus At Risk):</td>\n        <td><span class=\"maximum-minus-at-risk-total\"></span></td>\n      </tr>\n    </tbody>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "<div class=\"pipeline-granular-stats-floating\">\n  <div class=\"table-responsive\">\n    <table class=\"table table-condensed pipeline-granular-totals-table\">\n      <tbody class=\"table-total\">\n        <tr>\n          <td>Total:</td>\n          <td></td>\n          <td></td>\n          <td></td>\n          <td></td>\n          <td></td>\n          <td></td>\n          <td></td>\n          <td class=\"confirmed-total\"></td>\n          <td class=\"pending-total\"></td>\n          <td class=\"pledged-total\"></td>\n          <td class=\"at-risk-total\"></td>\n          <td></td>\n          <td></td>\n          <td></td>\n        </tr>\n      </tbody>\n    </table>\n  </div>\n\n  <div class=\"sums-stats\">\n    <div class=\"row\">\n      <div class=\"col-xs-3\">\n        Booked & Confirmed: <span class=\"booked-confirmed-total\"></span>\n      </div>\n      <div class=\"col-xs-3\">\n        Booked & Confirmed & Pending: <span class=\"booked-confirmed-pending-total\"></span>\n      </div>\n      <div class=\"col-xs-3\">\n        Maximum Potential: <span class=\"maximum-potential-total\"></span>\n      </div>\n      <div class=\"col-xs-3\">\n        Maximum Potential (minus At Risk): <span class=\"maximum-minus-at-risk-total\"></span>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div>\n  <div class=\"panel panel-default\" role=\"tablist\">\n    <div class=\"panel-heading\" id=\"pipeline-granular-filter-heading\" role=\"tab\">\n      <a href=\"#\" id=\"pipline-granular-filter-toggle\" data-toggle=\"collapse\"\n        data-target=\"#pipeline-granular-filter-panel\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isFilterPanelOpen : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        aria-controls=\"filter-accordion\">\n        <h4 class=\"panel-title\">Filter</h4>\n      </a>\n    </div>\n    <div id=\"pipeline-granular-filter-panel\" class=\"panel-collapse "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isFilterPanelOpen : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n      role=\"tabpanel\" aria-labelledby=\"pipeline-granular-filter-heading\">\n      <div class=\"panel-body\">\n        <div id=\"filter-content\">\n          <form class=\"pipeline-granular-filters\">\n            <fieldset>\n              <div class=\"row\">\n                <div class=\"form-group col-sm-3 col-xs-12\">\n                  <label for=\"pipeline-case-ref\">Case ref</label>\n                  <select id=\"pipeline-case-ref\" class=\"form-control case-ref\" name=\"case_ref\">\n                    <option value=\"0\">None</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.casefiles : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                  </select>\n                </div>\n                <div class=\"form-group col-sm-4 col-xs-12\">\n                  <label for=\"pipeline-disclosure\">Disclosure</label>\n                  <div class=\"row\">\n                    <div class=\"col-md-6 col-sm-6 col-xs-6\">\n                      <select id=\"pipeline-disclosure\" class=\"form-control disclosure-date-direction\"\n                        name=\"disclosure_date_direction\">\n                        <option value=\"before\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.disclosureDateDirection : depth0),"==","before",{"name":"compare","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Before</option>\n                        <option value=\"after\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.disclosureDateDirection : depth0),"==","after",{"name":"compare","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">After</option>\n                      </select>\n                    </div>\n                    <div class=\"col-md-6 col-sm-6 col-xs-6\">\n                      <input type=\"text\" name=\"disclosure_date\" class=\"form-control disclosure-date\"\n                        placeholder=\"DD/MM/YYYY\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disclosureDate : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n                    </div>\n                  </div>\n                </div>\n              </div>\n              <div class=\"row\">\n                <div class=\"form-group col-sm-2 col-xs-12\">\n                  <label for=\"pipeline-confirmed\">Confirmed</label>\n                  <select id=\"pipeline-confirmed\" name=\"confirmed\" class=\"form-control confirmed\">\n                    <option value=\"0\">Include</option>\n                    <option value=\"exclude\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.confirmed : depth0),"==","exclude",{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                      Exclude</option>\n                  </select>\n                </div>\n                <div class=\"form-group col-sm-2 col-xs-12\">\n                  <label for=\"pipeline-pending\">Pending confirmation</label>\n                  <select id=\"pipeline-pending\" name=\"pending\" class=\"form-control pending\">\n                    <option value=\"0\">Include</option>\n                    <option value=\"exclude\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.pending : depth0),"==","exclude",{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Exclude\n                    </option>\n                  </select>\n                </div>\n                <div class=\"form-group col-sm-2 col-xs-12\">\n                  <label for=\"pipeline-pledged\">Pledged</label>\n                  <select id=\"pipeline-pledged\" name=\"pledged\" class=\"form-control pledged\">\n                    <option value=\"0\">Include</option>\n                    <option value=\"exclude\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.pledged : depth0),"==","exclude",{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Exclude\n                    </option>\n                  </select>\n                </div>\n                <div class=\"form-group col-sm-2 col-xs-12\">\n                  <label for=\"pipeline-at-risk\">At risk</label>\n                  <select id=\"pipeline-at-risk\" name=\"at_risk\" class=\"form-control at-risk\">\n                    <option value=\"0\">Include</option>\n                    <option value=\"exclude\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.atRisk : depth0),"==","exclude",{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Exclude\n                    </option>\n                  </select>\n                </div>\n                <div class=\"form-group col-sm-2 col-xs-12\">\n                  <label for=\"pipeline-is-booked\">Booked Y / N</label>\n                  <select id=\"pipeline-is-booked\" name=\"is_booked\" class=\"form-control is-booked\">\n                    <option value=\"0\">Include</option>\n                    <option value=\"exclude\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.isBooked : depth0),"==","exclude",{"name":"compare","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Exclude\n                    </option>\n                  </select>\n                </div>\n              </div>\n              <button type=\"submit\" class=\"btn btn-primary\">Filter</button>\n              <button type=\"reset\" class=\"btn btn-default reset\">Reset filters</button>\n              <button type=\"button\" class=\"btn btn-default export\" data-loading-text=\"Exporting...\">Export CSV</button>\n            </fieldset>\n          </form>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"text-right\">\n  <div class=\"checkbox checkbox-inline\"><label><input type=\"checkbox\" class=\"display-pence\"\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.displayPence : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Display pence</label></div>\n  <button type=\"button\" class=\"btn btn-default btn-sm copy-table\" data-clipboard-target=\"#"
    + alias4(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tableId","hash":{},"data":data}) : helper)))
    + "\"><i\n      class=\"fa fa-copy\"></i> Copy</button>\n</div>\n<div class=\"table-responsive\">\n  <table class=\"table table-condensed table-striped pipeline-granular-table\" id=\""
    + alias4(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tableId","hash":{},"data":data}) : helper)))
    + "\">\n    <thead>\n      <tr>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"team\">Team</a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"caseType\">Council / Asset</a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"ref\">Case Ref</a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"case_manager\">Case Manager</a></th>\n        <th>Category</th>\n        <th>Disclosure Date</th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"date\">Actual / Forecasted Booking Date</a></th>\n        <th>Week</th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"confirmed\">CONFIRMED</a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"pending\">PENDING CONFIRMATION</a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"pledged\">PLEDGED</a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"is_at_risk\">AT RISK</a></th>\n        <th><a href=\"#\" class=\"sortable\" data-sort=\"is_csoa_raised\">CSOA RAISED</a></th>\n        <th>Notes</th>\n        <th>Booked</th>\n      </tr>\n    </thead>\n    <tbody class=\"table-items\"></tbody>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </table>\n</div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
