// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <td>\n        <select id=\"event\" class=\"form-control\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.eventTypes : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </td>\n    <td><input id=\"user\" class=\"form-control input-sm\" value=\""
    + alias4(((helper = (helper = helpers.user || (depth0 != null ? depth0.user : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user","hash":{},"data":data}) : helper)))
    + "\"></td>\n    <td><input id=\"details\" class=\"form-control input-sm\" value=\""
    + alias4(((helper = (helper = helpers.details || (depth0 != null ? depth0.details : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"details","hash":{},"data":data}) : helper)))
    + "\"></td>\n    <td><input id=\"createdDate\" class=\"form-control input-sm\" value=\""
    + alias4((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\"></td>\n    <td><button id=\"save\" class=\"btn btn-success btn-sm\">Save</button></td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n    <td>"
    + alias3((helpers.getEventName || (depth0 && depth0.getEventName) || alias2).call(alias1,(depth0 != null ? depth0.event : depth0),{"name":"getEventName","hash":{},"data":data}))
    + "</td>\n    <td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n    <td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.legacy_id : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.details || (depth0 != null ? depth0.details : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"details","hash":{},"data":data}) : helper)))
    + "</td>\n    <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n    <td><button id=\"edit\" class=\"btn btn-default btn-sm\"><i class=\"fa fa-pencil\"></i> Edit</button></td>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge\">Legacy</span> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.edit : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "<td><button id=\"remove\" class=\"btn btn-danger btn-sm\">Remove</button></td>\n\n";
},"useData":true});
