'use strict';
var template = require('../../templates/cases/my-casefiles-layout.hbs');

var MyCaseFilesLayout = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    reminders: '#reminders-sub',
    caseFiles: '#casefiles-list-sub'
  },
  onShow: function() {
    var that = this;

    if(this.options.remindersView) {
      that.reminders.show(that.options.remindersView);
    }

    this.caseFiles.show(this.options.caseFilesView);
  }
});

module.exports = MyCaseFilesLayout;
