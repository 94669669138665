// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " <span class=\"badge badge-danger\" title=\"New messages since last viewed\">"
    + container.escapeExpression(((helper = (helper = helpers.notificationBadgeCount || (depth0 != null ? depth0.notificationBadgeCount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notificationBadgeCount","hash":{},"data":data}) : helper)))
    + "</span>";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return alias4(((helper = (helper = helpers.entitlement_numerator || (depth0 != null ? depth0.entitlement_numerator : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entitlement_numerator","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.entitlement_denominator || (depth0 != null ? depth0.entitlement_denominator : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entitlement_denominator","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.fee_percentage_agreed || (depth0 != null ? depth0.fee_percentage_agreed : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fee_percentage_agreed","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    return "0";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<span title=\""
    + container.escapeExpression(((helper = (helper = helpers.vat_rate || (depth0 != null ? depth0.vat_rate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"vat_rate","hash":{},"data":data}) : helper)))
    + "%\">&#x2714;</span>";
},"11":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.treethorpe_revenue : depth0),{"name":"formatCurrency","hash":{},"data":data}));
},"13":function(container,depth0,helpers,partials,data) {
    return "£0.00";
},"15":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.client_distribution : depth0),{"name":"formatCurrency","hash":{},"data":data}));
},"17":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.client_distribution_before_fees : depth0),{"name":"formatCurrency","hash":{},"data":data}));
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fa_sent : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </td>\n        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pr_nok : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </td>\n        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fa_received : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </td>\n        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disclosure_sent : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </td>\n        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.payment_made : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </td>\n        <td>\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.questionnaire_returned : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </td>\n        <td></td>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.fa_sent : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.pr_nok : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.fa_received : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.disclosure_sent : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.payment_made : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.questonnaire_returned : depth0)) != null ? stack1.created : stack1),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <td class=\"client-pr-nok\">\n          "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.pr_nok : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </td>\n        <td></td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<tr class=\"client-row-closed\" data-toggle=\"collapse\" data-target=\"#client-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"client-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <td><a class=\"drag-handle\"><i class=\"fa fa-bars\"></i></a></td>\n  <td class=\"client-name\">"
    + alias4((helpers.prospectFullName || (depth0 && depth0.prospectFullName) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),true,{"name":"prospectFullName","hash":{},"data":data}))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notificationBadgeCount : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n  <td class=\"client-type-td\">\n    "
    + alias4((helpers.prospectObjectRoleLabel || (depth0 && depth0.prospectObjectRoleLabel) || alias2).call(alias1,depth0,{"name":"prospectObjectRoleLabel","hash":{},"data":data}))
    + "\n  </td>\n  <td class=\"client-entitlement\">\n      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.entitlement_numerator : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </td>\n  <td class=\"client-fee-percentage\">\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fa_received : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "%\n  </td>\n  <td class=\"client-vat\">\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_vat_applicable : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </td>\n  <td class=\"client-treethorpe-revenue\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fa_received : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n  <td class=\"client-distribution\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fa_received : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTableExpanded : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(32, data, 0),"data":data})) != null ? stack1 : "")
    + "</tr>\n<tr class=\"client-row-expanded\">\n  <td colspan=\"13\">\n    <div class=\"collapse\" id=\"client-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n      <div class=\"client-details-container\"></div>\n    </div>\n  </td>\n</tr>";
},"useData":true});
