'use strict';
var MainBodyLayout = require('../views/main-body-layout');
var EmailsMainView = require('../views/emails/emails-main');

var EmailsController = Backbone.Marionette.Controller.extend({
  initialize: function(options) {
    this.app = options.app;
  },
  appRoutes: {
    'email': 'index',
  },
  index: function() {
    // everyone can access the email app, no permission check needed
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'Email' });
    app.mainBody.show(mainBodyLayout);

    var emailsView = new EmailsMainView();
    mainBodyLayout.content.show(emailsView);
    document.title = 'Email';
  }
});

module.exports = EmailsController;
