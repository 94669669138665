// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.revenue || (depth0 != null ? depth0.revenue : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"revenue","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "0.00";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.disbursements || (depth0 != null ? depth0.disbursements : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"disbursements","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-xs btn-default cancel\">Cancel</button> <button type=\"button\" class=\"btn btn-xs btn-primary save\" data-loading-text=\"Saving...\">Save</button>";
},"11":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-success btn-sm save\" data-loading-text=\"Saving...\">Add</button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td>\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control input-sm\" name=\"date\" placeholder=\"DD/MM/YYYY\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n  </div>\n</td>\n<td></td>\n<td></td>\n<td>\n  <div class=\"form-group\">\n    <div class=\"casemanager-container\"></div>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control input-sm\" name=\"revenue\" type=\"number\" placeholder=\"Revenue...\" value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.revenue : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control input-sm\" name=\"disbursements\" type=\"number\" placeholder=\"Disbursements...\" value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disbursements : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <textarea class=\"form-control input-sm\" name=\"description\" placeholder=\"Description...\" rows=\"1\" maxlength=\"140\">"
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</textarea>\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control\" type=\"checkbox\" name=\"is_at_risk\" value=\"1\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_at_risk : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n  </div>\n</td>\n<td>\n  <div class=\"form-group\">\n    <input class=\"form-control\" type=\"checkbox\" name=\"is_csoa_raised\" value=\"1\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_csoa_raised : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n  </div>\n</td>\n<td></td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
