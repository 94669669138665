'use strict';

var template = require('../../../templates/reporting/company-revenue-report.hbs');
var Spinner = require('spin');
var _ = require('lodash');
var moment = require('moment');
var BookingRecords = require('../../../models/reporting/bookingrecords');
var CaseFiles = require('../../../models/cases/casefiles');
var UserTargets = require('../../../models/reporting/usertargets');
var CompanyTargets = require('../../../models/reporting/companytargets');
var TeamTargets = require('../../../models/reporting/teamtargets');
var TeamTarget = require('../../../models/reporting/teamtarget');
var Teams = require('../../../models/users/teams');

var CompanyRevenueReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'company-revenue-report',
  ui: {
    year: '.year',
    submit: '.submit-btn',
    reportingArea: '.reporting-area',
    team: '.team',
    teamName: '.team-name',
  },
  behaviors: {
    SubmitForm: {
      submitHandler: 'generateReport',
    },
    Validate: {
      validateOptions: {
        rules: {
          year: {
            required: true,
            number: true,
          },
        },
      },
    },
  },
  initialize: function() {
    this.teams = new Teams();
    this.userTargets = new UserTargets();
    this.companyTargets = new CompanyTargets();
    this.teamTargets = new TeamTargets();
    this.bookingRecords = new BookingRecords();
    this.bookingAssetRecords = new BookingRecords();
    this.bookingLARecords = new BookingRecords();
    this.casefiles = new CaseFiles();

    this.listenTo(this.teams, 'sync', this.render);
    this.listenTo(TCAS.archiveDataTokens, 'sync', this.render);
    this.teams.fetch();
  },
  generateReport: function(data) {
    var that = this;
    var year = parseInt(this.ui.year.val(), 10);
    this.year = year;
    var users = this.getOption('users');
    this.filteredUsers = users.toArray();
    this.includeInactiveUsers = data.include_inactive === '1'; // jshint ignore:line
    if (!this.includeInactiveUsers) {
      this.filteredUsers = this.filteredUsers.filter(function(user) {
        return user.get('is_active');
      });
    }
    var team = data.team;
    var teamIds = [];
    if (data.team) {
      teamIds = data.team.split(',').map(function(id) {
        return parseInt(id, 10);
      });
    }
    this.selectedTeams = teamIds;
    var categories = this.getOption('categories');

    if (teamIds.length) {
      // filter users belonging to any team
      var teamAllocations = this.getOption('teamAllocations');
      this.filteredUsers = this.filteredUsers.filter(function(user) {
        var matchedTeamAllocations = teamAllocations.filter(function(teamAllocation) {
          var startYear = parseInt(teamAllocation.get('start_date').substr(0, 4));
          var endYear = parseInt(teamAllocation.get('end_date').substr(0, 4));
          return (
            teamAllocation.get('user') === user.get('id') &&
            year >= startYear &&
            year <= endYear &&
            teamIds.indexOf(teamAllocation.get('team')) !== -1
          );
        });
        return matchedTeamAllocations.length;
      });

      var teamNames = this.teams
        .filter(function(team) {
          return teamIds.indexOf(team.get('id')) !== -1;
        })
        .map(function(team) {
          return team.get('name');
        })
        .join(', ');

      // set the team name in heading
      this.teamNames = teamNames;
    } else {
      this.teamNames = null;
      this.ui.teamName.text('').addClass('hidden');
    }

    // 1st january this year to next year
    var startDate = new Date(year, 0, 1);
    var endDate = moment(new Date(year, 0, 1))
      .endOf('year')
      .toDate();

    // fetch all user targets and bookings
    var filters = {
      date_after: moment(startDate).format('DD/MM/YYYY'),
      date_before: moment(endDate).format('DD/MM/YYYY'),
      is_active: true,
      page_size: 10000,
    };
    if (teamIds.length) {
      filters.team__in = teamIds.join(','); // jshint ignore:line
    }
    this.bookingRecords.reset();
    this.bookingAssetRecords.reset();
    this.bookingLARecords.reset();
    this.userTargets.reset();
    this.teamTargets.reset();
    this.companyTargets.reset();
    this.casefiles.reset();
    this.bookingRecords.setFilters(filters);
    this.userTargets.setFilters({ page_size: 10000, year: year });

    this.ui.submit.button('loading');

    $.when(
      this.userTargets.fetch(),
      this.teamTargets.fetch(),
      this.companyTargets.fetch(),
      this.bookingRecords.fetch()
    ).done(function() {
      var caseIds = that.bookingRecords.map(function(bookingRecord) {
        return bookingRecord.get('case');
      });
      caseIds = _.uniq(caseIds);
      that.casefiles.setFilters({
        id__in: caseIds.length ? caseIds.join(',') : 0,
        is_active: true,
        page_size: 10000,
      }); // jshint ignore:line
      that.casefiles.fetch().then(function() {
        // separate cases and booking records into LA and asset collections
        that.casefiles.each(function(bookedCase) {
          var category = categories.get(bookedCase.get('category'));
          var categoryGroupId = category.get('category_group');
          var filteredBookingRecords = that.bookingRecords.where({
            case: bookedCase.get('id'),
          });
          if ([2, 3].indexOf(categoryGroupId) === -1) {
            that.bookingAssetRecords.add(filteredBookingRecords);
          } else {
            that.bookingLARecords.add(filteredBookingRecords);
          }
        });

        that.render();
        that.ui.reportingArea.removeClass('hidden');
        that.ui.submit.button('reset');
      });
    });
  },
  onRender: function() {
    // set the date filters to this year
    var today = new Date();
    if (!this.year) {
      this.year = today.getFullYear();
    }
    this.ui.year.val(this.year);

    var teamOptions = {
      data: this.teams.map(function(team) {
        return {
          id: team.get('id'),
          text: team.get('name'),
        };
      }),
      multiple: true,
    };
    this.ui.team.val(this.selectedTeams).select2(teamOptions);
  },
  serializeData: function() {
    var data = {
      teams: this.teams.toJSON(),
      includeInactiveUsers: this.includeInactiveUsers,
    };
    var bookingAssetRecords = this.bookingAssetRecords;
    var bookingLARecords = this.bookingLARecords;
    var userTargets = this.userTargets;
    var year = this.year;
    var collectiveTarget = this.companyTargets.find(function(target) {
      return target.get('year') === year;
    });

    if (!TCAS.session.hasPerm('filter_company_report_team')) {
      data.isTeamDisabled = true;
      var userId = TCAS.session.get('id');
      var teamAllocation = this.getOption('teamAllocations').find(function(teamAllocation) {
        return teamAllocation.get('user') === userId;
      });
      if (teamAllocation) {
        this.selectedTeams = [teamAllocation.get('team')];
      } else {
        this.selectedTeams = [];
      }
      if (this.teams.length) {
        this.teamName = this.teams.get(this.selectedTeams[0]).get('name');
      }
    }

    if (this.teamNames) {
      data.teamNames = this.teamNames;
      data.selectedTeams = this.selectedTeams;
      if (this.selectedTeams.length === 1) {
        collectiveTarget = this.teamTargets.find(function(target) {
          return target.get('team') === data.selectedTeams[0] && target.get('year') === year;
        });
      }

      if (!collectiveTarget) {
        // team target doesn't exist, use blank one
        collectiveTarget = new TeamTarget({
          performance_revenue: 0,
          stretch_revenue: 0,
          average_asset_case_size: 0,
          average_la_case_size: 0,
          booked_asset_volume: 0,
          booked_la_volume: 0,
        });
      }
    }

    var totalAssetRevenueByMonth = [];
    var totalLARevenueByMonth = [];
    var totalRevenueByMonth = [];
    var totalRevenueYTD = [];
    var totalPhasedTarget = [];
    var totalPhasedStretch = [];
    var totalPhasedTargetPercentage = [];
    var totalPhasedStretchPercentage = [];
    for (var i = 0; i < 12; i += 1) {
      totalAssetRevenueByMonth[i] = 0;
      totalLARevenueByMonth[i] = 0;
      totalRevenueByMonth[i] = 0;
      totalRevenueYTD[i] = 0;
      if (collectiveTarget) {
        totalPhasedTarget[i] = (collectiveTarget.get('performance_revenue') / 12) * (i + 1);
        totalPhasedStretch[i] = (collectiveTarget.get('stretch_revenue') / 12) * (i + 1);
      }
      totalPhasedTargetPercentage[i] = 0;
      totalPhasedStretchPercentage[i] = 0;
    }

    if (this.filteredUsers) {
      var usersData = this.filteredUsers.map(function(user) {
        var userId = user.get('id');
        var userTarget = userTargets.find(function(userTarget) {
          return userTarget.get('user') === userId;
        });
        var assetRevenueByMonth = [];
        var laRevenueByMonth = [];
        var revenueYTD = [];
        var phasedTarget = [];
        var phasedStretch = [];

        for (var i = 0; i < 12; i += 1) {
          assetRevenueByMonth[i] = 0;
          laRevenueByMonth[i] = 0;
          revenueYTD[i] = 0;
          phasedTarget[i] = 0;
          phasedStretch[i] = 0;
        }

        bookingAssetRecords.forEach(function(bookingRecord) {
          if (bookingRecord.get('case_manager') === userId) {
            var created = new Date(bookingRecord.get('date'));
            assetRevenueByMonth[created.getMonth()] += bookingRecord.get('revenue');
          }
        });
        bookingLARecords.forEach(function(bookingRecord) {
          if (bookingRecord.get('case_manager') === userId) {
            var created = new Date(bookingRecord.get('date'));
            laRevenueByMonth[created.getMonth()] += bookingRecord.get('revenue');
          }
        });

        for (i = 0; i < 12; i += 1) {
          revenueYTD[i] = assetRevenueByMonth[i] + laRevenueByMonth[i];
          if (i > 0) {
            revenueYTD[i] += revenueYTD[i - 1];
          }

          totalAssetRevenueByMonth[i] += assetRevenueByMonth[i];
          totalLARevenueByMonth[i] += laRevenueByMonth[i];
          totalRevenueByMonth[i] += assetRevenueByMonth[i] + laRevenueByMonth[i];
        }

        if (userTarget) {
          var phasedTargetPerMonth = userTarget.get('performance_revenue') / 12;
          var phasedStretchPerMonth = userTarget.get('stretch_revenue') / 12;
          for (i = 0; i < 12; i += 1) {
            phasedTarget[i] = revenueYTD[i] / (phasedTargetPerMonth * (i + 1));
            phasedStretch[i] = revenueYTD[i] / (phasedStretchPerMonth * (i + 1));
          }
        }

        return {
          user: user.toJSON(),
          assetRevenueByMonth: assetRevenueByMonth,
          laRevenueByMonth: laRevenueByMonth,
          revenueYTD: revenueYTD,
          phasedTarget: phasedTarget,
          phasedStretch: phasedStretch,
        };
      });
    }

    // compute totalRevenueYTD from totalRevenueByMonth
    for (i = 0; i < 12; i += 1) {
      totalRevenueYTD[i] = totalRevenueByMonth[i];
      if (i > 0) {
        totalRevenueYTD[i] += totalRevenueYTD[i - 1];
      }

      // computer percentage against targets
      if (totalPhasedTarget[i]) {
        totalPhasedTargetPercentage[i] = totalRevenueYTD[i] / totalPhasedTarget[i];
      } else {
        totalPhasedTargetPercentage[i] = 0;
      }

      if (totalPhasedStretch[i]) {
        totalPhasedStretchPercentage[i] = totalRevenueYTD[i] / totalPhasedStretch[i];
      } else {
        totalPhasedStretchPercentage[i] = 0;
      }
    }

    data.usersData = usersData;
    data.totalAssetRevenueByMonth = totalAssetRevenueByMonth;
    data.totalLARevenueByMonth = totalLARevenueByMonth;
    data.totalRevenueByMonth = totalRevenueByMonth;
    data.totalRevenueYTD = totalRevenueYTD;
    data.totalPhasedTarget = totalPhasedTarget;
    data.totalPhasedStretch = totalPhasedStretch;
    data.totalPhasedTargetPercentage = totalPhasedTargetPercentage;
    data.totalPhasedStretchPercentage = totalPhasedStretchPercentage;

    var archiveDataToken = TCAS.request('archiveDataToken');
    data.isArchiveDataSession = !!archiveDataToken;

    return data;
  },
});

module.exports = CompanyRevenueReportView;
