'use strict';

var ClientMilestone = require('../../models/cases/clientmilestone');
var MilestoneListView = require('./milestone-list');
var ClientMilestoneView = require('./client-milestone');
var ConfirmModal = require('../confirm-modal');
var Spinner = require('spin');

var ClientMilestoneListView = MilestoneListView.extend({
  childView: ClientMilestoneView,
  initialize: function() {
    this.spinner = new Spinner();
  },
  filter: function(model) {
    if (this.getOption('isClient')) {
      return model.get('client_viewable');
    }
    return true;
  },
  applyMilestone: function(childView) {
    this.saveMilestone(childView);
  },
  removeMilestone: function(childView, that) {
    var confirmModal = new ConfirmModal({
      body: 'Are you sure you want to remove this milestone?'
    });
    confirmModal.on('confirmYes', function() {

      var milestone = childView.model.get('milestone');
      milestone.save({is_active: false}, { // jshint ignore:line
        wait: true,
        success:function() {
          childView.model.set({
            checked: false,
            created: null,
            milestone: null
          });
          TCAS.vent.trigger('milestone:change:end');
          that.render();
          that.trigger('milestoneSaved', milestone);
        }
      });
    });
    confirmModal.render();
  },
  notApplicableMilestone: function(childView, that) {
    var milestone = new ClientMilestone({
      client: this.getOption('client').get('id'),
      client_milestone_type: parseInt(childView.model.get('id')), // jshint ignore:line
      owner: TCAS.session.get('id'),
      user: this.getOption('casefile').getCurrentAllocation().get('case_manager'),
      is_not_applicable: true
    });
    milestone.save().then(function() {
      childView.model.set('created', milestone.get('created'));
      childView.model.set('is_not_applicable', true);
      childView.model.set('checked', true);
      childView.model.set('milestone', milestone);
      childView.render();
      that.getOption('client').get('milestones').add(milestone);
      TCAS.vent.trigger('milestone:change:end');
      that.trigger('milestoneSaved', milestone);
    });
  },
  saveMilestone: function(childView) {
    var that = this;
    var milestone = new ClientMilestone({
      client: this.getOption('client').get('id'),
      client_milestone_type: parseInt(childView.model.get('id')), // jshint ignore:line
      owner: TCAS.session.get('id'),
      user: this.getOption('casefile').getCurrentAllocation().get('case_manager')
    });
    milestone.save().then(function() {
      childView.model.set('created', milestone.get('created'));
      childView.model.set('checked', true);
      childView.model.set('milestone', milestone);
      childView.render();
      that.getOption('client').get('milestones').add(milestone);
      TCAS.vent.trigger('milestone:change:end', milestone);
      that.trigger('milestoneSaved', milestone);
    });
  }
});

module.exports = ClientMilestoneListView;
