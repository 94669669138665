'use strict';

var _ = require('lodash');
var template =
  require('../../../templates/reporting/company-activity-research-table.hbs');

var CompanyActivityResearchTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var data = [];
    var researchMilestones = this.getOption('researchMilestones');
    var userTargets = this.getOption('userTargets');
    var users = this.getOption('users');

    // for each user compute the number of research cases and ytd
    users.forEach(function(user) {
      var userId = user.get('id');
      var userTarget = userTargets.find(function(target) {
        return target.get('user') === userId;
      });
      var userResearchMilestones = researchMilestones.filter(function(milestone) {
        return milestone.get('user') === userId;
      });
      var researchCases = [];
      for (var i = 0; i < 12; i += 1) {
        researchCases[i] = [];
      }

      // group case ids by month
      userResearchMilestones.forEach(function(milestone) {
        var created = new Date(milestone.get('created'));
        researchCases[created.getMonth()].push(milestone.get('case'));
      });

      var researchByMonth = researchCases.map(function(researchCasesInMonth) {
        return _.unique(researchCasesInMonth).length;
      });
      var researchYTD = [];
      var targetResearchYTD = [];
      var targetResearchPerMonth = 0;
      if (userTarget) {
        targetResearchPerMonth = userTarget.get('research_reports_per_month');
      }

      var total = 0;
      for (i = 0; i < 12; i += 1) {
        total += researchByMonth[i];
        researchYTD[i] = total;
        targetResearchYTD[i] = targetResearchPerMonth * (i + 1);
      }
      var rowData = {
        user: user,
        researchByMonth: researchByMonth,
        researchYTD: researchYTD,
        targetResearchPerMonth: targetResearchPerMonth,
        targetResearchYTD: targetResearchYTD
      };
      data.push(rowData);
    });

    // inform parent view that data is ready for use in the summary table,
    // trigger(event, <array of data rows>, <section>, <field map>)
    this.trigger(
      'dataReady',
      data,
      'research',
      { researchByMonth: 'researchByMonth', researchYTD: 'researchYTD' }
    );

    return data;
  }
});

module.exports = CompanyActivityResearchTableView;
