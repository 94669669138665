var Request = require('./request');
var BaseCollection = require('../base-collection');

var Requests = BaseCollection.extend({
  urlRoot: '/api/requests/',
  model: Request,
  comparator: function(request){
    return -request.get('created').getTime();
  }
});

module.exports = Requests;