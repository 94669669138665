// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"correspondence-file-preview\">\n  <div class=\"row\">\n    <div class=\"col-xs-8\">\n      <a href=\""
    + alias4(((helper = (helper = helpers.file_download || (depth0 != null ? depth0.file_download : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_download","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.original_file_name || (depth0 != null ? depth0.original_file_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"original_file_name","hash":{},"data":data}) : helper)))
    + "</a>\n    </div>\n    <div class=\"col-xs-4\">\n      <button type=\"button\" class=\"btn btn-xs btn-danger remove\">\n        Remove\n      </button>\n    </div>\n  </div>\n</div>";
},"useData":true});
