'use strict';

var template = require('../../templates/allocations/allocation.hbs');

var AllocationView = Backbone.Marionette.ItemView.extend({
  className: 'allocation',
  tagName: 'tr',
  template: template,
  initialize: function(){
    if (this.model.get('active')) {
      this.$el.addClass('success');
    }
  },
  serializeData: function(){
    var that = this;
    var data = this.model.toJSON();
    var caseManager = TCAS.users.get(this.model.get('case_manager'));
    if (caseManager) {
      data.supervisor = TCAS.reqres.request('supervisorId', caseManager.get('id'));
    } else {
      TCAS.request('user', this.model.get('case_manager')).then(function(user){
        that.render();
      });
    }
    return data;
  }
});

module.exports = AllocationView;