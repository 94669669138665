// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panel panel-default\">\n    <div class=\"panel-heading\" data-toggle=\"collapse\" data-target=\"#add-template-collapse\">\n        <h4 class=\"panel-title\">Add Template</h4>\n    </div>\n    <div id=\"add-template-collapse\" class=\"panel-collapse collapse in\">\n        <div id=\"add-template-region\" class=\"panel-body\">\n            Some Text\n        </div>\n    </div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showCreate : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"panel panel-default\">\n    <div class=\"panel-heading\" data-toggle=\"collapse\" data-target=\"#templates-collapse\">\n        <h4 class=\"panel-title\">Templates</h4>\n    </div>\n\n    <div id=\"templates-collapse\" class=\"panel-collapse collapse in\">\n        <div class=\"panel-body\">\n            <form class=\"form-inline pull-right\">\n\n                <label>Grouping: </label>\n                <select class=\"form-control grouping-filter\">\n                </select>\n\n                <label>Category: </label>\n                <select class=\"form-control category-filter\">\n                    <option value=\"None\">Select Grouping</option>\n                </select>\n\n                <label>Description: </label>\n                <select class=\"form-control description-filter\">\n                    <option value=\"None\">Select Category</option>\n                </select>\n            </form>\n            <div id=\"list-templates-region\"></div>\n        </div>\n    </div>\n</div>";
},"useData":true});
