'use strict';
var template = require('../../templates/caseload/caseload-row.hbs');
var CaseLoad = require('../../models/cases/caseload');

var CaseLoadRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  className: 'caseload-row',
  model: CaseLoad
});

module.exports = CaseLoadRowView;