'use strict';

var template = require('../../templates/costs/cost-row-total.hbs');

var CostRowTotalView = Backbone.Marionette.ItemView.extend({
  className: 'cost cost-total',
  tagName: 'tr',
  template: template,
  collectionEvents: {
    'add': 'render',
    'remove': 'render',
    'sync': 'render'
  },
  serializeData: function(){
    var totalAmount = 0, totalVAT = 0;
    var filterType = this.getOption('filterType');
    this.collection.each(function(cost){
      if (cost.get('type') === filterType) {
        totalAmount += cost.get('amount');
        totalVAT += cost.get('vat');
      }
    });
    var data = {
      totalAmount: totalAmount,
      totalVAT: totalVAT
    };
    return data;
  }
});

module.exports = CostRowTotalView;