'use strict';

var template = require('../../templates/users/user-target-list.hbs');
var UserTargetRowView = require('./user-target-row');

var UserTargetListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: UserTargetRowView,
  childViewContainer: 'tbody',
  filter: function (child) {
    // only show targets for the specified year
    var year = this.getOption('year');
    return child.get('year') === year;
  }
});

module.exports = UserTargetListView;
