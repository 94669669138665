'use strict';

var template = require('../../../templates/reporting/report-individual-monthly.hbs');
var Spinner = require('spin');
var moment = require('moment');
var CaseManagerSelectView = require('../../allocations/casemanager-select');
var IndividualMonthlyResearchListView = require('./individual-monthly-research-list');
var IndividualMonthlyInClaimsListView = require('./individual-monthly-in-claims-list');
var IndividualMonthlyDisclosureSentListView = require('./individual-monthly-disclosure-sent-list');
var IndividualMonthlyBookedListView = require('./individual-monthly-booked-list');
var IndividualMonthlyConcludedListView = require('./individual-monthly-concluded-list');
var CaseFiles = require('../../../models/cases/casefiles');
var Milestones = require('../../../models/cases/milestones');
var BookingRecords = require('../../../models/reporting/bookingrecords');

var IndividualMonthlyReportView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'individual-monthly-report',
  ui: {
    year: '.year',
    month: '.month',
    submit: '.submit-btn',
    alertArea: '#alert-area',
    user: '#user',
    reportingArea: '.reporting-area',
  },
  events: {
    'change @ui.year': 'checkInputs',
    'change @ui.month': 'checkInputs',
  },
  regions: {
    caseManagerRegion: '.casemanager-select-container',
    researchRegion: '.research-table-container',
    inClaimsRegion: '.in-claims-table-container',
    disclosureRegion: '.disclosure-table-container',
    bookedLARegion: '.booked-la-table-container',
    bookedAssetRegion: '.booked-asset-table-container',
    concludedRegion: '.concluded-table-container',
  },
  behaviors: {
    SubmitForm: {
      submitHandler: 'generateReport',
    },
    Validate: {
      validateOptions: {
        rules: {
          year: {
            required: true,
            number: true,
          },
          case_manager: {
            // jshint ignore:line
            required: true,
          },
        },
      },
    },
  },
  initialize: function() {
    this.listenTo(TCAS.archiveDataTokens, 'sync', this.render);
  },
  checkInputs: function() {
    this.ui.submit.prop(
      'disabled',
      this.ui.year.val() === '' ||
        this.ui.month.val() === '' ||
        this.caseManagerSelectView.getSelectedCaseManager() === ''
    );
  },
  generateReport: function() {
    var year = parseInt(this.ui.year.val(), 10);
    var month = parseInt(this.ui.month.val(), 10);
    var user = this.caseManagerSelectView.getSelectedCaseManager();

    var startDate = new Date(year, month);
    var endDate = moment(new Date(year, month))
      .endOf('month')
      .toDate();

    this.refreshResearch(startDate, endDate, user);
    this.refreshInClaims(startDate, endDate, user);
    this.refreshDisclosure(startDate, endDate, user);
    this.refreshBooked(startDate, endDate, user);
    this.refreshConcluded(startDate, endDate, user);
    this.ui.reportingArea.removeClass('hidden');
  },
  onTopDataSync: function(collection) {
    // one of the collections just synced, ensure at least this amount of rows
    // are visible in the tables
    this.researchTable.setMinRows(collection.length);
    this.inClaimsTable.setMinRows(collection.length);
    this.disclosureTable.setMinRows(collection.length);
  },
  onBottomDataSync: function(collection) {
    // one of the collections just synced, ensure at least this amount of rows
    // are visible in the tables
    this.bookedLATable.setMinRows(collection.length);
    this.bookedAssetTable.setMinRows(collection.length);
    this.concludedTable.setMinRows(collection.length);
  },
  refreshResearch: function(startDate, endDate, user) {
    var that = this;

    // fetch all research signed off (pk 6) milestones for this user
    var researchMilestones = new Milestones();
    var researchCases = new CaseFiles();
    var researchCaseMilestones = new Milestones();
    researchMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 6,
      page_size: 10000,
      is_active: true,
    });
    researchMilestones.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these milestones
        // as well as all other milestones for the case
        var caseIds = researchMilestones.map(function(milestone) {
          return milestone.get('case');
        });
        researchCaseMilestones.setFilters({
          case__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000, // jshint ignore:line
          is_active: true,
        });

        researchCases.setFilters({
          id__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000,
          is_active: true,
        });
        researchCaseMilestones.fetch();
        researchCases.fetch();
      },
    });
    this.researchTable = new IndividualMonthlyResearchListView({
      collection: researchCases,
      milestones: researchCaseMilestones,
    });
    this.listenTo(researchCases, 'sync', this.onTopDataSync);
    this.researchRegion.show(that.researchTable);
  },
  refreshInClaims: function(startDate, endDate, user) {
    var that = this;

    // fetch all research signed off (pk 6) milestones for this user
    var inClaimsMilestones = new Milestones();
    var inClaimsCases = new CaseFiles();
    var inClaimsCaseMilestones = new Milestones();
    inClaimsMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 13,
      page_size: 10000,
      is_active: true,
    });
    inClaimsMilestones.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these milestones
        // as well as all other milestones for the case
        var caseIds = inClaimsMilestones.map(function(milestone) {
          return milestone.get('case');
        });
        inClaimsCaseMilestones.setFilters({
          case__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000,
          is_active: true,
        });
        inClaimsCaseMilestones.fetch();
        inClaimsCases.setFilters({
          id__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000,
          is_active: true,
        });
        inClaimsCases.fetch();
      },
    });
    this.inClaimsTable = new IndividualMonthlyInClaimsListView({
      collection: inClaimsCases,
      milestones: inClaimsCaseMilestones,
      year: startDate.getFullYear(),
    });
    this.listenTo(inClaimsCases, 'sync', this.onTopDataSync);
    this.inClaimsRegion.show(this.inClaimsTable);
  },
  refreshDisclosure: function(startDate, endDate, user) {
    var that = this;

    // fetch all research signed off (pk 6) milestones for this user
    var disclosureMilestones = new Milestones();
    var disclosureCases = new CaseFiles();
    var disclosureCaseMilestones = new Milestones();
    disclosureMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 26,
      page_size: 10000,
      is_active: true,
    });
    disclosureMilestones.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these milestones
        // as well as all other milestones for the case
        var caseIds = disclosureMilestones.map(function(milestone) {
          return milestone.get('case');
        });
        disclosureCaseMilestones.setFilters({
          case__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000,
          is_active: true,
        });
        disclosureCaseMilestones.fetch();
        disclosureCases.setFilters({
          id__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000,
          is_active: true,
        });
        disclosureCases.fetch();
      },
    });
    this.disclosureTable = new IndividualMonthlyDisclosureSentListView({
      collection: disclosureCases,
      milestones: disclosureCaseMilestones,
    });
    this.listenTo(disclosureCases, 'sync', this.onTopDataSync);
    this.disclosureRegion.show(this.disclosureTable);
  },
  refreshBooked: function(startDate, endDate, user) {
    var that = this;
    var categories = this.getOption('categories');

    // fetch all booked entries from case financials for this user
    var bookingRecords = new BookingRecords();
    var bookingLARecords = new BookingRecords();
    var bookingAssetRecords = new BookingRecords();
    var bookingCases = new CaseFiles();
    bookingRecords.setFilters({
      date_after: moment(startDate).format('DD/MM/YYYY'),
      date_before: moment(endDate).format('DD/MM/YYYY'),
      case_manager: user,
      is_active: true,
      page_size: 10000,
    });
    bookingRecords.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these records
        var caseIds = bookingRecords.map(function(bookingRecord) {
          return bookingRecord.get('case');
        });
        bookingCases.setFilters({
          id__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000,
          is_active: true,
        });
        bookingCases.fetch({
          success: function() {
            // separate cases and booking records into LA and asset collections
            bookingCases.each(function(bookedCase) {
              var category = categories.get(bookedCase.get('category'));
              var categoryGroupId = category.get('category_group');
              var filteredBookingRecords = bookingRecords.where({
                case: bookedCase.get('id'),
              });
              if ([2, 3].indexOf(categoryGroupId) === -1) {
                bookingAssetRecords.add(filteredBookingRecords);
              } else {
                bookingLARecords.add(filteredBookingRecords);
              }
            });
            that.onBottomDataSync(
              bookingAssetRecords.length > bookingLARecords.length
                ? bookingAssetRecords
                : bookingLARecords
            );
          },
        });
      },
    });
    this.bookedLATable = new IndividualMonthlyBookedListView({
      collection: bookingLARecords,
      casefiles: bookingCases,
    });
    this.bookedAssetTable = new IndividualMonthlyBookedListView({
      collection: bookingAssetRecords,
      casefiles: bookingCases,
    });
    this.bookedLARegion.show(this.bookedLATable);
    this.bookedAssetRegion.show(this.bookedAssetTable);
  },
  refreshConcluded: function(startDate, endDate, user) {
    var that = this;

    // fetch all concluded (pk 35) milestones for this user
    var concludedMilestones = new Milestones();
    var bookingRecords = new BookingRecords();
    var concludedCases = new CaseFiles();
    concludedMilestones.setFilters({
      created_after: moment(startDate).format('DD/MM/YYYY'),
      created_before: moment(endDate).format('DD/MM/YYYY'),
      user: user,
      milestone_type: 35,
      page_size: 10000,
      is_active: true,
    });
    concludedMilestones.fetch({
      success: function() {
        // now fetch the corresponding casefiles for these milestones
        // as well as all other milestones for the case
        var caseIds = concludedMilestones.map(function(milestone) {
          return milestone.get('case');
        });
        concludedCases.setFilters({
          id__in: caseIds.length ? caseIds.join(',') : 0,
          page_size: 10000,
          is_active: true,
        });
        concludedCases.fetch();
        bookingRecords.setFilters({
          case_manager: user,
          cases__in: caseIds.length ? caseIds : 0,
          page_size: 10000,
          is_active: true,
        });
        bookingRecords.fetch();
      },
    });
    this.concludedTable = new IndividualMonthlyConcludedListView({
      collection: concludedCases,
      bookingRecords: bookingRecords,
      concludedMilestones: concludedMilestones,
    });
    this.listenTo(concludedCases, 'sync', this.onBottomDataSync);
    this.concludedRegion.show(this.concludedTable);
  },
  onRender: function() {
    // disable filtering users unless the session user has permission
    var selectOptions = {};
    if (!TCAS.session.hasPerm('filter_individual_report_user')) {
      selectOptions.user = TCAS.session.get('id');
      selectOptions.disabled = true;
    }
    if (!TCAS.session.hasPerm('filter_pipeline_report_team')) {
      selectOptions.team = TCAS.session.get('userdetail').team;
    }
    this.caseManagerSelectView = new CaseManagerSelectView(selectOptions);
    this.listenTo(this.caseManagerSelectView, 'selected', this.checkInputs);
    this.caseManagerRegion.show(this.caseManagerSelectView);

    // set the date filters to this month
    var today = new Date();
    this.ui.month.val(today.getMonth());
    this.ui.year.val(today.getFullYear());

    this.checkInputs();
  },
  serializeData: function() {
    var archiveDataToken = TCAS.request('archiveDataToken');
    var data = { isArchiveDataSession: !!archiveDataToken };
    return data;
  },
});

module.exports = IndividualMonthlyReportView;
