// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <th>Remove</th>\n        <th></th>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"table-responsive\">\n  <table class=\"table milestones\">\n    <thead>\n      <tr>\n        <th class=\"col-xs-3\">Date</th>\n        <th class=\"col-xs-6\">Milestone</th>\n        <th class=\"col-xs-3\">Achieved</th>\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isClient : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </tr>\n    </thead>\n    <tbody></tbody>\n  </table>\n</div>";
},"useData":true});
