'use strict';

var template = require('../templates/modal-info.hbs');

var InfoModal = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'modal fade',
  initialize: function(){
    this.$el.attr('tabindex', '-1');
    this.$el.attr('role', 'dialog');
    this.$el.attr('aria-labelledby', 'info-modal-label');
    this.$el.attr('aria-hidden', 'true');
  },
  serializeData: function(){
    return this.options;
  },
  onRender: function(){
    this.$el.appendTo('body');
    this.$el.modal({
      backdrop: 'static'
    });
  }
});

module.exports = InfoModal;