'use strict';

var BaseCollection = require('../base-collection');
var SensitiveDataToken = require('./sensitivedatatoken');

var SensitiveDataTokens = BaseCollection.extend({
  model: SensitiveDataToken,
  urlRoot: '/api/sensitivedatatokens/',
  comparator: function(token) {
    var date = new Date(token.get('created'));
    return -date.getTime();
  },
  getValidToken: function() {
    if (this.length > 0 && this.at(0).isValid()) {
      return this.at(0).get('token');
    }
    return null;
  }
});

module.exports = SensitiveDataTokens;
