'use strict';
var BaseCollection = require('../base-collection');
var BookingRecord = require('./bookingrecord');

var BookingRecords = BaseCollection.extend({
  model: BookingRecord,
  urlRoot: '/api/bookingrecords/',
  comparator: function(bookingRecord){
    return new Date(bookingRecord.get('date')).getTime();
  }
});

module.exports = BookingRecords;
