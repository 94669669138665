'use strict';
var template = require('../../templates/cases/request-row.hbs');
var Request = require('../../models/cases/request');
var CaseFile = require('../../models/cases/casefile');
var User = require('../../models/users/user');

var RequestRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click @ui.approveBtn': 'approve',
    'click @ui.denyBtn': 'deny',
    'click .popout-case': 'popoutCase',
    'click .popout-link': 'popoutLink'
  },
  ui: {
    approveBtn: 'button.approve',
    denyBtn: 'button.deny'
  },
  popoutCase: function(e){
    e.preventDefault();
    TCAS.commands.execute('popoutCase', this.model.get('case'));

    return false;
  },
  popoutLink: function(e) {
    e.preventDefault();
    TCAS.commands.execute('popoutURL', this.$(e.currentTarget).attr('href'), null, 1024, 800);

    return false;
  },
  approve: function(){
    var that = this;
    this.ui.approveBtn.button('loading');

    // find current case allocation to assign the correct user
    var casefile = new CaseFile({ id: this.model.get('case') });
    casefile.fetch({
      success: function(casefile) {
        casefile.fetchAllocations().then(function(){
          var allocation = casefile.getCurrentAllocation();

          that.model.save({'status': Request.STATUSES.APPROVED,
            request_type: Request.TYPES.CASE, // jshint ignore:line
            actioned_by: TCAS.session.get('id'), // jshint ignore:line
            user: allocation.get('case_manager') // jshint ignore:line
          }, {
            success: function(){
              that.render();
            }
          });
        });
      }
    });


  },
  deny: function(){
    var that = this;
    this.ui.denyBtn.button('loading');

    // find current case allocation to assign the correct user
    var casefile = new CaseFile({ id: this.model.get('case') });

    casefile.fetch({
      success: function(casefile) {
        casefile.fetchAllocations().then(function(){
          var allocation = casefile.getCurrentAllocation();

          that.model.save({
            status: Request.STATUSES.DENIED,
            request_type: Request.TYPES.CASE, // jshint ignore:line
            actioned_by: TCAS.session.get('id'), // jshint ignore:line
            user: allocation.get('case_manager') // jshint ignore:line
          }, {
            wait: true,
            success: function(){
              that.render();
            }
          });
        });
      }
    });
  },
  serializeData: function(){
    var data = this.model.toJSON();
    var requestStatusMap = _.invert(Request.STATUSES);
    var statusName = requestStatusMap[this.model.get('status')].toLowerCase();
    data.statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
    data.isPending = this.model.get('status') === Request.STATUSES.PENDING;
    if (_.contains(TCAS.session.get('groups'), User.GROUPS.TEAM_SECOND_LEADER)) {
      data.canApproveThisRequest = this.model.get('user') !== TCAS.session.get('id');
    } else {
      data.canApproveThisRequest = true;
    }

    switch (this.model.get('action')) {
      case Request.ACTIONS.SIGN_OFF:
        data.request = 'Sign off on research report';
        break;
      case Request.ACTIONS.CONDITIONS_OF_APPROVAL_REPORT:
        data.request = 'Conditions of approval report Sign off';
        break;
      case Request.ACTIONS.CONCLUDE_CASE:
        data.request = 'Conclude case';
        break;
      case Request.ACTIONS.ABANDON_CASE:
        data.request = 'Abandon case';
        break;
      case Request.ACTIONS.UNPOST_RESEARCH:
        data.request = 'Unpost research';
        break;
      case Request.ACTIONS.HAND_BACK:
        data.request = 'Hand back case';
        break;
      case Request.ACTIONS.ALLOW_ADD_ALLOCATION:
        data.request = 'Allow extra allocation';
        break;
      case Request.ACTIONS.IN_CLAIMS:
        data.request = 'Move status to In Claims';
        break;
      case Request.ACTIONS.DISCLOSURE_SENT:
        data.request = 'Set Disclosure Sent';
        break;
      case Request.ACTIONS.CASE_BOOKED:
        data.request = 'Set Case Booked';
        break;
      case Request.ACTIONS.MESSAGE_REVIEW:
        var messageUrl = '/cases/' + this.model.get('case') + '/messages/' +
          this.model.get('message');
        data.request = 'Review a <a class="popout-link" href="'+messageUrl+'">note</a> on a case';
        break;
    }
    return data;
  },
  onRender: function(){
    this.$el.removeClass('success danger');
    if (this.model.get('status') === Request.STATUSES.APPROVED) {
      this.$el.addClass('success');
    } else if (this.model.get('status') === Request.STATUSES.DENIED) {
      this.$el.addClass('danger');
    }
  }
});

module.exports = RequestRowView;