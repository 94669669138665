'use strict';

var MainBodyLayout = require('../views/main-body-layout');
var CalendarView = require('../views/events/calendar')
var CalendarEvents = require('../models/events/calendarevents');
var CalendarEventCategories = require('../models/events/calendareventcategories');
var CalendarEventTypes = require('../models/events/calendareventtypes');
var Reminders = require('../models/cases/reminders');
var Reminder = require('../models/cases/reminder');

var EventsController = Backbone.Marionette.Controller.extend({
  initialize: function(options) {
    this.app = options.app;
  },
  appRoutes: {
    'calendar/day/:date': 'calendarDay',
    'calendar(/:eventType/:eventId)': 'calendar'
  },
  calendarDay: function(date) {
    if (!TCAS.session.hasPerm('view_calendar')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }
    var app = this.getOption('app');
    var calendarEvents = new CalendarEvents();
    calendarEvents.setFilters({
      is_active: true,
      page_size: 10000
    });
    var calendarEventCategories = new CalendarEventCategories();
    var calendarEventTypes = new CalendarEventTypes();
    var reminders = new Reminders();
    reminders.setFilters({
      fao: TCAS.session.get('id'),
      status: 0,
      page_size: 10000
    });

    $.when.apply(
      $,
      [
        calendarEventCategories.fetch(), calendarEventTypes.fetch(), calendarEvents.fetch(),
        reminders.fetch()
      ]
    ).then(function() {
      var mainBodyLayout = new MainBodyLayout({ title: 'Calendar' });
      app.mainBody.show(mainBodyLayout);
      document.title = 'Calendar';

      mainBodyLayout.content.show(new CalendarView({
        calendarEventCategories: calendarEventCategories,
        calendarEventTypes: calendarEventTypes,
        calendarEvents: calendarEvents,
        reminders: reminders,
        date: date
      }));
    }, function() {
      // error occured, just show permission denied
      TCAS.commands.execute('403', Backbone.history.fragment);
    });
  },
  calendar: function (eventType, eventId) {
    if (!TCAS.session.hasPerm('view_calendar')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }
    var app = this.getOption('app');
    var calendarEvents = new CalendarEvents();
    calendarEvents.setFilters({
      is_active: true,
      page_size: 10000
    });
    var calendarEventCategories = new CalendarEventCategories();
    var calendarEventTypes = new CalendarEventTypes();
    var reminders = new Reminders();
    reminders.setFilters({
      fao: TCAS.session.get('id'),
      status: 0,
      page_size: 10000
    });

    $.when.apply(
      $,
      [
        calendarEventCategories.fetch(), calendarEventTypes.fetch(), calendarEvents.fetch(),
        reminders.fetch()
      ]
    ).then(function() {
      var mainBodyLayout = new MainBodyLayout({ title: 'Calendar' });
      app.mainBody.show(mainBodyLayout);
      document.title = 'Calendar';

      var showEvent = null;
      if (eventType === 'reminder') {
        showEvent = reminders.get(parseInt(eventId, 10));
      } else if (eventType === 'event') {
        showEvent = calendarEvents.get(parseInt(eventId, 10));
      }

      mainBodyLayout.content.show(new CalendarView({
        calendarEventCategories: calendarEventCategories,
        calendarEventTypes: calendarEventTypes,
        calendarEvents: calendarEvents,
        reminders: reminders,
        showEvent: showEvent
      }));
    }, function() {
      // error occured, just show permission denied
      TCAS.commands.execute('403', Backbone.history.fragment);
    });
  }
});

module.exports = EventsController;
