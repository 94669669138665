'use strict';

var SurveyPieChartView = require('./survey-pie-chart');
var SurveyTextAnswersTableView = require('./survey-text-answers');

var SurveyReportAnswerList = Backbone.Marionette.CollectionView.extend({
  getChildView: function(question) {
    if (question.get('type') === 'multiple') {
      return SurveyPieChartView;
    } else {
      return SurveyTextAnswersTableView;
    }
  },
  childViewOptions: function(question) {
    var answers = this.getOption('answers').filter(function(answer) {
      return answer.get('question') === question.get('id');
    });
    return {
      question: question,
      answers: answers
    };
  }
});

module.exports = SurveyReportAnswerList;
