'use strict';

var BaseCollection = require('../base-collection');
var CalendarEventInvite = require('./invite');

var CalendarEventInvites = BaseCollection.extend({
  urlRoot: '/api/calendareventinvites/',
  model: CalendarEventInvite
});

module.exports = CalendarEventInvites;
