'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-concluded-row.hbs');
var CaseFile = require('../../../models/cases/casefile');

var IndividualMonthlyConcludedRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click .popout': 'onClickPopoutCase'
  },
  onClickPopoutCase: function() {
    TCAS.commands.execute('popoutCase', this.model);

    return false;
  },
  onRender: function() {
    this.$el.toggleClass('abandoned', this.model.get('status') === CaseFile.STATUS_IDS.ABANDONED);
  },
  serializeData: function() {
    var data = this.model.toJSON();
    var concluded = this.getOption('concludedMilestone');
    if (concluded) {
      data.concluded = concluded.toJSON();
    }
    var bookedTotal = 0;
    this.getOption('bookingRecords').each(function(bookingRecord) {
      if (bookingRecord.get('case') === data.id) {
        bookedTotal += bookingRecord.get('revenue');
      }
    });
    data.bookedTotal = bookedTotal;
    return data;
  }
});

module.exports = IndividualMonthlyConcludedRowView;
