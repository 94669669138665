// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<table>\n  <tbody>\n  <tr>\n    <th>Received:</th>\n    <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n  </tr>\n  <tr>\n    <th>Subject:</th>\n    <td>"
    + alias3(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"subject","hash":{},"data":data}) : helper)))
    + "</td>\n  </tr>\n  </tbody>\n</table>\n<div class=\"email-content\">"
    + alias3(((helper = (helper = helpers.body_content || (depth0 != null ? depth0.body_content : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"body_content","hash":{},"data":data}) : helper)))
    + "</div>";
},"useData":true});
