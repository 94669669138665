// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.user : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td>£"
    + alias3(((helper = (helper = helpers.minimum_revenue || (depth0 != null ? depth0.minimum_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"minimum_revenue","hash":{},"data":data}) : helper)))
    + "</td>\n<td>£"
    + alias3(((helper = (helper = helpers.performance_revenue || (depth0 != null ? depth0.performance_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"performance_revenue","hash":{},"data":data}) : helper)))
    + "</td>\n<td>£"
    + alias3(((helper = (helper = helpers.stretch_revenue || (depth0 != null ? depth0.stretch_revenue : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"stretch_revenue","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.research_reports_per_month || (depth0 != null ? depth0.research_reports_per_month : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"research_reports_per_month","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.cases_booked_per_month || (depth0 != null ? depth0.cases_booked_per_month : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"cases_booked_per_month","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.asset_case_conversion_rate || (depth0 != null ? depth0.asset_case_conversion_rate : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"asset_case_conversion_rate","hash":{},"data":data}) : helper)))
    + "%</td>\n<td>"
    + alias3(((helper = (helper = helpers.la_case_conversion_rate || (depth0 != null ? depth0.la_case_conversion_rate : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"la_case_conversion_rate","hash":{},"data":data}) : helper)))
    + "%</td>";
},"useData":true});
