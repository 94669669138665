'use strict';
var MainBodyLayout = require('../views/main-body-layout');
var WebsitesPageView = require('../views/websites/websites-page');
var WebsitesTableView = require('../views/websites/websites-table');
var Websites = require('../models/websites/websites');

var WebsitesController = Backbone.Marionette.Controller.extend({
  initialize: function (options) {
    this.app = options.app;
  },
  appRoutes: {
    'websites/admin(/)': 'manageWebsites',
    'websites(/)': 'websitesPage'
  },
  websitesPage: function() {
    var mainBodyLayout = new MainBodyLayout({title: 'Useful Links'});
    this.app.mainBody.show(mainBodyLayout);
    document.title = 'Useful Links';

    var websites = new Websites();
    websites.fetch().then(function() {
      var websitesPage = new WebsitesPageView({ collection: websites });
      mainBodyLayout.content.show(websitesPage);
    });
  },
  manageWebsites: function() {
    var mainBodyLayout = new MainBodyLayout({title: 'Useful Links'});
    this.app.mainBody.show(mainBodyLayout);
    document.title = 'Useful Links';

    var websites = new Websites();
    websites.fetch().then(function() {
      var websitesTable = new WebsitesTableView({ collection: websites });
      mainBodyLayout.content.show(websitesTable);
    });
  }
});

module.exports = WebsitesController;
