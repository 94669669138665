'use strict';

var math = require('mathjs');
var Spinner = require('spin');
var Sortable = require('sortablejs');
var template = require('../../templates/clients/client-list.hbs');
var ClientRowView = require('./client-row');
var User = require('../../models/users/user');
var Contact = require('../../models/users/contact');
var CaseProspect = require('../../models/cases/caseprospect');
var Util = require('../../util');

math.config({
  number: 'Fraction',
});

function printFraction(value) {
  return math.format(value, { fraction: 'ratio' });
}

function comparatorFamily(clients, clientRelations) {
  var clientRelationMap = {};
  clients.forEach(function(client) {
    clientRelationMap[client.get('id') + ''] = {
      client: client,
      mother: null,
      father: null,
      spouse: null,
    };
  });
  for (var i = 0; i < clientRelations.length; i += 1) {
    var relationship = clientRelations.at(i);
    clientRelationMap[relationship.get('person_b') + ''][relationship.get('relationship_type')] =
      clientRelationMap[relationship.get('person_a') + ''];
  }

  return function(client) {
    var relationInfo = clientRelationMap[client.get('id') + ''];
    var info = relationInfo;
    var lineage = client.getFullName();
    var processQueue = [];
    processQueue.push(relationInfo);

    while (processQueue.length) {
      var parents = '';
      var info = processQueue.pop();
      if (info.father) {
        parents += relationInfo.father.client.getFullName();
      }
      if (info.mother) {
        if (parents.length > 0) {
          parents += ' + ';
        }
        parents += info.mother.client.getFullName();
      }
      if (parents.length > 0) {
        lineage = parents + ' - ' + lineage;
        if (info.father) {
          processQueue.push(info.father);
        }
      }
    }

    return lineage;
  };
}

function createComparator(column, isReversed, milestones, casefile) {
  if (column === 'order_position') {
    return function(a, b) {
      var posA = a.get('order_position');
      var posB = b.get('order_position');
      var sortResult = 0;
      if (posA < posB) {
        sortResult = -1;
      } else if (posA > posB) {
        sortResult = 1;
      }

      if (isReversed) {
        sortResult *= -1;
      }

      return sortResult;
    };
  } else if (column === 'fullname') {
    return function(a, b) {
      var sortResult = a.getFullName().localeCompare(b.getFullName());
      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  } else if (column === 'type') {
    return function(a, b) {
      var sortResult = a.get('type').localeCompare(b.get('type'));
      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  } else if (column === 'entitlement') {
    return function(a, b) {
      var fractionA = a.getEntitlementFraction();
      var fractionB = b.getEntitlementFraction();
      var sortResult = 0;
      if (fractionA < fractionB) {
        sortResult = -1;
      } else if (fractionA > fractionB) {
        sortResult = 1;
      }

      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  } else if (column === 'fee') {
    return function(a, b) {
      var feeA = a.get('fee_percentage_agreed');
      var feeB = b.get('fee_percentage_agreed');
      var sortResult = 0;

      if (feeA < feeB) {
        sortResult = -1;
      } else if (feeA > feeB) {
        sortResult = 1;
      }

      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  } else if (column === 'vat_rate') {
    return function(a, b) {
      var feeA = a.get('is_vat_applicable') ? a.get('vat_rate') : 0;
      var feeB = b.get('is_vat_applicable') ? b.get('vat_rate') : 0;
      var sortResult = 0;

      if (feeA < feeB) {
        sortResult = -1;
      } else if (feeA > feeB) {
        sortResult = 1;
      }

      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  } else if (column === 'treethorpe_revenue') {
    return function(a, b) {
      var aRevenue = a.calculateRevenueAndClientDistribution(casefile).revenue;
      var bRevenue = b.calculateRevenueAndClientDistribution(casefile).revenue;
      var sortResult = 0;

      if (aRevenue < bRevenue) {
        sortResult = -1;
      } else if (aRevenue > bRevenue) {
        sortResult = 1;
      }

      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  } else if (column === 'client_distribution') {
    return function(a, b) {
      var aRevenue = a.calculateRevenueAndClientDistribution(casefile).clientDistribution;
      var bRevenue = b.calculateRevenueAndClientDistribution(casefile).clientDistribution;
      var sortResult = 0;

      if (aRevenue < bRevenue) {
        sortResult = -1;
      } else if (aRevenue > bRevenue) {
        sortResult = 1;
      }

      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  } else {
    // assume all other sorting is by milestone dates

    // a mapping betwee sort column and client_milestone_type
    var milestoneMap = {
      fa_sent: 1,
      pr_nok: 2,
      fa_received: 3,
      disclosure_sent: 4,
      payment_made: 5,
      questionnaire_returned: 6,
    };
    return function(a, b) {
      var sortResult = 0;
      var aMilestone = milestones.find(function(milestone) {
        return (
          milestone.get('client') === a.get('id') &&
          milestone.get('client_milestone_type') === milestoneMap[column]
        );
      });
      var bMilestone = milestones.find(function(milestone) {
        return (
          milestone.get('client') === b.get('id') &&
          milestone.get('client_milestone_type') === milestoneMap[column]
        );
      });
      if (aMilestone && bMilestone) {
        if (aMilestone.get('created') < bMilestone.get('created')) {
          sortResult = -1;
        } else if (aMilestone.get('created') > bMilestone.get('created')) {
          sortResult = 1;
        }
      } else if (aMilestone) {
        sortResult = 1;
      } else if (bMilestone) {
        sortResult = -1;
      }

      if (isReversed) {
        return sortResult * -1;
      }

      return sortResult;
    };
  }
}

var ClientListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: ClientRowView,
  childViewContainer: '.clients',
  childViewOptions: function() {
    return this.options;
  },
  initialize: function() {
    this.options.showTableExpanded = false;
    this.collection.comparator = createComparator(
      'order_position',
      false,
      this.getOption('clientMilestones'),
      this.getOption('casefile')
    );
    this.collection.sort();
    this.listenTo(
      this.getOption('casefile')
        .get('asset')
        .get('asset_values'),
      'sync',
      this.render
    );
  },
  ui: {
    addContactBtn: 'button.new-contact',
    addAncestorBtn: 'button.new-ancestor',
    addBeneficiaryBtn: 'button.new-beneficiary',
    sortableField: 'a.sortable',
    expandTable: 'a.expand-table',
    collapseTable: 'a.collapse-table',
    table: 'table.clients',
    targetSummary: '.target-summary',
    revenuePotential: '.revenue-potential',
    revenueEngaged: '.revenue-engaged',
    targetRevenue: '.target-revenue',
    percentageOfTarget: '.percentage-of-target',
  },
  events: {
    'click @ui.addContactBtn': 'newContact',
    'click @ui.addAncestorBtn': 'newAncestor',
    'click @ui.addBeneficiaryBtn': 'newBeneficiary',
    'click @ui.sortableField': 'sortField',
    'click @ui.expandTable': 'onClickExpandTable',
    'click @ui.collapseTable': 'onClickCollapseTable',
  },
  collectionEvents: {
    sync: 'displayTotal',
    'change:type': function(model, newType) {
      if (
        (model.previousAttributes().type === 'contact' && newType !== 'contact') ||
        (model.previousAttributes().type !== 'contact' && newType === 'contact')
      ) {
        this.render();
      }
    },
  },
  onClickExpandTable: function() {
    this.options.showTableExpanded = true;
    this.render();
    return false;
  },
  onClickCollapseTable: function() {
    this.options.showTableExpanded = false;
    this.render();
    return false;
  },
  getLargestOrderPosition: function() {
    var orderPosition = 0;
    this.collection.forEach(function(caseProspect) {
      if (caseProspect.get('order_position') > orderPosition) {
        orderPosition = caseProspect.get('order_position');
      }
    });
    return orderPosition;
  },
  newContact: function() {
    var orderPosition = this.getLargestOrderPosition() + 1;
    //jshint camelcase:false
    this.collection.create(
      {
        casefile: this.getOption('casefile').get('id'),
        type: CaseProspect.TYPES.contact,
        has_access: false,
        order_position: orderPosition,
        contact: new Contact({
          owner: TCAS.session.get('id'),
          first_name: 'Unknown',
          country: 'United Kingdom',
        }),
      },
      { wait: true }
    );
  },
  newAncestor: function() {
    var orderPosition = this.getLargestOrderPosition() + 1;
    //jshint camelcase:false
    this.collection.create(
      {
        casefile: this.getOption('casefile').get('id'),
        type: CaseProspect.TYPES.ancestor,
        has_access: false,
        order_position: orderPosition,
        contact: new Contact({
          owner: TCAS.session.get('id'),
          first_name: 'Unknown',
          country: 'United Kingdom',
        }),
      },
      { wait: true }
    );
  },
  newBeneficiary: function() {
    var orderPosition = this.getLargestOrderPosition() + 1;
    //jshint camelcase:false
    this.collection.create(
      {
        casefile: this.getOption('casefile').get('id'),
        type: CaseProspect.TYPES.beneficiary,
        has_access: false,
        order_position: orderPosition,
        contact: new Contact({
          owner: TCAS.session.get('id'),
          first_name: 'Unknown',
          country: 'United Kingdom',
        }),
      },
      { wait: true }
    );
  },
  sortField: function(e) {
    var $link = this.$(e.currentTarget);
    this.sort = $link.attr('data-sort');
    if ($link.hasClass('active')) {
      $link.toggleClass('reverse');
      this.isSortReversed = true;
    } else {
      this.$('a.sortable').removeClass('active reverse');
      $link.addClass('active');
      this.isSortReversed = false;
    }

    var orderBy = $link.attr('data-sort');
    this.collection.comparator = createComparator(
      orderBy,
      $(e.currentTarget).hasClass('reverse'),
      this.getOption('clientMilestones'),
      this.getOption('casefile')
    );
    this.collection.sort();

    return false;
  },
  displayTotal: function() {
    if (this.getOption('showContacts')) {
      return;
    }
    var casefile = this.getOption('casefile');
    var clientMilestones = this.getOption('clientMilestones');
    var assetValue = casefile.get('asset').getCurrentValuation();
    var targetRevenue = assetValue.getTargetRevenue();
    var total = 0;
    var avgFeePercentage = 0;
    var totalWithEntitlement = 0;
    var totalTreethorpeRevenue = 0;
    var totalEngagedClientRevenue = 0;
    var totalRevenuePotential = 0;
    var totalClientDistribution = 0;
    var totalFASent = 0;
    var totalFAReceived = 0;
    var totalDisclosureSent = 0;
    var totalPaymentMade = 0;
    var totalPRNoK = 0;
    var totalQuestionnaires = 0;
    var totalClients = 0;
    var totalBeneficiariesAndClients = 0;
    var totalVAT = 0;

    this.collection.each(function(client) {
      var isFASent = false;
      var isFAReceived = false;
      if (client.isClient() || client.isBeneficiary()) {
        if (client.isClient()) {
          totalBeneficiariesAndClients += 1;
          totalClients += 1;
        } else if (client.isBeneficiary()) {
          totalBeneficiariesAndClients += 1;
        }
        clientMilestones.each(function(milestone) {
          if (
            milestone.get('client') === client.get('id') &&
            milestone.get('is_active') &&
            !milestone.get('is_not_applicable')
          ) {
            var milestoneType = milestone.get('client_milestone_type');
            if (milestoneType === 1) {
              totalFASent += 1;
              isFASent = true;
            } else if (milestoneType === 2) {
              totalPRNoK += 1;
            } else if (milestoneType === 3) {
              totalFAReceived += 1;
              isFAReceived = true;
            } else if (milestoneType === 4) {
              totalDisclosureSent += 1;
            } else if (milestoneType === 5) {
              totalPaymentMade += 1;
            } else if (milestoneType === 6) {
              totalQuestionnaires += 1;
            }
          }
        });
      }

      var revenueAndDistribution = client.calculateRevenueAndClientDistribution(
        casefile,
        isFAReceived
      );
      if (isFAReceived) {
        totalTreethorpeRevenue += revenueAndDistribution.revenue;
        totalClientDistribution += revenueAndDistribution.clientDistribution;
      } else {
        totalClientDistribution += revenueAndDistribution.clientDistributionBeforeFees;
      }
      var entitlementFraction = 0;
      if (client.get('entitlement_numerator') && client.get('entitlement_denominator')) {
        entitlementFraction = math.fraction(
          client.get('entitlement_numerator'),
          client.get('entitlement_denominator')
        );
      }
      if (total === 0) {
        total = entitlementFraction;
      } else {
        total = total.add(entitlementFraction);
      }

      if (entitlementFraction && (client.isClient() || client.isBeneficiary())) {
        if (isFAReceived) {
          totalWithEntitlement += 1;
          avgFeePercentage += client.get('fee_percentage_agreed');
          totalEngagedClientRevenue += revenueAndDistribution.revenue;
          totalVAT += revenueAndDistribution.vat;
        } else if (isFASent) {
          totalRevenuePotential += revenueAndDistribution.revenue;
        }
      }
    });

    if (totalWithEntitlement === 0) {
      avgFeePercentage = 0;
    } else {
      avgFeePercentage /= totalWithEntitlement;
    }

    // append an extra row displaying the totals
    this.ui.table.find('.table-total').remove();
    if (this.collection.length) {
      var percentString = avgFeePercentage.toFixed(2);
      if (percentString.split('.')[1] === '00') {
        percentString = Math.round(avgFeePercentage);
      } else if (percentString.split('.')[1].substr(1) == 0) {
        percentString = avgFeePercentage.toFixed(1);
      }
      this.ui.table.append(
        '<tbody class="table-total">' +
          '<tr><td></td><td></td><td></td><td ' +
          (total > 1 ? 'class="text-danger"' : '') +
          '>' +
          printFraction(total) +
          '</td>' +
          '<td>' +
          percentString +
          '%</td><td></td>' +
          '<td>' +
          Util.formatCurrency(totalTreethorpeRevenue) +
          '</td>' +
          '<td>' +
          Util.formatCurrency(totalClientDistribution) +
          '</td>' +
          (this.getOption('showTableExpanded')
            ? '<td>' +
              totalFASent +
              '/' +
              totalBeneficiariesAndClients +
              '</td>' +
              '<td>' +
              totalPRNoK +
              '</td>' +
              '<td>' +
              totalFAReceived +
              '/' +
              totalBeneficiariesAndClients +
              '</td>' +
              '<td>' +
              totalDisclosureSent +
              '/' +
              totalBeneficiariesAndClients +
              '</td>' +
              '<td>' +
              totalPaymentMade +
              '/' +
              totalBeneficiariesAndClients +
              '</td> +' +
              '<td>' +
              totalQuestionnaires +
              '/' +
              totalClients +
              '</td>'
            : '<td>' + totalPRNoK + '</td><td></td>') +
          '</tr></tbody>'
      );
    }

    this.ui.revenuePotential.html(Util.formatCurrency(totalRevenuePotential));
    this.ui.revenueEngaged.html(Util.formatCurrency(totalEngagedClientRevenue));
    this.ui.targetRevenue.html(Util.formatCurrency(targetRevenue));
    var targetPercentage = 0;
    if (targetRevenue) {
      targetPercentage = (totalEngagedClientRevenue / targetRevenue) * 100;
    }
    this.ui.percentageOfTarget.html(Math.round(targetPercentage) + '%');
    this.ui.percentageOfTarget.toggleClass('text-danger', targetPercentage < 100);
  },
  onRender: function() {
    var $el = this.$el;
    // add the correct sort indication
    if (this.sort) {
      var $link = this.$('[data-sort="' + this.sort + '"]');
      $link.addClass('active');
      $link.toggleClass('reverse', this.isSortReversed);
    }

    this.displayTotal();

    var collection = this.collection;

    var sortable = Sortable.create(this.$('.clients')[0], {
      filter: 'thead, tbody.table-total',
      draggable: 'tbody.client-row',
      handle: '.drag-handle',
      onUpdate: function(evt) {
        $el.addClass('loading');
        var spinner = new Spinner().spin($el[0]);
        var sorted = sortable.toArray();
        var promises = [];
        sorted.forEach(function(id, index) {
          var client = collection.get(id);
          if (client.get('order_position') !== index) {
            promises.push(
              client.save(
                {
                  order_position: index,
                },
                { wait: true, patch: true }
              )
            );
          }
        });
        $.when.apply($, promises).done(function() {
          spinner.stop();
          $el.removeClass('loading');
        });
      },
    });
  },
  onShow: function() {
    var that = this;
    var highlightProspect = this.getOption('highlightProspect');
    if (highlightProspect) {
      this.listenTo(this.collection, 'sync', function() {
        _.defer(function() {
          $.scrollTo('#client-' + highlightProspect, 800);
        });
      });
      if (this.collection.length) {
        _.defer(function() {
          var $row = that.$('[data-id="' + highlightProspect + '"]').find('tr.client-row-closed');
          $row.click();
          $.scrollTo($row, 800);
        });
      }
    }

    this.displayTotal();
  },
  serializeData: function() {
    var data = this.collection.toJSON();
    data.showTableExpanded = this.getOption('showTableExpanded');
    data.showContacts = this.getOption('showContacts');

    return data;
  },
});

module.exports = ClientListView;
