'use strict';

var template = require('../../templates/users/secret-challenge.hbs');

var SecretChallengeView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    loginBtn: 'button',
    alert: '.alert.alert-danger',
    forgotLink: '.forgot-2fa-link',
    forgot2faForm: 'form.forgot-2fa-form'
  },
  events: {
    'submit form.secret-challenge': 'onSubmit',
    'submit form.forgot-2fa-form': 'onSubmitForgot',
    'keyup input': 'onKeyUp',
    'click @ui.forgotLink': 'showForgot2FA'
  },
  onKeyUp: function(e) {
    var $input = this.$(e.currentTarget);
    if ($input.val().length === 1) {
      $input.parents('.form-group').next().find('input').focus();
    }
  },
  showForgot2FA: function() {
    this.ui.forgot2faForm.slideDown();
  },
  onSubmitForgot: function(){
    var that = this;
    $.post('/api/twofactorreset/',
      {
        username: this.getOption('username'),
        password: this.getOption('password')
      },
      'json')
      .done(function(data){
        that.ui.alert.html('Please check your email for further instructions.')
          .removeClass('hide alert-danger').addClass('alert-success');
        that.ui.forgot2faForm.slideUp();
      }).error(function(jqXHR){
      var error = JSON.parse(jqXHR.responseText);
      if (error.email) {
        error = error.email.join('<br/>');
      }
      that.ui.alert.html(error).removeClass('hide alert-success')
        .addClass('alert-danger');
    });

    return false;
  },
  onSubmit: function() {
    var that = this;
    var secretChars = '';
    var username = this.getOption('username');
    var password = this.getOption('password');
    var session = this.getOption('session');
    this.$('input').each(function(){
      secretChars += $(this).val();
    });
    this.ui.loginBtn.button('loading');

    $.post('/api/session/', {
      username: username,
      password: password,
      secret_challenge_id: this.model.get('id'),
      secret_challenge_characters: secretChars
    }).done(function(data) {
      if (data.is_active) {
        session.set(data);
      } else {
        session.destroy();
        that.ui.alert.html('This user does not have an active account.')
          .removeClass('hide');
        that.ui.loginBtn.button('reset');
      }
    }).fail(function(xhr) {
      that.ui.alert.html(xhr.responseJSON.detail).removeClass('hide');
      that.ui.loginBtn.button('reset');
    });

    return false;
  },
  onShow: function() {
    this.$('input:first').focus();
  }
});

module.exports = SecretChallengeView;
