// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"text-danger\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-times\"></i>";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span title=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_csoa_raised_changed : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "\">&#x2714;</span>";
},"7":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-xs btn-default edit\">Edit</button> <button type=\"button\" class=\"btn btn-xs btn-danger delete\"><i class=\"fa fa-trash-o\"></i></button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.flagNotInWeek || (depth0 && depth0.flagNotInWeek) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"This pledge needs updating",{"name":"flagNotInWeek","hash":{},"data":data}))
    + alias3((helpers.dateToWeek || (depth0 && depth0.dateToWeek) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"dateToWeek","hash":{},"data":data}))
    + "</td>\n<td title=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.modified : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.modified : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.case_manager : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.revenue : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.revenue : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.disbursements : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.disbursements : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_at_risk : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_csoa_raised : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"change_pledgingrecord",{"name":"hasPerm","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
