'use strict';

var Message = Backbone.Model.extend({
  urlRoot: '/api/messages/',
  isClientEmail: function() {
    return this.get('fao_clients').length > 0;
  }
});

Message.TYPES = {
  'MESSAGE': 0,
  'REPLY': 1,
  'TIMETRACK': 2
};

module.exports = Message;