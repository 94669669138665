'use strict';

var BaseCollection = require('../base-collection');
var Permission = require('./permission');

var Permissions = BaseCollection.extend({
  initialize: function(options){
    if (options.user) {
      this.user = options.user;
    }
    if (options.group) {
      this.group = options.group;
    }
  },
  url: function(){
    var url = '/api/permissions/?page_size=1000';
    if (this.user) {
      url += '&user=' + this.user.get('id');
    }
    if(this.group) {
      url += '&group=' + this.group;
    }
    return url;
  },
  model: Permission
});

Permissions.unwantedPerms = [
  'add_logentry',
  'change_logentry',
  'delete_logentry',
  'add_group',
  'change_group',
  'delete_group',
  'add_permission',
  'delete_permission',
  'add_activemilestoneexception',
  'change_activemilestoneexception',
  'delete_activemilestoneexception',
  'add_countries',
  'change_countries',
  'delete_countries',
  'add_milestoneexception',
  'change_milestoneexception',
  'delete_milestoneexception',
  'add_contenttype',
  'change_contenttype',
  'delete_contenttype',
  'add_queue',
  'change_queue',
  'delete_queue',
  'add_crontabschedule',
  'change_crontabschedule',
  'delete_crontabschedule',
  'add_intervalschedule',
  'change_intervalschedule',
  'delete_intervalschedule',
  'add_periodictask',
  'change_periodictask',
  'delete_periodictask',
  'add_periodictasks',
  'change_periodictasks',
  'delete_periodictasks',
  'add_taskmeta',
  'change_taskmeta',
  'delete_taskmeta',
  'add_tasksetmeta',
  'change_tasksetmeta',
  'delete_tasksetmeta',
  'add_taskstate',
  'change_taskstate',
  'delete_taskstate',
  'add_workerstate',
  'change_workerstate',
  'delete_workerstate',
  'add_emailmessage',
  'change_emailmessage',
  'delete_emailmessage',
  'add_session',
  'change_session',
  'delete_session',
  'add_site',
  'change_site',
  'delete_site',
  'add_migrationhistory',
  'change_migrationhistory',
  'delete_migrationhistory',
  'add_passwordreset',
  'change_passwordreset',
  'delete_passwordreset',
  'view_permissions',
  'add_csvfile',
  'change_csvfile',
  'delete_csvfile',
  'add_correspondencepreference',
  'change_correspondencepreference',
  'delete_correspondencepreference',
  'add_applicationsettings',
  'change_applicationsettings',
  'delete_applicationsettings',
  'add_commericalsector',
  'change_commercialsector',
  'delete_commercialsector',
  'change_sensitivedatatoken',
  'delete_sensitivedatatoken'
];

module.exports = Permissions;