'use strict';

var BaseCollection = require('../base-collection');
var FinancialServiceEnquiry = require('./financialserviceenquiry');

var FinancialServiceEnquiries = BaseCollection.extend({
  urlRoot: '/api/financialserviceenquiries/',
  model: FinancialServiceEnquiry,
});

module.exports = FinancialServiceEnquiries;
