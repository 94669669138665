'use strict';

var Router = Backbone.Marionette.AppRouter.extend({
  hasRoute: function(fragment){
    var found = _(Backbone.history.handlers).some(function(handler){
      return handler.route.test(fragment);
    });
    return found;
  }
});

module.exports = Router;