'use strict';

var CaseProspect = require('./caseprospect');
var BaseCollection = require('../base-collection');

var CaseProspects = BaseCollection.extend({
  urlRoot: '/api/caseprospects/?page_size=0',
  model: CaseProspect
});

module.exports = CaseProspects;