'use strict';

var template = require('../../../templates/reporting/report-individual-monthly-booked-row.hbs');

var IndividualMonthlyBookedRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  events: {
    'click .popout': 'onClickPopoutCase'
  },
  onClickPopoutCase: function() {
    TCAS.commands.execute('popoutCase', this.getOption('casefile'));

    return false;
  },
  serializeData: function() {
    var data = this.model.toJSON();
    data.casefile = this.getOption('casefile').toJSON();
    data.isFirstForCase = this.getOption('isFirstForCase');
    return data;
  }
});

module.exports = IndividualMonthlyBookedRowView;
