'use strict';

var template = require('../../templates/emails/email-row.hbs');

var EmailRowView = Backbone.Marionette.ItemView.extend({
  className: 'email-row',
  tagName: 'tr',
  template: template,
  triggers: {
    'click': 'show:email'
  }
});

module.exports = EmailRowView;
