// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"form-group\">\n          <button type=\"button\" class=\"btn btn-default btn-xs attach\"><i class=\"fa fa-plus\"></i> Attach file</button>\n        </div>\n        <div class=\"dropzone-previews\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<form class=\"message-create-form\" role=\"form\">\n  <div class=\"row\">\n    <div class=\"col-md-6\">\n      <div class=\"form-group\">\n        <textarea class=\"form-control\" rows=\"4\" name=\"content\" placeholder=\"Type a message for your case manager here...\"></textarea>\n      </div>\n    </div>\n    <div class=\"col-md-6\">\n      <div class=\"row\">\n        <div class=\"col-md-6\">\n          <div class=\"form-group\">\n            <label for=\"fao\" class=\"control-label\">FAO users</label>\n            <input type=\"text\" class=\"fao-select form-control\" id=\"fao\" name=\"fao\" />\n          </div>\n        </div>\n      </div>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"add_file",{"name":"hasPerm","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n  <button type=\"submit\" class=\"btn btn-primary\" data-loading-text=\"Sending...\">Send message</button>\n  <input type=\"hidden\" name=\"fao_clients\" value=\""
    + container.escapeExpression(((helper = (helper = helpers.clientId || (depth0 != null ? depth0.clientId : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"clientId","hash":{},"data":data}) : helper)))
    + "\"/>\n  <input type=\"hidden\" name=\"is_published\" value=\"1\"/>\n</form>";
},"useData":true});
