'use strict';

var BaseCollection = require('../base-collection');
var CalendarEventCategory = require('./calendareventcategory');

var CalendarEventCategories = BaseCollection.extend({
  urlRoot: '/api/calendareventcategories/',
  model: CalendarEventCategory,
  comparator: 'name'
});

module.exports = CalendarEventCategories;
