'use strict';

var template = require('../../templates/emails/email-detail.hbs');

var EmailDetailView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'email-detail'
});

module.exports = EmailDetailView;
