'use strict';

var moment = require('moment');
var URLSearchParams = require('url-search-params');
var template = require('../../templates/cases/file-row.hbs');
var templateSimple = require('../../templates/cases/file-row-simple.hbs');
var templateEdit = require('../../templates/cases/file-row-edit.hbs');
var ConfirmModalView = require('../confirm-modal');

var FileRowView = Backbone.Marionette.ItemView.extend({
  className: 'file list-group-item',
  getTemplate: function() {
    if (this.editMode) {
      return templateEdit;
    } else {
      if (this.getOption('isSimple')) {
        return templateSimple;
      } else {
        return template;
      }
    }
  },
  modelEvents: {
    'change:file_download': 'render',
  },
  events: {
    'click @ui.deleteBtn': 'onClickDelete',
    'click @ui.editBtn': 'onClickEdit',
    'click @ui.cancelBtn': 'onClickCancel',
    'click @ui.clientsBtn': 'onClickClients',
    'click @ui.cancelClientsBtn': 'onClickCancelClients',
    'click @ui.saveClientsBtn': 'onSaveClients',
    'click @ui.removeBtn': 'onClickRemove',
    'submit @ui.form': 'onSave',
    'click @ui.fileLink': 'onClickFileLink',
    'click @ui.selectFile': 'onSelectFile',
  },
  ui: {
    fileLink: '.file-link',
    deleteBtn: 'button.delete-file',
    removeBtn: 'button.remove',
    editBtn: 'button.edit',
    saveBtn: 'button.save',
    cancelBtn: 'button.cancel',
    clientsBtn: 'button.clients',
    cancelClientsBtn: 'button.cancel-clients-allowed',
    saveClientsBtn: 'button.save-clients-allowed',
    form: 'form',
    fileName: 'input.file-name',
    clientsAllowed: 'input.clients-allowed',
    selectFile: '.select-file',
  },
  onClickDelete: function() {
    var confirmModal = new ConfirmModalView({
      body: 'Are you sure you want to delete this file?',
    });
    confirmModal.render();
    this.listenTo(confirmModal, 'confirmYes', function() {
      this.model.save(
        { deleted: true },
        {
          wait: true,
          patch: true,
          url: '/api/files/' + this.model.get('id') + '/',
        }
      );
    });
  },
  onClickRemove: function() {
    this.trigger('removeFile', this.model);
  },
  onClickEdit: function() {
    this.editMode = true;
    this.render();
    this.stopDragListener();
    this.ui.form.validate({
      rules: {
        original_file_name: {
          required: true,
          alphaNumericFile: true,
        },
      },
    });
  },
  onClickCancel: function() {
    this.editMode = false;
    this.render();
  },
  onSelectFile: function() {
    var isSelected = this.ui.selectFile.is(':checked');
    this.trigger('fileSelectionChanged', this.model, isSelected);
  },
  onClickClients: function() {
    this.showClients = !this.showClients;
    this.render();
  },
  onClickCancelClients: function() {
    this.showClients = false;
    this.render();
  },
  onSave: function() {
    var that = this;
    this.ui.saveBtn.button('loading');
    this.model.save(
      {
        original_file_name: this.ui.fileName.val(),
      },
      {
        patch: true,
        wait: true,
        success: function() {
          that.onClickCancel();
        },
      }
    );

    return false;
  },
  onSaveClients: function() {
    var that = this;
    this.ui.saveClientsBtn.button('loading');
    this.model.save(
      {
        clients_allowed: this.ui.clientsAllowed.select2('val'),
      },
      {
        patch: true,
        wait: true,
        success: function() {
          that.showClients = false;
          that.render();
        },
      }
    );
  },
  onClickFileLink: function(e) {
    if (!this.ui.fileLink.hasClass('downloadable')) {
      this.trigger('requestFileDownload');
    } else if (this.model.get('file_download')) {
      var urlParams = new URLSearchParams(this.model.get('file_download'));
      var expires = urlParams.get('Expires');
      var expiresMoment = moment.unix(expires);
      if (expiresMoment.isBefore(moment())) {
        var model = this.model;
        this.model.fetch().then(function() {
          window.location = model.get('file_download');
        });
        e.preventDefault();
      }
    }
  },
  startDragListener: function() {
    var that = this;
    this.$el.on('dragstart', function(e) {
      $(this).addClass('dragging');
      e.originalEvent.dataTransfer.setData('text/plain', JSON.stringify(that.model.toJSON()));
      that.trigger('fileDrag');
    });
  },
  stopDragListener: function() {
    this.$el.off('dragstart');
  },
  onDestroy: function() {
    this.$el.off('dragstart');
  },
  onRender: function() {
    var clients;
    this.startDragListener();
    if (this.getOption('clients')) {
      clients = this.getOption('clients').map(function(client) {
        return { id: client.get('id'), text: client.getFullName() };
      });
    }

    if (this.showClients && clients) {
      this.ui.clientsAllowed.select2({
        multiple: true,
        allowClear: true,
        placeholder: 'Select clients...',
        data: clients,
      });
      if (this.model.get('clients_allowed').length) {
        this.ui.clientsAllowed.select2('val', this.model.get('clients_allowed'));
      }
    }

    this.$('.label-tooltip').tooltip({
      container: 'body',
    });
  },
  serializeData: function() {
    var data = this.model.toJSON();
    if (this.getOption('casefile')) {
      var correspondence = this.getOption('casefile')
        .get('correspondence')
        .find(function(correspondence) {
          return correspondence.get('evidence') === data.id;
        });
      if (correspondence) {
        data.correspondence = correspondence.toJSON();
      }
    }
    if (this.getOption('lastViewed')) {
      if (
        new Date(data.created) > this.getOption('lastViewed') &&
        data.owner !== TCAS.session.get('id')
      ) {
        data.isNew = true;
      }
    }
    data.showClients = this.showClients;
    data.editMode = this.editMode;
    data.isClient = this.getOption('isClient');
    data.isPending = data.av_status === 'PENDING';
    data.isInfected = data.av_status === 'INFECTED';
    data.editMode = this.getOption('editMode');
    data.enableSelection = this.getOption('enableSelection');
    return data;
  },
});

module.exports = FileRowView;
