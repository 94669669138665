// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert alert-info\">A sensitive data session is required to access the authentication log.</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"alert-area\"></div>\n\n<div>\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.sdToken : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <form class=\"form-inline\">\n        <div class=\"form-group\">\n            <label for=\"start\">From</label>\n            <input id=\"start\" class=\"form-control\">\n        </div>\n        <div class=\"form-group\">\n            <label for=\"end\">To</label>\n            <input id=\"end\" class=\"form-control\">\n        </div>\n\n        <button class=\"btn btn-success\" id=\"submit\">Filter Authentication Log</button>\n    </form>\n</div>\n<br />\n<div id=\"reporting-area\" class=\"table-responsive\"></div>";
},"useData":true});
