// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"text-danger\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<a "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.csoa : depth0)) != null ? stack1.file_download : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"file-link "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.csoa : depth0)) != null ? stack1.file_download : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.csoa : depth0)) != null ? stack1.original_file_name : stack1), depth0))
    + "</a>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.csoa : depth0)) != null ? stack1.file_download : stack1), depth0))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    return "downloadable";
},"8":function(container,depth0,helpers,partials,data) {
    return "None";
},"10":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-xs btn-default edit\">Edit</button> <button type=\"button\" class=\"btn btn-xs btn-danger delete\"><i class=\"fa fa-trash-o\"></i></button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.dateToWeek || (depth0 && depth0.dateToWeek) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"dateToWeek","hash":{},"data":data}))
    + "</td>\n<td title=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.modified : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.modified : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.leadingZeros || (depth0 && depth0.leadingZeros) || alias2).call(alias1,3,(depth0 != null ? depth0.case_element : depth0),{"name":"leadingZeros","hash":{},"data":data}))
    + "</td>\n<td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.case_manager : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.revenue : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.revenue : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.vat : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.vat : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.disbursements : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.disbursements : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.csoa : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n<td>"
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</td>\n<td "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),0,{"name":"lt","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</td>\n<td>"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"change_bookingrecord",{"name":"hasPerm","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>";
},"useData":true});
