'use strict';

var BaseCollection = require('../base-collection');
var AssetValue = require('./assetvalue');

var AssetValues = BaseCollection.extend({
  model: AssetValue,
  urlRoot: '/api/assetvalue/',
  comparator: function(assetValue){
    return assetValue.get('id');
  }
});

module.exports = AssetValues;