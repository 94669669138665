'use strict';

var moment = require('moment');
var template = require('../../templates/events/event-form.hbs');
var CalendarEvent = require('../../models/events/calendarevent');
var CalendarEventInvite = require('../../models/events/invite');
var CaseManagerSelectView = require('../allocations/casemanager-select');

var EventFormView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'modal fade',
  ui: {
    title: '.event-title',
    startDate: '.start-date',
    endDate: '.end-date',
    startTime: '.start-time',
    endTime: '.end-time',
    eventCategory: '.event-category',
    eventType: '.event-type',
    eventTitle: '.event-title',
    isAllDay: '.is-all-day',
    isRecurring: '.is-recurring',
    groupTimes: '.group-start-time,.group-end-time',
    groupRecurring: '.group-recurring',
    groupInvitees: '.group-invitees',
    groupUser: '.group-user'
  },
  regions: {
    inviteesSelectRegion: '.invitees-select-container',
    userSelectRegion: '.user-select-container'
  },
  events: {
    'change @ui.eventCategory': 'onChangeEventCategory',
    'change @ui.eventType': 'onChangeEventType',
    'change @ui.isAllDay': 'onChangeIsAllDay',
    'change @ui.isRecurring': 'onChangeIsRecurring',
    'change @ui.startDate': 'onChangeStartDate'
  },
  behaviors: {
    SubmitForm: {
      selector: 'form',
      submitHandler: 'submitHandler'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          event_type: {
            required: true
          },
          title: {
            required: true,
            maxlength: 100
          },
          start_date: {
            required: true
          },
          start_time: {
            time: true
          },
          end_time: {
            time: true
          }
        }
      }
    }
  },
  onChangeEventCategory: function() {
    var catId = parseInt(this.ui.eventCategory.val(), 10);
    var eventTypes = this.getOption('calendarEventTypes').filter(function(eventType) {
      return eventType.get('category') === catId && (!eventType.get('is_leave_event') || TCAS.session.hasPerm('add_leave_events'));
    });
    this.ui.eventType.empty();
    var $eventType = this.ui.eventType;
    eventTypes.forEach(function(eventType) {
      var include = true;
      // only users with add_leave_events permission can create leave events
      if (eventType.get('is_leave_event') && !TCAS.session.hasPerm('add_leave_events')) {
        include = false;
      }

      if (include) {
        $eventType.append('<option value="'+eventType.get('id')+'">'+eventType.get('name')+'</option>');
      }
    });
    this.onChangeEventType();

    if (catId === 2) { // 2 - Meeting
      this.ui.groupInvitees.removeClass('hidden');
    } else {
      this.ui.groupInvitees.addClass('hidden');
    }
  },
  onChangeEventType: function() {
    var eventTypeId = parseInt(this.ui.eventType.val(), 10);
    var calendarEvent = this.getOption('calendarEvent');
    var eventType = this.getOption('calendarEventTypes').get(eventTypeId);
    this.ui.eventTitle.val(eventType.get('name'));
    if (this.ui.isAllDay.prop('checked') != eventType.get('is_default_all_day')) {
      this.ui.isAllDay.prop('checked', eventType.get('is_default_all_day'));
      this.onChangeIsAllDay();
    }

    if (eventType.get('is_leave_event') || eventType.get('is_for_multiple_users')) {
      this.ui.groupUser.removeClass('hidden');
    } else {
      this.ui.groupUser.addClass('hidden');
    }

    // set up user select again, multiple users might have changed
    var userSelectOptions = {
      multiple: eventType.get('is_for_multiple_users'),
      user: [TCAS.session.get('id')]
    };

    if (this.userSelectView) {
      userSelectOptions.user = parseInt(this.userSelectView.getSelectedCaseManager(), 10);
    } else if (calendarEvent) {
      userSelectOptions.user = calendarEvent.get('users');
    }

    this.setupUserSelect(userSelectOptions);
  },
  onChangeIsAllDay: function() {
    this.ui.groupTimes.toggleClass('hidden', this.ui.isAllDay.is(':checked'));
    if (this.ui.isAllDay.prop('checked')) {
      this.ui.endTime.val(this.ui.startTime.val());
    }
  },
  onChangeIsRecurring: function() {
    this.ui.groupRecurring.toggleClass('hidden', !this.ui.isRecurring.is(':checked'));
  },
  onChangeStartDate: function() {
    // check if end date should be adjusted
    var startDate = new Date(this.ui.startDate.val());
    var endDate = new Date(this.ui.endDate.val());

    if (startDate > endDate) {
      this.ui.endDate.val(this.ui.startDate.val());
    }
  },
  submitHandler: function(data) {
    var that = this;
    var start = moment(data.start_date);
    var end = null;
    if (data.start_time) {
      if (data.start_time.indexOf(':') === -1) {
        data.start_time = data.start_time.substr(0, 2)+':'+data.start_time.substr(2, 4);
      }
      var timeSplit = data.start_time.split(':');
      start.hour(timeSplit[0]);
      start.minute(timeSplit[1]);
    }
    if (data.end_date) {
      end = moment(data.end_date);
      if (data.end_time) {
        if (data.end_time.indexOf(':') === -1) {
          data.end_time = data.end_time.substr(0, 2)+':'+data.end_time.substr(2, 4);
        }
        timeSplit = data.end_time.split(':');
        end.hour(timeSplit[0]);
        end.minute(timeSplit[1]);
      }
    }

    var eventData = {
      event_type: data.event_type,
      title: data.title,
      start: start.toISOString(),
      users: [TCAS.session.get('id')],
      is_all_day: data.is_all_day === 'on',
      is_recurring: data.is_recurring === 'on',
      recur_every: 0,
      recur_interval: 'days'
    };

    if (end) {
      eventData.end = end.toISOString();
    }

    if (data.is_recurring === 'on') {
      eventData.recur_every = data.recur_every;
      eventData.recur_interval = data.recur_interval;
    }

    var attendees = that.inviteeSelectView.getSelectedCaseManager();
    var users = that.userSelectView.getSelectedCaseManager();
    if (data.event_category == 2 && attendees.length) {
      eventData.attendees = attendees.split(',');
    } else if (data.event_category == 4) {
      eventData.users = users.split(',')
    }

    var calendarEvent = this.getOption('calendarEvent');

    if (calendarEvent) {
      // this is an update to the calendarEvent, not a new one
      calendarEvent.save(eventData, { wait: true,
        success: function() {
          that.$el.modal('hide');
          that.ui.groupUser.find('.form-group').removeClass('has-error').find('.help-block').remove();
        },
        error: function(model, response) {
          if (response.responseJSON && response.responseJSON.user) {
            that.ui.groupUser.find('.form-group').addClass('has-error').find('.user-select-container').after('<span class="help-block">A user is required</span>');
          }
        }
      });
    } else {
      var collection = this.getOption('collection');
      collection.create(eventData, { wait: true,
        success: function() {
          that.$el.modal('hide');
        }
      });
    }
  },
  setupUserSelect: function(options) {
    // ensure user field is array or single item based on multiple boolean
    if (options.multiple && !options.user.length) {
      options.user = [options.user];
    } else if (!options.multiple && options.user.length) {
      options.user = options.user[0];
    }
    this.userSelectView = new CaseManagerSelectView(options);
    this.userSelectRegion.show(this.userSelectView);
  },
  onRender: function() {
    $.validator.addMethod('time', function(value, element) {
      return this.optional(element) ||
        /^(([0-1]?[0-9])|([2][0-3])):?([0-5]?[0-9])(:([0-5]?[0-9]))?$/i.
        test(value);
    }, 'Please enter a valid time (hh:mm or hhmm).');

    this.$el.appendTo('body');
    this.$el.modal({
      backdrop: 'static'
    });
    var calendarEvent = this.getOption('calendarEvent');

    this.onChangeEventCategory();
    this.inviteeSelectView = new CaseManagerSelectView({
      multiple: true,
      user: calendarEvent ? calendarEvent.get('attendees') : TCAS.session.get('id')
    });
    this.inviteesSelectRegion.show(this.inviteeSelectView);

    var userSelectOptions = {
      multiple: false,
      user: [TCAS.session.get('id')]
    };

    // if this is an edit, make sure form has correct selections
    if (calendarEvent) {
      var eventType = this.getOption('calendarEventTypes').get(calendarEvent.get('event_type'));
      this.ui.eventCategory.val(eventType.get('category'));
      this.onChangeEventCategory();
      this.ui.eventType.val(eventType.get('id'));
      this.ui.isRecurring.prop('checked', calendarEvent.get('is_recurring'));
      this.onChangeIsRecurring();
      this.onChangeEventType();
      this.ui.title.val(calendarEvent.get('title'));
      this.ui.isAllDay.prop('checked', calendarEvent.get('is_all_day'));
      userSelectOptions.multiple = eventType.get('is_for_multiple_users');
      userSelectOptions.user = calendarEvent.get('users');
    }

    this.setupUserSelect(userSelectOptions);
  },
  serializeData: function() {
    if (this.getOption('calendarEvent')) {
      var data = this.getOption('calendarEvent').toJSON();
    } else {
      data = {};
    }

    data.eventCategories = this.getOption('calendarEventCategories').toJSON();
    data.eventTypes = this.getOption('calendarEventTypes').toJSON();

    var calendarEvent = this.getOption('calendarEvent');
    if (calendarEvent) {
      data.event = calendarEvent.toJSON();
      data.event.start = new Date(calendarEvent.get('start'));
      data.event.end = new Date(calendarEvent.get('end'));
    } else {
      data.event = {
        start: moment().add(1, 'hours').minute(0).toDate(),
        end: moment().add(2, 'hours').minute(0).toDate()
      };
    }

    return data;
  }
});

module.exports = EventFormView;
