'use strict';

var template = require('../../templates/reporting/report-authenticationlog-list.hbs');
var AuthenticationLogRowView = require('./report-authenticationlog-row');
var Spinner = require('spin');

var LogListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: AuthenticationLogRowView,
  childViewContainer: 'table',
  ui: {
    sortableField: 'a.sortable'
  },
  events: {
    'click @ui.sortableField': 'sortField'
  },
  sortField: function(e) {
    var $column = this.$(e.currentTarget);
    var orderBy = $column.attr('data-sort');

    if (this.collection.orderBy && this.collection.orderBy === orderBy) {
      // reverse order
      if (orderBy.charAt(0) === '-') {
        orderBy = orderBy.substr(1);
      } else {
        orderBy = '-' + orderBy;
      }
    }
    this.collection.orderBy = orderBy;
    this.collection.setSortOrder(orderBy);
    this.render();
  },
  onRender: function(){
    if (this.collection.orderBy) {
      var orderBy = this.collection.orderBy;
      var isReverse = orderBy.charAt(0) === '-';
      if (isReverse) {
        orderBy = orderBy.substr(1);
      }

      var $sortColumn = this.$('.sortable[data-sort="' + orderBy+'"]');
      $sortColumn.addClass('active');

      if (isReverse) {
        $sortColumn.addClass('reverse');
      }
    }
  }
});

module.exports = LogListView;