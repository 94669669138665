'use strict';

var template = require('../../templates/financials/financialserviceenquiries-list.hbs');
var alertTemplate = require('../../templates/alert.hbs');
var FinancialServiceEnquiriesRowView = require('./financialserviceenquiries-row');

var FinancialServiceEnquiriesListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  ui: {
    alertArea: '.alert-container',
  },
  className: 'financialserviceenquiries',
  childView: FinancialServiceEnquiriesRowView,
  childViewContainer: 'tbody.table-items',
  childViewOptions: function(model) {
    var isCustom = !this.getOption('financialServices').some(function(financialService) {
      return financialService.get('name') === model.get('financial_service_name');
    });
    return {
      isCustom: isCustom,
      casefile: this.getOption('casefile'),
    };
  },
  childEvents: {
    save: function() {
      this.ui.alertArea.html(
        alertTemplate({
          message: 'Enquiry has been saved successfully!',
          type: 'success',
        })
      );
    },
  },
  filter: function(child) {
    return !child.get('is_not_applicable');
  }
});

module.exports = FinancialServiceEnquiriesListView;
