// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<button type=\"button\" class=\"btn btn-default btn-sm email-survey\" "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.client : depth0)) != null ? stack1.contact : stack1)) != null ? stack1.email : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-loading-text=\"Please wait...\">Email Questionnaire to Client</button>";
},"2":function(container,depth0,helpers,partials,data) {
    return "title=\"Unable to send, client does not have an email address.\"";
},"4":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-default btn-sm edit active\"><i class=\"fa fa-pencil\"></i> Edit</button>";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\n      <label class=\"control-label\">Date</label>\n      <div class=\"row\">\n        <div class=\"col-sm-3\">\n          <input type=\"date\" class=\"form-control\" name=\"date\" value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.date : depth0),"YYYY-MM-DD",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n        </div>\n      </div>\n    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\n      <label class=\"control-label\">Scanned paper questionnaire:</label>\n      <div>\n        <button type=\"button\" class=\"btn btn-default btn-sm questionnaire-file\">Upload Scanned Questionnaire</button>\n        <input type=\"hidden\" name=\"paper-questionnaire\" class=\"paper-questionnaire-hidden\"/>\n      </div>\n      <div class=\"file-preview\"></div>\n      <div class=\"dropzone-previews\"></div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form>\n  <div class=\"alert-area\"></div>\n  <div class=\"text-right\">\n    <div class=\"btn-group\">\n      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showEmailSurveyButton : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showEdit : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n\n  <div class=\"questions-container\"></div>\n\n  <hr />\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDateField : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showFile : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <div class=\"form-group form-buttons\">\n    <div class=\"alert-container\"></div>\n    <button type=\"submit\" class=\"btn btn-lg btn-primary save\" data-loading-text=\"Saving...\">Save</button>\n  </div>\n</form>";
},"useData":true});
