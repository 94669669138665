// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.currentAllocation : depth0)) != null ? stack1.case_manager : stack1), depth0))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<form class=\"form-inline pull-right\">\n    <div class=\"form-group\">\n        <label for=\"case-manager\">Select Case Manager: </label>\n        <input type=\"text\" class=\"form-control\" id=\"case-manager\" name=\"case-manager\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.currentAllocation : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/>\n        <input type=\"text\" class=\"form-control\" id=\"partner\" name=\"partner\" />\n    </div>\n    <button type=\"submit\" class=\"btn btn-primary\" id=\"allocate-case\">Allocate</button>\n</form>\n\n<div id=\"allocations-list\"></div>";
},"useData":true});
