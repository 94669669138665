'use strict';

var BaseCollection = Backbone.Collection.extend({
  initialize: function(models, options) {
    if (options) {
      if (options.urlRoot) {
        this.urlRoot = options.urlRoot;
      }
    }
  },
  url: function() {
    var url = this.urlRoot;
    var params = [];
    if (this.orderBy) {
      params.push('ordering=' + this.orderBy.join(','));
    }
    if (this.filters) {
      _.forOwn(this.filters, function(value, filter) {
        params.push(filter + '=' + value);
      });
    }
    if (params.length) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += params.join('&');
    }
    return url;
  },
  setFilters: function(filterObject) {
    this.filters = filterObject;
  },
  parse: function(response) {
    if (response.results) {
      this.total = response.count;
      this.next = response.next;
      this.previous = response.previous;
      if (this.next) {
        this.nextPage = /page=(\d+)/.exec(this.next)[1];
        this.page = this.nextPage - 1;
      }
      return response.results;
    } else {
      this.total = response.length;
      return response;
    }
  },
  fetch: function(options) {
    options = options || {};
    var timeStart = performance.now();
    var url = options.url || this.url();

    this.listenToOnce(this, 'sync', function() {
      var timeEnd = performance.now();

      //console.log('Fetching: ' + url +
      //  ' ' + (timeEnd - timeStart) + ' ms.');
    });

    var oldErrorHandler = options.error;
    // proxy error handling to check for 'Authentication credentials were not provided.' error
    function errorHandler(collection, response, options) {
      if (
        response.status === 403 &&
        response.responseJSON &&
        response.responseJSON.detail === 'Authentication credentials were not provided.'
      ) {
        alert('Your session has expired, you will need to log in again.');
        window.location = '/login';
      }
      if (oldErrorHandler) {
        options.error(collection, response, options);
      }
    }
    options.error = errorHandler;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },
  fetchNext: function(options) {
    options = options || {};
    options.remove = false;
    options.url = this.next;
    //    options.data = {page: this.page + 1};
    return this.fetch(options);
  },
  fetchPrevious: function(options) {
    options = options || {};
    options.remove = false;
    options.url = this.previous;
    //    options.data = {page: this.page - 1};
    return this.fetch(options);
  },
  setSortOrder: function(sortOrder) {
    if (this.comparators && this.comparators.hasOwnProperty(sortOrder)) {
      this.comparator = this.comparators[sortOrder];
    } else {
      var isNegated = sortOrder.charAt(0) === '-';
      if (isNegated) {
        sortOrder = sortOrder.substr(1);
      }
      this.comparator = function(modelA, modelB) {
        if (modelA.has(sortOrder)) {
          var valueA = modelA.get(sortOrder);
          var valueB = modelB.get(sortOrder);
          var sortValue = 0;
          var valueType = typeof valueA;
          if (valueType === 'number' || valueA instanceof Date) {
            if (valueA > valueB) {
              sortValue = -1;
            } else if (valueB > valueA) {
              sortValue = 1;
            }
          } else if (valueType === 'string') {
            sortValue = valueA.localeCompare(valueB);
          }

          if (isNegated) {
            sortValue *= -1;
          }

          return sortValue;
        }
      };
    }
    this.sort();
  },
});

module.exports = BaseCollection;
