// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "    <div class=\"row\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.choices : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <div class=\"col-md-3\">\n          <div class=\"radio\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].editable : depths[1]),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "                <label><input type=\"radio\" name=\"question-"
    + alias1(container.lambda(((stack1 = (depths[1] != null ? depths[1].question : depths[1])) != null ? stack1.id : stack1), depth0))
    + "\" value=\""
    + alias1(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias3).call(alias2,((stack1 = (depths[1] != null ? depths[1].answer : depths[1])) != null ? stack1.answer_multiple_choice : stack1),(depth0 != null ? depth0.id : depth0),{"name":"ifEqual","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> "
    + alias1(((helper = (helper = helpers.choice || (depth0 != null ? depth0.choice : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"choice","hash":{},"data":data}) : helper)))
    + "</label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? depths[1].answer : depths[1])) != null ? stack1.answer_multiple_choice : stack1),(depth0 != null ? depth0.id : depth0),{"name":"ifEqual","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <strong>"
    + container.escapeExpression(((helper = (helper = helpers.choice || (depth0 != null ? depth0.choice : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"choice","hash":{},"data":data}) : helper)))
    + "</strong>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                "
    + container.escapeExpression(((helper = (helper = helpers.choice || (depth0 != null ? depth0.choice : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"choice","hash":{},"data":data}) : helper)))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.editable : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "      <textarea class=\"form-control\" name=\"question-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" rows=\"4\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.answer : depth0)) != null ? stack1.answer_text : stack1), depth0))
    + "</textarea>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <p>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.answer : depth0)) != null ? stack1.answer_text : stack1), depth0))
    + "</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"form-group question\">\n  <label class=\"control-label question-label\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.number : stack1), depth0))
    + ". "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.question : stack1), depth0))
    + "</label>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.choices : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});
