// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-warning alert-dismissible\" role=\"alert\">\n  <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\"><i\n        class=\"fa fa-close\"></i></span></button>\n  <strong>Warning!</strong> You do not have Archived Cases session enabled, so reports will not contain any data\n  from archived cases.\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <tr>\n              <td>"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</td>\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.categoryData : depth0),{"name":"with","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <td>"
    + alias4(((helper = (helper = helpers.totalCases || (depth0 != null ? depth0.totalCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalCases","hash":{},"data":data}) : helper)))
    + "</td>\n              <td>"
    + alias4(((helper = (helper = helpers.ongoingCases || (depth0 != null ? depth0.ongoingCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ongoingCases","hash":{},"data":data}) : helper)))
    + "</td>\n              <td>"
    + alias4(((helper = (helper = helpers.newCasesThisYear || (depth0 != null ? depth0.newCasesThisYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newCasesThisYear","hash":{},"data":data}) : helper)))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.revenuePotential : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "</td>\n              <td>"
    + alias4(((helper = (helper = helpers.concludedOrBookedCases || (depth0 != null ? depth0.concludedOrBookedCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"concludedOrBookedCases","hash":{},"data":data}) : helper)))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedIncome : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td>"
    + alias4((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.successRate : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.avgValueOfCases : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.avgGeneratedFeeIncome : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <tbody class=\"table-total\">\n            <tr>\n              <td>Case analysis</td>\n              <td>"
    + alias4(((helper = (helper = helpers.totalCases || (depth0 != null ? depth0.totalCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalCases","hash":{},"data":data}) : helper)))
    + "</td>\n              <td>"
    + alias4(((helper = (helper = helpers.ongoingCases || (depth0 != null ? depth0.ongoingCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ongoingCases","hash":{},"data":data}) : helper)))
    + "</td>\n              <td>"
    + alias4(((helper = (helper = helpers.newCasesThisYear || (depth0 != null ? depth0.newCasesThisYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newCasesThisYear","hash":{},"data":data}) : helper)))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.revenuePotential : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td>"
    + alias4(((helper = (helper = helpers.concludedCases || (depth0 != null ? depth0.concludedCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"concludedCases","hash":{},"data":data}) : helper)))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedIncome : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td>"
    + alias4((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.successRate : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.avgValueOfCases : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.avgGeneratedFeeIncome : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            </tr>\n          </tbody>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <div class=\"commercial-summary-stats-floating\">\n        <table class=\"table table-condensed table-total commercial-summary-report-stats-table\">\n          <tbody>\n            <tr>\n              <td>Case analysis</td>\n              <td>"
    + alias4(((helper = (helper = helpers.totalCases || (depth0 != null ? depth0.totalCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalCases","hash":{},"data":data}) : helper)))
    + "</td>\n              <td>"
    + alias4(((helper = (helper = helpers.ongoingCases || (depth0 != null ? depth0.ongoingCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ongoingCases","hash":{},"data":data}) : helper)))
    + "</td>\n              <td>"
    + alias4(((helper = (helper = helpers.newCasesThisYear || (depth0 != null ? depth0.newCasesThisYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newCasesThisYear","hash":{},"data":data}) : helper)))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.revenuePotential : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td>"
    + alias4(((helper = (helper = helpers.concludedCases || (depth0 != null ? depth0.concludedCases : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"concludedCases","hash":{},"data":data}) : helper)))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedIncome : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              <td>"
    + alias4((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.successRate : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.avgValueOfCases : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n              "
    + alias4((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.avgGeneratedFeeIncome : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            </tr>\n          </tbody>\n        </table>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isArchiveDataSession : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div>\n  <form class=\"form-inline report-form\">\n    <button type=\"submit\" class=\"btn btn-success btn-sm submit-btn\" id=\"commercial-report-submit\"\n      data-loading-text=\"Please wait...\">\n      Generate Report\n    </button>\n  </form>\n</div>\n\n<div id=\"reporting-area\" class=\"reporting-area hidden\">\n  <h2>Commercial Relationship Tracking</h2>\n\n  <ul class=\"nav nav-tabs\" role=\"tablist\">\n    <li role=\"presentation\" class=\"active\"><a href=\"#commercial-summary\" class=\"tab-commercial-summary\"\n        aria-controls=\"commercial-summary\" role=\"tab\" data-toggle=\"tab\">Overview</a></li>\n    <li role=\"presentation\"><a href=\"#commercial-granular\" class=\"tab-commercial-granular\"\n        aria-controls=\"commercial-granular\" role=\"tab\" data-toggle=\"tab\">Granular</a></li>\n  </ul>\n  <div class=\"tab-content\">\n    <div role=\"tabpanel\" class=\"tab-pane active commercial-summary\" id=\"commercial-summary\">\n      <div class=\"actions\">\n        <button type=\"button\" class=\"btn btn-default csv\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.totalCases : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          data-loading-text=\"Exporting...\">Export\n          CSV</button>\n      </div>\n      <div class=\"table-responsive\">\n        <table class=\"table table-condensed commercial-summary-report-table\">\n          <thead>\n            <tr>\n              <th>Commercial Referrers</th>\n              <th>Total Cases referred</th>\n              <th>Ongoing Cases</th>\n              <th>New Cases "
    + container.escapeExpression(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + "</th>\n              <th>Current Revenue potential</th>\n              <th>Booked / Concluded Cases</th>\n              <th>Actual Booked Income</th>\n              <th>Success rate</th>\n              <th>Avg value of cases</th>\n              <th>Avg generated fee income</th>\n            </tr>\n          </thead>\n          <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </tbody>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n      </div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div role=\"tabpanel\" class=\"tab-pane commercial-granular\" id=\"commercial-granular\"></div>\n  </div>\n\n</div>";
},"useData":true});
