'use strict';

var template = require('../../templates/clients/client-payment-list.hbs');
var ClientPaymentRowView = require('./client-payment-row');

var ClientPaymentListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: ClientPaymentRowView,
  childViewContainer: 'tbody.table-items',
  childViewOptions: function(model, index) {
    return {
      clients: this.getOption('clients')
    };
  },
  collectionEvents: {
    'change:is_active': function(model, isActive){
      if (!isActive) {
        this.collection.remove(model);
      }
    },
    'sync add remove': 'render'
  },
  ui: {
    dateMade: 'input[name="date_made"]',
    payee: 'input[name="payee"]',
    prospect: 'input[name="case_prospect"]',
    submitBtn: 'button[type="submit"]',
    form: 'form'
  },
  behaviors: {
    Validate: {
      validateOptions: {
        rules: {
          date_made: { // jshint ignore:line
            required: true
          },
          amount: {
            required: true,
            number: true,
            min: 0.01
          },
          payee: {
            required: true,
            maxlength: 64
          },
          description: {
            required: true,
            maxlength: 64
          },
          case_prospect: { // jshint ignore:line
            required: true
          },
          invoice_number: { // jshint ignore:line
            required: true
          }
        },
        messages: {
          amount: {
            min: 'Must be a positive amount.'
          }
        },
        ignore: '.ignore'
      }
    },
    SubmitForm: {
      submitHandler: 'savePayment'
    }
  },
  filter: function (child) {
    if (this.getOption('filterClient')) {
      return child.get('case_prospect') === this.getOption('filterClient').get('id');
    }
    return true;
  },
  savePayment: function(data){
    var that = this;
    data.case = this.getOption('casefile').get('id');
    var date = data.date_made.split('/');
    data.date_made = date[2] + '-' + date[1] + '-' + date[0];
    if (this.getOption('filterClient')) {
      data.case_prospect = this.getOption('filterClient').get('id')
    }
    this.ui.submitBtn.button('loading');
    this.collection.create(data, {
      wait: true,
      success: function(){
        that.ui.submitBtn.button('reset');
        that.ui.form[0].reset();
      }
    });
  },
  onRender: function() {
    var clients = this.getOption('clients');

    function queryProspects(query){

      function getProspectName(prospect) {
        var name = prospect.getFullName();
        if (prospect.get('contact').get('post_code')) {
          var post = prospect.get('contact').get('post_code');
          name += ' (' + post.trim()+ ')';
        }

        return name;
      }

      var results = clients.filter(function(prospect){
        var name = getProspectName(prospect);
        return name.toLowerCase().indexOf(query.term.toLowerCase()) !== -1;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: getProspectName(prospect)
        };
      });
      query.callback({results: results, more: false});
    }

    this.ui.dateMade.datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
      todayHighlight: true
    });

    this.ui.prospect.select2({
      query: queryProspects,
      initSelection : function (element, callback) {
        var id = element.val();
        var data = {id: id, text: clients.get(id).getFullName()};
        callback(data);
      }
    });

    this.ui.payee.autoComplete({
      source: function(term, suggest){
        term = term.toLowerCase();
        var matches = [];
        clients.each(function(client) {
          var fullName = client.getFullName();
          if (fullName.toLowerCase().indexOf(term) !== -1) {
            matches.push(fullName);
          }
        });
        suggest(matches);
      },
      minChars: 2
    });
  },
  serializeData: function() {
    var data = {};
    if (this.getOption('filterClient')) {
      data.filterClient = this.getOption('filterClient').toJSON();
    }

    // calculate total
    var total = 0;
    var filterClient = this.getOption('filterClient');
    this.collection.forEach(function(payment) {
      if (filterClient && filterClient.get('id') !== payment.get('case_prospect')) {
        return;
      }
      total += payment.get('amount');
    });
    data.total = total;

    return data;
  }
});

module.exports = ClientPaymentListView;
