'use strict';

var template = require('../../templates/clients/correspondence-row.hbs');
var templateEdit =
  require('../../templates/clients/correspondence-row-edit.hbs');
var ConfirmModalView = require('../confirm-modal');

var CorrespondenceRowView = Backbone.Marionette.ItemView.extend({
  className: 'correspondence',
  tagName: 'tr',
  template: template,
  events: {
    'click @ui.deleteBtn': 'deleteCorrespondence',
    'click @ui.editBtn': 'editCorrespondence',
    'click @ui.saveBtn': 'saveCorrespondence',
    'click @ui.cancelEditBtn': 'cancelEditCorrespondence',
    'click @ui.fileLink': 'onClickFileLink',
    'click': 'rowSelected'
  },
  ui: {
    deleteBtn: 'button.delete',
    editBtn: 'button.edit',
    saveBtn: 'button.save',
    cancelEditBtn: 'button.cancel',
    date: 'input[name="date"]',
    incomingSelect: 'select[name="is_incoming"]',
    prospect: 'input[name="case_prospect"]',
    fileLink: 'a.file-link'
  },
  initialize: function(options){
    this.editMode = false;
    this.listenTo(options.casefile.get('files'), 'sync', this.render);
  },
  onClickFileLink: function(e){
    e.stopImmediatePropagation();

    if (!this.ui.fileLink.hasClass('downloadable')) {
      var files = this.getOption('casefile').get('files');
      this.trigger('requestFileDownload',
        files.get(this.model.get('evidence')));
    }
  },
  deleteCorrespondence: function(e){
    e.stopImmediatePropagation();

    //jshint camelcase:false
    var confirmModal = new ConfirmModalView({
      body: 'Are you sure you want to delete this correspondence?'
    });
    confirmModal.render();
    this.listenTo(confirmModal, 'confirmYes', function(){
      this.model.save({is_active: false}, {patch: true, wait: true});
    });
  },
  editCorrespondence: function(e){
    e.stopImmediatePropagation();
    this.template = templateEdit;
    this.editMode = true;
    this.render();
  },
  cancelEditCorrespondence: function(e){
    e.stopImmediatePropagation();
    this.template = template;
    this.editMode = false;
    this.render();
  },
  saveCorrespondence: function(e){
    //jshint camelcase:false
    e.stopImmediatePropagation();
    var that = this;

    this.ui.saveBtn.button('loading');
    var date = this.ui.date.val().split('/');
    var files = this.getOption('casefile').get('files');
    var data = {};
    var existingCaseProspect = this.model.get('case_prospect');
    data.case_prospect = this.ui.prospect.select2('val');
    data.date = date[2] + '-' + date[1] + '-' + date[0];
    data.is_incoming = this.ui.incomingSelect.val();

    this.model.save(data, {success: function() {
      if (data.case_prospect && data.case_prospect != existingCaseProspect) {
        var caseProspectId = parseInt(data.case_prospect, 10);
        var file = files.find(function(f) {
          return f.get('id') === that.model.get('evidence');
        });
        if (file) {
          var clientsAllowed = file.get('clients_allowed');
          if (clientsAllowed.indexOf(caseProspectId) === -1) {
            clientsAllowed.push(caseProspectId);
          }
          if (existingCaseProspect) {
            clientsAllowed = clientsAllowed.filter(function(clientId) {
              return clientId !== existingCaseProspect;
            });
          }
          file.save({ clients_allowed: clientsAllowed }, { patch: true, wait: true });
        }
      }
      that.template = template;
      that.render();
    }});
  },
  rowSelected: function(e){
    if (!_(['INPUT', 'BUTTON']).contains(e.target.tagName)) {
      if (this.$el.hasClass('success')) {
        this.$el.removeClass('success');
      } else {
        this.$el.addClass('success').siblings().removeClass('success');
      }
      this.trigger('selected', this.model, this.$el.hasClass('success'));
    }
  },
  rowDeselect: function() {
    if(this.$el.hasClass('success')) {
      this.$el.removeClass('success');
    }
  },
  serializeData: function(){
    var data = this.model.toJSON();
    var files = this.getOption('casefile').get('files');
    if (files.length && data.evidence) {
      var file = files.find(function(file) {
        return file.get('id') === data.evidence;
      });
      data.evidence = file ? file.toJSON() : null;
    }
    data.today = new Date();

    data.editMode = this.editMode;

    return data;
  },
  onRender: function(){
    var that = this;
    var clients = this.getOption('clients');

    function getProspectName(prospect) {
      var name = prospect.getFullName();
      if (prospect.get('contact').get('post_code')) {
        var post = prospect.get('contact').get('post_code');
        name += ' (' + post.trim() + ')';
      }
      return name;
    }

    function queryProspects(query){
      var results = clients.filter(function(prospect){
        var name = getProspectName(prospect);
        return name.toLowerCase().indexOf(query.term.toLowerCase()) !== -1;
      }).map(function(prospect){
        return {
          id: prospect.get('id'),
          text: getProspectName(prospect)
        };
      });
      query.callback({results: results, more: false});
    }

    if(this.editMode) {
      this.ui.prospect.select2({
        query: queryProspects,
        initSelection : function (element, callback) {
          var id = element.val();
          var data;

          if(clients.get(id) === undefined) {
            data = {id: 0, text: ''};
          } else {
            data = {id: id, text: getProspectName(clients.get(id))};
          }

          callback(data);
        }
      });
      this.ui.prospect.select2('val', that.model.get('case_prospect'));
    }

    this.model.on('manualDeselect', that.rowDeselect, that);

    function findNumParents(model, collection){
      if (!model.has('response_to_correspondence')) {
        return 0;
      } else {
        var parent = collection.get(model.get('response_to_correspondence'));
        if (parent) {
          return findNumParents(parent, collection) + 1;
        } else {
          return 0;
        }
      }
    }

    if (this.model.has('response_to_correspondence')) {
      //work out depth and apply relevant class
      var collection = this.model.collection;
      var numParents = findNumParents(this.model, collection);
      this.$el.addClass('correspondence-child-'+numParents);
    }

    if (this.template === templateEdit) {
      this.ui.date.datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy',
        todayHighlight: true
      });
    }
  }
});

module.exports = CorrespondenceRowView;