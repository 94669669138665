'use strict';
var template = require('../templates/profile-widget.hbs');

var ProfileWidgetView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    sensitiveData: '.sensitive-data',
    archiveData: '.archive-data'
  },
  events: {
    'click @ui.sensitiveData': 'enableSensitiveData',
    'click @ui.archiveData': 'enableArchiveData'
  },
  modelEvents: {
    'authenticated unauthenticated': 'render'
  },
  initialize: function(options) {
    this.listenTo(options.sensitiveDataTokens, 'add sync', this.render);
    this.listenTo(options.archiveDataTokens, 'add sync', this.render);
  },
  enableSensitiveData: function() {
    TCAS.commands.execute('enableSensitiveData');
    return false;
  },
  enableArchiveData: function() {
    TCAS.commands.execute('enableArchiveData');
    return false;
  },
  onRender: function() {
    var that = this;
    var tokens = this.getOption('sensitiveDataTokens');
    if (tokens.length && tokens.at(0).isValid()) {
      var now = new Date().getTime();
      var created = new Date(tokens.at(0).get('created'));
      var expires = created.getTime() + 3600000;
      if (expires > now) {
        setTimeout(function(){
          that.render();
        }, expires - now);
      }
    }
  },
  serializeData: function() {
    var data = {
      user: this.model.toJSON()
    };

    // look for a valid tokens for sensitive and archive
    var tokens = this.getOption('sensitiveDataTokens');
    var validToken = tokens.getValidToken();
    data.hasValidSensitiveDataToken = validToken != null;

    tokens = this.getOption('archiveDataTokens');
    validToken = tokens.getValidToken();
    data.hasValidArchiveDataToken = validToken != null;

    return data;
  }
});

module.exports = ProfileWidgetView;