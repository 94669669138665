'use strict';

var SensitiveDataTokens = require('./sensitivedatatokens');
var ArchiveDataToken = require('./archivedatatoken');

var ArchiveDataTokens = SensitiveDataTokens.extend({
  model: ArchiveDataToken,
  urlRoot: '/api/archivedatatokens/'
});

module.exports = ArchiveDataTokens;
