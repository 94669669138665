'use strict';

var Handlebars = require('hbsfy/runtime');
var template = require('../../templates/allocations/casemanager-select.hbs');
var User = require('../../models/users/user');
var Users = require('../../models/users/users');

var CaseManagerSelectView = Backbone.Marionette.ItemView.extend({
  template: template,
  ui: {
    select: '.case-manager-select',
  },
  getSelectedCaseManager: function() {
    return this.ui.select.val();
  },
  getSelectedCaseManagerName: function() {
    var data = this.ui.select.select2('data');
    if (data) {
      var s = Handlebars.Utils.escapeExpression(data.first_name + ' ' + data.last_name);
      if (!data.is_active) {
        s += ' <span class="badge">Inactive</span>';
      }
      return s;
    }
    return null;
  },
  onShow: function() {
    function formatUser(user, container, query){
      var s = Handlebars.Utils.escapeExpression(user.first_name + ' ' + user.last_name); // jshint ignore:line
      s = s.replace(new RegExp(query.term, 'gi'), function(q){
        return '<u>' + q + '</u>';
      });
      if (!user.is_active) {
        s += ' <span class="badge">Inactive</span>';
      }

      return s;
    }

    var that = this;

    var caseWorkersUrl = '/api/users/?groups=' +
      User.CASE_WORKER_GROUPS.join('&groups=');

    // check for additional filtering by team
    if (this.getOption('team')) {
      caseWorkersUrl += '&user_teamallocation__team=' + this.getOption('team');
    }

    this.ui.select.select2({
      placeholder: 'Case manager name',
      minimumInputLength: 1,
      multiple: this.getOption('multiple'),
      ajax: {
        url: caseWorkersUrl,
        dataType: 'json',
        quietMillis: 250,
        data: function(term, page){
          return { search: term, page: page };
        },
        results: function(data){
          return { results: data.results, more: !!data.next };
        },
        cache: true
      },
      formatResult: formatUser,
      formatSelection: formatUser,
      allowClear: true,
      initSelection: function(element, callback) {
        if(that.getOption('user')) {
          var user = that.getOption('user');
          if (Array.isArray(user)) {
            var users = new Users();
            users.setFilters({
              'id__in': user.join(',')
            });
            users.fetch().then(function(){
              callback(users.toJSON());
            });
          } else {
            var user = new User({
              id: that.getOption('user')
            });
            user.fetch().then(function () {
              callback(user.toJSON());
            });
          }
        }
      }
    });
    this.ui.select.on('change', function(e) {
      that.trigger('selected', e.val);
    });
  },
  onDestroy: function() {
    this.ui.select.off('change');
  },
  serializeData: function() {
    return this.options;
  }
});

module.exports = CaseManagerSelectView;
