'use strict';

var Chart = require('chart.js');
require('chart.piecelabel.js');
var Util = require('../../../util');

var TimetrackPieChartView = Backbone.Marionette.ItemView.extend({
  template: false,
  className: 'timetrack-pie-chart',
  ui: {
    link: 'a'
  },
  events: {
    'click @ui.link': 'onClickUrl'
  },
  onClickUrl: function(e) {
    TCAS.commands.execute('popoutURL', e.currentTarget.href, 'TimeTrack Activity');

    return false;
  },
  onRender: function() {
    if (this.getOption('clickUrl')) {
      this.$el.html(
        '<a href="'+this.getOption('clickUrl') + '" class="timetrack-pie-chart" target="_blank"><canvas width="400" height="400"></canvas></a>'
      );
    } else {
      this.$el.html('<canvas width="400" height="400"></canvas>');
    }

    var context = this.$('canvas')[0].getContext('2d');

    var availableColours = [
      '#137D79',
      '#595CA5',
      '#00A8B0',
      '#7A9B62',
      '#4097DB',
      '#D1517A',
      '#ADC763',
      '#94679C',
      '#F8DEAE',
      '#E8A43D'
    ];

    var backgroundColors = availableColours.slice(0, this.getOption('labels').length);

    var data = {
      datasets: [{
        data: this.getOption('data'),
        backgroundColor: this.getOption('backgroundColor') ?
                         this.getOption('backgroundColor') : backgroundColors
      }],
      labels: this.getOption('labels')
    };

    var chart = new Chart(context, {
      type: 'pie',
      data: data,
      options: {
        title: {
          display: true,
          fontSize: 18,
          fontColor: '#555',
          fontFamily: "'Questrial', 'Arial', 'Helvetica', sans-serif",
          text: this.getOption('title')
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label ||
                data.labels[tooltipItem.index];

              if (label) {
                label += ': ';
              }

              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              label += Util.formatMinutesAsTime(value);

              return label;
            }
          }
        },
        pieceLabel: {
          // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          render: 'percentage',
          fontSize: 16,
          fontStyle: 'bold',
          fontColor: '#fff'
        }
      }
    });
  }
});

module.exports = TimetrackPieChartView;
