// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depths[1] != null ? depths[1].filterCategoryId : depths[1]),"==",(depth0 != null ? depth0.id : depth0),{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                    "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " selected=\"selected\"\n                                    ";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "        <tr>\n            <td>"
    + alias3((helpers.categoryField || (depth0 && depth0.categoryField) || alias2).call(alias1,(depth0 != null ? depth0.category : depth0),"name",{"name":"categoryField","hash":{},"data":data}))
    + "</td>\n            <td><a href=\"/cases/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-case-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"popout\" target=\"_blank\">"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</a></td>\n            <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"MMM",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.caseManagerId : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td>"
    + alias3((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.fee : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.revenuePotential : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedValueTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.forecastConfirmedTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.forecastPendingTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.pledgedValueTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td>"
    + alias3(((helper = (helper = helpers.statusName || (depth0 != null ? depth0.statusName : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"statusName","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.activeCasesTotal : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <tr>\n            <td>Cases "
    + alias4(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + "</td>\n            <td></td>\n            <td></td>\n            <td>"
    + alias4(((helper = (helper = helpers.activeCasesTotal || (depth0 != null ? depth0.activeCasesTotal : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activeCasesTotal","hash":{},"data":data}) : helper)))
    + "</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n        </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            <th>Revenue Booked "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "        <tr>\n            <td>"
    + alias3((helpers.categoryField || (depth0 && depth0.categoryField) || alias2).call(alias1,(depth0 != null ? depth0.category : depth0),"name",{"name":"categoryField","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</td>\n            <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"MMM",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depth0 != null ? depth0.created : depth0),"YYYY",{"name":"formatDate","hash":{},"data":data}))
    + "</td>\n            <td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.caseManagerId : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td>"
    + alias3((helpers.formatPercent || (depth0 && depth0.formatPercent) || alias2).call(alias1,(depth0 != null ? depth0.fee : depth0),{"name":"formatPercent","hash":{},"data":data}))
    + "</td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.bookedByYear : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias2).call(alias1,(depth0 != null ? depth0.bookedValueTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.concludedDate : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            <td>"
    + alias3(((helper = (helper = helpers.statusName || (depth0 != null ? depth0.statusName : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"statusName","hash":{},"data":data}) : helper)))
    + "</td>\n        </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.concludedDate : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"16":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.bookedRevenueTotal : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <td>"
    + container.escapeExpression(((helper = (helper = helpers.bookedCasesTotal || (depth0 != null ? depth0.bookedCasesTotal : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bookedCasesTotal","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "            <td></td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing;

  return "<div class=\"panel panel-default\" role=\"tablist\">\n    <div class=\"panel-heading\" id=\"commercial-filter-heading\" role=\"tab\">\n        <a href=\"#\" id=\"commercial-filter-toggle\" data-toggle=\"collapse\" data-target=\"#commercial-filter-panel\"\n            aria-expanded=\"true\" aria-controls=\"filter-accordion\">\n            <h4 class=\"panel-title\">Filter</h4>\n        </a>\n    </div>\n    <div id=\"commercial-filter-panel\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\n        aria-labelledby=\"commercial-filter-heading\">\n        <div class=\"panel-body\">\n            <form class=\"category-filter\">\n                <fieldset>\n                    <div class=\"row\">\n                        <div class=\"form-group col-sm-3\">\n                            <label for=\"commercial-category\" class=\"control-label\">Commercial Referrer</label>\n                            <select id=\"commercial-category\" class=\"form-control commercial-category\" name=\"category\">\n                                <option value=\"0\">All</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n                    </div>\n                    <button type=\"submit\" class=\"btn btn-default btn-sm\" id=\"commercial-filter-submit\"\n                        data-loading-text=\"Please wait...\">\n                        Filter\n                    </button>\n                </fieldset>\n            </form>\n        </div>\n    </div>\n</div>\n<table class=\"table table-condensed commercial-summary-report-granular-active-table\">\n    <thead>\n        <tr>\n            <th>Commercial Referrers</th>\n            <th>Case Name</th>\n            <th>Month Allocated</th>\n            <th>Year Allocated</th>\n            <th>Cases Manager</th>\n            <th>Value</th>\n            <th>Fee</th>\n            <th>Revenue Potential</th>\n            <th>Booked</th>\n            <th>Confirmed</th>\n            <th>Pending Confirmation</th>\n            <th>Pledged</th>\n            <th>Case Status</th>\n        </tr>\n    </thead>\n    <tbody class=\"table-items\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.casesActive : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n    <tbody class=\"table-total\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.yearsData : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <tr>\n            <td>Total cases</td>\n            <td>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.casesActive : depth0)) != null ? stack1.length : stack1), depth0))
    + "</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n        </tr>\n        <tr>\n            <td>Total</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesActiveTotalValue : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td></td>\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesActiveTotalRevenue : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesActiveTotalBooked : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesActiveTotalForecastConfirmed : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesActiveTotalForecastPending : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesActiveTotalPledged : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td></td>\n        </tr>\n    </tbody>\n</table>\n<table class=\"table table-condensed commercial-summary-report-concluded-table\">\n    <thead>\n        <tr>\n            <th>Commercial Referrers</th>\n            <th>Case Name</th>\n            <th>Month Allocated</th>\n            <th>Year Allocated</th>\n            <th>Cases Manager</th>\n            <th>Value</th>\n            <th>Fee</th>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.years : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <th>Total</th>\n            <th>Date Completed</th>\n            <th>Case Status</th>\n        </tr>\n    </thead>\n    <tbody class=\"table-items\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.casesBooked : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n    <tbody class=\"table-total\">\n        <tr>\n            <td>Generated Revenue</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.yearsData : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesBookedTotalBooked : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td></td>\n            <td></td>\n        </tr>\n        <tr>\n            <td>Number of cases</td>\n            <td>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.casesBooked : depth0)) != null ? stack1.length : stack1), depth0))
    + "</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.yearsData : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <td></td>\n            <td></td>\n            <td></td>\n        </tr>\n        <tr>\n            <td>Value</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n            "
    + alias3((helpers.currencyTableCell || (depth0 && depth0.currencyTableCell) || alias4).call(alias1,(depth0 != null ? depth0.casesBookedTotalValue : depth0),{"name":"currencyTableCell","hash":{},"data":data}))
    + "\n            <td></td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.years : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <td></td>\n            <td></td>\n            <td></td>\n        </tr>\n    </tbody>\n</table>";
},"useData":true,"useDepths":true});
