'use strict';

var template = require('../../templates/clients/client-info.hbs');

var ClientInfoView = Backbone.Marionette.LayoutView.extend({
  template: template,
  serializeData: function() {
    var data = this.model.toJSON();
    if (data.contact.gender === 'unspecified') {
      data.contact.gender = 'Not specified';
    } else if (data.contact.gender === 'female') {
      data.contact.gender = 'Female';
    } else if (data.contact.gender === 'male') {
      data.contact.gender = 'Male';
    }

    return data;
  }
});

module.exports = ClientInfoView;
