'use strict';

var moment = require('moment');
var template = require('../../templates/events/event-details.hbs');
var ConfirmModal = require('../confirm-modal');

var EventDetailsModalView = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'modal fade event-details-modal',
  events: {
    'click @ui.acceptBtn': 'onClickAccept',
    'click @ui.declineBtn': 'onClickDecline',
    'click @ui.deleteBtn': 'onClickDelete',
    'click @ui.proposeBtn': 'onClickPropose',
    'click @ui.cancelProposal': 'onClickCancelProposal',
    'click @ui.acceptProposalBtn': 'onClickAcceptProposal',
    'click .popout-case': 'popoutCase'
  },
  ui: {
    acceptBtn: '.accept',
    declineBtn: '.decline',
    proposeBtn: '.propose',
    acceptProposalBtn: '.accept-proposal',
    editBtn: '.edit',
    deleteBtn: '.delete',
    groupProposal: '.group-proposal',
    cancelProposal: '.cancel-proposal'
  },
  triggers: {
    'click @ui.editBtn': 'event:edit'
  },
  behaviors: {
    SubmitForm: {
      selector: 'form.proposal-form',
      submitHandler: 'onSubmitProposal'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          start_date: {
            required: true
          },
          start_time: {
            time: true
          },
          end_time: {
            time: true
          }
        }
      }
    }
  },
  close: function() {
    this.$el.modal('hide');
  },
  popoutCase: function(e){
    e.preventDefault();
    TCAS.commands.execute('popoutCase', this.model.get('case'));

    return false;
  },
  onClickAccept: function() {
    var that = this;
    var userId = TCAS.session.get('id');
    var invite = this.getOption('invites').find(function(inv) {
      return inv.get('invitee') === userId;
    });

    invite.save({ status: 'accepted' }, { wait: true }).then(function() {
      that.render();
    });
  },
  onClickDecline: function() {
    var that = this;
    var userId = TCAS.session.get('id');
    var invite = this.getOption('invites').find(function(inv) {
      return inv.get('invitee') === userId;
    });

    invite.save({ status: 'declined' }, { wait: true }).then(function() {
      that.render();
    });
  },
  onClickPropose: function() {
    this.ui.groupProposal.removeClass('hidden');
    this.ui.proposeBtn.addClass('active');
  },
  onClickAcceptProposal: function(e) {
    var that = this;
    var inviteId = this.$(e.currentTarget).attr('data-invite-id');
    var invite = this.getOption('invites').find(function(inv) {
      return inv.get('id') == inviteId;
    });

    // use the proposed times in the invite to update the calendar event
    if (invite) {
      this.model.save({
        start: invite.get('alternative_start'),
        end: invite.get('alternative_end')
      }, {
        wait: true,
        success: function() {
          that.trigger('calendarevent:change', that.model);
        }
      });
    }
  },
  onClickCancelProposal: function() {
    this.ui.groupProposal.addClass('hidden');
    this.ui.proposeBtn.removeClass('active');
  },
  onSubmitProposal: function(data) {
    var that = this;
    var userId = TCAS.session.get('id');
    var invite = this.getOption('invites').find(function(inv) {
      return inv.get('invitee') === userId && !inv.get('is_revoked');
    });
    var start = moment(data.start_date);
    var end = null;

    if (data.start_time) {
      if (data.start_time.indexOf(':') === -1) {
        data.start_time = data.start_time.substr(0, 2)+':'+data.start_time.substr(2, 4);
      }
      var timeSplit = data.start_time.split(':');
      start.hour(timeSplit[0]);
      start.minute(timeSplit[1]);
    }
    if (data.end_date) {
      end = moment(data.end_date);
      if (data.end_time) {
        if (data.end_time.indexOf(':') === -1) {
          data.end_time = data.end_time.substr(0, 2)+':'+data.end_time.substr(2, 4);
        }
        timeSplit = data.end_time.split(':');
        end.hour(timeSplit[0]);
        end.minute(timeSplit[1]);
      }
    }

    var proposalData = { alternative_start: start.toISOString() };
    if (end) {
      proposalData.alternative_end = end.toISOString();
    } else {
      proposalData.alternative_end = null;
    }
    proposalData.status = 'declined';

    invite.save(proposalData, {
      wait: true,
      success: function() {
        that.onClickCancelProposal();
      }
    });
  },
  onClickDelete: function() {
    var event = this.getOption('event');
    var msg = 'Are you sure you want to delete this event?';
    if (event.id.indexOf('reminder') === 0) {
      msg = 'Are you sure you want to delete this reminder?';
    }

    var confirmModal = new ConfirmModal({
      title: 'Are you sure?',
      body: msg
    });
    this.listenTo(confirmModal, 'confirmYes', function() {
      if (event.id.indexOf('reminder') === 0) {
        this.trigger('reminder:delete', this.model);
      } else {
        this.trigger('calendarevent:delete', this.model);
      }
    });
    confirmModal.render();
  },
  onRender: function() {
    $.validator.addMethod('time', function(value, element) {
      return this.optional(element) ||
        /^(([0-1]?[0-9])|([2][0-3])):?([0-5]?[0-9])(:([0-5]?[0-9]))?$/i.
        test(value);
    }, 'Please enter a valid time (hh:mm or hhmm).');

    this.$el.appendTo('body');
    this.$el.modal({
      show: true
    });

    this.listenTo(this.getOption('invites'), 'change', this.render);
    this.$('[title]').tooltip();
  },
  serializeData: function() {
    var event = this.getOption('event');
    var eventTypes = this.getOption('calendarEventTypes');
    var isReminder = event.id.indexOf('reminder') === 0;
    var isLeaveEvent = false;
    var isEventUser = false;
    if (!isReminder) {
      var eventType = eventTypes.get(this.model.get('event_type'));
      isLeaveEvent = eventType.get('is_leave_event');
      isEventUser = this.model.get('users').indexOf(TCAS.session.get('id')) !== -1;
    }

    var end = moment(event.end);
    if (event.allDay) {
      end.subtract(1, 'day');
    }

    var data = {
      title: event.title,
      start: event.start,
      end: end,
      allDay: event.allDay,
      isRecurring: event.isRecurring,
      isReminder: isReminder,
      recurEvery: event.recurEvery,
      recurInterval: event.recurInterval,
      invites: this.getOption('invites') ? this.getOption('invites').toJSON() : [],
      users: event.users,
      sessionUserId: TCAS.session.get('id'),
      isEventUser: isEventUser,
      canEdit: TCAS.session.isAdmin() || event.users.indexOf(TCAS.session.get('id')) !== -1 ||
               (isLeaveEvent && TCAS.session.hasPerm('add_leave_events'))
    };

    if (isReminder && this.model.get('case')) {
      data.case = this.model.get('case');
    }

    return data;
  }
});

module.exports = EventDetailsModalView;
