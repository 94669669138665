'use strict';
var Contact = require('../users/contact');
var User = require('../users/user');
var ClientMilestones = require('./clientmilestones');

var CaseProspect = Backbone.Model.extend({
  urlRoot: '/api/caseprospects/',
  parse: function(response){
    response.contact = new Contact(response.contact, {parse: true});
    response.correspondence_preference =
      new Backbone.Model(response.correspondence_preference, {parse: true});
    response.milestones = new ClientMilestones([]);
    if (response.user) {
      response.user = new User(response.user, {parse: true});
    }
    return response;
  },
  toJSON: function(){
    var data = Backbone.Model.prototype.toJSON.call(this);
    if(this.get('contact')) {
      data.contact = this.get('contact').toJSON();
    }

    if (this.get('correspondence_preference')) {
      data.correspondence_preference =
        this.get('correspondence_preference').toJSON();
    }

    if(this.get('user')) {
      data.user = this.get('user').toJSON();
    }

    return data;
  },
  getFullName: function(showMiddleName){
    return this.get('contact').getFullName(showMiddleName);
  },
  getEntitlementFraction: function() {
    if (this.get('entitlement_denominator') === 0) {
      return 0;
    }
    return this.get('entitlement_numerator') / this.get('entitlement_denominator');
  },
  calculateRevenueAndClientDistribution: function(casefile) {
    if (!this.get('entitlement_numerator')) {
      return {
        vat: 0,
        revenue: 0,
        clientDistribution: 0,
        clientDistributionBeforeFees: 0
      };
    }

    // work out client distribution
    var assetValue = casefile.get('asset').getCurrentValuation();
    var clientDistribution = assetValue.get('capital_value') + assetValue.get('income_value');
    var vat = 0;

    clientDistribution /= this.get('entitlement_denominator');
    clientDistribution *= this.get('entitlement_numerator');
    var revenue = (clientDistribution / 100) * this.get('fee_percentage_agreed');

    if (this.get('is_vat_applicable')) {
      vat = revenue * (this.get('vat_rate') / 100);
    }

    return {
      vat: vat,
      revenue: revenue,
      clientDistribution: clientDistribution - vat - revenue,
      clientDistributionBeforeFees: clientDistribution
    };
  },
  fetchMilestones: function() {
    var milestones = this.get('milestones');
    milestones.urlRoot = '/api/clientmilestones/?client='+this.get('id');
    return milestones.fetch();
  },
  isAccounts: function() {
    return this.get('type') === CaseProspect.TYPES.accounts;
  },
  isClient: function() {
    return this.get('type') === CaseProspect.TYPES.client;
  },
  isBeneficiary: function() {
    return this.get('type') === CaseProspect.TYPES.beneficiary;
  },
  isAncestor: function() {
    return this.get('type') === CaseProspect.TYPES.ancestor;
  },
  isContact: function() {
    return this.get('type') === CaseProspect.TYPES.contact;
  }
});

CaseProspect.TYPES = {
  contact: 'contact',
  beneficiary: 'beneficiary',
  ancestor: 'ancestor',
  client: 'client',
  accounts: 'accounts'
};

module.exports = CaseProspect;