'use strict';
var Message = require('./message');
var BaseCollection = require('../base-collection');

var Messages = BaseCollection.extend({
  urlRoot: '/api/messages/',
  model: Message,
  parse: function(response){
    response = BaseCollection.prototype.parse.call(this, response);

    function findParents(message){
      if (!message.message) {
        message.parents = [];
        return [];
      } else {
        if (message.hasOwnProperty('parents')) {
          return message.parents;
        }
        var parent = _(response).find(function(m){
          return m.id === message.message;
        });
        if (parent) {
          message.parents = findParents(parent).concat([parent.id]);
        } else {
          message.parents = [];
        }

        return message.parents;
      }
    }

    _(response).each(findParents);

    return response;
  },
  findParents: function(message) {
    if (!message.has('message')) {
      message.set('parents', []);
      return [];
    } else {
      if (message.has('parents')) {
        return message.get('parents');
      }
      var parent = this.find(function(m){
        return m.get('id') === message.get('message');
      });
      if (parent) {
        message.set('parents',
          this.findParents(parent).concat([parent.get('id')]));
      } else {
        message.set('parents', []);
      }
    }

    return message.get('parents');
  },
  regenerateOrderedTree: function(){
    var that = this;

    function populateChildren(message){
      message.children = that.filter(function(child){
        return child.get('message') === message.get('id');
      });
      message.children = _.sortBy(message.children, function(child){
        return new Date(child.get('published_date')).getTime();
      });
      _(message.children).each(function(child){
        populateChildren(child);
      });
    }

    var tree = this.filter(function(message){
      return !message.get('message');
    });
    tree = _.sortBy(tree, function(message){
      return new Date(message.get('published_date')).getTime();
    });
    _(tree).each(function(message){
      populateChildren(message);
    });
    this.tree = { children: tree };
  },
  sort: function(options){
    //regenerate the tree before sorting, so comparator uses correct index
    this.regenerateOrderedTree();
    return BaseCollection.prototype.sort.call(this, options);
  },
  comparator: function(message) {
    if (!message.has('parents')) {
      this.findParents(message);
    }

    var queue = [].concat(this.tree.children);
    for (var i = 0; i < queue.length; i += 1) {
      if (queue[i].children.length) {
        queue.splice.apply(queue, [i + 1, 0].concat(queue[i].children));
      }
    }
    for (i = 0; i < queue.length; i += 1) {
      if (queue[i] === message) {
        return i;
      }
    }
    return -1;
  },
  comparators: {
    'date-asc': function(message) {
      return message.get('published_date');
    },
    'date-desc': function(message) {
      return -message.get('published_date');
    }
  },
  setSortOrder: function(sortOrder) {
    if(this.comparators.hasOwnProperty(sortOrder)) {
      this.comparator = this.comparators[sortOrder];
    }
    this.sort();
  }
});

module.exports = Messages;