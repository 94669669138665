'use strict';
var template = require('../../templates/cases/casefile-allocate-row.hbs');
var CaseFile = require('../../models/cases/casefile');

var CaseFileAllocateRowView = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  className: 'case-row',
  model: CaseFile,
  ui: {
    popoutCase: '.popout'
  },
  events: {
    'click': 'selectModel',
    'click @ui.popoutCase': 'popoutCase'
  },
  selectModel: function() {
    var checked = this.model.get('checked') || false;
    checked = !checked;
    this.model.set('checked', checked);
    this.checkModel();

    this.trigger('child:clicked');
  },
  checkModel: function() {
    var checked = this.model.get('checked');

    if(checked) {
      this.$el.addClass('success');
    } else {
      this.$el.removeClass('success');
    }

    this.render();
  },
  popoutCase: function(e){
    TCAS.commands.execute('popoutCase', this.model);
    
    return false;
  },
  onShow: function() {
    this.checkModel();
  }
});

module.exports = CaseFileAllocateRowView;