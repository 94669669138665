'use strict';
var CaseFileImportRowView = require('./casefile-import-row');
var CaseFileNoRowsView = require('./casefile-no-rows');
var template = require('../../templates/cases/casefile-import-list.hbs');

var CaseFilesTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'table',
  id: 'casefiles',
  className: 'table table-hover table-condensed',
  childViewContainer: 'tbody',
  template: template,
  childView: CaseFileImportRowView,
  emptyView: CaseFileNoRowsView
});

module.exports = CaseFilesTableView;