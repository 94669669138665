'use strict';

var TimetrackPieChartView = require('./timetrack-pie-chart');
var TimeTrack = require('../../../models/timetrack/timetrack');

var TimetrackIndividualPieChartsView = Backbone.Marionette.CollectionView.extend({
  childView: TimetrackPieChartView,
  childViewOptions: function(user) {
    var timetracks = this.getOption('timetracks');
    var availableColours = this.getOption('availableColours');
    var activityOrder = this.getOption('activityOrder');

    // do same as above but for each user
    var activityMap = {};
    var activityTypes = [];

    timetracks.forEach(function(entry) {
      if (entry.get('owner') === user.id) {
        var activityType = entry.get('activity');
        var task = entry.get('task');
        if (!activityMap.hasOwnProperty(activityType)) {
          activityMap[activityType] = {};
          activityTypes.push(activityType);
        }

        if (!activityMap[activityType].hasOwnProperty(task)) {
          activityMap[activityType][task] = 0;
        }
        activityMap[activityType][task] += entry.totalMinutes();
      }
    });

    // order activityTypes based on activityOrder
    activityTypes.sort(function(a, b){
      var indexA = activityOrder.indexOf(a);
      var indexB = activityOrder.indexOf(b);
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      } else {
        return 0;
      }
    });

    var activityData = activityTypes.map(function(activityType) {
      var tasksMap = activityMap[activityType];
      var taskOrder = TimeTrack.ACTIVITY_TASKS[activityType];
      var taskData = [];
      var totalMinutes = 0;

      taskOrder.forEach(function(taskType) {
        if (tasksMap.hasOwnProperty(taskType)) {
          taskData.push({
            task: taskType,
            name: TimeTrack.TASKS[taskType],
            minutes: tasksMap[taskType]
          });
          totalMinutes += tasksMap[taskType];
        }
      });

      return {
        activity: activityType,
        name: TimeTrack.ACTIVITIES[activityType],
        tasks: taskData,
        totalMinutes: totalMinutes
      };
    });

    return {
      data: activityData.map(function(activity) {
        return activity.totalMinutes;
      }),
      labels: activityData.map(function(activity) {
        return activity.name;
      }),
      backgroundColor: activityData.map(function(activity) {
        return availableColours[activity.activity];
      }),
      title: 'Spread of Actual Activity - ' + user.getFullName()
    }
  },
  // we are only interested in showing user data which has some timetrack entries
  filter: function(user) {
    var userId = user.get('id');
    return this.getOption('timetracks').some(function(entry) {
      return entry.get('owner') === userId;
    });
  }
});

module.exports = TimetrackIndividualPieChartsView;
