// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <div class=\"btn-group\" role=\"group\" aria-label=\"Action button group\">\n        <button type=\"button\" class=\"btn btn-danger btn-sm delete-client\"><i class=\"fa fa-trash\"></i> Delete</button>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.userdetail : stack1)) != null ? stack1.is_locked : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <button type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\"\n          aria-expanded=\"false\">\n          Convert to <i class=\"fa fa-caret-down\"></i>\n        </button>\n        <ul class=\"dropdown-menu\">\n          <li "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"contact",{"name":"ifEqual","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a href=\"#\" class=\"convert-role\"\n              data-role-type=\"contact\">Contact</a></li>\n          <li "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"ancestor",{"name":"ifEqual","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a href=\"#\" class=\"convert-role\"\n              data-role-type=\"ancestor\">Ancestor</a></li>\n          <li "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"beneficiary",{"name":"ifEqual","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a href=\"#\" class=\"convert-role\"\n              data-role-type=\"beneficiary\">Beneficiary</a></li>\n          <li "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"client",{"name":"ifEqual","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a href=\"#\" class=\"convert-role\"\n              data-role-type=\"client\">Client</a></li>\n        </ul>\n      </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-default btn-sm reset-password\">Reset Password</button>\n          <button type=\"button\" class=\"btn btn-default btn-sm unlock\">Unlock</button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "class=\"disabled\" ";
},"6":function(container,depth0,helpers,partials,data) {
    return "nav-pills nav-stacked";
},"8":function(container,depth0,helpers,partials,data) {
    return "nav-tabs";
},"10":function(container,depth0,helpers,partials,data) {
    return "class=\"active\" ";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-relationships",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-relationships-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-relationships-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\"\n      data-toggle=\"tab\">Relationships</a></li>\n  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-financials",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-financials-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-financials-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\"\n      data-toggle=\"tab\">Financials</a></li>\n  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-income",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-income-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-income-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\" data-toggle=\"tab\">Payments</a></li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAccounts : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-milestones",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-milestones-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-milestones-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\"\n      data-toggle=\"tab\">Milestones</a></li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-messages",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-messages-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-messages-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\"\n      data-toggle=\"tab\">Email"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notificationBadgeCount : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a></li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return " <span class=\"badge badge-danger\"\n        title=\"New messages since last viewed\">"
    + container.escapeExpression(((helper = (helper = helpers.notificationBadgeCount || (depth0 != null ? depth0.notificationBadgeCount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notificationBadgeCount","hash":{},"data":data}) : helper)))
    + "</span>";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <li role=\"presentation\"\n    class=\"questionnaire-tab hidden "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-questionnaire",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"><a\n      href=\"#client-questionnaire-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"client-questionnaire-tab\" aria-controls=\"client-questionnaire-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n      role=\"tab\" data-toggle=\"tab\">Questionnaire</a></li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "active";
},"23":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\" ";
},"25":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\" ";
},"27":function(container,depth0,helpers,partials,data) {
    return "hide";
},"29":function(container,depth0,helpers,partials,data) {
    return "";
},"31":function(container,depth0,helpers,partials,data) {
    return "invisible";
},"33":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"\n                    ";
},"35":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"37":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"form-group col-md-6\">\n            <label class=\"control-label\">VAT</label>\n            <p class=\"form-control-static\">"
    + container.escapeExpression((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.vat : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</p>\n          </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"form-group\">\n          <div class=\"checkbox\">\n            <label><input type=\"checkbox\" class=\"pif-dissenter\" name=\"is_pif_dissenter\" value=\"1\"\n                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_pif_dissenter : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> PIF Dissenter</label>\n          </div>\n        </div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"form-group\">\n          <div class=\"checkbox\">\n            <label><input type=\"checkbox\" class=\"beneficiary-unverified\" name=\"is_unverified_beneficiary\" value=\"1\"\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_unverified_beneficiary : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Unverified Beneficiary</label>\n          </div>\n        </div>\n        <div class=\"form-group\">\n          <div class=\"checkbox\">\n            <label><input type=\"checkbox\" class=\"beneficiary-missing\" name=\"is_missing\" value=\"1\"\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_missing : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Known Missing Beneficiary</label>\n          </div>\n        </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"form-group\">\n          <div class=\"checkbox\">\n            <label><input type=\"checkbox\" class=\"deceased-asset-holder\" name=\"is_deceased_asset_holder\" value=\"1\"\n                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_deceased_asset_holder : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> The Deceased / Asset Holder?</label>\n          </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda;

  return "<div class=\"client-actions-bar\">\n  <div class=\"row\">\n    <div class=\"col-md-6\">\n      <h4>"
    + alias3((helpers.prospectFullName || (depth0 && depth0.prospectFullName) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"prospectFullName","hash":{},"data":data}))
    + " "
    + alias3((helpers.prospectObjectRoleLabel || (depth0 && depth0.prospectObjectRoleLabel) || alias2).call(alias1,depth0,{"name":"prospectObjectRoleLabel","hash":{},"data":data}))
    + "<span class=\"client-meta\"></span></h4>\n    </div>\n    <div class=\"col-md-6 text-right\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isAccounts : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n\n<ul class=\"nav "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\" role=\"tablist\">\n  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-contact-information",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-contact-information-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-contact-information-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\"\n      data-toggle=\"tab\">Information</a></li>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isAccounts : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-notes",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-notes-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-notes-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\" data-toggle=\"tab\">Notes</a></li>\n  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-correspondence",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-correspondence-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-controls=\"client-correspondence-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\"\n      data-toggle=\"tab\">Correspondence</a></li>\n  <li role=\"presentation\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-files",{"name":"ifEqual","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><a\n      href=\"#client-files-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"client-files-tab\" aria-controls=\"client-files-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"tab\"\n      data-toggle=\"tab\">Files</a></li>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isContact : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isAccounts : depth0),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n\n<div class=\"tab-content\">\n  <div role=\"tabpanel\" class=\"tab-pane "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-contact-information",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-contact-information-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <form class=\"client-address-form\">\n      <div class=\"panel-body\">\n        <div class=\"alert-area\"></div>\n        <div class=\"row\">\n          <div class=\"col-md-6\">\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-title\">Title</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-title\" class=\"form-control input-sm\" name=\"title\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.title : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-firstname\">First name</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-firstname\" class=\"form-control input-sm\" name=\"first_name\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.first_name : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-middlename\">Middle name</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-middlename\" class=\"form-control input-sm\" name=\"middle_name\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.middle_name : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-lastname\">Last name</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-lastname\" class=\"form-control input-sm\" name=\"last_name\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.last_name : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-surname-at-birth\">Maiden name/Surname at Birth</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-surname-at-birth\" class=\"form-control input-sm\"\n                name=\"surname_at_birth\" value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.surname_at_birth : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-former-names\">Former name(s)</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-former-names\" class=\"form-control input-sm\" name=\"former_names\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.former_names : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-gender\">Gender</label>\n              <select id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-gender\" class=\"form-control\" name=\"gender\">\n                <option value=\"unspecified\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.gender : stack1),"unspecified",{"name":"ifEqual","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                  Not specified</option>\n                <option value=\"male\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.gender : stack1),"male",{"name":"ifEqual","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Male</option>\n                <option value=\"female\" "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.gender : stack1),"female",{"name":"ifEqual","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">Female\n                </option>\n              </select>\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-dob\">Date of Birth</label>\n              <input type=\"date\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-dob\" class=\"form-control input-sm\" name=\"date_of_birth\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.date_of_birth : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-dod\">Date of Death</label>\n              <input type=\"date\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-dod\" class=\"form-control input-sm\" name=\"date_of_death\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.date_of_death : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-ni-number\">National Insurance Number</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-ni-number\" class=\"form-control input-sm\" name=\"ni_number\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.ni_number : stack1), depth0))
    + "\" />\n            </div>\n\n            <button type=\"button\" class=\"btn btn-default btn-xs pull-right copy\"\n              data-clipboard-text=\""
    + alias3((helpers.contactClipboard || (depth0 && depth0.contactClipboard) || alias2).call(alias1,(depth0 != null ? depth0.contact : depth0),{"name":"contactClipboard","hash":{},"data":data}))
    + "\"><i class=\"fa fa-copy\"></i> Copy</button>\n          </div>\n\n          <div class=\"col-md-6\">\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-address1\">Address line 1</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-address1\" class=\"form-control input-sm\" name=\"address1\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.address1 : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-address2\">Address line 2</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-address2\" class=\"form-control input-sm\" name=\"address2\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.address2 : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-city\">City</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-city\" class=\"form-control input-sm\" name=\"city\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.city : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-postcode\">Post code</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-postcode\" class=\"form-control input-sm\" name=\"post_code\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.post_code : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-country\">Country</label>\n              <div class=\"countries\"></div>\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-phone\">Phone</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-phone\" class=\"form-control input-sm\" name=\"phone\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.phone : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-mobile\">Mobile</label>\n              <input type=\"text\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-mobile\" class=\"form-control input-sm\" name=\"mobile\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.mobile : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-email\">Email</label>\n              <input type=\"email\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-email\" class=\"form-control input-sm\" name=\"email\"\n                value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.email : stack1), depth0))
    + "\" />\n            </div>\n            <div class=\"form-group\">\n              <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-other\">Other</label>\n              <textarea id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-other\" class=\"form-control\" rows=\"3\"\n                name=\"legacy_address\">"
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.contact : depth0)) != null ? stack1.legacy_address : stack1), depth0))
    + "</textarea>\n            </div>\n\n            <div class=\"form-group correspondence-pref\">\n              <label>Correspondence preference</label>\n              <div class=\"checkbox\">\n                <label>\n                  <input class=\"correspondence-email-check\" type=\"checkbox\" name=\"is_email\" value=\"1\"\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.correspondence_preference : depth0)) != null ? stack1.is_email : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Email\n                </label>\n              </div>\n              <div class=\"checkbox\">\n                <label>\n                  <input class=\"correspondence-telephone-check\" type=\"checkbox\" name=\"is_telephone\" value=\"1\"\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.correspondence_preference : depth0)) != null ? stack1.is_telephone : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Telephone\n                </label>\n              </div>\n              <div class=\"checkbox\">\n                <label>\n                  <input class=\"correspondence-letter-check\" type=\"checkbox\" name=\"is_letter\" value=\"1\"\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.correspondence_preference : depth0)) != null ? stack1.is_letter : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Letter\n                </label>\n              </div>\n              <div class=\"checkbox form-group\">\n                <label>\n                  <input class=\"correspondence-other-check\" type=\"checkbox\" name=\"is_other\" value=\"1\"\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.correspondence_preference : depth0)) != null ? stack1.is_other : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> Other\n                </label>\n                <div class=\"indent-20\">\n                  <input\n                    class=\"correspondence-other form-control input-sm "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.correspondence_preference : depth0)) != null ? stack1.is_other : stack1),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n                    type=\"text\" name=\"other\" placeholder=\"Other correspondence...\"\n                    value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.correspondence_preference : depth0)) != null ? stack1.other : stack1), depth0))
    + "\" />\n                </div>\n              </div>\n            </div>\n\n            <div class=\"form-group "
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || alias2).call(alias1,(depth0 != null ? depth0.isClient : depth0),(depth0 != null ? depth0.isAccounts : depth0),{"name":"or","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n              <label>Access settings</label>\n              <div class=\"checkbox\">\n                <label>\n                  <input type=\"checkbox\" name=\"has_access\" class=\"client-access\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_access : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> Grant access to case\n                </label>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class=\"clearfix\"></div>\n        <div>\n          <button type=\"submit\" class=\"btn btn-primary btn-sm save-address\" data-loading-text=\"Saving...\">Save</button>\n        </div>\n      </div>\n    </form>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-relationships",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-relationships-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"panel-body client-relationships\"></div>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-financials",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-financials-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <form class=\"client-financials-form\">\n      <div class=\"panel-body\">\n        <div class=\"alert-area\"></div>\n        <div class=\"form-group\">\n          <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-entitlement-numerator\">Entitlement Fraction</label>\n          <div class=\"row\">\n            <div class=\"col-xs-12 col-sm-6 col-md-3\">\n              <div class=\"row\">\n                <div class=\"col-xs-5 form-group\">\n                  <input type=\"number\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-entitlement-numerator\" class=\"form-control input-sm number\"\n                    name=\"entitlement_numerator\" min=\"0\" step=\"1\" value=\""
    + alias3(((helper = (helper = helpers.entitlement_numerator || (depth0 != null ? depth0.entitlement_numerator : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"entitlement_numerator","hash":{},"data":data}) : helper)))
    + "\" />\n                </div>\n                <div class=\"col-xs-2\">\n                  /\n                </div>\n                <div class=\"col-xs-5 form-group\">\n                  <input type=\"number\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-entitlement-denominator\" class=\"form-control input-sm number\"\n                    name=\"entitlement_denominator\" min=\"0\" step=\"1\" value=\""
    + alias3(((helper = (helper = helpers.entitlement_denominator || (depth0 != null ? depth0.entitlement_denominator : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"entitlement_denominator","hash":{},"data":data}) : helper)))
    + "\" />\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"form-group\">\n          <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-fee-percentage\">Fee Percentage</label>\n          <div class=\"input-group\">\n            <input type=\"number\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-fee-percentage\" class=\"form-control input-sm number\"\n              name=\"fee_percentage_agreed\" min=\"0\" step=\"0.01\" pattern=\"[0-9.]+\" value=\""
    + alias3(((helper = (helper = helpers.fee_percentage_agreed || (depth0 != null ? depth0.fee_percentage_agreed : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"fee_percentage_agreed","hash":{},"data":data}) : helper)))
    + "\" />\n            <span class=\"input-group-addon\">%</span>\n          </div>\n        </div>\n\n        <div class=\"row\">\n          <div class=\"form-group col-md-6\">\n            <div class=\"checkbox\">\n              <label for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-vat\"><input type=\"checkbox\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-vat\" class=\"is-vat-applicable\"\n                  name=\"is_vat_applicable\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_vat_applicable : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " /> VAT Applicable</label>\n            </div>\n          </div>\n          <div class=\"form-group col-md-6 vat-rate-group "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_vat_applicable : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-vat-rate\">VAT Rate</label>\n            <div class=\"input-group\">\n              <input type=\"number\" id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-vat-rate\" class=\"form-control input-sm number\" name=\"vat_rate\"\n                min=\"0\" step=\"0.01\" pattern=\"[0-9.]+\" value=\""
    + alias3(((helper = (helper = helpers.vat_rate || (depth0 != null ? depth0.vat_rate : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"vat_rate","hash":{},"data":data}) : helper)))
    + "\" />\n              <span class=\"input-group-addon\">%</span>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"row\">\n          <div class=\"form-group col-md-6\">\n            <label class=\"control-label\">Treethorpe Revenue</label>\n            <p class=\"form-control-static\">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.treethorpe_revenue : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</p>\n          </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_vat_applicable : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"form-group\">\n          <label class=\"control-label\">Client Distribution</label>\n          <p class=\"form-control-static\">"
    + alias3((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || alias2).call(alias1,(depth0 != null ? depth0.client_distribution : depth0),{"name":"formatCurrency","hash":{},"data":data}))
    + "</p>\n        </div>\n"
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"client",{"name":"ifEqual","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"beneficiary",{"name":"ifEqual","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"ancestor",{"name":"ifEqual","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div>\n          <button type=\"submit\" class=\"btn btn-primary btn-sm save-financials\"\n            data-loading-text=\"Saving...\">Save</button>\n        </div>\n      </div>\n    </form>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane client-notes-tab-panel "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-notes",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-notes-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <form class=\"client-notes-form\">\n      <div class=\"panel-body\">\n        <div class=\"alert-area\"></div>\n        <div class=\"form-group\">\n          <label class=\"control-label\" for=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-notes\">Notes</label>\n          <textarea id=\"client-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-notes\" class=\"form-control client-notes\" name=\"notes\">"
    + alias3(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"notes","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </div>\n        <div>\n          <button type=\"submit\" class=\"btn btn-primary btn-sm save-notes\" data-loading-text=\"Saving...\">Save</button>\n        </div>\n      </div>\n    </form>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane client-income "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-income",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-income-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"panel-body\">\n      <div class=\"client-payments\"></div>\n    </div>\n  </div>\n  <div role=\"tabpanel\"\n    class=\"tab-pane client-correspondence "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-correspondence",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-correspondence-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"panel-body\">\n      <div class=\"client-letters\"></div>\n    </div>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane client-files "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-files",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-files-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"panel-body\">\n      <div class=\"client-files-container\"></div>\n      <div class=\"dropzone-previews\"></div>\n      <button type=\"button\" class=\"btn btn-default btn-block add-file list-group-item text-center\"><i\n          class=\"fa fa-plus fa-lg\"></i> Drag and drop a file on the page or click here</button>\n    </div>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane client-milestones "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-milestones",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-milestones-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"panel-body\"></div>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane client-messages "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-messages",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-messages-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"panel-body\"></div>\n  </div>\n  <div role=\"tabpanel\"\n    class=\"tab-pane client-questionnaire "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"client-questionnaire",{"name":"ifEqual","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    id=\"client-questionnaire-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"panel-body\"></div>\n  </div>\n</div>";
},"useData":true});
