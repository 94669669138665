'use strict';
var moment = require('moment');
var MainBodyLayout = require('../views/main-body-layout');
var TimeTrackMain = require('../views/timetrack/timetrack-main');
var TimeTrackOverview = require('../views/timetrack/timetrack-overview');
var TimetrackReportView = require('../views/reporting/timetrack/timetrack');
var TimetrackTaskIndividualPieChartsView =
  require('../views/reporting/timetrack/timetrack-task-individual-pie-charts-list-view');
var Users = require('../models/users/users');

var TimetrackController = Backbone.Marionette.Controller.extend({
  initialize: function(options) {
    this.app = options.app;
  },
  appRoutes: {
    'timetrack': 'timeTrackEntry',
    'timetrack/entry(/)': 'timeTrackEntry',
    'timetrack/overview(/)': 'timeTrackOverview',
    'timetrack/report(/)': 'reportTimetrack',
    'timetrack/report/activity/:activityType/:dateRange(/teams/:teamIds)(/users/:userIds)':
      'reportTimetrackActivityTasks'
  },
  timeTrackOverview: function() {

    if(!TCAS.session.hasPerm('view_timetrack')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'Timetrack Overview' });
    app.mainBody.show(mainBodyLayout);

    var timeTrackOverview = new TimeTrackOverview();
    mainBodyLayout.content.show(timeTrackOverview);
    document.title = 'TimeTrack Overview';
  },
  timeTrackEntry: function() {

    if(!TCAS.session.hasPerm('add_timetrack')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({title: 'Timetrack Entry'});
    app.mainBody.show(mainBodyLayout);

    var timeTrackMain = new TimeTrackMain();
    mainBodyLayout.content.show(timeTrackMain);
    document.title = 'TimeTrack Entry';
  },
  reportTimetrack: function() {
    if(!TCAS.session.hasPerm('view_timetrack_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var that = this;
    if(TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', that.renderReportTimetrack);
    } else {
      console.log('no waiting');
      that.renderReportTimetrack();
    }
  },
  reportTimetrackActivityTasks: function(activityType, dateRange, teamIds, userIds) {
    if(!TCAS.session.hasPerm('view_timetrack_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    var dateSplit = dateRange.split('_');
    var startMoment = moment(dateSplit[0]);
    var endMoment = moment(dateSplit[1]);
    if (teamIds) {
      teamIds = teamIds.split(',').map(function(teamId) {
        return parseInt(teamId, 10);
      });
    } else {
      teamIds = [];
    }
    if (userIds) {
      userIds = userIds.split(',').map(function(userId) {
        return parseInt(userId, 10);
      });
    } else {
      userIds = [];
    }

    var that = this;
    if(TCAS.users.length === 0) {
      console.log('waiting');
      that.listenToOnce(TCAS.users, 'sync', function() {
        that.renderReportTimetrackActivityTasks(
          parseInt(activityType, 10),
          startMoment.toDate(),
          endMoment.toDate(),
          teamIds,
          userIds
        );
      });
    } else {
      console.log('no waiting');
      that.renderReportTimetrackActivityTasks(
        parseInt(activityType, 10),
        startMoment.toDate(),
        endMoment.toDate(),
        teamIds,
        userIds
      );
    }
  },
  renderReportTimetrack: function() {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'Timetrack Report' });
    app.mainBody.show(mainBodyLayout);
    var timetrackReport = new TimetrackReportView({
      users: app.users,
      teams: app.teams,
      teamAllocations: app.teamAllocations
    });
    mainBodyLayout.content.show(timetrackReport);
    document.title = 'TimeTrack Report';
  },
  renderReportTimetrackActivityTasks: function(activityType, startDate, endDate, teamIds, userIds) {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({title: 'Timetrack Report'});
    app.mainBody.show(mainBodyLayout);
    var timetrackTaskIndividualPieChartsView = new TimetrackTaskIndividualPieChartsView({
      collection: new Users(),
      users: app.users,
      teamAllocations: app.teamAllocations,
      activityType: activityType,
      start: startDate,
      end: endDate,
      userIds: userIds,
      teamIds: teamIds
    });
    mainBodyLayout.content.show(timetrackTaskIndividualPieChartsView);
    document.title = 'TimeTrack Report Activity Tasks';
  }
});

module.exports = TimetrackController;