// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"panel panel-casefile casefile-financials\">\n        <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#financials\">\n          <h3 class=\"panel-title\">Revenue & Forecasting</h3>\n        </a>\n        <div class=\"accodion-body collapse\" id=\"financials\">\n          <div class=\"panel panel-default\">\n            <div class=\"panel-body\">\n              <div class=\"filter-casemanager-container pull-right\"></div>\n              <h4>Booking History</h4>\n              <div class=\"booking-history-container\"></div>\n            </div>\n            <div class=\"panel-body\">\n              <h4>Forecasting</h4>\n              <div class=\"forecasting-container\"></div>\n            </div>\n            <div class=\"panel-body\">\n              <h4>Pledging</h4>\n              <div class=\"pledging-container\"></div>\n            </div>\n          </div>\n\n          <div class=\"panel panel-default\">\n            <div class=\"panel-body\">\n              <h4>Client payments</h4>\n              <div class=\"client-payments-container\"></div>\n            </div>\n          </div>\n        </div>\n      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"panel panel-casefile casefile-disbursements\">\n        <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#costs\">\n          <h3 class=\"panel-title\">Income & Disbursements</h3>\n        </a>\n        <div class=\"accordion-body collapse\" id=\"costs\">\n          <div class=\"panel-body\">\n            <h4>Costs</h4>\n            <div class=\"costs-out-container\"></div>\n            <h4>Monies Received</h4>\n            <div class=\"costs-income-container\"></div>\n            <h4>Disbursements</h4>\n            <div class=\"costs-disbursement-container\"></div>\n          </div>\n        </div>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"casefile-detail casefile-detail-client\">\n  <div class=\"panel-group\">\n    <div class=\"panel panel-casefile casefile-details\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#details\">\n        <h3 class=\"panel-title\">Case Details</h3>\n      </a>\n      <div class=\"accordion-body panel-body collapse in\" id=\"details\">\n        <div class=\"details-list\">\n          <table class=\"table-no-borders\">\n            <tr>\n              <td class=\"col-md-4\">Your case reference:</td>\n              <td class=\"col-md-8\"><strong>"
    + alias3(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "</strong></td>\n            </tr>\n            <tr>\n              <td>Your case manager:</td>\n              <td>"
    + alias3((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.caseManagerId : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n            </tr>\n            <tr>\n              <td>Registered Asset Holder Name(s):</td>\n              <td>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.asset : depth0)) != null ? stack1.asset_holder_name : stack1), depth0))
    + "</td>\n            </tr>\n          </table>\n        </div>\n      </div>\n    </div>\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_clientpayment",{"name":"hasPerm","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.hasPerm || (depth0 && depth0.hasPerm) || alias2).call(alias1,"view_cost",{"name":"hasPerm","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"panel panel-casefile casefile-files\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#files\">\n        <h3 class=\"panel-title files-title\">Files</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"files\">\n        <div class=\"panel-body\">\n          <p class=\"help-block\">View and download files related to the case here. You can also upload any files for the attention of the case manager.</p>\n          <div class=\"row\">\n            <div class=\"col-xs-12 col-sm-10 col-md-9\">\n              <div class=\"list-group\">\n                <div class=\"file-list-container\"></div>\n                <div class=\"dropzone-previews\"></div>\n                <button type=\"button\" class=\"btn btn-default btn-block add-file list-group-item text-center\"><i class=\"fa fa-plus fa-lg\"></i> Drag and drop a file on the page or click here</button>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"panel panel-casefile casefile-notes\">\n      <a class=\"panel-heading accordion-toggle\" data-toggle=\"collapse\" data-target=\"#client-messages\">\n        <h3 class=\"panel-title\">Messages</h3>\n      </a>\n      <div class=\"accordion-body collapse\" id=\"client-messages\">\n        <div class=\"panel-body\">\n          <div class=\"help-block\">This section displays messages between you and the case manager.\n            You can also communicate with the case manager using the form below.</div>\n          <div class=\"message-list-container\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
