'use strict';

var ResearchView = require('./research');
var template = require('../../templates/research/research-list.hbs');

var ResearchListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: ResearchView,
  childViewContainer: '.research-form'
});

module.exports = ResearchListView