'use strict';

var Question = Backbone.Model.extend({
  urlRoot: '/api/questions/',
  toJSON: function () {
    var data = Backbone.Model.prototype.toJSON.call(this);
    if (data.type === 'text') {
      data.choices.sort(function (a, b) {
        return a.order - b.order;
      });
    }

    return data;
  }
});

module.exports = Question;