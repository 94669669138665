'use strict';
var template = require('../../templates/categories/category-layout.hbs');
var CategoryListView = require('./category-list');
var Category = require('../../models/cases/category');
var alertTemplate = require('../../templates/alert.hbs');

var CategoryLayoutView = Backbone.Marionette.LayoutView.extend({
  template: template,
  regions: {
    'categoryList': '.category-list'
  },
  ui: {
    'researchSelect': '.research-select',
    'alertArea': '.alert-area'
  },
  initialize: function() {
    $.validator.addMethod('checkCategory', function(value, element) {
      return this.optional(element) ||
        !TCAS.categories.findWhere({'code': element.value});
    }, 'That Category name already exists.');
  },
  onRender: function() {
    this.ui.researchSelect.select2();
    var that = this;

    this.categoryList.show(
      new CategoryListView({
        collection: that.options.categories,
        researchTypes: that.options.researchTypes
      }));
  },
  submitData: function(data) {
    var that = this;
    var research = this.ui.researchSelect.select2('val');

    var category = new Category({
      research_fields: research,
      default_limit: data.limit,
      code: data.category,
      category_group: data.category_group,
      name: data.description
    });

    category.save({}, {
      success: function() {
        that.categoryList.currentView.collection.add(category);
        that.categoryList.currentView.render();

        that.ui.alertArea.html(alertTemplate({
          strong: 'Success!',
          message: 'Category created successfully.',
          type: 'success'
        }));
      },
      error: function() {
        that.ui.alertArea.html(alertTemplate({
          strong: 'Warning!',
          message: 'There was an error saving the Category..',
          type: 'danger'
        }));
      }
    });
  },
  behaviors: {
    SubmitForm: {
      message: 'new category form',
      selector: '.new-category-form',
      submitHandler: 'submitData'
    },
    Validate: {
      validateOptions: {
        /*jshint camelcase: false */
        rules: {
          category: {
            required: true,
            checkCategory: true
          },
          description: {
            required: true
          },
          limit: {
            required: true,
            number: true
          },
          research: {
            required: true
          },
          milestones: {
            required: true
          }
        },
        ignore: []
      },
      selector: '.new-category-form'
    }
  },
  serializeData: function() {
    var data = {
      researchFields: this.getOption('researchTypes').toJSON(),
      categoryGroups: this.getOption('categoryGroups').toJSON()
    };

    return data;
  },
});

module.exports = CategoryLayoutView;