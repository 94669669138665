'use strict';
var template =
  require('../../templates/templates_repository/repository-main.hbs');
var GeneralFiles = require('../../models/reporting/generalfiles');
var TemplateCreate = require('./templates-create');
var TemplatesList = require('./templates-list');

var RepositoryMainView = Backbone.Marionette.LayoutView.extend({
  template: template,
  className: 'col-sm-12',
  ui: {
    'category': '.category-filter',
    'grouping': '.grouping-filter',
    'description': '.description-filter'
  },
  events: {
    'change @ui.grouping': 'groupingChanged',
    'change @ui.category': 'categoryChanged',
    'change @ui.description': 'descriptionChanged'
  },
  regions: {
    'addTemplateRegion': '#add-template-region',
    'listTemplatesRegion': '#list-templates-region'
  },
  serializeData: function() {
    var data = {};
    data.showCreate = this.options.showCreate;
    return data;
  },
  onShow: function() {
    var that = this;
    var templates = new GeneralFiles([], {
      urlRoot: '/api/generalfiles/?type=1&page_size=2000'
    });
    templates.fetch().then(function() {
      that.templates = templates;
      that.getGroupings(that);
      that.ui.grouping.prop('selectedIndex', -1);
    });

    if(this.options.showCreate) {
      this.addTemplateRegion.show(new TemplateCreate());

      this.listenTo(TCAS.vent, 'dropzone:saved', function(template) {
        that.addTemplate(template);
        that.getGroupings(that);
        that.addTemplateRegion.show(new TemplateCreate({
          templateAdded: true
        }));
      });
    }

    this.listenTo(TCAS.vent, 'remove:template', function() {
      that.getGroupings(that);
    });

    this.ui.category.prop('disabled', true);
    this.ui.description.prop('disabled', true);
  },
  getGroupings: function(that) {
    var groupings = _.uniq(that.templates.pluck('grouping'));

    that.ui.grouping.html('<option value="">All</option>');
    _.forEach(groupings, function(value) {
      that.ui.grouping.append(
        $('<option></option>').attr('value', value).text(value)
      );
    });
  },
  addTemplate: function(template) {
    var that = this;
    if(!this.listTemplatesRegion.currentView) {
      that.listTemplatesRegion.show(new TemplatesList(
        { collection: new GeneralFiles([])}));
    }
    this.listTemplatesRegion.currentView.collection.add(template);
    this.listTemplatesRegion.currentView.render();
    this.templates.add(template);
  },
  groupingChanged: function() {
    var that = this;
    var grouping = this.ui.grouping.val();

    var collection;
    if(grouping !== '') {
      collection = new GeneralFiles(this.templates.where({grouping: grouping}));
    } else {
      collection = this.templates;
    }

    this.listTemplatesRegion.show(new TemplatesList({
      collection: collection,
      showCreate: this.options.showCreate
    }));

    var categories = _.uniq(collection.pluck('category'));

    this.ui.category.html('<option value="">All</option>');
    _.forEach(categories, function(value) {
      that.ui.category.append(
        $('<option></option>').attr('value', value).text(value)
      );
    });
    this.ui.category.prop('disabled', false);
    this.ui.category.prop('selectedIndex', -1);
    this.ui.description.prop('disabled', true);
    this.ui.description.html('<option value="None">Select Category</option>');
  },
  categoryChanged: function() {
    var that = this;
    var category = this.ui.category.val();

    var collection;
    if(category !== '') {
      collection = that.listTemplatesRegion.currentView.collection.where({
        category: category
      });
      collection = new GeneralFiles(collection);
    } else {
      collection = that.listTemplatesRegion.currentView.collection;
    }

    this.listTemplatesRegion.show(new TemplatesList({
      collection: collection,
      showCreate: this.options.showCreate
    }));

    var descriptions = _.uniq(collection.pluck('description'));

    this.ui.description.html('<option value="">All</option>');
    _.forEach(descriptions, function(value) {
      that.ui.description.append(
        $('<option></option>').attr('value', value).text(value)
      );
    });
    this.ui.description.prop('disabled', false);
    this.ui.description.prop('selectedIndex', -1);
  },
  descriptionChanged: function() {
    var that = this;
    var description = this.ui.description.val()
    var collection;
    if(description !== '') {
      collection = that.listTemplatesRegion.currentView.collection.where({
        description: description
      });
      collection = new GeneralFiles(collection);
    } else {
      collection = that.listTemplatesRegion.currentView.collection;
    }

    this.listTemplatesRegion.show(new TemplatesList({
      collection: collection,
      showCreate: this.options.showCreate
    }));

  }
});

module.exports = RepositoryMainView;