'use strict';


var SensitiveDataToken = Backbone.Model.extend({
  urlRoot: '/api/sensitivedatatokens/',
  isValid: function() {
    // token is valid if created date is less than an hour old
    var now = new Date();
    var created = new Date(this.get('created'));
    var diff = now.getTime() - created.getTime();

    return diff < 3600000;
  }
});

module.exports = SensitiveDataToken;
