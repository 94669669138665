// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <th>"
    + container.escapeExpression(((helper = (helper = helpers.choice || (depth0 != null ? depth0.choice : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"choice","hash":{},"data":data}) : helper)))
    + "</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.first),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <td>"
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"userFullName","hash":{},"data":data}))
    + "</td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <td>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <th>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<canvas class=\"survey-pie-chart-canvas\" width=\"400\" height=\"400\" data-toggle=\"popover\"\n    data-popover-content=\"#survey-pie-chart-table-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.id : stack1), depth0))
    + "\"></canvas>\n<div class=\"hidden\" id=\"survey-pie-chart-table-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">\n    <div class=\"table-responsive\">\n        <table class=\"table table-condensed table-striped table-hover survey-pie-chart-table\">\n            <thead>\n                <tr>\n                    <th>Case Manager</th>\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.choices : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tbody>\n            <tbody>\n                <tr>\n                    <th>Total</th>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.choicesTotal : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </tr>\n            </tbody>\n        </table>\n    </div>\n</div>";
},"useData":true});
