// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "Reminder:";
},"3":function(container,depth0,helpers,partials,data) {
    return "Event:";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"text-right\">\n          <div class=\"btn-group\">\n            <button type=\"button\" class=\"btn btn-default btn-xs edit\">Edit</button>\n            <button type=\"button\" class=\"btn btn-danger btn-xs delete\"><i class=\"fa fa-trash-o\"></i></button>\n          </div>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<tr><th>Who</th><td>"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.users : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td></tr>";
},"8":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"userFullName","hash":{},"data":data}))
    + "<br/>";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<tr><th>Case</th><td><a href=\""
    + alias3((helpers.caseUrl || (depth0 && depth0.caseUrl) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"caseUrl","hash":{},"data":data}))
    + "\" class=\"popout-case\">"
    + alias3((helpers.caseField || (depth0 && depth0.caseField) || alias2).call(alias1,(depth0 != null ? depth0["case"] : depth0),"ref",{"name":"caseField","hash":{},"data":data}))
    + "</a></td></tr>";
},"12":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.start : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"14":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.start : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}));
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<tr><th>End</th><td>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.allDay : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "</td></tr>";
},"17":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.end : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"19":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.end : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}));
},"21":function(container,depth0,helpers,partials,data) {
    return "<tr><th>All Day Event?</th><td>&#x2714;</td></tr>";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<tr><th>Recurring</th><td>Every "
    + alias4(((helper = (helper = helpers.recurEvery || (depth0 != null ? depth0.recurEvery : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recurEvery","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.recurInterval || (depth0 != null ? depth0.recurInterval : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recurInterval","hash":{},"data":data}) : helper)))
    + "</td></tr>";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "          <tr>\n            <th>Attendees</th>\n            <td>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.invites : depth0),{"name":"each","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </td>\n          </tr>\n";
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                <div class=\"invitee "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_revoked : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_revoked : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                  <i class=\"fa fa-fw invitee-status-icon "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"accepted",(depth0 != null ? depth0.status : depth0),{"name":"ifEqual","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"declined",(depth0 != null ? depth0.status : depth0),{"name":"ifEqual","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></i> "
    + container.escapeExpression((helpers.userFullName || (depth0 && depth0.userFullName) || alias2).call(alias1,(depth0 != null ? depth0.invitee : depth0),{"name":"userFullName","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.is_revoked : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "revoked";
},"29":function(container,depth0,helpers,partials,data) {
    return "title=\"Invite revoked\"";
},"31":function(container,depth0,helpers,partials,data) {
    return "fa-check-circle-o";
},"33":function(container,depth0,helpers,partials,data) {
    return "fa-times-circle-o";
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alternative_start : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || helpers.helperMissing).call(alias1,(depths[1] != null ? depths[1].sessionUserId : depths[1]),(depth0 != null ? depth0.invitee : depth0),{"name":"ifEqual","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                      <span class=\"alternative-proposal\">\n                        Proposes: "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].allDay : depths[1]),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n\n                      </span> "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].isEventUser : depths[1]),{"name":"if","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.alternative_start : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alternative_end : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    return "to "
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternative_end : depth0),"DD/MM/YYYY",{"name":"formatDate","hash":{},"data":data}));
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.alternative_start : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alternative_end : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    return "to "
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alternative_end : depth0),"DD/MM/YYYY HH:mm",{"name":"formatDate","hash":{},"data":data}));
},"43":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<button type=\"button\" class=\"btn btn-xs btn-default accept-proposal\" title=\"Apply the proposed time\" data-invite-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\"><i class=\"fa fa-clock-o\"></i></button>";
},"45":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                      <div class=\"btn-group invite-buttons\">\n                        <button type=\"button\" class=\"btn btn-default btn-xs accept "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"accepted",(depth0 != null ? depth0.status : depth0),{"name":"ifEqual","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">Accept</button>\n                        <button type=\"button\" class=\"btn btn-default btn-xs decline "
    + ((stack1 = (helpers.ifEqual || (depth0 && depth0.ifEqual) || alias2).call(alias1,"declined",(depth0 != null ? depth0.status : depth0),{"name":"ifEqual","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">Decline</button>\n                        <button type=\"button\" class=\"btn btn-default btn-xs propose "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alternative_start : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">Propose date/time</button>\n                      </div>\n                      <div class=\"group-proposal hidden\">\n                        <form class=\"form-horizontal proposal-form\">\n                          <div class=\"form-group\">\n                            <label for=\"start-date\" class=\"control-label col-sm-2\">Start</label>\n                            <div class=\"col-sm-5\">\n                              <input type=\"date\" class=\"form-control input-sm start-date\" name=\"start_date\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depths[1] != null ? depths[1].start : depths[1]),"YYYY-MM-DD",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n                            </div>\n                            <div class=\"col-sm-5 group-start-time "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].allDay : depths[1]),{"name":"if","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                              <input type=\"text\" class=\"form-control input-sm start-time\" id=\"start-time\"\n                                     name=\"start_time\" placeholder=\"HH:MM\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depths[1] != null ? depths[1].start : depths[1]),"HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n                            </div>\n\n                          </div>\n                          <div class=\"form-group\">\n                            <label for=\"end-date\" class=\"control-label col-sm-2\">End</label>\n                            <div class=\"col-sm-5\">\n                              <input type=\"date\" class=\"form-control input-sm end-date\" name=\"end_date\" value=\""
    + alias3((helpers.formatDate || (depth0 && depth0.formatDate) || alias2).call(alias1,(depths[1] != null ? depths[1].end : depths[1]),"YYYY-MM-DD",{"name":"formatDate","hash":{},"data":data}))
    + "\" />\n                            </div>\n                            <div class=\"col-sm-5 group-end-time "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].allDay : depths[1]),{"name":"if","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                              <input type=\"text\" class=\"form-control input-sm end-time\" id=\"end-time\"\n                                     name=\"end_time\" placeholder=\"HH:MM\" "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].end : depths[1]),{"name":"if","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n                            </div>\n                          </div>\n                          <div class=\"row\">\n                            <div class=\"col-sm-10 col-sm-offset-2\">\n                              <button type=\"submit\" class=\"btn btn-primary btn-sm save-proposal\">Save</button>\n                              <button type=\"button\" class=\"btn btn-default btn-sm cancel-proposal\">Cancel</button>\n                            </div>\n                          </div>\n                        </form>\n                      </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "active";
},"48":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"50":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return "value=\""
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].end : depths[1]),"HH:mm",{"name":"formatDate","hash":{},"data":data}))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n    <div class=\"modal-header\">\n      <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n      <h4 class=\"modal-title\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isReminder : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n    </div>\n    <div class=\"modal-body\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canEdit : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n      <table class=\"table\">\n        <tbody>\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.users : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["case"] : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <tr><th>Event</th><td>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</td></tr>\n        <tr><th>Start</th><td>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allDay : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</td></tr>\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.end : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allDay : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRecurring : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.invites : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tbody>\n      </table>\n    </div>\n  </div>\n</div>";
},"useData":true,"useDepths":true});
