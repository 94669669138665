'use strict';

var MainBodyLayout = require('../views/main-body-layout');
var CaseProspect = require('../models/cases/caseprospect');
var Survey = require('../models/survey/survey');
var SurveyAccessToken = require('../models/survey/surveyaccesstoken');
var Questions = require('../models/survey/questions');
var Answers = require('../models/survey/answers');
var SurveyResponseForm = require('../views/survey/survey-response-form');
var SurveyThanksView = require('../views/survey/thanks');
var SurveyReportView = require('../views/reporting/survey/survey-report');

var SurveyController = Backbone.Marionette.Controller.extend({
  initialize: function(options) {
    this.app = options.app;
  },
  appRoutes: {
    'survey/thanks': 'surveyThanks',
    'survey/:surveyAccessToken': 'survey',
    'survey/report(/:surveyId)': 'report'
  },
  survey: function (surveyAccessToken) {
    var app = this.getOption('app');
    var surveyAccess = new SurveyAccessToken();
    surveyAccess.set('id', surveyAccessToken);

    // check survey token is valid
    surveyAccess.fetch({
      success: function() {
        var questions = new Questions();
        var answers = new Answers();
        var survey = surveyAccess.get('survey');
        var caseProspect = surveyAccess.get('client');
        questions.setFilters({
          survey: survey.get('id'),
          client: caseProspect.get('id'),
          survey_access_token: surveyAccessToken
        });
        answers.setFilters({
          question__survey: survey.get('id'),
          client: caseProspect.get('id'),
          survey_access_token: surveyAccessToken
        });

        $.when.apply($, [questions.fetch(), answers.fetch()]).done(function() {
          var mainBodyLayout = new MainBodyLayout({ title: survey.get('name') });
          app.mainBody.show(mainBodyLayout);
          document.title = survey.get('name');
          mainBodyLayout.content.show(new SurveyResponseForm({
            collection: questions,
            answers: answers,
            survey: survey,
            client: caseProspect,
            surveyAccessToken: surveyAccessToken,
            showThanksPageOnSubmit: true,
            editable: true
          }));
        });
      },
      error: function() {
        TCAS.commands.execute('403', Backbone.history.fragment);
      }
    });
  },
  surveyThanks: function () {
    var app = this.getOption('app');
    var mainBodyLayout = new MainBodyLayout({ title: 'Thank you for submitting your responses' });
    app.mainBody.show(mainBodyLayout);
    document.title = 'Thank you';
    var surveyThanksView = new SurveyThanksView();
    mainBodyLayout.content.show(surveyThanksView);
  },
  report: function(surveyId) {
    if (!TCAS.session.hasPerm('view_survey_report')) {
      TCAS.commands.execute('403', Backbone.history.fragment);
      return false;
    }

    if (!surveyId) {
      surveyId = 1;
    }
    var survey = new Survey();
    survey.set('id', surveyId);

    var app = this.getOption('app');

    survey.fetch().then(function () {
      var mainBodyLayout = new MainBodyLayout({ title: survey.get('name') });
      app.mainBody.show(mainBodyLayout);
      document.title = survey.get('name');
      mainBodyLayout.content.show(new SurveyReportView({
        survey: survey,
        users: app.users,
        teams: app.teams,
        teamAllocations: app.teamAllocations
      }));
    });
  }
});

module.exports = SurveyController;
