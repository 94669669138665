'use strict';

var _ = require('lodash');
var template =
  require('../../../templates/reporting/individual-yearly-booked-table.hbs');

var IndividualYearlyBookedTableView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function() {
    var bookingAssetRecords = this.getOption('bookingAssetRecords');
    var bookingLARecords = this.getOption('bookingLARecords');
    var targetBookedPerMonth = this.getOption('userTarget').get('cases_booked_per_month');
    var data = {};

    var bookingAssetByMonth = [];
    var bookingLAByMonth = [];
    var targetBookedYTD = [];
    for (var i = 0; i < 12; i += 1) {
      bookingAssetByMonth[i] = [];
      bookingLAByMonth[i] = [];
      targetBookedYTD[i] = (i + 1) * targetBookedPerMonth;
    }

    // group records by month
    bookingAssetRecords.each(function(booking) {
      var created = new Date(booking.get('date'));
      bookingAssetByMonth[created.getMonth()].push(booking);
    });
    bookingLARecords.each(function(booking) {
      var created = new Date(booking.get('date'));
      bookingLAByMonth[created.getMonth()].push(booking);
    });

    var totalAssetPerMonth = [];
    var totalAssetYTD = [];
    var totalLAPerMonth = [];
    var totalLAYTD = [];
    var totalPerMonth = [];
    var totalYTD = [];

    for (i = 0; i < 12; i += 1) {
      var totalAsset = 0;
      bookingAssetByMonth[i].forEach(function(booking) {
        if (booking.get('case_element') === 1) {
          totalAsset += 1;
        }
      });
      totalAssetPerMonth[i] = totalAsset;

      totalAssetYTD[i] = totalAsset;
      if (i > 0) {
        totalAssetYTD[i] += totalAssetYTD[i - 1];
      }

      var totalLA = 0;
      bookingLAByMonth[i].forEach(function(booking) {
        if (booking.get('case_element') === 1) {
          totalLA += 1;
        }
      });
      totalLAPerMonth[i] = totalLA;
      totalLAYTD[i] = totalLA;

      if (i > 0) {
        totalLAYTD[i] += totalLAYTD[i - 1];
      }

      totalPerMonth[i] = totalAsset + totalLA;
      totalYTD[i] = totalAsset + totalLA;
      if (i > 0) {
        totalYTD[i] += totalYTD[i - 1];
      }
    }

    data.totalAssetPerMonth = totalAssetPerMonth;
    data.totalAssetYTD = totalAssetYTD;
    data.totalLAPerMonth = totalLAPerMonth;
    data.totalLAYTD = totalLAYTD;
    data.totalPerMonth = totalPerMonth;
    data.totalYTD = totalYTD;

    data.targetBookedPerMonth = targetBookedPerMonth;
    data.targetBookedYTD = targetBookedYTD;

    return data;
  }
});

module.exports = IndividualYearlyBookedTableView;
