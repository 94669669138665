'use strict';

var template = require('../../templates/clients/correspondence-list.hbs');

var CorrespondenceRowView = require('./correspondence-row');
var FileDownloadModal = require('../cases/file-download-modal');

//jshint camelcase:false
var CorrespondenceListView = Backbone.Marionette.CollectionView.extend({
  template: template,
  childView: CorrespondenceRowView,
  childViewOptions: function(){
    return {
      clients: this.getOption('clients'),
      clientId: this.getOption('clientId'),
      casefile: this.getOption('casefile')
    };
  },
  collectionEvents: {
    'change:is_active': function(model, isActive){
      if (!isActive) {
        this.collection.remove(model);
      }
    }
  },
  filter: function(model) {
    if (this.getOption('clientId')) {
      return model.get('case_prospect') === this.getOption('clientId');
    } else {
      return true;
    }
  },
  childEvents: {
    selected: function(view, model, selected){
      this.trigger('correspondenceSelected', model, selected);
    },
    requestFileDownload: function(view, file){
      var casefile = this.getOption('casefile');

      // show dialog asking for download password
      TCAS.commands.execute('enableSensitiveData', function(){
        var sensitiveDataToken = TCAS.reqres.request('sensitiveDataToken');
        casefile.get('files').fetch({
          data: {
            'sensitive_data_token': sensitiveDataToken
          }
        });
      });
      // var downloadModal = new FileDownloadModal({model: file});
      // downloadModal.render();
      // this.listenTo(downloadModal, 'passwordSuccess', function(password){
      //   //re-fetch files with file password in url
      //   this.getOption('casefile').get('files').fetch({
      //     data: {files_password: password}
      //   });
      // });
    }
  },
  onRender: function() {
    var that = this;
    this.listenTo(TCAS.vent, 'prospect:changed', function() {
      that.render();
    });
  }
});

module.exports = CorrespondenceListView;