'use strict';

var template = require('../../templates/financials/bookingrecord-list.hbs');

var BookingRecordRowView = require('./bookingrecord-row');
var BookingRecord = require('../../models/reporting/bookingrecord');
var FileDownloadModal = require('../cases/file-download-modal');

var BookingRecordListView = Backbone.Marionette.CompositeView.extend({
  template: template,
  childView: BookingRecordRowView,
  childViewContainer: 'tbody.table-items',
  childViewOptions: function() {
    return {
      casefile: this.getOption('casefile')
    };
  },
  childEvents: {
    requestFileDownload: function(view, file){
      // show dialog asking for download password
      var casefile = this.getOption('casefile');

      // show dialog asking for download password
      TCAS.commands.execute('enableSensitiveData', function(){
        var sensitiveDataToken = TCAS.reqres.request('sensitiveDataToken');
        casefile.get('files').fetch({
          data: {
            'sensitive_data_token': sensitiveDataToken
          }
        });
      });

      // var downloadModal = new FileDownloadModal({model: file});
      // downloadModal.render();
      // this.listenTo(downloadModal, 'passwordSuccess', function(password){
      //   //re-fetch files with file password in url
      //   this.getOption('casefile').get('files').fetch({
      //     data: {files_password: password}
      //   });
      // });
    }
  },
  collectionEvents: {
    'change:is_active': function(model, isActive){
      if (!isActive) {
        this.collection.remove(model);
      }
    },
    'add remove sync': 'render'
  },
  setupForm: function() {
    // jshint ignore:start
    // find the largest case element and increment
    var max = 0;
    this.collection.each(function(bookingRecord) {
      var caseElement = bookingRecord.get('case_element');
      if (caseElement > max) {
        max = caseElement;
      }
    });
    var caseManager = null;
    var allocation = this.getOption('casefile').getCurrentAllocation();
    if (allocation) {
      caseManager = allocation.get('case_manager');
    }
    var newModel = new BookingRecord({
      case: this.getOption('casefile').get('id'),
      case_manager: caseManager,
      case_element: (max + 1)
    });
    // jshint ignore:end

    this.newBookingRecordView = new BookingRecordRowView({
      model: newModel,
      casefile: this.getOption('casefile'),
      editMode: true
    });
    this.listenTo(this.newBookingRecordView, 'saved', function() {
      this.collection.add(newModel);
      this.stopListening(this.newBookingRecordView);
      this.setupForm();
    });

    this.formRegion.show(this.newBookingRecordView);
  },
  onRender: function() {
    if (this.formRegion) {
      this.formRegion.empty();
    }
    this.formRegion = new Backbone.Marionette.Region({
      el: this.$('.table-add')
    });

    if (TCAS.session.hasPerm('add_bookingrecord')) {
      this.setupForm();
    }
  },
  serializeData: function() {
    var data = {};
    var totalRevenue = 0;
    var totalVAT = 0;
    var totalDisbursements = 0;
    var total = 0;

    this.collection.forEach(function(record) {
      totalRevenue += record.get('revenue');
      totalVAT += record.get('vat');
      totalDisbursements += record.get('disbursements');
      total += record.get('revenue') + record.get('vat') + record.get('disbursements');
    });
    data.totalRevenue = totalRevenue;
    data.totalVAT = totalVAT;
    data.totalDisbursements = totalDisbursements;
    data.total = total;

    return data;
  }
});

module.exports = BookingRecordListView;
