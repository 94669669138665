'use strict';
var template = require('../templates/not-found.hbs');

var NotFoundView = Backbone.Marionette.ItemView.extend({
  template: template,
  serializeData: function(){
    return { route: this.getOption('route') };
  }
});

module.exports = NotFoundView;